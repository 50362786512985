<template>
  <div>
    <b-message type="is-success" has-icon v-if="formData.nic_selfie_face_verification_details.isIdentical">
      Face Validation Success : Selfie image {{ GlobalFunctions.getRoundedValue(formData.nic_selfie_face_verification_details.confidence * 100) }}% matching with NIC
    </b-message>
    <b-message type="is-danger" has-icon v-else>
      Face Validation Failed : Selfie image not matched with NIC
    </b-message>
    <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox v-if="formData.nic_front_attachment.attachment_type !== 'pdf'" 
        label="NIC front side image" styles="mt-2" :imageName=formData.nic_front_attachment.attachment_url />
        <div v-else class="image-box-wrapper">
          <ImageBox label="NIC front side image" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.nic_front_attachment.attachment_url, 'NIC Front Image')">Download</button>
        </div>
      </div>
      <div class="column">
        <ImageBox v-if="formData.nic_back_attachment.attachment_type !== 'pdf'" 
        label="NIC rear side image" styles="mt-2" :imageName=formData.nic_back_attachment.attachment_url />
        <div v-else class="image-box-wrapper">
          <ImageBox label="NIC rear side image" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.nic_back_attachment.attachment_url, 'NIC Rear Image')">Download</button>
        </div>
      </div>
    </div>
    <div class="columns is-7 is-variable" v-if="formData.passport_bio_page_attachment.attachment_type !== ''">
      <div class="column">
        <ImageBox v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'" 
        label="Passport bio page image" styles="mt-2" :imageName=formData.passport_bio_page_attachment.attachment_url	 />
        <div v-else class="image-box-wrapper">
          <ImageBox label="Passport bio page image" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.passport_bio_page_attachment.attachment_url, 'Passport Bio Page Image')">Download</button>
        </div>
      </div>
      <div class="column">
        <ImageBox v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'" 
        label="Passport alteration page image" styles="mt-2" :imageName=formData.passport_alteration_attachment.attachment_url />
        <div v-else class="image-box-wrapper">
          <ImageBox label="Passport alteration page image" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.passport_alteration_attachment.attachment_url, 'Passport Alteration Page Image')">Download</button>
        </div>
      </div>
    </div>
    <div class="columns is-7 is-variable" v-if="formData.passport_entry_stamp_attachment.attachment_type !== ''">
      <div class="column is-half">
        <ImageBox v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'" 
        label="Passport entry stamp page image" styles="mt-5" :imageName=formData.passport_entry_stamp_attachment.attachment_url	 />
        <ImageBox v-else label="Passport entry stamp page image" styles="mt-2" :imageName="pdfPlaceholder" />  
      </div>
    </div>
    <div class="columns is-7 is-variable" v-if="formData.visa_attachment.attachment_type !== ''">
      <div class="column is-half">
        <ImageBox v-if="formData.visa_attachment.attachment_type !== 'pdf'" 
        label="Visa image" styles="mt-5" :imageName=formData.visa_attachment.attachment_url	 />
        <div v-else class="image-box-wrapper">
          <ImageBox label="Visa image" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.visa_attachment.attachment_url, 'Visa Image')">Download</button>
        </div>
      </div>
    </div>
    <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox v-if="formData.selfie_attachment.attachment_type !== 'pdf'" 
        label="Selfie image" styles="mt-5" :imageName=formData.selfie_attachment.attachment_url />
        <div v-else class="image-box-wrapper">
          <ImageBox label="Selfie image" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.selfie_attachment.attachment_url, 'Selfie Image')">Download</button>
        </div>
      </div>
      <div class="column">
        <ImageBox label="Signature image" styles="mt-5" :imageName=formData.signature_image />
      </div>
    </div>
    <div class="columns is-7 is-variable">
      <div class="column is-half">
        <ImageBox v-if="formData.address_proof_attachment.attachment_type !== 'pdf'" 
        label="Proof of address" styles="mt-5" :imageName=formData.address_proof_attachment.attachment_url />
        <div class="image-box-wrapper" v-else>
          <ImageBox label="Proof of address" styles="mt-2" :imageName="pdfPlaceholder" />  
          <button class="download-button"
            @click="downloadpdf(formData.address_proof_attachment.attachment_url, 'Proof of Address')">Download</button>
        </div>

        <text-display-component
            label="Type"
            :value="formData.ocr_address_proof_result.bill_type"
            v-if="formData.address_proof_attachment.attachment_type === 'image'"/>

      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import ImageBox from "../../../components/ImageBox.vue";
import {useAuthStore} from "../../../store/authStore";
import TextDisplayComponent from "../../../components/TextDisplayComponent/TextDisplayComponent.vue";
import {GlobalFunctions} from "../../../helpers/globalFunctions";
 
export default {
  name: "ImageUpload",
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    }
  },
  components: {
    ImageBox,
    TextDisplayComponent
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return {
      pdfPlaceholder:  require("@/assets/Images/pdf.png"),
      formData :[],
    };
  },
  methods: {
    downloadpdf(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    GetApprovedApplicationData: function (){
      const comp= this
      const data = {
        id: comp.authStore.kyc_form_id
      }

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication
        }
      })
    },
  },
  mounted() {
    this.GetApprovedApplicationData();
   }
};
</script>

<style lang="scss" scoped>
.image-box-wrapper {
    position: relative; /* Make the wrapper a positioning context */
}

.download-button {
    display: none; /* Initially hide the download button */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95.2%;
    margin-top :2.5vh;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

.image-box-wrapper:hover .download-button {
    display: block; /* Show the download button on hover */
}
</style>
Footer
© 2023 GitHub, Inc.
Footer navigation
Terms
Privacy
Security
Status
Docs
Contact GitHub
Pricing
API
Training
Blog
About
DFCC-frontend-vue.js/ImageUploads.vue at sarmi · PayMediaSL/DFCC-frontend-vue.js