<template>
  <ValidationObserver v-slot="{ invalid }">
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 90vw; height: fit-content; max-height:99vh; overflow: auto !important;
      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;
      /*overflow: auto !important;*/">
      <div>
        <div class="columns is-7 is-variable" style="width: 100%">
          <div class="column is-three-fourth" style="text-align: center;">
            <label style="color: #04c3fc; font-size: large;">Hold Application</label>
          </div> 
          <div class="column is-three-fifth" style="margin-left: 93%; position:absolute;">
            <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
          </div>
        </div> 
      </div>
        

      <div class="message-box-body">
        Select Reason<label style="color:#ED1C24">*</label>
        <div>
          <div class="grid-container" v-if="tableDetails !=null">
            <div v-for="(item, index) in tableDetails" :key="index" class="grid-item" >
                <div>
                <b-field>
                  <b-checkbox v-model="item.is_comment_selected">{{ item.comment_content}}</b-checkbox>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message-box-body" v-if="tableDetails[tableDetails.length-1].is_comment_selected">
        Specify<label style="color:#ED1C24">*</label>
        <ValidationProvider
        :rules="{ required: true }"
                  v-slot="{ errors }"
        >
          <b-field>
            <ValidationProvider
              name="Specify"
              :rules="validateComment()"
              v-slot="{errors}">
              <b-input rounded 
                maxlength="200" 
                type="textarea"
                v-model="tableDetails[tableDetails.length-1].additional_comment" 
                @input="emitHAspecify"
                placeholder="" 
                rows = 2
                style="width: 100%;"></b-input>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="message-box-body">
        Add Comment/s for Customer
        <b-field grouped position="is-centered">
          <ValidationProvider
            name="Customer Comment"
            :rules="validateComment()"
            v-slot="{errors}" style="width:100%;">
            <b-input class="rounded-input"
              rounded 
              type="textarea" 
              maxlength="400" 
              v-model="HA_customercomment" 
              @input="emitHAcustomercomment"
              placeholder="" 
              rows = 2
              style="width: 100%;"></b-input>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-field>
      </div>
      <div>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <label aria-controls="contentIdForA11y1" style="color: #04c3fc;">Add Comment/s for internal use</label>
        </b-collapse>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger="props">
                <label aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                      style="color: #04c3fc;"> 
                      <i class="mdi mdi-plus-circle-outline"></i> 
                      Add Comment/s for internal use
                </label>
                 
              </template>
                  <div class="content">
                    <b-field grouped position="is-centered">
                      <ValidationProvider
                        name="Internal Comment"
                        :rules="validateComment()"
                        v-slot="{errors}" style="width:100%;">
                        <b-input class="rounded-input"
                          rounded 
                          type="textarea" 
                          maxlength="400" 
                          v-model="HA_internalcomment" 
                          @input="emitHAinternalcomment"
                          placeholder="" 
                          rows = 2
                          style="width:100%;"></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
          </b-collapse>

      </div>
      <b-field grouped position="is-centered">
          <b-button style=" width: 40vw;" type="is-primary" @click="emitHAholdapplication" 
          :disabled="!atLeastOneChecked || (invalid)">Hold</b-button>
      </b-field>
    </div>
  </ValidationObserver>
</template>

<script>
import NetworkManager from "@/network";
export default {
  name: 'HoldApplication',
  props: {
    title: String,
    message: String,
    show: Boolean,
    val:Boolean,
  },

  emits:['close'],

  data(){
    return{
      HA_drp_fail : this.Boolean,
      HA_Specify : '',
      HA_customercomment :'',
      HA_internalcomment:'',
      isvisible : true,
      HA_others :false,
      HA_isButtonDisabled: false,
      HA_showMessageBox: false,
      val1:false,
      tableDetails :[],
    }
  },
  computed: {
    // noCommentsSelected() 
    // { 
    //   return this.tableDetails.some(((item) => item.is_comment_selected));
    // },
    atLeastOneChecked() {   
      for (let i = 0; i < this.tableDetails.length; i++) {
        if(this.tableDetails[i].is_comment_selected)
        {
          if(((this.tableDetails[this.tableDetails.length - 1].is_comment_selected) && (this.tableDetails[this.tableDetails.length - 1].additional_comment=='')))
          {
            return false;
          }
          if((this.tableDetails[i].is_comment_selected) && ((this.tableDetails[this.tableDetails.length - 1].is_comment_selected) && (this.tableDetails[this.tableDetails.length - 1].additional_comment=='')))
          {
            return false;
          }
          return true;
        }
      }
    }
  },
  methods: {
    emitHAdrpfail() {
      this.$emit('update_HA_drp_fail', this.HA_drp_fail);
    },
    emitHAspecify() {
      this.$emit('update_HA_Specify', this.HA_Specify);
    },
    emitHAcustomercomment() {
      this.$emit('update_HA_customer_comment', this.HA_customercomment);
    },
    emitHAinternalcomment() {
      this.$emit('update_HA_internal_comment', this.HA_internalcomment);
    },
    emitHAResponces(){
      this.$emit('update_HA_reasons',this.tableDetails)
    },
    emitCloss(){
      this.$emit('Closs_HA',this.HA_showMessageBox)
    },
    emitHAholdapplication() {
      if(this.tableDetails[this.tableDetails.length-1].is_comment_selected && this.tableDetails[this.tableDetails.length-1].additional_comment==='')
      {
        this.popuperror()
      }
      else{
        this.emitHAResponces()
        this.$emit('hold_application',this.HA_isButtonDisabled,this.HA_showMessageBox);
      }
    },
    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    GetHoldReasonsList: function (){
      const comp= this
      const data = {
        type:"hold"
      }
      console.log("in Hold Application reasons")

      //comp.showFullScreenLoader()
      NetworkManager.apiRequest('api/KycApplication/returnReasons',data, function (e){
        console.log("in Hold Application Reason list",e.data)
        if(e.statusCode === 200){
          comp.tableDetails = e.data.comments
          
        }
      })
    },
    popuperror() {
        this.$buefy.dialog.alert({
            title: 'Error',
            message: 'You have to give Specific reason!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true,
            duration: 5000,
        })
    },

  },
  mounted()
  {
    console.log("in mounted")
    this.GetHoldReasonsList()
  }
};
</script>

<style>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(15px, auto);
    grid-gap: 10px;
    text-align: left;
  }

  .grid-item {
    padding: 5px;
    font-size: 18px;
    text-align: left;
  }
  .rounded-input .input,
  .rounded-input textarea {
    border-radius: 25px;
  }
</style>