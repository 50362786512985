export class TableDetailItem {
    id = 0
    ref_no = ""
    nic = ""
    name = ""
    email = ""
    contact = ""
    branch = ""
    gender = ""
    resident_status = ""
    received_date
    action = ""
    assigned_user_name = ""
    assigned_user_id = 0
    video_call_date=""
    video_call_time=""

    constructor(id,refNo,nicEicNo,name,gender,resident_status,email,mobileNo,branch,receivedDate,assignedUserName,assignedUserId,videoCallDate, videoCallTime) {
        this.id = id;
        this.ref_no = refNo;
        this.nic = nicEicNo;
        this.name = name;
        this.email = email;
        this.contact = mobileNo;
        this.gender = gender;
        this.resident_status = resident_status;
        this.branch = branch;
        this.received_date = receivedDate;
        this.assigned_user_name = assignedUserName;
        this.assigned_user_id = assignedUserId;
        this.video_call_date = videoCallDate;
        this.video_call_time = videoCallTime;

    }
}