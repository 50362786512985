<template>
  <div>
    <div class="pdf-content">
      <b-message
        v-if="!formData.pep_declaration.customer_input.not_politically_exposed"
        type="is-danger"
        has-icon
      >
        PEP Customer
      </b-message>

      <b-message
        v-if="videoCallDetail != null && videoCallDetail.call_duration > 0"
        type="is-success"
        has-icon
      >
        Duration to complete video call :
        {{
          GlobalFunctions.getDisplayTimeDuration(videoCallDetail.call_duration)
        }}
      </b-message>

      <!-- display overrides -->
      <b-message v-if="cifOverrideDetails !== null" type="is-danger" has-icon>
        <div>
          Overrides
          <ul style="margin-left: 30px; list-style: disc">
            <li v-for="(override, index) in cifOverrideDetails" :key="index">
              <span
                v-if="override.fieldName !== '' && override.fieldName !== null"
                >{{ override.fieldName }} - {{ override.message }}</span
              >
              <span v-else>{{ override.message }}</span>
              <!--              {{override.code}} - {{override.fieldName}} - {{override.message}}-->
            </li>
          </ul>
        </div>
      </b-message>

      <div v-if="getCommitKycDetails !== null">
        <div v-for="comments in getCommitKycDetails">
          <div v-if="!(comments.comment === '' || comments.comment === null)">
            <div>
              <ul
                type="disc"
                style="color: #000000"
                :key="comments.comment_label"
              >
                <li>*{{ comments.comment_label }}</li>
                <br />
              </ul>
              <!-- <b-field :label="comments.comment_label"  style="font-family: Gotham-Light; font-weight: bold">
              </b-field> -->
            </div>
          </div>
        </div>

        <b-modal v-model="isCardModalActive" :width="1100" scroll="keep">
          <div
            class="card"
            rounded
            style="
              border: 0.3vh solid #000000;
              border-radius: 25px;
              padding: 20px;
            "
          >
            <div v-for="comments in getCommitKycDetails">
              <div>
                <b-field
                  :label="comments.comment_label"
                  style="font-family: Gotham-Light; font-weight: bold"
                >
                </b-field>
              </div>
              <div>
                <InputLabel
                  v-if="comments.internal_comment"
                  label="Internal Comment"
                  readonly="true"
                  type="textarea"
                  placeholder="-- No Data --"
                  :value="comments.internal_comment"
                />
              </div>
              <div>
                <InputLabel
                  v-if="comments.customer_comment"
                  label="Customer Comment"
                  readonly="true"
                  type="textarea"
                  placeholder="-- No Data --"
                  :value="comments.customer_comment"
                />
              </div>
              <div>
                <ul type="disc" style="color: #313030">
                  <li v-if="comments.reasonsList">Reasons</li>
                </ul>
                <div v-for="items in comments.reasonsList">
                  <ul
                    type="disc"
                    style="color: #000000"
                    :key="items.comment_key"
                  >
                    <li v-if="items.is_comment_selected">
                      *{{ items.comment_content }}
                    </li>
                  </ul>
                  <div
                    v-if="
                      (items.comment_content == 'Other' ||
                        items.comment_content == 'Others') &&
                      items.is_comment_selected
                    "
                  >
                    <InputLabel
                      label="Please specify"
                      readonly="true"
                      type="textarea"
                      placeholder="-- No Data --"
                      :value="items.additional_comment"
                    />
                  </div>
                </div>
              </div>
              <hr
                style="width: 100%"
                :style="{
                  'background-color': '#1b1b1b',
                  height: '1.8px',
                  opacity: '0.5',
                  'margin-inline': '4px',
                }"
              />
            </div>
          </div>
        </b-modal>
      </div>

      <b-button
        @click="isCardModalActive = true"
        v-if="getCommitKycDetails !== null && getCommitKycDetails.length > 0"
        >Show more</b-button
      >

      <div class="is-7 mt-3" v-for="reports in drpArray" style="margin-top: 20;">
          <label >DRP Checked date : {{ reports.updated_at  }}</label>
          <InputLabel  class="mt-3"
                    v-if="reports.comment !=''"
                    label="DRP Internal Comment"
                    readonly="true"
                    type="textarea"
                    placeholder="-- No Data --"
                    :value="reports.comment"
                  />
        </div>

      <div id="input-separator"></div>

      <p>Customer Details</p>

      <div class="columns mt-1 is-7 is-variable">
        <div class="column is-three-fifths">
          <!-- Title -->
          <div class="columns is-7 is-variable">
            <div class="column is-one-quarter">
              <SelectLabel
                label="Title"
                v-model="titleEditCI"
                :selectOptions="titleList"
                :class="
                  formData.title.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />

              <AgentSelectLabel
                label="Title"
                v-model="titleEditCAI"
                :selectOptions="titleList"
                styles="mt-5"
                :hide="!formData.title.is_changed_by_call_agent"
              />

              <AgentInputButtons
                :show-buttons="
                  formData.title.is_changed_by_call_agent
                    ? formData.title.call_agent_input !== titleEditCAI
                    : formData.title.customer_input !== titleEditCI
                "
                :save="
                  () => {
                    if (formData.title.is_changed_by_call_agent) {
                      formData.title.call_agent_input = titleEditCAI;
                    } else {
                      formData.title.call_agent_input = titleEditCI;
                      titleEditCAI = titleEditCI;
                      titleEditCI = formData.title.customer_input;
                    }
                    formData.title.is_changed_by_call_agent = true;
                    if (
                      formData.title.call_agent_input ===
                      formData.title.customer_input
                    ) {
                      formData.title.call_agent_input = ``;
                      formData.title.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.title.is_changed_by_call_agent) {
                      titleEditCAI = formData.title.call_agent_input;
                    } else {
                      titleEditCI = formData.title.customer_input;
                    }
                  }
                "
              />
            </div>
            <div class="column">
              <!-- <FullNameView
                  class="mt-1"
                  label="Full Name (As per the NIC / EIC / PP)"
                  :value="fullNameEditCI"
                  :is-agent="false"
                  @getError="
                  (error) => {
                    fullNameValidationProviderError = error;
                  }
                "
                  />
                <FullNameView v-if="formData.full_name.is_changed_by_call_agent"
                  class="mt-1"
                  label="Full Name (As per the NIC / EIC / PP)"
                  :value="fullNameEditCAI"
                  :is-agent="true"
                  @getError="
                  (error) => {
                      fullNameValidationProviderError = error;
                    }
                  "
                  /> -->
              <InputLabel copyable="true"
                label="Full Name (As per the NIC / EIC / PP)"
                type="textarea"
                rounded
                :maxlength="146"
                rows="2"
                cols="48"
                v-model="fullNameEditCI"
                :validationRules="validateName()"
                fieldName="Name"
                :class="
                  formData.full_name.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
                @getError="
                  (error) => {
                    fullNameValidationProviderError = error;
                  }
                "
              />

              <AgentInputField copyable="true"
                label="Full Name (As per the NIC / EIC / PP)"
                type="textarea"
                rounded
                :maxlength="146"
                rows="2"
                cols="48"
                v-model="fullNameEditCAI"
                :validationRules="validateName()"
                fieldName="Name"
                styles="mt-3"
                :hide="!formData.full_name.is_changed_by_call_agent"
                @getError="
                  (error) => {
                    fullNameValidationProviderError = error;
                  }
                "
              />
              <AgentInputButtons
                :show-buttons="
                  formData.full_name.is_changed_by_call_agent
                    ? formData.full_name.call_agent_input !== fullNameEditCAI
                    : formData.full_name.customer_input !== fullNameEditCI
                "
                :save="
                  () => {
                    if (formData.full_name.is_changed_by_call_agent) {
                      formData.full_name.call_agent_input = fullNameEditCAI;
                    } else {
                      formData.full_name.call_agent_input = fullNameEditCI;
                      fullNameEditCAI = fullNameEditCI;
                      fullNameEditCI = formData.full_name.customer_input;
                    }
                    formData.full_name.is_changed_by_call_agent = true;
                    if (
                      formData.full_name.call_agent_input ===
                      formData.full_name.customer_input
                    ) {
                      formData.full_name.call_agent_input = ``;
                      formData.full_name.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.full_name.is_changed_by_call_agent) {
                      fullNameEditCAI = formData.full_name.call_agent_input;
                    } else {
                      fullNameEditCI = formData.full_name.customer_input;
                    }
                  }
                "
                :disable="fullNameValidationProviderError"
              />
            </div>
          </div>
          <!-- citizenship -->
          <div class="columns is-7 is-variable">
            <div class="column">
              <SelectLabel
                label="Citizenship"
                v-model="citizenshipEditCI"
                :selectOptions="citizenshipList"
                :class="
                  formData.citizenship.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />

              <AgentSelectLabel
                label="Citizenship"
                v-model="citizenshipEditCAI"
                :selectOptions="citizenshipList"
                styles="mt-5"
                :hide="!formData.citizenship.is_changed_by_call_agent"
              />

              <AgentInputButtons
                :show-buttons="
                  formData.citizenship.is_changed_by_call_agent
                    ? formData.citizenship.call_agent_input !==
                      citizenshipEditCAI
                    : formData.citizenship.customer_input !== citizenshipEditCI
                "
                :save="
                  () => {
                    if (formData.citizenship.is_changed_by_call_agent) {
                      formData.citizenship.call_agent_input =
                        citizenshipEditCAI;
                    } else {
                      formData.citizenship.call_agent_input = citizenshipEditCI;
                      citizenshipEditCAI = citizenshipEditCI;
                      citizenshipEditCI = formData.citizenship.customer_input;
                    }
                    formData.citizenship.is_changed_by_call_agent = true;
                    if (
                      formData.citizenship.call_agent_input ===
                      formData.citizenship.customer_input
                    ) {
                      formData.citizenship.call_agent_input = ``;
                      formData.citizenship.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.citizenship.is_changed_by_call_agent) {
                      citizenshipEditCAI =
                        formData.citizenship.call_agent_input;
                    } else {
                      citizenshipEditCI = formData.citizenship.customer_input;
                    }
                  }
                "
              />
            </div>
            <div class="column">
              <SelectLabel
                label="Residency"
                v-model="residentEditCI"
                :selectOptions="residentList"
                class="non-interactable-input"
              />
              <!-- :class="
                  formData.residentship.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                " -->
              <AgentSelectLabel
                label="Residency"
                v-model="residentEditCAI"
                :selectOptions="residentList"
                styles="mt-5"
                :hide="!formData.residentship.is_changed_by_call_agent"
              />

              <AgentInputButtons
                :show-buttons="
                  formData.residentship.is_changed_by_call_agent
                    ? formData.residentship.call_agent_input !== residentEditCAI
                    : formData.residentship.customer_input !== residentEditCI
                "
                :save="
                  () => {
                    if (formData.residentship.is_changed_by_call_agent) {
                      formData.residentship.call_agent_input = residentEditCAI;
                    } else {
                      formData.residentship.call_agent_input = residentEditCI;
                      residentEditCAI = residentEditCI;
                      residentEditCI = formData.residentship.customer_input;
                    }
                    formData.residentship.is_changed_by_call_agent = true;
                    if (
                      formData.residentship.call_agent_input ===
                      formData.residentship.customer_input
                    ) {
                      formData.residentship.call_agent_input = ``;
                      formData.residentship.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.residentship.is_changed_by_call_agent) {
                      residentEditCAI = formData.residentship.call_agent_input;
                    } else {
                      residentEditCI = formData.residentship.customer_input;
                    }
                  }
                "
              />
            </div>
          </div>
          <!-- date of birth -->
          <div class="columns is-7 is-variable">
            <div class="column">
              <SelectLabel
                label="Nationality"
                v-model="nationalityEditCI"
                :selectOptions="nationalityList"
                :class="
                  formData.nationality.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />

              <AgentSelectLabel
                label="Nationality"
                v-model="nationalityEditCAI"
                :selectOptions="nationalityList"
                styles="mt-5"
                :hide="!formData.nationality.is_changed_by_call_agent"
              />

              <AgentInputButtons
                :show-buttons="
                  formData.nationality.is_changed_by_call_agent
                    ? formData.nationality.call_agent_input !==
                      nationalityEditCAI
                    : formData.nationality.customer_input !== nationalityEditCI
                "
                :save="
                  () => {
                    if (formData.nationality.is_changed_by_call_agent) {
                      formData.nationality.call_agent_input =
                        nationalityEditCAI;
                    } else {
                      formData.nationality.call_agent_input = nationalityEditCI;
                      nationalityEditCAI = nationalityEditCI;
                      nationalityEditCI = formData.nationality.customer_input;
                    }
                    formData.nationality.is_changed_by_call_agent = true;
                    if (
                      formData.nationality.call_agent_input ===
                      formData.nationality.customer_input
                    ) {
                      formData.nationality.call_agent_input = ``;
                      formData.nationality.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.nationality.is_changed_by_call_agent) {
                      nationalityEditCAI =
                        formData.nationality.call_agent_input;
                    } else {
                      nationalityEditCI = formData.nationality.customer_input;
                    }
                  }
                "
              />
            </div>
            <div class="column is-two-fifths">
              <InputLabel copyable="true"
                label="Date of Birth"
                readonly="true"
                :value="
                  getFormattedDateTime(formData.date_of_birth.customer_input)
                "
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Date of Birth"
                :value="
                  getFormattedDateTime(formData.date_of_birth.call_agent_input)
                "
                styles="mt-5"
                :hide="!formData.date_of_birth.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- Nic -->
          <div class="columns is-7 is-variable">
            <div class="column">
              <InputLabel copyable="true"
                label="NIC / EIC Number"
                readonly="true"
                :value="formData.nic_eic_number.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="NIC / EIC Number"
                :value="formData.nic_eic_number.call_agent_input"
                styles="mt-5"
                :hide="!formData.nic_eic_number.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div class="column is-two-fifths">
              <div>
                <b-field
                  label-position="inside"
                  :custom-class="'custom_float_label_date'"
                >
                  <template #label>NIC Issue Date</template>

                  <p class="control has-icons-right">
                    <b-datepicker
                      position="is-bottom-left"
                      rounded
                      :max-date="maxDate"
                      @focus="
                        () => {
                          nicIssueDate = true;
                        }
                      "
                      @blur="
                        () => {
                          nicIssueDate = false;
                        }
                      "
                      v-model="nicIssueDateEditCI"
                      :class="
                        formData.nic_eic_issue_date.is_changed_by_call_agent
                          ? `non-interactable-input`
                          : ``
                      "
                    >
                    </b-datepicker>

                    <span class="icon is-small is-right" v-if="nicIssueDate">
                      <img
                        :src=calendarIcon
                        alt="Calendar-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>
              <div
                v-if="formData.nic_eic_issue_date.is_changed_by_call_agent"
                class="mt-5"
              >
                <b-field
                  label-position="inside"
                  :custom-class="'custom_float_label_date'"
                >
                  <template #label>NIC Issue Date</template>

                  <p class="control has-icons-right agent">
                    <b-datepicker
                      position="is-bottom-left"
                      rounded
                      :max-date="maxDate"
                      @focus="
                        () => {
                          nicIssueDateAj = true;
                        }
                      "
                      @blur="
                        () => {
                          nicIssueDateAj = false;
                        }
                      "
                      v-model="nicIssueDateEditCAI"
                    >
                    </b-datepicker>

                    <span class="icon is-small is-right" v-if="nicIssueDate">
                      <img
                        :src=calendarIcon
                        alt="Calendar-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>

              <AgentInputButtons
                :show-buttons="
                  formData.nic_eic_issue_date.is_changed_by_call_agent
                    ? new Date(
                        formData.nic_eic_issue_date.call_agent_input
                      ).getTime() !== nicIssueDateEditCAI.getTime()
                    : new Date(
                        formData.nic_eic_issue_date.customer_input
                      ).getTime() !== nicIssueDateEditCI.getTime()
                "
                :save="
                  () => {
                    if (formData.nic_eic_issue_date.is_changed_by_call_agent) {
                      formData.nic_eic_issue_date.call_agent_input =
                        nicIssueDateEditCAI;
                    } else {
                      formData.nic_eic_issue_date.call_agent_input =
                        nicIssueDateEditCI;
                      nicIssueDateEditCAI = nicIssueDateEditCI;
                      nicIssueDateEditCI = new Date(
                        formData.nic_eic_issue_date.customer_input
                      );
                    }
                    formData.nic_eic_issue_date.is_changed_by_call_agent = true;
                    if (
                      new Date(
                        formData.nic_eic_issue_date.call_agent_input
                      ).getTime() ===
                      new Date(
                        formData.nic_eic_issue_date.customer_input
                      ).getTime()
                    ) {
                      formData.nic_eic_issue_date.call_agent_input = ``;
                      formData.nic_eic_issue_date.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.nic_eic_issue_date.is_changed_by_call_agent) {
                      nicIssueDateEditCAI = new Date(
                        formData.nic_eic_issue_date.call_agent_input
                      );
                    } else {
                      nicIssueDateEditCI = new Date(
                        formData.nic_eic_issue_date.customer_input
                      );
                    }
                  }
                "
              />
            </div>
          </div>

          <!-- Passport -->
          <div v-if="formData.passport_number.customer_input != ''">
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  label="Passport Number"
                  readonly="true"
                  :value="formData.passport_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Passport Number"
                  :value="formData.passport_number.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.passport_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-two-fifths">
                <!-- new -->
                <div>
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label>Passport Issue Date</template>

                    <p class="control has-icons-right">
                      <b-datepicker
                        position="is-bottom-left"
                        rounded
                        :max-date="maxDate"
                        @focus="
                          () => {
                            passIssueDate = true;
                          }
                        "
                        @blur="
                          () => {
                            passIssueDate = false;
                          }
                        "
                        v-model="passIssueDateEditCI"
                        :class="
                          formData.passport_issue_date.is_changed_by_call_agent
                            ? `non-interactable-input`
                            : ``
                        "
                      >
                      </b-datepicker>

                      <span class="icon is-small is-right" v-if="passIssueDate">
                        <img
                          :src=calendarIcon
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>

                <div
                  v-if="formData.passport_issue_date.is_changed_by_call_agent"
                  class="mt-5"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label>Passport Issue Date</template>

                    <p class="control has-icons-right agent">
                      <b-datepicker
                        position="is-bottom-left"
                        rounded
                        :max-date="maxDate"
                        @focus="
                          () => {
                            passIssueDateAj = true;
                          }
                        "
                        @blur="
                          () => {
                            passIssueDateAj = false;
                          }
                        "
                        v-model="passIssueDateEditCAI"
                      >
                      </b-datepicker>

                      <span
                        class="icon is-small is-right"
                        v-if="passIssueDateAj"
                      >
                        <img
                          :src=calendarIcon
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>

                <AgentInputButtons
                  :show-buttons="
                    formData.passport_issue_date.is_changed_by_call_agent
                      ? new Date(
                          formData.passport_issue_date.call_agent_input
                        ).getTime() !== passIssueDateEditCAI.getTime()
                      : new Date(
                          formData.passport_issue_date.customer_input
                        ).getTime() !== passIssueDateEditCI.getTime()
                  "
                  :save="
                    () => {
                      if (
                        formData.passport_issue_date.is_changed_by_call_agent
                      ) {
                        formData.passport_issue_date.call_agent_input =
                          passIssueDateEditCAI;
                      } else {
                        formData.passport_issue_date.call_agent_input =
                          passIssueDateEditCI;
                        passIssueDateEditCAI = passIssueDateEditCI;
                        passIssueDateEditCI = new Date(
                          formData.passport_issue_date.customer_input
                        );
                      }
                      formData.passport_issue_date.is_changed_by_call_agent = true;
                      if (
                        new Date(
                          formData.passport_issue_date.call_agent_input
                        ).getTime() ===
                        new Date(
                          formData.passport_issue_date.customer_input
                        ).getTime()
                      ) {
                        formData.passport_issue_date.call_agent_input = ``;
                        formData.passport_issue_date.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.passport_issue_date.is_changed_by_call_agent
                      ) {
                        passIssueDateEditCAI = new Date(
                          formData.passport_issue_date.call_agent_input
                        );
                      } else {
                        passIssueDateEditCI = new Date(
                          formData.passport_issue_date.customer_input
                        );
                      }
                    }
                  "
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  label="Passport Expire Date"
                  readonly="true"
                  :value="
                    getFormattedDateTime(
                      formData.passport_expire_date.customer_input
                    )
                  "
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Passport Expire Date"
                  :value="
                    getFormattedDateTime(
                      formData.passport_expire_date.call_agent_input
                    )
                  "
                  styles="mt-5"
                  :hide="
                    !formData.passport_expire_date.is_changed_by_call_agent
                  "
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-two-fifths"></div>
            </div>
          </div>

          <!-- gender -->
          <div class="columns is-7 is-variable">
            <div class="column is-two-fifths">
              <InputLabel copyable="true"
                label="Gender"
                readonly="true"
                :value="formData.gender.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Gender"
                :value="formData.gender.call_agent_input"
                styles="mt-5"
                :hide="!formData.gender.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div class="column is-two-fifths">
              <SelectLabel
                label="Marital Status"
                v-model="statusEditCI"
                :selectOptions="maritalList"
                :class="
                  formData.maritial_status.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />

              <AgentSelectLabel
                label="Marital Status"
                v-model="statusEditCAI"
                :selectOptions="maritalList"
                styles="mt-5"
                :hide="!formData.maritial_status.is_changed_by_call_agent"
              />

              <AgentInputButtons
                :show-buttons="
                  formData.maritial_status.is_changed_by_call_agent
                    ? formData.maritial_status.call_agent_input !==
                      statusEditCAI
                    : formData.maritial_status.customer_input !== statusEditCI
                "
                :save="
                  () => {
                    if (formData.maritial_status.is_changed_by_call_agent) {
                      formData.maritial_status.call_agent_input = statusEditCAI;
                    } else {
                      formData.maritial_status.call_agent_input = statusEditCI;
                      statusEditCAI = statusEditCI;
                      statusEditCI = formData.maritial_status.customer_input;
                    }
                    formData.maritial_status.is_changed_by_call_agent = true;
                    if (
                      formData.maritial_status.call_agent_input ===
                      formData.maritial_status.customer_input
                    ) {
                      formData.maritial_status.call_agent_input = ``;
                      formData.maritial_status.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.maritial_status.is_changed_by_call_agent) {
                      statusEditCAI = formData.maritial_status.call_agent_input;
                    } else {
                      statusEditCI = formData.maritial_status.customer_input;
                    }
                  }
                "
              />
            </div>
          </div>
          <!-- <b-message type="is-success" has-icon>
              Face Validation Success :
            </b-message> -->
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <!-- Permanent Address -->
          <p>Permanent Address</p>
          <!-- <div
            v-if="
              !formData.is_correspondence_address_differs_with_the_permanent_address
            "
          >
            <label style="size: 18px; color: #ababab"
              >Permanent and correspondence addresses are one and the
              same.</label
            >
          </div> -->
          <div class="columns is-7 is-variable mt-1">
            <div class="column is-two-fifths">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Building Number"
                v-model="buildingNumEditCI"
                :class="
                  formData.building_number.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentInputField copyable="true"
                label="Building Number"
                v-model="buildingNumEditCAI"
                :hide="!formData.building_number.is_changed_by_call_agent"
                styles="mt-5"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.building_number.is_changed_by_call_agent
                    ? formData.building_number.call_agent_input !==
                      buildingNumEditCAI
                    : formData.building_number.customer_input !==
                      buildingNumEditCI
                "
                :save="
                  () => {
                    if (formData.building_number.is_changed_by_call_agent) {
                      formData.building_number.call_agent_input =
                        buildingNumEditCAI;
                    } else {
                      formData.building_number.call_agent_input =
                        buildingNumEditCI;
                      buildingNumEditCAI = buildingNumEditCI;
                      buildingNumEditCI =
                        formData.building_number.customer_input;
                    }
                    formData.building_number.is_changed_by_call_agent = true;
                    if (
                      formData.building_number.call_agent_input ===
                      formData.building_number.customer_input
                    ) {
                      formData.building_number.call_agent_input = ``;
                      formData.building_number.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.building_number.is_changed_by_call_agent) {
                      buildingNumEditCAI =
                        formData.building_number.call_agent_input;
                    } else {
                      buildingNumEditCI =
                        formData.building_number.customer_input;
                    }
                  }
                "
              />
            </div>
            <div class="column">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Building Name"
                v-model="buildingNameEditCI"
                :class="
                  formData.building_name.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentInputField copyable="true"
                label="Building Name"
                v-model="buildingNameEditCAI"
                styles="mt-5"
                :hide="!formData.building_name.is_changed_by_call_agent"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.building_name.is_changed_by_call_agent
                    ? formData.building_name.call_agent_input !==
                      buildingNameEditCAI
                    : formData.building_name.customer_input !==
                      buildingNameEditCI
                "
                :save="
                  () => {
                    if (formData.building_name.is_changed_by_call_agent) {
                      formData.building_name.call_agent_input =
                        buildingNameEditCAI;
                    } else {
                      formData.building_name.call_agent_input =
                        buildingNameEditCI;
                      buildingNameEditCAI = buildingNameEditCI;
                      buildingNameEditCI =
                        formData.building_name.customer_input;
                    }
                    formData.building_name.is_changed_by_call_agent = true;
                    if (
                      formData.building_name.call_agent_input ===
                      formData.building_name.customer_input
                    ) {
                      formData.building_name.call_agent_input = ``;
                      formData.building_name.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.building_name.is_changed_by_call_agent) {
                      buildingNameEditCAI =
                        formData.building_name.call_agent_input;
                    } else {
                      buildingNameEditCI =
                        formData.building_name.customer_input;
                    }
                  }
                "
              />
            </div>
          </div>
          <div class="columns is-7 is-variable">
            <div class="column">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Street"
                v-model="perStreetEditCI"
                :class="
                  formData.street.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentInputField copyable="true"
                label="Street"
                styles="mt-5"
                v-model="perStreetEditCAI"
                :hide="!formData.street.is_changed_by_call_agent"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.street.is_changed_by_call_agent
                    ? formData.street.call_agent_input !== perStreetEditCAI
                    : formData.street.customer_input !== perStreetEditCI
                "
                :save="
                  () => {
                    if (formData.street.is_changed_by_call_agent) {
                      formData.street.call_agent_input = perStreetEditCAI;
                    } else {
                      formData.street.call_agent_input = perStreetEditCI;
                      perStreetEditCAI = perStreetEditCI;
                      perStreetEditCI = formData.street.customer_input;
                    }
                    formData.street.is_changed_by_call_agent = true;
                    if (
                      formData.street.call_agent_input ===
                      formData.street.customer_input
                    ) {
                      formData.street.call_agent_input = ``;
                      formData.street.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.street.is_changed_by_call_agent) {
                      perStreetEditCAI = formData.street.call_agent_input;
                    } else {
                      perStreetEditCI = formData.street.customer_input;
                    }
                  }
                "
              />
            </div>
            <div class="column">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Street 2"
                v-model="perStreet2EditCI"
                :class="
                  formData.street_2.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentInputField copyable="true"
                label="Street 2"
                styles="mt-5"
                v-model="perStreet2EditCAI"
                :hide="!formData.street_2.is_changed_by_call_agent"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.street_2.is_changed_by_call_agent
                    ? formData.street_2.call_agent_input !== perStreet2EditCAI
                    : formData.street_2.customer_input !== perStreet2EditCI
                "
                :save="
                  () => {
                    if (formData.street_2.is_changed_by_call_agent) {
                      formData.street_2.call_agent_input = perStreet2EditCAI;
                    } else {
                      formData.street_2.call_agent_input = perStreet2EditCI;
                      perStreet2EditCAI = perStreet2EditCI;
                      perStreet2EditCI = formData.street_2.customer_input;
                    }
                    formData.street_2.is_changed_by_call_agent = true;
                    if (
                      formData.street_2.call_agent_input ===
                      formData.street_2.customer_input
                    ) {
                      formData.street_2.call_agent_input = ``;
                      formData.street_2.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.street_2.is_changed_by_call_agent) {
                      perStreet2EditCAI = formData.street_2.call_agent_input;
                    } else {
                      perStreet2EditCI = formData.street_2.customer_input;
                    }
                  }
                "
              />
            </div>
          </div>
          <div class="columns is-7 is-variable">
            <div class="column">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Town"
                v-model="townEditCI"
                :class="
                  formData.town.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentInputField copyable="true"
                label="Town"
                v-model="townEditCAI"
                :hide="!formData.town.is_changed_by_call_agent"
                styles="mt-5"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.town.is_changed_by_call_agent
                    ? formData.town.call_agent_input !== townEditCAI
                    : formData.town.customer_input !== townEditCI
                "
                :save="
                  () => {
                    if (formData.town.is_changed_by_call_agent) {
                      formData.town.call_agent_input = townEditCAI;
                    } else {
                      formData.town.call_agent_input = townEditCI;
                      townEditCAI = townEditCI;
                      townEditCI = formData.town.customer_input;
                    }
                    formData.town.is_changed_by_call_agent = true;
                    if (
                      formData.town.call_agent_input ===
                      formData.town.customer_input
                    ) {
                      formData.town.call_agent_input = ``;
                      formData.town.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.town.is_changed_by_call_agent) {
                      townEditCAI = formData.town.call_agent_input;
                    } else {
                      townEditCI = formData.town.customer_input;
                    }
                  }
                "
              />
            </div>
            <div class="column">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Postal Code"
                v-model="postalCodeEditCI"
                :class="
                  formData.postal_code.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentInputField copyable="true"
                label="Postal Code"
                v-model="postalCodeEditCAI"
                styles="mt-5"
                :hide="!formData.postal_code.is_changed_by_call_agent"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.postal_code.is_changed_by_call_agent
                    ? formData.postal_code.call_agent_input !==
                      postalCodeEditCAI
                    : formData.postal_code.customer_input !== postalCodeEditCI
                "
                :save="
                  () => {
                    if (formData.postal_code.is_changed_by_call_agent) {
                      formData.postal_code.call_agent_input = postalCodeEditCAI;
                    } else {
                      formData.postal_code.call_agent_input = postalCodeEditCI;
                      postalCodeEditCAI = postalCodeEditCI;
                      postalCodeEditCI = formData.postal_code.customer_input;
                    }
                    formData.postal_code.is_changed_by_call_agent = true;
                    if (
                      formData.postal_code.call_agent_input ===
                      formData.postal_code.customer_input
                    ) {
                      formData.postal_code.call_agent_input = ``;
                      formData.postal_code.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.postal_code.is_changed_by_call_agent) {
                      postalCodeEditCAI = formData.postal_code.call_agent_input;
                    } else {
                      postalCodeEditCI = formData.postal_code.customer_input;
                    }
                  }
                "
              />
            </div>      
          </div>
          <div class="columns is-7 is-variable">
            <div class="column">
              <div
                v-if="
                  formData.residentship.customer_input !== `Sri Lanka` &&
                  (formData.country.is_changed_by_call_agent
                    ? formData.country.call_agent_input !== `Sri Lanka`
                    : formData.country.customer_input !== `Sri Lanka`)
                "
              >
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="District"
                  v-model="districtEditCI"
                  :class="
                    formData.district.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />

                <AgentInputField copyable="true"
                  label="District"
                  v-model="districtEditCAI"
                  styles="mt-5"
                  :hide="!formData.district.is_changed_by_call_agent"
                />
              </div>
              <div
                v-if="
                  formData.residentship.customer_input === `Sri Lanka` ||
                  (formData.country.is_changed_by_call_agent
                    ? formData.country.call_agent_input === `Sri Lanka`
                    : formData.country.customer_input === `Sri Lanka`)
                "
              >
                <div>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        districtEditCIAutoComplete.trim() === '' &&
                        !isPerAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>District</template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          :class="
                            formData.district.is_changed_by_call_agent
                              ? `non-interactable-input`
                              : ``
                          "
                          @focus="
                            () => {
                              isPerAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPerAddDistrictFocus = false;
                            }
                          "
                          :data="districtFilteredDataArray"
                          v-model="districtEditCIAutoComplete"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                permanentaddressdistrictcount = 0;
                                isPermanentAddressDistrictTouched = false;
                                districtEditCI = option;
                              }
                            }
                          "
                          @input.native="
                            () => {
                              ++permanentaddressdistrictcount;
                              if (permanentaddressdistrictcount > 0) {
                                isPermanentAddressDistrictTouched = true;
                                districtEditCI = ``;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>

                    <span
                      class="has-text-danger"
                      v-if="
                        !districtEditCI && isPermanentAddressDistrictTouched
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div
                  v-if="formData.district.is_changed_by_call_agent"
                  class="mt-5"
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        districtEditCAIAutoComplete.trim() === '' &&
                        !isAgPerAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>District</template>

                      <p class="control has-icons-right agent">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isAgPerAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isAgPerAddDistrictFocus = false;
                            }
                          "
                          :data="districtFilteredDataArrayCAI"
                          v-model="districtEditCAIAutoComplete"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                permanentaddressdistrictcountcai = 0;
                                isPermanentAddressDistrictTouchedcai = false;
                                districtEditCAI = option;
                              }
                            }
                          "
                          @input.native="
                            () => {
                              ++permanentaddressdistrictcountcai;
                              if (permanentaddressdistrictcountcai > 0) {
                                isPermanentAddressDistrictTouchedcai = true;
                                districtEditCAI = ``;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        !districtEditCAI && isPermanentAddressDistrictTouchedcai
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <AgentInputButtons
                :show-buttons="
                  formData.district.is_changed_by_call_agent
                    ? formData.district.call_agent_input !== districtEditCAI &&
                      districtEditCAI
                    : formData.district.customer_input !== districtEditCI &&
                      districtEditCI
                "
                :save="
                  () => {
                    if (formData.district.is_changed_by_call_agent) {
                      formData.district.call_agent_input = districtEditCAI;
                    } else {
                      formData.district.call_agent_input = districtEditCI;
                      districtEditCAI = districtEditCI;
                      districtEditCAIAutoComplete = districtEditCI;
                      districtEditCI = formData.district.customer_input;
                      districtEditCIAutoComplete = districtEditCI;
                    }
                    formData.district.is_changed_by_call_agent = true;
                    if (
                      formData.district.call_agent_input ===
                      formData.district.customer_input
                    ) {
                      formData.district.call_agent_input = ``;
                      formData.district.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.district.is_changed_by_call_agent) {
                      districtEditCAI = formData.district.call_agent_input;
                      districtEditCAIAutoComplete = districtEditCAI;
                    } else {
                      districtEditCI = formData.district.customer_input;
                      districtEditCIAutoComplete = districtEditCI;
                    }
                  }
                "
              />
            </div>
            <div class="column">
              <div>
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      countryEditCIAutoComplete.trim() === '' &&
                      !isPerAddCountryFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Country</template>

                    <p class="control has-icons-right">
                      <b-autocomplete
                        rounded
                        :class="
                          formData.country.is_changed_by_call_agent
                            ? `non-interactable-input`
                            : ``
                        "
                        @focus="
                          () => {
                            isPerAddCountryFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPerAddCountryFocus = false;
                          }
                        "
                        :data="countryFilteredDataArray"
                        v-model="countryEditCIAutoComplete"
                        placeholder=""
                        icon=""
                        open-on-focus
                        @select="
                          (option) => {
                            if (option) {
                              permanentaddresscountrycount = 0;
                              isPermanentAddressCountryTouched = false;
                              countryEditCI = option.name;
                            }
                          }
                        "
                        @input.native="
                          () => {
                            ++permanentaddresscountrycount;
                            if (permanentaddresscountrycount > 0) {
                              isPermanentAddressCountryTouched = true;
                              countryEditCI = ``;
                            }
                          }
                        "
                        field="name"
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span class="icon is-small is-right mx-2">
                        <img
                          :src=autoCompleteDownArrowImage
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>

                  <span
                    class="has-text-danger"
                    v-if="
                      !countryEditCI &&
                      isPermanentAddressCountryTouched &&
                      countryEditCIAutoComplete
                    "
                    >You have to select a valid district</span
                  >
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- country red -->
              <div
                v-if="formData.country.is_changed_by_call_agent"
                class="mt-5"
              >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      countryEditCAIAutoComplete.trim() === '' &&
                      !isPerAddCountryFocusCai
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label>Country</template>

                    <p class="control has-icons-right agent">
                      <b-autocomplete
                        rounded
                        @focus="
                          () => {
                            isPerAddCountryFocusCai = true;
                          }
                        "
                        @blur="
                          () => {
                            isPerAddCountryFocusCai = false;
                          }
                        "
                        :data="countryFilteredDataArrayCai"
                        v-model="countryEditCAIAutoComplete"
                        placeholder=""
                        icon=""
                        open-on-focus
                        @select="
                          (option) => {
                            if (option) {
                              permanentaddresscountrycountcai = 0;
                              isPermanentAddressCountryTouchedCai = false;
                              countryEditCAI = option.name;
                            }
                          }
                        "
                        @input.native="
                          () => {
                            ++permanentaddresscountrycountcai;
                            if (permanentaddresscountrycountcai > 0) {
                              isPermanentAddressCountryTouchedCai = true;
                              countryEditCAI = ``;
                            }
                          }
                        "
                        field="name"
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span class="icon is-small is-right mx-2">
                        <img
                          :src=autoCompleteDownArrowImage
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>

                  <span
                    class="has-text-danger"
                    v-if="
                      !countryEditCAI &&
                      isPermanentAddressCountryTouchedCai &&
                      countryEditCAIAutoComplete
                    "
                    >You have to select a valid district</span
                  >
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <AgentInputButtons
                :show-buttons="
                  formData.country.is_changed_by_call_agent
                    ? formData.country.call_agent_input !== countryEditCAI &&
                      countryEditCAI
                    : formData.country.customer_input !== countryEditCI &&
                      countryEditCI
                "
                :save="
                  () => {
                    if (formData.country.is_changed_by_call_agent) {
                      formData.country.call_agent_input = countryEditCAI;
                    } else {
                      formData.country.call_agent_input = countryEditCI;
                      countryEditCAI = countryEditCI;
                      countryEditCAIAutoComplete = countryEditCI;
                      countryEditCI = formData.country.customer_input;
                      countryEditCIAutoComplete = countryEditCI;
                    }
                    formData.country.is_changed_by_call_agent = true;
                    if (
                      formData.country.call_agent_input ===
                      formData.country.customer_input
                    ) {
                      formData.country.call_agent_input = ``;
                      formData.country.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.country.is_changed_by_call_agent) {
                      countryEditCAI = formData.country.call_agent_input;
                      countryEditCAIAutoComplete = countryEditCAI;
                    } else {
                      countryEditCI = formData.country.customer_input;
                      countryEditCIAutoComplete = countryEditCI;
                    }
                  }
                "
              />
            </div>   
          </div>
          <div class="columns is-7 is-variable mt-1">
            <div class="column">
              <!-- <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Status of Permanant Residence"
                :value="
                  GlobalFunctions.getStatusOfResidenceDisplayValue(
                    formData.state_of_residence.customer_input
                  )
                "
                class="non-interactable-input"
              /> -->
              <SelectLabel
                label="Status of Permanant Residence"
                v-model="residencestatusEditCI"
                :selectOptions="residentStatusList"
                :class="
                  formData.state_of_residence.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />
              <AgentSelectLabel
                label="Status of Permanant Residence"
                v-model="residencestatusEditCAI"
                :selectOptions="residentStatusList"
                styles="mt-5"
                :hide="!formData.state_of_residence.is_changed_by_call_agent"
              />
              <AgentInputButtons
                :show-buttons="
                  formData.state_of_residence.is_changed_by_call_agent
                    ? formData.state_of_residence.call_agent_input !==
                      residencestatusEditCAI
                    : formData.state_of_residence.customer_input !==
                      residencestatusEditCI
                "
                :save="
                  () => {
                    if (formData.state_of_residence.is_changed_by_call_agent) {
                      formData.state_of_residence.call_agent_input =
                        residencestatusEditCAI;
                    } else {
                      formData.state_of_residence.call_agent_input =
                        residencestatusEditCI;
                      residencestatusEditCAI = residencestatusEditCI;
                      residencestatusEditCI =
                        formData.state_of_residence.customer_input;
                    }
                    formData.state_of_residence.is_changed_by_call_agent = true;
                    if (
                      formData.state_of_residence.call_agent_input ===
                      formData.state_of_residence.customer_input
                    ) {
                      formData.state_of_residence.call_agent_input = ``;
                      formData.state_of_residence.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.state_of_residence.is_changed_by_call_agent) {
                      residencestatusEditCAI =
                        formData.state_of_residence.call_agent_input;
                    } else {
                      residencestatusEditCI =
                        formData.state_of_residence.customer_input;
                    }
                  }
                "
              />
            </div>
          </div>
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <!-- correspondence  Address -->
          <!-- v-if="
              formData.is_correspondence_address_differs_with_the_permanent_address
            " -->
          <div>
            <p>Correspondence Address</p>
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-two-fifths">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Building Number"
                  v-model="cobuildingNumEditCI"
                  :class="
                    formData.correspondence_address_building_number
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Building Number"
                  v-model="cobuildingNumEditCAI"
                  :hide="
                    !formData.correspondence_address_building_number
                      .is_changed_by_call_agent
                  "
                  styles="mt-5"
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_building_number
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_building_number
                          .call_agent_input !== cobuildingNumEditCAI
                      : formData.correspondence_address_building_number
                          .customer_input !== cobuildingNumEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_building_number
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_building_number.call_agent_input =
                          cobuildingNumEditCAI;
                      } else {
                        formData.correspondence_address_building_number.call_agent_input =
                          cobuildingNumEditCI;
                        cobuildingNumEditCAI = cobuildingNumEditCI;
                        cobuildingNumEditCI =
                          formData.correspondence_address_building_number
                            .customer_input;
                      }
                      formData.correspondence_address_building_number.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_building_number
                          .call_agent_input ===
                        formData.correspondence_address_building_number
                          .customer_input
                      ) {
                        formData.correspondence_address_building_number.call_agent_input = ``;
                        formData.correspondence_address_building_number.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_building_number
                          .is_changed_by_call_agent
                      ) {
                        cobuildingNumEditCAI =
                          formData.correspondence_address_building_number
                            .call_agent_input;
                      } else {
                        cobuildingNumEditCI =
                          formData.correspondence_address_building_number
                            .customer_input;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Building Name"
                  v-model="cobuildingNameEditCI"
                  :class="
                    formData.correspondence_address_building_name
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Building Name"
                  v-model="cobuildingNameEditCAI"
                  styles="mt-5"
                  :hide="
                    !formData.correspondence_address_building_name
                      .is_changed_by_call_agent
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_building_name
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_building_name
                          .call_agent_input !== cobuildingNameEditCAI
                      : formData.correspondence_address_building_name
                          .customer_input !== cobuildingNameEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_building_name
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_building_name.call_agent_input =
                          cobuildingNameEditCAI;
                      } else {
                        formData.correspondence_address_building_name.call_agent_input =
                          cobuildingNameEditCI;
                        cobuildingNameEditCAI = cobuildingNameEditCI;
                        cobuildingNameEditCI =
                          formData.correspondence_address_building_name
                            .customer_input;
                      }
                      formData.correspondence_address_building_name.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_building_name
                          .call_agent_input ===
                        formData.correspondence_address_building_name
                          .customer_input
                      ) {
                        formData.correspondence_address_building_name.call_agent_input = ``;
                        formData.correspondence_address_building_name.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_building_name
                          .is_changed_by_call_agent
                      ) {
                        cobuildingNameEditCAI =
                          formData.correspondence_address_building_name
                            .call_agent_input;
                      } else {
                        cobuildingNameEditCI =
                          formData.correspondence_address_building_name
                            .customer_input;
                      }
                    }
                  "
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Street"
                  v-model="coStreetEditCI"
                  :class="
                    formData.correspondence_address_street
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Street"
                  v-model="coStreetEditCAI"
                  styles="mt-5"
                  :hide="
                    !formData.correspondence_address_street
                      .is_changed_by_call_agent
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_street
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_street
                          .call_agent_input !== coStreetEditCAI
                      : formData.correspondence_address_street
                          .customer_input !== coStreetEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_street
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_street.call_agent_input =
                          coStreetEditCAI;
                      } else {
                        formData.correspondence_address_street.call_agent_input =
                          coStreetEditCI;
                        coStreetEditCAI = coStreetEditCI;
                        coStreetEditCI =
                          formData.correspondence_address_street.customer_input;
                      }
                      formData.correspondence_address_street.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_street
                          .call_agent_input ===
                        formData.correspondence_address_street.customer_input
                      ) {
                        formData.correspondence_address_street.call_agent_input = ``;
                        formData.correspondence_address_street.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_street
                          .is_changed_by_call_agent
                      ) {
                        coStreetEditCAI =
                          formData.correspondence_address_street
                            .call_agent_input;
                      } else {
                        coStreetEditCI =
                          formData.correspondence_address_street.customer_input;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Street 2"
                  v-model="coStreet2EditCI"
                  :class="
                    formData.correspondence_address_street_2
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Street 2"
                  v-model="coStreet2EditCAI"
                  styles="mt-5"
                  :hide="
                    !formData.correspondence_address_street_2
                      .is_changed_by_call_agent
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_street_2
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_street_2
                          .call_agent_input !== coStreet2EditCAI
                      : formData.correspondence_address_street_2
                          .customer_input !== coStreet2EditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_street_2
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_street_2.call_agent_input =
                        coStreet2EditCAI;
                      } else {
                        formData.correspondence_address_street_2.call_agent_input =
                        coStreet2EditCI;
                        coStreet2EditCAI = coStreet2EditCI;
                        coStreet2EditCI =
                          formData.correspondence_address_street_2.customer_input;
                      }
                      formData.correspondence_address_street_2.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_street_2
                          .call_agent_input ===
                        formData.correspondence_address_street_2.customer_input
                      ) {
                        formData.correspondence_address_street_2.call_agent_input = ``;
                        formData.correspondence_address_street_2.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_street_2
                          .is_changed_by_call_agent
                      ) {
                        coStreet2EditCAI =
                          formData.correspondence_address_street_2
                            .call_agent_input;
                      } else {
                        coStreet2EditCI =
                          formData.correspondence_address_street_2.customer_input;
                      }
                    }
                  "
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Town"
                  v-model="coTownEditCI"
                  :class="
                    formData.correspondence_address_town
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Town"
                  v-model="coTownEditCAI"
                  styles="mt-5"
                  :hide="
                    !formData.correspondence_address_town
                      .is_changed_by_call_agent
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_town
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_town
                          .call_agent_input !== coTownEditCAI
                      : formData.correspondence_address_town.customer_input !==
                        coTownEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_town
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_town.call_agent_input =
                          coTownEditCAI;
                      } else {
                        formData.correspondence_address_town.call_agent_input =
                          coTownEditCI;
                        coTownEditCAI = coTownEditCI;
                        coTownEditCI =
                          formData.correspondence_address_town.customer_input;
                      }
                      formData.correspondence_address_town.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_town
                          .call_agent_input ===
                        formData.correspondence_address_town.customer_input
                      ) {
                        formData.correspondence_address_town.call_agent_input = ``;
                        formData.correspondence_address_town.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_town
                          .is_changed_by_call_agent
                      ) {
                        coTownEditCAI =
                          formData.correspondence_address_town.call_agent_input;
                      } else {
                        coTownEditCI =
                          formData.correspondence_address_town.customer_input;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Postal Code"
                  v-model="coPostalCodeEditCI"
                  :class="
                    formData.correspondence_address_postal_code
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Postal Code"
                  v-model="coPostalCodeEditCAI"
                  styles="mt-5"
                  :hide="
                    !formData.correspondence_address_postal_code
                      .is_changed_by_call_agent
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_postal_code
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_postal_code
                          .call_agent_input !== coPostalCodeEditCAI
                      : formData.correspondence_address_postal_code
                          .customer_input !== coPostalCodeEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_postal_code
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_postal_code.call_agent_input =
                          coPostalCodeEditCAI;
                      } else {
                        formData.correspondence_address_postal_code.call_agent_input =
                          coPostalCodeEditCI;
                        coPostalCodeEditCAI = coPostalCodeEditCI;
                        coPostalCodeEditCI =
                          formData.correspondence_address_postal_code
                            .customer_input;
                      }
                      formData.correspondence_address_postal_code.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_postal_code
                          .call_agent_input ===
                        formData.correspondence_address_postal_code
                          .customer_input
                      ) {
                        formData.correspondence_address_postal_code.call_agent_input = ``;
                        formData.correspondence_address_postal_code.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_postal_code
                          .is_changed_by_call_agent
                      ) {
                        coPostalCodeEditCAI =
                          formData.correspondence_address_postal_code
                            .call_agent_input;
                      } else {
                        coPostalCodeEditCI =
                          formData.correspondence_address_postal_code
                            .customer_input;
                      }
                    }
                  "
                />
              </div>          
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <div
                  v-if="
                    formData.residentship.customer_input !== `Sri Lanka` &&
                    (formData.correspondence_address_country
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_country
                          .call_agent_input !== `Sri Lanka`
                      : formData.correspondence_address_country
                          .customer_input !== `Sri Lanka`)
                  "
                >
                  <InputLabel copyable="true"
                    placeholder=" -- No data -- "
                    label="District"
                    v-model="coDistrictEditCI"
                    :class="
                      formData.correspondence_address_district
                        .is_changed_by_call_agent
                        ? `non-interactable-input`
                        : ``
                    "
                  />
                  <AgentInputField copyable="true"
                    label="District"
                    v-model="coDistrictEditCAI"
                    :hide="
                      !formData.correspondence_address_district
                        .is_changed_by_call_agent
                    "
                    styles="mt-5"
                  />
                </div>
                <div
                  v-if="
                    formData.residentship.customer_input === `Sri Lanka` ||
                    (formData.correspondence_address_country
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_country
                          .call_agent_input === `Sri Lanka`
                      : formData.correspondence_address_country
                          .customer_input === `Sri Lanka`)
                  "
                >
                  <div>
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          corrDistrictEditCIAutoComplete.trim() === '' &&
                          !isCorAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label>District</template>

                        <p class="control has-icons-right">
                          <b-autocomplete
                            rounded
                            :class="
                              formData.correspondence_address_district
                                .is_changed_by_call_agent
                                ? `non-interactable-input`
                                : ``
                            "
                            @focus="
                              () => {
                                isCorAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddDistrictFocus = false;
                              }
                            "
                            :data="corCiDistrictFilteredDataArray"
                            v-model="corrDistrictEditCIAutoComplete"
                            placeholder=""
                            icon=""
                            open-on-focus
                            @select="
                              (option) => {
                                if (option) {
                                  coraddressdistrictcount = 0;
                                  isCorAddressDistrictTouched = false;
                                  coDistrictEditCI = option;
                                }
                              }
                            "
                            @input.native="
                              () => {
                                ++coraddressdistrictcount;
                                if (coraddressdistrictcount > 0) {
                                  isCorAddressDistrictTouched = true;
                                  coDistrictEditCI = ``;
                                }
                              }
                            "
                          >
                            <template #empty>No results found</template>
                          </b-autocomplete>
                          <span class="icon is-small is-right mx-2">
                            <img
                              :src=autoCompleteDownArrowImage
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                        </p>
                      </b-field>

                      <span
                        class="has-text-danger"
                        v-if="!coDistrictEditCI && isCorAddressDistrictTouched"
                        >You have to select a valid district</span
                      >
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="
                      formData.correspondence_address_district
                        .is_changed_by_call_agent
                    "
                    class="mt-5"
                  >
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          corrDistrictEditCAIAutoComplete.trim() === '' &&
                          !isAgCorPerAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label>District</template>

                        <p class="control has-icons-right agent">
                          <b-autocomplete
                            rounded
                            @focus="
                              () => {
                                isAgCorPerAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isAgCorPerAddDistrictFocus = false;
                              }
                            "
                            :data="corCaiDistrictFilteredDataArray"
                            v-model="corrDistrictEditCAIAutoComplete"
                            placeholder=""
                            icon=""
                            open-on-focus
                            @select="
                              (option) => {
                                if (option) {
                                  coraddressdistrictcountcai = 0;
                                  isCorAddressDistrictTouchedcai = false;
                                  coDistrictEditCAI = option;
                                }
                              }
                            "
                            @input.native="
                              () => {
                                ++coraddressdistrictcountcai;
                                if (coraddressdistrictcountcai > 0) {
                                  isCorAddressDistrictTouchedcai = true;
                                  coDistrictEditCAI = ``;
                                }
                              }
                            "
                          >
                            <template #empty>No results found</template>
                          </b-autocomplete>
                          <span class="icon is-small is-right mx-2">
                            <img
                              :src=autoCompleteDownArrowImage
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                        </p>
                      </b-field>
                      <span
                        class="has-text-danger"
                        v-if="
                          !coDistrictEditCAI && isCorAddressDistrictTouchedcai
                        "
                        >You have to select a valid district</span
                      >
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_district
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_district
                          .call_agent_input !== coDistrictEditCAI &&
                        coDistrictEditCAI
                      : formData.correspondence_address_district
                          .customer_input !== coDistrictEditCI &&
                        coDistrictEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_district
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_district.call_agent_input =
                          coDistrictEditCAI;
                      } else {
                        formData.correspondence_address_district.call_agent_input =
                          coDistrictEditCI;
                        coDistrictEditCAI = coDistrictEditCI;
                        corrDistrictEditCAIAutoComplete = coDistrictEditCI;
                        coDistrictEditCI =
                          formData.correspondence_address_district
                            .customer_input;
                        corrDistrictEditCIAutoComplete = coDistrictEditCI;
                      }
                      formData.correspondence_address_district.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_district
                          .call_agent_input ===
                        formData.correspondence_address_district.customer_input
                      ) {
                        formData.correspondence_address_district.call_agent_input = ``;
                        formData.correspondence_address_district.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_district
                          .is_changed_by_call_agent
                      ) {
                        coDistrictEditCAI =
                          formData.correspondence_address_district
                            .call_agent_input;
                        corrDistrictEditCAIAutoComplete = coDistrictEditCAI;
                      } else {
                        coDistrictEditCI =
                          formData.correspondence_address_district
                            .customer_input;
                        corrDistrictEditCIAutoComplete = coDistrictEditCI;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <!-- <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Country"
                  :value="
                    formData.correspondence_address_country.customer_input
                  "
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Country"
                  :value="
                    formData.correspondence_address_country.call_agent_input
                  "
                  :hide="
                    !formData.correspondence_address_country
                      .is_changed_by_call_agent
                  "
                  class="non-interactable-input"
                /> -->
                <div>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        CoCountryEditCIAutoComplete.trim() === '' &&
                        !isCoAddCountryFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>Country</template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          :class="
                            formData.correspondence_address_country
                              .is_changed_by_call_agent
                              ? `non-interactable-input`
                              : ``
                          "
                          @focus="
                            () => {
                              isCoAddCountryFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCoAddCountryFocus = false;
                            }
                          "
                          :data="CorCountryFilteredDataArray"
                          v-model="CoCountryEditCIAutoComplete"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                coraddresscountrycount = 0;
                                isCorAddressCountryTouched = false;
                                coCountryEditCI = option.name;
                              }
                            }
                          "
                          @input.native="
                            () => {
                              ++coraddresscountrycount;
                              if (coraddresscountrycount > 0) {
                                isCorAddressCountryTouched = true;
                                coCountryEditCI = ``;
                              }
                            }
                          "
                          field="name"
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>

                    <span
                      class="has-text-danger"
                      v-if="
                        !coCountryEditCI &&
                        isCorAddressCountryTouched &&
                        CoCountryEditCIAutoComplete
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- country red -->
                <div
                  v-if="
                    formData.correspondence_address_country
                      .is_changed_by_call_agent
                  "
                  class="mt-5"
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        CoCountryEditCAIAutoComplete.trim() === '' &&
                        !isCoAddCountryFocusCai
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>Country</template>

                      <p class="control has-icons-right agent">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isCoAddCountryFocusCai = true;
                            }
                          "
                          @blur="
                            () => {
                              isCoAddCountryFocusCai = false;
                            }
                          "
                          :data="CorCountryFilteredDataArrayCai"
                          v-model="CoCountryEditCAIAutoComplete"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                coraddresscountrycountcai = 0;
                                isCorAddressCountryTouchedCai = false;
                                coCountryEditCAI = option.name;
                              }
                            }
                          "
                          @input.native="
                            () => {
                              ++coraddresscountrycountcai;
                              if (coraddresscountrycountcai > 0) {
                                isCorAddressCountryTouchedCai = true;
                                coCountryEditCAI = ``;
                              }
                            }
                          "
                          field="name"
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>

                    <span
                      class="has-text-danger"
                      v-if="
                        !coCountryEditCAI &&
                        isCorAddressCountryTouchedCai &&
                        CoCountryEditCAIAutoComplete
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <AgentInputButtons
                  :show-buttons="
                    formData.correspondence_address_country
                      .is_changed_by_call_agent
                      ? formData.correspondence_address_country
                          .call_agent_input !== coCountryEditCAI &&
                        coCountryEditCAI
                      : formData.correspondence_address_country
                          .customer_input !== coCountryEditCI && coCountryEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.correspondence_address_country
                          .is_changed_by_call_agent
                      ) {
                        formData.correspondence_address_country.call_agent_input =
                          coCountryEditCAI;
                      } else {
                        formData.correspondence_address_country.call_agent_input =
                          coCountryEditCI;
                        coCountryEditCAI = coCountryEditCI;
                        CoCountryEditCAIAutoComplete = coCountryEditCI;
                        coCountryEditCI =
                          formData.correspondence_address_country
                            .customer_input;
                        CoCountryEditCIAutoComplete = coCountryEditCI;
                      }
                      formData.correspondence_address_country.is_changed_by_call_agent = true;
                      if (
                        formData.correspondence_address_country
                          .call_agent_input ===
                        formData.correspondence_address_country.customer_input
                      ) {
                        formData.correspondence_address_country.call_agent_input = ``;
                        formData.correspondence_address_country.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.correspondence_address_country
                          .is_changed_by_call_agent
                      ) {
                        coCountryEditCAI =
                          formData.correspondence_address_country
                            .call_agent_input;
                        CoCountryEditCAIAutoComplete = coCountryEditCAI;
                      } else {
                        coCountryEditCI =
                          formData.correspondence_address_country
                            .customer_input;
                        CoCountryEditCIAutoComplete = coCountryEditCI;
                      }
                    }
                  "
                />
              </div>
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
          </div>
          <!-- contact -->
          <P>Contact Details</P>
          <div class="columns is-7 is-variable mt-1">
            <div class="column">
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Primary Mobile Number"
                :value="formData.mobile_number.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Primary Mobile Number"
                :value="formData.mobile_number.call_agent_input"
                :hide="!formData.mobile_number.is_changed_by_call_agent"
                class="non-interactable-input"
              />
            </div>

            <div
              class="column"
              v-if="
                formData.telephone_number.customer_input ||
                formData.telephone_number.call_agent_input
              "
            >
              <InputLabel copyable="true"
                placeholder=" -- No data -- "
                label="Mobile/Telephone Number"
                :value="formData.telephone_number.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Mobile/Telephone Number"
                :value="formData.telephone_number.call_agent_input"
                :hide="!formData.telephone_number.is_changed_by_call_agent"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- Email Address -->
          <InputLabel copyable="true"
            placeholder=" -- No data -- "
            label="Primary Email ID"
            :value="formData.email.customer_input"
            styles="mt-5"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true"
            label="Primary Email ID"
            styles="mt-5"
            :value="formData.email.call_agent_input"
            :hide="!formData.email.is_changed_by_call_agent"
            class="non-interactable-input"
          />
          <!-- additional email address -->
          <InputLabel copyable="true"
            v-if="formData.secondary_email.customer_input"
            placeholder=" -- No data -- "
            label="Additional Email"
            styles="mt-5"
            :value="formData.secondary_email.customer_input"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true"
            label="Additional Email"
            styles="mt-5"
            :value="formData.secondary_email.call_agent_input"
            :hide="!formData.secondary_email.is_changed_by_call_agent"
            class="non-interactable-input"
          />
          <!-- Mother’s Maiden Name -->
          <InputLabel copyable="true"
            placeholder=" -- No data -- "
            label="Mother’s Maiden Name"
            v-model="coMotherMaidenEditCI"
            :class="
              formData.mothers_maiden_name.is_changed_by_call_agent
                ? `non-interactable-input`
                : ``
            "
            styles="mt-5"
          />
          <AgentInputField copyable="true"
            label="Mother’s Maiden Name"
            v-model="coMotherMaidenEditCAI"
            styles="mt-5"
            :hide="!formData.mothers_maiden_name.is_changed_by_call_agent"
          />
          <AgentInputButtons
            :show-buttons="
              formData.mothers_maiden_name.is_changed_by_call_agent
                ? formData.mothers_maiden_name.call_agent_input !==
                  coMotherMaidenEditCAI
                : formData.mothers_maiden_name.customer_input !==
                  coMotherMaidenEditCI
            "
            :save="
              () => {
                if (formData.mothers_maiden_name.is_changed_by_call_agent) {
                  formData.mothers_maiden_name.call_agent_input =
                    coMotherMaidenEditCAI;
                } else {
                  formData.mothers_maiden_name.call_agent_input =
                    coMotherMaidenEditCI;
                  coMotherMaidenEditCAI = coMotherMaidenEditCI;
                  coMotherMaidenEditCI =
                    formData.mothers_maiden_name.customer_input;
                }
                formData.mothers_maiden_name.is_changed_by_call_agent = true;
                if (
                  formData.mothers_maiden_name.call_agent_input ===
                  formData.mothers_maiden_name.customer_input
                ) {
                  formData.mothers_maiden_name.call_agent_input = ``;
                  formData.mothers_maiden_name.is_changed_by_call_agent = false;
                }
              }
            "
            :cancel="
              () => {
                if (formData.mothers_maiden_name.is_changed_by_call_agent) {
                  coMotherMaidenEditCAI =
                    formData.mothers_maiden_name.call_agent_input;
                } else {
                  coMotherMaidenEditCI =
                    formData.mothers_maiden_name.customer_input;
                }
              }
            "
          />
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <!-- Employment Details -->
          <p>Employment Details</p>
          <div class="mb-5">
            <ValidationProvider :rules="{ required: true }" v-slot="{ errors }"
              ><b-field
                label-position="inside"
                :custom-class="
                  industry.trim() === '' && !isindustryFocus
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
                class="mb-0"
              >
                <template #label>Industry</template>

                <p class="control has-icons-right">
                  <b-autocomplete
                    rounded
                    v-model="industry"
                    open-on-focus
                    :data="industryFilteredDataArray"
                    @select="
                      (option) => {
                        if (option) {
                          industrySelected = { ...option };
                          isIndustryTouched = false;
                        } else {
                          industrySelected = ``;
                        }
                      }
                    "
                    @input="
                      () => {
                        if (
                          industrySelected &&
                          industrySelected.description &&
                          industrySelected.description !== industry
                        ) {
                          isIndustryTouched = true;
                          industrySelected = ``;
                        }
                      }
                    "
                    @focus="
                      () => {
                        isindustryFocus = true;
                      }
                    "
                    @blur="
                      () => {
                        isindustryFocus = false;
                      }
                    "
                    placeholder=""
                    icon=""
                    field="description"
                  >
                    <template #empty>No results found</template>
                  </b-autocomplete>
                  <span
                    class="icon is-small is-right mx-2"
                    v-if="isindustryFocus"
                  >
                    <img
                      :src=autoCompleteDownArrowImage
                      alt="Stroke-img"
                      width="15"
                      height="15"
                    />
                  </span>
                </p>
              </b-field>

              <span
                v-if="industry && isIndustryTouched"
                class="has-text-danger"
                >{{ `Please select a valid industry` }}</span
              >

              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <!-- here -->
          <AgentInputButtons
            :show-buttons="
              formData.customer_industry
                ? industrySelected &&
                  formData.customer_industry.description !==
                    industrySelected.description
                : industrySelected
            "
            :save="
              () => {
                formData.customer_industry = {
                  ...industrySelected,
                };
              }
            "
            :cancel="
              () => {
                industry = formData.customer_industry.description;
                industrySelected = { ...formData.customer_industry };
                test(industrySelected);
              }
            "
          />
          <!-- industry -->
          <!-- occupation -->
          <div
            v-if="
              formData.employment_status.is_changed_by_call_agent
                ? formData.employment_status.call_agent_input === `Full-Time` ||
                  formData.employment_status.call_agent_input === `Part Time` ||
                  formData.employment_status.call_agent_input === `Probation`
                : formData.employment_status.customer_input === `Full-Time` ||
                  formData.employment_status.customer_input === `Part Time` ||
                  formData.employment_status.customer_input === `Probation`
            "
          >
            <div class="mb-5">
              <ValidationProvider
                :rules="{ required: true }"
                v-slot="{ errors }"
                ><b-field
                  label-position="inside"
                  :custom-class="
                    occupation.trim() === '' && !isoccupationFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                  class="mb-0"
                >
                  <template #label>Occupation</template>

                  <p class="control has-icons-right">
                    <b-autocomplete
                      rounded
                      v-model="occupation"
                      open-on-focus
                      :data="occupationFilteredDataArray"
                      @select="
                        (option) => {
                          if (option) {
                            occupationSelected = option;
                            isoccupationTouched = false;
                          } else {
                            occupationSelected = ``;
                          }
                        }
                      "
                      @input="
                        () => {
                          if (occupationSelected !== occupation) {
                            test('here');
                            isoccupationTouched = true;
                            occupationSelected = ``;
                          }
                        }
                      "
                      @focus="
                        () => {
                          isoccupationFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isoccupationFocus = false;
                        }
                      "
                      placeholder=""
                      icon=""
                      field=""
                    >
                      <template #empty>No results found</template>
                    </b-autocomplete>
                    <span
                      class="icon is-small is-right mx-2"
                      v-if="isoccupationFocus"
                    >
                      <img
                        :src=autoCompleteDownArrowImage
                        alt="Stroke-img"
                        width="15"
                        height="15"
                      />
                    </span>
                  </p>
                </b-field>

                <span
                  v-if="occupation && isoccupationTouched"
                  class="has-text-danger"
                  >{{ `Please select a valid occupation` }}</span
                >

                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <AgentInputButtons
              :show-buttons="
                formData.customer_occupation
                  ? formData.customer_occupation !== occupation
                  : occupation
              "
              :save="
                () => {
                  formData.customer_occupation = occupationSelected;
                }
              "
              :cancel="
                () => {
                  occupationSelected = formData.customer_occupation;
                  occupation = formData.customer_occupation;
                }
              "
              :disable="
                !occupationSelected && occupation && isoccupationTouched
              "
            />
          </div>
          <!-- occupation -->

          <div class="columns is-7 is-variable mt-1">
            <div class="column is-half">
              <SelectLabel
                label="Employment Status"
                v-model="employmentStatusEditCI"
                :selectOptions="employmentStatusList"
                :class="
                  formData.employment_status.is_changed_by_call_agent
                    ? `non-interactable-input`
                    : ``
                "
              />

              <AgentSelectLabel
                label="Employment Status"
                v-model="employmentStatusEditCAI"
                :selectOptions="employmentStatusList"
                styles="mt-5"
                :hide="!formData.employment_status.is_changed_by_call_agent"
              />

              <AgentInputButtons
                :show-buttons="
                  formData.employment_status.is_changed_by_call_agent
                    ? formData.employment_status.call_agent_input !==
                      employmentStatusEditCAI
                    : formData.employment_status.customer_input !==
                      employmentStatusEditCI
                "
                :save="
                  () => {
                    formData.profession_nature_of_business.call_agent_input = ``;
                    formData.profession_nature_of_business.is_changed_by_call_agent = true;
                    natureOfBusinessEditCAI = ``;

                    formData.are_you_work_on_another_platform.call_agent_input = false;
                    formData.are_you_work_on_another_platform.is_changed_by_call_agent = true;
                    areYouworkingEditCAI = false;

                    formData.other_platforms_working_on.call_agent_input = null;
                    formData.other_platforms_working_on.is_changed_by_call_agent = true;
                    otherPlatformWorkingOnCAI = [];

                    formData.other_platforms_working_on_other_description.call_agent_input = ``;
                    otherPlatformDescriptionCAI = ``;
                    formData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;

                    if (formData.employment_status.is_changed_by_call_agent) {
                      formData.employment_status.call_agent_input =
                        employmentStatusEditCAI;
                    } else {
                      formData.employment_status.call_agent_input =
                        employmentStatusEditCI;
                      employmentStatusEditCAI = employmentStatusEditCI;
                      employmentStatusEditCI =
                        formData.employment_status.customer_input;
                    }
                    formData.employment_status.is_changed_by_call_agent = true;
                    if (
                      formData.employment_status.call_agent_input ===
                      formData.employment_status.customer_input
                    ) {
                      formData.employment_status.call_agent_input = ``;
                      formData.employment_status.is_changed_by_call_agent = false;
                    }
                  }
                "
                :cancel="
                  () => {
                    if (formData.employment_status.is_changed_by_call_agent) {
                      employmentStatusEditCAI =
                        formData.employment_status.call_agent_input;
                    } else {
                      employmentStatusEditCI =
                        formData.employment_status.customer_input;
                    }
                  }
                "
              />
            </div>
          </div>
          <div
            v-if="
              formData.employment_status.is_changed_by_call_agent
                ? formData.employment_status.call_agent_input ===
                    'Self-Employed' ||
                  formData.employment_status.call_agent_input === 'Freelance'
                : formData.employment_status.customer_input === 'Freelance' ||
                  formData.employment_status.customer_input === 'Self-Employed'
            "
          >
            <InputLabel copyable="true"
              label="Profession / Nature of Business"
              placeholder=" -- No data -- "
              styles="mt-4"
              v-model="natureOfBusinessEditCI"
              :class="
                formData.profession_nature_of_business.is_changed_by_call_agent
                  ? `non-interactable-input`
                  : ``
              "
            />
            <AgentInputField copyable="true"
              label="Profession / Nature of Business"
              v-model="natureOfBusinessEditCAI"
              styles="mt-4"
              :hide="
                !formData.profession_nature_of_business.is_changed_by_call_agent
              "
            />
            <AgentInputButtons
              :show-buttons="
                formData.profession_nature_of_business.is_changed_by_call_agent
                  ? formData.profession_nature_of_business.call_agent_input !==
                    natureOfBusinessEditCAI
                  : formData.profession_nature_of_business.customer_input !==
                    natureOfBusinessEditCI
              "
              :save="
                () => {
                  if (
                    formData.profession_nature_of_business
                      .is_changed_by_call_agent
                  ) {
                    formData.profession_nature_of_business.call_agent_input =
                      natureOfBusinessEditCAI;
                  } else {
                    formData.profession_nature_of_business.call_agent_input =
                      natureOfBusinessEditCI;
                    natureOfBusinessEditCAI = natureOfBusinessEditCI;
                    natureOfBusinessEditCI =
                      formData.profession_nature_of_business.customer_input;
                  }
                  formData.profession_nature_of_business.is_changed_by_call_agent = true;
                  if (
                    formData.profession_nature_of_business.call_agent_input ===
                    formData.profession_nature_of_business.customer_input
                  ) {
                    formData.profession_nature_of_business.call_agent_input = ``;
                    formData.profession_nature_of_business.is_changed_by_call_agent = false;
                  }
                }
              "
              :cancel="
                () => {
                  if (
                    formData.profession_nature_of_business
                      .is_changed_by_call_agent
                  ) {
                    natureOfBusinessEditCAI =
                      formData.profession_nature_of_business.call_agent_input;
                  } else {
                    natureOfBusinessEditCI =
                      formData.profession_nature_of_business.customer_input;
                  }
                }
              "
            />
          </div>
          <div
            v-if="
              formData.employment_status.is_changed_by_call_agent
                ? formData.employment_status.call_agent_input === 'Full-Time' ||
                  formData.employment_status.call_agent_input === 'Part Time' ||
                  formData.employment_status.call_agent_input ===
                    'Self-Employed' ||
                  formData.employment_status.call_agent_input === 'Probation'
                : formData.employment_status.customer_input === 'Full-Time' ||
                  formData.employment_status.customer_input === 'Part Time' ||
                  formData.employment_status.customer_input ===
                    'Self-Employed' ||
                  formData.employment_status.customer_input === 'Probation'
            "
          >
            <InputLabel copyable="true"
              label="Employer /  Business Name"
              placeholder=" -- No data -- "
              styles="mt-5"
              v-model="employerEditCI"
              :class="
                formData.employer.is_changed_by_call_agent
                  ? `non-interactable-input`
                  : ``
              "
            />
            <AgentInputField copyable="true"
              label="Employer /  Business Name"
              v-model="employerEditCAI"
              styles="mt-5"
              :hide="!formData.employer.is_changed_by_call_agent"
            />
            <AgentInputButtons
              :show-buttons="
                formData.employer.is_changed_by_call_agent
                  ? formData.employer.call_agent_input !== employerEditCAI
                  : formData.employer.customer_input !== employerEditCI
              "
              :save="
                () => {
                  if (formData.employer.is_changed_by_call_agent) {
                    formData.employer.call_agent_input = employerEditCAI;
                  } else {
                    formData.employer.call_agent_input = employerEditCI;
                    employerEditCAI = employerEditCI;
                    employerEditCI = formData.employer.customer_input;
                  }
                  formData.employer.is_changed_by_call_agent = true;
                  if (
                    formData.employer.call_agent_input ===
                    formData.employer.customer_input
                  ) {
                    formData.employer.call_agent_input = ``;
                    formData.employer.is_changed_by_call_agent = false;
                  }
                }
              "
              :cancel="
                () => {
                  if (formData.employer.is_changed_by_call_agent) {
                    employerEditCAI = formData.employer.call_agent_input;
                  } else {
                    employerEditCI = formData.employer.customer_input;
                  }
                }
              "
            />
          </div>

          <div
            v-if="
              formData.employment_status.is_changed_by_call_agent
                ? formData.employment_status.call_agent_input === 'Full-Time' ||
                  formData.employment_status.call_agent_input === 'Part Time' ||
                  formData.employment_status.call_agent_input ===
                    'Self-Employed' ||
                  formData.employment_status.call_agent_input === 'Probation'
                : formData.employment_status.customer_input === 'Full-Time' ||
                  formData.employment_status.customer_input === 'Part Time' ||
                  formData.employment_status.customer_input ===
                    'Self-Employed' ||
                  formData.employment_status.customer_input === 'Probation'
            "
          >
            <!-- Employer / Business Address -->
            <p class="mt-4">Employer / Business Address</p>
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-two-fifths">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Building Number"
                  v-model="embuildingNumEditCI"
                  :class="
                    formData.emp_building_number.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Building Number"
                  v-model="embuildingNumEditCAI"
                  :hide="!formData.emp_building_number.is_changed_by_call_agent"
                  styles="mt-5"
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.emp_building_number.is_changed_by_call_agent
                      ? formData.emp_building_number.call_agent_input !==
                        embuildingNumEditCAI
                      : formData.emp_building_number.customer_input !==
                        embuildingNumEditCI
                  "
                  :save="
                    () => {
                      if (
                        formData.emp_building_number.is_changed_by_call_agent
                      ) {
                        formData.emp_building_number.call_agent_input =
                          embuildingNumEditCAI;
                      } else {
                        formData.emp_building_number.call_agent_input =
                          embuildingNumEditCI;
                        embuildingNumEditCAI = embuildingNumEditCI;
                        embuildingNumEditCI =
                          formData.emp_building_number.customer_input;
                      }
                      formData.emp_building_number.is_changed_by_call_agent = true;
                      if (
                        formData.emp_building_number.call_agent_input ===
                        formData.emp_building_number.customer_input
                      ) {
                        formData.emp_building_number.call_agent_input = ``;
                        formData.emp_building_number.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.emp_building_number.is_changed_by_call_agent
                      ) {
                        embuildingNumEditCAI =
                          formData.emp_building_number.call_agent_input;
                      } else {
                        embuildingNumEditCI =
                          formData.emp_building_number.customer_input;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Building Name"
                  v-model="embuildingNameEditCI"
                  :class="
                    formData.emp_building_name.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Building Name"
                  v-model="embuildingNameEditCAI"
                  :hide="!formData.emp_building_name.is_changed_by_call_agent"
                  styles="mt-5"
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.emp_building_name.is_changed_by_call_agent
                      ? formData.emp_building_name.call_agent_input !==
                        embuildingNameEditCAI
                      : formData.emp_building_name.customer_input !==
                        embuildingNameEditCI
                  "
                  :save="
                    () => {
                      if (formData.emp_building_name.is_changed_by_call_agent) {
                        formData.emp_building_name.call_agent_input =
                          embuildingNameEditCAI;
                      } else {
                        formData.emp_building_name.call_agent_input =
                          embuildingNameEditCI;
                        embuildingNameEditCAI = embuildingNameEditCI;
                        embuildingNameEditCI =
                          formData.emp_building_name.customer_input;
                      }
                      formData.emp_building_name.is_changed_by_call_agent = true;
                      if (
                        formData.emp_building_name.call_agent_input ===
                        formData.emp_building_name.customer_input
                      ) {
                        formData.emp_building_name.call_agent_input = ``;
                        formData.emp_building_name.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (formData.emp_building_name.is_changed_by_call_agent) {
                        embuildingNameEditCAI =
                          formData.emp_building_name.call_agent_input;
                      } else {
                        embuildingNameEditCI =
                          formData.emp_building_name.customer_input;
                      }
                    }
                  "
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Street"
                  v-model="emStreetEditCI"
                  :class="
                    formData.emp_street.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Street"
                  v-model="emStreetEditCAI"
                  :hide="!formData.emp_street.is_changed_by_call_agent"
                  styles="mt-5"
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.emp_street.is_changed_by_call_agent
                      ? formData.emp_street.call_agent_input !== emStreetEditCAI
                      : formData.emp_street.customer_input !== emStreetEditCI
                  "
                  :save="
                    () => {
                      if (formData.emp_street.is_changed_by_call_agent) {
                        formData.emp_street.call_agent_input = emStreetEditCAI;
                      } else {
                        formData.emp_street.call_agent_input = emStreetEditCI;
                        emStreetEditCAI = emStreetEditCI;
                        emStreetEditCI = formData.emp_street.customer_input;
                      }
                      formData.emp_street.is_changed_by_call_agent = true;
                      if (
                        formData.emp_street.call_agent_input ===
                        formData.emp_street.customer_input
                      ) {
                        formData.emp_street.call_agent_input = ``;
                        formData.emp_street.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (formData.emp_street.is_changed_by_call_agent) {
                        emStreetEditCAI = formData.emp_street.call_agent_input;
                      } else {
                        emStreetEditCI = formData.emp_street.customer_input;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Town"
                  v-model="emTownEditCI"
                  :class="
                    formData.emp_town.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Town"
                  v-model="emTownEditCAI"
                  :hide="!formData.emp_town.is_changed_by_call_agent"
                  styles="mt-5"
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.emp_town.is_changed_by_call_agent
                      ? formData.emp_town.call_agent_input !== emTownEditCAI
                      : formData.emp_town.customer_input !== emTownEditCI
                  "
                  :save="
                    () => {
                      if (formData.emp_town.is_changed_by_call_agent) {
                        formData.emp_town.call_agent_input = emTownEditCAI;
                      } else {
                        formData.emp_town.call_agent_input = emTownEditCI;
                        emTownEditCAI = emTownEditCI;
                        emTownEditCI = formData.emp_town.customer_input;
                      }
                      formData.emp_town.is_changed_by_call_agent = true;
                      if (
                        formData.emp_town.call_agent_input ===
                        formData.emp_town.customer_input
                      ) {
                        formData.emp_town.call_agent_input = ``;
                        formData.emp_town.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (formData.emp_town.is_changed_by_call_agent) {
                        emTownEditCAI = formData.emp_town.call_agent_input;
                      } else {
                        emTownEditCI = formData.emp_town.customer_input;
                      }
                    }
                  "
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  placeholder=" -- No data -- "
                  label="Postal Code"
                  v-model="emSPostalCodeEditCI"
                  :class="
                    formData.emp_postal_code.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Postal Code"
                  styles="mt-5"
                  :hide="!formData.emp_postal_code.is_changed_by_call_agent"
                  v-model="emSPostalCodeEditCAI"
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.emp_postal_code.is_changed_by_call_agent
                      ? formData.emp_postal_code.call_agent_input !==
                        emSPostalCodeEditCAI
                      : formData.emp_postal_code.customer_input !==
                        emSPostalCodeEditCI
                  "
                  :save="
                    () => {
                      if (formData.emp_postal_code.is_changed_by_call_agent) {
                        formData.emp_postal_code.call_agent_input =
                          emSPostalCodeEditCAI;
                      } else {
                        formData.emp_postal_code.call_agent_input =
                          emSPostalCodeEditCI;
                        emSPostalCodeEditCAI = emSPostalCodeEditCI;
                        emSPostalCodeEditCI =
                          formData.emp_postal_code.customer_input;
                      }
                      formData.emp_postal_code.is_changed_by_call_agent = true;
                      if (
                        formData.emp_postal_code.call_agent_input ===
                        formData.emp_postal_code.customer_input
                      ) {
                        formData.emp_postal_code.call_agent_input = ``;
                        formData.emp_postal_code.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (formData.emp_postal_code.is_changed_by_call_agent) {
                        emSPostalCodeEditCAI =
                          formData.emp_postal_code.call_agent_input;
                      } else {
                        emSPostalCodeEditCI =
                          formData.emp_postal_code.customer_input;
                      }
                    }
                  "
                />
              </div>
              <div class="column">
                <div
                  v-if="
                    formData.residentship.customer_input !== `Sri Lanka` &&
                    (formData.emp_country.is_changed_by_call_agent
                      ? formData.emp_country.call_agent_input !== `Sri Lanka`
                      : formData.emp_country.customer_input !== `Sri Lanka`)
                  "
                >
                  <InputLabel copyable="true"
                    placeholder=" -- No data -- "
                    label="District"
                    v-model="emDistrictEditCI"
                    :class="
                      formData.emp_district.is_changed_by_call_agent
                        ? `non-interactable-input`
                        : ``
                    "
                  />
                  <AgentInputField copyable="true"
                    label="District"
                    v-model="emDistrictEditCAI"
                    :hide="!formData.emp_district.is_changed_by_call_agent"
                    styles="mt-5"
                  />
                </div>
                <div
                  v-if="
                    formData.residentship.customer_input === `Sri Lanka` ||
                    (formData.emp_country.is_changed_by_call_agent
                      ? formData.emp_country.call_agent_input === `Sri Lanka`
                      : formData.emp_country.customer_input === `Sri Lanka`)
                  "
                >
                  <div>
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          emDistrictEditCIAutoComplete.trim() === '' &&
                          !isEmAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label>District</template>

                        <p class="control has-icons-right">
                          <b-autocomplete
                            rounded
                            :class="
                              formData.emp_district.is_changed_by_call_agent
                                ? `non-interactable-input`
                                : ``
                            "
                            @focus="
                              () => {
                                isEmAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmAddDistrictFocus = false;
                              }
                            "
                            :data="emDistrictFilteredDataArray"
                            v-model="emDistrictEditCIAutoComplete"
                            placeholder=""
                            icon=""
                            open-on-focus
                            @select="
                              (option) => {
                                if (option) {
                                  emaddressdistrictcount = 0;
                                  isEmAddressDistrictTouched = false;
                                  emDistrictEditCI = option;
                                }
                              }
                            "
                            @input.native="
                              () => {
                                ++emaddressdistrictcount;
                                if (emaddressdistrictcount > 0) {
                                  isEmAddressDistrictTouched = true;
                                  emDistrictEditCI = ``;
                                }
                              }
                            "
                          >
                            <template #empty>No results found</template>
                          </b-autocomplete>
                          <span class="icon is-small is-right mx-2">
                            <img
                              :src=autoCompleteDownArrowImage
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                        </p>
                      </b-field>

                      <span
                        class="has-text-danger"
                        v-if="!emDistrictEditCI && isEmAddressDistrictTouched"
                        >You have to select a valid district</span
                      >
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="formData.emp_district.is_changed_by_call_agent"
                    class="mt-5"
                  >
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          emDistrictEditCAIAutoComplete.trim() === '' &&
                          !isAgEmPerAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label>District</template>

                        <p class="control has-icons-right agent">
                          <b-autocomplete
                            rounded
                            @focus="
                              () => {
                                isAgEmPerAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isAgEmPerAddDistrictFocus = false;
                              }
                            "
                            :data="emDistrictFilteredDataArrayCAI"
                            v-model="emDistrictEditCAIAutoComplete"
                            placeholder=""
                            icon=""
                            open-on-focus
                            @select="
                              (option) => {
                                if (option) {
                                  emaddressdistrictcountcai = 0;
                                  isEmAddressDistrictTouchedcai = false;
                                  emDistrictEditCAI = option;
                                }
                              }
                            "
                            @input.native="
                              () => {
                                ++emaddressdistrictcountcai;
                                if (emaddressdistrictcountcai > 0) {
                                  isEmAddressDistrictTouchedcai = true;
                                  emDistrictEditCAI = ``;
                                }
                              }
                            "
                          >
                            <template #empty>No results found</template>
                          </b-autocomplete>
                          <span class="icon is-small is-right mx-2">
                            <img
                              :src=autoCompleteDownArrowImage
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                        </p>
                      </b-field>
                      <span
                        class="has-text-danger"
                        v-if="
                          !emDistrictEditCAI && isEmAddressDistrictTouchedcai
                        "
                        >You have to select a valid district</span
                      >
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <AgentInputButtons
                    :show-buttons="
                      formData.emp_district.is_changed_by_call_agent
                        ? formData.emp_district.call_agent_input !==
                            emDistrictEditCAI && emDistrictEditCAI
                        : formData.emp_district.customer_input !==
                            emDistrictEditCI && emDistrictEditCI
                    "
                    :save="
                      () => {
                        if (formData.emp_district.is_changed_by_call_agent) {
                          formData.emp_district.call_agent_input =
                            emDistrictEditCAI;
                        } else {
                          formData.emp_district.call_agent_input =
                            emDistrictEditCI;
                          emDistrictEditCAI = emDistrictEditCI;
                          emDistrictEditCAIAutoComplete = emDistrictEditCI;
                          emDistrictEditCI =
                            formData.emp_district.customer_input;
                          emDistrictEditCIAutoComplete = emDistrictEditCI;
                        }
                        formData.emp_district.is_changed_by_call_agent = true;
                        if (
                          formData.emp_district.call_agent_input ===
                          formData.emp_district.customer_input
                        ) {
                          formData.emp_district.call_agent_input = ``;
                          formData.emp_district.is_changed_by_call_agent = false;
                        }
                      }
                    "
                    :cancel="
                      () => {
                        if (formData.emp_district.is_changed_by_call_agent) {
                          emDistrictEditCAI =
                            formData.emp_district.call_agent_input;
                          emDistrictEditCAIAutoComplete = emDistrictEditCAI;
                        } else {
                          emDistrictEditCI =
                            formData.emp_district.customer_input;
                          emDistrictEditCIAutoComplete = emDistrictEditCI;
                        }
                      }
                    "
                  />
                </div>
              </div>
              <div class="column">
                <div>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        EmCountryEditCIAutoComplete.trim() === '' &&
                        !isEmAddCountryFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>Country</template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          :class="
                            formData.emp_country.is_changed_by_call_agent
                              ? `non-interactable-input`
                              : ``
                          "
                          @focus="
                            () => {
                              isEmAddCountryFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddCountryFocus = false;
                            }
                          "
                          :data="emCountryFilteredDataArray"
                          v-model="EmCountryEditCIAutoComplete"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                emaddresscountrycount = 0;
                                isEmAddressCountryTouched = false;
                                emCountryEditCI = option.name;
                              }
                            }
                          "
                          @input.native="
                            () => {
                              ++emaddresscountrycount;
                              if (emaddresscountrycount > 0) {
                                isEmAddressCountryTouched = true;
                                emCountryEditCI = ``;
                              }
                            }
                          "
                          field="name"
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>

                    <span
                      class="has-text-danger"
                      v-if="
                        !emCountryEditCI &&
                        isEmAddressCountryTouched &&
                        EmCountryEditCIAutoComplete
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- country red -->
                <div
                  v-if="formData.emp_country.is_changed_by_call_agent"
                  class="mt-5"
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        EmCountryEditCAIAutoComplete.trim() === '' &&
                        !isEmAddCountryFocusCai
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label>Country</template>

                      <p class="control has-icons-right agent">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isEmAddCountryFocusCai = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddCountryFocusCai = false;
                            }
                          "
                          :data="emCountryFilteredDataArrayCai"
                          v-model="EmCountryEditCAIAutoComplete"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                emaddresscountrycountcai = 0;
                                isEmAddressCountryTouchedCai = false;
                                emCountryEditCAI = option.name;
                              }
                            }
                          "
                          @input.native="
                            () => {
                              ++emaddresscountrycountcai;
                              if (emaddresscountrycountcai > 0) {
                                isEmAddressCountryTouchedCai = true;
                                emCountryEditCAI = ``;
                              }
                            }
                          "
                          field="name"
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoCompleteDownArrowImage
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>

                    <span
                      class="has-text-danger"
                      v-if="
                        !emCountryEditCAI &&
                        isEmAddressCountryTouchedCai &&
                        EmCountryEditCAIAutoComplete
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <AgentInputButtons
                  :show-buttons="
                    formData.emp_country.is_changed_by_call_agent
                      ? formData.emp_country.call_agent_input !==
                          emCountryEditCAI && emCountryEditCAI
                      : formData.emp_country.customer_input !==
                          emCountryEditCI && emCountryEditCI
                  "
                  :save="
                    () => {
                      if (formData.emp_country.is_changed_by_call_agent) {
                        formData.emp_country.call_agent_input =
                          emCountryEditCAI;
                      } else {
                        formData.emp_country.call_agent_input = emCountryEditCI;
                        emCountryEditCAI = emCountryEditCI;
                        EmCountryEditCAIAutoComplete = emCountryEditCI;
                        emCountryEditCI = formData.emp_country.customer_input;
                        EmCountryEditCIAutoComplete = emCountryEditCI;
                      }
                      formData.emp_country.is_changed_by_call_agent = true;
                      if (
                        formData.emp_country.call_agent_input ===
                        formData.emp_country.customer_input
                      ) {
                        formData.emp_country.call_agent_input = ``;
                        formData.emp_country.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (formData.emp_country.is_changed_by_call_agent) {
                        emCountryEditCAI =
                          formData.emp_country.call_agent_input;
                        EmCountryEditCAIAutoComplete = emCountryEditCAI;
                      } else {
                        emCountryEditCI = formData.emp_country.customer_input;
                        EmCountryEditCIAutoComplete = emCountryEditCI;
                      }
                    }
                  "
                />
              </div>
            </div>
          </div>

          <!-- <div
              v-if="
                formData.employment_status.is_changed_by_call_agent
                  ? formData.employment_status.call_agent_input === 'Freelance'
                  : formData.employment_status.customer_input === 'Freelance'
              "
            > -->
          <div
            v-if="
              formData.employment_status.is_changed_by_call_agent
                ? formData.employment_status.call_agent_input === `Freelance`
                : formData.employment_status.customer_input === `Freelance`
            "
          >
            <SelectLabel
              label="Are you working for any other platform's?"
              v-model="areYouworkingEditCI"
              :selectOptions="yesNoList"
              :class="
                formData.are_you_work_on_another_platform
                  .is_changed_by_call_agent
                  ? `non-interactable-input`
                  : ``
              "
            />

            <AgentSelectLabel
              label="Are you working for any other platform's?"
              v-model="areYouworkingEditCAI"
              :selectOptions="yesNoList"
              styles="mt-5"
              :hide="
                !formData.are_you_work_on_another_platform
                  .is_changed_by_call_agent
              "
            />

            <AgentInputButtons
              :show-buttons="
                formData.are_you_work_on_another_platform
                  .is_changed_by_call_agent
                  ? formData.are_you_work_on_another_platform
                      .call_agent_input !== areYouworkingEditCAI
                  : formData.are_you_work_on_another_platform.customer_input !==
                    areYouworkingEditCI
              "
              :save="
                () => {
                  if (
                    formData.are_you_work_on_another_platform
                      .is_changed_by_call_agent
                  ) {
                    formData.are_you_work_on_another_platform.call_agent_input =
                      areYouworkingEditCAI;

                    if (!areYouworkingEditCAI) {
                      formData.other_platforms_working_on.call_agent_input =
                        null;
                      otherPlatformWorkingOnCAI = [];
                      formData.other_platforms_working_on.is_changed_by_call_agent = true;

                      formData.other_platforms_working_on_other_selected.call_agent_input = false;
                      formData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;

                      formData.other_platforms_working_on_other_description.call_agent_input = ``;
                      formData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                      otherPlatformDescriptionCAI = ``;
                    }
                  } else {
                    formData.are_you_work_on_another_platform.call_agent_input =
                      areYouworkingEditCI;
                    if (!areYouworkingEditCI) {
                      formData.other_platforms_working_on.call_agent_input =
                        null;
                      otherPlatformWorkingOnCAI = [];
                      formData.other_platforms_working_on.is_changed_by_call_agent = true;
                      formData.other_platforms_working_on_other_selected.call_agent_input = false;
                      formData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;
                      formData.other_platforms_working_on_other_description.call_agent_input = ``;
                      formData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                      otherPlatformDescriptionCAI = ``;
                    }
                    areYouworkingEditCAI = areYouworkingEditCI;
                    areYouworkingEditCI =
                      formData.are_you_work_on_another_platform.customer_input;
                  }
                  formData.are_you_work_on_another_platform.is_changed_by_call_agent = true;
                  if (
                    formData.are_you_work_on_another_platform
                      .call_agent_input ===
                    formData.are_you_work_on_another_platform.customer_input
                  ) {
                    formData.are_you_work_on_another_platform.call_agent_input = ``;
                    formData.are_you_work_on_another_platform.is_changed_by_call_agent = false;
                  }
                }
              "
              :cancel="
                () => {
                  if (
                    formData.are_you_work_on_another_platform
                      .is_changed_by_call_agent
                  ) {
                    areYouworkingEditCAI =
                      formData.are_you_work_on_another_platform
                        .call_agent_input;
                  } else {
                    areYouworkingEditCI =
                      formData.are_you_work_on_another_platform.customer_input;
                  }
                }
              "
            />

            <div
              v-if="
                formData.are_you_work_on_another_platform.customer_input ||
                formData.are_you_work_on_another_platform.call_agent_input
              "
            >
              <!-- <InputLabel copyable="true"
                  label="What other platforms are you working in?"
                 
                  :value="formData.other_platforms_working_on.customer_input"
                  styles="mt-4"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  v-if="formData.other_platforms_working_on.is_changed_by_call_agent"
                  label="What other platforms are you working in?"
                  :value="formData.other_platforms_working_on.call_agent_input"
                  styles="mt-5"
                  class="non-interactable-input"
                 
                /> -->
              <!-- other_platforms_working - customer inputs -->
              <div
                @click="
                  () => {
                    if (
                      formData.other_platforms_working_on
                        .is_changed_by_call_agent
                    ) {
                      return;
                    }
                    isworkingPlatformModalActive = true;
                  }
                "
              >
                <DisplayBulletList
                  class="non-interactable-input"
                  label="What other platforms are you working in?"
                  :items="otherPlatformWorkingOnCI"
                  :is-agent="false"
                  v-if="
                    formData.are_you_work_on_another_platform
                      .is_changed_by_call_agent
                      ? formData.are_you_work_on_another_platform
                          .call_agent_input
                      : formData.are_you_work_on_another_platform.customer_input
                  "
                />
              </div>
              <!-- other_platforms_working - agent inputs -->
              <div
                @click="
                  () => {
                    isworkingPlatformModalActive = true;
                  }
                "
              >
                <DisplayBulletList
                  class="non-interactable-input"
                  v-if="
                    (formData.are_you_work_on_another_platform
                      .is_changed_by_call_agent
                      ? formData.are_you_work_on_another_platform
                          .call_agent_input
                      : formData.are_you_work_on_another_platform
                          .customer_input) &&
                    formData.other_platforms_working_on.is_changed_by_call_agent
                  "
                  label="What other platforms are you working in?"
                  :items="
                    otherPlatformWorkingOnCAI.length > 0
                      ? otherPlatformWorkingOnCAI
                      : []
                  "
                  :is-agent="true"
                  @click="
                    () => {
                      isworkingPlatformModalActive = true;
                    }
                  "
                />
              </div>
              <!-- What other platforms MOdal -->
              <b-modal
                v-model="isworkingPlatformModalActive"
                :width="540"
                :can-cancel="false"
              >
                <div class="box">
                  <div class="is-pulled-right">
                    <button
                      class="delete mb-2"
                      aria-label="close"
                      @click="
                        () => {
                          if (
                            formData.other_platforms_working_on
                              .is_changed_by_call_agent
                          ) {
                            otherPlatformWorkingOnCAI =
                              formData.other_platforms_working_on
                                .call_agent_input;
                          } else {
                            otherPlatformWorkingOnCI =
                              formData.other_platforms_working_on
                                .customer_input;
                          }
                          isworkingPlatformModalActive = false;
                        }
                      "
                    ></button>
                  </div>
                  <div>
                    <label class="is-size-5"
                      >What other platforms are you working in?</label
                    >
                    <div
                      v-if="
                        !formData.other_platforms_working_on
                          .is_changed_by_call_agent
                      "
                    >
                      <div class="columns source-list">
                        <div
                          class="source-list-item"
                          v-for="(work, index) in workeList"
                          :key="index"
                        >
                          <b-field>
                            <b-checkbox
                              class="en-style"
                              :native-value="work"
                              v-model="otherPlatformWorkingOnCI"
                            >
                              {{ work }}
                            </b-checkbox>
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        formData.other_platforms_working_on
                          .is_changed_by_call_agent
                      "
                    >
                      <div class="columns source-list">
                        <div
                          class="source-list-item"
                          v-for="(work, index) in workeList"
                          :key="index"
                        >
                          <b-field>
                            <b-checkbox
                              class="en-style"
                              :native-value="work"
                              v-model="otherPlatformWorkingOnCAI"
                            >
                              {{ work }}
                            </b-checkbox>
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      @click="
                        () => {
                          if (
                            formData.other_platforms_working_on
                              .is_changed_by_call_agent
                          ) {
                            formData.other_platforms_working_on.call_agent_input =
                              otherPlatformWorkingOnCAI;

                            if (
                              !(formData
                                .other_platforms_working_on_other_selected
                                .is_changed_by_call_agent
                                ? formData
                                    .other_platforms_working_on_other_selected
                                    .call_agent_input
                                : formData
                                    .other_platforms_working_on_other_selected
                                    .customer_input) &&
                              otherPlatformWorkingOnCAI.includes(`Other`)
                            ) {
                              formData.other_platforms_working_on_other_selected.call_agent_input = true;
                              formData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;

                              formData.other_platforms_working_on_other_description.call_agent_input = ``;
                              formData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                              otherPlatformDescriptionCAI = ``;
                            }
                          } else {
                            formData.other_platforms_working_on.call_agent_input =
                              otherPlatformWorkingOnCI;

                            if (
                              !(formData
                                .other_platforms_working_on_other_selected
                                .is_changed_by_call_agent
                                ? formData
                                    .other_platforms_working_on_other_selected
                                    .call_agent_input
                                : formData
                                    .other_platforms_working_on_other_selected
                                    .customer_input) &&
                              otherPlatformWorkingOnCI.includes(`Other`)
                            ) {
                              formData.other_platforms_working_on_other_selected.call_agent_input = true;
                              formData.other_platforms_working_on_other_selected.is_changed_by_call_agent = true;

                              formData.other_platforms_working_on_other_description.call_agent_input = ``;
                              formData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                              otherPlatformDescriptionCAI = ``;
                            }
                            otherPlatformWorkingOnCAI =
                              otherPlatformWorkingOnCI;
                            otherPlatformWorkingOnCI =
                              formData.other_platforms_working_on
                                .customer_input;
                          }

                          formData.other_platforms_working_on.is_changed_by_call_agent = true;
                          if (
                            JSON.stringify(
                              formData.other_platforms_working_on
                                .call_agent_input
                            ) ===
                            JSON.stringify(
                              formData.other_platforms_working_on.customer_input
                            )
                          ) {
                            formData.other_platforms_working_on.call_agent_input =
                              null;
                            formData.other_platforms_working_on.is_changed_by_call_agent = false;
                          }
                          isworkingPlatformModalActive = false;
                        }
                      "
                      >Save
                    </b-button>
                  </div>
                </div>
              </b-modal>
              <br />
              <div
                v-if="
                  formData.other_platforms_working_on_other_selected
                    .is_changed_by_call_agent
                    ? formData.other_platforms_working_on_other_selected
                        .call_agent_input
                    : formData.other_platforms_working_on_other_selected
                        .customer_input
                "
              >
                <InputLabel copyable="true"
                  label="Please Specify"
                  styles="mt-4"
                  v-model="otherPlatformDescriptionCI"
                  :class="
                    formData.other_platforms_working_on_other_description
                      .is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
                <AgentInputField copyable="true"
                  label="Please Specify"
                  v-model="otherPlatformDescriptionCAI"
                  styles="mt-5"
                  v-if="
                    formData.other_platforms_working_on_other_description
                      .is_changed_by_call_agent
                  "
                />
                <AgentInputButtons
                  :show-buttons="
                    formData.other_platforms_working_on_other_description
                      .is_changed_by_call_agent
                      ? formData.other_platforms_working_on_other_description
                          .call_agent_input !== otherPlatformDescriptionCAI
                      : formData.other_platforms_working_on_other_description
                          .customer_input !== otherPlatformDescriptionCI
                  "
                  :save="
                    () => {
                      if (
                        formData.other_platforms_working_on_other_description
                          .is_changed_by_call_agent
                      ) {
                        formData.other_platforms_working_on_other_description.call_agent_input =
                          otherPlatformDescriptionCAI;
                      } else {
                        formData.other_platforms_working_on_other_description.call_agent_input =
                          otherPlatformDescriptionCI;
                        otherPlatformDescriptionCAI =
                          otherPlatformDescriptionCI;
                        otherPlatformDescriptionCI =
                          formData.other_platforms_working_on_other_description
                            .customer_input;
                      }
                      formData.other_platforms_working_on_other_description.is_changed_by_call_agent = true;
                      if (
                        formData.other_platforms_working_on_other_description
                          .call_agent_input ===
                        formData.other_platforms_working_on_other_description
                          .customer_input
                      ) {
                        formData.other_platforms_working_on_other_description.call_agent_input = ``;
                        formData.other_platforms_working_on_other_description.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (
                        formData.other_platforms_working_on_other_description
                          .is_changed_by_call_agent
                      ) {
                        otherPlatformDescriptionCAI =
                          formData.other_platforms_working_on_other_description
                            .call_agent_input;
                      } else {
                        otherPlatformDescriptionCI =
                          formData.other_platforms_working_on_other_description
                            .customer_input;
                      }
                    }
                  "
                />
              </div>
            </div>
          </div>

          <div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <!-- Tax Details -->
            <p>Tax Details</p>
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <SelectLabel
                  label="Are You a Taxpayer?"
                  v-model="taxPayerEditCI"
                  :selectOptions="yesNoList"
                  :class="
                    formData.are_you_a_tax_payer.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
  
                <AgentSelectLabel
                  label="Are You a Taxpayer?"
                  v-model="taxPayerEditCAI"
                  :selectOptions="yesNoList"
                  styles="mt-5"
                  :hide="!formData.are_you_a_tax_payer.is_changed_by_call_agent"
                />
  
                <AgentInputButtons
                  :show-buttons="
                    formData.are_you_a_tax_payer.is_changed_by_call_agent
                      ? formData.are_you_a_tax_payer.call_agent_input !==
                        taxPayerEditCAI
                      : formData.are_you_a_tax_payer.customer_input !==
                        taxPayerEditCI
                  "
                  :save="
                    () => {
                      if (formData.are_you_a_tax_payer.is_changed_by_call_agent) {
                        formData.are_you_a_tax_payer.call_agent_input =
                          taxPayerEditCAI;
  
                        if (!taxPayerEditCAI) {
                          formData.tax_file_numer.call_agent_input = ``;
                          formData.tax_file_numer.is_changed_by_call_agent = true;
                          taxNoEditCAI = ``;
                        }
                      } else {
                        formData.are_you_a_tax_payer.call_agent_input =
                          taxPayerEditCI;
  
                        if (!taxPayerEditCI) {
                          formData.tax_file_numer.call_agent_input = ``;
                          formData.tax_file_numer.is_changed_by_call_agent = true;
                          taxNoEditCAI = ``;
                        }
                        taxPayerEditCAI = taxPayerEditCI;
                        taxPayerEditCI =
                          formData.are_you_a_tax_payer.customer_input;
                      }
                      formData.are_you_a_tax_payer.is_changed_by_call_agent = true;
                      if (
                        formData.are_you_a_tax_payer.call_agent_input ===
                        formData.are_you_a_tax_payer.customer_input
                      ) {
                        formData.are_you_a_tax_payer.call_agent_input = ``;
                        formData.are_you_a_tax_payer.is_changed_by_call_agent = false;
                      }
                    }
                  "
                  :cancel="
                    () => {
                      if (formData.are_you_a_tax_payer.is_changed_by_call_agent) {
                        taxPayerEditCAI =
                          formData.are_you_a_tax_payer.call_agent_input;
                      } else {
                        taxPayerEditCI =
                          formData.are_you_a_tax_payer.customer_input;
                      }
                    }
                  "
                />
              </div>
            </div>
          </div>
          <div
            v-if="
              formData.are_you_a_tax_payer.is_changed_by_call_agent
                ? formData.are_you_a_tax_payer.call_agent_input
                : formData.are_you_a_tax_payer.customer_input
            "
          >
            <InputLabel copyable="true"
              label="Tax File Number"
              placeholder=" -- No data -- "
              v-model="taxNoEditCI"
              :class="
                formData.tax_file_numer.is_changed_by_call_agent
                  ? `non-interactable-input`
                  : ``
              "
            />
            <AgentInputField copyable="true"
              label="Tax File Number"
              v-model="taxNoEditCAI"
              styles="mt-5"
              :hide="!formData.tax_file_numer.is_changed_by_call_agent"
            />
            <AgentInputButtons
              :show-buttons="
                formData.tax_file_numer.is_changed_by_call_agent
                  ? formData.tax_file_numer.call_agent_input !== taxNoEditCAI
                  : formData.tax_file_numer.customer_input !== taxNoEditCI
              "
              :save="
                () => {
                  if (formData.tax_file_numer.is_changed_by_call_agent) {
                    formData.tax_file_numer.call_agent_input = taxNoEditCAI;
                  } else {
                    formData.tax_file_numer.call_agent_input = taxNoEditCI;
                    taxNoEditCAI = taxNoEditCI;
                    taxNoEditCI = formData.tax_file_numer.customer_input;
                  }
                  formData.tax_file_numer.is_changed_by_call_agent = true;
                  if (
                    formData.tax_file_numer.call_agent_input ===
                    formData.tax_file_numer.customer_input
                  ) {
                    formData.tax_file_numer.call_agent_input = ``;
                    formData.tax_file_numer.is_changed_by_call_agent = false;
                  }
                }
              "
              :cancel="
                () => {
                  if (formData.tax_file_numer.is_changed_by_call_agent) {
                    taxNoEditCAI = formData.tax_file_numer.call_agent_input;
                  } else {
                    taxNoEditCI = formData.tax_file_numer.customer_input;
                  }
                }
              "
            />
          </div>
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <p>PEP Declaration</p>
          <div>
            <!--          New way of displaying pep details - start -->
            <!-- <InputLabel copyable="true"
              label="Are You or Your Close Relative a Politically Exposed Person?"
              :value="
                GlobalFunctions.getPepSelectionDisplayValue(
                  formData.pep_declaration
                )
              "
              styles="mt-4"
              class="non-interactable-input"
            /> -->

            <SelectLabel
              label="Are You or Your Close Relative a Politically Exposed Person?"
              v-model="areYouOrCloseRelativeCI"
              :selectOptions="pepList"
              :class="
                formData.pep_declaration.is_changed_by_call_agent
                  ? `non-interactable-input`
                  : ``
              "
            />

            <AgentSelectLabel
              label="Are You or Your Close Relative a Politically Exposed Person?"
              v-model="areYouOrCloseRelativeCAI"
              :selectOptions="pepList"
              styles="mt-5"
              :hide="!formData.pep_declaration.is_changed_by_call_agent"
            />

            <AgentInputButtons
              :show-buttons="
                formData.pep_declaration.is_changed_by_call_agent
                  ? areYouOrCloseRelativeCAIPermanent !==
                    areYouOrCloseRelativeCAI
                  : areYouOrCloseRelativeCIPermanent !== areYouOrCloseRelativeCI
              "
              :save="
                () => {
                  if (formData.pep_declaration.is_changed_by_call_agent) {
                    if (
                      areYouOrCloseRelativeCAI ===
                        areYouOrCloseRelativeCIPermanent &&
                      JSON.stringify(pepPersonDetailsCAI) ===
                        JSON.stringify(
                          formData.pep_declaration.customer_input
                            .pep_person_details
                        )
                    ) {
                      formData.pep_declaration.call_agent_input = null;
                      formData.pep_declaration.is_changed_by_call_agent = false;
                      pepPersonDetailsCAI = [];
                    } else {
                      if (areYouOrCloseRelativeCAI === `I`) {
                        formData.pep_declaration.call_agent_input = {
                          iam_politically_exposed: true,
                          close_relative_politically_exposed: false,
                          not_politically_exposed: false,
                          pep_person_details: [
                            {
                              name: ``,
                              designation: ``,
                            },
                            {
                              name: ``,
                              designation: ``,
                            },
                          ],
                        };
                        pepPersonDetailsCAI = [
                          {
                            name: ``,
                            designation: ``,
                          },
                          {
                            name: ``,
                            designation: ``,
                          },
                        ];
                      } else if (areYouOrCloseRelativeCAI === `Relative`) {
                        formData.pep_declaration.call_agent_input = {
                          iam_politically_exposed: false,
                          close_relative_politically_exposed: true,
                          not_politically_exposed: false,
                          pep_person_details: [
                            {
                              name: ``,
                              designation: ``,
                            },
                            {
                              name: ``,
                              designation: ``,
                            },
                          ],
                        };
                        pepPersonDetailsCAI = [
                          {
                            name: ``,
                            designation: ``,
                          },
                          {
                            name: ``,
                            designation: ``,
                          },
                        ];
                      } else if (areYouOrCloseRelativeCAI === `No`) {
                        formData.pep_declaration.call_agent_input = {
                          iam_politically_exposed: false,
                          close_relative_politically_exposed: false,
                          not_politically_exposed: true,
                          pep_person_details: [],
                        };
                        pepPersonDetailsCAI = [];
                      }
                      areYouOrCloseRelativeCAIPermanent =
                        areYouOrCloseRelativeCAI;
                    }
                  } else {
                    if (
                      areYouOrCloseRelativeCI ===
                        areYouOrCloseRelativeCIPermanent &&
                      JSON.stringify(pepPersonDetailsCI) ===
                        JSON.stringify(
                          formData.pep_declaration.customer_input
                            .pep_person_details
                        )
                    ) {
                      formData.pep_declaration.call_agent_input = null;
                      formData.pep_declaration.is_changed_by_call_agent = false;
                      pepPersonDetailsCAI = [];
                    } else {
                      if (areYouOrCloseRelativeCI === `I`) {
                        formData.pep_declaration.call_agent_input = {
                          iam_politically_exposed: true,
                          close_relative_politically_exposed: false,
                          not_politically_exposed: false,
                          pep_person_details: [
                            {
                              name: ``,
                              designation: ``,
                            },
                            {
                              name: ``,
                              designation: ``,
                            },
                          ],
                        };
                        pepPersonDetailsCAI = [
                          {
                            name: ``,
                            designation: ``,
                          },
                          {
                            name: ``,
                            designation: ``,
                          },
                        ];
                      } else if (areYouOrCloseRelativeCI === `Relative`) {
                        formData.pep_declaration.call_agent_input = {
                          iam_politically_exposed: false,
                          close_relative_politically_exposed: true,
                          not_politically_exposed: false,
                          pep_person_details: [
                            {
                              name: ``,
                              designation: ``,
                            },
                            {
                              name: ``,
                              designation: ``,
                            },
                          ],
                        };

                        pepPersonDetailsCAI = [
                          {
                            name: ``,
                            designation: ``,
                          },
                          {
                            name: ``,
                            designation: ``,
                          },
                        ];
                      } else if (areYouOrCloseRelativeCI === `No`) {
                        formData.pep_declaration.call_agent_input = {
                          iam_politically_exposed: false,
                          close_relative_politically_exposed: false,
                          not_politically_exposed: true,
                          pep_person_details: [],
                        };
                        pepPersonDetailsCAI = [];
                      }
                      formData.pep_declaration.is_changed_by_call_agent = true;
                      areYouOrCloseRelativeCAI = areYouOrCloseRelativeCI;
                      areYouOrCloseRelativeCAIPermanent =
                        areYouOrCloseRelativeCI;
                      areYouOrCloseRelativeCI =
                        areYouOrCloseRelativeCIPermanent;
                    }
                  }
                }
              "
              :cancel="
                () => {
                  if (formData.pep_declaration.is_changed_by_call_agent) {
                    areYouOrCloseRelativeCAI =
                      areYouOrCloseRelativeCAIPermanent;
                  } else {
                    areYouOrCloseRelativeCI = areYouOrCloseRelativeCIPermanent;
                  }
                }
              "
            />
            <!--          New way of displaying pep details - end -->

            <!--            TODO :: recheck and remove below commented lines - LR -->

            <!--            <InputLabel copyable="true"-->
            <!--              v-if="formData.pep_declaration.customer_input.not_politically_exposed"-->
            <!--              label="Are You or Your Close Relative a Politically Exposed Person?"-->
            <!--             -->
            <!--              :value="formData.pep_declaration.customer_input.not_politically_exposed ? 'No' : 'Yes'"-->
            <!--              styles="mt-4"-->
            <!--              class="non-interactable-input"-->
            <!--            />-->
            <!--            <InputLabel copyable="true"-->
            <!--              v-if="formData.pep_declaration.customer_input.close_relative_politically_exposed"-->
            <!--              label="Are You or Your Close Relative a Politically Exposed Person?"-->
            <!--             -->
            <!--              :value="formData.pep_declaration.customer_input.close_relative_politically_exposed ? 'Yes : Close Relative Politically Exposed' : 'No'  "-->
            <!--              styles="mt-4"-->
            <!--              class="non-interactable-input"-->
            <!--            />-->
            <!--            <InputLabel copyable="true"-->
            <!--              v-if="formData.pep_declaration.customer_input.iam_politically_exposed"-->
            <!--              label="Are You or Your Close Relative a Politically Exposed Person?"-->
            <!--             -->
            <!--              :value="formData.pep_declaration.customer_input.iam_politically_exposed ? 'Yes : I am Politically Exposed': 'No'"-->
            <!--              styles="mt-4"-->
            <!--              class="non-interactable-input"-->
            <!--            />-->
            <!--            <div v-if="formData.pep_declaration.is_changed_by_call_agent">-->
            <!--              <AgentInputField copyable="true"-->
            <!--                v-if="formData.pep_declaration.call_agent_input.not_politically_exposed"-->
            <!--                label="Are You or Your Close Relative a Politically Exposed Person?"-->
            <!--                :value="formData.pep_declaration.call_agent_input.not_politically_exposed ? 'No' : 'Yes'"-->
            <!--                styles="mt-5"-->
            <!--                :hide="!formData.pep_declaration.is_changed_by_call_agent"-->
            <!--               -->
            <!--                class="non-interactable-input"-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div v-if="formData.pep_declaration.is_changed_by_call_agent">-->
            <!--              <AgentInputField copyable="true"-->
            <!--                v-if="formData.pep_declaration.call_agent_input.close_relative_politically_exposed"-->
            <!--                label="Are You or Your Close Relative a Politically Exposed Person?"-->
            <!--                :value="formData.pep_declaration.call_agent_input.close_relative_politically_exposed ? 'Yes : Close Relative Politically Exposed': 'No'"-->
            <!--                styles="mt-5"-->
            <!--                :hide="!formData.pep_declaration.is_changed_by_call_agent"-->
            <!--               -->
            <!--                class="non-interactable-input"-->
            <!--              />-->
            <!--            </div>-->
            <!--            <div v-if="formData.pep_declaration.is_changed_by_call_agent">-->
            <!--              <AgentInputField copyable="true"-->
            <!--                v-if="formData.pep_declaration.call_agent_input.iam_politically_exposed"-->
            <!--                label="Are You or Your Close Relative a Politically Exposed Person?"-->
            <!--                :value="formData.pep_declaration.call_agent_input.iam_politically_exposed ? 'Yes : I am Politically Exposed' : 'No'"-->
            <!--                styles="mt-5"-->
            <!--                :hide="!formData.pep_declaration.is_changed_by_call_agent"-->
            <!--               -->
            <!--                class="non-interactable-input"-->
            <!--              />-->
            <!--            </div>-->
          </div>
          <br />
          <div
            v-if="
              formData.pep_declaration.is_changed_by_call_agent
                ? !formData.pep_declaration.call_agent_input
                    .not_politically_exposed
                : !formData.pep_declaration.customer_input
                    .not_politically_exposed
            "
          >
            <div
              class="columns is-7 is-variable"
              v-for="(pep_detail, index) in pepPersonDetailsCI"
              :key="index"
            >
              <div class="column">
                <InputLabel copyable="true"
                  label="Name"
                  v-model="pepPersonDetailsCI[index].name"
                  :class="
                    formData.pep_declaration.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  label="Designation"
                  v-model="pepPersonDetailsCI[index].designation"
                  :class="
                    formData.pep_declaration.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                />
              </div>
            </div>

            <div v-if="formData.pep_declaration.is_changed_by_call_agent">
              <div
                class="columns is-7 is-variable"
                v-for="(pep_detail, index) in pepPersonDetailsCAI"
                :key="index"
              >
                <div class="column">
                  <AgentInputField copyable="true"
                    label="Name"
                    v-model="pepPersonDetailsCAI[index].name"
                    styles="mt-5"
                  />
                </div>
                <div class="column">
                  <AgentInputField copyable="true"
                    label="Designation"
                    v-model="pepPersonDetailsCAI[index].designation"
                    styles="mt-5"
                  />
                </div>
              </div>
            </div>

            <AgentInputButtons
              :show-buttons="
                formData.pep_declaration.is_changed_by_call_agent
                  ? JSON.stringify(pepPersonDetailsCAI) !==
                    JSON.stringify(
                      formData.pep_declaration.call_agent_input
                        .pep_person_details
                    )
                  : JSON.stringify(pepPersonDetailsCI) !==
                    JSON.stringify(
                      formData.pep_declaration.customer_input.pep_person_details
                    )
              "
              :save="
                () => {
                  if (formData.pep_declaration.is_changed_by_call_agent) {
                    formData.pep_declaration.call_agent_input.pep_person_details =
                      pepPersonDetailsCAI.map((pep) => {
                        return { ...pep };
                      });
                  } else {
                    formData.pep_declaration.call_agent_input = {
                      ...formData.pep_declaration.customer_input,
                    };
                    formData.pep_declaration.call_agent_input.pep_person_details =
                      pepPersonDetailsCI.map((pep) => {
                        return { ...pep };
                      });
                    pepPersonDetailsCAI = pepPersonDetailsCI.map((pep) => {
                      return { ...pep };
                    });

                    pepPersonDetailsCI =
                      formData.pep_declaration.customer_input.pep_person_details.map(
                        (pep) => {
                          return { ...pep };
                        }
                      );
                    areYouOrCloseRelativeCAI = areYouOrCloseRelativeCIPermanent;
                    areYouOrCloseRelativeCAIPermanent =
                      areYouOrCloseRelativeCAI;

                    formData.pep_declaration.is_changed_by_call_agent = true;
                  }

                  if (
                    JSON.stringify(
                      formData.pep_declaration.call_agent_input
                    ) ===
                    JSON.stringify(formData.pep_declaration.customer_input)
                  ) {
                    formData.pep_declaration.call_agent_input = null;
                    formData.pep_declaration.is_changed_by_call_agent = false;
                    pepPersonDetailsCAI = [];
                  }
                }
              "
              :cancel="
                () => {
                  if (formData.pep_declaration.is_changed_by_call_agent) {
                    pepPersonDetailsCAI =
                      formData.pep_declaration.call_agent_input.pep_person_details.map(
                        (pep) => {
                          return { ...pep };
                        }
                      );
                  } else {
                    pepPersonDetailsCAI =
                      formData.pep_declaration.customer_input.pep_person_details.map(
                        (pep) => {
                          return { ...pep };
                        }
                      );
                  }
                }
              "
            />

            <div></div>
          </div>
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <p>Financial Details</p>

          <!-- source of funds - customer inputs -->
          <div
            @click="
              () => {
                if (formData.source_of_funds.is_changed_by_call_agent) {
                  return;
                }
                isSourceModalActive = true;
              }
            "
          >
            <DisplayBulletList
              class="non-interactable-input"
              label="Source of Funds"
              :items="sourceofincomeCI"
              :is-agent="false"
            />
          </div>

          <!-- source of funds - agent inputs -->
          <div
            @click="
              () => {
                isSourceModalActive = true;
              }
            "
          >
            <DisplayBulletList
              class="non-interactable-input"
              v-if="formData.source_of_funds.call_agent_input != null"
              label="Source of Funds"
              :items="sourceofincomeCAI ? sourceofincomeCAI : []"
              :is-agent="true"
            />
          </div>
          <!-- source of fund modal -->
          <b-modal
            v-model="isSourceModalActive"
            :width="540"
            :can-cancel="false"
          >
            <div class="box">
              <div class="is-pulled-right">
                <button
                  class="delete mb-2"
                  aria-label="close"
                  @click="
                    () => {
                      if (formData.source_of_funds.is_changed_by_call_agent) {
                        sourceofincomeCAI =
                          formData.source_of_funds.call_agent_input;
                      } else {
                        sourceofincomeCI =
                          formData.source_of_funds.customer_input;
                      }
                      isSourceModalActive = false;
                    }
                  "
                ></button>
              </div>
              <div>
                <label class="is-size-5">Source of Funds</label>
                <div v-if="!formData.source_of_funds.is_changed_by_call_agent">
                  <div class="columns source-list">
                    <div
                      class="source-list-item"
                      v-for="(source, index) in sourceOfFundList"
                      :key="index"
                    >
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          :native-value="source"
                          v-model="sourceofincomeCI"
                        >
                          {{ source }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div v-if="formData.source_of_funds.is_changed_by_call_agent">
                  <div class="columns source-list">
                    <div
                      class="source-list-item"
                      v-for="(source, index) in sourceOfFundList"
                      :key="index"
                    >
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          :native-value="source"
                          v-model="sourceofincomeCAI"
                        >
                          {{ source }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="is-flex is-justify-content-flex-end is-align-content-flex-end"
              >
                <b-button
                  class="is-primary is-rounded save_button"
                  @click="
                    () => {
                      if (formData.source_of_funds.is_changed_by_call_agent) {
                        if (
                          (formData.source_of_funds.call_agent_input.includes(
                            `Other`
                          ) &&
                            !sourceofincomeCAI.includes(`Other`)) ||
                          (!formData.source_of_funds.call_agent_input.includes(
                            `Other`
                          ) &&
                            sourceofincomeCAI.includes(`Other`))
                        ) {
                          formData.source_of_funds_other_description.is_changed_by_call_agent = true;
                          formData.source_of_funds_other_description.call_agent_input = ``;
                          otherSourceEditCAI = ``;
                        }

                        formData.source_of_funds.call_agent_input =
                          sourceofincomeCAI;
                      } else {
                        if (
                          (formData.source_of_funds.customer_input.includes(
                            `Other`
                          ) &&
                            !sourceofincomeCI.includes(`Other`)) ||
                          (!formData.source_of_funds.customer_input.includes(
                            `Other`
                          ) &&
                            sourceofincomeCI.includes(`Other`))
                        ) {
                          formData.source_of_funds_other_description.is_changed_by_call_agent = true;
                          formData.source_of_funds_other_description.call_agent_input = ``;
                          otherSourceEditCAI = ``;
                        }
                        formData.source_of_funds.call_agent_input =
                          sourceofincomeCI;

                        sourceofincomeCAI = sourceofincomeCI;
                        sourceofincomeCI =
                          formData.source_of_funds.customer_input;
                      }

                      formData.source_of_funds.is_changed_by_call_agent = true;
                      if (
                        JSON.stringify(
                          formData.source_of_funds.call_agent_input
                        ) ===
                        JSON.stringify(formData.source_of_funds.customer_input)
                      ) {
                        formData.source_of_funds.call_agent_input = null;
                        formData.source_of_funds.is_changed_by_call_agent = false;
                      }
                      isSourceModalActive = false;
                    }
                  "
                  >Save
                </b-button>
              </div>
            </div>
          </b-modal>

          <br />
          <div
            v-if="
              formData.source_of_funds.is_changed_by_call_agent
                ? formData.source_of_funds.call_agent_input.includes(`Other`)
                : formData.source_of_funds.customer_input.includes(`Other`)
            "
          >
            <InputLabel copyable="true"
              label="Please Specify"
              v-model="otherSourceEditCI"
              styles="mt-4"
              :class="
                formData.source_of_funds_other_description
                  .is_changed_by_call_agent
                  ? `non-interactable-input`
                  : ``
              "
            />
            <AgentInputField copyable="true"
              label="Please Specify"
              v-model="otherSourceEditCAI"
              styles="mt-5"
              :hide="
                !formData.source_of_funds_other_description
                  .is_changed_by_call_agent
              "
            />
            <AgentInputButtons
              :show-buttons="
                formData.source_of_funds_other_description
                  .is_changed_by_call_agent
                  ? formData.source_of_funds_other_description
                      .call_agent_input !== otherSourceEditCAI
                  : formData.source_of_funds_other_description
                      .customer_input !== otherSourceEditCI
              "
              :save="
                () => {
                  if (
                    formData.source_of_funds_other_description
                      .is_changed_by_call_agent
                  ) {
                    formData.source_of_funds_other_description.call_agent_input =
                      otherSourceEditCAI;
                  } else {
                    formData.source_of_funds_other_description.call_agent_input =
                      otherSourceEditCI;
                    otherSourceEditCAI = otherSourceEditCI;
                    otherSourceEditCI =
                      formData.source_of_funds_other_description.customer_input;
                  }
                  formData.source_of_funds_other_description.is_changed_by_call_agent = true;
                  if (
                    formData.source_of_funds_other_description
                      .call_agent_input ===
                    formData.source_of_funds_other_description.customer_input
                  ) {
                    formData.source_of_funds_other_description.call_agent_input = ``;
                    formData.source_of_funds_other_description.is_changed_by_call_agent = false;
                  }
                }
              "
              :cancel="
                () => {
                  if (
                    formData.source_of_funds_other_description
                      .is_changed_by_call_agent
                  ) {
                    otherSourceEditCAI =
                      formData.source_of_funds_other_description
                        .call_agent_input;
                  } else {
                    otherSourceEditCI =
                      formData.source_of_funds_other_description.customer_input;
                  }
                }
              "
            />
          </div>
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <p>Branch Details</p>

          <div>
            <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
              <b-field
                label-position="inside"
                :custom-class="
                  branchCI === '' && !isBranchInputFocus
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
                class="mb-0"
              >
                <template #label>Preferred Branch</template>

                <b-select
                  expanded
                  rounded
                  v-model="branchCI"
                  :class="
                    formData.branch_id.is_changed_by_call_agent
                      ? `non-interactable-input`
                      : ``
                  "
                  @focus="
                    () => {
                      isBranchInputFocus = true;
                    }
                  "
                  @blur="
                    () => {
                      isBranchInputFocus = false;
                    }
                  "
                >
                  <option
                    v-for="branch in $root.formDataSupport.branchesArray"
                    :key="branch.id"
                    :value="branch.id"
                  >
                    {{ branch.branch_name }}
                  </option>
                </b-select>
              </b-field>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div
            v-if="formData.branch_id.is_changed_by_call_agent"
            class="mt-5 agent"
          >
            <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
              <b-field
                label-position="inside"
                :custom-class="
                  branchCAI === '' && !isBranchInputFocusCAI
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
                class="mb-0"
              >
                <template #label>Preferred Branch</template>

                <b-select
                  expanded
                  rounded
                  v-model="branchCAI"
                  @focus="
                    () => {
                      isBranchInputFocusCAI = true;
                    }
                  "
                  @blur="
                    () => {
                      isBranchInputFocusCAI = false;
                    }
                  "
                >
                  <option
                    v-for="branch in $root.formDataSupport.branchesArray"
                    :key="branch.id"
                    :value="branch.id"
                  >
                    {{ branch.branch_name }}
                  </option>
                </b-select>
              </b-field>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <AgentInputButtons
            :show-buttons="
              formData.branch_id.is_changed_by_call_agent
                ? formData.branch_id.call_agent_input !== branchCAI
                : formData.branch_id.customer_input !== branchCI
            "
            :save="
              () => {
                if (formData.branch_id.is_changed_by_call_agent) {
                  formData.branch_id.call_agent_input = branchCAI;
                } else {
                  formData.branch_id.call_agent_input = branchCI;
                  branchCAI = branchCI;
                  branchCI = formData.branch_id.customer_input;
                }
                formData.branch_id.is_changed_by_call_agent = true;
                if (
                  formData.branch_id.call_agent_input ===
                  formData.branch_id.customer_input
                ) {
                  formData.branch_id.call_agent_input = ``;
                  formData.branch_id.is_changed_by_call_agent = false;
                }

                formData.branch_comment.call_agent_input = ``;
                formData.branch_comment.is_changed_by_call_agent = true;
                branchCommentEditCAI = ``;
              }
            "
            :cancel="
              () => {
                if (formData.branch_id.is_changed_by_call_agent) {
                  branchCAI = formData.branch_id.call_agent_input;
                } else {
                  branchCI = formData.branch_id.customer_input;
                }
              }
            "
          />

          <br />
          <InputLabel copyable="true"
            v-if="formData.branch_comment.customer_input != ''"
            label=" Reason for opening an account in a branch that is located far from the permanent address"
            v-model="branchCommentEditCI"
            :class="
              formData.branch_comment.is_changed_by_call_agent
                ? `non-interactable-input`
                : ``
            "
          />

          <AgentInputField copyable="true"
            v-if="formData.branch_comment.is_changed_by_call_agent"
            label="Reason for opening an account in a branch that is located far from the permanent address"
            v-model="branchCommentEditCAI"
            styles="mt-5"
          />
          <AgentInputButtons
            :show-buttons="
              formData.branch_comment.is_changed_by_call_agent
                ? formData.branch_comment.call_agent_input !==
                  branchCommentEditCAI
                : formData.branch_comment.customer_input !== branchCommentEditCI
            "
            :save="
              () => {
                if (formData.branch_comment.is_changed_by_call_agent) {
                  formData.branch_comment.call_agent_input =
                    branchCommentEditCAI;
                } else {
                  formData.branch_comment.call_agent_input =
                    branchCommentEditCI;
                  branchCommentEditCAI = branchCommentEditCI;
                  branchCommentEditCI = formData.branch_comment.customer_input;
                }
                formData.branch_comment.is_changed_by_call_agent = true;
                if (
                  formData.branch_comment.call_agent_input ===
                  formData.branch_comment.customer_input
                ) {
                  formData.branch_comment.call_agent_input = ``;
                  formData.branch_comment.is_changed_by_call_agent = false;
                }
              }
            "
            :cancel="
              () => {
                if (formData.branch_comment.is_changed_by_call_agent) {
                  branchCommentEditCAI =
                    formData.branch_comment.call_agent_input;
                } else {
                  branchCommentEditCI = formData.branch_comment.customer_input;
                }
              }
            "
          />
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <!-- Product Selection -->

          <div v-for="product in products" :key="product.id">
            <p>
              Product Selection -
              <span>{{
                product.account_type.is_changed_by_call_agent
                  ? product.account_type.call_agent_input
                  : product.account_type.customer_input
              }}</span>
            </p>
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel copyable="true"
                  v-if="product.currency_type.customer_input"
                  label="Currency"
                  placeholder=" -- No data -- "
                  readonly="true"
                  :value="product.currency_type.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Currency"
                  :value="product.currency_type.call_agent_input"
                  styles="mt-5"
                  :hide="!product.currency_type.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <!-- saving_account_type -->
              <div class="column">
                <InputLabel copyable="true"
                  v-if="product.account_type.customer_input"
                  label="Account"
                  placeholder=" -- No data -- "
                  readonly="true"
                  :value="product.account_type.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Account"
                  :value="product.account_type.call_agent_input"
                  styles="mt-5"
                  :hide="!product.account_type.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <!-- Purpose of Account Opening -->
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <!-- purpose of opening account - customer inputs -->

                <DisplayBulletList
                  class="non-interactable-input"
                  label="Purpose of Account Opening"
                  :items="product.purpose_of_opening_customer_inputs"
                  :is-agent="false"
                />

                <!-- purpose of opening account - agent inputs -->
                <DisplayBulletList
                  class="non-interactable-input"
                  v-if="
                    product.purpose_of_opening_account.is_changed_by_call_agent
                  "
                  label="Purpose of Account Opening"
                  :items="product.purpose_of_opening_agent_inputs"
                  :is-agent="true"
                />

                <!-- OTHER SPECIFIED  -->
                <InputLabel copyable="true"
                  v-if="
                    product.purpose_of_opening_other_specify_customer_inputs !==
                    ''
                  "
                  label="Please Specify the Purpose"
                  readonly="true"
                  :value="
                    product.purpose_of_opening_other_specify_customer_inputs
                  "
                  styles="mt-4"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Please Specify the Purpose"
                  :value="product.purpose_of_opening_other_specify_agent_inputs"
                  styles="mt-5"
                  :hide="
                    product.purpose_of_opening_other_specify_agent_inputs == ''
                  "
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- Anticipated Volumes of Deposits from above Sources -->

            <InputLabel copyable="true"
              v-if="product.anticipated_volume.customer_input"
              label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
              placeholder=" -- No data -- "
              readonly="true"
              :value="product.anticipated_volume.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
              :value="product.anticipated_volume.call_agent_input"
              styles="mt-5"
              :hide="!product.anticipated_volume.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <!-- Initial Deposit -->
            <InputLabel copyable="true"
              v-if="product.initial_deposit_amount.customer_input"
              label="Initial Deposit"
              placeholder=" -- No data -- "
              readonly="true"
              :value="product.initial_deposit_amount.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Initial Deposit"
              :value="product.initial_deposit_amount.call_agent_input"
              styles="mt-5"
              :hide="!product.initial_deposit_amount.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <!-- Value Added Services -->
          <p>Value Added Services</p>
          <div class="columns">
            <div class="column">
              <InputLabel copyable="true"
                label="SMS Alerts"
                readonly="true"
                :value="formData.vas_sms_alert.customer_input ? 'Yes' : 'No'"
                class="non-interactable-input"
              />
            </div>

            <div class="column">
              <InputLabel copyable="true"
                label="Online Banking"
                readonly="true"
                :value="
                  formData.vas_online_banking.customer_input ? 'Yes' : 'No'
                "
                class="non-interactable-input"
              />
            </div>
          </div>
          <div class="columns is-7 is-variable">
            <div class="column is-half"  v-if="formData.residentship.customer_input == 'Sri Lanka'">
              <InputLabel copyable="true"
                label="Virtual Wallet"
                readonly="true"
                :value="
                  formData.vas_virtual_wallet.customer_input ? 'Yes' : 'No'
                "
                class="non-interactable-input"
              />
            </div>

            <div class="column is-half">
              <InputLabel copyable="true"
                label="E-Statement"
                readonly="true"
                :value="formData.vas_estatement.customer_input ? 'Yes' : 'No'"
                class="non-interactable-input"
              />
            </div>
          </div>

          <!--  debit card -->
          <div
            v-if="
              GlobalFunctions.checkDebitCardSectionVisible(
                formData.residentship,
                products
              )
            "
          >
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel copyable="true"
                  label="Debit Card"
                  readonly="true"
                  :value="formData.vas_debit_card.customer_input ? 'Yes' : 'No'"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Debit Card"
                  :value="
                    formData.vas_debit_card.call_agent_input ? 'Yes' : 'No'
                  "
                  styles="mt-5"
                  :hide="!formData.vas_debit_card.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  label="POS Required"
                  readonly="true"
                  :value="
                    formData.vas_debit_card_pos_required.customer_input
                      ? 'Yes'
                      : 'No'
                  "
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="POS Required"
                  :value="
                    formData.vas_debit_card_pos_required.call_agent_input
                      ? 'Yes'
                      : 'No'
                  "
                  styles="mt-5"
                  :hide="
                    !formData.vas_debit_card_pos_required
                      .is_changed_by_call_agent
                  "
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <!-- If costomer POS REQUIRED TRUE THIS WILL SHOW-->

              <div class="column">
                <InputLabel copyable="true"
                  v-if="formData.vas_debit_card_pos_account.customer_input"
                  label="Account to be Linked for POS Transaction"
                  readonly="true"
                  placeholder=" -- No data -- "
                  :value="formData.vas_debit_card_pos_account.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Account to be Linked for POS Transaction"
                  :value="formData.vas_debit_card_pos_account.call_agent_input"
                  styles="mt-5"
                  :hide="
                    !formData.vas_debit_card_pos_account
                      .is_changed_by_call_agent
                  "
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <InputLabel copyable="true"
              v-if="formData.vas_debit_card_name.customer_input"
              placeholder=" -- No data -- "
              label="Name to be Printed on Card"
              readonly="true"
              :value="formData.vas_debit_card_name.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Name to be Printed on Card"
              :value="formData.vas_debit_card_name.call_agent_input"
              styles="mt-5"
              :hide="!formData.vas_debit_card_name.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <!-- {{ formData.vas_debit_card_collection_details.is_changed_by_call_agent + "4" }}
              <br />
              {{ formData.vas_debit_card_collection_details.call_agent_input }} -->
            <div class="columns is-7 is-variable mt-3">
              <div class="column">
                <InputLabel copyable="true"
                  v-if="
                    formData.vas_debit_card_collection_details.customer_input
                  "
                  placeholder=" -- No data -- "
                  label="Card Collection Details"
                  readonly="true"
                  :value="
                    formData.vas_debit_card_collection_details.customer_input
                  "
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  v-if="
                    formData.vas_debit_card_collection_details
                      .is_changed_by_call_agent
                  "
                  label="Card Collection Details"
                  :value="
                    formData.vas_debit_card_collection_details.call_agent_input
                  "
                  styles="mt-5"
                  :hide="
                    !formData.vas_debit_card_collection_details
                      .is_changed_by_call_agent
                  "
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel copyable="true"
                  v-if="
                    formData.vas_debit_card_pin_collection_details
                      .customer_input
                  "
                  label="PIN Collection Details"
                  readonly="true"
                  :value="
                    formData.vas_debit_card_pin_collection_details
                      .customer_input
                  "
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  v-if="
                    formData.vas_debit_card_pin_collection_details
                      .is_changed_by_call_agent
                  "
                  label="PIN Collection Details"
                  :value="
                    formData.vas_debit_card_pin_collection_details
                      .call_agent_input
                  "
                  styles="mt-5"
                  :hide="
                    !formData.vas_debit_card_pin_collection_details
                      .is_changed_by_call_agent
                  "
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
          </div>
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
          <!-- Video Call Details -->
          <p>Video Call Details</p>
          <div class="columns is-7 is-variable mt-1">
            <div class="column">
              <InputLabel copyable="true"
                label="Preferred Date"
                readonly="true"
                :value="getFormattedDateTime(formData.video_call_date)"
                class="non-interactable-input"
              />
            </div>
            <div class="column">
              <InputLabel copyable="true"
                label="Preferred Time"
                readonly="true"
                :value="formData.video_call_time"
                class="non-interactable-input"
              />
            </div>
          </div>

          <div
            class="columns is-7 is-variable mt-1"
            v-if="
              videoCallDetail !== null && videoCallDetail.selfie_image !== null
            "
          >
            <div class="column">
              <InputLabel copyable="true"
                label="Date of Account Funding"
                readonly="true"
                :value="getFormattedDateTime(formData.date_of_account_funding)"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            /> -->
        </div>
        <!-- image part -->
        <div class="column">
          <!-- ================================================== PROOF IMAGES ID(F/B) / SELFI ================================================== -->
          <div>
            <div
              class="container"
              rounded
              style="
                width: 32vw;
                height: 68vh;
                border: 0.3vh solid #000000;
                border-radius: 25px;
                padding: 20px;
              "
            >
              <div class="wrapper">
                <div style="position: relative; z-index: 1">
                  <b-tabs
                    size="is-small"
                    type="is-toggle-rounded"
                    v-model="nicActiveTab"
                    position="is-centered"
                  >
                    <b-tab-item label="NIC Front Image">
                      <figure
                        style="
                          min-width: 28.2vw;
                          max-width: 28.2vw;
                          min-height: 56vh;
                          max-height: 56vh;
                        "
                      >
                        <div class="wrapper">
                          <img v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                            :src="button"
                            @click="idfRotateClockwise"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                              cursor: pointer;
                            "
                          />
                          <b-button v-else rounded 
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.nic_front_attachment.attachment_url, 'NIC Front')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>
                          <div
                            style="text-align: center"
                            :style="{
                              transform: 'rotate(' + idfRotateAngle + 'deg)',
                            }"
                          >
                            <span class="helper"></span>
                            <img  v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                              :src="formData.nic_front_attachment.attachment_url"
                              @error="imageplaceholder"
                              alt="NIC Front"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                            <img v-else
                              :src="pdfPlaceholder"
                              @error="imageplaceholder"
                              alt="NIC Front"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                          </div>
                        </div>
                      </figure>
                    </b-tab-item>

                    <b-tab-item label="NIC Rear Image">
                      <figure
                        style="
                          min-width: 28.2vw;
                          max-width: 28.2vw;
                          min-height: 56vh;
                          max-height: 56vh;
                        "
                      >
                        <div class="wrapper">
                          <img v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                            :src="button"
                            @click="idbRotateClockwise"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                              cursor: pointer;
                            "
                          />
                          <b-button v-else rounded 
                            icon-right=download-circle-outline type=is-primary
                            size=is-large
                            @click="downloadPdf(formData.nic_back_attachment.attachment_url, 'NIC Rear')"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;"
                          ></b-button>
                          <div
                            style="
                              height: 56vh;
                              width: 28.2vw;
                              text-align: center;
                            "
                            :style="{
                              transform: 'rotate(' + idbRotateAngle + 'deg)',
                            }"
                          >
                            <span class="helper"></span>
                            <img v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                              :src="formData.nic_back_attachment.attachment_url"
                              @error="imageplaceholder"
                              alt="NIC Rear"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                            <img v-else
                              :src="pdfPlaceholder"
                              @error="imageplaceholder"
                              alt="NIC Rear"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                          </div>
                        </div>
                      </figure>
                    </b-tab-item>

                    <b-tab-item label="Selfie Image">
                      <figure
                        style="
                          min-width: 28.2vw;
                          max-width: 28.2vw;
                          min-height: 56vh;
                          max-height: 56vh;
                        "
                      >
                        <div class="wrapper">
                          <div
                            style="
                              height: 56vh;
                              width: 28.2vw;
                              text-align: center;
                            "
                          >
                            <span class="helper"></span>
                            <img v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
                              :src="formData.selfie_attachment.attachment_url"
                              @error="imageplaceholder"
                              alt="Selfie"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                            <img v-else
                              :src="pdfPlaceholder"
                              @error="imageplaceholder"
                              alt="Selfie"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                          </div>
                        </div>
                      </figure>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
            </div>

            <div
              style="margin-top: 1%; margin-left: 2%"
              v-if="hasPermission('view-new-application-pool') && !reviewOnly"
            >
              <div v-if="DRP_showMessageBox">
                <div id="loading" class="fullscreen">
                  <CheckDRP
                    :customer_data="DRPCustomerData"
                    :drp_data="DRPData"
                    :drp_status="DRPStatus"
                    @update_DRP_internal_comment="update_DRP_internal_comment"
                    @DRP_success="DRP_success"
                    @DRP_fail="DRP_fail"
                    @Closs_DRP="Closs_DRP"
                  >
                  </CheckDRP>
                </div>
              </div>
            </div>
            <div style="margin-top: 1%; margin-left: 2%" v-else>
              <div v-if="drpAttempt !== null">
                <b-message :type="formData.drp_status === true ? 'is-success' : 'is-danger'">
                  DRP Status : {{ GlobalFunctions.getCheckedDrpStatusResultDisplayValue(formData.drp_status) }}
                </b-message>
              </div>
            </div>
          </div>
          <br />

          <!-- ================================================== PROOF PASSPORT BIO / ALTERATION / STAMP ================================================== -->
          <div v-if="formData.passport_bio_page_attachment.attachment_type != ''">
            <div style="text-align: center">
              <label>Passport Images</label>
            </div>

            <div
              class="container"
              rounded
              style="
                min-width: 32vw;
                max-width: 32vw;
                min-height: 68vh;
                max-height: 68vh;
                border: 0.3vh solid #000000;
                border-radius: 25px;
                padding: 20px;
              "
            >
              <div class="wrapper">
                <div style="position: relative; z-index: 1">
                  <b-tabs
                    type="is-toggle-rounded"
                    v-model="passportActiveTab"
                    position="is-centered"
                  >
                    <b-tab-item label="Bio Page">
                      <figure
                        style="
                          min-width: 28.2vw;
                          max-width: 28.2vw;
                          min-height: 56vh;
                          max-height: 56vh;
                        "
                      >
                        <div class="wrapper">
                          <img v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                            :src="button"
                            @click="ppbioRotateClockwise"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                              cursor: pointer;
                            "
                          />
                          <b-button v-else rounded 
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.passport_bio_page_attachment.attachment_url, 'passport_bio_page')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>
                          <div
                            style="
                              height: 56vh;
                              width: 28.2vw;
                              text-align: center;
                            "
                            :style="{
                              transform: 'rotate(' + ppbioRotateAngle + 'deg)',
                            }"
                          >
                            <span class="helper"></span>
                            <img v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                              :src="formData.passport_bio_page_attachment.attachment_url"
                              @error="imageplaceholder"
                              alt="ID Back Image"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                            <img v-else
                              :src="pdfPlaceholder"
                              @error="imageplaceholder"
                              alt="Passport Bio Page"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                          </div>
                        </div>
                      </figure>
                    </b-tab-item>

                    <b-tab-item label="Alteration Page">
                      <figure
                        style="
                          min-width: 28.2vw;
                          max-width: 28.2vw;
                          min-height: 56vh;
                          max-height: 56vh;
                        "
                      >
                        <div class="wrapper">
                          <img v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                            :src="button"
                            @click="ppaltRotateClockwise"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                              cursor: pointer;
                            "
                          />
                          <b-button v-else rounded 
                            icon-right=download-circle-outline type=is-primary
                            size=is-large
                            @click="downloadPdf(formData.passport_alteration_attachment.attachment_url, 'passport_alteration')"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                            "></b-button>
                          <div
                            style="
                              height: 54vh;
                              width: 28.2vw;
                              text-align: center;
                            "
                            :style="{
                              transform: 'rotate(' + ppaltRotateAngle + 'deg)',
                            }"
                          >
                            <span class="helper"></span>
                            <img  v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                              :src="formData.passport_alteration_attachment.attachment_url"
                              @error="imageplaceholder"
                              alt="ID Back Image"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                          </div>
                        </div>
                      </figure>
                    </b-tab-item>

                    <b-tab-item label="Stamp Page">
                      <figure
                        style="
                          min-width: 28.2vw;
                          max-width: 28.2vw;
                          min-height: 56vh;
                          max-height: 56vh;
                        "
                      >
                        <div class="wrapper">
                          <img v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                            :src="button"
                            @click="ppsRotateClockwise"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                              cursor: pointer;
                            "
                          />
                          <b-button v-else rounded 
                            icon-right=download-circle-outline type=is-primary
                            size=is-large
                            @click="downloadPdf(formData.passport_entry_stamp_attachment.attachment_url, 'passport_entry_stamp')"
                            style="
                              width: 50px;
                              position: absolute;
                              z-index: 2;
                              margin-left: 25vw;
                              margin-top: 45vh;
                              display: flex;
                            "></b-button>
                          <div
                            style="
                              height: 54vh;
                              width: 28.2vw;
                              text-align: center;
                            "
                            :style="{
                              transform: 'rotate(' + ppsRotateAngle + 'deg)',
                            }"
                          >
                            <span class="helper"></span>
                            <img v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                              :src="formData.passport_entry_stamp_attachment.attachment_url"
                              @error="imageplaceholder"
                              alt="Passport Stamp Page Image"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                            <img v-else
                              :src="pdfPlaceholder"
                              @error="imageplaceholder"
                              alt="Passport Stamp Page Image"
                              style="vertical-align: middle; max-height: 55vh"
                            />
                          </div>
                        </div>
                      </figure>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>

          <!-- visa image  -->
          <br v-if="formData.visa_attachment.attachment_url != ''" />
          <div
            v-if="formData.visa_attachment.attachment_url != ''"
            style="text-align: center"
          >
            <label>Visa image</label>
          </div>

          <div
            v-if="formData.visa_attachment != ''"
            class="container"
            rounded
            style="
              min-width: 32vw;
              max-width: 32vw;
              min-height: 60vh;
              max-height: 60vh;
              border: 0.3vh solid #000000;
              border-radius: 25px;
              padding: 20px;
            "
          >
            <div class="wrapper">
              <figure
                style="
                  min-width: 28.2vw;
                  max-width: 28.2vw;
                  min-height: 56vh;
                  max-height: 56vh;
                "
              >
                <img v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                  :src="button1"
                  @click="visarotateClockwise"
                  style="
                    width: 50px;
                    position: absolute;
                    z-index: 2;
                    margin-left: 26vw;
                    margin-top: 45vh;
                    display: flex;
                    cursor: pointer;
                  "
                />
                <b-button v-else rounded 
                  icon-right=download-circle-outline type=is-primary
                  size=is-large
                  @click="downloadPdf(formData.visa_attachment.attachment_url, 'Visa')"
                  style="
                    width: 50px;
                    position: absolute;
                    z-index: 2;
                    margin-left: 25vw;
                    margin-top: 45vh;
                    display: flex;
                  "></b-button>
                <div
                  style="height: 56vh; width: 28.2vw; text-align: center"
                  :style="{ transform: 'rotate(' + visarotateAngle + 'deg)' }"
                >
                  <span class="helper"></span>
                  <img  v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                    style="vertical-align: middle; max-height: 55vh"
                    :src="formData.visa_attachment.attachment_url"
                    @error="imageplaceholder"
                    resposive
                    alt="Visa Image"
                  />
                  <img v-else
                    :src="pdfPlaceholder"
                    @error="imageplaceholder"
                    alt="Visa"
                    style="vertical-align: middle; max-height: 55vh"
                  />
                </div>
              </figure>
            </div>
          </div>

          <br />

          <div style="text-align: center">
            <label>Proof of address image</label>
          </div>

          <div
            class="container"
            rounded
            style="
              min-width: 32vw;
              max-width: 32vw;
              min-height: 60vh;
              max-height: 60vh;
              border: 0.3vh solid #000000;
              border-radius: 25px;
              padding: 20px;
            "
          >
            <div class="wrapper">
              <figure
                style="
                  min-width: 28.2vw;
                  max-width: 28.2vw;
                  min-height: 56vh;
                  max-height: 56vh;
                "
              >
                <img v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                  :src="button1"
                  @click="addressrotateClockwise"
                  style="
                    width: 50px;
                    position: absolute;
                    z-index: 2;
                    margin-left: 26vw;
                    margin-top: 45vh;
                    display: flex;
                    cursor: pointer;
                  "
                />
                <b-button v-else rounded 
                  icon-right=download-circle-outline type=is-primary
                  size=is-large
                  @click="downloadPdf(formData.address_proof_attachment.attachment_url, 'Proof of address')"
                  style="
                    width: 50px;
                    position: absolute;
                    z-index: 2;
                    margin-left: 25vw;
                    margin-top: 45vh;
                    display: flex;
                  "></b-button>

                <div
                  style="height: 56vh; width: 28.2vw; text-align: center"
                  :style="{
                    transform: 'rotate(' + addressrotateAngle + 'deg)',
                  }"
                >
                  <span class="helper"></span>
                  <img v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                    style="vertical-align: middle; max-height: 55vh"
                    :src="formData.address_proof_attachment.attachment_url"
                    @error="imageplaceholder"
                    resposive
                    alt="Proof of address Image"
                  />
                  <img v-else
                    :src="pdfPlaceholder"
                    @error="imageplaceholder"
                    alt="Proof of address Image"
                    style="vertical-align: middle; max-height: 55vh"
                  />
                </div>
              </figure>
            </div>
          </div>

          <br />
          <!-- <div v-if="formData.hold_application_attachments != null">
            <label>Image Attachments of Detail Reviewer</label>
            <div
              v-for="(item, index) in formData.hold_application_attachments"
              :key="item.path"
            >
              <div style="margin-top: 20px">
                <div
                  class="container"
                  rounded
                  style="
                    min-width: 28.2vw;
                    border: 2px solid #000000;
                    border-radius: 25px;
                    padding: 20px;
                  "
                >
                  <label>Image Attachment {{ index + 1 }}</label>
                  <img
                    :src="item.path"
                    style="height: 53vh"
                    resposive
                    alt="Attachment"
                  />
                  <br />
                  <label>Comment : </label>
                  <label>{{ item.comment }}</label>
                  <br />
                </div>
                <br />
              </div>
            </div>
          </div>

          <br /> -->
          <!-- <div v-if="formData.hold_application_attachments_call_agent != null">
            <label>Image Attachments of Call Agent</label>
            <div
              v-for="(
                item, index
              ) in formData.hold_application_attachments_call_agent"
              :key="item.path"
            >
              <div style="margin-top: 20px">
                <div
                  class="container"
                  rounded
                  style="
                    min-width: 28.2vw;
                    border: 2px solid #000000;
                    border-radius: 25px;
                    padding: 20px;
                  "
                >
                  <label>Image Attachment {{ index + 1 }}</label>
                  <img
                    :src="item.path"
                    style="height: 53vh"
                    resposive
                    alt="Attachment"
                  />
                  <br />
                  <label>Comment : </label>
                  <label>{{ item.comment }}</label>
                  <br />
                </div>
                <br />
              </div>
            </div>
          </div> -->

          <!--================================= ADD ATTACHMENTS UPTO 5 =================================-->
          <div
              class="container"
              style="
                width: 30vw;
                height: 8vh;
                background-color: #ED1C24;
                border-radius: 15px;
                padding: 10px;
                margin-left: 20px;
              "
            >
              <div style="text-align: center; margin-top: 1vh; cursor: pointer;"
                @click="showAttachmentModel =true">
                <b-icon
                  style="color:white"
                    icon="paperclip"
                    size="is-medium">
                </b-icon>
                <label style="color:white; font-size:24px"> Attachments</label>
              </div>
            </div>
            <div v-if=showAttachmentModel id="loading" class="fullscreen">
              <Attachments
              :applicationId = authStore.kyc_form_id
              :authId = authStore.loggedInUser
              :currentStatus = formData.current_status
              :reviewOnly = reviewOnly
              @Closs_Att="Closs_Att"/>
            </div>
            <br>

          <!-- START OF VIDEO VIEW & DOWNLOAD-->
          <br />
          <div class="container" v-if="videoCallDetail != null">
            <div
              class="card"
              v-if="videoCallDetail.video_call_recording != null"
            >
              <video
                ref="videoPlayer"
                controls
                preload="auto"
                disablePictureInPicture
                :src="videoCallDetail.video_call_recording"
                style="
                  width: 100%;
                  border-radius: 25px;
                  border: 2px solid #ff6666;
                "
                v-once
                onloadmetadata="this.parentNode.querySelector('.videoDuration').textContent = this.duration.toFixed(2)"
              ></video>
              <br />
              <b-button
                v-if="!reviewOnly"
                class="is-danger"
                style="width: 100%"
                @click="downloadVideo"
              >
                Download
              </b-button>
            </div>
          </div>
          <!-- END OF VIDEO VIEW & DOWNLOAD-->
        </div>
      </div>

      <hr
        :style="{
          'background-color': '#1b1b1b',
          height: '1.8px',
          opacity: '0.5',
          'margin-inline': '4px',
        }"
      />

      <div class="columns is-7 is-variable">
        <div class="column">
          <!-- Signature image -->
          <ImageBox
            label="Signature image"
            :imageName="formData.signature_image"
          />
          <!-- <b-message type="is-info" has-icon class="mt-5">
              Duration to complete form :
            </b-message> -->
        </div>
        <div class="column" style="margin-top: 25px">
          <ShowMapLocation
            v-if="showCustomerLocation"
            :position="kycFormCustomerLocation"
            styles="height: 400px"
          />
        </div>
      </div>
      <hr
        :style="{
          'background-color': '#1b1b1b',
          height: '1.8px',
          opacity: '0.5',
          'margin-inline': '4px',
        }"
      />

      <!--  =========================== CHECK IF APPLICATION IS "NEW" , "SUBMITTED-CALL-AGENT" , "else (AFTER CONDUCTING VIDEO CALL)"=========================== -->

      <div v-if="videoCallDetail">
        <div v-if="videoCallDetail.call_duration != 0">
          <!-- Face Comparisons -->
          <p class="has-text-danger">Face Comparisons</p>
          <div class="columns is-7 is-variable">
            <div class="column">
              <ImageBox v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
                label="User uploaded selfie image"
                :imageName="formData.selfie_attachment.attachment_url"
                styles="mt-3"
              />
              <ImageBox v-else 
                label="User uploaded selfie image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
            <div class="column" v-if="videoCallDetail.selfie_image != null">
              <ImageBox
                label="Selfie image from video call"
                :imageName="videoCallDetail.selfie_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.selfie_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <div v-if="videoCallDetail.face_and_nic_front_side_image != null">
            <!-- User Uploads and Video Call Data Comparison -->
            <p class="has-text-danger">
              User Uploads and Video Call Data Comparison
            </p>
            <p class="is-uppercase">NIC / Passport</p>
            <!-- NIC -->
            <div class="columns is-7 is-variable">
              <div class="column">
                <ImageBox v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                  label="User uploaded NIC front image"
                  :imageName="formData.nic_front_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded NIC front image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column">
                <ImageBox
                  label="NIC front image from video call"
                  :imageName="
                    videoCallDetail.face_and_nic_front_side_image.path
                  "
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="
                      videoCallDetail.face_and_nic_front_side_image.rating
                    "
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>
            <!-- NIC rear image from video call -->
            <div
              class="columns is-7 is-variable"
              v-if="videoCallDetail.face_and_nic_back_side_image != null"
            >
              <div class="column">
                <ImageBox v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                  label="User uploaded NIC rear image"
                  :imageName="formData.nic_back_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded NIC rear image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column">
                <ImageBox
                  label="NIC rear image from video call"
                  :imageName="videoCallDetail.face_and_nic_back_side_image.path"
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="
                      videoCallDetail.face_and_nic_back_side_image.rating
                    "
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>
            <!-- ///////////////////////////////////nic comments ////////////////////////////////////////////////////////////////////////-->

            <div
              style="width: fit-content; max-width: 850px"
              v-if="videoCallDetail.nic_comments != null"
            >
              <div class="columns is-variable is-7">
                <div
                  class="column is-three-fifths"
                  style="pointer-events: none"
                >
                  <div
                    v-for="comment in videoCallDetail.nic_comments.comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          rounded
                          @click.native="warning()"
                          maxlength="200"
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ////////////////nic comment end//////////////////////// -->
          </div>

          <!-- Passport -->
          <div
            v-if="videoCallDetail.face_and_passport_bio_page_image != null"
            class="columns is-7 is-variable"
          >
            <div
              class="column"
              v-if="formData.passport_bio_page_attachment.attachment_type !== ''"
            >
              <ImageBox v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                label="User uploaded Passport bio page image"
                :imageName="formData.passport_bio_page_attachment.attachment_url"
                styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Passport bio page image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
            <div class="column">
              <ImageBox
                label="Passport bio page  image from video call"
                :imageName="
                  videoCallDetail.face_and_passport_bio_page_image.path
                "
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="
                    videoCallDetail.face_and_passport_bio_page_image.rating
                  "
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div>

          <div
            class="columns is-7 is-variable"
            v-if="
              videoCallDetail.face_and_passport_alteration_page_image != null
            "
          >
            <div
              class="column"
              v-if="formData.passport_alteration_attachment.attachment_type !== ''"
            >
              <ImageBox v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                label="User uploaded Passport alteration page image"
                :imageName="formData.passport_alteration_attachment.attachment_url"
                styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Passport alteration page image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
            <div class="column">
              <ImageBox
                label="Passport alteration page  image from video call"
                :imageName="
                  videoCallDetail.face_and_passport_alteration_page_image.path
                "
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="
                    videoCallDetail.face_and_passport_alteration_page_image
                      .rating
                  "
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div>

          <div
            class="columns is-7 is-variable"
            v-if="
              videoCallDetail.face_and_passport_entry_stamp_page_image != null
            "
          >
            <div
              class="column"
              v-if="formData.passport_entry_stamp_attachment.attachment_type !== ''"
            >
              <ImageBox v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                label="User uploaded Passport entry stamp page image"
                :imageName="formData.passport_entry_stamp_attachment.attachment_url"
                styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Passport entry stamp page image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
            <div class="column">
              <ImageBox
                label="Passport entry stamp page  image from video call"
                :imageName="
                  videoCallDetail.face_and_passport_entry_stamp_page_image.path
                "
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="
                    videoCallDetail.face_and_passport_entry_stamp_page_image
                      .rating
                  "
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div>
          <!-- ////////////////////passport comments start//////////////// -->
          <!-- <div v-if="videoCallDetail.face_and_passport_alteration_page_image || videoCallDetail.face_and_passport_entry_stamp_page_image"> -->
          <div v-if="videoCallDetail.passport_comments != null">
            <div relative style="width: fit-content; max-width: 1100px">
              <div class="columns is-variable is-7">
                <div
                  class="column is-three-fifths"
                  style="pointer-events: none"
                >
                  <div
                    v-for="comment in videoCallDetail.passport_comments
                      .comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <!-- ////////////////////passport comments end//////////////// -->
          <p class="is-uppercase">Signature image</p>
          <div class="columns is-7 is-variable">
            <!-- Signature image -->

            <div class="column">
              <ImageBox
                label="User uploaded Signature image"
                :imageName="formData.signature_image"
                styles="mt-3"
              />
            </div>
            <div class="column" v-if="videoCallDetail.signature_image != null">
              <ImageBox
                label="Signature image from video call"
                :imageName="videoCallDetail.signature_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.signature_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <p class="is-uppercase">address proof document</p>
          <div class="columns is-7 is-variable">
            <!-- address proof document -->

            <div class="column">
              <ImageBox v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                label="User uploaded address proof document"
                :imageName="formData.address_proof_attachment.attachment_url"
                styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded address proof document"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
            <div
              class="column"
              v-if="videoCallDetail.address_proof_document_image != null"
            >
              <ImageBox
                label="Address proof document from video call"
                :imageName="videoCallDetail.address_proof_document_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.address_proof_document_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <!-- checkbox selection for vedio call review page -->
          <div class="check">
            <div class="columns is-7 is-variable">
              <div class="column" v-if="videoCallDetail.video_comments != null">
                <p class="mb-2">Video</p>
                <div style="pointer-events: none">
                  <div
                    v-for="comment in videoCallDetail.video_comments.comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Audio call verification -->

              <div class="column" v-if="videoCallDetail.audio_comments != null">
                <p class="mb-2">Audio call verification</p>
                <div style="pointer-events: none">
                  <div
                    v-for="comment in videoCallDetail.audio_comments.comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- if DRP Verification failed -->

          <div class="columns is-7 is-variable">
            <div class="column" v-if="videoCallDetail.drp_comments != null">
              <p class="mb-2">if DRP Verification failed</p>

              <div style="pointer-events: none">
                <div
                  v-for="comment in videoCallDetail.drp_comments.comments"
                  :key="comment.comment_content"
                >
                  <b-field style="width: fit-content">
                    <b-checkbox
                      type="is-primary"
                      v-model="comment.is_comment_selected"
                      @click.native="warning()"
                    >
                      {{ comment.comment_content }}
                    </b-checkbox>
                  </b-field>

                  <div v-if="comment.additional_comment != ''">
                    <b-field style="text-align: center">
                      <b-input
                        @click.native="warning()"
                        maxlength="200"
                        rounded
                        placeholder="Specify"
                        v-model="comment.additional_comment"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="column"
              v-if="videoCallDetail.customer_behavior_comments != null"
            >
              <p class="mb-2">Customer Behaviour</p>

              <div style="pointer-events: none">
                <div
                  v-for="comment in videoCallDetail.customer_behavior_comments
                    .comments"
                  :key="comment.comment_content"
                >
                  <b-field style="width: fit-content">
                    <b-checkbox
                      type="is-primary"
                      v-model="comment.is_comment_selected"
                      @click.native="warning()"
                    >
                      {{ comment.comment_content }}
                    </b-checkbox>
                  </b-field>

                  <div v-if="comment.additional_comment != ''">
                    <b-field style="text-align: center">
                      <b-input
                        @click.native="warning()"
                        maxlength="200"
                        rounded
                        placeholder="Specify"
                        v-model="comment.additional_comment"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />

          <!-- ////////////////////passport comments end//////////////// -->

          <!-- visa image -->
          <div
            class="columns is-7 is-variable"
            v-if="videoCallDetail.face_and_visa_image != null"
          >
            <div class="column" v-if="formData.visa_attachment.attachment_type !== ''">
              <ImageBox v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                label="User uploaded Visa image"
                :imageName="formData.visa_attachment.attachment_url"
                styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Visa image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
            <div class="column">
              <ImageBox
                label="Visa image from video call"
                :imageName="videoCallDetail.face_and_visa_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.face_and_visa_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
          </div>

          <!-- <p class="is-uppercase">Signature image</p>
          <div class="columns is-7 is-variable"> -->
          <!-- Signature image -->

          <!-- <div class="column">
              <ImageBox
                label="User uploaded Signature image"
                :imageName="formData.signature_image"
                styles="mt-3"
              />
            </div>
            <div class="column" v-if="videoCallDetail.signature_image != null">
              <ImageBox
                label="Signature image from video call"
                :imageName="videoCallDetail.signature_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.signature_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div> -->

          <!-- <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            /> -->

          <!-- <p class="is-uppercase">address proof document</p>
          <div class="columns is-7 is-variable"> -->
          <!-- address proof document -->

          <!-- <div class="column">
              <ImageBox
                label="User uploaded address proof document"
                :imageName="formData.address_proof_image"
                styles="mt-3"
              />
            </div>
            <div class="column" v-if="videoCallDetail.address_proof_document_image != null">
              <ImageBox
                label="Address proof document from video call"
                :imageName="videoCallDetail.address_proof_document_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.address_proof_document_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div> 
          </div> -->

          <!-- <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            /> -->

          <!-- Location verification -->
          <div class="columns is-7 is-variable">
            <div
              class="column"
              v-if="videoCallDetail.location_verification_comments != null"
            >
              <p class="mb-2">Location verification</p>
              <div style="pointer-events: none">
                <div
                  v-for="comment in videoCallDetail
                    .location_verification_comments.comments"
                  :key="comment.comment_content"
                >
                  <b-field style="width: fit-content">
                    <b-checkbox
                      type="is-primary"
                      v-model="comment.is_comment_selected"
                      @click.native="warning()"
                    >
                      {{ comment.comment_content }}
                    </b-checkbox>
                  </b-field>

                  <div v-if="comment.additional_comment != ''">
                    <b-field style="text-align: center">
                      <b-input
                        @click.native="warning()"
                        maxlength="200"
                        rounded
                        placeholder="Specify"
                        v-model="comment.additional_comment"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- location verifier -->
            <div
              class="column"
              v-if="videoCallDetail.location_verifier_comments != null"
            >
              <p class="mb-2">Location verifier</p>
              <div style="pointer-events: none">
                <div
                  v-for="comment in videoCallDetail.location_verifier_comments
                    .comments"
                  :key="comment.comment_content"
                >
                  <b-field style="width: fit-content">
                    <b-checkbox
                      type="is-primary"
                      v-model="comment.is_comment_selected"
                      @click.native="warning()"
                    >
                      {{ comment.comment_content }}
                    </b-checkbox>
                  </b-field>

                  <div v-if="comment.additional_comment != ''">
                    <b-field style="text-align: center">
                      <b-input
                        @click.native="warning()"
                        maxlength="200"
                        rounded
                        placeholder="Specify"
                        v-model="comment.additional_comment"
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns is-7 is-variable">
            <div class="column">
              <ShowMapLocation
                v-if="showVideoCallCustomerLocation"
                :position="videoKycCustomerLocation"
                styles="height: 400px"
              />
            </div>
            <div class="column">
              <p class="mb-2">Customer Spoken Language</p>
                <div v-if="formData.spoken_language">
                  <AgentInputField copyable="true"
                    label="Customer Spoken Language"
                    :value="formData.spoken_language"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr
      :style="{
        'background-color': '#1b1b1b',
        height: '1.8px',
        opacity: '0.5',
        'margin-inline': '4px',
      }"
    />

    <!-- Button section-->
    <div class="button-wrapper">
      <!-- manually reject btn -->
      <b-button
        v-if="ShowActionButtons"
        class="action-btn"
        @click="manuallyRejectKycFromCifAccFlow"
      >
        Reject
      </b-button>

      <!-- approve overrides and create cif button btn -->
      <!-- TODO  :: add validations to this button -->
      <b-button
        v-if="ShowActionButtons"
        class="action-btn"
        type="is-primary"
        @click="submitCorrectedOverrides"
      >
        Approve
      </b-button>

      <!-- create cif button btn for corrected overrides -->
      <b-button
        v-if="ShowActionBtnForCorrectedOverrides"
        class="action-btn"
        type="is-primary"
        @click="submitCorrectedOverrides"
      >
        Retry
      </b-button>
    </div>

    <!-- CIF creation and Account opening flow related pop up modals - START  -->

    <!-- waiting for cif creation pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showWaitingForCifCreationProcessModal"
      close-modal-redirect-route="/accountCreationQueue"
      :hide-close-btn="true"
      :modalImage="copygif"
      modalImageAltText="Copy Gif"
      modalImageClass="img_blend"
      modalImageWidth="220px"
      imgline="Waiting for Account Creation Process"
      modalthirdline=""
      :modalClose="acccreationprocessModalRoute"
      line="line"
    />

    <!-- cif error go to retry queue pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showCifErrorRetryQueueModal"
      close-modal-redirect-route="/accountCreationQueue"
      :modalImage="charmcircle"
      modalImageAltText="charmcircle"
      modalImageWidth="80px"
      modalfirstline="An error occurred"
      errorLine="Retry Queue"
      :modalClose="retryqueueModalRoute"
    />

    <!-- cif error overrides pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showCifErrorOverridesModal"
      close-modal-redirect-route="/accountCreationQueue"
      :modalImage="charmcircle"
      modalImageAltText="charmcircle"
      modalImageWidth="80px"
      modalfirstline="An error occurred"
      modalsecondline="Overrides"
      errorLineSecond="Override Queue"
      :modalClose="overridequeueModalRoute"
    />

    <!-- FCM reject pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showFCMRejectModal"
      close-modal-redirect-route="/accountCreationQueue"
      :modalImage="charmcircle"
      modalImageAltText="charmcircle"
      modalImageWidth="80px"
      modalfirstline="An error occurred"
      modalsecondline="FCM Rejected"
      errorLineSecond="FCM Rejected Queue"
      :modalClose="fcmRejectedModalRoute"
    />

    <!-- signature error pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showSignatureErrorModal"
      close-modal-redirect-route="/accountCreationQueue"
      :modalImage="charmcircle"
      modalImageAltText="charmcircle"
      modalImageWidth="80px"
      modalfirstline="An error occurred"
      modalsecondline="Signature Error"
      errorLineSecond="Signature Error"
      :modalClose="signatureModalRoute"
    />

    <!-- product error pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showProductErrorModal"
      close-modal-redirect-route="/accountCreationQueue"
      :modalImage="charmcircle"
      modalImageAltText="charmcircle"
      modalImageWidth="80px"
      modalfirstline="An error occurred"
      :modalsecondline="approvedKycDFCCData.productErrorTextForPopUp"
      :errorLineSecond="approvedKycDFCCData.productErrorTextForPopUp"
      :modalClose="productcurrencyModalRoute"
    />

    <!-- successful CIF creation and open accounts pop up -->
    <CifModalScreen
      v-if="cifPopUpModals.showSuccessfulCifCreationAndAccOpeningModal"
      close-modal-redirect-route="/accountCreationQueue"
      :isShowSecondModalContent="true"
      :accountNumbers="approvedKycDFCCData.productDetails"
      :cifNumber="approvedKycDFCCData.cifNumber"
      :modalClose="accCreationModal"
    />

    <!-- CIF creation and Account opening flow related pop up modals - END    -->
  </div>
</template>

<script>
// cif new
import FullNameView from "./FullNameView.vue"
import AgentInputButtons from "../components/AgentInputButtons.vue";
import SelectLabel from "../components/SelectLabel.vue";
import AgentSelectLabel from "../components/AgentSelectLabel.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";

import InputLabel from "./InputLabel.vue";
import ImageBox from "./ImageBox.vue";
import NetworkManager from "../network";
import ReffertoBranch from "./ReffertoBranch.vue";
import { onMounted } from "vue";
import ExceptionalApprover from "./ExceptionalApprover.vue";
import RejectApplication from "./RejectApplication.vue";
import ReturnApplication from "./ReturnApplication.vue";
import HoldApplication from "./HoldApplication.vue";
import ScheduleRescheduleMeeting from "./ScheduleRescheduleMeeting.vue";
import ApproveApplication from "./ApproveApplication.vue";
import SubmitToApprover from "./SubmitToApprover.vue";
import CheckDRP from "./CheckDRP.vue";
import { useAuthStore } from "../store/authStore";
import { DateTimeManger } from "../helpers/dateTimeManger";
import AgentInputField from "./AgentInputField.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import AddIndustryButtonComponent from "./T24Industry/AddIndustryButtonComponent.vue";
import AddIndustryModal from "./T24Industry/AddIndustryModal.vue";
import { GlobalEventManager } from "../helpers/globalEventManager";
import { GlobalEvents } from "../helpers/globalEvents";
import { GlobalFunctions } from "../helpers/globalFunctions";
import { GeoPositionCoordinates } from "../helpers/classes/geoPositionCoordinates";
import ShowMapLocation from "./ShowMapLocation.vue";
import LocationgetMapVue from "./LocationgetMap.vue";
import { BuefyHelper } from "../helpers/bufeyHelper";
import DisplayBulletList from "./DisplayBulletList.vue";
import DisplayReturnReasons from "./DisplayReturnReasons.vue";
import { CreatedProductDetailDTO } from "../helpers/classes/dtos/createdProductDetailDTO";
import { ApiRequestManager } from "../network/apiRequestManager";
import network from "../network";
import CifModalScreen from "./CifModalScreen.vue";
import AddInternalComment from "./AddInternalComment.vue";
import copygif from "../assets/Images/Gif/copy.gif";
import charmcircle from "../assets/Images/Icons/charm_circle-tick.png";
import doneimage from "../assets/Images/Icons/done.png";
import { GlobalAttributes } from "../helpers/globalAttributes";
import Attachments from "./Attachments.vue";

export default {
  name: "CifApplicationReview",
  props: {
    reviewOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddInternalComment,
    CifModalScreen,
    SvgIcon,
    AgentInputButtons,
    AddIndustryButtonComponent,
    AddIndustryModal,
    InputLabel,
    SelectLabel,
    AgentSelectLabel,
    ImageBox,
    ShowMapLocation,
    ReffertoBranch,
    ExceptionalApprover,
    RejectApplication,
    ReturnApplication,
    ApproveApplication,
    HoldApplication,
    ScheduleRescheduleMeeting,
    CheckDRP,
    AgentInputField,
    LocationgetMapVue,
    VueCropper,
    SubmitToApprover,
    DisplayBulletList,
    DisplayReturnReasons,
    FullNameView,
    Attachments,
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      autoCompleteDownArrowImage : require("@/assets/Images/Icons/Stroke-3.png"),
      calendarIcon : require("@/assets/Images/Icons/Calendar.png"),
      applicationId : null,
      authId :"",
      currentStatus:null,
      showAttachmentModel:false,
      pdfPlaceholder : require("@/assets/Images/pdf.png"),
      drpAttempt : null,
      getCommitKycDetails: null,
      isCardModalActive: false,

      isindustryFocus: false,
      industry: "",
      isIndustryTouched: false,
      industries: [],
      industrySelected: "",

      occupation: "",
      occupations: [],
      isoccupationFocus: false,
      isoccupationTouched: false,
      occupationSelected: "",

      // CIF creation related properties -- LR -----------------------------------------------------------------------------

      copygif: copygif,
      charmcircle: charmcircle,
      doneimage: doneimage,

      cifPopUpModals: {
        showWaitingForCifCreationProcessModal: false,
        showCifErrorRetryQueueModal: false,
        showCifErrorOverridesModal: false,
        showFCMRejectModal: false,
        showSignatureErrorModal: false,
        showProductErrorModal: false,
        showSuccessfulCifCreationAndAccOpeningModal: false,
      },

      approvedKycDFCCData: {
        cifNumber: "",
        productDetails: [],
        productErrorTextForPopUp: "",
      },
      // CIF creation related properties -- LR -----------------------------------------------------------------------------
      // cif new
      isSourceModalActive: false,
      isworkingPlatformModalActive: false,
      maxDate: new Date(),
      nicIssueDate: false,
      nicIssueDateAj: false,
      isPerAddDistrictFocus: false,
      permanentaddressdistrictcount: 0,
      isPermanentAddressDistrictTouched: false,
      isAgPerAddDistrictFocus: false,
      permanentaddressdistrictcountcai: 0,
      isPermanentAddressDistrictTouchedcai: false,
      isPerAddCountryFocus: false,
      permanentaddresscountrycount: 0,
      isPermanentAddressCountryTouched: false,
      isPerAddCountryFocusCai: false,
      permanentaddresscountrycountcai: 0,
      isPermanentAddressCountryTouchedCai: false,
      titleEditCI: "",
      titleEditCAI: "",
      fullNameEditCI: "",
      fullNameEditCAI: "",
      citizenshipEditCI: "",
      citizenshipEditCAI: "",
      nationalityEditCI: "",
      nationalityEditCAI: "",
      statusEditCI: "",
      statusEditCAI: "",
      residentEditCI: "",
      residentEditCAI: "",
      perStreetEditCI: "",
      perStreetEditCAI: "",
      perStreet2EditCI: "",
      perStreet2EditCAI: "",
      buildingNumEditCI: "",
      buildingNumEditCAI: "",
      buildingNameEditCI: "",
      buildingNameEditCAI: "",
      townEditCI: "",
      townEditCAI: "",
      postalCodeEditCI: "",
      postalCodeEditCAI: "",
      cobuildingNumEditCI: "",
      cobuildingNumEditCAI: "",
      cobuildingNameEditCI: "",
      cobuildingNameEditCAI: "",
      coStreetEditCI: "",
      coStreetEditCAI: "",
      coStreet2EditCI: "",
      coStreet2EditCAI: "",
      coTownEditCI: "",
      coTownEditCAI: "",
      coPostalCodeEditCI: "",
      coPostalCodeEditCAI: "",
      coMotherMaidenEditCI: "",
      coMotherMaidenEditCAI: "",
      employmentStatusEditCI: "",
      employmentStatusEditCAI: "",
      taxPayerEditCI: "",
      taxPayerEditCAI: "",
      taxNoEditCI: "",
      taxNoEditCAI: "",
      sourceofincomeCI: null,
      sourceofincomeCAI: null,
      otherSourceEditCI: "",
      otherSourceEditCAI: "",
      branchCommentEditCI: "",
      branchCommentEditCAI: "",
      residencestatusEditCI: "",
      residencestatusEditCAI: "",
      natureOfBusinessEditCI: "",
      natureOfBusinessEditCAI: "",
      employerEditCI: "",
      employerEditCAI: "",
      embuildingNumEditCI: "",
      embuildingNumEditCAI: "",
      embuildingNameEditCI: "",
      embuildingNameEditCAI: "",
      emStreetEditCI: "",
      emStreetEditCAI: "",
      emTownEditCI: "",
      emTownEditCAI: "",
      emSPostalCodeEditCI: "",
      emSPostalCodeEditCAI: "",
      areYouworkingEditCI: "",
      areYouworkingEditCAI: "",
      otherPlatformWorkingOnCI: [],
      otherPlatformWorkingOnCAI: [],
      otherPlatformDescriptionCI: "",
      otherPlatformDescriptionCAI: "",
      nicIssueDateEditCI: "",
      nicIssueDateEditCAI: "",
      districtEditCI: "",
      districtEditCIAutoComplete: "",
      districtEditCAI: "",
      districtEditCAIAutoComplete: "",
      countryEditCI: "",
      countryEditCIAutoComplete: "",
      countryEditCAI: "",
      countryEditCAIAutoComplete: "",
      coDistrictEditCI: "",
      coDistrictEditCAI: "",
      corrDistrictEditCIAutoComplete: "",
      corrDistrictEditCAIAutoComplete: "",
      areYouOrCloseRelativeCI: "",
      areYouOrCloseRelativeCIPermanent: "",
      areYouOrCloseRelativeCAI: "",
      areYouOrCloseRelativeCAIPermanent: "",
      pepPersonDetailsCI: "",
      pepPersonDetailsCAI: "",
      isCorAddDistrictFocus: false,
      coraddressdistrictcount: 0,
      isCorAddressDistrictTouched: false,
      isAgCorPerAddDistrictFocus: false,
      coraddressdistrictcountcai: 0,
      isCorAddressDistrictTouchedcai: false,

      emDistrictEditCI: "",
      emDistrictEditCAI: "",
      emDistrictEditCIAutoComplete: "",
      emDistrictEditCAIAutoComplete: "",
      isEmAddDistrictFocus: false,
      emaddressdistrictcount: 0,
      isEmAddressDistrictTouched: false,
      isAgEmPerAddDistrictFocus: false,
      emaddressdistrictcountcai: 0,
      isEmAddressDistrictTouchedcai: false,

      CoCountryEditCIAutoComplete: "",
      CoCountryEditCAIAutoComplete: "",
      isCoAddCountryFocus: false,
      coraddresscountrycount: 0,
      isCorAddressCountryTouched: false,
      coCountryEditCI: "",
      coCountryEditCAI: "",
      isCoAddCountryFocusCai: false,
      coraddresscountrycountcai: 0,
      isCorAddressCountryTouchedCai: false,

      EmCountryEditCIAutoComplete: "",
      EmCountryEditCAIAutoComplete: "",
      isEmAddCountryFocus: false,
      emaddresscountrycount: 0,
      isEmAddressCountryTouched: false,
      emCountryEditCI: "",
      emCountryEditCAI: "",
      isEmAddCountryFocusCai: false,
      emaddresscountrycountcai: 0,
      isEmAddressCountryTouchedCai: false,

      passIssueDate: false,
      passIssueDateAj: false,
      passIssueDateEditCI: "",
      passIssueDateEditCAI: "",

      branchCI: "",
      branchCAI: "",
      isBranchInputFocus: false,

      // cif new
      fullNameValidationProviderError: "",
      pepList: [
        { value: "I", label: "I am politically exposed" },
        { value: "Relative", label: "My Close Relative politically exposed" },
        { value: "No", label: "No" },
      ],
      //   cif new data
      titleList: [
        {
          value: "Dr",
          label: "Dr",
        },
        {
          value: "Master",
          label: "Master",
        },
        {
          value: "Professor",
          label: "Professor",
        },
        {
          value: "Rev",
          label: "Rev",
        },
        {
          value: "Mr",
          label: "Mr",
        },
        {
          value: "Mrs",
          label: "Mrs",
        },
        {
          value: "Ms",
          label: "Ms",
        },
        {
          value: "Miss",
          label: "Miss",
        },
      ],
      residentStatusList: [
        {
          value: "House Holder",
          label: "House Holder",
        },
        {
          value: "Living With Parents",
          label: "Living With Parents",
        },
        {
          value: "Renter",
          label: "Renter",
        },
      ],

      yesNoList: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      citizenshipList: [
        {
          value: "Sri Lankan",
          label: "Sri Lankan",
        },
        {
          value: "Sri Lankan with Dual Citizenship",
          label: "Sri Lankan with Dual Citizenship",
        },
      ],
      residentList: [
        {
          value: "Sri Lanka",
          label: "Sri Lanka",
        },
        {
          value: "Out of Sri Lanka",
          label: "Out of Sri Lanka",
        },
      ],
      maritalList: [
        {
          value: "Married",
          label: "Married",
        },
        {
          value: "Unmarried",
          label: "Unmarried",
        },
      ],
      nationalityList: [
        {
          value: "Sri Lankan",
          label: "Sri Lankan",
        },
        {
          value: "Sri Lankan with Dual Citizenship",
          label: "Sri Lankan with Dual Citizenship",
        },
        // {
        //   value: "Foreign National",
        //   label: "Foreign National",
        // },
        // {
        //   value: "Foreign National with Dual Citizenship",
        //   label: "Foreign National with Dual Citizenship",
        // },
      ],
      employmentStatusList: [
        {
          value: "Full-Time",
          label: "Full-Time",
        },
        {
          value: "Part Time",
          label: "Part Time",
        },
        {
          value: "Self-Employed",
          label: "Self-Employed",
        },
        {
          value: "Freelance",
          label: "Freelance",
        },
        {
          value: "Probation",
          label: "Probation",
        },
        {
          value: "Unemployed",
          label: "Unemployed",
        },
        {
          value: "Retired",
          label: "Retired",
        },
        {
          value: "Housewife",
          label: "Housewife",
        },
        {
          value: "Student",
          label: "Student",
        },
      ],
      workeList: ["Upwork", "Fiverr", "Freelancer", "Other"],
      sourceOfFundList: [
        "Employment Income",
        "Investment Proceeds",
        "Gifts",
        "Inheritance",
        "Commission Income",
        "Sale of Property / Asset",
        "Family Remittance",
        "Donation",
        "Other",
      ],
      //   cif new data
      applicationstatus: false,
      videoDuration: "",
      getAllInternalComments: null,
      returnReasonDetail: null,
      path: mdiPlus,

      showAddIndustryModal: false,
      showAddIndustryButton: false,

      nicActiveTab: 0,
      passportActiveTab: 0,
      DRP_showMessageBox: false,

      HA_isButtonDisabled: false,
      HA_showMessageBox: false,

      RB_isButtonDisabled: false,
      RB_showMessageBox: false,

      App_isButtonDisabled: false,
      App_showMessageBox: false,

      showCreateCifAndOpenAccountApproveMsgBox: false,

      Su_to_App_isButtonDisabled: false,
      Su_to_App_showMessageBox: false,

      EA_isButtonDisabled: false,
      EA_showMessageBox: false,

      R_isButtonDisabled: false,
      R_showMessageBox: false,

      RA_isButtonDisabled: false,
      RA_showMessageBox: false,

      Sh_isButtonDisabled: false,
      Sh_showMessageBox: false,

      idfRotateAngle: 0,
      idbRotateAngle: 0,
      addressrotateAngle: 0,
      visarotateAngle: 0,
      ppbioRotateAngle: 0,
      ppaltRotateAngle: 0,
      ppsRotateAngle: 0,

      Image: "",
      IDF: true,
      IDB: false,
      SLF: false,
      varcheker: "",

      kycFormCustomerLocation: new GeoPositionCoordinates(),
      videoKycCustomerLocation: new GeoPositionCoordinates(),
      showCustomerLocation: false,
      showVideoCallCustomerLocation: false,
      formData: [],
      videoCallDetail: [],
      customer_branch: null,
      agent_branch: [],
      products: [],

      drpArray:'',
      attachment: ["", "", "", "", ""],

      button: "https://cdn-icons-png.flaticon.com/512/1040/1040252.png",
      button1: "https://cdn-icons-png.flaticon.com/512/1040/1040252.png",

      //defaultimg: require("../assets/Images/add.png"),
      defaultimg: require("../assets/Images/add.png"),

      cards: [{ id: 1, image: "", description: "" }],

      count: 1,
      maxCards: 5,

      R_drp_fail: false,
      R_specify: "",
      R_reasons: [],
      R_customer_comment: "",
      R_internal_comment: "",

      RA_return_to: "",
      RA_reasons: [],
      RA_customer_comment: "",
      RA_internal_comment: "",

      RB_customer_comment: "",
      RB_internal_comment: "",

      App_customer_comment: "",
      App_internal_comment: "",

      Su_to_App_internal_comment: "",

      EA_customer_comment: "",
      EA_internal_comment: "",

      HA_customer_comment: "",
      HA_internal_comment: "",
      HA_reasons: [],

      DRP_internal_comment: "",
      //DRP_data :[],

      //values forSchedule Reschedule meeting

      Sh_customer_comment: "",
      Sh_internal_comment: "",
      Sh_email: true,
      Sh_sms: false,
      videoCallTime: "",
      selected: "",
      Schedule_or_Reschedule: "Schedule a Video Call",
      Sh_Disabled: true,

      schedule_button_name: "Schedule a Video Call & Submit",

      DRPCustomerData: {
        nic: "",
        dob: "",
        address: "",
        full_name: "",
        issue_date: "",
      },
      DRPdata: null,
      isActive: false,
      imageUrl: "",
      captureModalIsVisible: false,
      cropModalIsVisible: false,
      file: null,
      imageToCrop: null,
      croppedImg: null,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      crop_ratio: 1 / 1,
      selectedCardID: "",
      latestUpdateUserRoleId: "",

      // CIF override details
      cifOverrideDetails: null,
    };
  },

  methods: {
    downloadPdf(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // cif new
    /**
     * This function is used to get T24 industry list
     */
    getT24Industries(callback) {
      const comp = this;

      NetworkManager.apiRequest("api/T24/getIndustriesList", {}, (response) => {
        console.log("T24 industries response");
        console.log(response);
        if (response.statusCode === 200) {
          comp.industries = response.data.industries.sort((a, b) => {
            return a.description.localeCompare(b.description);
          });

          if (typeof callback === "function") {
            callback();
          }
        } else {
          BuefyHelper.showToastMessage(
            "Unable to get Industries list",
            "is-danger"
          );
        }
      });
    },

    /**
     * This function is used to get T24 occupations list
     */
    getT24Occupations(callback) {
      const comp = this;

      NetworkManager.apiRequest(
        "api/T24/getOccupationsList",
        {},
        (response) => {
          console.log("T24 industries response");
          console.log(response);
          if (response.statusCode === 200) {
            comp.occupations = response.data.occupations.sort((a, b) => {
              return a.localeCompare(b);
            });

            if (typeof callback === "function") {
              callback();
            }
          } else {
            BuefyHelper.showToastMessage(
              "Unable to get Occupations list",
              "is-danger"
            );
          }
        }
      );
    },
    validateName: function () {
      const obj = {
        required: true,
        min: 3,
        alphaSpaces: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    // cif new

    // CIF creation and open accounts flow Pop-ups related methods - START

    accCreationModal() {
      this.cifPopUpModals.showSuccessfulCifCreationAndAccOpeningModal = false;
      this.$router.push("/approvedApplications");
    },

    acccreationprocessModalRoute() {
      this.cifPopUpModals.showWaitingForCifCreationProcessModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 0 },
      });
    },

    retryqueueModalRoute() {
      this.cifPopUpModals.showCifErrorRetryQueueModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 0 },
      });
    },

    overridequeueModalRoute() {
      this.cifPopUpModals.showCifErrorOverridesModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 1 },
      });
    },

    fcmRejectedModalRoute() {
      this.cifPopUpModals.showFCMRejectModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 3 },
      });
    },

    signatureModalRoute() {
      this.cifPopUpModals.showSignatureErrorModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 5 },
      });
    },

    productcurrencyModalRoute() {
      this.cifPopUpModals.showProductErrorModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 6 },
      });
    },

    manuallyRejectKycFromCifAccFlow() {
      const comp = this;
      comp.showFullScreenLoader();

      const requestBody = {
        id: comp.authStore.kyc_form_id,
        status: "MANUALLY-REJECTED-FROM-CIF-ACC-FLOW",
        comment_for_customer: "",
        comment_for_internal_use: "",
        reasons: [],
      };

      console.log("Manually rejecting kyc application from CIF ACC flow");
      console.log("request body", requestBody);

      network.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        requestBody,
        (response) => {
          console.log("Manually rejecting kyc application from CIF ACC flow");
          console.log("response", response);

          comp.hideFullScreenLoader();

          if (response.statusCode === 200) {
            BuefyHelper.showToastMessage(
              "Application Manually Rejected",
              "is-danger"
            );
            // move to manually rejected tab in acc creation queue
            comp.$router.push({
              path: "/accountCreationQueue",
              query: { tabId: 4 },
            });
          } else {
            // error from api
            BuefyHelper.showToastMessage("Something went wrong!", "is-danger");
          }
        }
      );
    },

    submitCorrectedOverrides() {
      const comp = this;

      console.log("submit corrected overrides -------- start --------");

      if (
        comp.formData.current_status ===
        GlobalAttributes.kycFormStatus.T24_CIF_OVERRIDES
      ) {
        comp.showFullScreenLoader();

        const requestBody = {
          ...comp.formData,
        };

        console.log("request body : ", requestBody);

        network.apiRequest(
          "api/KycApplication/submitFixedT24Overrides",
          requestBody,
          (response) => {
            console.log("submit corrected overrides response ", response);

            comp.hideFullScreenLoader();

            if (response.statusCode === 200) {
              // successfully saved the corrected overrides

              // call cif creation
              let comments = {
                commentForCustomer: "",
                commentForInternal: "",
              };

              comp.createCIFOpenAccountsAndApproveKyc(comments, true);
            } else {
              // some error occurred
              BuefyHelper.showToastMessage(
                "Something went wrong!",
                "is-danger"
              );
            }
          }
        );
      } else {
        // Bypass override submitting
        // call cif creation
        let comments = {
          commentForCustomer: "",
          commentForInternal: "",
        };

        comp.createCIFOpenAccountsAndApproveKyc(comments, true);
      }
    },

    createCIFOpenAccountsAndApproveKyc(comments, useOldComments = false) {
      const comp = this;

      comp.showCreateCifAndOpenAccountApproveMsgBox = false;

      console.log("Create CIF and Open accounts ==========");
      console.log("Kyc Form Id : " + comp.authStore.kyc_form_id);
      console.log("Comments : ", comments);

      // show processing pop up
      comp.cifPopUpModals.showWaitingForCifCreationProcessModal = true;

      ApiRequestManager.createCIFAndOpenAccounts(
        comp.authStore.kyc_form_id,
        comments.commentForInternal,
        comments.commentForCustomer,
        useOldComments,
        (response) => {
          console.log("response");
          console.log(response);

          // hide pop up
          comp.cifPopUpModals.showWaitingForCifCreationProcessModal = false;

          if (response.statusCode === 200) {
            // success api responses
            console.log(response.data.message);

            if (response.data.cif_creation_and_open_accounts_success) {
              // successfully created the CIF and opened the accounts

              // get cif number and account details
              comp.approvedKycDFCCData.cifNumber = response.data.cif_number;
              comp.approvedKycDFCCData.productDetails = [];

              response.data.created_product_details.forEach(
                (createdProduct) => {
                  console.log("current created product", createdProduct);
                  comp.approvedKycDFCCData.productDetails.push(
                    new CreatedProductDetailDTO(
                      createdProduct.account_type,
                      createdProduct.account_number
                    )
                  );
                }
              );

              // show success popup
              comp.cifPopUpModals.showSuccessfulCifCreationAndAccOpeningModal = true;
            } else if (
              response.data.t24_cif_error ||
              response.data.inao_record_from_t24
            ) {
              // cif error or record status is INAO from dfcc side

              // show cif error modal -> move to retry queue
              comp.cifPopUpModals.showCifErrorRetryQueueModal = true;
            } else if (response.data.t24_cif_overrides) {
              // CIF Overrides situation
              // show cif overrides modal -> move to overrides queue
              comp.cifPopUpModals.showCifErrorOverridesModal = true;
            } else if (response.data.fcm_rejected) {
              // FCM rejected the customer situation
              // move to fcm rejected queue
              comp.cifPopUpModals.showFCMRejectModal = true;
            } else if (response.data.t24_signature_error) {
              // T24 signature error situation
              // show signature error pop up and move to signature error queue queue
              comp.cifPopUpModals.showSignatureErrorModal = true;
            } else if (response.data.t24_account_creation_error) {
              // T24 account creation error situation

              // get error type
              console.log(
                "Product error type : " +
                  response.data.t24_account_creation_error_type
              );

              if (
                response.data.t24_account_creation_error_type === "CURRENCY"
              ) {
                comp.approvedKycDFCCData.productErrorTextForPopUp =
                  "Currency Change";
              } else if (
                response.data.t24_account_creation_error_type === "PRODUCT"
              ) {
                comp.approvedKycDFCCData.productErrorTextForPopUp =
                  "Product Change";
              } else {
                comp.approvedKycDFCCData.productErrorTextForPopUp =
                  "Other Error";
              }

              // show product error pop up and move to signature error queue queue
              comp.cifPopUpModals.showProductErrorModal = true;
            }
          } else if (response.statusCode === 503) {
            // apis are not enabled

            console.warn("T24 APIs are not enabled");
            BuefyHelper.showToastMessage(
              "Unable to approve. Try again",
              "is-danger"
            );

            // hide popup
            // comp.cifPopUpModals.showWaitingForCifCreationProcessModal = false
          } else if (response.statusCode === 500) {
            // internal server errors

            console.warn("Internal error. Refer to internal logs");
            BuefyHelper.showToastMessage(
              "Unable to approve. Try again",
              "is-danger"
            );

            // route to corrected overrides tab
            this.$router.push({
              path: "/accountCreationQueue",
              query: { tabId: 2 },
            });
          } else {
            // 400 bad requests
          }
        }
      );
    },

    getFormattedDateTime(date) {
      return DateTimeManger.formatCustomerInputDates(date);
    },

    DrpCheck() {
      const comp = this;
      comp.showFullScreenLoader();
      const data = {
        nic: comp.formData.nic_eic_number.customer_input,
      };
      console.log("check drp console 1", data);
      comp.DRPCustomerData.nic = comp.formData.nic_eic_number.customer_input;
      comp.DRPCustomerData.dob = comp.getFormattedDateTime(
        comp.formData.date_of_birth.customer_input
      );
      comp.DRPCustomerData.address = comp.formData.building_number
        .is_changed_by_call_agent
        ? comp.formData.building_number.call_agent_input
        : comp.formData.building_number.customer_input +
          ", " +
          comp.formData.building_name.is_changed_by_call_agent
        ? comp.formData.building_name.call_agent_input
        : comp.formData.building_name.customer_input +
          ",  " +
          comp.formData.street.is_changed_by_call_agent
        ? comp.formData.street.call_agent_input
        : comp.formData.street.customer_input +
          ",  " +
          comp.formData.town.is_changed_by_call_agent
        ? comp.formData.town.call_agent_input
        : comp.formData.town.customer_input;
      comp.DRPCustomerData.full_name = comp.formData.full_name
        .is_changed_by_call_agent
        ? comp.formData.full_name.call_agent_input
        : comp.formData.full_name.customer_input;
      comp.DRPCustomerData.issue_date = comp.getFormattedDateTime(
        comp.formData.nic_eic_issue_date.is_changed_by_call_agent
          ? comp.formData.nic_eic_issue_date.call_agent_input
          : comp.formData.nic_eic_issue_date.customer_input
      );
      NetworkManager.apiRequest(
        "api/KycApplication/checkDrp",
        data,
        function (e) {
          if (e.statusCode === 200) {
            if (e.data != null) {
              comp.DRPdata = e.data.drp_result;
              comp.DRPCustomerData.dob == comp.DRPdata.birth_date
                ? (comp.DRPStatus.DOB_status = comp.check)
                : (comp.DRPStatus.DOB_status = comp.wrong);
              comp.DRPCustomerData.address == comp.DRPdata.address
                ? (comp.DRPStatus.Address_status = comp.check)
                : (comp.DRPStatus.Address_status = comp.wrong);
              comp.DRPCustomerData.nic == comp.DRPdata.id
                ? (comp.DRPStatus.NIC_status = comp.check)
                : (comp.DRPStatus.NIC_status = comp.wrong);
              comp.DRPCustomerData.issue_date == comp.DRPdata.issued_date
                ? (comp.DRPStatus.IDissueDAte_status = comp.check)
                : (comp.DRPStatus.IDissueDAte_status = comp.wrong);
              comp.DRPCustomerData.full_name == comp.DRPdata.name
                ? (comp.DRPStatus.Full_name_status = comp.check)
                : (comp.DRPStatus.Full_name_status = comp.wrong);
              comp.hideFullScreenLoader();
              comp.DRP_showMessageBox = true;
            } else {
              comp.hideFullScreenLoader();
              const params = {
                type: "is-danger",
                message: "Unable to check DRP. Try again.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
            }
          } else {
            comp.hideFullScreenLoader();
            const params = {
              type: "is-danger",
              message: "Unable to check DRP. Try again.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    DRP_success: function () {
      const comp = this;
      //this.isButtonDisabled = true

      this.DRP_showMessageBox = false;
      const data = {
        id: comp.authStore.kyc_form_id,
        comment: comp.DRP_internal_comment,
        status: true,
      };

      NetworkManager.apiRequest(
        "api/KycApplication/SaveDRPStatus",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.DRP_showMessageBox = false;
            const params = {
              type: "is-success",
              message: "DRP status saved successfully.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    DRP_fail: function () {
      const comp = this;
      //this.isButtonDisabled = true

      this.DRP_showMessageBox = false;
      const data = {
        id: comp.authStore.kyc_form_id,
        comment: comp.DRP_internal_comment,
        status: false,
      };

      NetworkManager.apiRequest(
        "api/KycApplication/SaveDRPStatus",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.DRP_showMessageBox = false;
            const params = {
              type: "is-success",
              message: "DRP status saved successfully.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    refreshPage() {
      this.$router.go(0);
    },

    idfRotateClockwise() {
      this.idfRotateAngle += 90;
    },
    idbRotateClockwise() {
      this.idbRotateAngle += 90;
    },

    ppbioRotateClockwise() {
      this.ppbioRotateAngle += 90;
    },
    ppaltRotateClockwise() {
      this.ppaltRotateAngle += 90;
    },
    ppsRotateClockwise() {
      this.ppsRotateAngle += 90;
    },

    addressrotateClockwise() {
      this.addressrotateAngle += 90;
    },
    visarotateClockwise() {
      this.visarotateAngle += 90;
    },
    imageplaceholder(event) {
      event.target.src = this.defaultimg;
    },

    downloadVideo() {
      var video_name = this.formData.reference_number + ".mp4";

      var xhr = new XMLHttpRequest();
      xhr.open("GET", this.videoCallDetail.video_call_recording, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.target = "_blank";

        tag.download = video_name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = (err) => {
        alert("Failed to download video");
      };
      xhr.send();
    },

    handleImageClick(cardid) {
      this.selectedCardID = cardid;
      this.$refs.upload.click();

      // this.isActive = true

      // const input = document.createElement("input");
      // input.type = "file";
      // input.accept = "image/*";
      //
      // input.addEventListener("change", (event) => {
      //   const file = event.target.files[0];
      //   card.file = event.target.files[0];
      //   const reader = new FileReader();
      //
      //   reader.addEventListener("load", () => {
      //     card.image = reader.result;
      //
      //     if (card.id === this.count && this.count < this.maxCards) {
      //       this.addCard();
      //     }
      //   });
      //   reader.readAsDataURL(file);
      // });
      // input.click();
    },

    Closs_Att(value) {
      this.showAttachmentModel = value;
    },

    addCard(image) {
      this.count++;

      this.cards.push({
        id: this.count,
        image: image,
        file: "",
        description: "",
      });
    },

    removeCard(card) {
      const index = this.cards.indexOf(card);
      if (index > -1) {
        this.cards.splice(index, 1);
        this.count--;

        // if (this.count < this.maxCards && this.cards.length === this.maxCards) {
        //   this.cards.push({
        //     id: this.maxCards,
        //     image: "",
        //     description: "",
        //   });
        // }
      }
      if (index == 0) {
        this.addCard();
      }
    },

    GetApplicationData: function () {
      const comp = this;
      const data = { id: comp.authStore.kyc_form_id };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
        "api/KycApplication/getApplicationById",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.formData = e.data.kycApplication;

            console.log("kyc form data : ", comp.formData);

            console.log("get application by id data ===============", e.data);

            comp.drpAttempt = e.data.drpAttempt
            comp.applicationstatus = e.data.isKycApplicationVideoCallComplete;
            comp.products = e.data.productSelection;
            comp.getAllInternalComments = e.data.getAllInternalComments;
            comp.drpArray = e.data.drpStatusList;
            comp.returnReasonDetail = e.data.returnReasonDetail;
            comp.videoCallDetail = e.data.videoCallDetail;
            comp.cifOverrideDetails = e.data.latestT24Overrides;
            comp.getCommitKycDetails = e.data.getCommitKycDetails;

            console.log("latest t24 cif overrides : ", comp.cifOverrideDetails);

            comp.videoCallTime = e.data.kycApplication.video_call_time;

            let dateStr = e.data.kycApplication.video_call_date;
            comp.selected = new Date(dateStr);

            console.log("customer branch", e.data.customerBranch);
            comp.customer_branch = e.data.customerBranch;
            comp.agent_branch = e.data.agentBranch;
            comp.latestUpdateUserRoleId = e.data.latestUpdatedUserModel.role_id;

            // get customer location
            let customerLocation = JSON.parse(
              e.data.kycApplication.customer_location
            );
            //console.log("customer location")
            //console.log(customerLocation)
            comp.kycFormCustomerLocation = new GeoPositionCoordinates(
              customerLocation.latitude,
              customerLocation.longitude
            );
            comp.showCustomerLocation = true;

            // get video call location if available
            if (comp.videoCallDetail !== null) {
              let videoCallCustomerLocation = JSON.parse(
                comp.videoCallDetail.video_call_location
              );
              //console.log("video call location")
              //console.log(videoCallCustomerLocation)
              comp.videoKycCustomerLocation = new GeoPositionCoordinates(
                videoCallCustomerLocation.latitude,
                videoCallCustomerLocation.longitude
              );
              comp.showVideoCallCustomerLocation = true;
            }

            // cif new
            comp.industry = comp.formData.customer_industry.description;
            comp.industrySelected = { ...comp.formData.customer_industry };
            comp.occupation = comp.formData.customer_occupation;
            comp.occupationSelected = comp.formData.customer_occupation;
            comp.titleEditCI = comp.formData.title.customer_input;
            comp.titleEditCAI = comp.formData.title.is_changed_by_call_agent
              ? comp.formData.title.call_agent_input
              : comp.formData.title.customer_input;
            comp.fullNameEditCI = comp.formData.full_name.customer_input;
            comp.fullNameEditCAI = comp.formData.full_name
              .is_changed_by_call_agent
              ? comp.formData.full_name.call_agent_input
              : comp.formData.full_name.customer_input;
            comp.citizenshipEditCI = comp.formData.citizenship.customer_input;
            comp.citizenshipEditCAI = comp.formData.citizenship
              .is_changed_by_call_agent
              ? comp.formData.citizenship.call_agent_input
              : comp.formData.citizenship.customer_input;
            comp.nationalityEditCI = comp.formData.nationality.customer_input;
            comp.nationalityEditCAI = comp.formData.nationality
              .is_changed_by_call_agent
              ? comp.formData.nationality.call_agent_input
              : comp.formData.nationality.customer_input;
            comp.statusEditCI = comp.formData.maritial_status.customer_input;
            comp.statusEditCAI = comp.formData.maritial_status
              .is_changed_by_call_agent
              ? comp.formData.maritial_status.call_agent_input
              : comp.formData.maritial_status.customer_input;
            comp.residentEditCI = comp.formData.residentship.customer_input;
            comp.residentEditCAI = comp.formData.residentship
              .is_changed_by_call_agent
              ? comp.formData.residentship.call_agent_input
              : comp.formData.residentship.customer_input;
            comp.perStreetEditCI = comp.formData.street.customer_input;
            comp.perStreet2EditCI = comp.formData.street_2.customer_input;
            comp.perStreetEditCAI = comp.formData.street
              .is_changed_by_call_agent
              ? comp.formData.street.call_agent_input
              : comp.formData.street.customer_input;
            comp.perStreet2EditCAI = comp.formData.street_2
              .is_changed_by_call_agent
              ? comp.formData.street_2.call_agent_input
              : comp.formData.street_2.customer_input;
            comp.buildingNumEditCI =
              comp.formData.building_number.customer_input;
            comp.buildingNumEditCAI = comp.formData.building_number
              .is_changed_by_call_agent
              ? comp.formData.building_number.call_agent_input
              : comp.formData.building_number.customer_input;

            comp.buildingNameEditCAI = comp.formData.building_name
              .is_changed_by_call_agent
              ? comp.formData.building_name.call_agent_input
              : comp.formData.building_name.customer_input;
            comp.buildingNameEditCI =
              comp.formData.building_name.customer_input;
            comp.townEditCAI = comp.formData.town.is_changed_by_call_agent
              ? comp.formData.town.call_agent_input
              : comp.formData.town.customer_input;
            comp.townEditCI = comp.formData.town.customer_input;
            comp.postalCodeEditCAI = comp.formData.postal_code
              .is_changed_by_call_agent
              ? comp.formData.postal_code.call_agent_input
              : comp.formData.postal_code.customer_input;
            comp.postalCodeEditCI = comp.formData.postal_code.customer_input;
            comp.cobuildingNumEditCAI = comp.formData
              .correspondence_address_building_number.is_changed_by_call_agent
              ? comp.formData.correspondence_address_building_number
                  .call_agent_input
              : comp.formData.correspondence_address_building_number
                  .customer_input;
            comp.cobuildingNumEditCI =
              comp.formData.correspondence_address_building_number.customer_input;
            comp.cobuildingNameEditCAI = comp.formData
              .correspondence_address_building_name.is_changed_by_call_agent
              ? comp.formData.correspondence_address_building_name
                  .call_agent_input
              : comp.formData.correspondence_address_building_name
                  .customer_input;
            comp.cobuildingNameEditCI =
              comp.formData.correspondence_address_building_name.customer_input;
            comp.coStreetEditCAI = comp.formData.correspondence_address_street
              .is_changed_by_call_agent
              ? comp.formData.correspondence_address_street.call_agent_input
              : comp.formData.correspondence_address_street.customer_input;
            comp.coStreet2EditCAI = comp.formData.correspondence_address_street_2
              .is_changed_by_call_agent
              ? comp.formData.correspondence_address_street_2.call_agent_input
              : comp.formData.correspondence_address_street_2.customer_input;
            comp.coStreetEditCI =
              comp.formData.correspondence_address_street.customer_input;
            comp.coStreet2EditCI =
              comp.formData.correspondence_address_street_2.customer_input;
            comp.coTownEditCAI = comp.formData.correspondence_address_town
              .is_changed_by_call_agent
              ? comp.formData.correspondence_address_town.call_agent_input
              : comp.formData.correspondence_address_town.customer_input;
            comp.coTownEditCI =
              comp.formData.correspondence_address_town.customer_input;
            comp.coPostalCodeEditCAI = comp.formData
              .correspondence_address_postal_code.is_changed_by_call_agent
              ? comp.formData.correspondence_address_postal_code
                  .call_agent_input
              : comp.formData.correspondence_address_postal_code.customer_input;
            comp.coPostalCodeEditCI =
              comp.formData.correspondence_address_postal_code.customer_input;
            comp.coMotherMaidenEditCAI = comp.formData.mothers_maiden_name
              .is_changed_by_call_agent
              ? comp.formData.mothers_maiden_name.call_agent_input
              : comp.formData.mothers_maiden_name.customer_input;
            comp.coMotherMaidenEditCI =
              comp.formData.mothers_maiden_name.customer_input;
            comp.employmentStatusEditCAI = comp.formData.employment_status
              .is_changed_by_call_agent
              ? comp.formData.employment_status.call_agent_input
              : comp.formData.employment_status.customer_input;
            comp.employmentStatusEditCI =
              comp.formData.employment_status.customer_input;
            comp.taxPayerEditCAI = comp.formData.are_you_a_tax_payer
              .is_changed_by_call_agent
              ? comp.formData.are_you_a_tax_payer.call_agent_input
              : comp.formData.are_you_a_tax_payer.customer_input;
            comp.taxPayerEditCI =
              comp.formData.are_you_a_tax_payer.customer_input;
            comp.taxNoEditCAI = comp.formData.tax_file_numer
              .is_changed_by_call_agent
              ? comp.formData.tax_file_numer.call_agent_input
              : comp.formData.tax_file_numer.customer_input;
            comp.taxNoEditCI = comp.formData.tax_file_numer.customer_input;
            comp.sourceofincomeCI =
              comp.formData.source_of_funds.customer_input;
            comp.sourceofincomeCAI = comp.formData.source_of_funds
              .is_changed_by_call_agent
              ? comp.formData.source_of_funds.call_agent_input
              : comp.formData.source_of_funds.customer_input;
            comp.otherSourceEditCAI = comp.formData
              .source_of_funds_other_description.is_changed_by_call_agent
              ? comp.formData.source_of_funds_other_description.call_agent_input
              : comp.formData.source_of_funds_other_description.customer_input;
            comp.otherSourceEditCI =
              comp.formData.source_of_funds_other_description.customer_input;
            comp.branchCommentEditCAI = comp.formData.branch_comment
              .is_changed_by_call_agent
              ? comp.formData.branch_comment.call_agent_input
              : comp.formData.branch_comment.customer_input;
            comp.branchCommentEditCI =
              comp.formData.branch_comment.customer_input;
            comp.branchCAI = comp.formData.branch_id.is_changed_by_call_agent
              ? comp.formData.branch_id.call_agent_input
              : comp.formData.branch_id.customer_input;
            comp.branchCI = comp.formData.branch_id.customer_input;
            comp.residencestatusEditCAI = comp.formData.state_of_residence
              .is_changed_by_call_agent
              ? comp.formData.state_of_residence.call_agent_input
              : comp.formData.state_of_residence.customer_input;
            comp.residencestatusEditCI =
              comp.formData.state_of_residence.customer_input;
            comp.natureOfBusinessEditCAI = comp.formData
              .profession_nature_of_business.is_changed_by_call_agent
              ? comp.formData.profession_nature_of_business.call_agent_input
              : comp.formData.profession_nature_of_business.customer_input;
            comp.natureOfBusinessEditCI =
              comp.formData.profession_nature_of_business.customer_input;
            comp.employerEditCAI = comp.formData.employer
              .is_changed_by_call_agent
              ? comp.formData.employer.call_agent_input
              : comp.formData.employer.customer_input;
            comp.employerEditCI = comp.formData.employer.customer_input;
            comp.embuildingNumEditCAI = comp.formData.emp_building_number
              .is_changed_by_call_agent
              ? comp.formData.emp_building_number.call_agent_input
              : comp.formData.emp_building_number.customer_input;
            comp.embuildingNumEditCI =
              comp.formData.emp_building_number.customer_input;
            comp.embuildingNameEditCAI = comp.formData.emp_building_name
              .is_changed_by_call_agent
              ? comp.formData.emp_building_name.call_agent_input
              : comp.formData.emp_building_name.customer_input;
            comp.embuildingNameEditCI =
              comp.formData.emp_building_name.customer_input;
            comp.emStreetEditCAI = comp.formData.emp_street
              .is_changed_by_call_agent
              ? comp.formData.emp_street.call_agent_input
              : comp.formData.emp_street.customer_input;
            comp.emStreetEditCI = comp.formData.emp_street.customer_input;
            comp.emTownEditCAI = comp.formData.emp_town.is_changed_by_call_agent
              ? comp.formData.emp_town.call_agent_input
              : comp.formData.emp_town.customer_input;
            comp.emTownEditCI = comp.formData.emp_town.customer_input;
            comp.emSPostalCodeEditCAI = comp.formData.emp_postal_code
              .is_changed_by_call_agent
              ? comp.formData.emp_postal_code.call_agent_input
              : comp.formData.emp_postal_code.customer_input;
            comp.emSPostalCodeEditCI =
              comp.formData.emp_postal_code.customer_input;
            comp.areYouworkingEditCAI = comp.formData
              .are_you_work_on_another_platform.is_changed_by_call_agent
              ? comp.formData.are_you_work_on_another_platform.call_agent_input
              : comp.formData.are_you_work_on_another_platform.customer_input;
            comp.areYouworkingEditCI =
              comp.formData.are_you_work_on_another_platform.customer_input;
            comp.otherPlatformWorkingOnCI = comp.formData
              .other_platforms_working_on.customer_input
              ? comp.formData.other_platforms_working_on.customer_input
              : [];
            comp.otherPlatformWorkingOnCAI = comp.formData
              .other_platforms_working_on.is_changed_by_call_agent
              ? comp.formData.other_platforms_working_on.call_agent_input
                ? comp.formData.other_platforms_working_on.call_agent_input
                : []
              : comp.formData.other_platforms_working_on.customer_input
              ? comp.formData.other_platforms_working_on.customer_input
              : [];
            comp.otherPlatformDescriptionCI =
              comp.formData.other_platforms_working_on_other_description.customer_input;
            comp.otherPlatformDescriptionCAI = comp.formData
              .other_platforms_working_on_other_description
              .is_changed_by_call_agent
              ? comp.formData.other_platforms_working_on_other_description
                  .call_agent_input
              : comp.formData.other_platforms_working_on_other_description
                  .customer_input;
            comp.nicIssueDateEditCAI = comp.formData.nic_eic_issue_date
              .is_changed_by_call_agent
              ? new Date(comp.formData.nic_eic_issue_date.call_agent_input)
              : new Date(comp.formData.nic_eic_issue_date.customer_input);
            comp.nicIssueDateEditCI = new Date(
              comp.formData.nic_eic_issue_date.customer_input
            );
            comp.passIssueDateEditCAI = comp.formData.passport_issue_date
              .is_changed_by_call_agent
              ? new Date(comp.formData.passport_issue_date.call_agent_input)
              : new Date(comp.formData.passport_issue_date.customer_input);
            comp.passIssueDateEditCI = new Date(
              comp.formData.passport_issue_date.customer_input
            );
            comp.districtEditCAI = comp.formData.district
              .is_changed_by_call_agent
              ? comp.formData.district.call_agent_input
              : comp.formData.district.customer_input;
            comp.districtEditCI = comp.formData.district.customer_input;
            comp.districtEditCIAutoComplete = comp.districtEditCI;
            comp.districtEditCAIAutoComplete = comp.districtEditCAI;
            comp.countryEditCAI = comp.formData.country.is_changed_by_call_agent
              ? comp.formData.country.call_agent_input
              : comp.formData.country.customer_input;
            comp.countryEditCI = comp.formData.country.customer_input;
            comp.countryEditCIAutoComplete = comp.countryEditCI;
            comp.countryEditCAIAutoComplete = comp.countryEditCAI;

            comp.coDistrictEditCAI = comp.formData
              .correspondence_address_district.is_changed_by_call_agent
              ? comp.formData.correspondence_address_district.call_agent_input
              : comp.formData.correspondence_address_district.customer_input;
            comp.coDistrictEditCI =
              comp.formData.correspondence_address_district.customer_input;
            comp.corrDistrictEditCIAutoComplete = comp.coDistrictEditCI;
            comp.corrDistrictEditCAIAutoComplete = comp.coDistrictEditCAI;

            comp.emDistrictEditCAI = comp.formData.emp_district
              .is_changed_by_call_agent
              ? comp.formData.emp_district.call_agent_input
              : comp.formData.emp_district.customer_input;
            comp.emDistrictEditCI = comp.formData.emp_district.customer_input;
            comp.emDistrictEditCIAutoComplete = comp.emDistrictEditCI;
            comp.emDistrictEditCAIAutoComplete = comp.emDistrictEditCAI;

            comp.coCountryEditCAI = comp.formData.correspondence_address_country
              .is_changed_by_call_agent
              ? comp.formData.correspondence_address_country.call_agent_input
              : comp.formData.correspondence_address_country.customer_input;
            comp.coCountryEditCI =
              comp.formData.correspondence_address_country.customer_input;
            comp.CoCountryEditCIAutoComplete = comp.coCountryEditCI;
            comp.CoCountryEditCAIAutoComplete = comp.coCountryEditCAI;

            comp.emCountryEditCAI = comp.formData.emp_country
              .is_changed_by_call_agent
              ? comp.formData.emp_country.call_agent_input
              : comp.formData.emp_country.customer_input;
            comp.emCountryEditCI = comp.formData.emp_country.customer_input;
            comp.EmCountryEditCIAutoComplete = comp.emCountryEditCI;
            comp.EmCountryEditCAIAutoComplete = comp.emCountryEditCAI;

            comp.areYouOrCloseRelativeCI = comp.formData.pep_declaration
              .customer_input.iam_politically_exposed
              ? "I"
              : comp.formData.pep_declaration.customer_input
                  .close_relative_politically_exposed
              ? "Relative"
              : "No";
            comp.areYouOrCloseRelativeCIPermanent =
              comp.areYouOrCloseRelativeCI;
            comp.areYouOrCloseRelativeCAI = !comp.formData.pep_declaration
              .is_changed_by_call_agent
              ? ""
              : comp.formData.pep_declaration.call_agent_input
                  .iam_politically_exposed
              ? "I"
              : comp.formData.pep_declaration.call_agent_input
                  .close_relative_politically_exposed
              ? "Relative"
              : "No";
            comp.areYouOrCloseRelativeCAIPermanent =
              comp.areYouOrCloseRelativeCAI;

            comp.pepPersonDetailsCI =
              comp.formData.pep_declaration.customer_input.pep_person_details.map(
                (pep) => {
                  return { ...pep };
                }
              );
            comp.pepPersonDetailsCAI = comp.formData.pep_declaration
              .is_changed_by_call_agent
              ? comp.formData.pep_declaration.call_agent_input.pep_person_details.map(
                  (pep) => {
                    return { ...pep };
                  }
                )
              : [];

            comp.hideFullScreenLoader();
          } else {
            // BuefyHelper.showToastMessage("Unable to get the application data", "is-danger")
            comp.hideFullScreenLoader();
          }
        }
      );
    },

    onUpload: function () {
      console.log("on upload -------------------");
      const comp = this;

      if (comp.file !== null && comp.file["type"].split("/")[0] === "image") {
        console.log("uploaded image size (bytes): " + comp.file.size);
        // validate image size
        if (comp.file.size <= 5245329) {
          comp.imageToCrop = URL.createObjectURL(comp.file);
          comp.isActive = false;
          comp.initiateImageCrop();
        } else {
          comp.file = [];
          BuefyHelper.showToastMessage(
            "Please select an image of the maximum size of 5MB",
            "is-danger"
          );
        }
      } else {
        BuefyHelper.showToastMessage("Select an image file", "is-danger");
      }
    },

    cropImage: function () {
      var croppedImageCanvas = this.$refs.cropper.getCroppedCanvas();
      var croppedImageFile = null;

      //console.log(croppedImageCanvas);

      this.croppedImg = croppedImageCanvas.toDataURL();
      this.imageUrl = this.croppedImg;

      this.cropModalIsVisible = false;
      // this.$emit('on-captured', this.croppedImg) // original code

      // convert canvas into a blob
      var fileName = "attachment" + this.count + ".jpg";

      croppedImageCanvas.toBlob((blob) => {
        let file = new File([blob], fileName, { type: "image/jpeg" });
        this.cards.forEach((card) => {
          if (card.id == this.selectedCardID) {
            card.image = this.croppedImg;
            card.file = file;
          }
        });

        // add new card
        if (this.count < this.maxCards) {
          this.addCard(this.defaultimg);
        }
      }, "image/jpeg");

      console.log("file cropped");
    },

    clearselect: function () {
      this.cropModalIsVisible = false;
      this.file = null;
      this.croppedImg = null;
      this.imageUrl = null;
    },

    initiateImageCrop: function () {
      this.cropModalIsVisible = true;
    },

    submitimages: function () {
      const data = new FormData();
      data.append(`kyc_form_id`, this.authStore.kyc_form_id);
      data.append(
        "is_call_agent",
        this.hasPermission("assign-scheduled-application")
      );
      for (let i = 0; i < this.cards.length; i++) {
        //console.log(this.cards[i]["file"]);
        if (this.cards[i]["image"]) {
          data.append(`attachments_image${i + 1}`, this.cards[i]["file"]);
          data.append(
            `attachments_comment${i + 1}`,
            this.cards[i]["description"]
          );
        }
      }
      this.showFullScreenLoader();
      NetworkManager.apiRequest(
        "api/KycApplication/UploadAttchmentsInHold",
        data,
        (response) => {
          if (response.statusCode == 200) {
            const params = {
              type: "is-success",
              message: "Attachments upload successfully",
              duration: 5000,
            };
            this.$buefy.toast.open(params);
            this.hideFullScreenLoader();
            this.GetApplicationData();
          } else {
            this.hideFullScreenLoader();
            const params = {
              type: "is-warning",
              message: "Attachments upload failed",
              duration: 5000,
            };
            this.$buefy.toast.open(params);
          }
        },
        false,
        "multipart/form-data"
      );
    },
  },
  computed: {
    industryFilteredDataArray() {
      return this.industries.filter((option) => {
        return (
          option.description
            .toLowerCase()
            .indexOf(this.industry.toLowerCase()) >= 0
        );
      });
    },
    occupationFilteredDataArray() {
      return this.occupations.filter((option) => {
        return option.toLowerCase().indexOf(this.occupation.toLowerCase()) >= 0;
      });
    },
    districtFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.districtEditCIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    districtFilteredDataArrayCAI() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.districtEditCAIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    corCiDistrictFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.corrDistrictEditCIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    corCaiDistrictFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.corrDistrictEditCAIAutoComplete.toLowerCase()) >= 0
        );
      });
    },

    emDistrictFilteredDataArray() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.emDistrictEditCIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    emDistrictFilteredDataArrayCAI() {
      return this.$root.formDataSupport.districtsArray.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.emDistrictEditCAIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    countryFilteredDataArray() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.countryEditCIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    countryFilteredDataArrayCai() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.countryEditCAIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    CorCountryFilteredDataArray() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.CoCountryEditCIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    CorCountryFilteredDataArrayCai() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.CoCountryEditCAIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    emCountryFilteredDataArray() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.EmCountryEditCIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    emCountryFilteredDataArrayCai() {
      return this.$root.formDataSupport.countriesArray.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.EmCountryEditCAIAutoComplete.toLowerCase()) >= 0
        );
      });
    },
    GlobalFunctions() {
      return GlobalFunctions;
    },
    showButton() {
      return this.count <= this.maxCards;
    },

    ShowActionButtons() {
      const comp = this;
      return (
        comp.hasPermission("approve-application") &&
        comp.formData.current_status === "T24-CIF-OVERRIDES"
      );
    },

    ShowActionBtnForCorrectedOverrides() {
      const comp = this;
      return (
        comp.hasPermission("approve-application") &&
        comp.formData.current_status ===
          GlobalAttributes.kycFormStatus.T24_CORRECTED_OVERRIDES
      );
    },
  },

  // lifecycle hooks ===================================================================================================
  created() {
    const comp = this;
    // listening to global events

    GlobalEventManager.$on(
      GlobalEvents.admin.kycApplications.showInputIndustryModal,
      () => {
        comp.showAddIndustryModal = true;
      }
    );

    GlobalEventManager.$on(
      GlobalEvents.admin.kycApplications.hideInputIndustryModal,
      () => {
        comp.showAddIndustryModal = false;
      }
    );

    GlobalEventManager.$on(
      GlobalEvents.admin.kycApplications.hideInputIndustryModalAndLoadData,
      () => {
        comp.showAddIndustryModal = false;
        comp.GetApplicationData();
      }
    );
  },

  mounted() {
    this.getT24Industries();
    this.getT24Occupations();
    if (!this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }

    this.GetApplicationData();
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  //margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 15px 10px;
  background: white;
  //box-shadow: 0px -11px 9px -12px rgba(0, 0, 0, 0.75);
  //-webkit-box-shadow: 0px -11px 9px -12px rgba(0,0,0,0.75);
  //-moz-box-shadow: 0px -11px 9px -12px rgba(0,0,0,0.75);
}

.action-btn {
  min-width: 150px;
  margin-left: 20px;
}

.button.delete:hover {
  background-color: #ed1c24 !important;
}
.delete {
  position: relative;
  right: -10px;
  top: 5px;
}
#input-separator {
  margin-bottom: 20px;
}
p {
  color: #64676a;
}
p span {
  color: #231f20;
  font-family: "Gotham-Light";
}
.check p {
  color: #231f20;
  text-transform: uppercase;
  font-family: "Gotham-Bold";
}
.wrapper {
  position: relative;
}
.image {
  width: 960px;
  height: 540px;
  border: 2px solid #000000;
  border-radius: 25px;
  padding: 20px;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.rating-wrapper label {
  font-size: medium;
}

crop-content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.modal-card {
  border-radius: 0;
}
.non-interactable-input {
  pointer-events: none;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.source-list-item {
  width: 150px;
  height: 64px;
  padding: 20px;
}
.source-list {
  flex-wrap: wrap;
  /*padding: 0.5rem;*/
  align-items: baseline;
}
.wrapper-customer {
  margin-top : 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 25px;
}

.wrapper-agent {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid red;
  border-radius: 20px;
}
.item-customer {
  color: black;
}

.item-agent {
  color: red;
}
</style>
