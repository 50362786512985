<template>
  <section style="overflow: auto !important">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="" active >
              <label style="font-family:Gotham-Medium; color: gray; font-weight: 400;">Roles</label>
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half" >
            <b-input
              placeholder="Search..."
              rounded
              type="search"
              icon="magnify"
              icon-clickable
              v-model="searchKeyword"
              style="width:450px"

            >
            </b-input>
          </div> 
          <div class="column">
            <b-button v-if="hasPermission('role-create')" type="is-primary is-small" class="is-pulled-right"  icon-left="account-plus-outline" @click="navigateTo('CreateRole')" rounded>Create Role</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <filterBranch></filterBranch>
    </div> -->
    <section>
    <div>
      <section style="margin-top:10px; margin-left: 20px; height: 100px;overflow: visible; position: relative!important;"> 
        <div class="columns">
          <div class="column">
            <b-field >Date Range
              <b-datepicker
              
                rounded
                v-model="startDate"
                :max-date="maxStartDate"
                :show-week-number="false"
                :append-to-body="true"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus>
              </b-datepicker>
            </b-field>
          </div>
          <br><br> To
          <div class="column">
            <b-field> 
              <b-datepicker
                rounded
                v-model="endDate"
                :max-date="maxStartDate"
                :show-week-number="false"
                :append-to-body="true"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
                style="margin-top: 21px;">
              </b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field> 
            <b-button type="is-primary"
                icon-left="magnify"
                @click="filter"
                style="margin-top: 21px; width: 200px;z-index: 9999 !important">
                Filter
            </b-button>
            </b-field>
          </div>
          <div class="column">
            <b-field> 
            <b-button  class="clear-filter-btn"
                type="is-primary" outlined
                icon-left="magnify"
                @click="clear"
                style="margin-top: 21px; width: 200px;">
                Clear
            </b-button>
            </b-field>
          </div>
        </div>  
      </section>
    </div>
    <div class="card-content" style="overflow: auto; height: 55vh;">     

      <b-table
          :data="tableData.searchData(roleList, searchableFields, searchKeyword)"
          :paginated="tableOptions.isPaginated"
          :per-page="tableOptions.perPage"
          :current-page.sync="tableOptions.currentPage"
          :pagination-simple="tableOptions.isPaginationSimple"
          :pagination-position="tableOptions.paginationPosition"
          :default-sort-direction="tableOptions.defaultSortDirection"
          :pagination-rounded="tableOptions.isPaginationRounded"
          :sort-icon="tableOptions.sortIcon"
          :sort-icon-size="tableOptions.sortIconSize"
          default-sort="id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          
          :page-input="tableOptions.hasInput"
          :pagination-order="tableOptions.paginationOrder"
          :page-input-position="tableOptions.inputPosition"
          :debounce-page-input="tableOptions.inputDebounce"
          :sticky-header="true"
          >

        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
          {{ props.row.id}}
        </b-table-column>

        <b-table-column field="role_name" label="Role Name" sortable v-slot="props">
          {{ props.row.role_name }}
        </b-table-column>

        <b-table-column field="permissions" label="Permissions" sortable v-slot="props">
          <b-taglist style="max-width: 600px !important;display: flex !important; flex-wrap: wrap:  !important;; ">
            <b-tag v-for="permission in props.row.permissions.split('|')" :key="permission" type="is-primary is-light"> {{permission}}</b-tag>

          </b-taglist>
        </b-table-column>

        <!-- <b-table-column field="branch" label="Branch" sortable v-slot="props">
          {{ props.row.branch }}
        </b-table-column> -->


        <b-table-column field="created_at_upm" label="Created At" sortable centered v-slot="props">
                <span>
                    {{ DateTimeManger.getDisplayDateForUPM(props.row.created_at_upm)}}
                </span>
        </b-table-column>

        <b-table-column field="updated_at_upm" label="Updated At" sortable centered v-slot="props">
                <span >
                    {{ DateTimeManger.getDisplayDateForUPM(props.row.updated_at_upm)}}
                </span>
        </b-table-column>

        <b-table-column field="status" label="Status" sortable v-slot="props">
          <span >
            <b-tooltip :label="props.row.status === 'Active' ? 'Active': 'Not Active'" :type="props.row.status === 'Active' ? 'is-success' : 'is-danger'">
               <b-icon
                icon="circle"
                size="is-small"
                :type="props.row.status ==='Active' ? 'is-success' : 'is-danger'"
                rounded/>
               </b-tooltip>  
            </span>
        </b-table-column>

        <b-table-column field="action" label="Action" v-slot="props">
          <b-tooltip v-if="props.row.locked=== 0 && hasPermission('role-edit')" label="Update">
            <b-button rounded
                      style="width:auto; height: auto;"
                      type="is-primary"
                      size="is-small"
                      @click="navigateTo('EditRole', {id: props.row.id})"
                      icon-left="lead-pencil"
                      > </b-button>
          </b-tooltip>
          <b-tooltip v-else label="Locked">
            <!-- <b-icon
                icon="lock"
                size="is-medium">
            </b-icon> -->
            <b-button rounded
                      style="width:auto; height: auto;"
                      type="is-dark"
                      size="is-small"
                      icon-left="lock"></b-button>

          </b-tooltip>
        </b-table-column>

      </b-table>
    </div>
    </section>
    <br />
  </section>
</template>

<script>
import NetworkManager from "@/network"
import FilterBranch from "../../components/FilterBranch.vue";
import SearchBar from "../../components/SearchBar.vue";
// import {DateTimeManger} from "../../helpers/dateTimeManger";
import { filterTableData } from "../../helpers/filterTableData";
import {DateTimeManger} from "../../helpers/dateTimeManger";

export default {
  name: 'RoleList',
  components: {
    'searchBar':SearchBar,
    'filterBranch':FilterBranch
    
  },
  data() {
    return {
      roles: [],
      crData: [],
      crIsLoading: true,
      filterOutFromCR: ['id', 'created_at_upm', 'updated_at_upm', 'locked'],

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,

        
        
      },
       searchableFields: ['id', 'role_name', 'permissions','created_at_upm', 'updated_at_upm'],
       searchKeyword: "",
       tableData:"",
       roleList:[],
       startDate : null,
       endDate : null,
       maxStartDate : new Date()

    };
  },

  computed: {
    DateTimeManger() {
      return DateTimeManger
    }
  },

  created() {
    this.tableData = filterTableData
  },

  methods: {
    clear:function(){
      const self = this;
      self.startDate = null
      self.endDate = null
      self.loadData(null, null)
    },
    filter: function (){
      this.loadData(this.startDate, this.endDate)
    },
    loadData: function (startDate,endDate){
      const  self = this
      
      self.showFullScreenLoader()
      const data = {
        start_date : startDate,
        end_date : endDate

      }
      NetworkManager.apiRequest('api/role/list', data, function (e){
        if(e.statusCode === 200){
          self.roles = []
          self.roleList = []
          self.roles = e.data.roles

          self.roles.forEach(role => {

            self.roleList.push({
              'id':role.id.value,
              'created_at_upm':role.created_at.value,
              'locked':role.locked.value,
              'role_name':role.role_name.value,
              'status':role.status.value,
              'updated_at_upm':role.updated_at.value,
              'user_level':role.user_level.value,
              'user_type':role.user_type.value,
              'permissions':role.permissions.value.join('|')
            })
          })
        }
        self.hideFullScreenLoader()
      })
    },

    approve_or_reject: function (data){
      const self = this
      this.crIsLoading = true

      NetworkManager.apiRequest('api/role/approve', data, function (e){
        if(e.statusCode === 200){
          const params = {
            type: 'is-success',
            message: 'Role ' + data.actionTaken + ' successfully',
            duration: 5000,
          }
          self.$buefy.toast.open(params)
          location.reload()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'Role ' + data.actionTaken + ' failed!',
            duration: 5000,
          }
          self.$buefy.toast.open(params)
        }

        self.crIsLoading = false
      })
    },

    // getFormattedDateTime(date){
    //   return DateTimeManger.formatDateTime(date)
    // },

  },

  mounted() {
    this.loadData(null, null)
  }
};
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}
.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
.b-table .table-wrapper.has-sticky-header {
    height: 40vh !important;
    overflow-y: auto;
   
}


</style>
