<template>
  <div>
    <b-modal v-model="isModalActive" scroll="keep" :width="700" :can-cancel="false">
      <div class="model_box mt-3">
        <div
          class="has-text-centered"
          style="transition-timing-function: ease-in-out"
        >
          <header class="modal-card-head" style="background-color: white">
            <p class="modal-card-title has-text-centered is-size-3">
              <strong class="has-text-primary is-uppercase"
                >Account creation</strong
              >
            </p>
            <button
                v-if="!hideCloseBtn"
              @click="closeModal"
              type="button"
              class="delete"
            ></button>
          </header>
          <div
            style="padding-inline: 10% !important"
            v-if="!isShowSecondModalContent"
          >
            <hr />
            <p :class="line" v-if="imgline" class="is-uppercase is-size-4">
              {{ imgline }}
            </p>
            <img
              :src="modalImage"
              :alt="modalImageAltText"
              :width="modalImageWidth"
              :class="modalImageClass"
            />

            <p class="is-uppercase is-size-4" v-if="modalfirstline">
              {{ modalfirstline }}
            </p>
            <p class="has-text-primary mt-2 is-size-3" v-if="modalsecondline">
              {{ modalsecondline }}
            </p>
            <p style="color: #a7a9ac" class="is-size-5" v-if="modalthirdline">
              {{ modalthirdline }}
            </p>
            <p style="color: #a7a9ac" class="is-size-5 mt-5" v-if="errorLine">
              You can close this window and try again in the “<b
                style="color: #64676a"
                >{{ errorLine }}</b
              >”.
            </p>
            <p
              style="color: #a7a9ac"
              class="is-size-5 mt-5"
              v-if="errorLineSecond"
            >
              You can close this window and your application has been
              automatically moved to the “<b style="color: #64676a">{{
                errorLineSecond
              }}</b
              >”.
            </p>
          </div>
          <div
            style="padding-inline: 10% !important"
            v-if="isShowSecondModalContent"
          >
            <hr />

            <p class="is-size-3 mb-2">CIF Number</p>
            <div
              class="is-flex is-flex-direction-row is-justify-content-center mb-5"
            >
              <div
                class="boxDesign is-flex is-justify-content-center is-align-items-center"
                v-for="(char, index) in cifNumber"
                :key="`${char}${index}`"
              >
                {{ char }}
              </div>
              <button
                @click="copyToCifClipboard()"
                class="button is-primary p-5"
                style="border-radius: 5px !important"
              >
                <span class="mdi mdi-content-copy"></span>
              </button>
            </div>
            <img
              src="../assets//Images//Icons/done.png"
              alt="Done"
              width="80px"
              class="mt-5"
            />
            <p class="is-uppercase is-size-3">Account creation success!</p>
            <div
              v-for="(accountNumber, index) in accountNumbers"
              :key="accountNumber.accountNumber"
            >
              <p class="mt-1 is-size-4 mb-2">
                {{ `${accountNumber.productName} Account Number` }}
              </p>
              <div
                class="is-flex is-flex-direction-row is-justify-content-center mb-5"
              >
                <div
                  class="boxDesign is-flex is-justify-content-center is-align-items-center"
                  v-for="(char, index2) in accountNumber.accountNumber"
                  :key="`${char}${index2}`"
                >
                  {{ char }}
                </div>
                <button
                  @click="copyToAccClipboard(accountNumber.accountNumber)"
                  class="button is-primary p-5"
                  style="border-radius: 5px !important"
                >
                  <span class="mdi mdi-content-copy"></span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="has-text-centered"
            style="transition-timing-function: ease-in-out"
          >
            <b-button
                v-if="!hideCloseBtn"
              type="is-primary"
              class="py-5 mt-5"
              @click="modalClose()"
              style="padding-inline: 100px !important"
              rounded
              >OK
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CifModalScreen",
  data() {
    return {
      isModalActive: true,
    };
  },
  methods: {

    closeModal(){
      const comp = this
      comp.isModalActive = false
      comp.$router.replace(comp.closeModalRedirectRoute)
    },

    copyToCifClipboard() {
      navigator.clipboard.writeText(this.cifNumber);
      this.$buefy.toast.open({
        message: "CIF Number Copied",
        type: "is-primary",
      });
    },
    copyToAccClipboard(accountNumber) {
      navigator.clipboard.writeText(accountNumber);
      this.$buefy.toast.open({
        message: "Account Number Copied",
        type: "is-primary",
      });
    },
    copyToClipboard: function () {
      const self = this;

      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = self.newPassword.value;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      self.$buefy.toast.open({
        message: "Copied to Clipboard",
        duration: 5000,
      });
    },
  },
  props: [
    "modalImage",
    "modalImageAltText",
    "modalImageClass",
    "modalImageWidth",
    "modalfirstline",
    "modalsecondline",
    "modalthirdline",
    "errorLine",
    "errorLineSecond",
    "line",
    "imgline",
    "isShowSecondModalContent",
    "cifNumber",
    "accountNumbers",
    "modalClose",
    "hideCloseBtn",
    "closeModalRedirectRoute"
  ],
};
</script>

<style lang="scss" scoped>
.img_blend {
  mix-blend-mode: darken;
  opacity: 0.4000000059604645;
}
.line {
  position: absolute;
  top: 220px;
}
hr {
  stroke-width: 2px;
  stroke: var(--text, #231f20);
  opacity: 0.30000001192092896;
  margin-bottom: 1px !important;
  text-align: center !important;
}
.model_box {
  border-radius: 20px;
  border: 1px solid #ed1c24 !important;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
}
.boxDesign {
  width: 47.927px;
  height: 47.927px;
  border-radius: 4.985px;
  border: 0.831px solid var(--text, #231f20);
  background: #fff;
  color: var(--red, #ed1c24);
  font-size: 26.587px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-inline: 7px;
}
</style>
