export class FilteredUserByRole {
    id = 0
    username = ""
    name = ""

    constructor(id, username, name) {
        this.id = id;
        this.username = username;
        this.name = name;
    }

}