import User from "@/mixins/modules/authMixins";
export default function permission({ to, from,next, router }) {
   
    if (!hasPermissionsNeeded(to)) {
        next('login');
    } else {
        return next()

    }
    function hasPermissionsNeeded(to) {
        var permissions = to.meta.permissions;
        var hasPermission = false;

        permissions.forEach(element => {

            if(User.hasPermission(element)){
                hasPermission = true;
            }
        });

        return hasPermission;
    };

    return next()

}
