import {DateTimeManger} from "./dateTimeManger";

const filterTableData = {

    searchData(sourceData, fields, keyword) {
        var outputArray = [];

        if(sourceData){
            sourceData.forEach(function(row){
                let pushed = false

                fields.forEach(function(fieldName){
                    // variable to hold display value of a field
                    let fieldValue = ""

                    // get display value of fields
                    if(fieldName === "received_date" || fieldName === "completed_date" || fieldName === 'created_at' || fieldName === 'status_updated_at'){
                        fieldValue = DateTimeManger.formatDateTime(String(row[fieldName]))
                    }
                    else if(fieldName === "video_call_date"){
                        fieldValue = DateTimeManger.formatDate(String(row[fieldName]))+" "+String(row['video_call_time'])
                    }
                    else if(fieldName === "updated_at_upm" || fieldName === "created_at_upm"){
                        fieldValue = DateTimeManger.getDisplayDateForUPM(String(row[fieldName]))
                    }
                    else if(fieldName === "timestamp"){
                        fieldValue = DateTimeManger.getTimestampForActivityLog(String(row[fieldName]))
                    }
                    else{
                        fieldValue = String(row[fieldName]);
                    }

                    if(keyword && keyword.charAt(0) ==='+'){
                        // removing + sign from the keyword, otherwise it returns a regex error
                        console.log("first letter is +")
                        if(keyword.length === 1){
                            keyword = ""
                        }
                        else{
                            keyword = keyword.substring(1)
                        }
                    }


                    console.log("keyword : "+keyword)

                    if(fieldValue.match(RegExp(keyword, "i")) && !pushed){
                        outputArray.push(row)
                        pushed = true
                    }
                });
            });
        }
        return outputArray
    }
}
export {filterTableData}
