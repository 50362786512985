export default class VideoCallNotification {
    customer
    agent
    kycForm
    kycFormProductSelection
    questionDetails
    videoCallDate
    videoCallTime
    location
    nextAssignedVideoCall = null
    callMaxDurationMinutes
    videoCallVerificationQuestionList


    constructor() {
        this.customer = ""
        this.agent = ""
        this.kycForm = null
        this.kycFormProductSelection = null
        this.questionDetails = null
        this.videoCallDate = ""
        this.videoCallTime = ""
        this.location = null
        this.callMaxDurationMinutes = 0
        this.nextAssignedVideoCall = null,
        this.videoCallVerificationQuestionList= null
    }
}
