export class CommonKycDetails {
    id = 0
    nic = ""
    full_name = ""
    source=""
    status=""
    received_date=""
    remark=""

    constructor(id,nic,full_name,source,status,received_date,remark) {
        this.id = id;
        this.nic = nic;
        this.full_name = full_name;
        this.source = source;
        this.status = status;
        this.received_date = received_date;
        this.remark = remark;

    }
}