<template>
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 600px;
      background-color: white;
      border: 2px;
      border-radius: 25px;
      padding: 20px;">
      <div>
        <div class="columns" style="width: 600px;">
          <div class="column" style="text-align: center;">
            <label style="color: #ED1C24; font-size: 24px;">Pending for Debit Card</label>
          </div> 
          <div class="column" style="margin-left: 87%; position: absolute;">
            <b-button @click="emitCloss">x</b-button>
          </div> 
        </div> 
      </div>
        

      
      <div class="message-box-body" >
        Add Comment
        <b-input 
          rounded maxlength="200" 
          v-model="PDC_comment"
          @input="emitPDCcomment"
          type="textarea" 
          placeholder="Specify" 
          rows = 4
          style="width: 100%;
          margin-top: 10px;" 
        ></b-input> 
      </div>

      <div style="margin-left: auto; margin-right: auto; text-align: center;">
        <b-button style=" width: 400px;" type="is-primary" @click="emitPendingForDebitCard">Reviewed</b-button>
      </div>
    </div>
  <!-- </b-modal>  -->
</template>

<script>
import {useAuthStore} from "../store/authStore"; 

export default {
  name: 'PendingForDebitCard',
  props: {
    title: String,
    message: String,
    show: Boolean,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  emits:['close'],

  data(){
    return{
      PDC_comment :'',
      isvisible : true,
      PDC_isButtonDisabled: false,
      PDC_showMessageBox: false,
    }
  },
  methods: {
    emitPDCcomment() {
      this.$emit('update_PDC_comment', this.PDC_comment);
    },
    emitPendingForDebitCard() {
      this.$emit('reviewed',this.App_isButtonDisabled,this.App_showMessageBox);
    },
    emitCloss(){
      this.$emit('Closs_PDC',this.App_showMessageBox)
    },
    
  },
  mounted()
  {
  }
};
</script>

<style scoped>

</style>
