<template>
  <div>
    <div :class="styles">
      <label>{{ label }}</label>
      <div
        style="border-radius: 10px; border: 1px solid black; padding: 10px"
        class="has-text-centered"
      >
        <b-image
          :src="!imageName ? imagePlaceholder : imageName"
          placeholder="/static/img/icons/favicon-32x32.png"
          responsive
          style="margin: auto"
        ></b-image>
        <span>{{ nameOfImage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageBox",
  props: ["label", "styles", "imageName", "nameOfImage"],
  components: {},
  data() {
    return {
      isLabelFocus: false,
      vModelVaribale: this.vModel,
      imagePlaceholder: require("../assets/Images/Icons/image_placeholder.webp"),
    };
  },
  mounted() {
    console.log("image name ------", this.imageName);
  },
};
</script>

<style lang="scss" scoped></style>
