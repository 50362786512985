

<template>
  <div class="table-comp-wrapper">
    <b-table
        style="
        height: auto ;
        max-height: 100vh"
        v-if="tableData.length >= 0"
        :data="filterTableData.searchData(tableData, searchableFields, searchKeyword)" :class="styles"
        :paginated="tableOptions.isPaginated"
        :per-page="tableOptions.perPage"
        :current-page.sync="tableOptions.currentPage"
        :pagination-simple="tableOptions.isPaginationSimple"
        :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection"
        :pagination-rounded="tableOptions.isPaginationRounded"
        :sort-icon="tableOptions.sortIcon"
        :sort-icon-size="tableOptions.sortIconSize"
        default-sort="id"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :sticky-header="true"
        :page-input="tableOptions.hasInput"
        :pagination-order="tableOptions.paginationOrder"
        :page-input-position="tableOptions.inputPosition"
        :debounce-page-input="tableOptions.inputDebounce">


      <b-table-column
          v-for="column in columns"
          :key="column.id"
          :field="column.field"
          :label="column.label"
          v-slot="props"
          :sortable="column.field === 'timestamp'"

      >

        <span v-if="!column.date && !column.hide && column.field ==='level'">
          <b-icon
              icon="record"
              size="is-small"
              v-bind:class="'log-icon-'+props.row[column.field].toLowerCase()"
              :title="props.row.level"
          />

        </span>

        <span v-if="!column.date && !column.hide && column.field !== 'level'">
          {{ props.row[column.field] }}
        </span>

        <span v-if="(column.date ) && !column.hide">
          {{ getFormattedTimestamp(props.row[column.field]) }}
        </span>

      </b-table-column>

    </b-table>
    <div class="mt-5 has-text-centered" v-if="tableData.length === 0" style="display: flex; justify-content: center; align-items: center; ">
      <div style=" margin-top: -40% ">
          <span class="icon has-text-grey-lighter is-normal">
               <i class="mdi mdi-information-outline mdi-48px"></i>
          </span>
        <br>
        <!-- <span class="ml-2 has-text-grey-lighter" style="font-size: 20px; margin-top: -170px;">No records to show</span> -->
      </div>

      <br>

      <br>
      <span class="has-text-grey-lighter">{{$props.customText}}</span>
    </div>

  </div>
</template>

<script>

import {DateTimeManger} from "../../helpers/dateTimeManger";
import {useAuthStore} from "../../store/authStore";
import {filterTableData} from "../../helpers/filterTableData";


export default {
  computed: {
    filterTableData() {
      return filterTableData
    }
  },


  props: ["tableData", "columns", "styles","searchableFields","searchKeyword","customText"],

  name: "ActivityLogTableComponent",

  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },

  data() {
    return {

      type:'',
      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },
    };

  },

  methods:{

    getFormattedTimestamp(date){
      return DateTimeManger.getTimestampForActivityLog(date)
    },


  },


  // life cycle hooks
  mounted(){

  },
}

</script>
<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display:grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.scrollable-table {
  max-width: 75vw;
  overflow-x: auto;
}
.table-comp-wrapper {
  overflow: auto !important;
}

/*log level icon colors*/

.log-icon-debug{color: #a2a2a2;}

.log-icon-info{color: #1facff;}

.log-icon-notice{color: #f7c200;}

.log-icon-update{color: #5b5fe1}

.log-icon-warning{color: #f7c200;}
.log-description-warning{background-color: #fff795;color: #000;}

.log-icon-error{color: #ff0000;}
.log-description-error{color: #ff0000;}

.log-icon-critical{color: #ff0000;}
.log-description-critical{background-color: #f11d1d;color: #FFF;}

.log-icon-alert{color: #ff0000;}
.log-description-alert{background-color: #f11d1d;color: #FFF;}

.log-icon-emergency{color: #ff0000;}
.log-description-emergency{background-color: #f11d1d;color: #FFF;}

</style>
