<template>
  <div :class="isDanger? 'wrapper-danger': 'wrapper'" id="wrapper">
    <figure  class="figure">
<!--      <img v-if="type === 'image'"-->
<!--           :src="button1"-->
<!--           :alt="alt"-->
<!--           @click="rotateImageClockwise"-->
<!--           class="image-rotate-btn"-->
<!--      />-->
      <b-button v-if="type !== 'image'" rounded
                icon-right=download-circle-outline type=is-primary
                size=is-large
                @click="downloadPdf(url, alt)"
                class="download-btn"/>

      <div class="image-helper" :style="{transform: 'rotate(' + imageAngle + 'deg)' }" id="imageRotator">
        <span class="helper"></span>
        <img id="attachment-image"
            style="vertical-align: middle; max-height: 55vh"
            :src="type === 'image' ? url : pdfPlaceholder"
            @error="imagePlaceHolder"
            :alt="alt"
        />
      </div>
    </figure>
  </div>

</template>

<script>

import {defineComponent} from "vue";

export default defineComponent({
  name: "display-attachment",

  props: {
    url:{
      required: true,
      type: String
    },
    type:{
      required: true,
      type: String
    },
    alt:{
      required : false,
      type : String,
      default: "attachment"
    },
    isDanger:{
      required : false,
      type : Boolean,
      default: false
    }
  },

  watch : {
    imageAngle(newValue,oldValue){
      const comp = this
      // comp.setWrapperWidth()
    }
  },

  data(){
    return {
      button: "https://cdn-icons-png.flaticon.com/512/1040/1040252.png",
      button1: "https://cdn-icons-png.flaticon.com/512/1040/1040252.png",
      pdfPlaceholder : require("../../assets/Images/pdf.png"),
      placeholder: require("../../assets/Images/add.png"),
      imageAngle : 0,
    }
  },

  methods : {
    downloadPdf(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    rotateImageClockwise() {
      this.imageAngle+= 90;
    },

    imagePlaceHolder(event) {
      event.target.src = this.placeholder;
    },

    setWrapperWidth(isHorizontal){
      const comp = this
      const wrapper = document.getElementById("wrapper")
      const image = document.getElementById("attachment-image")
    }
  },

  mounted(){

  }


})

</script>

<style scoped>

:root{
  --wrapper-width : 0px;
}

.image-helper {
  //height: 56vh;
  padding: 10px;
  width: 28.2vw;
  text-align: center;
  transition: transform  0.5s ease; /* Optional transition for rotation */
}
.image-rotate-btn{
  width: 50px;
  position: absolute;
  z-index: 2;
  margin-left: 20vw;
  margin-top: 45vh;
  display: flex;
  cursor: pointer;
}

.download-btn{
  width: 50px;
  position: absolute;
  z-index: 2;
  margin-left: 25vw;
  margin-top: 45vh;
  display: flex;
}

.figure{
  min-width: 28.2vw;
  max-width: 28.2vw;
  height: fit-content;
}

.wrapper{
  border-radius: 10px;
  border : 1px solid black;
  width: fit-content;
  height: fit-content;
}

.wrapper-danger{
  border-radius: 10px;
  border : 1px solid red;
  width: fit-content;
  height: fit-content;
}
</style>