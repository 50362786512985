export class FilterConfigData {
    date = false
    fields = []
    roleFilteringFor = ""
    userSelect = false

    constructor(date,fields,roleFilteringFor,userSelect) {
        this.date = date
        this.fields = fields
        this.roleFilteringFor = roleFilteringFor
        this.userSelect = userSelect
    }
}