export class ApplicationStatusListTableDataItem{
    kyc_form_id
    reference_number
    nic_eic_number
    full_name
    email
    mobile_number
    branch
    agent
    current_status
    created_at
    kyc_approved_at
    gender

    constructor(kycFormId,refNo,nicNo,fullName,email,mobileNo,branch,agent,currentStatus,createdAt,kycApprovedAt,gender) {
        this.kyc_form_id = kycFormId;
        this.reference_number = refNo;
        this.nic_eic_number = nicNo;
        this.full_name = fullName;
        this.email = email;
        this.mobile_number = mobileNo;
        this.branch = branch;
        this.agent = agent;
        this.current_status = currentStatus;
        this.created_at = createdAt;
        this.kyc_approved_at = kycApprovedAt;
        this.gender = gender
    }
}