<template >
  <section>

      <div class="card-content" type="is-primary" style="padding-left: 0px !important; height:65vh">
        <div >
          <b-table 
           :data="data" :paginated="tableOptions.isPaginated"
          :per-page="tableOptions.perPage"
          :current-page.sync="tableOptions.currentPage"
          :pagination-simple="tableOptions.isPaginationSimple"
          :pagination-position="tableOptions.paginationPosition"
          :default-sort-direction="tableOptions.defaultSortDirection"
          :pagination-rounded="tableOptions.isPaginationRounded"
          :sort-icon="tableOptions.sortIcon"
          :sort-icon-size="tableOptions.sortIconSize"
          default-sort="id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :page-input="tableOptions.hasInput"
          :pagination-order="tableOptions.paginationOrder"
          :page-input-position="tableOptions.inputPosition"
          :debounce-page-input="tableOptions.inputDebounce" 
          :type="type">

            <b-table-column field="name" :label="labelname"  v-slot="props" v-if="labelname == `User Name`">
              {{  props.row.newRecord.name.value}}
            </b-table-column>
            
            <b-table-column field="name" :label="labelname"  v-slot="props" v-if="labelname == `Role Name`">
              {{ props.row.newRecord.role_name.value}}
            </b-table-column>

            <b-table-column field="name" :label="labelname"  v-slot="props"  v-if="labelname == `Branch Code`">
              {{props.row.newRecord.branch_code.value}}
            </b-table-column>

            <b-table-column field="requestedBy" label="Requested By" v-slot="props">
              {{ props.row.requestedBy }}
            </b-table-column>

            <b-table-column field="requestedTime" label="Requested Date" centered v-slot="props">
            <span>
<!--                {{  getFormattedDateTime(props.row.requestedTime).toLocaleString() }}-->
              {{DateTimeManger.getDisplayDateForUPM(props.row.requestedTime)}}
            </span>
            </b-table-column>

    
            <b-table-column field="status" label="Status" centered v-slot="props">
            <span >
              <b-tooltip :label="props.row.newRecord.status.value === 'Active' ? 'Active': 'Not Active'" :type="props.row.newRecord.status.value === 'Active' ? 'is-success' : 'is-danger'">
           
               <b-icon
                icon="circle"
                size="is-small"
                :type="props.row.newRecord.status.value ==='Active' ? 'is-success' : 'is-danger'"
                rounded/>
              </b-tooltip>
               
            </span>
           
            </b-table-column>

            <b-table-column
                centered
                v-slot="props"
                field="pendingAction" label="Pending Action"
            >
                <span :class="
                        [
                            'tag',
                            {'is-success is-light': props.row.pendingAction ==='CREATE'},
                            {'is-danger is-light':  props.row.pendingAction==='DELETE'},
                            {'is-warning is-light':  props.row.pendingAction==='UPDATE'}
                        ]">
                    {{ props.row.pendingAction  }}
                </span>
            </b-table-column>

            



            <b-table-column label="Action" v-slot="props">
              <b-button type="is-primary"
                        size="is-small"
                       
                        @click="selectChangeRequest(props.row)"
                        rounded
                        
              >
                Approve/Reject
              </b-button>

            </b-table-column>

          </b-table>
        </div>
      </div>
      <footer class="card-footer">
      </footer>
   

    <change-request-approve-modal
        :is-visible="isApproveModalActive"
        :change-request="selectedChangeRequest"
        :filter-out="filterOut"
        :is-loading="isLoading"
        @close-modal="closeApproveModal()"
        @approve-or-reject="approve_or_reject"
    >
    </change-request-approve-modal>

  </section>
</template>

<script>

import ChangeRequestApproveModal from "./ChangeRequestApproveModal.vue";
import {DateTimeManger} from "../helpers/dateTimeManger";
// import {DateTimeManger} from "../helpers/dateTimeManger";


export default {
  name: "ChangeRequest",
  components: {ChangeRequestApproveModal},
  props: ['data', 'filterOut', 'isLoading', 'customChangeRequest','labelname','type'],
  emits: ['approve-or-reject'],
  data() {
    return {
      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,

      },
      stickyHeaders: true,
      dateSearchable: false,
      isApproveModalActive: false,
      selectedChangeRequest: null,
      // props:{
      //           labelname:String
      //         }
    
    }
  },

  computed: {
    DateTimeManger() {
      return DateTimeManger
    }
  },

  methods: {
    approve_or_reject: function (action){
      let data = {
        changeRequestID : this.selectedChangeRequest.changeRequestId,
        actionTaken : action
        
      }
      this.$emit('approve-or-reject', data)
    },

    closeApproveModal: function (){
      this.isApproveModalActive = false
      this.selectedChangeRequest = null
    },

    selectChangeRequest: function (e){
      this.selectedChangeRequest = e

      this.isApproveModalActive = true
    },
    // getFormattedDateTime(date){
    //   return DateTimeManger.formatDateTime(date)
    // }
  },
  mounted(){
    console.log(this.data)
  }
}
</script>

<style >

</style>
