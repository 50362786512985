<template>
  <div v-if="showButtons">
    <div class="is-flex is-align-items-center is-justify-content-flex-end">
      <b-button
        type="is-primary"
        class="py-3 px-5"
        rounded
        native-type="button"
        @click="save"
        :disabled="disable"
        >Save</b-button
      >
      <div class="ml-2 mt-1 is-clickable">
        <!-- <img
          src="../../src/assets/Images/Icons/CircleMinus.png"
          alt="minus_icon"
          width="38"
        /> -->
        <b-button rounded type="is-primary" outlined icon-right="minus-thick"
          @click="cancel"></b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentInputButtons",
  props: ["showButtons", "save", "cancel", "disable"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
