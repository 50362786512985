<template>
    <div>
      <b-modal
          v-model="videoCallModalVisible"
          has-modal-card
          full-screen
          :can-cancel="false"
      >

        <!-- Video call UI -->
        <div class="modal-card" style="width: auto">
          <!-- nav bar -->
          <NavBar/>

          <!-- spiral loader -->
          <b-loading :is-full-page="true" v-model="enableLoader" :can-cancel="false"></b-loading>

          <!-- uploading loader -->
          <!-- <b-loading :is-full-page="true" v-model="showUploadingLoader" :can-cancel="false">
            <b-icon
                icon="progress-upload"
                size="is-large">
            </b-icon>
          </b-loading> -->
          <div class="fullscreen" v-if="showUploadingLoader">
            <ProgressLoader :value="videoKycStore.videoUploadProgress" :label="videoKycStore.videoUploadState"/>
          </div>

          <section class="modal-card-body" style="padding: 0px; max-height: none;overflow: hidden;display: flex;">

            <!-- end call reminder notification  -->
            <AgentVideoCallEndTimeNotification v-if="showEndCallReminderNotification" />

            <!-- Agent Video Call Component -->
            <div id="video-call-div" class="video-kyc-video-stream-container">
              <AgentVideoCall :start-recording-video-call="startRecording"/>
            </div>

            <div class="video-kyc-question-container px-3 py-3">

              <!-- TODO :: complete KYC Questions and Video KYC form integration -->

              <!-- tabs  -->
              <b-tabs position="is-centered" v-model="activeTab">

                <!-- questions tab -->
                <b-tab-item label="Questions">
                 <ApplicationQuestionsCheck :is-recording-on="startRecording" />
                </b-tab-item>

                <!-- ekyc form tab -->
                <b-tab-item label="EKYC">
                  <VideoCallEkyc :ekyc-form-data="videoKycStore.answeredVideoKycData.kycForm" :product_selection="videoKycStore.answeredVideoKycData.kycFormProductSelection" />
                </b-tab-item>
               
                <!-- verification tab -->
                <b-tab-item label="Verification">
                  <VerifyQuestions :ekyc-questions="videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList"  />
                </b-tab-item>

              </b-tabs>

            </div>
            <br>
          </section>
        </div>

      </b-modal>
    </div>
</template>

<script>
import {GlobalEventManager} from "../helpers/globalEventManager";
import ProgressLoader from "./ProgressLoader.vue";
import NavBar from "../layout/components/NavBar.vue";
import AgentVideoCall from "./AgentVideoCall.vue";
import {GlobalEvents} from "../helpers/globalEvents";
import ApplicationQuestionsCheck from "./ApplicationQuestionsCheck.vue";
import VideoCallEkyc from "./VideoCallEkyc.vue";
import {useVideoKycStore} from "../store/videoKycStore";
import AgentVideoCallEndTimeNotification from "./AgentVideoCallEndTimeNotification.vue";
import VerifyQuestions from "./VerifyQuestions.vue";

export default {
  name: "VideoCallAgentModal",

  components : {
    NavBar,
    AgentVideoCall,
    ApplicationQuestionsCheck,
    VideoCallEkyc,
    AgentVideoCallEndTimeNotification,
    ProgressLoader,
    VerifyQuestions,
  },

  setup(){
    const videoKycStore = useVideoKycStore()
    console.log(videoKycStore.answeredVideoKycData.kycForm, "sarmi");
    return  {videoKycStore}
  },

  props:{
    videoCallModalVisible : {
      type : Boolean,
      default : false,
    },
    startRecording:{
      type : Boolean,
      default : false,
    }
  },

  computed : {
    enableLoader(){
      const comp = this
      return !comp.startRecording
    }
  },

  watch : {
    startRecording(newVal,oldVal){
      const comp = this
      if(newVal){
        comp.updateCurrentTime()
      }
    },

    currentTimeSec(newVal,oldVal){
      const comp = this

      // debug logs --
      // console.log("End call reminder notification handler ...")
      // console.log("show notification : "+comp.showEndCallReminderNotification)
      // console.log("Expected end sec : "+comp.videoKycStore.expectedCallEndTimeSec)
      // console.log("Current time sec : "+newVal)
      // console.log("time difference : "+ (comp.videoKycStore.expectedCallEndTimeSec - newVal) )
      // console.log("Max threshold sec : "+comp.videoKycStore.showEndVideoCallNotificationThresholdMins *60)

      // show end call reminder notification
      if(comp.startRecording) {
        if (comp.videoKycStore.expectedCallEndTimeSec > 0 && Math.ceil(comp.videoKycStore.expectedCallEndTimeSec - newVal) <= (comp.videoKycStore.showEndVideoCallNotificationThresholdMins * 60) && !comp.showEndCallReminderNotificationManualClose) {
          comp.showEndCallReminderNotification = true
        }
      }
    },
  },

  data(){
    return{
      activeTab : 0,
      currentTimeSec : 0,
      showEndCallReminderNotification : false,
      showEndCallReminderNotificationManualClose : false,
      showUploadingLoader : false,
    }
  },

  methods : {


    updateCurrentTime(){
      const comp = this
      comp.currentTimeSec = Math.floor(performance.now() / 1000)

      setTimeout(()=>{comp.updateCurrentTime()},1000)
    }

  },

  // Lifecycle hooks ---------------------------------------------------------------------------------------------------
  created() {
    const comp = this

    // listening to global events---------------------------------------------------------------------------------------

    GlobalEventManager.$on(GlobalEvents.agentSide.closeEndCallNotification,()=>{
      console.log("hide end call reminder notification")
      comp.showEndCallReminderNotification = false
      comp.showEndCallReminderNotificationManualClose = true
    })

    GlobalEventManager.$on(GlobalEvents.agentSide.uploadingLoader.show,()=>{
      const comp = this
      comp.showUploadingLoader = true
    })

    GlobalEventManager.$on(GlobalEvents.agentSide.uploadingLoader.hide,()=>{
      const comp = this
      comp.showUploadingLoader = false
    })

  },

  mounted() {
    const comp = this


    comp.showEndCallReminderNotification = false
    comp.showEndCallReminderNotificationManualClose = false
  }
}
</script>

<style scoped>
.video-kyc-video-stream-container{
  flex-grow: 1;
  margin-left: 15px;
}
.video-kyc-question-container{
  flex-basis: 40%;
  overflow-y: scroll;
  border-radius: 25px;  -webkit-box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
  box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
  margin-top: 10px;
  margin-right: 10px;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
</style>