<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="/users">Users</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Update</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">

        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 70vh">
      <section>
        <b-field
            horizontal
            :type="userData.name.class"
            :message="userData.name.message">
          <template slot="label">Name <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="userData.name.blur()" v-model="userData.name.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="userData.username.class"
            :message="userData.username.message">
          <template slot="label">Username <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="userData.username.blur()" v-model="userData.username.value" maxlength="100" expanded disabled></b-input>
        </b-field>

        <b-field
            horizontal
            :type="userData.email.class"
            :message="userData.email.message">
          <template slot="label">Email <span class="has-text-danger">*</span></template>
          <b-input rounded @input="userData.email.blur()" v-model="userData.email.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="userData.contactNo.class"
            :message="userData.contactNo.message">
          <template slot="label">Contact No <span class="has-text-danger">*</span></template>
          <b-input rounded @blur="userData.contactNo.blur()" v-model="userData.contactNo.value" maxlength="12" placeholder="+947XXXXXXXX"></b-input>
        </b-field>

        <!-- <b-field
            horizontal
            :type="userData.userType.class"
            :message="userData.userType.message">
          <template slot="label">User Type <span class="has-text-danger">*</span></template>
          <b-select  @blur="userData.userType.blur()" v-model="userData.userType.value" placeholder="Select a Type" >
            <option value=""  disabled selected>Select a Type</option>
            <option value="BACKEND" :key="1">Backend User</option>
          </b-select>
        </b-field> -->

        <b-field
            horizontal
            :type="userData.branch.class"
            :message="userData.branch.message">
          <template slot="label">Branch <span class="has-text-danger">*</span></template>
<!--          <b-select @blur="userData.branch.value.blur()" v-model="userData.branch.value" placeholder="Select a Branch" >-->
<!--            <option v-for="item in branchList" v-bind:value="item.id" :key="item.id">{{ item.name }}</option>-->
<!--          </b-select>-->
          <p class="control has-icons-right">
            <b-autocomplete
                placeholder="Select a Branch"
                size="is-medium"
                v-model="branchInput"
                open-on-focus
                :data="filterBranches"
                field="name"
                rounded
                @blur="userData.branch.blur()"
                @select="branch => onBranchSelected(branch)"
            >
            </b-autocomplete>
            <span class="icon is-small is-right mr-2 ">
                <img src="@/assets/Images/Icons/Stroke-3.png" alt="Stroke-img" width="15" height="15"/>
              </span>
          </p>
        </b-field>

        <b-field
            horizontal
            :type="userData.department.class"
            :message="userData.department.message">
          <template slot="label">Department <span class="has-text-danger">*</span></template>
          <!--          <b-select @blur="userData.branch.value.blur()" v-model="userData.branch.value" placeholder="Select a Branch" >-->
          <!--            <option v-for="item in branchList" v-bind:value="item.id" :key="item.id">{{ item.name }}</option>-->
          <!--          </b-select>-->
          <p class="control has-icons-right">
            <b-autocomplete
                placeholder="Select a Department"
                size="is-medium"
                v-model="departmentInput"
                open-on-focus
                :data="filterDepartments"
                field="name"
                rounded
                @blur="userData.department.blur()"
                @select="department => onDepartmentSelected(department)"
            >
            </b-autocomplete>
            <span class="icon is-small is-right mr-2 ">
                <img src="@/assets/Images/Icons/Stroke-3.png" alt="Stroke-img" width="15" height="15"/>
              </span>
          </p>
        </b-field>

        <b-field
            horizontal 
            :type="userData.role.class"
            :message="userData.role.message">
          <template slot="label">Role <span class="has-text-danger">*</span></template>
          <b-select
              @input="onRoleSelected(userData.role.value)"
              @blur="userData.role.blur()"
              rounded
              v-model="userData.role.value"
              placeholder="Select a Role">
            <option :value="''"  disabled selected>Select a Role</option>
            <option
                v-for="role in roleListRef"
                v-bind:value="role.roleId"
                :key="role.roleId">{{ role.roleName }}</option>
          </b-select>


        </b-field>


        <b-field horizontal>
          <div class="block"></div>
        </b-field>


        <b-field horizontal
        :type="userData.permissions.class"
             :message="userData.permissions.message">
       
          <template slot="label">Permissions<span class="has-text-danger">*</span></template>

          <div class="permissions-container">

            <div class="permission-group-wrapper"
                 v-for="permissionGroup in permissionsListRef"
                 :key="permissionGroup.index">

              <div class="permission-group-title">{{ permissionGroup.type }}</div>
              <div class="permission-group-permission-list">

                <div class="field"
                     v-for="permissionItem in permissionGroup.permissions"
                     :key="permissionItem.index">

                     <b-field>
                      <b-checkbox v-model="permissionItem.checked" @input="permissionChanged()" disabled >{{ permissionItem.value }}</b-checkbox>
                    </b-field>
                  <!-- <label class="b-checkbox checkbox check">
                    <input type="checkbox" autocomplete="on" true-value="true" value="false" :checked="permissionItem.checked" disabled>
                    <span class="check"></span>
                    <span class="control-label">{{ permissionItem.value }}</span>
                  </label> -->

                </div>
              </div>
            </div>
          </div>
        </b-field>

        <b-field horizontal>
          <div class="block"></div>
        </b-field>

        <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="userData.disabled.value"
                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="userData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger"
                     style="margin-left: 20px;">
              Not Active
            </b-radio>

          </div>
        </b-field>

      </section>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column has-text-danger is-italic is-size-5">Fields marked with <big>*</big> are mandatory</p>
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :loading="false" type="is-success" :disabled="isButtonDisabled || disablebutton">Update</b-button>
            <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
    </b-loading>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import {PermissionGroup, PermissionItem} from "@/views/User/Support";

export default {
  name: "UserEdit",
  data() {
    return {
      branchInput : "",
      departmentInput:"",
      branchList: [],
      departmentList:[],
      roleList: [],
      permissionsList: [],
      exchangeHouseList: [],
      providerList: [],
      disablebutton :false,
      isLoading:false,

      userData: {
        name: new FieldValidator(
            /^([A-Za-z\d]*\s?[A-Za-z\d]+){3,}$/,
            "Name can only have alphanumeric characters and should be at least 3 characters long"
        ),

        userType: new FieldValidator(
            /^\w+$/,
            "Please select a User Type"
        ),

        username: new FieldValidator(
            /^\w{3,}$/,
            "Username can only have alphanumeric characters with '_' and should be at least 3 characters long"
        ),
        email: new FieldValidator(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
            "Please enter a valid Email"
        ),
        contactNo: new FieldValidator(
          // /^\+94\d{9}$/,
          /^(\+94)(7[0-9]{8})$/,
            "Please enter a valid Contact No starting with +94"
        ), 
        branch: new FieldValidator(
            /\w+/,
            "Please select a Branch"
        ),
        department: new FieldValidator(
            /\w+/,
            "Please select a Department"
        ),
        role: new FieldValidator(
            /\w+/,
            "Please select a Role"
        ),
        permissions: new FieldValidator(
            /^[\S\s\d]+$/,
            "Please select at least one Permission"
        ),
        disabled: new FieldValidator()
      },
      
      oldUserData: {
        name: new FieldValidator(
            /^([A-Za-z\d]*\s?[A-Za-z\d]+){3,}$/,
            "Name can only have alphanumeric characters and should be at least 3 characters long"
        ),

        userType: new FieldValidator(
            /^\w+$/,
            "Please select a User Type"
        ),

        username: new FieldValidator(
            /^\w{3,}$/,
            "Username can only have alphanumeric characters with '_' and should be at least 3 characters long"
        ),
        email: new FieldValidator(
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
            "Please enter a valid Email"
        ),
        contactNo: new FieldValidator(
          // /^\+94\d{9}$/,
          /^(\+94)(7[0-9]{8})$/,
            "Please enter a valid Contact No starting with +94"
        ), 
        branch: new FieldValidator(
            /\w+/,
            "Please select a Branch"
        ),
        role: new FieldValidator(
            /\w+/,
            "Please select a Role"
        ),
        department: new FieldValidator(
            /\w+/,
            "Please select a Department"
        ),
        permissions: new FieldValidator(
            /^[\S\s\d]+$/,
            "Please select at least one Permission"
        ),
        disabled: new FieldValidator()
      },

      checkBoxVal: false
    }
  },

  computed: {
    isButtonDisabled() {
      console.log((
        (this.oldUserData.name.value === this.userData.name.value) ,
        ( this.oldUserData.username.value === this.userData.username.value) ,
        ( this.oldUserData.email.value === this.userData.email.value) ,
        ( this.oldUserData.contactNo.value === this.userData.contactNo.value),
        ( this.oldUserData.branch.value === this.userData.branch.value),
        ( this.oldUserData.department.value === this.userData.department.value) &&
        ( this.oldUserData.role.value === this.userData.role.value) ,
        ( this.oldUserData.disabled.value === this.userData.disabled.value)
          )
        )
      return (
        (this.oldUserData.name.value === this.userData.name.value) &&
        ( this.oldUserData.username.value === this.userData.username.value) &&
        ( this.oldUserData.email.value === this.userData.email.value) &&
        ( this.oldUserData.contactNo.value === this.userData.contactNo.value) &&
        ( this.oldUserData.branch.value === this.userData.branch.value) &&
        (this.oldUserData.department.value === this.userData.department.value)&&
        ( this.oldUserData.role.value === this.userData.role.value) &&
        ( this.oldUserData.disabled.value === this.userData.disabled.value)
      );
    },
    roleListRef(){
      const self = this
      const list = []

      self.roleList.forEach(function (item){

        if(item.userType === self.userData.userType.value || item.userType == 'backend'){
          list.push(item)
        }
      })

      return list
    },

    permissionsListRef(){
      const self = this
      const list = []

      self.permissionsList.forEach(function (item){
        // if(item.userType === self.userData.userType.value){
        //   list.push(item)
        // }
        list.push(item)
      })

      return list
    },

    filterBranches() {
      return this.branchList.filter((branch) => {
        return branch.name.toLowerCase().indexOf(this.branchInput.toLowerCase()) >= 0
      })
    },

    filterDepartments() {
      console.log("====================department list", this.departmentList)

      return this.departmentList.filter((department) => {
        return department.name.toLowerCase().indexOf(this.departmentInput.toLowerCase()) >= 0
      })
    }
  },

  methods: {

    onBranchSelected(branch){
      const comp = this

      console.log("selected branch : ")
      console.log(branch)

      if(branch === null){
        comp.userData.branch.value = "";
      }
      else {
        comp.userData.branch.value = branch.id
      }

    },

    onDepartmentSelected(department){
      const comp = this
      console.log("selected department : ")
      console.log(department)

      if(department === null){
        comp.userData.department.value = "";
      }
      else {
        comp.userData.department.value = department.id
      }

    },

    onUserTypeChange: function (){
      const self = this
      let x = 'a Branch'

      // switch (self.userData.userType.value){
      //   case 'BACKEND':
      //     x = "a Branch"
      //     break
      //   case 'EXCHANGEHOUSE':
      //     x = "an exchange house"
      //     break
      //   case 'IPGBANK':
      //     x = "a bank"
      //     break
      // }

      self.userData.branch.blur()
      self.userData.branch.value = ''
      self.userData.role.value = ''
      self.userData.branch.errorMessage = 'Please select ' + x
    },

    initialData: function (){
      const self = this
      NetworkManager.apiRequest('api/user/initial-create-data', {}, function (e){
        if(e.statusCode === 200){

          self.branchList = e.data.branchList
          self.roleList = e.data.roleList
          self.exchangeHouseList = e.data.exchangeHouseList
          self.providerList = e.data.providerList
          self.departmentList = e.data.departmentList

          e.data.permissionsList.forEach(function (item, i){
            const groupItem = new PermissionGroup()
            groupItem.index = i
            groupItem.type = item.type
            groupItem.userType = item.userType

            item.permissions.forEach(function (perm, j){
              const permission = new PermissionItem()
              permission.index = j
              permission.value = perm
              groupItem.permissions.push(permission)
            })

            self.permissionsList.push(groupItem)
          })

          self.getUserForEdit()
        }
      })
    },

    permissionChanged: function() {
      const self = this

      self.userData.permissions.value = ""
      self.permissionsList.forEach(function (group){
        group.permissions.forEach(function (item){
          if(item.checked){
            self.userData.permissions.value += (self.userData.permissions.value !== '' ? "|" : '') + item.value
          }
        })
      })
    },

    getUserForEdit: function (){
      const self = this

      const data = {
        id : self.$route.params.id
      }
      NetworkManager.apiRequest('api/user/get-for-edit', data, function (e){
        if(e.statusCode === 200){
          const user = e.data.user
          self.userData.name.value = user.name
          self.userData.userType.value = user.user_type
          self.userData.username.value = user.username
          self.userData.email.value = user.email
          self.userData.contactNo.value = user.contact
          self.userData.branch.value = user.branch_id
          self.userData.department.value = user.department_id
          self.userData.role.value = user.role_id
          self.userData.disabled.value = user.disabled
          self.branchInput = self.getSelectedBranchName(user.branch_id)
          self.departmentInput = self.getSelectedDepartmentName(user.department_id)
          
          self.oldUserData.name.value = user.name
          self.oldUserData.userType.value = user.user_type
          self.oldUserData.username.value = user.username
          self.oldUserData.email.value = user.email
          self.oldUserData.contactNo.value = user.contact
          self.oldUserData.branch.value = user.branch_id
          self.oldUserData.department.value = user.department_id
          self.oldUserData.role.value = user.role_id
          self.oldUserData.disabled.value = user.disabled

          user.permissions.forEach(function (item){
            self.userData.permissions.value += (self.userData.permissions.value === '' ? '' : '|') + item
          })

          self.permissionsList.forEach(function (group){
            group.permissions.forEach(function (perm){
              perm.checked = user.permissions.includes(perm.value)
            })
          })
          self.onRoleSelected(user.role)
        }
      })
    },

    /**
     * A function get branch name using branch ID
     * @param branchId
     * @returns {unknown}
     */
    getSelectedBranchName(branchId){
      const comp = this
      return comp.branchList.find(b => b.id === branchId).name;
    },

    getSelectedDepartmentName(departmentId){
      const comp = this
      return departmentId === null ? "" : comp.departmentList.find(b => b.id === departmentId).name;
    },

    onRoleSelected: function (roleId){
      const self = this
      let role = null

      for (let i = 0; i < self.roleList.length; i++){
        if(self.roleList[i].roleId === roleId){
          role = self.roleList[i]
        }
      }

      if(role !== null){
        self.permissionsList.forEach(function (group){
          group.permissions.forEach(function (perm){
            perm.checked = role.permissions.includes(perm.value);
          })
        })
      }
    },

    validate: function (){
      const self = this
      let isValid = true

      Object.keys(self.userData).forEach(function(key, index) {
        console.log(self.userData[key].validate())
        if(!self.userData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function (){
      const self = this
      if(self.validate()){
        const data = {
          id : self.$route.params.id,
          type : self.userData.userType.value,
          name : self.userData.name.value,
          email : self.userData.email.value,
          contact : self.userData.contactNo.value,
          username : self.userData.username.value,
          branch_id : self.userData.branch.value,
          department_id:self.userData.department.value,
          role : self.userData.role.value,
          disabled : self.userData.disabled.value
        }
        
        self.isLoading = true
        self.disablebutton =true
        NetworkManager.apiRequest('api/user/edit', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'Updated user record sent for approval',
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
            self.$router.go(-1)
          }
          else if(e.statusCode === 422){
            const params = {
              type: 'is-danger',
              message: e.data.message,
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
          }
          else {
            const params = {
              type: 'is-danger',
              message: 'User edit failed!.',
              duration: 5000,
            }
            self.disablebutton =false
            self.isLoading = false
            self.$buefy.toast.open(params)
          }
        })
      }
      self.disablebutton =false
      self.isLoading = false
    },
  },

  mounted() {
    this.userData.disabled.value = 0
    this.userData.userType.value = "BACKEND"
    this.initialData()
  },
}
</script>


<style scoped>
.permissions-container {
  display: block;
}

.permission-group-wrapper {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;
  vertical-align: top;
}

.permission-group-title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-style: italic;
}

.permission-group-permission-list {
  padding-left: 0.1rem;
}
</style>
