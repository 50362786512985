<template>
    <div style="height: 80.5vh; overflow: auto !important">
      <div class="container-header p-3">
        <div class="columns" style="width: 100%">
          <!-- <h5 style="color: grey; font-size: 20px; margin-left: 20px;"></h5> -->
          <div class="column">
            <b-breadcrumb align="is-left">
              <label style="font-family:Gotham-Medium; color: gray; font-weight: 400;">Debit Card Report</label>
            </b-breadcrumb>
          </div>
            <div class="columns is-vcentered">
              <div class="coloumn is-half">
                <div class="column mr-2">
                    <b-input
                    placeholder="Search..."
                    rounded
                    type="search"
                    icon="magnify"
                    icon-clickable
                    v-model="searchKeyword"
                    style="width:450px"

                    >
                    </b-input>
                </div>
              </div>
            </div>
        </div>
    </div>

    <div >

      <FilterBranch 
      :hide-input="false"  
      @form-submitted="applyFilteredData"
      @xlsx-download="xlsxDownload"
      @pdf-download="pdfDownload"
      @isdisabled="isdisabled"
      :disable-downloads="disableDownloadButtons"/>

    </div> 
    <div class="mt-6">      
      <DebitCardTable 
       v-if="!isLoading"        
       :tableData="filterdArray"
       :columns="columnDetails"
       :searchableFields="searchableFields"
       :searchKeyword="searchKeyword"
       @disableDownloadBtn="handleDisableDownloadButtons"
       />
    </div>

    </div> 
 
    
 
</template>

<script>
import DebitCardTableVue from '../../components/DebitCardTable.vue';
import FilterBranch from '../../components/FilterBranch.vue';
import { DateTimeManger } from '../../helpers/dateTimeManger';
import {GlobalAttributes} from "../../helpers/globalAttributes";
import {TableDetailItem} from "../../helpers/classes/admin/applicationPoolList/tableDetailItem";
import NetworkManager from "@/network";
import {BuefyHelper} from "../../helpers/bufeyHelper";
import {ReportExportWizard} from "@/views/Reports/Supports";
import {useAuthStore} from "../../store/authStore";
import {BranchFilterOption} from "../../helpers/classes/admin/dataFilterTable/branchFilterOption";

export default {
  name: "DebitCardReport",
  computed: {
    GlobalAttributes() {
      return GlobalAttributes
    },
  },

  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  components: {

    FilterBranch: FilterBranch,
    DebitCardTable:DebitCardTableVue
  },
  props :[],
  data() {
      return {
          disableDownloadButtons : false,
          searchKeyword: "",
          isLoading : false,
          data: [ ],
          filterdArray:[],
          searchableFields: ['ref_no', 'nic', 'name','email','branch','assigned_user','approved_date'],
          columnDetails: [
                        { id: 1, field: "ref_no", label: "Ref No.",date:false,hide:false },
                        { id: 2, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
                        { id: 3, field: "name", label: "Name" ,date:false,hide:false},
                        { id: 4, field: "email", label: "Email" ,date:false,hide:false},
                        { id: 5, field: "contact", label: "Contact" ,date:false,hide:false},
                        { id: 6, field: "branch",label: "Branch" ,date:false,hide:false},
                        { id: 7, field: "received_date", label: "Applied Date" ,date:false,hide:false},
                        { id: 8, field: "action-view", label: "Action" ,date:false,hide:false},
                      ],
          columns:[
                    { id: 1, field: "id", label: "ID",date:false,hide:false },
                    { id: 2, field: "ref_no", label: "Ref No.",date:false,hide:false },
                    { id: 3, field: "nic", label: "NIC/EIC" ,date:false,hide:false},
                    { id: 4, field: "name", label: "Name" ,date:false,hide:false},
                    { id: 5, field: "email", label: "Email" ,date:false,hide:false},
                    { id: 6, field: "contact", label: "Contact" ,date:false,hide:false},
                    { id: 7, field: "branch",label: "Branch" ,date:false,hide:false},
                    { id: 8, field: "received_date", label: "Applied Date" ,date:false,hide:false},
                  ],
         exportWizard: new ReportExportWizard("Debit Card Report"),

      };
  },

  methods:{

    handleDisableDownloadButtons(disableStatus){
      const comp = this
      comp.disableDownloadButtons = disableStatus
    },

    xlsxDownload(data){
      console.log("in debit card")
      const comp = this
      let branchName = comp.getBranchNameById(data.BranchSearch)
      comp.exportWizard.exportToXlsx(
          data.StartDateSearch,
          data.EndDateSearch,
          data.Nic,
          branchName,
          "debit_card_report_" + comp.formatDate(new Date()),
          comp.columns,
          comp.filterdArray
      )
    },

    pdfDownload(data){
      console.log("in debit card")
      const comp = this

    
      let branchName = comp.getBranchNameById(data.BranchSearch)

      comp.exportWizard.exportToPDF(
          data.StartDateSearch,
          data.EndDateSearch,
          data.Nic,
          branchName,
          "debit_card_report_" + comp.formatDate(new Date()),
          comp.columns,
          comp.filterdArray
      )
    },

    formatDate: function (date) {

      let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    getFormattedDateTime(date){
      return DateTimeManger.formatDateTime(date)
    },

    applyFilteredData(filterParams){
      console.log("filter params... (receiving end) ")
      console.log(filterParams)

      const comp = this

      // set request parameters
      let applicationStatus = GlobalAttributes.kycFormStatus.DEBIT_CARD_PENDING;

      // find application  status

      let branchId = filterParams.BranchSearch === null ? 0 : filterParams.BranchSearch;
      let Nic = filterParams.Nic === '' ? null : filterParams.Nic;
      let endDate = null;

      if (filterParams.EndDateSearch != null) {
        let endDateTemp = new Date(filterParams.EndDateSearch); // create new date object otherwise may increment the date continuously
        endDate = new Date(endDateTemp.setDate(endDateTemp.getDate() + 1)); // pass +1 date for the end date. otherwise it will not returns the correct end date applications due to timestamp filtering in backend
      }

      console.log("end date : "+endDate)

      // filter relevant tab data
      comp.getFilteredApplications(filterParams.StartDateSearch,endDate,branchId,Nic,applicationStatus,()=>{
        console.log("filter applied successfully...")
      })
    },

  async getFilteredApplications (startDate,endDate,branchId,Nic,applicationStatus,callback) {
    const comp = this;
    const requestBody = {
      start_date: startDate,
      end_date: endDate,
      branch_id: branchId,
      nic:Nic,
      application_pool_status: applicationStatus,
    };

    comp.showFullScreenLoader();
    comp.isLoading = true;


    // waiting for the branch list
    await comp.waitUntilBranchListLoaded()

    NetworkManager.apiRequest("api/Admin/getDebitCardApplications", requestBody,(response)=> {
          if (response.statusCode === 200) {

            comp.filterdArray =[];

            response.data.filtered_kyc_applications.forEach((kycApplication) =>{
              comp.filterdArray.push(comp.getNewTableItemObject(kycApplication))
            });

            if(!comp.filterdArray.length > 0){
              comp.disableDownloadButtons = true
            }
            else{
              comp.disableDownloadButtons = true
            }

            comp.hideFullScreenLoader();

            console.log("FILTERED ARRAY", response.data.filtered_kyc_applications)
            comp.isLoading = false
            callback()
          }
          else{
            //BuefyHelper.showToastMessage("Unable to filter the data","is-danger")

            console.error("Failed to filter data")
            if(response.data){
              console.error(response.data.message);
            }


            comp.hideFullScreenLoader();
            comp.isLoading = false
          }

        }
    );
    },
   
    waitUntilBranchListLoaded() {
      const comp = this;

      return new Promise((resolve) => {
        const checkBranchListLoaded = () => {
          if (comp.$root.formDataSupport.branchesArray.length > 0) {
            console.log("branch list loaded ...");
            resolve();
          } else {
            console.log("waiting for branch list...");
            setTimeout(checkBranchListLoaded, 100);
          }
        };

        checkBranchListLoaded();
      });
    },


  getNewTableItemObject(kycApplication){

    const comp = this

    let newTableDetailItem = new TableDetailItem()

    newTableDetailItem.id = kycApplication.kyc_form_id
    newTableDetailItem.ref_no =  kycApplication.reference_number
    newTableDetailItem.nic = kycApplication.nic_eic_number
    newTableDetailItem.name = kycApplication.full_name
    newTableDetailItem.email = kycApplication.email
    newTableDetailItem.contact = kycApplication.mobile_number
    newTableDetailItem.branch =comp.getBranchNameById(kycApplication.branch_id)
    newTableDetailItem.received_date = comp.getFormattedDateTime( kycApplication.received_date)

    return newTableDetailItem;

    },

    getBranchNameById(id){

      const comp = this
      let filteredBranch =  comp.$root.formDataSupport.branchesArray.find(branch=>branch.id === id)

      if(filteredBranch !== undefined){
        return filteredBranch.branch_name
      }
      else {
        return ""
      }
    },

  async getBranchFilterOptions(){

    const comp = this
    let branchFilterOptions = []
    comp.showFullScreenLoader()

    await comp.waitUntilBranchListLoaded()

    console.log("after waiting for branch list...")

    comp.$root.formDataSupport.branchesArray.forEach(branch=>branchFilterOptions.push(new BranchFilterOption(branch.id,branch.branch_name)))

    comp.hideFullScreenLoader()
    },

    loadInitialData(){
      const comp = this;

      // load all tabs data at initial load
      this.getFilteredApplications(null,null,0,null,GlobalAttributes.kycFormStatus.DEBIT_CARD_PENDING);
    },

    emittabledata() {
      this.$emit('update_tabledata', this.data);
    },



    isdisabled(){

    },

  },


  mounted(){
    this.loadInitialData();
        if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
  }
   
}
</script>

<style>

</style>