<template>
  <!-- list items -->
  <div class="wrapper">
    <span style="font-family: Gotham-Light; font-weight: bold">{{label}}</span>

    <div style="margin-top: 10px">
      <div style="display: grid; margin-left: 10px; ">
        <ul >
          <li v-for="(reason,index) in reasons" :key="index" v-if="reason.is_comment_selected" >{{reason.comment_content}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayReturnReasons",

  props: {

    reasons:{
      type: Array,
      required : true
    },

    label :{
      type : String,
      required : true
    }

  },

}
</script>

<style scoped>
  .wrapper {
    margin-top : 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 20px;
  }


  ul{
    list-style: disc;
  }
</style>