<template>
  <div>
    
      <b-button type="is-primary"
        @click="DrpCheck()"
        >Check DRP</b-button
      >
    
    <b-modal
      v-model="isModalActive"
      scroll="keep"
      :width="1400"
      :can-cancel="false"
    >
      <div class="model_box drp">
        <div style="transition-timing-function: ease-in-out">
          <header class="modal-card-head" style="background-color: white">
            <p
              class="modal-card-title has-text-centered is-size-3 has-text-primary"
            >
              DRP Verification Report
            </p>
            <button
              @click="isModalActive = false"
              type="button"
              class="delete"
            ></button>
          </header>

          <hr />
          <div class="is-flex is-justify-content-center">
            <b-tabs type="is-toggle-rounded" position="is-centered">
              <b-tab-item :label="'Card '+(index+1)" v-for="(item,index) in mergedDataArray" :key="index" >      
                <div class="columns mx-6">
                  <div class="column">
                    <div>
                      <DRPTable
                        :data="item.tableData"
                        :has-mobile-cards="hasMobileCards"
                        :columns="columns"
                        row-class-one="striped-table-color"
                      />
                    </div>
                    <!-- comment  -->
                    <div class="mt-6">
                      <div
                        class="icon-text mt-5"
                        :style="{
                          cursor: 'pointer',
                          color: '#F57E82',
                          'line-height': '20px',
                          'letter-spacing': '0.7px',
                        }"
                      >
                        <p v-if="!isShow"
                          @click="visible()"
                          class="is-flex is-flex-direction-row is-align-content-center">
                          <span class="icon has-text-info">
                            <img
                              src="../assets/Images//Icons/Circle-plus.png"
                              alt="Circle_Plus image"
                            />
                          </span>
                          <span class="fontstyle mt-1">
                            Add comments for internal use
                          </span>
                        </p>
                      </div>
                      <div v-if="isShow">
                        <div @click="() => {visibleHide();}">
                          <span
                            class="icon has-text-info"
                            :style="{
                              cursor: 'pointer',
                              color: '#F57E82',
                              'line-height': '20px',
                              'letter-spacing': '0.7px',
                            }"
                          >
                            <img
                              src="../assets/Images//Icons/CircleMinus.png"
                              alt="CircleMinus.png"
                            />
                          </span>
                          <span class="fontstyle mt-1">
                            Add comments for internal use
                          </span>
                        </div>

                        <b-input
                          maxlength="200"
                          v-model="DRP_internalcomment"
                          @input="emitDRPinternalcomment"
                          type="textarea"
                          placeholder="Specify"
                          rows="5"
                          style="width: 100%"
                        ></b-input>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="columns">
                      <div class="column mt-2">
                        <p class="letterstyle">NIC Images</p>
                        <div
                          style="background: rgba(155, 155, 155, 0.1)"
                          class="has-text-centered p-4">
                          <div>
                            <div :class="!item.imageData.nic_front_side_image ? `image-border` : ``">
                              <img
                                :src="getDisplayImage(item.imageData.nic_front_side_image) || unSuccessfulImage"
                                alt="DRP Image"
                              />
                              <p v-if="!item.imageData.nic_front_side_image" class="unsuccesStyle">No Photo Available</p>
                            </div>
                            <div class="mt-4" :class="!item.imageData.nic_rear_side_image ? `image-border` : ``">
                              <img
                                :src="getDisplayImage(item.imageData.nic_rear_side_image) || unSuccessfulImage"
                                alt="DRP Image"
                              />
                              <p v-if="!item.imageData.nic_rear_side_image" class="unsuccesStyle">No Photo Available</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column mt-2">
                        <p class="letterstyle" >Customer Image</p>
                        <div style="background: rgba(155, 155, 155, 0.1)" class="has-text-centered p-4">
                          <div class="mt-4" :class="!item.imageData.customer_image ? `image-border` : ``">
                            <img
                              :src="getDisplayImage(item.imageData.customer_image) || unSuccessfulImage"
                              alt="DRP Image"
                            />
                            <p v-if="!item.imageData.customer_image" class="unsuccesStyle">No Photo Available</p>
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <!-- <b-tab-item label="Card-02"></b-tab-item>
              <b-tab-item label="Card-03"></b-tab-item> -->
            </b-tabs>
          </div>

          
          <div
            style="margin-top: 2%; padding-bottom: 2%"
            class="is-flex is-justify-content-space-between mx-6"
          >
            <b-button
              type="is-primary"
              outlined
              class="is-rounded is-size-5 back-button-en"
              style="padding-inline: 120px"
              @click="emitDRP_fail"
              >Fail</b-button
            >
            <b-button
              class="is-primary is-rounded continue-button-en is-size-5"
              style="padding-inline: 100px"
              @click="emitDRP_success"
              >Success</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DRPTable from "./DRPTableCopm.vue";
import { DateTimeManger } from "../helpers/dateTimeManger";
import { BuefyHelper } from "../helpers/bufeyHelper";
import { GlobalFunctions } from "../helpers/globalFunctions";
import NetworkManager from "../network";
import unSuccessfulImage from "../assets/Images/Icons/Unsucces.webp";
export default {
  name: "CheckDRPNew",
  props:["formData"],
  components: { DRPTable,  },
  data() {
    const columns = [
      {
        field: "id",
        label: "ID",
        tableHeaderAttributes: this.thAttrs,
        tableDataAttributes: this.columnTdAttrs,
      },
      {
        field: "data",
        label: "Data",
        tableHeaderAttributes: this.thAttrs,
        tableDataAttributes: this.columnTdAttrs,
      },
      {
        field: "customer_data",
        label: "Customer Filled",
        tableHeaderAttributes: this.thAttrs,
        tableDataAttributes: this.columnTdAttrs,
      },
      {
        field: "drp_record",
        label: "DRP Record",
        tableHeaderAttributes: this.thAttrs,
        tableDataAttributes: this.columnTdAttrs,
      },
    ];
    return {
      manualDRP:false,
      hasMobileCards: false,
      base64Prefix:"data:image/jpeg;base64,",
      isModalActive: false,
      isShow: false,
      columns,
      DRP_internalcomment: "",
      DRPCustomerData: {
        nic: this.formData.nic_eic_number.customer_input,
        dob: this.getFormattedDateTime( this.formData.date_of_birth.customer_input),
        address: GlobalFunctions.getCustomerPermanentAddress(this.formData),
        full_name: this.formData.full_name.is_changed_by_call_agent ? this.formData.full_name.call_agent_input : this.formData.full_name.customer_input,
        issue_date: this.getFormattedDateTime(this.formData.nic_eic_issue_date.is_changed_by_call_agent ? this.formData.nic_eic_issue_date.call_agent_input : this.formData.nic_eic_issue_date.customer_input ),
        gender: this.formData.gender.customer_input,
      },
      DRPdata: [],
      DRPImages:[],
      unSuccessfulImage
    };
  },
  computed:{
    mergedDataArray() {
      // Initialize an empty array to store the merged data objects
      const mergedArray = [];
      const contentData =[];
      // Loop through each card object in the 'cards' array
      for (const card of this.DRPdata) {
        // Create a new merged data object based on your base structure
        const mergedData = [
          {
            id: 0,
            data: "Date of Birth",
            customer_data: this.DRPCustomerData.dob || "-",
            drp_record: card.birth_date || "-",
          },
          {
            id: 1,
            data: "NIC No.",
            customer_data: this.DRPCustomerData.nic || "-",
            drp_record: card.id || "-",
          },
          {
            id: 2,
            data: "Address",
            customer_data: this.DRPCustomerData.address || "-",
            drp_record: card.address || "-",
          },
          {
            id: 3,
            data: "Full Name / Other Name",
            customer_data: this.DRPCustomerData.full_name || "-",
            drp_record: card.name || "-",
          },
          {
            id: 4,
            data: "Gender",
            customer_data: this.DRPCustomerData.gender || "-",
            drp_record: card.gender || "-",
          },
          {
            id: 5,
            data: "ID Issue Date",
            customer_data: this.DRPCustomerData.issue_date || "-",
            drp_record: card.issued_date || "-",
          },        
        ];
        const images = {
          nic_front_side_image: card.nic_front,
          nic_rear_side_image: card.nic_back,
          customer_image: card.face_image,
        };
        
        // Push the merged data object into the mergedArray
        // contentData.push(mergedData,images);

        let data = {
          tableData : mergedData,
          imageData : images
        }
        mergedArray.push(data);

      }
      console.log("mergedArray",mergedArray)
      return mergedArray;
    },
  },
  methods: {
    emitDRP_fail() {
      this.$emit('DRP_fail',this.DRP_showMessageBox);
    },
    emitDRP_Check_Failed() {
      console.log("NO DATA EMITTING MANUALDRP FALSE",this.manualDRP)
      this.$emit('DRP_Check_Failed_fail',this.manualDRP);
    },
    emitDRP_success() {
      this.$emit('DRP_success',this.DRP_showMessageBox);
    },
    emitDRPinternalcomment() {
      this.$emit('update_DRP_internal_comment', this.DRP_internalcomment);
    },
    getFormattedDateTime(date) {
      return DateTimeManger.formatCustomerInputDates(date);
    },
    getDisplayImage(base64String){
      const comp = this

      if(base64String === ""){
        return comp.unSuccessfulImage
      }
      else{
        return comp.base64Prefix+base64String
      }
    },
    DrpCheck() {
      const comp = this;
      comp.showFullScreenLoader();
      const data = {
        nic: comp.formData.nic_eic_number.customer_input,
      };
      console.log("check drp console 1", data);
      comp.DRPCustomerData.nic = comp.formData.nic_eic_number.customer_input;
      comp.DRPCustomerData.dob = comp.getFormattedDateTime( comp.formData.date_of_birth.customer_input);

      // // get permanent address
      comp.DRPCustomerData.address = GlobalFunctions.getCustomerPermanentAddress(comp.formData)
      comp.DRPCustomerData.full_name = comp.formData.full_name.is_changed_by_call_agent ? comp.formData.full_name.call_agent_input : comp.formData.full_name.customer_input;
      comp.DRPCustomerData.issue_date = comp.getFormattedDateTime(comp.formData.nic_eic_issue_date.is_changed_by_call_agent ? comp.formData.nic_eic_issue_date.call_agent_input : comp.formData.nic_eic_issue_date.customer_input );

      NetworkManager.apiRequest(
        "api/KycApplication/checkDrp",
        data,
        function (e) {
          console.log("checkDrp DATA===========: ",e)
          if(e.statusCode === 200) {
            // console.log("checkDrp DATA: ",e.data.drp_result)
            comp.hideFullScreenLoader();
            comp.DRPdata = e.data.drp_result.cards;
            comp.isModalActive = true;
            comp.manualDRP=false;
            console.log("DRP customer data : ",comp.DRPCustomerData)
            console.log("check drp endpoint  : ",comp.DRPdata)
            console.log("comp.DRPdata.length-------->",comp.DRPdata.length)
            console.log("this.DRPdata[0]",this.DRPdata[0])
           
              // comp.DRP_showMessageBox = true;
            
            // else {
            //   comp.hideFullScreenLoader();
            //   const params = {
            //     type: "is-danger",
            //     message: "Unable to check DRP. Try again.",
            //     duration: 5000,
            //   };
            //   comp.$buefy.toast.open(params);
            // }
          }
          else if (e.statusCode === 404) {
            // no records found
            comp.checkDRPFailed =true;
            comp.emitDRP_Check_Failed();
            comp.hideFullScreenLoader();
            BuefyHelper.showToastMessage("No DRP records found", "is-danger");
          }
          else if(e.statusCode === 500)
          {
            comp.hideFullScreenLoader();
            comp.checkDRPFailed =true;
            const params = {
              type: "is-danger",
              message: "Unable to check DRP. Try again.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    thAttrs(column) {
      return {
        style: {
          display: column.label === "ID" ? "none" : "",
        },
      };
    },
    columnTdAttrs(row, column) {
      return {
        style: {
          "text-align": "left !important",
          display: column.label === "ID" ? "none" : "",
        },
      };
    },
    visible() {
      this.isShow = true;
    },
    visibleHide() {
      this.isShow = false;
      this.DRP_internalcomment = "";
    },
  },
mounted(){

},
};
</script>
<style lang="scss" scoped>
.line {
  position: absolute;
  top: 220px;
}
hr {
  stroke-width: 2px;
  stroke: var(--text, #231f20);
  opacity: 0.3;
  margin-bottom: 1px !important;
  text-align: center !important;
}
.model_box {
  border-radius: 20px;
  border: 1px solid #ed1c24 !important;
  padding: 10px;
  background-color: #f9f9f9;
}
.fontstyle {
  color: var(--Red, #04c3fc);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.image-border {
  border-radius: 3.733px;
  border: 1px dashed #acacac;
  background: #f5f5f5;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.letterstyle {
  color: var(--gray-1, #64676a);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.unsuccesStyle {
  color: var(--gray2, #a7a9ac);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
