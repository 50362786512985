<template>
  <div style="height: 80.5vh; overflow: auto !important;">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag="router-link" to="" active
            ><label style="font-family:Gotham-Medium; color: gray; font-weight: 400;">Settings</label></b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <!---------tabs--------------------------------------->
    <section class="mt-5">
      <b-tabs v-model="activeTab">
        <b-tab-item label="Day Set">
          <b-message>
            <div class="columns">
              <div class="column">
                Video call maximum duration
              </div>
              <div class="column is-2">
                <b-field>
                  <b-select placeholder="Custom" rounded>
                    <option value="flint">Flint</option>
                    <option value="silver">Silver</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </b-message>
          <div class="box">
            <div class="columns">
              <div class="column is-9">
                Set a new time slot
              </div>
              <div class="column is-2">
                <b-field>
                  <b-input rounded></b-input>
                </b-field>
              </div>
              <div class="column is-1">
                <b-field>
                  <b-button class="is-primary" rounded>Set</b-button>
                </b-field>
              </div>
            </div>
          </div>

          <b-collapse
              :open="false"
              class="card"
              animation="slide"
              aria-id="contentIdForA11y3">
            <template #trigger="props">
              <div
                  class="card-header"
                  role="button"
                  aria-controls="contentIdForA11y3"
                  :aria-expanded="props.open">
                <span class="card-header-title">
                      Set the video call lead time
                </span>
                <b-field>
                  <b-input rounded readonly></b-input>
                </b-field>
                <a class="card-header-icon">
                  <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column is-9">
                    Set a new time slot
                  </div>
                  <div class="column is-2">
                    <b-field>
                      <b-input rounded></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field>
                      <b-button class="is-primary" rounded>Set</b-button>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
          <br>
          <b-collapse
              :open="false"
              class="card"
              animation="slide"
              aria-id="contentIdForA11y3">
            <template #trigger="props">
              <div
                  class="card-header"
                  role="button"
                  aria-controls="contentIdForA11y3"
                  :aria-expanded="props.open">
                <span class="card-header-title">
                      Set the system available time
                </span>
                <b-field>
                  <b-input rounded readonly></b-input>
                </b-field>
                <a class="card-header-icon">
                  <b-icon
                      :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column is-9">
                    Set a new time slot
                  </div>
                  <div class="column is-2">
                    <b-field>
                      <b-input rounded></b-input>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <b-field>
                      <b-button class="is-primary" rounded>Set</b-button>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>

          <br>
          <b-message>
            <div class="columns">
              <div class="column">
                Delete hal filled applications after
              </div>
              <div class="column is-2">
                <b-field>
                  <b-select placeholder="30 days" rounded>
                    <option value="flint">Flint</option>
                    <option value="silver">Silver</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </b-message>
        </b-tab-item>

        <b-tab-item label="Hyperlinks">

        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>

import NetworkManager from "@/network";
import AdminTableComp from "@/components/AdminTableComp";
import AdminDataTableFilter from "@/components/AdminDataTableFilter";

export default {
  components: {
   
    AdminTableComp,
    AdminDataTableFilter
  },
  data() {
    return {
      activeTab: 0,

      currentPage: "NEW",
      statusToBeAssigned: "ASSIGNED-DETAIL-REVIEWER",

      searchKeyword:"",
      searchableFields: ['ref_no', 'nic', 'name','email','gender','branch','assigned_user','approved_date'],

      filterDetails:[
        {
          id:1,
          name:'Branch',
          selectedValue:null,
          placeholder:'Select a Branch',
          options:[
            {id:'1',value:'Kandy'},
            {id:'2',value:'Dambulla'}
          ]
        },

        {
          id:2,
          name:'User',
          selectedValue:null,
          placeholder:'All Users',
          options:[
            {id:'1',value:'Dtm98'},
            {id:'2',value:'User23'}
          ]
        }
      ],


      columnDetails: [
        { id: 1, field: "ref_no", label: "Ref No.",date:false },
        { id: 2, field: "nic", label: "NIC/EIC" ,date:false},
        { id: 3, field: "name", label: "Name" ,date:false},
        { id: 5, field: "email", label: "Email" ,date:false},
        { id: 6, field: "contact", label: "Contact" ,date:false},
        { id: 7, field: "branch",label: "Branch",date:false},
        { id: 8, field: "assigned_user",label: "Assigned User",date:false},
        { id: 9, field: "approved_date",label: "Approved Date",date:false},
        { id: 12, field: "action-view", label: "Action" ,date:false},
      ],
      tableDetails: [
        {
          id: 1,
          ref_no: "546-861",
          nic: "964558570V",
          name: "Jason Carter",
          gender:"Male",
          email: "jasoncarter@gmail.com",
          contact: "+94756449568",
          branch:"branch 1",
          assigned_user:"Agent 2",
          approved_date:"2023-04-11T16:13:39",
          action: ``,
        },
        {
          id: 2,
          ref_no: "456-923",
          nic: "962245865V",
          name: "Williamson Cameron",
          gender:"Female",
          email: "cameronwilliamson@gmail.com",
          contact: "+94756449568",
          branch:"branch 2",
          assigned_user:"Agent 2",
          approved_date:"2023-04-11T16:13:39",
          action: ``,
        },
      ],
    };
  },

  methods: {

    GetNewApplicationData: function () {
      const comp = this;
      const data = {
        status: "NEW",
      };

      comp.showFullScreenLoader();
      // NetworkManager.apiRequest(
      //     "api/KycApplication/listByStatus",
      //     data,
      //     function (e) {
      //       if (e.statusCode === 200) {
      //         comp.tableDetails = e.data.applications;
      //       }
      //
      //       comp.hideFullScreenLoader();
      //     }
      // );
      comp.hideFullScreenLoader();
    },
  },
  mounted() {
    this.GetNewApplicationData();
    if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
  },
};
</script>
