<template>
  <div v-if="!hide" class="agent">
    <div :class="styles">
      <ValidationProvider
        :name="fieldName"
        :rules="validationRules"
        v-slot="{ errors }"
      >
        <b-field
          label-position="inside"
          :custom-class="
            vModelVaribale.trim() === '' && !isLabelFocus
              ? 'custom-float-label-initial'
              : 'custom-float-label'
          "
        >
          <template #label>{{ label }}</template>

          <b-input
            :icon-right="copyable? 'content-copy' : ''"
            @icon-right-click="copyToClipboard(vModelVaribale)"
            icon-right-clickable
            v-model="vModelVaribale"
            :type="type ? type : `text`"
            :readonly="readonly"
            :disabled="disabled"
            :rows="rows"
            :cols="cols"
            @focus="
              () => {
                isLabelFocus = true;
              }
            "
            @blur="
              () => {
                isLabelFocus = false;
              }
            "
            rounded
            :password-reveal="type === `password` ? true : false"
            :maxlength="maxLength ? maxLength : ``"
          ></b-input>
        </b-field>
        <span class="has-text-danger" style="display: flex">{{
          errors[0]
        }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
import { BuefyHelper } from '../helpers/bufeyHelper';

export default {
  name: "AgentInputLabel",
  props: [
    "copyable",
    "label",
    "value",
    "styles",
    "type",
    "maxLength",
    "hide",
    "disabled",
    "readonly",
    "validationRules",
    "fieldName",
    "rows",
    "cols",
  ],
  components: {},
  data() {
    return {
      isLabelFocus: false,
    };
  },
  computed: {
    vModelVaribale: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods:{
    copyToClipboard(text) {
      console.log("copy to clipboard called");
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      BuefyHelper.showToastMessage("Copied to Clipboard", "is-danger");
    },
  }
};
</script>
<style>
.custom-float-label-initial {
  top: 0.93em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
}

.custom-float-label {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}
</style>
