<template>
  <div style="height: 86.5vh; overflow: auto !important" > 
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label
              style="
                font-family: Gotham-Medium;
                color: gray;
                font-weight: 400;
                font-size: 23px;
              "
              ></label
            >
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div> 
  <div style="margin-bottom: 25px;">
  <filter_branch></filter_branch>
  </div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Detail Reviewer" v-bind="deatailReviwer">
        <TableComp
          :tableData="deatailReviwer"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          :searchKeyword="searchKeyword"

        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Call Agent" v-bind="callAgent">
        <TableComp
          :tableData="callAgent"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          :searchKeyword="searchKeyword"

        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Authorizer" v-bind="authorizer">
        <TableComp
          :tableData="authorizer"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          :searchKeyword="searchKeyword"


        styles="mt-4 mx-6"
        />
      </b-tab-item>
    </b-tabs> 
  </div>
</template>

<script>

import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network"
import FilterBranch from "../../components/FilterBranch.vue";

export default {
  components: { TableComp, SearchBar,
  'filter_branch':FilterBranch,
  },
  name: "BranchReffer",
  data() {
    return {
      activeTab: 0,
      callAgent : false,
      authorizer : false,
      deatailReviwer : false,
      
      callAgenttableDetails: [],
      authorizertableDetails: [],
      deatailReviwertableDetails: [],
      currentPage:'SUBMITTED-EXCEPTIONAL-APPROVER',
      statusToAssign:"ASSIGNED-EXCEPTIONAL-APPROVER",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "mobile_number", label: "Contact" ,date:false},
        { id: 5, field: "branch", label: "Branch" ,date:false},
        { id: 6, field: "created_at", label: "Sent Date",date:true},
        { id: 7, field: "action", label: "Action" ,date:false},   
      ],

      callAgent:   [
                        {reference_number: '1', nic_eic_number: '986545676v', full_name:'Vishwa',email:'erena@gamil.com',mobile_number:'0779945676',branch:'Negegoda',created_at:'2023-03-23T09:05:29.679873'},
                        {reference_number: '2', nic_eic_number: '678909856v', full_name:'Vasantha',email:'vasantha5566@gamil.com',mobile_number:'0776674345',branch:'Wattala',created_at:'2023-03-24T09:05:29.685512'},
                        {reference_number: '3', nic_eic_number: '966797345v', full_name:'Erena',email:'erena5566@gamil.com',mobile_number:'0765678987',branch:'Kandy',created_at:'2023-03-25T09:05:29.679873'},
                ],
               
     authorizer:  [
                        {reference_number: '1', nic_eic_number: '986545676v', full_name:'Vishwa',email:'erena@gamil.com',mobile_number:'0779945676',branch:'Colombo',created_at:'2023-03-23T09:05:29.679873'},
                        {reference_number: '2', nic_eic_number: '678909856v', full_name:'Vasantha',email:'vasantha5566@gamil.com',mobile_number:'0776674345',branch:'Gampaha',created_at:'2023-03-24T09:05:29.685512'},
                        {reference_number: '3', nic_eic_number: '966797345v', full_name:'Erena',email:'erena5566@gamil.com',mobile_number:'0765678987',branch:'Kurunegala',created_at:'2023-03-25T09:05:29.679873'},
                ],
               
      deatailReviwer:   [
                        {reference_number: '1', nic_eic_number: '986545676v', full_name:'Vishwa',email:'erena@gamil.com',mobile_number:'0779945676',branch:'Nugegoda',created_at:'2023-03-23T09:05:29.679873'},
                        {reference_number: '2', nic_eic_number: '678909856v', full_name:'Vasantha',email:'vasantha5566@gamil.com',mobile_number:'0776674345',branch:'Mathugama',created_at:'2023-03-24T09:05:29.685512'},
                        {reference_number: '3', nic_eic_number: '966797345v', full_name:'Erena',email:'erena5566@gamil.com',mobile_number:'0765678987',branch:'Nuwareliya',created_at:'2023-03-25T09:05:29.679873'},
                ]

    };
  },
  methods: {
    Get_DetailReviwer_Details(){
    },
    Get_CallAgent_Details(){
    },
    Get_Authorizer_Details(){
    }
 
  },
  mounted() {     
      this.Get_DetailReviwer_Details();
      this.Get_CallAgent_Details();
      this.Get_Authorizer_Details();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
};
</script>
<style scoped></style>
