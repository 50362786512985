<template>
  <div>
      <b-modal
          v-model="isModalActive"
          scroll="keep"
          :width="1400"
          :can-cancel="false"
      >
        <div class="model_box">
          <div style="transition-timing-function: ease-in-out">
          <header class="modal-card-head" style="background-color: white">
            <p
                class="modal-card-title has-text-centered is-size-3 has-text-primary"
            >
              Random Questionnaire Data
            </p>
            <button
                @click="questionnaireClose"
                type="button"
                class="delete"
            ></button>
          </header>
      <hr style="width: 90%; margin-left: auto; margin-right: auto;">
        <div class="table-comp-wrapper">
          <b-table
              style="
        height: auto ;
        "
              v-if="questionnaireResultListData != null && questionnaireResultListData.length > 0"
              :data="questionnaireResultListData"
              :per-page="tableOptions.perPage"
              :sort-icon="tableOptions.sortIcon"
              :sort-icon-size="tableOptions.sortIconSize"
              default-sort="id"
              :row-class="getRowClass"
          >
            <b-table-column
                v-for="column in tableColumn"
                :key="column.id"
                :field="column.field"
                :label="column.label"
                v-slot="props"
            >
              <span v-if="column.field  !== 'correct_answer'">
                 {{props.row[column.field] }}
              </span>
              <span v-if="column.field === 'correct_answer'">
                <span v-if=" props.row[column.field] === null">
                  <b-icon icon="check" scale="2" variant="success"></b-icon>
                </span>
                 <span v-else>
                  {{  props.row[column.field] }}
                </span>
              </span>
            </b-table-column>
          </b-table>
        </div>
          </div>
        </div>
      </b-modal>
  </div>
</template>

<script>

import NetworkManager from "@/network";
import {BuefyHelper} from "@/helpers/bufeyHelper";

class TableColumn {
  constructor(field, label) {
    this.field = field;
    this.label = label;
  }
}
export default {
  name: 'VideoCallRandomQuestionnaireResult',
  props: {
    applicationId: null,
  },
  data() {
    return {
      searchKeyword: "",
      questionnaireResultListData:[],
      tableColumn: this.getColumns(),
      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 8,
        hasInput: false,
        paginationOrder: 'is-centered',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },
      isModalActive: true,
      showRandomQuestionnaireModel :false,
      type: "RandomQuestionnaireResult"
    };
  },

  mounted() {
    this.assignedApplication(this.applicationId);
  },
  methods:{

    getRowClass(row)
    {
      if(row.validity)
      {
        return "is-success"
      }else {
        return "is-danger"
      }
    },

    getColumns() {
      return [
        new TableColumn("question", "Question"),
        new TableColumn("answer", "Answer"),
        new TableColumn("correct_answer", "Correct Answer"),
      ];
    },
    questionnaireClose()
    {
      this.$emit('Close_Random_Question_Model',this.showRandomQuestionnaireModel)
    },

    assignedApplication: function (id) {
      const comp = this;
      const data = {
        id: comp.applicationId,
      };
      NetworkManager.apiRequest(
          "api/KycApplication/getVideoCallVerificationQuestionResultById",
          data,
          function (e) {
            if (e.statusCode === 200) {
              comp.questionnaireResultListData = e.data
              console.warn("check details",comp.questionnaireResultListData)
            }else {
              BuefyHelper.showToastMessage(
                  "Something Went Wrong . Try again Later.",
                  "is-danger"
              );
            }
          }
      );
    },
  }
}


</script>

<style lang="scss" scoped>
.line {
  position: absolute;
  top: 220px;
}
hr {
  stroke-width: 2px;
  stroke: var(--text, #231f20);
  opacity: 0.3;
  margin-bottom: 1px !important;
  text-align: center !important;
}
.model_box {
  border-radius: 20px;
  padding: 10px;
  background-color: #f9f9f9;
}
.fontstyle {
  color: var(--Red, #ed1c24);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.image-border {
  border-radius: 3.733px;
  border: 1px dashed #acacac;
  background: #f5f5f5;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.letterstyle {
  color: var(--gray-1, #64676a);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.unsuccesStyle {
  color: var(--gray2, #a7a9ac);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>