<template>
  <div>
    <div class="background">
    </div>

    <div class="nic-ocr-result-wrapper">
      <div class="header">
        NIC OCR Result
        <b-button
            id="btn-close"
            type="is-primary"
            outlined icon-right="close"
            @click="emitClose"/>
      </div>
      <div class="body">
        <b-tabs size="is-small" type="is-toggle-rounded" position="is-centered" class="custom-tabs">

          <!-- NIC FRONT -->
          <b-tab-item label="NIC Front Image" icon="card-account-details">
            <div class="tab-item-wrapper">
              <div class="not-available" v-if="nicFront === null || nicFront.nicType === ''">
                No Data Available
              </div>
              <div v-else>
                <div class="nic-type">
                  Identified NIC Image Type : {{nicFront.getNicType()}}
                  <two-way-table :columns="nicFront.getTableColumns()" :data="nicFront.getTableData(kycForm)"/>
                </div>
              </div>
            </div>
          </b-tab-item>

          <!-- NIC REAR -->
          <b-tab-item label="NIC Rear Image" icon="card-bulleted">
            <div class="tab-item-wrapper">
              <div class="not-available" v-if="nicRear === null || nicRear.nicType === ''">
                No Data Available
              </div>
              <div v-else>
                <div class="nic-type">
                  Identified NIC Image Type : {{nicRear.getNicType()}}
                  <div class="not-available" v-if="nicRear.checkNicType('old_nic_rear')">
                    No OCR Available
                  </div>

                  <two-way-table v-else :columns="nicRear.getTableColumns()" :data="nicRear.getTableData(kycForm)"/>
                </div>

              </div>
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </div>
  </div>

</template>

<script>
import {NicOcrResultDTO} from "../../helpers/classes/dtos/nicOcrResultDTO";
import TwoWayTable from "../Table/TwoWayTable.vue";

export default {
  name: "NicOcrResult",

  components:{
    TwoWayTable,
  },

  props: {
    nicFront:{
      type: NicOcrResultDTO,
      required: true
    },
    nicRear:{
      type: NicOcrResultDTO,
      required: true
    },
    kycForm:{
      type: Object,
      required: true
    }

  },

  data(){
    return {

    }
  },

  methods:{
    emitClose(){
      this.$emit("close")
    }
  }


}
</script>


<style scoped>
.nic-ocr-result-wrapper{
  background-color: white;
  z-index: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  height:60vh;
  width:60vw;
  min-height: fit-content;
  border-radius: 22px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background{
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //transform: translate(-50%, -50%);
  background-color: #7a7a7a;
  opacity: 0.3;
}

.header{
  height: 50px;
  padding: 10px;
  color: #0a0a0a !important;
  display: flex;
  justify-content: center;
}

.body{
  display: flex;
  align-items: center;
  justify-content: center;
  //width: calc(60vw - 10vw);
}

#btn-close{
  position: absolute;
  top: 5px;
  right: 5px;
}

.not-available{
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.tab-item-wrapper{
  margin-top: 5vh;
}

.custom-tabs{
  width: calc(60vw - 10vw)
}
</style>