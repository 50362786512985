<template>
  <!-- list items -->
  <div :class="GetWrapperClass">
    <span style="font-family: Gotham-Light; font-weight: bold; color: black; pointer-events: none;" >{{label}}</span>

    <div style="margin-top: 10px">
      <div style="display: grid; margin-left: 10px; ">
        <ul :class="GetItemClass" style="cursor: default;">
          <li v-for="(item,index) in items" :key="index" >{{item}}
            <!-- <b-button :class="GetItemClass" v-if="copyable" :icon-left="copyable? 'content-copy' : ''" @click="copyToClipboard(item)"></b-button> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BuefyHelper } from '../helpers/bufeyHelper';

export default {
  name: "DisplayBulletList",

  props: {
    copyable:{
      type : Boolean,
      required : false,
      default : false
    },
    isAgent : {
      type : Boolean,
      required : true,
      default : false
    },

    items:{
      type: Array,
      required : true
    },

    label :{
      type : String,
      required : true
    }

  },

  computed : {
    GetWrapperClass(){
      const comp = this
      return comp.isAgent ? 'wrapper-agent' : 'wrapper-customer';
    },

    GetItemClass(){
      const comp = this
      return comp.isAgent ? 'item-agent' : 'item-customer';
    }
  },
  methods:{
    copyToClipboard(text) {
      console.log("copy to clipboard called");
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      BuefyHelper.showToastMessage("Copied to Clipboard", "is-danger");
    },
  }


}
</script>

<style scoped>

  .wrapper-customer {
    margin-top : 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 20px;
  }

  .wrapper-agent {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid red;
    border-radius: 20px;
  }

  .item-customer {
    color: black;
  }

  .item-agent {
    color: red;
  }

  ul{
    list-style: disc;
  }
</style>