export default class PendingActions {
    isVideoCallEkycFormUpdatedByAgent
    isVideoCallKycFormSubmitted
    isVideoRecordingUploaded

    constructor() {
        this.isVideoCallEkycFormUpdatedByAgent = false
        this.isVideoCallKycFormSubmitted = false
        this.isVideoRecordingUploaded = false
    }
}