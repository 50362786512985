<template>
  <div style="height: 80.5vh; overflow: auto !important"> 
    <b-tabs v-model="activeTab" >
      <b-tab-item v-bind="deatailReviwer" >
        <div>
          <SearchBar page="Assigend Applications" />
        </div>
      </b-tab-item>
      <b-tab-item v-bind="callAgent">
        <div>
          <SearchBar page="Assigned Applications" />
        </div>  
      </b-tab-item>
      <b-tab-item v-bind="authorizer">
        <div >
          <SearchBar page="Assigend Applications" />
        </div>  
      </b-tab-item>
  </b-tabs>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Detail Reviewer" v-bind="deatailReviwer">
        <TableComp
          :tableData="deatailReviwertableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Call Agent" v-bind="callAgent">
        <TableComp
          :tableData="callAgenttableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Authorizer" v-bind="authorizer">
        <TableComp
          :tableData="authorizertableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
        styles="mt-4 mx-6"
        />
      </b-tab-item>
    </b-tabs> 
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network"

export default {
  components: { TableComp, SearchBar },
  name: "AssignedApplication",
  data() {
    return {
      activeTab: 0,
      callAgent : false,
      authorizer : false,
      deatailReviwer : false,
     
      callAgenttableDetails: [],
      authorizertableDetails: [],
      deatailReviwertableDetails: [],
      currentPage:'ASSIGNED-EXCEPTIONAL-APPROVER',
      statusToAssign:"",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "gender", label: "Gender" ,date:false},
        { id: 5, field: "email", label: "Email" ,date:false},
        { id: 6, field: "mobile_number", label: "Contact" ,date:false},
        { id: 7, field: "resident_status", label: "Residence" ,date:false},
        { id: 8, field: "created_at", label: "Received Date",date:true},
        { id: 9, field: "action", label: "Action" ,date:false},   
      ],
      searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','mobile_number','created_at'],



    };
  },
  methods: {
    Get_Assigned_DetailReviwer_Details(){
      const comp= this
        const  data = {
          status:"ASSIGNED-EXCEPTIONAL-APPROVER",
          type:3
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/GetExceptionalApplications',data, function (e){
          if(e.statusCode === 200){
            comp.deatailReviwertableDetails = e.data
          }
          // comp.hideFullScreenLoader()
        })
    },
    Get__Assigned_CallAgent_Details(){
      const comp= this
        const  data = {
          status:"ASSIGNED-EXCEPTIONAL-APPROVER",
          type:4
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/GetExceptionalApplications',data, function (e){
          if(e.statusCode === 200){
            comp.callAgenttableDetails = e.data
          }
          // comp.hideFullScreenLoader()
        })
    },
    Get__Assigned_Authorizer_Details(){
      const comp= this
        const  data = {
          status:"ASSIGNED-EXCEPTIONAL-APPROVER",
          type:5
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/GetExceptionalApplications',data, function (e){
          if(e.statusCode === 200){
            comp.authorizertableDetails = e.data
          }
          // comp.hideFullScreenLoader()
        })
    }
 
  },
  mounted() {     
      this.Get_Assigned_DetailReviwer_Details();
      this.Get__Assigned_CallAgent_Details();
      this.Get__Assigned_Authorizer_Details();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
};
</script>
<style scoped></style>
