import NetworkManager from "./index";
import {BuefyHelper} from "../helpers/bufeyHelper";

/*
 * ======================================================================================================================
 * This util can be used to define re usable api endpoints
 * */
const ApiRequestManager = {
  /*
   * ==================================================================================================================
   * This method is used to get country list
   *
   *  */
  getCountryList: function (callback) {
    NetworkManager.apiRequest(
      "api/KycApplication/getCountryList",
      {},
      function (response) {
        if (response.statusCode === 200) {
          console.log("country list loaded successfully");
          callback(response.data.countries);
          console.log(response.data.countries);
        } else {
          console.log("failed to load country list");
          callback([]);
        }
      },
        false
    );
  },

  /*
   * ==================================================================================================================
   * This method is used to get country list
   *
   *  */
  getDistrictList: function (callback) {
    NetworkManager.apiRequest(
      "api/KycApplication/getDistrictList",
      {},
      function (response) {
        if (response.statusCode === 200) {
          console.log("district list loaded successfully");
          callback(response.data.districts);
        } else {
          console.log("failed to load district list");
          callback([]);
        }
      },
      false
    );
  },

  getBranchList: function (callback) {
    NetworkManager.apiRequest(
      "api/KycApplication/branchList",
      {},
      function (response) {
        if (response.statusCode === 200) {
          console.log("branch list loaded successfully");
          console.log(response.data, "response.data");
          callback(response.data);
        } else {
          console.log("failed to load branch list");
          callback([]);
        }
      },
      false
    );
  },


    /***
     * This function can be used to call create CIF and open accounts endpoint
     * @param kycFormId
     * @param commentForInternalUse
     * @param commentForCustomer
     * @param useOldComments
     * @param callback
     */
    createCIFAndOpenAccounts(kycFormId,commentForInternalUse="",commentForCustomer="",useOldComments,callback){
        const comp = this

        console.log("createCIFAndOpenAccounts - START =================")

        const requestBody = {
            kyc_form_id : kycFormId,
            comment_for_customer : commentForCustomer,
            comment_for_internal_use : commentForInternalUse,
            use_old_comments : useOldComments,
        }

        console.log("Request Body")
        console.log(requestBody)


        NetworkManager.apiRequest("api/KycApplication/createCifAndOpenAccounts",requestBody,(response)=>{
            callback(response)
        })
    },

 

};

export { ApiRequestManager };
