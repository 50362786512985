import {ExceptionalApproverBaseTableDataItem} from "../exceptionalApproverBaseTableDataItem";

export class DeniedExApprovalAuthorizerTableDataItem extends ExceptionalApproverBaseTableDataItem {
    received_date
    denied_date

    constructor(id,refNo,nic,name,gender,email,branch,assignedUserName,status,receivedDate,deniedDate) {
        super(id,refNo,nic,name,gender,email,branch,assignedUserName,status);
        this.received_date = receivedDate;
        this.denied_date = deniedDate;
    }
}