<!--LR-->
<template>
  <div class="wrapper">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>

    <ValidationObserver v-slot="{ invalid }">

      <div class="heading-content"> {{GetHeadingText}} </div>

      <div class="body-content">
        <ValidationProvider
            :rules="{ required: true }"
            v-slot="{ errors }"
        >
          <b-field>
            <template #label v-if="occupationRequired">
              Industry <span class="has-text-danger">*</span>
            </template>
            <p class="control has-icons-right">
              <b-autocomplete
                  placeholder="Select an Industry"
                  size="is-medium"
                  v-model="industryInput"
                  open-on-focus
                  :data="filterIndustries"
                  field="description"
                  rounded
                  @select="industry => (selectedIndustry = industry )"
              >
              </b-autocomplete>
              <span class="icon is-small is-right mr-2 ">
                <img src="@/assets/Images/Icons/Stroke-3.png" alt="Stroke-img" width="15" height="15"/>
              </span>
            </p>
          </b-field>
          <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
        </ValidationProvider>

        <div v-if="occupationRequired">
          <ValidationProvider
              :rules="{ required: true }"
              v-slot="{ errors }"
          >
            <b-field>
              <template #label v-if="occupationRequired">
                Occupation <span class="has-text-danger">*</span>
              </template>
              <p class="control has-icons-right">
                <b-autocomplete
                    placeholder="Select an Occupation"
                    size="is-medium"
                    v-model="occupationInput"
                    open-on-focus
                    :data="filterOccupations"
                    rounded
                    @select="occupation => (selectedOccupation = occupation )"
                >
                </b-autocomplete>
                <span class="icon is-small is-right mr-2 ">
                <img src="@/assets/Images/Icons/Stroke-3.png" alt="Stroke-img" width="15" height="15"/>
              </span>
              </p>
            </b-field>
            <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

      </div>

      <div class="button-area">
        <b-button class="action-btn save-btn" type="is-primary" :disabled="invalid" @click="saveIndustry" :loading="saveBtnIsLoading">Save</b-button>
        <b-button class="action-btn" type="is-primary" outlined @click="emitClose">Close</b-button>
      </div>

    </ValidationObserver>
  </div>

</template>

<script>
import NetworkManager from "../../network";
import {GlobalEventManager} from "../../helpers/globalEventManager";
import {GlobalEvents} from "../../helpers/globalEvents";
import {BuefyHelper} from "../../helpers/bufeyHelper";

export default {
  name: "AddIndustryModal",

  props :{
    kycFormId : {
      required : true,
      type : Number
    },
    occupationRequired : {
      type : Boolean,
      required : true,
      default : false
    }
  },

  computed : {

    filterIndustries() {
      return this.industries.filter((industry) => {
        return industry.description.toLowerCase().indexOf(this.industryInput.toLowerCase()) >= 0
      })
    },

    filterOccupations(){
      return this.occupations.filter((occupation) => {
        return occupation.toLowerCase().indexOf(this.occupationInput.toLowerCase()) >= 0
      })
    },

    GetHeadingText(){
      const comp = this
      return comp.occupationRequired ? "Add Industry and Occupation" : "Add Industry";
    }
  },

  data(){
    return {
      industries :[],
      occupations:[],

      industryInput: "",
      occupationInput: "",

      selectedIndustry:null,
      selectedOccupation:"",

      saveBtnIsLoading : false,
      isLoading:false,
    }
  },

  methods : {

    /**
     * This function is used to get T24 industry list
     */
    getT24Industries(callback){

      const comp = this

      NetworkManager.apiRequest("api/T24/getIndustriesList",{},(response)=>{
        console.log("T24 industries response")
        console.log(response)
        if(response.statusCode === 200){
          comp.industries = response.data.industries.sort((a, b) => {
            return a.description.localeCompare(b.description);
          });

          if(typeof callback === "function"){
            callback()
          }
        }
        else{
          BuefyHelper.showToastMessage("Unable to get Industries list","is-danger")
        }
      })
    },

    /**
     * This function is used to get T24 occupations list
     */
    getT24Occupations(callback){

      const comp = this

      NetworkManager.apiRequest("api/T24/getOccupationsList",{},(response)=>{
        console.log("T24 industries response")
        console.log(response)
        if(response.statusCode === 200){
          comp.occupations = response.data.occupations.sort((a, b) => {
            return a.localeCompare(b);
          });

          if(typeof callback === "function"){
            callback()
          }
        }
        else{
          BuefyHelper.showToastMessage("Unable to get Occupations list","is-danger")
        }
      })
    },


    /**
     * This function is used to emit close event
     */
    emitClose(){
      const comp = this
      comp.selectedIndustry = null
      comp.selectedOccupation = ""
      GlobalEventManager.$emit(GlobalEvents.admin.kycApplications.hideInputIndustryModal)
    },


    /**
     * This function is used to save the selected industry in the database
     */
    saveIndustry(){
      const comp = this

      comp.saveBtnIsLoading = true;

      console.log("saving selected Industry ...")
      console.log(comp.selectedIndustry)

      const requestBody = {
        kyc_form_id : comp.kycFormId,
        industry : {
          industry_id : comp.selectedIndustry.industry_id,
          description : comp.selectedIndustry.description
        },
        occupation : comp.selectedOccupation
      }

      console.log("request body")
      console.log(requestBody)

      NetworkManager.apiRequest("api/KycApplication/saveCustomerIndustry",requestBody,(response)=>{
        console.log("save industry response ...")
        console.log(response)

        if(response.statusCode === 200){

          let msg = comp.occupationRequired ? "Successfully saved the Industry and Occupation" : "Successfully saved the Industry";

          BuefyHelper.showToastMessage(msg,"is-success")
          // emit global event to hide the modal and load application data again
          GlobalEventManager.$emit(GlobalEvents.admin.kycApplications.hideInputIndustryModalAndLoadData)
          comp.saveBtnIsLoading = false
        }
        else{

          BuefyHelper.showToastMessage("Something went wrong!. Please try again!", "is-warning")
          comp.saveBtnIsLoading = false
        }
      })
    },


    loadData(){
      const comp = this
      comp.isLoading = true;

      comp.getT24Industries(()=>{
        if(comp.occupationRequired){
          comp.getT24Occupations(()=>{comp.isLoading = false})
        }
        else {
          comp.isLoading = false;
        }
      })
    },



  },

  // Lifecycle hooks
  mounted() {
    const comp = this
    this.loadData()
  }

}
</script>



<style scoped>
  .wrapper{
    justify-content: space-around;
    z-index: 5;
    min-height: fit-content;
    min-width: 720px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
    padding: 30px 20px;
  }
  .heading-content{
    display: flex;
    justify-content: center;
    font-size: 20pt;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  .body-content{
    margin: auto 20px 30px 20px;
  }

  .button-area{
    margin : auto 20px 0 20px;
    display: flex;
    justify-content: flex-end;
  }

  .action-btn{
    margin-left: 30px;
    height: 45px !important;
  }

  .save-btn{
    width: 200px;
  }
</style>