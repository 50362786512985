
<template>
  <div v-if="!isLoading">
    <div>
      <b-image v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
        :src="formData.selfie_attachment.attachment_url"
        responsive
        style="border-radius: 11px"
      ></b-image>
      <b-image v-else
      :src="pdfPlaceholder"
        responsive
        style="border-radius: 11px" >
      </b-image>
    </div>

    <InputLabel copyable="true"
      label="Full Name (As per the NIC / EIC / PP)"
      :value="formData.full_name.is_changed_by_call_agent ? formData.full_name.call_agent_input : formData.full_name.customer_input "
      styles="mt-5"
      readonly="true"
      class="non-interactable-input"
    />
    <InputLabel copyable="true"
      label="NIC / EIC"
      :value="formData.nic_eic_number.is_changed_by_call_agent ? formData.nic_eic_number.call_agent_input : formData.nic_eic_number.customer_input "
      styles="mt-5"
      readonly="true"
      class="non-interactable-input"
    />
    <InputLabel copyable="true"
      label="Primary Mobile Number"
      :value="formData.mobile_number.is_changed_by_call_agent ? formData.mobile_number.call_agent_input : formData.mobile_number.customer_input "
      styles="mt-5"
      readonly="true"
      class="non-interactable-input"
    />
     <InputLabel copyable="true"
      label="Email"
      :value="formData.email.is_changed_by_call_agent ? formData.email.call_agent_input : formData.email.customer_input "
      styles="mt-5"
      readonly="true"
      class="non-interactable-input"
    />
    <InputLabel copyable="true"
      label="Employment Status"
      :value="formData.employment_status.is_changed_by_call_agent ? formData.employment_status.call_agent_input : formData.employment_status.customer_input "
      styles="mt-5"
      readonly="true"
      class="non-interactable-input"
    />

    <!-- reject reasons -->
    <!-- <div v-if="formData.current_status === GlobalAttributes.kycFormStatus.REJECTED && !isLoading">

      <span style="font-family: Gotham-Light; font-weight: bold">Reject Reasons</span>

      <div class="reason-wrapper">
        <div v-for="(reason,index) in GetSelectedRejectReasons" style="display: grid; margin-left: 10px; " :key="index">
          <b-checkbox class="non-interactable-input" v-model="reason.is_comment_selected" @click.native="GlobalFunctions.preventDefault">{{reason.comment_content}}</b-checkbox>
          <InputLabel
              v-if="(reason.comment_content === 'Others') && reason.is_comment_selected "
              label="Specify"
              :value="reason.additional_comment"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
          />
        </div>
      </div>
    </div> -->


    <!-- internal comment -->
    <!-- <div v-if="internalComment !== null &&
     (formData.current_status === GlobalAttributes.kycFormStatus.REJECTED ||
     formData.current_status === GlobalAttributes.kycFormStatus.APPROVED ||
     formData.current_status === GlobalAttributes.kycFormStatus.EXCEPTIONAL_APPROVER_REJECTED ||
     formData.current_status === GlobalAttributes.kycFormStatus.EXCEPTIONAL_APPROVER_APPROVED ||
     formData.current_status === GlobalAttributes.kycFormStatus.DEBIT_CARD_REVIEWED
     )" class="internal-comment" >
      <span style="font-family: Gotham-Light;font-weight: bold">Reject Reason Internal Comment</span>
      {{internalComment.comment}}
    </div> -->

    <!-- customer comment -->
    <!-- <div v-if="customerCommentList.customer_comments !== null &&
     (formData.current_status === GlobalAttributes.kycFormStatus.REJECTED ||
     formData.current_status === GlobalAttributes.kycFormStatus.APPROVED ||
     formData.current_status === GlobalAttributes.kycFormStatus.EXCEPTIONAL_APPROVER_REJECTED ||
     formData.current_status === GlobalAttributes.kycFormStatus.EXCEPTIONAL_APPROVER_APPROVED ||
     formData.current_status === GlobalAttributes.kycFormStatus.DEBIT_CARD_REVIEWED
     )" class="internal-comment" >
      <span style="font-family: Gotham-Light;font-weight: bold">Reject Reason Customer Comment</span>
      {{customerCommentList.customer_comments}}
    </div> -->

  </div>

</template>

<script>
import InputLabel from "../../components/InputLabel.vue";
import NetworkManager from "@/network";
import {useAuthStore} from "../../store/authStore";
import {GlobalAttributes} from "../../helpers/globalAttributes";
import {GlobalFunctions} from "../../helpers/globalFunctions";

export default {
  name : "CustomerDetail",
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    },
    GlobalAttributes() {
      return GlobalAttributes
    },
    GetSelectedRejectReasons(){
      const comp = this
      let rejectedStatusHistory = null
      let selectedRejectReasons = []

      rejectedStatusHistory  = comp.kycFormStatusHistory.find(sh => sh.kyc_status === GlobalAttributes.kycFormStatus.REJECTED);

      rejectedStatusHistory.reasons.forEach(reason =>{
        if(reason.is_comment_selected){
          selectedRejectReasons.push(reason)
        }
      })
      console.log("rejected status history",rejectedStatusHistory)

      console.log("selected reject reasons",selectedRejectReasons)

      return selectedRejectReasons;
    },
  },

  props : {
    kycFormStatusToGetInternalComment : {
      type : String,
      required : false,
      default : ""
    }
  },

  components: {
    InputLabel,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return{
      formData : [],
      kycFormStatusHistory :[],
      customerCommentList:[],
      internalComment : null,
      isLoading:true,
      pdfPlaceholder:  require("@/assets/Images/pdf.png"),
    };
  },
  methods: {
    GetApprovedApplicationData: function (){
      const comp= this
      comp.isLoading = true

      const data = {
        id : comp.authStore.kyc_form_id,
        kyc_status_to_get_internal_comment : comp.kycFormStatusToGetInternalComment
      }

      console.log("CustomerDetails component - getDetailsById request body")
      console.log(data)

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        console.log("customer details column data response==========")
        console.log(e)

        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication
          comp.kycFormStatusHistory = e.data.kycFormStatusHistory;
          comp.internalComment = e.data.internalComment;
          comp.customerCommentList = e.data.customerCommentList

          comp.isLoading = false
        }
        else{
          comp.isLoading =false
        }
      })
    },




  },


  mounted(){
    this.GetApprovedApplicationData()
  },
};
</script>

<style lang="scss" scoped>
.user_image_box {
  background: #affcb3;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.19);
  border-radius: 11px;
}

.check-box{
  margin-bottom: 8px;
}

.reason-wrapper{
  margin-top: 10px;
}
.internal-comment{
  border: 1px solid;
  padding: 10px;
  border-radius: 20px;
  display: grid;
  margin-top:20px;
}
.non-interactable-input {
  pointer-events: none;
}
</style>
