
export class PermissionGroup {
    index = 0
    type = ''
    userType = ''
    permissions = []
}

export class PermissionItem {
    index = 0
    value = ''
    checked = false
}
