<template>
  <div>
    <div :contenteditable="!readonly" :class="{readonly: readonly}">
      <label>{{ label }}</label>
      <div style="border: 1.5px solid black; border-radius: 3px">
        <GmapMap
          :center="{ lat: 7.5, lng: 80 }"
          :zoom="7"
          map-type-id="terrain"
          style="width: auto; height: 300px"
          @click="addMarker"
        >
        <!-- <GmapMap
          :center="{ lat: 7.5, lng: 80 }"
          :zoom="7"
          map-type-id="terrain"
          style="width: auto; height: 300px"
        > -->
          <GmapMarker
            :position="markerPosition"
            :clickable="true"
            :draggable="true"
            @click="center = markerPosition"
            @dragend="updateMarkerPosition"
            v-model="markerPosition"
          />
          <!-- <GmapMarker
            :position="markerPosition"
            v-model="markerPosition"
          /> -->
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";
export default { 
  name: "LocationSetaMap", 
  props: ["label", "styles","position","readonly"] ,

  data(){
    return{
      markerPosition: null,
      formData:[],
      center: { lat: 7.5, lng: 80 },
    };
  },
  methods: {
    addMarker(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.$emit('send-data', this.markerPosition);
      console.log('Im here',this.markerPosition)
    },
    updateMarkerPosition(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    getMarkerPosition() {
      return this.markerPosition;
    },
  },

};

</script>

<style scoped></style>
