<template>
    <div> 
        <div style="margin-top: 10px; margin-left: 10px; max-width: 50vw;">
            <p>eKYC Video Call</p>
            <label style="color: #A7A9AC; font-size: 18px;">Customer Name : {{ GlobalFunctions.getFormattedNameForNotification(videoKycStore.answeredVideoKycData.kycForm) }}</label>
        </div>
        <div class="card video-wrapper-card"  rounded>
            <div class="card customer-video-card"  rounded>
                <div class="card agent-video-card" rounded>
                    <video muted id="local-video" autoplay style="width: auto; height: auto;  border-radius: 25px;"></video>
                </div>
               <RecorderStopwatch id="recorder-stopwatch" :is-start="showRecorderStopwatch" />
                <video id="remote-video" autoplay style="width: 800px;  height: 447px;   border-radius: 25px;"></video>
                <video id="recorded" class="collapse" playsinline loop></video>
                <canvas id="imageCanvas" class="collapse"></canvas>
            </div>
            <br>
            <div class="action-buttons" >
              <b-button @click="muteVideo()" rounded class="button-small" :icon-left="GetVideoBtnIcon"  :disabled="videoKycStore.buttonStates.disabled.videoBtn" ></b-button>
              <b-button @click="enableShowEndCallModal()" rounded  class="button-large" :icon-left="videoKycStore.buttonIcons.phoneHangUp" :disabled="videoKycStore.buttonStates.disabled.endCallBtn" > </b-button>
              <b-button @click="muteAudio()" rounded  class="button-small" :icon-left="GetAudioBtnIcon" :disabled="videoKycStore.buttonStates.disabled.audioBtn"></b-button>
            </div>
            
        </div>

        <EndAgentVideoCallModal v-if="showEndCallModal" :display-modal="showEndCallModal" />
        <div v-if="showMessageBox" id="loading" class="fullscreen" style="z-index: -999;">
          <ApplicationDetailedReviewcompair
            @CloseApp="ClossApp">
          </ApplicationDetailedReviewcompair>
        </div>
    </div>
</template>

<script>


import {BuefyHelper} from "../helpers/bufeyHelper";
import {useVideoKycStore} from "../store/videoKycStore";
import {GlobalEventManager} from "../helpers/globalEventManager";
import {GlobalFunctions} from "../helpers/globalFunctions";
import {GlobalEvents} from "../helpers/globalEvents";
import {PiniaStoreHelper} from "../helpers/piniaHelper";
import EndAgentVideoCallModal from "./EndAgentVideoCallModal.vue";
import RecorderStopwatch from "./RecorderStopwatch.vue";
import NetworkManager from "../network";
import JwtUtils from "../helpers/jwtUtils";
import {useAuthStore} from "../store/authStore";
import ApplicationDetailedReviewcompair from "../views/ApplicationDetailsView/ApplicationDetailedReviewcompair.vue";

export default {
  name: "AgentVideoCall",
  components :{
    EndAgentVideoCallModal,
    RecorderStopwatch,
    ApplicationDetailedReviewcompair
  },
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    },
    GetAudioBtnIcon(){
      const comp = this
      return comp.videoKycStore.streamStatus.isAudioMuted ? comp.videoKycStore.buttonIcons.micOff : comp.videoKycStore.buttonIcons.micOn
    },

    GetVideoBtnIcon(){
      const comp = this
      return comp.videoKycStore.streamStatus.isVideoMuted ? comp.videoKycStore.buttonIcons.videoOff : comp.videoKycStore.buttonIcons.videoOn
    },

  },

  props:{
    startRecordingVideoCall : {
      type : Boolean,
      default : false,
    },
  },


  watch:{
    startRecordingVideoCall(newVal,oldVal){
      const comp = this
      console.log("TESTING WHATS IN FILES",comp.videoKycStore.answeredVideoKycData.questionDetails)
      console.log("video recording watcher called")
      if(newVal){
        comp.startRecording()
        comp.showRecorderStopwatch = true

      }
    },
  },

  setup(){
    const videoKycStore = useVideoKycStore()
    const authStore = useAuthStore()
    return {videoKycStore, authStore}
  },


  data() {
      return {
        showRecorderStopwatch : false,
        showMessageBox:false,
        isRecording:false,

        // streamStatus:{
        //   isAudioMuted : true,
        //   isVideoMuted : true,
        // },

        showEndCallModal: false,

      };
  },
  methods: {
    showPageRefreshWarning(event) {
      event.preventDefault();
      event.returnValue = "Data will be lost if you leave the page, are you sure?";
    },
    /**
     * This function is used to wait until pdf generated
     */
    //  waitUntilPdfGenerated() {
    //     const comp = this;

    //     return new Promise((resolve) => {
    //         const checkPDFGenerated = () => {
    //             if (comp.videoKycStore.answeredVideoKycData.base64pdfdata !== '') {
    //                 console.log("PDF Generated ...");
    //                 resolve();
    //                 comp.showMessageBox=false
    //             } else {
    //                 console.log("waiting for PDF to Generate...");
    //                 setTimeout(checkPDFGenerated, 100);
    //             }
    //         };

    //         checkPDFGenerated();
    //     });
    // },
    ClossApp(value) {
      this.showMessageBox = value;
    },

    enableShowEndCallModal(){
      const comp = this
      comp.showEndCallModal = true
    },

    // TODO :: use this method to end the video call and close the video call model. used after data was submitted

    closeVideoCallModel(){
      const comp = this


      comp.showRecorderStopwatch = false
      comp.$root.videoCallManager.endVideoCall()
      PiniaStoreHelper.clearLocalStorage(comp.videoKycStore)

      console.log("close model emitted")
      GlobalEventManager.$emit(GlobalEvents.agentSide.hideVideoCallAgentModel)
      GlobalFunctions.setVideoCallStartedState(false) // video call end updated
    },


    /**
     * =================================================================================================================
     * A function that can be used to upload the video call recording
     * @param callback
     */
    uploadVideoCallVideo(callback){
      const comp = this

      // this is used to notify the client when agent started to upload the data
      comp.updateVideoCallLinkActiveState(false,()=>{
        // comp.$root.videoCallManager.endVideoSubmit()
        comp.$root.videoCallManager.endVideoCall()
      })

      const formData = new FormData();
      const tokenData = JwtUtils.parseJwt(comp.authStore.token)


      formData.append("kyc_form_id",comp.videoKycStore.answeredVideoKycData.kycForm.id)
      formData.append("video_call_recording",comp.videoKycStore.recordedVideo)

      formData.append("Auth.Id",tokenData.id)
      formData.append("Auth.Email",tokenData.email)
      formData.append("Auth.UserType",tokenData.userType)
      formData.append("Auth.Role",tokenData.role)
      formData.append("Auth.Branch",tokenData.branch)
      formData.append("Auth.Permissions",tokenData.permissions)
      formData.append("Auth.Username",tokenData.username)
      formData.append("Auth.Name",tokenData.name)

      if (comp.videoKycStore.recordedVideo) {
        const selectedFile = comp.videoKycStore.recordedVideo
        const fileSize = (selectedFile.size / (1024 * 1024)).toFixed(2);
        comp.videoKycStore.videoUploadState = "Uploading video call session :("+fileSize+"MB) *Upload Time might differ depending on the network"
      }

      NetworkManager.apiRequest("api/VideoCall/uploadVideoCallVideo",formData,(response)=>{
        if(response.statusCode === 200) {
          console.log("video call uploaded successfully")
          comp.videoKycStore.videoUploadProgress=60;
          comp.videoKycStore.videoUploadState = "Uploading Captured Images..."
          callback()
        }
        else {
          BuefyHelper.showToastMessage("Sorry! Unable to upload the video recording","is-danger")
          console.error("failed to upload the video : "+response.data.message)

          // enable video call again since an exception was occurred
          comp.updateVideoCallLinkActiveState(true)

          GlobalEventManager.$emit(GlobalEvents.agentSide.uploadingLoader.hide)
          comp.closeVideoCallModel()
        }


      },false,"multipart/form-data")
    },


    /**
     * =================================================================================================================
     * A function that can be used to upload the video call data
     * @param callback
     */
    uploadVideoCallImages(callback){
      const comp = this
      const formData = new FormData()
      const tokenData = JwtUtils.parseJwt(comp.authStore.token)

      formData.append("kyc_form_id",comp.videoKycStore.answeredVideoKycData.kycForm.id)


      formData.append("face_and_nic_front_side_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.file)
      formData.append("face_and_nic_front_side_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.rating)

      formData.append("face_and_nic_back_side_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.file)
      formData.append("face_and_nic_back_side_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.rating)

      formData.append("face_and_passport_bio_page_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.file)
      formData.append("face_and_passport_bio_page_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.rating)

      formData.append("face_and_passport_alteration_page_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.file)
      formData.append("face_and_passport_alteration_page_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.rating)

      formData.append("face_and_passport_entry_stamp_page_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.file)
      formData.append("face_and_passport_entry_stamp_page_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.rating)

      formData.append("face_and_visa_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.file)
      formData.append("face_and_visa_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.rating)

      formData.append("signature_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.file)
      formData.append("signature_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.rating)

      formData.append("selfie_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.file)
      formData.append("selfie_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.rating)

      formData.append("address_proof_document_image.image_file",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.file)
      formData.append("address_proof_document_image.rating",comp.videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.rating)

      formData.append("Auth.Id",tokenData.id)
      formData.append("Auth.Email",tokenData.email)
      formData.append("Auth.UserType",tokenData.userType)
      formData.append("Auth.Role",tokenData.role)
      formData.append("Auth.Branch",tokenData.branch)
      formData.append("Auth.Permissions",tokenData.permissions)
      formData.append("Auth.Username",tokenData.username)
      formData.append("Auth.Name",tokenData.name)

      NetworkManager.apiRequest("api/VideoCall/uploadVideoCallImages",formData,(response)=>{
        if(response.statusCode === 200){
          console.log("video call images uploaded successfully")
          comp.videoKycStore.videoUploadProgress=90;
          comp.videoKycStore.videoUploadState = "Uploading Agent changed data..."
          callback()
        }
        else {
          BuefyHelper.showToastMessage("Sorry! Unable to upload the images","is-danger")
          console.error("failed to upload the images : "+response.data.message)

          // enable video call again since an exception was occurred
          comp.updateVideoCallLinkActiveState(true)

          GlobalEventManager.$emit(GlobalEvents.agentSide.uploadingLoader.hide)
          comp.closeVideoCallModel()
        }
      },false,"multipart/form-data")

    },


    /**
     * =================================================================================================================
     * A function that can be used to submit the video call data
     */
    submitVideoCallData(){

      const comp = this
      const callDuration = Math.floor((comp.videoKycStore.callEndTime - comp.videoKycStore.callStartTime) / 1000) // in seconds


      const requestBody = {
        kyc_form_id: comp.videoKycStore.answeredVideoKycData.kycForm.id,
        video_call_location: JSON.stringify(comp.videoKycStore.answeredVideoKycData.location),
        call_duration: callDuration,
        nic_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.nic_comments,
        passport_comments: comp.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.required ? comp.videoKycStore.answeredVideoKycData.questionDetails.comments.passport_comments : null,
        video_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.video_comments,
        audio_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.audio_comments,
        drp_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.drp_comments,
        location_verification_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.location_verification_comments,
        location_verifier_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.location_verifier_comments,
        customer_behavior_comments: comp.videoKycStore.answeredVideoKycData.questionDetails.comments.customer_behavior_comments,
        date_of_account_funding: comp.videoKycStore.answeredVideoKycData.dateOfAccountFunding.toISOString(),
        call_details_pdf : comp.videoKycStore.answeredVideoKycData.base64pdfdata,
        is_kyc_form_changed_by_agent : comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallEkycFormUpdatedByAgent,
        spoken_language : comp.videoKycStore.answeredVideoKycData.kycForm.spoken_language,
        videoCallVerificationQuestionDTOs : comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList

      }

      NetworkManager.apiRequest("api/VideoCall/submitVideoCallData",requestBody,(response)=>{

        if(response.statusCode === 200){
          comp.videoKycStore.videoUploadProgress=100;
          comp.videoKycStore.videoUploadState = "Data Upload Completed"
          console.log("video call data submitted successfully...")
          BuefyHelper.showToastMessage("Successfully uploaded the video call data", "is-success")

          setTimeout(() => {
            GlobalEventManager.$emit(GlobalEvents.agentSide.uploadingLoader.hide)
            comp.closeVideoCallModel();
            comp.$router.push("/completedvideocalls");
         }, 1500); 
          
          // comp.$router.push("/completedvideocalls").then(()=>{comp.$router.go()});

        }
        else  {
          GlobalEventManager.$emit(GlobalEvents.agentSide.uploadingLoader.hide)

          // enable video call again since an exception was occurred
          comp.updateVideoCallLinkActiveState(true)

          comp.closeVideoCallModel()
          console.log("failed to submit the video call data : "+response.data.message)
        }
      })
    },


    /**
     * =================================================================================================================
     * This function is used to update the video call link active status
     * @param isActive
     * @param callback
     */
    updateVideoCallLinkActiveState(isActive,callback){
      const comp = this

      const requestBody = {
        kyc_form_id: comp.videoKycStore.answeredVideoKycData.kycForm.id,
        is_video_call_active: isActive
      }

      console.log("Updating video call link active state....")
      console.log("request body ")
      console.log(requestBody)

      NetworkManager.apiRequest("api/VideoCall/updateVideoCallActiveState",requestBody,(response)=>{
          if(response.statusCode === 200){
            console.log(response.data.message)

            if(typeof callback === "function"){
              callback()
            }

          }
          else {
            console.error("Failed to update video call active status ...")

            if(typeof callback === "function"){
              callback()
            }
          }
      })
    },


  // video call related functions - start ------------------------------------------------------------------------------

    startCall() {
      const comp = this;


      navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

      navigator.mediaDevices.getUserMedia({
        video: {facingMode: 'user'},
        audio: true
      })
        .then(function (stream) {

          const localVideo = document.getElementById('local-video')
          const remoteVideo = document.getElementById('remote-video')

          comp.$root.videoCallManager.initiateVideoCall(localVideo, remoteVideo, stream, comp.videoKycStore.openVideoRequestUserID, function () {
            //
          })
        })
        .catch(function (err) {
          console.log("error in start call")
          console.error(err);
        });
    },

    muteAudio (manualMute = false, muteAudio = false) {
      const comp = this;

      if(manualMute){
        if(muteAudio){
          comp.videoKycStore.streamStatus.isAudioMuted = true
          comp.$root.videoCallManager.muteAudio()
        }
        else{
          comp.videoKycStore.streamStatus.isAudioMuted = false
          comp.$root.videoCallManager.unmuteAudio()
        }
      }
      else{
        comp.videoKycStore.streamStatus.isAudioMuted = !comp.videoKycStore.streamStatus.isAudioMuted

        if (comp.videoKycStore.streamStatus.isAudioMuted) {
          comp.$root.videoCallManager.muteAudio()
        } else {
          comp.$root.videoCallManager.unmuteAudio()
        }
      }
    },


    muteVideo(manualPause = false, pauseVideo = false ) {

      const comp = this;

      if(manualPause){
        if(pauseVideo){
          comp.videoKycStore.streamStatus.isVideoMuted = true
          comp.$root.videoCallManager.pauseVideo()
        }
        else{
          comp.videoKycStore.streamStatus.isVideoMuted = false
          comp.$root.videoCallManager.resumeVideo()
        }
      }
      else{
        comp.videoKycStore.streamStatus.isVideoMuted = !comp.videoKycStore.streamStatus.isVideoMuted

        if (comp.videoKycStore.streamStatus.isVideoMuted) {
          comp.$root.videoCallManager.pauseVideo()
        } else {
          comp.$root.videoCallManager.resumeVideo()
        }
      }
    },


    // remote stream controlling
    muteUnmuteRemoteAudio(mute = true){
      const comp = this

      if(mute){
        comp.$root.videoCallManager.muteRemoteAudio();
      }
      else{
        comp.$root.videoCallManager.unmuteRemoteAudio();
      }

    },

    muteUnmuteRemoteVideo(mute = true){
      const comp = this

      if(mute){
        comp.$root.videoCallManager.pauseRemoteVideo();
      }
      else{
        comp.$root.videoCallManager.resumeRemoteVideo();
      }
    },


    // recording related methods
    // TODO :: call this methods in relevant positions
    startRecording() {

      const comp = this;

      comp.videoKycStore.callStartTime = performance.now(); // since call is enabled after the recording is started, get time here
      comp.videoKycStore.expectedCallEndTimeSec = (comp.videoKycStore.callStartTime / 1000 ) + ( comp.videoKycStore.answeredVideoKycData.callMaxDurationMinutes * 60) // in seconds

      const elementToRecord = document.getElementById('element-to-record')
      comp.$root.videoCallManager.startRecording(elementToRecord)

      comp.isRecording = true;

      BuefyHelper.showToastMessage("Video recording started","is-danger")

    },

    stopRecording(callback = ()=>{console.log("default callback")}) {
      const comp = this
      comp.isRecording = false
      comp.showRecorderStopwatch = false

      comp.videoKycStore.callEndTime = performance.now()

      comp.$root.videoCallManager.stopRecording(function (){
         comp.videoKycStore.recordedVideo = comp.$root.videoCallManager.getRecordedBlob()
         comp.videoKycStore.videoUploadState = "Starting File Upload"
        callback()

      }, false)

    },
    // async showpdfmodel(callback ) {
    //   console.log("Inside goToDownload...")
    //   this.showMessageBox=true
    //   // newWindow.focus(); // check this line
      
    //   await this.waitUntilPdfGenerated()

    //   callback()

    // },


    // This function can be used to record via button

    // recordVideo() {
    //   const comp = this;
    //   if (comp.videoKycData.recordButton.text === 'Start Recording') {
    //     comp.startRecording();
    //   } else {
    //
    //     comp.stopRecording();
    //
    //     comp.videoKycData.recordButton.text = 'Start Recording';
    //     comp.videoKycData.recordButton.icon = 'record';
    //     comp.videoKycData.recordButton.disabled = true;
    //     comp.videoKycData.playButton.disabled = false;
    //
    //     comp.$buefy.toast.open({
    //       message: 'Video recording stopped'
    //       duration: 5000,
    //     })
    //   }
    // },


    // This can be used to download the recorded video call
    downloadVideo() {
      const comp = this;
      const blob = comp.videoKycStore.recordedVideo
      const url = window.URL.createObjectURL(blob);

      const data = {
        src: url,
        blob: url
      };

      console.log('video download called')
      comp.videoKycStore.capturedVideo = data;

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = comp.videoKycStore.answeredVideoKycData.customer + '.mp4';
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    },


    captureImage(attachmentKey,download = false) {

      // TODO :: update method
      const comp = this;

      console.log("request to capture an image ...");
      const canvas = document.getElementById("imageCanvas");
      const video = document.getElementById("remote-video");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // original codes
      canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      const img_src = canvas.toDataURL("image/png");

      // mirror videos
      // mirroring capturing image
      // let canvasContext = canvas.getContext('2d')
      // canvasContext.translate(video.videoWidth, 0);
      // canvasContext.scale(-1, 1);
      // canvasContext.drawImage(video, 0, 0);

      // const img_src = canvas.toDataURL("image/png");

      canvas.toBlob((blob) => {
        let fileName = attachmentKey+".jpg"
        let file = new File([blob], fileName, {type: "image/jpeg"})

        comp.videoKycStore.answeredVideoKycData.imageUploadDetails[attachmentKey].srcObject = img_src;
        comp.videoKycStore.answeredVideoKycData.imageUploadDetails[attachmentKey].file = file;

        if(download){
          let a = document.createElement('a');
          a.href = img_src
          a.download = fileName
          a.click();


          setTimeout(() => {
            a.remove()
            window.URL.revokeObjectURL(img_src);
          }, 100);
        }

      }, 'image/jpeg');

      canvas.style.display = 'none';
    },

  // video call related functions - end --------------------------------------------------------------------------------



  },


// Life cycle hooks

  created(){
    const comp = this

    // listening to global events --------------------------------------------------------------------------------------
    GlobalEventManager.$on(GlobalEvents.agentSide.endCallModal.closeModal,()=>{
      console.log("end call modal : close modal emitted ...")
      comp.showEndCallModal = false // hide end call modal
    })

    GlobalEventManager.$on(GlobalEvents.agentSide.endCallModal.endWithOutSavingData,()=>{
      console.log("end call modal : end with out saving data emitted ...")
      comp.showEndCallModal = false
      comp.stopRecording()
      comp.closeVideoCallModel()
    })


    // This event will emit only if the data is validated
    GlobalEventManager.$on(GlobalEvents.agentSide.endCallModal.endAndSaveData,()=>{

      console.log("end call modal : end and save data emitted ...")

      comp.showEndCallModal = false
      GlobalEventManager.$emit(GlobalEvents.agentSide.uploadingLoader.show)

      comp.videoKycStore.isStartedUploadingCallData = true

      // pause local streams when uploading data
      comp.muteAudio(true,true)
      comp.muteVideo(true,true)

      // pause remote streams as well
      comp.muteUnmuteRemoteAudio()
      comp.muteUnmuteRemoteVideo()

      // this is the function sequence that is used to upload/submit all video call data
      comp.stopRecording(() => {

        console.log("hiding next call notification...")
        GlobalEventManager.$emit(GlobalEvents.agentSide.closeEndCallNotification)

        comp.uploadVideoCallVideo(() => {
          comp.uploadVideoCallImages(() => {
            comp.submitVideoCallData();

            // generate pdf if only kyc form is modified by the agent
            // if (comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallEkycFormUpdatedByAgent) {
            //   // console.log("Inside pdf creation...");
            //   // comp.showpdfmodel(() => {
            //   //   console.warn("generated pdf data")
            //   //   console.log(comp.videoKycStore.answeredVideoKycData.base64pdfdata)
            //      comp.submitVideoCallData();
            //   // });
            // }
            // else {
            //   console.log("Outside pdf creation...");
            //   comp.submitVideoCallData();
            // }
          });
        });
      });

    })


    GlobalEventManager.$on(GlobalEvents.agentSide.captureAttachmentImage,(imageKey)=>{
      console.log("agent video call questions : request to capture image - key : "+imageKey)
      comp.captureImage(imageKey)
    })

  },

  mounted() {
    window.addEventListener('beforeunload', this.showPageRefreshWarning);
    const comp = this
    console.log("video call manager at agent video call component : ")
    console.log(this.$root.videoCallManager)

    comp.startCall()
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.showPageRefreshWarning);
  },

}
</script>
<style scoped>


.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(88, 88, 88, 0.5);
  display:grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.action-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-small{
  width: 40px !important;
  height: 40px !important;
  color : black !important;
  border-color: black !important;
  padding : 0 !important;
  font-size: 24px;
  -webkit-box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
  box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
}

.button-large{
  height: 70px;
  width: 70px;
  margin-left: 20px;
  margin-right: 20px;
  padding :0 !important;
  color: white;
  background-color: red;
  font-size: 46px;
  -webkit-box-shadow: 1px 1px 14px 1px rgba(255,0,0,0.40);
  box-shadow: 1px 1px 14px 1px rgba(255,0,0,0.40);
}

.video-wrapper-card{
  width: 800px;
  /*height: 750px;*/
  background-color: white;
  border-radius: 25px;
  padding: 0 0 10px 0;
  margin-left: auto;
  margin-right: auto;
}

.customer-video-card{
  width: 800px;
  height: 450px;
  background-color: white;
  border: 2px solid #04c3fc;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.agent-video-card{
  width: 228px;
  height: 172px;
  margin-left: 2%;
  margin-top: 2%;
  background-color: white;
  border: 2px solid #04c3fc;
  border-radius: 25px;
  position: absolute;
  z-index: 1;
}

#recorder-stopwatch{
  position: fixed;
  z-index: 99;
  margin-left: calc(500px - 10px);
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
