<template>
  <ValidationObserver v-slot="{ invalid }">
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 66vw;
      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;
      z-index: 2">
      <div>
        <div class="columns is-7 is-variable" style="width: 66vw;">
          <div class="column i" style="text-align: center;">
            <label style="color: #04c3fc; font-size: large;">Return Application</label>
          </div> 
          <div class="column" style="margin-left: 92%; position: absolute;">
            <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
          </div>
        </div> 
      </div>
      <div v-if="!is_exceptional_approver">
        Return to<label style="color:#ED1C24">*</label>
        <div>
          <b-field>
            <ValidationProvider
              :rules="{ required: true }"
                        v-slot="{ errors }"
              >
              <b-select class="longer" placeholder="Select Option" v-model="RA_return_to" rounded @input="emitRAReturnTo" expanded>
                  <option value="RETURNED-DETAIL-REVIEWER">Detail Reviewer</option>
                  <option value="RETURNED-CALL-AGENT" >Call Agent</option>
              </b-select>
              <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </div>
      </div>
      
      <div class="message-box-body" >
        Select Reason<label style="color:#ED1C24">*</label>
        <div>
          <div class="grid-container" v-if="Comments !=null">
            <div v-for="(item, index) in Comments" :key="index" class="grid-item" >
              <div >
                <b-field>

                  <b-checkbox v-model="item.is_comment_selected">{{ item.comment_content}}</b-checkbox>
                </b-field>
              </div>
              

            </div>
          </div>
        </div>
      </div>
      <div class="message-box-body" v-if="Comments[Comments.length-1].is_comment_selected">
        Specify<label style="color:#ED1C24">*</label>
        <ValidationProvider
        :rules="{ required: true }"
                  v-slot="{ errors }"
        >
          <b-field>
            <ValidationProvider
              name="Specify"
              :rules="validateComment()"
              v-slot="{errors}">
              <b-input rounded 
                maxlength="200" 
                type="textarea"
                v-model="Comments[Comments.length-1].additional_comment"
                placeholder="" 
                rows = 2
                style="width: 100%;"></b-input>
              <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          </b-field>
        <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- <div class="message-box-body" v-if="!is_exceptional_approver">
        Add Comment/s for Customer
        <b-field>
          <b-input class="rounded-input"
          type="textarea"
            rounded 
            maxlength="400" 
            v-model="RA_customercomment" 
            @input="emitRAcustomercomment"
            placeholder="Specify" 
            rows = 3
            style="width: 100%;"></b-input>
        </b-field>
      </div> -->
      <div class="message-box-body" v-if="is_exceptional_approver">
        Add comment/s for internal use
        <ValidationProvider
          name="Internal Comment"
          :rules="validateComment()"
          v-slot="{errors}">
          <b-input class="rounded-input"
            type="textarea"
            rounded 
            maxlength="400" 
            v-model="RA_internalcomment" 
            @input="emitRAinternalcomment"
            placeholder="Specify"
            rows = 3
            style="width: 100%;"></b-input>
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div v-if="!is_exceptional_approver">
        <!-- <b-collapse :open="false" aria-id="contentIdForA11y1">
          <label aria-controls="contentIdForA11y1">Add Comment/s for internal use</label>
        </b-collapse> -->
        <!-- <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger="props">-->
                <label aria-controls="contentIdForA11y1">Add Comment/s for internal use</label>
                 
              <!-- </template> -->
              <!-- <div class="notification"> -->
                  <div class="content">
                    <b-field>
                      <ValidationProvider
                        name="Internal Comment"
                        :rules="validateComment()"
                        v-slot="{errors}">
                        <b-input class="rounded-input"
                          type="textarea"
                          rounded 
                          maxlength="400" 
                          v-model="RA_internalcomment" 
                          @input="emitRAinternalcomment"
                          placeholder="Specify"
                          rows = 3
                          style="width: 100%;"></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
              <!-- </div> -->
          <!-- </b-collapse> -->

      </div>
      <div style="margin-left: auto; margin-right: auto; text-align: center;">
        <b-button style=" width: 400px;" type="is-primary" @click="emitRArejectapplication" :disabled="!atLeastOneChecked || (invalid && !is_exceptional_approver)">Return</b-button>
      </div>
    </div>
  <!-- </b-modal>  -->
</ValidationObserver>
</template>

<script>
import NetworkManager from "@/network";
import {useAuthStore} from "../store/authStore";

export default {
  name: 'ReturnApplication',
  props: {
    title: String,
    message: String,
    show: Boolean,
    val:Boolean,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
   emits:['close'],

  data(){
    return{
      RA_return_to : null,
      RA_customercomment :'',
      RA_internalcomment:'',
      isvisible : true,
      RA_others :false,
      RA_isButtonDisabled: false,
      RA_showMessageBox: false,
      Comments:[],
      is_exceptional_approver:false,
     
    }
  },
  computed:{
    atLeastOneChecked() {   
      for (let i = 0; i < this.Comments.length; i++) {
          if (this.Comments[i].is_comment_selected) {        
              if((this.Comments.every((item) => !item.is_comment_selected)))
              {
                return false;
              }
              else 
              {
                  return true;
              }
          }
      }
      return false; // return false if no comment is selected
    }
    
  },
  methods: {
    emitRAReturnTo() {
      this.$emit('update_RA_return_to', this.RA_return_to);
    },
    emitRAResponces(){
      this.$emit('update_RA_reasons',this.Comments)
    },
    emitRAcustomercomment() {
      this.$emit('update_RA_customer_comment', this.RA_customercomment);
    },
    emitRAinternalcomment() {
      this.$emit('update_RA_internal_comment', this.RA_internalcomment);
    },
    emitRArejectapplication() {
        this.emitRAResponces()
        this.$emit('return_application',this.RA_isButtonDisabled,this.RA_showMessageBox);
    },
    emitCloss(){
      this.$emit('Closs_RA',this.RA_showMessageBox)
    },
    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    GetReturnList: function (){
      const comp= this
      const data = {
        type:"return"
      }

      //comp.showFullScreenLoader()
      NetworkManager.apiRequest('api/KycApplication/returnReasons',data, function (e){
        if(e.statusCode === 200){
          comp.Comments = e.data.comments
         
        }
      })
    },
    
  },
  mounted()
  {
    console.log("in mounted", this.authStore.loggedInUser)
    if(this.authStore.loggedInUser.role == 'Exceptional Approver'){
      this.is_exceptional_approver =true
    }
    this.GetReturnList()
  }
};
</script>

<style>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(15px, auto);
    grid-gap: 10px;
    text-align: left;
  }

  .grid-item {
    padding: 5px;
    font-size: 18px;
    text-align: left;
  }
</style>
