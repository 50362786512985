<template>
  <ValidationObserver v-slot="{ invalid }">
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 65vw;
      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;
      z-index: 2">
      <div>
        <div class="columns is-7 is-variable" style="width: 63vw">
          <div class="column is-three-fifth" style="text-align: center;">
            <label style="color: #04c3fc; font-size: large;">Reject Application</label>
          </div> 
          <div >
            <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
          </div>
        </div> 
      </div>
        
      
      <div class="message-box-body" >
        Select Reason<label style="color:#ED1C24">*</label>
        <div>
          <div class="grid-container" v-if="tableDetails !=null">
            <div v-for="(item, index) in tableDetails" :key="index" class="grid-item" >
              <div >
                <ValidationProvider
                  :rules="{ required: true }"
                            v-slot="{ errors }"
                  >
                  <b-field>
                    <b-checkbox v-model="item.is_comment_selected">{{ item.comment_content}}</b-checkbox>
                  </b-field>
                  <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>


      
      <div class="message-box-body" v-if="tableDetails[tableDetails.length-1].is_comment_selected">
        Specify<label style="color:#ED1C24">*</label>
        <ValidationProvider
        :rules="{ required: true }"
                  v-slot="{ errors }"
        >
        <b-field>
          <ValidationProvider
            name="Specify"
            :rules="validateComment()"
            v-slot="{errors}">
            <b-input class="rounded-input"
              rounded 
              maxlength="400" 
              type="textarea" 
              v-model="tableDetails[tableDetails.length-1].additional_comment" 
              @input="emitRspecify"
              placeholder="Specify" 
              rows = 3
              style="width: 100%;"></b-input>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-field>
        <span class="has-text-danger" style="font-size: 15px;">{{ errors[0] }}</span>
        </ValidationProvider>

        
      </div>
      <div class="message-box-body" v-if="!is_exceptional_approver">
        Add Comment/s for Customer<label style="color:#ED1C24">*</label>
        <b-field>
          <ValidationProvider
            required="true"
            name="Customer Comment"
            :rules="validateCustomerComment()"
            v-slot="{errors}">
            <b-input class="rounded-input"
              rounded 
              maxlength="400" 
              type="textarea" 
              v-model="R_customercomment" 
              @input="emitRcustomercomment"
              placeholder="" 
              rows = 3
              style="width: 100%;"></b-input>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-field>
      </div>
      <div class="message-box-body" v-if="is_exceptional_approver">
        Add Comment/s internal use
        <ValidationProvider
          name="Internal Comment "
          :rules="validateComment()"
          v-slot="{errors}">
          <b-input class="rounded-input"
            rounded 
            type="textarea"
            maxlength="400" 
            v-model="R_internalcomment" 
            @input="emitRinternalcomment"
            placeholder=""
            rows = 3
            style="width: 100%;"></b-input>
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div v-if="!is_exceptional_approver">
        <b-collapse :open="false" aria-id="contentIdForA11y1">
          <label aria-controls="contentIdForA11y1">Add Comment/s for internal use</label>
        </b-collapse>
        <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger="props">
                <label aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                      style="color: #04c3fc;"> 
                      <i class="mdi mdi-plus-circle-outline"></i> 
                      Add Comment/s for internal use
                </label>
                  <!-- <b-button
                      label="Add Comment/s for internal use"
                      aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open" /> -->
              </template>
              <!-- <div class="notification"> -->
                  <div class="content">
                    <b-field>
                      <ValidationProvider
                        name="Internal Comment  "
                        :rules="validateComment()"
                        v-slot="{errors}">
                        <b-input class="rounded-input"
                          rounded 
                          type="textarea"
                          maxlength="400" 
                          v-model="R_internalcomment" 
                          @input="emitRinternalcomment"
                          placeholder=""
                          rows = 3
                          style="width: 100%;"></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
              <!-- </div> -->
          </b-collapse>

      </div>
      <b-field grouped position="is-centered">
        <b-button style=" width: 25vw;" type="is-primary" @click="emitRrejectapplication" :disabled="invalid || (!atLeastOneChecked) " >Reject</b-button>
      </b-field>
    </div>
  <!-- </b-modal>  -->
  </ValidationObserver>
</template>

<script>
import NetworkManager from "@/network";
import {useAuthStore} from "../store/authStore";

export default {
  name: 'RejectApplication',
  props: {
    title: String,
    message: String,
    show: Boolean,
    val:Boolean,
  },

  // emits:['close'],
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data(){
    return{
      R_drp_fail : this.Boolean,
      R_Specify : '',
      R_customercomment :'',
      R_internalcomment:'',
      isvisible : true,
      R_others :false,
      R_isButtonDisabled: false,
      R_showMessageBox: false,
      tableDetails:[],
      is_exceptional_approver:false,

    }
  },
  computed:{
    atLeastOneChecked() {   
      for (let i = 0; i < this.tableDetails.length; i++) {
        if(this.tableDetails[i].is_comment_selected)
        {
          if(((this.tableDetails[this.tableDetails.length - 1].is_comment_selected) && (this.tableDetails[this.tableDetails.length - 1].additional_comment=='')))
          {
            return false;
          }
          if((this.tableDetails[i].is_comment_selected) && ((this.tableDetails[this.tableDetails.length - 1].is_comment_selected) && (this.tableDetails[this.tableDetails.length - 1].additional_comment=='')))
          {
            return false;
          }
          return true;
        }
      }
    }
    
  },
  methods: {
    emitRdrpfail() {
      this.$emit('update_R_drp_fail', this.R_drp_fail);
    },
    emitRspecify() {
      this.$emit('update_R_specify', this.R_specify);
    },
    emitRResponces(){
      this.$emit('update_R_reasons',this.tableDetails)
    },
    emitRcustomercomment() {
      this.$emit('update_R_customer_comment', this.R_customercomment);
    },
    emitRinternalcomment() {
      this.$emit('update_R_internal_comment', this.R_internalcomment);
    },
    emitRrejectapplication() {
      // if((this.tableDetails[0].is_comment_selected)=== '' || (this.tableDetails[1].additional_comment === ''))
      // {
      //     //until selects the comment or aditional comment r button will be diabled
      //      // this.R_isButtonDisabled===true
      // }
      // else{
        this.emitRResponces()
        this.$emit('reject_application',this.R_isButtonDisabled,this.R_showMessageBox);
      //}
    },
    emitCloss(){
      this.$emit('Closs_R',this.R_showMessageBox)
    },
    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateCustomerComment: function () {
      const obj = {
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    GetRejectReasonList: function (){
      const comp= this
      const data = {
        type:"reject"
      }
      console.log("reject Application reasons")

      //comp.showFullScreenLoader()
      NetworkManager.apiRequest('api/KycApplication/returnReasons',data, function (e){
        console.log("reject Application Reason list",e.data)
        if(e.statusCode === 200){
          comp.tableDetails = e.data.comments
          //if (comp.tableDetails && comp.tableDetails.length >= 14) {
            //Others_Array = comments[13]
            //console.log("TEST ",comp.tableDetails[13])
          //}
        }
      })
    },
    
  },
  mounted()
  {
    if(this.authStore.loggedInUser.role == 'Exceptional Approver'){
      this.is_exceptional_approver =true
    }
    console.log("in mounted")
    this.GetRejectReasonList()
  }
};
</script>

<style>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(15px, auto);
    grid-gap: 10px;
    text-align: left;
  }

  .grid-item {
    padding: 5px;
    font-size: 18px;
    text-align: left;
  }
  .rounded-input .input,
  .rounded-input textarea {
    border-radius: 25px;
  }
</style>
