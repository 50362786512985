<template>
  <div>
    <!-- <b-message type="is-success">
      Overall Score :
      {{ $root.formData.overall_score }}
    </b-message> -->
    <!-- <p style="color: #a7a9ac" class="mb-2">Customer Details</p> -->
    <GeneralDetails />
    <hr
      style="
        background-color: #1b1b1b;
        height: 1.8px;
        opacity: 0.5;
        margin-inline: 4px;
      "
    />
    <AccountDetails />
    <hr
      style="
        background-color: #1b1b1b;
        height: 1.8px;
        opacity: 0.5;
        margin-inline: 4px;
      "
    />
    <ImageUploads />
    <hr
      style="
        background-color: #1b1b1b;
        height: 1.8px;
        opacity: 0.5;
        margin-inline: 4px;
      "
    />
    <div class="columns is-flex is-align-items-center is-7 is-variable">
      <div class="column" v-if="!isLoading">
        <ShowMapLocation  label="Geo Location" :position="customerLocation"/>
      </div>
      <div class="column">
        <!-- <b-message type="is-info" has-icon>
          Duration to complete form : <span st>15 minutes and 38 seconds</span>
        </b-message> -->
      </div>
    </div>
    <hr
      style="
        background-color: #1b1b1b;
        height: 1.8px;
        opacity: 0.5;
        margin-inline: 4px;
      "
    />

    <p>Video Call Details</p>
    <div class="columns is-7 is-variable">
      <div class="column">
        <InputLabel placeholder=" -- No data -- " 
          label="Date"
          :value="getFormattedDateTime(formData.video_call_date)"
          styles="mt-2"
          readonly="true"
          class="non-interactable-input"
        />
      </div>
      <div class="column">
        <InputLabel placeholder=" -- No data -- " 
          label="Time"
          v-model="formData.video_call_time"
          styles="mt-2"
          readonly="true"
          class="non-interactable-input"
        />
      </div>
    </div>
    <div class="columns is-7 is-variable" v-if="GlobalFunctions.isVideoCallCompleted(videoCallData)">
      <div class="column">
        <InputLabel placeholder=" -- No data -- " 
            label="Date of Account Funding"
            :value="getFormattedDateTime(formData.date_of_account_funding)"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
        />
      </div>
      <div class="column">
        <ShowMapLocation  label="Geo Location Video KYC" :position="videoKycCustomerLocation" v-if="videoKycCustomerLocation !== null"/>
      </div>
    </div>
    
    <hr
      style="
        background-color: #1b1b1b;
        height: 1.8px;
        opacity: 0.5;
        margin-inline: 4px;
      "
    />
  

    <div>
      <div v-if="detailReviewerAttachments.length !== 0">
        <label>Image Attachments of Detail Reviewer</label>
        <div v-for="(item, index) in detailReviewerAttachments" :key="index">
          <div style="margin-top: 20px">
            <div class="image-box-wrapper" rounded style="width: 28.2vw; border: 2px solid #000000; border-radius: 25px; padding: 20px;">
                <label>Image Attachment {{ index + 1 }}</label>
                <div v-if="item.type === 'pdf'">        
                  <img
                    :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                    style="height: 53vh"
                    resposive
                    alt="Attachment"
                  />
                  <button class="attachment-download-button"
                  @click="downloadpdf(item.url, 'Attachment')">Download</button>
                </div>
                <div v-else>
                  <img
                    :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                    style="height: 53vh"
                    resposive
                    alt="Attachment"
                  />
                </div>
                  <br>
                <label>Comment : </label>
                <label>{{item.comment}}</label>
              <br/>
            </div>
            <br/>
          </div>

        </div>
        <hr
        style="
          background-color: #1b1b1b;
          height: 1.8px;
          opacity: 0.5;
          margin-inline: 4px;
        "
      />
      </div>


      <div v-if="callAgentAttachments.length !== 0">
        <label>Image Attachments of Call Agent</label>
        <div v-for="(item, index) in callAgentAttachments" :key="item.path">
        <div style="margin-top: 20px">
            <div  rounded style="width: 28.2vw; border: 2px solid #000000; border-radius: 25px; padding: 20px;">
            <label>Image Attachment {{ index + 1 }}</label>
            <div class="image-box-wrapper" v-if="item.type === 'pdf'">
              <img
                :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                style="height: 53vh"
                resposive
                alt="Attachment"
              />
              <button class="attachment-download-button"
              @click="downloadpdf(item.url, 'Attachment')">Download</button>
            </div>
            <div v-else>
              <img
                :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                style="height: 53vh"
                resposive
                alt="Attachment"
              />
            </div>
            <br />
            <label>Comment : </label>
            <label>{{ item.comment }}</label>
            <br />
            </div>
            <br />
        </div>
        </div>
        <hr
        style="
          background-color: #1b1b1b;
          height: 1.8px;
          opacity: 0.5;
          margin-inline: 4px;
        "
      />
      </div>

      <div v-if="applicationAttachments.length !== 0">
        <label>Application Attachments</label>
        <div v-for="(item, index) in applicationAttachments" :key="item.path">
        <div>
            <div  class="image-box-wrapper" rounded style="width: 28.2vw; border: 2px solid #000000; border-radius: 25px; padding: 20px;">
            <label>Image Attachment {{ index + 1 }}</label>
            <div v-if="item.type === 'pdf'">
              <img
                :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                style="height: 53vh"
                resposive
                alt="Attachment"
              />
              <button class="attachment-download-button"
               @click="downloadpdf(item.url, 'Attachment')">Download</button>
            </div>
            <div v-else>
              <img
                :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                style="height: 53vh"
                resposive
                alt="Attachment"
              />
            </div>
            <br />
            <label>Comment : </label>
            <label>{{ item.comment }}</label>
            <br />
            </div>
            <br />
        </div>
        </div>
        <hr
        style="
          background-color: #1b1b1b;
          height: 1.8px;
          opacity: 0.5;
          margin-inline: 4px;
        "
      />
      </div>
    </div>

   
    <div v-if="videoCallData  != null">
      
    <p class="has-text-primary" v-if="videoCallData.face_and_nic_front_side_image  != null">User Uploads and Video Call Data Comparison</p>
    <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
          label="User uploaded NIC front image"
          styles="mt-5"
          :imageName=formData.nic_front_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
          <ImageBox
            label="User uploaded NIC front image"
            styles="mt-5"
            :imageName=pdfPlaceholder
          />
          <button class="download-button"
            @click="downloadpdf(formData.nic_front_attachment.attachment_url, 'NIC Front Image')">Download</button>
        </div>
      </div>
      <div class="column" v-if="videoCallData.face_and_nic_front_side_image != null">
        <ImageBox
          label="NIC front image from video call"
          styles="mt-5"
          :imageName=videoCallData.face_and_nic_front_side_image.path
        />
        <div class="rating-wrapper">
          
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.face_and_nic_front_side_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>
    <!-- <b-message type="is-primary" has-icon>
      Face Validation Failed :
      <span class="ml-6">Image in NIC 33.85% matching with Selfie</span>
    </b-message> -->
    <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
          label="User uploaded NIC rear image"
          styles="mt-3"
          :imageName=formData.nic_back_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
        <ImageBox
          label="User uploaded NIC front image"
          styles="mt-5"
          :imageName=pdfPlaceholder
        />  
        <button class="download-button"
          @click="downloadpdf(formData.nic_back_attachment.attachment_url, 'NIC Rear Image')">Download</button>
        </div>
      </div>
      <div class="column" v-if="videoCallData.face_and_nic_back_side_image != null">
        <ImageBox
          label="NIC rear image from video call"
          styles="mt-3"
          :imageName=videoCallData.face_and_nic_back_side_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.face_and_nic_back_side_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>

       <!-- nic comments -->
        <div
          style="width: fit-content; max-width: 850px"
          v-if="videoCallData.nic_comments != null"
        >
          <div class="columns is-variable is-7">
            <div class="column is-three-fifths" style="pointer-events: none">
              <div
                v-for="comment in videoCallData.nic_comments.comments"
                :key="comment.comment_content"
              >
                <b-field style="width: fit-content">
                  <b-checkbox
                    type="is-primary"
                    v-model="comment.is_comment_selected"
                    @click.native="warning()"
                    readonly="true"
                    class="non-interactable-input"
                  >
                    {{ comment.comment_content }}
                  </b-checkbox>
                </b-field>

                <div v-if="comment.additional_comment != ''">
                  <b-field style="text-align: center">
                    <b-input
                      rounded
                      @click.native="warning()"
                      maxlength="200"
                      placeholder="Specify"
                      v-model="comment.additional_comment"
                      readonly="true"
                      class="non-interactable-input"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end of nic comments -->

    <div class="columns is-7 is-variable" v-if="formData.passport_bio_page_attachment.attachment_type !== ''">
      <div class="column">
        <ImageBox v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
          label="User uploaded Passport bio page image"
          styles="mt-3"
          :imageName=formData.passport_bio_page_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
          <ImageBox
            label="User uploaded Passport bio page image"
            styles="mt-3"
            :imageName=pdfPlaceholder
          />
          <button class="download-button"
            @click="downloadpdf(formData.passport_bio_page_attachment.attachment_url, 'Passport Bio Page Image')">Download</button>
        </div>
      </div>
      <div class="column">
        <ImageBox
          label="Passport bio page image from video call"
          styles="mt-3"
          :imageName=videoCallData.face_and_passport_bio_page_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.face_and_passport_bio_page_image.rating"
              style="margin-left:150px;" 
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>

    <div class="columns is-7 is-variable"  v-if="formData.passport_alteration_attachment.attachment_type !== ''">
      <div class="column">
        <ImageBox v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
          label="User uploaded Passport alteration page image"
          styles="mt-3"
          :imageName=formData.passport_alteration_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
          <ImageBox 
            label="User uploaded Passport alteration page image"
            styles="mt-3"
            :imageName=pdfPlaceholder
          />
          <button class="download-button"
            @click="downloadpdf(formData.passport_alteration_attachment.attachment_url, 'Passport Alteration Page Image')">Download</button>
        </div>
      </div>
      <div class="column">
        <ImageBox
          label="Passport alteration page image from video call"
          styles="mt-3"
          :imageName=videoCallData.face_and_passport_alteration_page_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.face_and_passport_alteration_page_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>

    <div class="columns is-7 is-variable"  v-if="formData.passport_entry_stamp_attachment.attachment_type !== ''">
      <div class="column">
        <ImageBox v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
          label="User uploaded Passport entry stamp page image"
          styles="mt-3"
          :imageName=formData.passport_entry_stamp_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
          <ImageBox
            label="User uploaded Passport entry stamp page image"
            styles="mt-3"
            :imageName=pdfPlaceholder
          />
          <button class="download-button"
            @click="downloadpdf(formData.passport_entry_stamp_attachment.attachment_url, 'Passport Entry Stamp Page Image')">Download</button>
        </div>
      </div>
      <div class="column">
        <ImageBox
          label="Passport entry stamp page image from video call"
          styles="mt-3"
          :imageName=videoCallData.face_and_passport_entry_stamp_page_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.face_and_passport_entry_stamp_page_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>

             <!-- ////////////////////passport comments start//////////////// -->
      <!-- <div v-if="videoCallDetail.face_and_passport_alteration_page_image || videoCallDetail.face_and_passport_entry_stamp_page_image"> -->
        <div v-if="videoCallData.passport_comments != null">
        <div relative style="width: fit-content; max-width: 1100px">
          <div class="columns is-variable is-7">
            <div class="column is-three-fifths" style="pointer-events: none">
              <div
                v-for="comment in videoCallData.passport_comments.comments"
                :key="comment.comment_content"
              >
                <b-field style="width: fit-content">
                  <b-checkbox
                    type="is-primary"
                    v-model="comment.is_comment_selected"
                    @click.native="warning()"
                    readonly="true"
                    class="non-interactable-input"
                  >
                    {{ comment.comment_content }}
                  </b-checkbox>
                </b-field>

                <div v-if="comment.additional_comment != ''">
                  <b-field style="text-align: center">
                    <b-input
                      @click.native="warning()"
                      maxlength="200"
                      rounded
                      placeholder="Specify"
                      v-model="comment.additional_comment"
                      readonly="true"
                      class="non-interactable-input"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
        </div>
      </div>

      <!-- ////////////////////passport comments end//////////////// -->

      <div v-if="formData.visa_attachment.attachment_type !== ''" class="columns is-7 is-variable">
        <div class="column">
          <ImageBox v-if="formData.visa_attachment.attachment_type !== 'pdf'"
            label="User uploaded Visa image"
            styles="mt-3"
            :imageName=formData.visa_attachment.attachment_url
          />
          <div v-else class="image-box-wrapper">
            <ImageBox
              label="User uploaded Visa image" 
              styles="mt-3"
              :imageName=pdfPlaceholder
            />
            <button class="download-button"
              @click="downloadpdf(formData.visa_attachment.attachment_url, 'Visa Image')">Download</button>
          </div>
        </div>
        <div class="column" v-if="videoCallData.face_and_visa_image != null">
          <ImageBox
            label="Visa image from video call"
            styles="mt-3"
            :imageName=videoCallData.face_and_visa_image.path
          />
          <div class="rating-wrapper">
            <b-rate disabled
                @click.native="warning()"
                v-model="videoCallData.face_and_visa_image.rating"
                style="margin-left:150px;"
                custom-text="Satisfactory Level">
            </b-rate>
          </div>
        </div>
    </div>
    
      <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
          label="User uploaded Selfie image"
          styles="mt-3"
          :imageName=formData.selfie_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
          <ImageBox
            label="User uploaded Selfie image"
            styles="mt-3"
            :imageName=formData.selfie_attachment.attachment_url
          />
          <button class="download-button"
            @click="downloadpdf(formData.selfie_attachment.attachment_url, 'Selfie Image')">Download</button>
        </div>
      </div>
      <div class="column" v-if="videoCallData.selfie_image != null">
        <ImageBox
          label="Selfie image from video call"
          styles="mt-3"
          :imageName=videoCallData.selfie_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.selfie_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>



    <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox
          label="User uploaded Signature image"
          styles="mt-3"
          :imageName=formData.signature_image
        />
      </div>
      <div class="column" v-if="videoCallData.signature_image != null">
        <ImageBox
          label="Signature image from video call"
          styles="mt-3"
          :imageName=videoCallData.signature_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.signature_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>

    <div class="columns is-7 is-variable">
      <div class="column">
        <ImageBox v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
          label="User uploaded address proof document"
          styles="mt-3"
          :imageName=formData.address_proof_attachment.attachment_url
        />
        <div v-else class="image-box-wrapper">
          <ImageBox
            label="User uploaded address proof document"
            styles="mt-3"
            :imageName=pdfPlaceholder
          />
          <button class="download-button"
            @click="downloadpdf(formData.address_proof_attachment.attachment_url, 'Address Proof Document')">Download</button>
        </div>
      </div>
      <div class="column" v-if="videoCallData.address_proof_document_image != null">
        <ImageBox
          label="Address proof document from video call"
          styles="mt-3"
          :imageName=videoCallData.address_proof_document_image.path
        />
        <div class="rating-wrapper">
          <b-rate disabled
              @click.native="warning()"
              v-model="videoCallData.address_proof_document_image.rating"
              style="margin-left:150px;"
              custom-text="Satisfactory Level">
          </b-rate>
        </div>
      </div>
    </div>

         <!-- checkbox selection for vedio call review page -->
         <div class="check">
        <div class="columns is-7 is-variable">
          <div class="column" v-if="videoCallData.video_comments != null">
            <p class="mb-2">Video</p>
            <div style="pointer-events: none">
              <div
                v-for="comment in videoCallData.video_comments.comments"
                :key="comment.comment_content"
              >
                <b-field style="width: fit-content">
                  <b-checkbox
                    type="is-primary"
                    v-model="comment.is_comment_selected"
                    @click.native="warning()"
                    readonly="true"
                    class="non-interactable-input"
                  >
                    {{ comment.comment_content }}
                  </b-checkbox>
                </b-field>

                <div v-if="comment.additional_comment != ''">
                  <b-field style="text-align: center">
                    <b-input
                      @click.native="warning()"
                      maxlength="200"
                      rounded
                      placeholder="Specify"
                      readonly="true"
                      class="non-interactable-input"
                      v-model="comment.additional_comment"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
 <!-- Audio call verification -->

 <div class="column" v-if="videoCallData.audio_comments != null">
        <p class="mb-2">Audio call verification</p>
        <div style="pointer-events: none">
          <div
            v-for="comment in videoCallData.audio_comments.comments"
            :key="comment.comment_content"
          >
            <b-field style="width: fit-content">
              <b-checkbox
                type="is-primary"
                v-model="comment.is_comment_selected"
                @click.native="warning()"
                readonly="true"
                class="non-interactable-input"
              >
                {{ comment.comment_content }}
              </b-checkbox>
            </b-field>

            <div v-if="comment.additional_comment != ''">
              <b-field style="text-align: center">
                <b-input
                  @click.native="warning()"
                  maxlength="200"
                  rounded
                  placeholder="Specify"
                  v-model="comment.additional_comment"
                  readonly="true"
                  class="non-interactable-input"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
   

       <!-- if DRP Verification failed -->

       <div class="columns is-7 is-variable">
        <div class="column" v-if="videoCallData.drp_comments != null">
          <p class="mb-2">if DRP Verification failed</p>

          <div style="pointer-events: none">
            <div
              v-for="comment in videoCallData.drp_comments.comments"
              :key="comment.comment_content"
            >
              <b-field style="width: fit-content">
                <b-checkbox
                  type="is-primary"
                  v-model="comment.is_comment_selected"
                  @click.native="warning()"
                  readonly="true"
                  class="non-interactable-input"
                >
                  {{ comment.comment_content }}
                </b-checkbox>
              </b-field>

              <div v-if="comment.additional_comment != ''">
                <b-field style="text-align: center">
                  <b-input
                    @click.native="warning()"
                    maxlength="200"
                    rounded
                    placeholder="Specify"
                    readonly="true"
                    class="non-interactable-input"
                    v-model="comment.additional_comment"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>

        <div
        class="column"
        v-if="videoCallData.customer_behavior_comments != null"
      >
        <p class="mb-2">Customer Behaviour</p>

        <div style="pointer-events: none">
          <div
            v-for="comment in videoCallData.customer_behavior_comments
              .comments"
            :key="comment.comment_content"
          >
            <b-field style="width: fit-content">
              <b-checkbox
                type="is-primary"
                v-model="comment.is_comment_selected"
                @click.native="warning()"
                readonly="true"
                class="non-interactable-input"
              >
                {{ comment.comment_content }}
              </b-checkbox>
            </b-field>

            <div v-if="comment.additional_comment != ''">
              <b-field style="text-align: center">
                <b-input
                  @click.native="warning()"
                  maxlength="200"
                  rounded
                  placeholder="Specify"
                  readonly="true"
                  class="non-interactable-input"
                  v-model="comment.additional_comment"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      </div>

       <!-- Location verification -->
       <div class="columns is-7 is-variable">
        <div
          class="column"
          v-if="videoCallData.location_verification_comments != null"
        >
          <p class="mb-2">Location verification</p>
          <div style="pointer-events: none">
            <div
              v-for="comment in videoCallData.location_verification_comments
                .comments"
              :key="comment.comment_content"
            >
              <b-field style="width: fit-content">
                <b-checkbox
                  type="is-primary"
                  v-model="comment.is_comment_selected"
                  @click.native="warning()"
                  readonly="true"
                  class="non-interactable-input"
                >
                  {{ comment.comment_content }}
                </b-checkbox>
              </b-field>

              <div v-if="comment.additional_comment != ''">
                <b-field style="text-align: center">
                  <b-input
                    @click.native="warning()"
                    maxlength="200"
                    rounded
                    placeholder="Specify"
                    readonly="true"
                    class="non-interactable-input"
                    v-model="comment.additional_comment"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      <!-- location verifier -->
      <div
        class="column"
        v-if="videoCallData.location_verifier_comments != null"
      >
        <p class="mb-2">Location verifier</p>
        <div style="pointer-events: none">
          <div
            v-for="comment in videoCallData.location_verifier_comments
              .comments"
            :key="comment.comment_content"
          >
            <b-field style="width: fit-content">
              <b-checkbox
                type="is-primary"
                v-model="comment.is_comment_selected"
                readonly="true"
                class="non-interactable-input"
                @click.native="warning()"
              >
                {{ comment.comment_content }}
              </b-checkbox>
            </b-field>

            <div v-if="comment.additional_comment != ''">
              <b-field style="text-align: center">
                <b-input
                  @click.native="warning()"
                  maxlength="200"
                  rounded
                  placeholder="Specify"
                  readonly="true"
                  class="non-interactable-input"
                  v-model="comment.additional_comment"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>

      </div>
    <hr
      :style="{
        'background-color': '#1b1b1b',
        height: '1.8px',
        opacity: '0.5',
        'margin-inline': '4px',
      }"
    />
  </div>
   
    <div class="columns is-7 is-variable" v-if="videoCallData != null">
      <div class="column" v-if="formData.spoken_language">
        <p class="mb-2">Customer Spoken Language</p>
        <InputLabelVue copyable="true"
          label="Customer Spoken Language"
          :value="formData.spoken_language"
          readonly="true"
          class="non-interactable-input"
        />
      </div>
         
     
      <!-- <div class="column">
        <p>Introduced By</p>
        <InputLabelVue
          label="Name/ Staff ID"
          v-model="$root.formData.staff_name"
        />
         <b-message type="is-success" has-icon class="mt-4">
          Approved after Video-KYC Process :
          <b-button class="is-success is-rounded mt-2"
            >View Audit Information</b-button
          >
        </b-message>
      </div> -->
    </div>

    <b-loading  :is-full-page="true" :can-cancel="false" v-model="isLoading" />

  </div>
</template>

<script>
import VedioCallDetails from "./VedioCallDetails.vue";
import ImageUploads from "./ImageUploads.vue";
import AccountDetails from "./AccountDetails.vue";
import GeneralDetails from "./GeneralDetails.vue";
import ImageBox from "../../../components/ImageBox.vue";
import InputLabelVue from "../../../components/InputLabel.vue";
import NetworkManager from "../../../network";
import {useAuthStore} from "../../../store/authStore";
import {DateTimeManger} from "../../../helpers/dateTimeManger";
import InputLabel from "../../../components/InputLabel.vue";
import {GeoPositionCoordinates} from "../../../helpers/classes/geoPositionCoordinates";
import ShowMapLocation from "../../../components/ShowMapLocation.vue";
import {BuefyHelper} from "../../../helpers/bufeyHelper";
import {GlobalFunctions} from "../../../helpers/globalFunctions";

export default {
  name: "RiskAnalysis",
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    }
  },
  components: {
    ShowMapLocation,
    VedioCallDetails,
    ImageUploads,
    AccountDetails,
    GeneralDetails,
    ImageBox,
    InputLabelVue,
    InputLabel,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return {
      pdfPlaceholder : require("@/assets/Images/pdf.png"),
      formData :[],
      videoCallData:[],
      customerLocation : new GeoPositionCoordinates(),
      videoKycCustomerLocation : null,
      isLoading: true,
      detailReviewerAttachments: "",
      callAgentAttachments :"",
      applicationAttachments : "",
    };
  },
  methods: {
    downloadpdf(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getAttachmentInfo:function(){
      const comp = this;
      const data = { kyc_form_id: comp.authStore.kyc_form_id};


      NetworkManager.apiRequest(
      "api/KycApplication/getAgentUploadAttachmentById",
      data,
      function (e) {
          if (e.statusCode === 200) {
              console.log("getAgentUploadAttachmentById data ===============", e.data);
              comp.detailReviewerAttachments = e.data.hold_application_attachments.detailReviewerAttachments;
              comp.callAgentAttachments = e.data.hold_application_attachments.callAgentHoldAttachments;
              comp.applicationAttachments = e.data.additional_attachments.attachment_list;
          } else{
              console.error("FAILED GETTING ATTACHMENTS")
          }
      }
      );  
  },
    getFormattedDateTime(date){
      return DateTimeManger.formatCustomerInputDates(date)
    },
    GetApprovedApplicationData: function (){
      const comp= this
      comp.isLoading = true

      const data = {
        id: comp.authStore.kyc_form_id
      }
      console.log("in GetApprovedApplicationData")

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        console.log("in GetApprovedApplicationData",e.data)
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication   
          comp.videoCallData = e.data.videoCallDetail

          // get customer location
          let customerLocation = JSON.parse(e.data.kycApplication.customer_location);
          console.log(customerLocation)
          comp.customerLocation =  new GeoPositionCoordinates(customerLocation.latitude,customerLocation.longitude)

          // get video kyc customer location
          if(GlobalFunctions.isVideoCallCompleted(comp.videoCallData)){
            let videoKycCustomerLocation = JSON.parse(comp.videoCallData.video_call_location);
            console.log("video kyc customer location")
            console.log(videoKycCustomerLocation)
            comp.videoKycCustomerLocation = new GeoPositionCoordinates(videoKycCustomerLocation.latitude,videoKycCustomerLocation.longitude)

          }
          comp.isLoading = false
        }
        else{
          BuefyHelper.showToastMessage("Unable to get the application data",'is-danger')
          comp.isLoading = false
        }

      })
    },
  },
  mounted() {
    this.GetApprovedApplicationData();
    this.getAttachmentInfo();
   }
};
</script>

<style scoped>
 .rating-wrapper label{
   font-size: medium;
 }
 .non-interactable-input {
  pointer-events: none;
}
.image-box-wrapper {
    position: relative; /* Make the wrapper a positioning context */
}

.download-button {
    display: none; /* Initially hide the download button */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95.2%;
    margin-top :2.5vh;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
}
.attachment-download-button {
    display: none; /* Initially hide the download button */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top :0vh;
    border-radius: 18px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    color: white;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

.image-box-wrapper:hover .download-button {
    display: block; /* Show the download button on hover */
}
.image-box-wrapper:hover .attachment-download-button  {
    display: block; /* Show the download button on hover */
}
 </style>
