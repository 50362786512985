<template>
  <div>
    <div v-if="getCommitKycDetails !== null">
          <div v-for="comments in getCommitKycDetails">
            <div v-if="!(comments.comment === '' || comments.comment === null)">
              <div>
                <ul type="disc" style="color:#000000" :key="comments.comment_label">
                      <li >*{{comments.comment_label}}</li>
                      <br>
                    </ul>
                <!-- <b-field :label="comments.comment_label"  style="font-family: Gotham-Light; font-weight: bold">
                </b-field> -->
              </div>          
            </div>
          </div>

          <b-modal v-model="isCardModalActive" :width="1100" scroll="keep" >
            <div class="card" rounded style="border: 0.3vh solid #000000; border-radius: 25px; padding: 20px;">
              <div v-for="comments in getCommitKycDetails">
                <div>
                  <b-field :label="comments.comment_label"  style="font-family: Gotham-Light; font-weight: bold">
                  </b-field>
                </div>
                <div>
                  <InputLabel v-if="comments.internal_comment"
                    label="Internal Comment"
                    readonly="true"
                    type="textarea" 
                    placeholder="-- No Data --" 
                    :value="comments.internal_comment"
                    />
                  </div>
                  <div>
                    <InputLabel v-if="comments.customer_comment"
                    label="Customer Comment"
                    readonly="true"
                    type="textarea" 
                    placeholder="-- No Data --" 
                    :value="comments.customer_comment"
                  />
                </div>
                <div>
                  <div v-for="items in comments.reasonsList">
                    <ul type="disc" style="color:#000000" :key="items.comment_key">
                      <li v-if="items.is_comment_selected">*{{items.comment_content}}</li>
                    </ul>
                    <div v-if="(items.comment_content == 'Other' || items.comment_content == 'Others') && items.is_comment_selected ">
                      <InputLabel 
                        label="Please specify"
                        readonly="true"
                        type="textarea" 
                        placeholder="-- No Data --" 
                        :value="items.additional_comment"
                        />
                    </div>
                  </div>
                </div>
                <hr style="width:100%;"
                  :style="{
                      'background-color': '#1b1b1b',
                      height: '1.8px',
                      opacity: '0.5',
                      'margin-inline': '4px',
                  }"
                />
              </div>
            </div>
          </b-modal>
        </div>

        <b-button @click="isCardModalActive = true" v-if="getCommitKycDetails !== null && getCommitKycDetails.length > 0" type="is-primary" outlined>Show more</b-button>

        <div class="is-7 mt-3" v-for="reports in drpArray" style="margin-top: 20;">
          <label >DRP Checked date : {{ reports.updated_at  }}</label>
          <InputLabel class="mt-3"
                    v-if="reports.comment !=''"
                    label="DRP Internal Comment"
                    readonly="true"
                    type="textarea"
                    placeholder="-- No Data --"
                    :value="reports.comment"
                  />
        </div>
    <div class="columns is-7 is-variable">
      <div class="column">
        <!-- Basic Information -->
        <div>
          <p>Basic Information</p>
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.title.customer_input"
            label="Title"
            :value="formData.title.customer_input"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Title"
            :value="formData.title.call_agent_input"
            styles="mt-5"
            :hide="!formData.title.is_changed_by_call_agent"
            :readonly="true"
            class="non-interactable-input"
          />
          <FullNameView
            class="mt-1"
            label="Full Name (As per the NIC / EIC / PP)"
            :value="formData.full_name.customer_input"
            :is-agent="false"
            :copyable=true
            />
          <FullNameView v-if="formData.full_name.is_changed_by_call_agent"
            class="mt-1"
            label="Full Name (As per the NIC / EIC / PP)"
            :value="formData.full_name.call_agent_input"
            :is-agent="true"
            :copyable=true
            />
          <!-- <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.full_name.customer_input"
            label="Full Name (As per the NIC / EIC / PP)"
            :value="formData.full_name.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Full Name (As per the NIC / EIC / PP)"
            :value="formData.full_name.call_agent_input"
            styles="mt-5"
            :hide="!formData.full_name.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          /> -->
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.citizenship.customer_input"
            label="Citizenship"
            :value="formData.citizenship.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Citizenship"
            :value="formData.citizenship.call_agent_input"
            styles="mt-5"
            :hide="!formData.citizenship.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.residentship.customer_input"
            label="Residency"
            :value="formData.residentship.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Residency"
            :value="formData.residentship.call_agent_input"
            styles="mt-5"
            :hide="!formData.residentship.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.nationality.customer_input"
            label="Nationality"
            :value="formData.nationality.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Nationality"
            :value="formData.nationality.call_agent_input"
            styles="mt-5"
            :hide="!formData.nationality.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />

          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.date_of_birth.customer_input"
            label="Date of Birth"
            :value="getFormattedDateTime(formData.date_of_birth.customer_input)"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Date of Birth"
            :value="getFormattedDateTime(formData.date_of_birth.call_agent_input)"
            styles="mt-5"
            :hide="!formData.date_of_birth.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.nic_eic_number.customer_input"
            label="NIC / EIC"
            :value="formData.nic_eic_number.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="NIC / EIC"
            :value="formData.nic_eic_number.call_agent_input"
            styles="mt-5"
            :hide="!formData.nic_eic_number.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.nic_eic_issue_date.customer_input"
            label="NIC Issue Date"
            :value="getFormattedDateTime(formData.nic_eic_issue_date.customer_input)"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="NIC Issue Date"
            :value="getFormattedDateTime(formData.nic_eic_issue_date.call_agent_input)"
            styles="mt-5"
            :hide="!formData.nic_eic_issue_date.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.passport_number.customer_input != ''"
            label="Passport Number"
            :value="formData.passport_number.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Passport Number"
            :value="formData.passport_number.call_agent_input"
            styles="mt-5"
            :hide="!formData.passport_number.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.passport_number.customer_input != ''"
            label="Passport Issue Date"
            :value="getFormattedDateTime(formData.passport_issue_date.customer_input)"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Passport Issue Date"
            :value="getFormattedDateTime(formData.passport_issue_date.call_agent_input)"
            styles="mt-5"
            :hide="!formData.passport_issue_date.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.passport_number.customer_input != ''"
            label="Passport Expire Date"
            :value="getFormattedDateTime(formData.passport_expire_date.customer_input)"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Passport Expire Date"
            :value="getFormattedDateTime(formData.passport_expire_date.call_agent_input)"
            styles="mt-5"
            :hide="!formData.passport_expire_date.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.gender.customer_input"
            label="Gender"
            :value="formData.gender.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Gender"
            :value="formData.gender.call_agent_input"
            styles="mt-5"
            :hide="!formData.gender.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.maritial_status.customer_input"
            label="Marital Status"
            :value="formData.maritial_status.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Marital Status"
            :value="formData.maritial_status.call_agent_input"
            styles="mt-5"
            :hide="!formData.maritial_status.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
        </div>
        <!-- <div class="mt-6">
          <p>Employment Details</p>
          <InputLabel copyable="true" placeholder=" -- No data -- " 
            label="Employment Status"
            :value="formData.employment_status.customer_input"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Employment Status"
            :value="formData.employment_status.call_agent_input"
            styles="mt-5"
            :hide="!formData.employment_status.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
         

           <div class="column is-half" v-if="formData.employment_status.customer_input == 'Freelance'">
              <InputLabel copyable="true" placeholder=" -- No data -- " 
                  label="Industry"
                  readonly="true"
                  :value="formData.customer_industry.description"
                  class="non-interactable-input"
              />
            </div>

          <AgentInputField copyable="true" 
            label="Profession / Nature of Business"
            :value="formData.profession_nature_of_business.call_agent_input"
            styles="mt-5"
            :hide="(formData.profession_nature_of_business.is_changed_by_call_agent === false) && (formData.profession_nature_of_business.call_agent_input === '')"
            readonly="true"
            class="non-interactable-input"
          />

          <InputLabel copyable="true" placeholder=" -- No data -- " 
            label="Are you working for any other platform's?"
            readonly="true"
            :value="
              formData.are_you_work_on_another_platform.customer_input ? 'Yes' : 'No'
            "
            class="non-interactable-input"
          />
          <AgentInputField copyable="true"
            label="Are you working for any other platform's?"
            :value="
              formData.are_you_work_on_another_platform.call_agent_input ? 'Yes' : 'No'
            "
            styles="mt-5"
            :hide="!formData.are_you_work_on_another_platform.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          <div v-if="formData.are_you_work_on_another_platform.customer_input">
            <InputLabel copyable="true" placeholder=" -- No data -- " 
              label="What other platforms are you working in?"
              readonly="true"
              :value="formData.other_platforms_working_on.customer_input.toString()"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              v-if="formData.other_platforms_working_on.call_agent_input != null"
              label="What other platforms are you working in?"
              :value="formData.other_platforms_working_on.call_agent_input.toString()"
              styles="mt-5"
              :hide="!formData.other_platforms_working_on.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <br />
            <InputLabel copyable="true" placeholder=" -- No data -- " 
              v-if="formData.other_platforms_working_on_other_selected.customer_input"
              label="Please Specify"
              readonly="true"
              :value="formData.other_platforms_working_on_other_description.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Please Specify"
              :value="formData.other_platforms_working_on_other_description.call_agent_input"
              styles="mt-5"
              :hide="!formData.other_platforms_working_on_other_selected.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
          </div>
          
          <InputLabel copyable="true" placeholder=" -- No data -- "   v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Profession / Nature of Business"
            :value="formData.profession_nature_of_business.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Profession / Nature of Business"
            :value="formData.profession_nature_of_business.call_agent_input"
            styles="mt-5"
            :hide="(formData.profession_nature_of_business.is_changed_by_call_agent === false) && (formData.profession_nature_of_business.call_agent_input === '')"
            readonly="true"
            class="non-interactable-input"
          />
           <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Employer /  Business Name"
            :value="formData.employer.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          /> 
          <AgentInputField copyable="true" 
            label="Employer /  Business Name"
            :value="formData.employer.call_agent_input"
            styles="mt-5"
            :hide="!formData.employer.is_changed_by_call_agent && (formData.employer.call_agent_input === '')"
            readonly="true"
            class="non-interactable-input"
          /> 
          <p class="mt-4" style="font-size: 18px" v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' ">
            Employer / Business Address
          </p>
          <InputLabel copyable="true" placeholder=" -- No data -- "   v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Building Number"
            :value="formData.emp_building_number.customer_input"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Building Number"
            :value="formData.emp_building_number.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.emp_building_number.is_changed_by_call_agent && (formData.emp_building_number.call_agent_input === '')
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Building Name"
            :value="formData.emp_building_name.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Building Name"
            :value="formData.emp_building_name.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.emp_building_name.is_changed_by_call_agent && (formData.emp_building_name.call_agent_input === '')
            "
             readonly="true"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Street"
            :value="formData.emp_street.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Street"
            :value="formData.emp_street.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.emp_street.is_changed_by_call_agent && (formData.emp_street.call_agent_input === '')
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Town"
            :value="formData.emp_town.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Town"
            :value="formData.emp_town.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.emp_town.is_changed_by_call_agent && (formData.emp_town.call_agent_input === '')
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Postal Code"
            :value="formData.emp_postal_code.customer_input"
            styles="mt-5"
            readonly="true"
          />
          <AgentInputField copyable="true" 
            label="Postal Code"
            :value="
              formData.emp_postal_code.call_agent_input
            "
            styles="mt-5"
            :hide="
              !formData.emp_postal_code.is_changed_by_call_agent && (formData.emp_postal_code.call_agent_input === '')
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="District"
            :value="formData.emp_district.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="District"
            :value="formData.emp_district.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.emp_district.is_changed_by_call_agent && (formData.emp_district.call_agent_input === '')
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input === 'Full-Time'
                   || formData.employment_status.customer_input === 'Part Time'
                   || formData.employment_status.customer_input === 'Self-Employed'
                   || formData.employment_status.customer_input === 'Probation' "
            label="Country"
            :value="formData.emp_country.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Country"
            :value="formData.emp_country.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.emp_country.is_changed_by_call_agent && (formData.emp_country.call_agent_input ==='')
            "
             readonly="true"
             class="non-interactable-input"
          />
        </div>  -->
         <!-- Employment Details -->
         <p>Employment Status</p>
          <!-- Industry -->
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.customer_industry  !==null"
                  label="Industry"
                  readonly="true"
                  :value="formData.customer_industry.description"
                  class="non-interactable-input"
              />

              <!-- Occupation -->
              <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="GlobalFunctions.checkCustomerOccupationRequired(formData) && formData.customer_occupation  !==null"
                          label="Occupation"
                          readonly="true"
                          :value="formData.customer_occupation"
                          class="non-interactable-input"
              />
              <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employment_status.customer_input"
                label="Employment Status"
                readonly="true"
                :value="formData.employment_status.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Employment Status"
                :value="formData.employment_status.call_agent_input"
                styles="mt-5"
                :hide="!formData.employment_status.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />

            
            <div
            v-if="formData.employment_status.is_changed_by_call_agent ?
              formData.employment_status.call_agent_input === 'Full-Time' ||
              formData.employment_status.call_agent_input === 'Part Time' ||
              formData.employment_status.call_agent_input === 'Self-Employed' ||
              formData.employment_status.call_agent_input === 'Probation' : formData.employment_status.customer_input === 'Full-Time' ||
              formData.employment_status.customer_input === 'Part Time' ||
              formData.employment_status.customer_input === 'Self-Employed' ||
              formData.employment_status.customer_input === 'Probation'
            "
          >
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.profession_nature_of_business.customer_input"
              label="Profession / Nature of Business"
              readonly="true"
              :value="formData.profession_nature_of_business.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Profession / Nature of Business"
              :value="formData.profession_nature_of_business.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.profession_nature_of_business.is_changed_by_call_agent
              "
              readonly="true"
              class="non-interactable-input"
            />
          </div>
          <div
            v-if="formData.employment_status.is_changed_by_call_agent ?
              formData.employment_status.call_agent_input === 'Full-Time' ||
              formData.employment_status.call_agent_input === 'Part Time' ||
              formData.employment_status.call_agent_input === 'Self-Employed' ||
              formData.employment_status.call_agent_input === 'Probation' : formData.employment_status.customer_input === 'Full-Time' ||
              formData.employment_status.customer_input === 'Part Time' ||
              formData.employment_status.customer_input === 'Self-Employed' ||
              formData.employment_status.customer_input === 'Probation'
            "
          >
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.employer.customer_input"
              label="Employer /  Business Name"
              readonly="true"
              :value="formData.employer.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Employer /  Business Name"
              :value="formData.employer.call_agent_input"
              styles="mt-5"
              :hide="!formData.employer.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <div v-if="formData.employment_status.customer_input === 'Full-Time' || formData.employment_status.call_agent_input === 'Full-Time' ||
              formData.employment_status.customer_input === 'Part Time' ||  formData.employment_status.call_agent_input === 'Part Time' || 
              formData.employment_status.customer_input === 'Self-Employed' || formData.employment_status.call_agent_input === 'Self-Employed' || 
              formData.employment_status.customer_input === 'Probation' || formData.employment_status.call_agent_input === 'Probation'"
            >
              <p class="mt-4" style="font-size: 18px" >Employer / Business Address</p>
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="Building Number"
                :value="formData.emp_building_number.customer_input"
                styles="mt-2"
                readonly="true"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true" 
                label="Building Number"
                :value="formData.emp_building_number.call_agent_input"
                styles="mt-5"
                :hide="!formData.emp_building_number.is_changed_by_call_agent && (formData.emp_building_number.call_agent_input === '')"
                 readonly="true"
                 class="non-interactable-input"
              />
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="Building Name"
                :value="formData.emp_building_name.customer_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true" 
                label="Building Name"
                :value="formData.emp_building_name.call_agent_input"
                styles="mt-5"
                :hide="!formData.emp_building_name.is_changed_by_call_agent && (formData.emp_building_name.call_agent_input === '')"
                 readonly="true"
              />
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="Street"
                :value="formData.emp_street.customer_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true" 
                label="Street"
                :value="formData.emp_street.call_agent_input"
                styles="mt-5"
                :hide="!formData.emp_street.is_changed_by_call_agent && (formData.emp_street.call_agent_input === '')"
                 readonly="true"
                 class="non-interactable-input"
              />
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="Town"
                :value="formData.emp_town.customer_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true" 
                label="Town"
                :value="formData.emp_town.call_agent_input"
                styles="mt-5"
                :hide="!formData.emp_town.is_changed_by_call_agent && (formData.emp_town.call_agent_input === '')"
                 readonly="true"
                 class="non-interactable-input"
              />
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="Postal Code"
                :value="formData.emp_postal_code.customer_input"
                styles="mt-5"
                readonly="true"
              />
              <AgentInputField copyable="true" 
                label="Postal Code"
                :value="
                  formData.emp_postal_code.call_agent_input
                "
                styles="mt-5"
                :hide="!formData.emp_postal_code.is_changed_by_call_agent && (formData.emp_postal_code.call_agent_input === '')"
                 readonly="true"
                 class="non-interactable-input"
              />
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="District"
                :value="formData.emp_district.customer_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true" 
                label="District"
                :value="formData.emp_district.call_agent_input"
                styles="mt-5"
                :hide="!formData.emp_district.is_changed_by_call_agent && (formData.emp_district.call_agent_input === '')"
                 readonly="true"
                 class="non-interactable-input"
              />
              <InputLabel copyable="true" 
                placeholder=" -- No data -- "
                label="Country"
                :value="formData.emp_country.customer_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true" 
                label="Country"
                :value="formData.emp_country.call_agent_input"
                styles="mt-5"
                :hide="!formData.emp_country.is_changed_by_call_agent && (formData.emp_country.call_agent_input ==='')"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div v-if="formData.employment_status.is_changed_by_call_agent ? formData.employment_status.call_agent_input === 'Freelance' : formData.employment_status.customer_input === 'Freelance'">
            

              <InputLabel copyable="true" placeholder=" -- No data -- " 
              label="Are you working for any other platform's?"
              readonly="true"
              styles="mt-5"
              :value="
                formData.are_you_work_on_another_platform.customer_input ? 'Yes' : 'No'
              "
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Are you working for any other platform's?"
              :value="
                formData.are_you_work_on_another_platform.call_agent_input ? 'Yes' : 'No'
              "
              styles="mt-5"
              :hide="!formData.are_you_work_on_another_platform.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <div v-if="formData.are_you_work_on_another_platform.customer_input || formData.are_you_work_on_another_platform.call_agent_input ">
              <!-- <InputLabel copyable="true" placeholder=" -- No data -- " 
                label="What other platforms are you working in?"
                readonly="true"
                :value="formData.other_platforms_working_on.customer_input"
                styles="mt-4"
              />
              <AgentInputField copyable="true"
                v-if="formData.other_platforms_working_on.is_changed_by_call_agent"
                label="What other platforms are you working in?"
                :value="formData.other_platforms_working_on.call_agent_input"
                styles="mt-5"
                
                readonly="true"
              /> -->
              <!-- other_platforms_working - customer inputs -->
              <DisplayBulletList class="non-interactable-input" label="What other platforms are you working in?" :items="formData.other_platforms_working_on.customer_input" :is-agent="false"/>

              <!-- other_platforms_working - agent inputs -->
             <DisplayBulletList class="non-interactable-input" v-if="formData.other_platforms_working_on.call_agent_input != null" label="What other platforms are you working in?" :items="formData.other_platforms_working_on.call_agent_input" :is-agent="true"/>
              
              <br />
              <InputLabel copyable="true" placeholder=" -- No data -- " 
                label="Please Specify"
                v-if="formData.other_platforms_working_on_other_selected.customer_input"
                readonly="true"
                :value="formData.other_platforms_working_on_other_description.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Please Specify"
                v-if="formData.other_platforms_working_on_other_selected.is_changed_by_call_agent"
                :value="formData.other_platforms_working_on_other_description.call_agent_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          </div>
          <div v-if="formData.employment_status.is_changed_by_call_agent ? formData.employment_status.call_agent_input === 'Freelance' : formData.employment_status.customer_input === 'Freelance'">
            

            <InputLabel copyable="true" placeholder=" -- No data -- " 
              label="Are you working for any other platform's?"
              readonly="true"
              styles="mt-5"
              :value="
                formData.are_you_work_on_another_platform.customer_input ? 'Yes' : 'No'
              "
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Are you working for any other platform's?"
              :value="
                formData.are_you_work_on_another_platform.call_agent_input ? 'Yes' : 'No'
              "
              styles="mt-5"
              :hide="!formData.are_you_work_on_another_platform.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <div v-if="formData.are_you_work_on_another_platform.customer_input || formData.are_you_work_on_another_platform.call_agent_input ">
              <!-- <InputLabel copyable="true" placeholder=" -- No data -- " 
                label="What other platforms are you working in?"
                readonly="true"
                :value="formData.other_platforms_working_on.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                v-if="formData.other_platforms_working_on.is_changed_by_call_agent"
                label="What other platforms are you working in?"
                :value="formData.other_platforms_working_on.call_agent_input"
                styles="mt-5"
                class="non-interactable-input"
                readonly="true"
              /> -->
              <!-- other_platforms_working - customer inputs -->
              <DisplayBulletList class="non-interactable-input" label="What other platforms are you working in?" :items="formData.other_platforms_working_on.customer_input" :is-agent="false"/>

              <!-- other_platforms_working - agent inputs -->
             <DisplayBulletList class="non-interactable-input" v-if="formData.other_platforms_working_on.call_agent_input != null" label="What other platforms are you working in?" :items="formData.other_platforms_working_on.call_agent_input" :is-agent="true"/>
              
              <br />
              <InputLabel copyable="true" placeholder=" -- No data -- " 
                label="Please Specify"
                v-if="formData.other_platforms_working_on_other_selected.customer_input"
                readonly="true"
                :value="formData.other_platforms_working_on_other_description.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Please Specify"
                v-if="formData.other_platforms_working_on_other_selected.is_changed_by_call_agent"
                :value="formData.other_platforms_working_on_other_description.call_agent_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
        <!-- show selfie liveness video start -->
        <br />
        <div class="container"  >
          <div style="text-align: center">
            <label>Selfie Liveness</label>
          </div>
          <div v-if="formData.selfie_liveness !== null">
            <b-message :type="formData.selfie_liveness.result == 'real' ? 'is-success' : 'is-danger'">
              Selfie Liveness : {{ GlobalFunctions.getRoundedValue(formData.selfie_liveness.score * 100) }} %
            </b-message>
          </div>
          <br>
          <div
              class="card"
              v-if="formData.face_liveness_video_recording != null"
          >
            <video
                ref="videoPlayer"
                controls
                preload="auto"
                disablePictureInPicture
                :src="formData.face_liveness_video_recording"
                style="
                    width: 100%;
                    border-radius: 25px;
                    border: 2px solid #ff6666;
                  "
                v-once
                onloadmetadata="this.parentNode.querySelector('.videoDuration').textContent = this.duration.toFixed(2)"
            ></video>
            <br />
            <!-- <b-button
              v-if="!reviewOnly"
              class="is-primary"
              style="width: 100%"
              @click="downloadVideo"
            >
              Download
            </b-button> -->
          </div>
        </div>
      </div>
      <div class="column">
        <div>

          <p>Permanent Address</p>
          <!-- <p style="font-family: Gotham-Light; font-size: 1.2rem"
            v-if="formData.is_correspondence_address_differs_with_the_permanent_address === false">
            Permanent and correspondence addresses are one and the same.
          </p> -->
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.building_number.customer_input"
            label="Building Number"
            :value="formData.building_number.customer_input"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true"
            label="Building Number"
            :value="formData.building_number.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.building_number.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.building_name.customer_input"
            label="Building Name"
            :value="formData.building_name.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Building Name"
            :value="formData.building_name.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.building_name.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.street.customer_input"
            label="Street"
            :value="formData.street.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Street"
            :value="formData.street.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.street.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.street_2.customer_input"
            label="Street 2"
            :value="formData.street_2.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Street 2"
            :value="formData.street_2.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.street_2.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.town.customer_input"
            label="Town"
            :value="formData.town.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Town"
            :value="formData.town.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.town.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.postal_code.customer_input"
            label="Postal Code"
            :value="formData.postal_code.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Postal Code"
            :value="formData.postal_code.call_agent_input "
            styles="mt-5"
            :hide="
              !formData.postal_code.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.district.customer_input"
            label="District"
            :value="formData.district.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="District"
            :value="formData.district.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.district.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.country.customer_input"
            label="Country"
            :value="formData.country.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Country"
            :value="formData.country.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.country.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.state_of_residence.customer_input"
            label="Status of Permanant Residence"
            :value="formData.state_of_residence.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Country"
            :value="formData.state_of_residence.call_agent_input"
            styles="mt-5"
            :hide="
              !formData.state_of_residence.is_changed_by_call_agent
            "
             readonly="true"
             class="non-interactable-input"
          />
        </div> 
        <!-- <div v-if="formData.is_correspondence_address_differs_with_the_permanent_address"> -->
        <div>

            <p>Correspondence Address</p>
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_building_number.customer_input"
              label="Building Number"
              :value="formData.correspondence_address_building_number.customer_input"
              styles="mt-2"
              readonly="true"
              class="non-interactable-input"
            />

            <AgentInputField copyable="true"
              label="Building Number"
              :value="formData.correspondence_address_building_number.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_building_number.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_building_name.customer_input"
              label="Building Name"
              :value="formData.correspondence_address_building_name.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Building Name"
              :value="formData.correspondence_address_building_name.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_building_name.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_street.customer_input"
              label="Street"
              :value="formData.correspondence_address_street.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Street"
              :value="formData.correspondence_address_street.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_street.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_street_2.customer_input"
              label="Street 2"
              :value="formData.correspondence_address_street_2.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Street 2"
              :value="formData.correspondence_address_street_2.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_street_2.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_town.customer_input"
              label="Town"
              :value="formData.correspondence_address_town.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Town"
              :value="formData.correspondence_address_town.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_town.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_postal_code.customer_input"
              label="Postal Code"
              :value="formData.correspondence_address_postal_code.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Postal Code"
              :value="formData.correspondence_address_postal_code.call_agent_input "
              styles="mt-5"
              :hide="
                !formData.correspondence_address_postal_code.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_district.customer_input"
              label="District"
              :value="formData.correspondence_address_district.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="District"
              :value="formData.correspondence_address_district.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_district.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.correspondence_address_country.customer_input"
              label="Country"
              :value="formData.correspondence_address_country.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Country"
              :value="formData.correspondence_address_country.call_agent_input"
              styles="mt-5"
              :hide="
                !formData.correspondence_address_country.is_changed_by_call_agent
              "
               readonly="true"
               class="non-interactable-input"
            />
            </div> 
         <div class="mt-6">
          <p>Contact</p>
          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.mobile_number.customer_input"
            label="Primary Mobile Number"
            :value="formData.mobile_number.customer_input"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Primary Mobile Number"
            :value="formData.mobile_number.call_agent_input"
            styles="mt-5"
            :hide="!formData.mobile_number.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />

          <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.telephone_number.customer_input"
              label="Mobile/Telephone Number"
              :value="formData.telephone_number.customer_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true" 
              label="Mobile/Telephone Number"
              :value="formData.telephone_number.call_agent_input"
              styles="mt-5"
              :hide="!formData.telephone_number.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.email.customer_input"
            label="Primary Email ID"
            :value="formData.email.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Primary Email ID"
            :value="formData.email.call_agent_input"
            styles="mt-5"
            :hide="!formData.email.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />

         <!-- additional email address -->
         <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.secondary_email.customer_input"
             label="Additional Email Address"
             readonly="true"
             :value="formData.secondary_email.customer_input"
             styles="mt-5"
             class="non-interactable-input"
         />
         <AgentInputField copyable="true"
             label="Additional Email Address"
             :value="formData.secondary_email.call_agent_input"
             styles="mt-5"
             :hide="!formData.secondary_email.is_changed_by_call_agent"
             readonly="true"
             class="non-interactable-input"

         />

         <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="formData.mothers_maiden_name.customer_input"
            label="Mother’s Maiden Name"
            :value="formData.mothers_maiden_name.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Mother’s Maiden Name"
            :value="formData.mothers_maiden_name.call_agent_input"
            styles="mt-5"
            :hide="!formData.mothers_maiden_name.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
        </div> 
        <div class="mt-6">
          <p>Tax Details</p>
          <InputLabel copyable="true" placeholder=" -- No data -- " 
            label="Are You a Taxpayer?"
            :value="formData.are_you_a_tax_payer.customer_input ? 'Yes' : 'No'"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
          />
         
          <AgentInputField copyable="true" 
            label="Are You a Taxpayer?"
            :value ="formData.are_you_a_tax_payer.call_agent_input? 'Yes' : 'No'"
            styles="mt-5"
            :hide="!formData.are_you_a_tax_payer.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />
          


          <InputLabel copyable="true" placeholder=" -- No data -- "
            label="Tax File Number"
            :value="formData.tax_file_numer.customer_input"
            styles="mt-5"
            readonly="true"
            class="non-interactable-input"
          />
          <AgentInputField copyable="true" 
            label="Tax File Number"
            :value="formData.tax_file_numer.call_agent_input"
            styles="mt-5"
            :hide="!formData.tax_file_numer.is_changed_by_call_agent && !formData.are_you_a_tax_payer.call_agent_input" 
            readonly="true"
            class="non-interactable-input"
          />
        </div> 
        <div class="mt-6">

          <p>PEP Declaration</p>

        <!--New way of displaying pep details - start -->
          <InputLabel copyable="true" placeholder=" -- No data -- " 
            label="Are You or Your Close Relative a Politically Exposed Person?"
            readonly="true"
            :value="GlobalFunctions.getPepSelectionDisplayValue(formData.pep_declaration)"
            styles="mt-4"
            class="non-interactable-input"
          />

          <AgentInputField copyable="true"
              v-if="GlobalFunctions.showAgentChangedPepSelection(formData.pep_declaration)"
              label="Are You or Your Close Relative a Politically Exposed Person?"
              :value="GlobalFunctions.getPepSelectionDisplayValue(formData.pep_declaration,true)"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
          />
          <!--          New way of displaying pep details - end -->


          <div v-if="!formData.pep_declaration.customer_input.not_politically_exposed " style="margin-top: 10px">
            <div class="columns is-7 is-variable" v-for="(pep_detail,index) in formData.pep_declaration.customer_input.pep_person_details" :key="index">
                <div class="column">
                  <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="pep_detail.name !== ''"
                    label="Name"
                    readonly="true"
                    :value="pep_detail.name"
                  />
                </div>
                <div class="column">
                  <InputLabel copyable="true" placeholder=" -- No data -- "  v-if="pep_detail.designation !== ''"
                    label="Designation"
                    readonly="true"
                    :value="pep_detail.designation"
                    class="non-interactable-input"
                  />
                </div>
            </div>
          </div>

        
          <div v-if="formData.pep_declaration.is_changed_by_call_agent">
            <div class="columns is-7 is-variable" v-for="(pep_detail,index) in formData.pep_declaration.call_agent_input.pep_person_details" :key="index">
                <div class="column">
                  <AgentInputField copyable="true"
                      v-if="GlobalFunctions.showAgentChangedPepDetail(formData.pep_declaration,index, 'name')"
                      label="Name"
                      :value="pep_detail.name"
                      styles="mt-5"
                      :hide="!formData.pep_declaration.is_changed_by_call_agent"
                      readonly="true"
                  />
                </div>
                <div class="column">
                  <AgentInputField copyable="true"
                      v-if="GlobalFunctions.showAgentChangedPepDetail(formData.pep_declaration,index, 'designation')"
                      label="Designation"
                      :value="pep_detail.designation"
                      styles="mt-5"
                      :hide="!formData.pep_declaration.is_changed_by_call_agent"
                      readonly="true"
                  />
                 
                </div>
            </div>
          </div>
          
        </div> 
        <div class="mt-6">
          <p>Financial Details</p>
          <!-- source of funds - customer inputs -->
          <DisplayBulletList class="non-interactable-input" label="Source of Funds" :items="formData.source_of_funds.customer_input" :is-agent="false"/>

          <!-- source of funds - agent inputs -->
          <DisplayBulletList class="non-interactable-input" v-if="formData.source_of_funds.call_agent_input != null" label="Source of Funds" :items="formData.source_of_funds.call_agent_input" :is-agent="true"/>


          <InputLabel copyable="true" placeholder=" -- No data -- "   v-if="formData.source_of_funds_other_description.customer_input"
          label="Please Specify"
          readonly="true"
          :value="formData.source_of_funds_other_description.customer_input"
          styles="mt-4"
          class="non-interactable-input"
          />

          <AgentInputField copyable="true" 
            label="Source of Funds"
            :value="formData.source_of_funds_other_description.call_agent_input"
            styles="mt-5"
            :hide="!formData.source_of_funds_other_description.is_changed_by_call_agent"
            readonly="true"
            class="non-interactable-input"
          />

        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import InputLabel from "../../../components/InputLabel.vue";
import AgentInputField from "../../../components/AgentInputField.vue";
import NetworkManager from "@/network";
import {useAuthStore} from "../../../store/authStore"; 
import {DateTimeManger} from "../../../helpers/dateTimeManger";
import DisplayBulletList from "../../../components/DisplayBulletList.vue";
import {GlobalFunctions} from "../../../helpers/globalFunctions";
import FullNameView from "../../../components/FullNameView.vue";

export default {
  name: "GeneralDetails",
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    }
  },
  components: {
    DisplayBulletList,
    InputLabel,
    AgentInputField,
    FullNameView,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return {
      isCardModalActive :false,
      formData : [],
      getAllInternalComments : null,
      getCommitKycDetails : null,
      drpArray:'',
    };
  },
  methods: {
    getFormattedDateTime(date){
      return DateTimeManger.formatCustomerInputDates(date)
    },
    GetApprovedApplicationData: function (){
      const comp= this
      const data = {

         id: comp.authStore.kyc_form_id
       

      }
      console.log("in GetApprovedApplicationData")

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        console.log("in GetApprovedApplicationData",e.data)
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication 
          comp.getAllInternalComments = e.data.getAllInternalComments;  
          comp.getCommitKycDetails = e.data.getCommitKycDetails     
          comp.drpArray = e.data.drpStatusList; 
        }
      })
    },
  },
  mounted() {
    this.GetApprovedApplicationData();
  },
};
</script>

<style>
p {
  font-family: "Gotham-Medium";
  color: #231f20;
  font-size: 24px;
}
.non-interactable-input {
  pointer-events: none;
}
</style>
