<template>
    <div>
      <div class="container" id="attachment-container"
        rounded style="width: 60.5vw;
        background-color: white;
        border: 2px solid #000000;
        border-radius: 25px;
        padding: 20px;
        z-index: 2">
        <ValidationObserver v-slot="{invalid}">
          <div>
            <div class="columns is-7 is-variable">
              <b-tabs position="is-right" class="block" v-model="activeTab" style="margin-left: 15vw; text-align: center;">
                <b-tab-item v-bind="attachmentTab">
                   <div class="column is-three-fourth" >
                    <label style="color: #04c3fc; font-size: 26px;"> Applciation Attachments</label>
                    <label style="color: #04c3fc;font-size: 24px; font-weight: 100;"> ( Maximum 05 )</label>
                  </div> 
                </b-tab-item>
                <b-tab-item v-bind="holdTab"  style="margin-left: 3vw;">
                  <div class="column is-three-fourth">
                    <label style="color: #04c3fc; font-size: 26px;"> Hold Attachments</label>
                    <label style="color: #04c3fc;font-size: 24px; font-weight: 100;"> ( Maximum 05 )</label>
                  </div> 
                </b-tab-item>
              </b-tabs>
              <div class="column is-three-fifth" style="margin-left: 54vw; position: absolute;">
                <b-button @click="emitCloss" type="is-primary">x</b-button>
              </div>
            </div> 
          </div>
          <hr style="width: 90%; margin-left: auto; margin-right: auto;">
          <div>
            <b-tabs v-model="activeTab"
              size="is-small"
              type="is-toggle-rounded"
              position="is-centered"
            >
              <b-tab-item label="Application Attachments" v-bind="attachmentTab">
                <div class="columns">
                  <div class="image-box-wrapper">
                    <div class="column">
                      <div
                        class="container"
                        style="
                          width: 24vw;
                          height: 17.5vw;
                          border: 2px dashed #696767;
                          border-radius: 20px;
                          padding: 5px;
                          margin-left: 10px;
                        "
                      >
                        <div style="text-align: center; margin-top: 1vh">
                          <b-notification
                            :type=attachmentNotificationType
                            v-model="attachmentNotificationBox"
                            auto-close 
                            :progress-bar='progressBar' 
                            :duration="duration" 
                            has-icon 
                            style="z-index: 99; position: absolute; margin-top: 9vh; width:23vw;"
                            aria-close-label="Close notification">
                            {{ attachmentMessage }}
                          </b-notification>
                          <b-upload
                              ref="applicationupload"
                              v-model="attachmentFile"
                              @input="onApplicationAttachmentBrowse()"
                              rounded
                              accept=".pdf, image/*"
                            >
                              <div>
                                <img
                                  :src="(applicationAttachmentType !== 'pdf' ? applicationAttachmentImage : pdfPlaceholder)"
                                  @click="handleAttchmentClick()"
                                  @error="imageplaceholder"
                                  style="width: 23vw; height: 14.5vw; "
                                  resposive
                                  alt="Attachment"
                                />
                                <br>
                                <div v-if="applicationAttachmentFile === ''" style="text-align: center;">
                                  <label style="color: #ED1C24; font-size: 12px;">Note: </label>
                                  <label style="color: #696767; font-size: 12px;">Maximum file size should be 5MB</label>
                                </div>
                              </div>
                            </b-upload>
                          <br>
                        </div>
                      </div>
                      <br>
                      <div class="container" style="margin-left: 1vw;">
                        <label>Add Comment/s for internal use</label>
                        <b-input style="width: 22.5vw" 
                          placeholder="Internal Comment"
                          v-model="applicationAttachmentComment"
                          type="textarea" :rows=3
                          maxlength="200"></b-input>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-button style="width:10vw; margin-left: 1vw" @click="clearApplicationAttachment()" type="is-primary" outlined>Clear</b-button> 
                        </div>
                        <div class="column">
                          <b-button :loading="isUploading" :disabled="(authId.role === 'Super Admin' || authId.role === 'Admin') || ((currentAttachmentUploadCount == 5)? true :false) || isEmpty" type="is-primary" style="width:10vw" @click="submitApplicationAttachment()">Upload</b-button>
                        </div>
                      </div>
                    </div>
                    <button v-if="currentAttachmentUploadCount == 5" class="disable-button">Max Upload Attachment Count Reached</button>
                    <button v-if="(authId.role === 'Super Admin' || authId.role === 'Admin')" class="disable-button">You are not allowed to add Attachments</button>
                  </div>
                  <div class="column">
                    <div style=" margin-top: 20px; height: 25.5vw; overflow-y:scroll !important;">
                      <label v-if="applicationAttachments.length !== 0">Application Attachments</label>
                      <div
                        v-for="(item, index) in applicationAttachments"
                        :key="item.path"
                      >
                        <div>
                          <div class="card"
                            rounded
                            style="
                            border-radius: 25px;
                            padding: 18px;
                            height:18vh;
                            width: 29vw;
                          ">
                            <div class="columns is-gappless" style="margin-top: 0.75vh;">
                              <div class="container"
                               style="
                                  border: 2px solid #696767;
                                  border-radius: 20px;
                                  padding: 5px;
                                  margin-left: 5px;
                                  width: 8vw;
                                ">
                                <img 
                                  :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                                  style=" margin-top: 5px;"
                                  resposive
                                  alt="Attachment"
                                />
                              </div>
                              <div style="margin-left: 1vh; width: 19vw;">
                                <label>{{ item.attachment_label }}</label>
                                <b-input
                                    class="rounded-input"
                                    rounded
                                    readonly
                                    type="textarea"
                                    v-model="item.comment"
                                    placeholder="Add Comment/s"
                                    rows="3"
                                ></b-input>
                              </div>
                              <div>
                                <b-icon  style="margin-top: 5vh; cursor: pointer;"
                                  type="is-primary"
                                  icon="tray-arrow-down"
                                  size="is-medium"
                                  @click.native="downloadfile(item.url, 'Application Attachment')">
                                </b-icon>
                              </div>
                            </div>
                          </div>
                          <br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Hold Attachments"  v-bind="holdTab"
                v-if="(((currentStatus === 'HOLD') && hasPermission('view-new-application-pool') || (currentStatus === 'HOLD') && hasPermission('assign-scheduled-application')) || (currentDRHoldUploadCount !== 0 | currentCAHoldUploadCount !== 0))">
                <div class="columns">
                  <div class="image-box-wrapper">
                    <div class="column">
                      <div
                        class="container"
                        style="
                          width: 24vw;
                          height: 17.5vw;
                          border: 2px dashed #696767;
                          border-radius: 20px;
                          padding: 5px;
                          margin-left: 10px;
                        "
                      >
                        <div style="text-align: center; margin-top: 1vh">
                          <b-notification
                            :type=holdNotificationType
                            v-model="holdNotificationBox"
                            auto-close 
                            :progress-bar='progressBar' 
                            :duration="duration" 
                            title="Success!" 
                            has-icon 
                            style="z-index: 99; position: absolute; margin-top: 9vh; width:23vw;"
                            aria-close-label="Close notification">
                            {{ holdMessage }}
                          </b-notification>
                          <b-upload
                            ref="holdupload"
                            v-model="holdFile"
                            @input="onHoldAttachmentBrowse()"
                            rounded
                            accept=".pdf, image/*"
                          > 
                            <div>
                              <img
                                :src="(holdAttachmentType !== 'pdf' ? holdAttachmentImage : pdfPlaceholder)"
                                @click="handleHoldClick()"
                                @error="imageplaceholder"
                                style="width: 23vw; height: 14.5vw; "
                                resposive
                                alt="Attachment"
                              />
                              <br>
                              <div v-if="holdAttachmentFile === ''" style="text-align: center;">
                                <label style="color: #ED1C24; font-size: 12px;">Note: </label>
                                <label style="color: #696767; font-size: 12px;">Maximum file size should be 5MB</label>
                              </div>
                            </div>
                          </b-upload>
                          <br>
                        </div>
                      </div>
                      <br>
                      <div class="container" style="margin-left: 1vw;">
                        <label>Add Comment/s for internal use</label>
                        <b-input style="width: 22.5vw"
                          placeholder="Internal Comment"
                          v-model="holdAttachmentComment"
                          type="textarea" :rows=3
                          maxlength="200"></b-input>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-button style="width:10vw; margin-left: 1vw" @click="clearHoldAttachment()" type="is-primary" outlined>Clear</b-button> 
                        </div>
                        <div class="column">
                          <b-button :loading="isUploading" type="is-primary" style="width:10vw" @click="submitHoldAttachment()"
                          :disabled="(isHoldEmpty || currentStatus !== 'HOLD') || (((authId.role === 'Detail Reviewer') && (currentDRHoldUploadCount === 5)) || ((authId.role === 'Call Agent') &&(currentCAHoldUploadCount === 5))) "
                          >Upload</b-button>
                        </div>
                      </div>
                    </div>
                    <div v-if="(((authId.role !== 'Detail Reviewer') || (authId.role !== 'Call Agentr')) && currentStatus !== 'HOLD')">
                      <button class="disable-button">Application is not in Hold Status or You are not allowed to add Hold Attachments</button>
                    </div>
                    <div v-else>
                      <button v-if="((authId.role === 'Detail Reviewer' ) &&(currentDRHoldUploadCount === 5) || (authId.role === 'Call Agent') &&(currentCAHoldUploadCount === 5))" class="disable-button">Max Upload Attachment Count Reached</button>
                    </div>
                  </div>
                  <div class="column">
                    <div style=" margin-top: 20px; height: 25.5vw; overflow-y:scroll !important;">
                      <label v-if="detailReviewerAttachments.length !== 0">Hold Attachments by Detail Reviewer</label>
                      <div
                        v-for="(item, index) in detailReviewerAttachments"
                        :key="item.path"
                      >
                        <div>
                          <div class="card"
                            rounded
                            style="
                            border-radius: 25px;
                            padding: 18px;
                            height:18vh;
                            width: 29vw;
                          ">
                            <div class="columns is-gappless" style="margin-top: 0.75vh;">
                              <div class="container"
                               style="
                                  border: 2px solid #696767;
                                  border-radius: 20px;
                                  padding: 5px;
                                  margin-left: 5px;
                                  width: 8vw;
                                ">
                                <img 
                                  :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                                  style=" margin-top: 5px;"
                                  resposive
                                  alt="Attachment"
                                />
                              </div>
                              <div style="margin-left: 1vh; width: 19vw;">
                                <label>{{ item.attachment_label }}</label>
                                <b-input
                                    class="rounded-input"
                                    rounded
                                    readonly
                                    type="textarea"
                                    v-model="item.comment"
                                    placeholder="Add Comment/s"
                                    rows="3"
                                ></b-input>
                              </div>
                              <div>
                                <b-icon  style="margin-top: 5vh; cursor: pointer;"
                                  type="is-primary"
                                  icon="tray-arrow-down"
                                  size="is-medium"
                                  @click.native="downloadfile(item.url, 'Application Attachment')">
                                </b-icon>
                              </div>
                            </div>
                          </div>
                          <br>
                        </div>
                      </div>
                      <label v-if="callAgentAttachments.length !== 0">Hold Attachments by Call Agent</label>
                      <div
                        v-for="(item, index) in callAgentAttachments"
                        :key="item.path"
                      >
                        <div>
                          <div class="card"
                            rounded
                            style="
                            border-radius: 25px;
                            padding: 18px;
                            height:18vh;
                            width: 29vw;
                          ">
                            <div class="columns is-gappless" style="margin-top: 0.75vh;">
                              <div class="container"
                               style="
                                  border: 2px solid #696767;
                                  border-radius: 20px;
                                  padding: 5px;
                                  margin-left: 5px;
                                  width: 8vw;
                                ">
                                <img 
                                  :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                                  style=" margin-top: 5px;"
                                  resposive
                                  alt="Attachment"
                                />
                              </div>
                              <div style="margin-left: 1vh; width: 19vw;">
                                <label>{{ item.attachment_label }}</label>
                                <b-input
                                    class="rounded-input"
                                    rounded
                                    readonly
                                    type="textarea"
                                    v-model="item.comment"
                                    placeholder="Add Comment/s"
                                    rows="3"
                                ></b-input>
                              </div>
                              <div>
                                <b-icon  style="margin-top: 5vh; cursor: pointer;"
                                  type="is-primary"
                                  icon="tray-arrow-down"
                                  size="is-medium"
                                  @click.native="downloadfile(item.url, 'Application Attachment')">
                                </b-icon>
                              </div>
                            </div>
                          </div>
                          <br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </ValidationObserver>
      </div>
      <b-modal
        v-model="holdCropModalIsVisible"
        has-modal-card
        full-screen
        :can-cancel="false"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body" style="padding: 0">
            <div class="columns">
              <div class="column"></div>
              <div class="column is-three-fifths">
                <div class="web-camera-container">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="holdCropper"
                      :src="holdImageToCrop"
                      :guides="true"
                      :view-mode="3"
                      preview=".preview"
                    />
                  </div>
                </div>
              </div>
              <div class="column"></div>
            </div>
          </section>

          <footer class="modal-card-foot">
            <div id="video-call-div1"></div>
            <b-button
              label="Done"
              type="is-primary"
              @click="cropHoldImage"
            ></b-button>
            <b-button
              label="Cancel"
              type="is-info"
              class="is-rounded"
              @click="clearHoldSelect"
            ></b-button>
          </footer>
        </div>
      </b-modal>

      <b-modal
        v-model="attachmentCropModalIsVisible"
        has-modal-card
        full-screen
        :can-cancel="false"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body" style="padding: 0">
            <div class="columns">
              <div class="column"></div>
              <div class="column is-three-fifths">
                <div class="web-camera-container">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="attachmentCropper"
                      :src="attachmentImageToCrop"
                      :guides="true"
                      :view-mode="3"
                      preview=".preview"
                    />
                  </div>
                </div>
              </div>
              <div class="column"></div>
            </div>
          </section>

          <footer class="modal-card-foot">
            <div id="video-call-div1"></div>
            <b-button
              label="Done"
              type="is-primary"
              @click="cropAttachmentImage"
            ></b-button>
            <b-button
              label="Cancel"
              type="is-info"
              class="is-rounded"
              @click="clearAttachmentSelect"
            ></b-button>
          </footer>
        </div>
      </b-modal>
    </div>
</template>

<script>
import veerValidate from "vee-validate";
import VueCropper from "vue-cropperjs";
import NetworkManager from "../network";
import {BuefyHelper} from "@/helpers/bufeyHelper";
export default {
  props: {
    applicationId: null,
    authId: "",
    currentStatus :null,
  },

  data () {
    return {
      attachmentTab:false,
      holdTab:false,
      activeTab:0,
      detailReviewerAttachments: "",
      callAgentAttachments :"",
      applicationAttachments : "",
      isUploading:false,
      isHoldEmpty:true,
      isEmpty:true,
      attachmentNotificationType: "",
      holdNotificationType:"",
      attachmentMessage:"",
      holdMessage:"",
      attachmentNotificationBox: false,
      holdNotificationBox: false,
      duration: 4000,
      progressBar: true,

      showAttachmentModel: false,
      cropAttachmentModalIsVisible: false,
      cards: [{ id: 1, image: "", description: "" }],
      count: 1,

      maxHoldUploadCount: 0,
      currentDRHoldUploadCount : 0 ,
      currentCAHoldUploadCount : 0 ,
      maxAttachmentUploadCount : 0,
      currentAttachmentUploadCount : 0,
      defaultimg: require("@/assets/Images/Icons/image_placeholder.webp"),
      pdfPlaceholder : require("@/assets/Images/pdf.png"),

      holdImageToCrop: null,
      attachmentImageToCrop: null,
      holdAttachmentImage : "",
      holdAttachmentFile : "",
      holdAttachmentType : "",
      holdAttachmentComment : "",

      applicationAttachmentImage : "",
      applicationAttachmentFile : "",
      applicationAttachmentType : "",
      applicationAttachmentComment : "",

      holdCropModalIsVisible: false,
      isHoldActive: false,
      holdFile: null,
      holdImageUrl: "",
      holdCroppedImg: null,

      attachmentCropModalIsVisible: false,
      isAttachmentActive: false,
      attachmentFile: null,
      attachmentImageUrl: "",
      attachmentCroppedImg: null,

      tabName:"Application Attachments",
      }
  },
  components: {
      VueCropper,
  },
  computed: {
  },
  methods: {
    downloadfile(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    emitCloss()
    {
        this.$emit('Closs_Att',this.showAttachmentModel)
    },

    onHoldAttachmentBrowse: function () {
      console.log("on onHoldAttachmentBrowse -------------------");
      const comp = this;
      if (comp.holdFile !== null && comp.holdFile["type"].split("/")[0] === "image") {
        console.log("uploaded image size (bytes): " + comp.holdFile.size);
        // validate image size
        if (comp.holdFile.size <= 5245329) {
          comp.holdImageToCrop = URL.createObjectURL(comp.holdFile);
          comp.isHoldActive = false;
          comp.initiateHoldImageCrop();
        } 
        if (comp.holdFile.size > 5245329) {
          comp.holdFile = [];
          this.holdNotificationType = "is-danger"
          this.holdMessage = "File size should be less than 5MB";
          this.holdNotificationBox = true;
        }
      } 
      else if (comp.holdFile != null && comp.holdFile["type"] === "application/pdf") {
        if (comp.holdFile.size > 5245329) {
          comp.holdFile = [];
          this.holdNotificationType = "is-danger"
          this.holdMessage = "File size should be less than 5MB";
          this.holdNotificationBox = true;
        }
        else{
          console.log("uploaded pdf size (bytes): " + comp.holdFile.size);
          comp.holdAttachmentImage = this.holdCroppedImg;
          comp.holdAttachmentType = "pdf";  
          this.isHoldEmpty = false;
          comp.holdAttachmentFile = comp.holdFile;
        }
      }
      else {
        if(comp.holdFile != null)
        {
          console.log("holdFile type", comp.holdFile["type"]);
          BuefyHelper.showToastMessage("Select an image or pdf file", "is-danger");
        }
      }
    },

    onApplicationAttachmentBrowse: function () {
      console.log("on onApplication Attachment Browse -------------------");
      const comp = this;
      if (comp.attachmentFile !== null && comp.attachmentFile["type"].split("/")[0] === "image") {
        console.log("uploaded image size (bytes): " + comp.attachmentFile.size);
        // validate image size
        if (comp.attachmentFile.size <= 5245329) {
          comp.attachmentImageToCrop = URL.createObjectURL(comp.attachmentFile);
          comp.isAttachmentActive = false;
          comp.initiateAttachmentImageCrop();
        } 
        if (comp.attachmentFile.size > 5245329) {
          comp.attachmentFile = [];
          this.attachmentNotificationType= "is-danger";
          this.attachmentMessage = "File size should be less than 5MB";
          this.attachmentNotificationBox = true
        }
      } 
      else if (comp.attachmentFile != null && comp.attachmentFile["type"] === "application/pdf") {
        if (comp.attachmentFile.size > 5245329) {
          comp.attachmentFile = [];
          this.attachmentNotificationType= "is-danger";
          this.attachmentMessage = "File size should be less than 5MB";
          this.attachmentNotificationBox = true
        }
        else{
          console.log("uploaded pdf size (bytes): " + comp.attachmentFile.size);
          comp.applicationAttachmentImage = this.attachmentCroppedImg;
          comp.applicationAttachmentType = "pdf";

          const reader = new FileReader();
          reader.readAsDataURL(comp.attachmentFile);
          console.log("file",comp.attachmentFile["type"].split('/')[1])
          reader.onload = () => {
            console.log("inside reader")
            const dataUrl = reader.result;
            const base64String = dataUrl.split(',')[1];
            console.log("row form of base64image",base64String);
            this.applicationAttachmentFile = base64String;
            this.isEmpty = false;
          }
          console.log("applicationAttachmentFile",this.applicationAttachmentFile);
          console.log("applicationAttachmentType",comp.applicationAttachmentType);
        }
      }
      else {
        if(comp.attachmentFile != null)
        {
          console.log("attachmentFile type", comp.attachmentFile["type"]);
          BuefyHelper.showToastMessage("Select an image or pdf file", "is-danger");
        }
      }
    },

    clearApplicationAttachment:function(){
      const comp = this;
      comp.applicationAttachmentImage = "";
      comp.applicationAttachmentFile = "";
      comp.applicationAttachmentComment = "";
      comp.applicationAttachmentType = "";
      comp.clearAttachmentSelect();
      comp.isEmpty = true;
    },
    clearHoldAttachment:function(){
      const comp = this;
      comp.holdAttachmentImage = "";
      comp.holdAttachmentFile = "";
      comp.holdAttachmentComment = "";
      comp.holdAttachmentType = "";
      comp.clearHoldSelect();
      comp.isHoldEmpty = true;
    },

    submitApplicationAttachment: function () {
      const data = {
        kyc_id: this.applicationId,
        attachmentDTOs: [
          {
            attachmentBase64 : this.applicationAttachmentFile,
            type : this.applicationAttachmentType,
            comment : this.applicationAttachmentComment
          }
        ]

      }
      this.isUploading = true;
      this.showFullScreenLoader();
      NetworkManager.apiRequest(
        "api/KycApplication/AgentUploadAttachments",
        data,
        (response) => {
          if (response.statusCode === 200) {

            this.isUploading = false;
            this.attachmentNotificationType = "is-success"
            this.attachmentMessage = "Attachment uploaded Successfully";
            this.attachmentNotificationBox = true;
            this.clearApplicationAttachment()
            this.hideFullScreenLoader();
            this.getAttachmentInfo();
          }
          else {
            this.hideFullScreenLoader();
            this.isUploading = false;
            this.attachmentNotificationType = "is-danger"
            this.attachmentMessage = "Attachment upload Failed";
            this.attachmentNotificationBox = true;
          }
        },
      );
    },


    submitHoldAttachment: function () {
      let data = {
        kyc_form_id : this.applicationId,
        attachments_file : this.holdAttachmentFile,
        attachments_comment : this.holdAttachmentComment,
        attachments_type : this.holdAttachmentType,
        is_call_agent : this.hasPermission("assign-scheduled-application")
    }
      this.isUploading = true;
      this.showFullScreenLoader();
      NetworkManager.apiRequest(
        "api/KycApplication/UploadAttchmentsInHold",
        data,
        (response) => {
          if (response.statusCode === 200) {

            this.isUploading = false;

            this.holdNotificationType = "is-success"
            this.holdMessage = "Attachment uploaded Successfully";
            this.holdNotificationBox = true;

            this.hideFullScreenLoader();
            this.clearHoldAttachment();
            this.getAttachmentInfo();
          }
          else {
            this.hideFullScreenLoader();
            this.holdNotificationType = "is-danger"
            this.holdMessage = "Attachment upload Failed";
            this.holdNotificationBox = true;
            this.isUploading = false;
          }
        },
        false,
        "multipart/form-data"
      );
    },

    imageplaceholder(event) {
        event.target.src = this.defaultimg;
    },
    validateComment: function () {
        const obj = {
            required: false,
            notStartsWith: " ",
            notEndsWith: " ",
            notConsecutive: "  ",
        };
        return obj;
    },
    async handleHoldClick() {
        this.holdFile = null;
        this.holdImageToCrop = null;
        this.holdCroppedImg = null;
        this.holdImageUrl = null;
        this.onHoldAttachmentBrowse()
        //this.$refs.holdupload.$refs.click();
    },
    async handleAttchmentClick() {
        this.attachmentFile = null;
        this.attachmentImageToCrop = null;
        this.attachmentCroppedImg = null;
        this.attachmentImageUrl = null;
        this.onApplicationAttachmentBrowse();
        // this.$refs.applicationupload.$refs.click();
    },
    cropHoldImage: function () {
        var croppedImageCanvas = this.$refs.holdCropper.getCroppedCanvas();
        var croppedImageFile = null;

        //console.log(croppedImageCanvas);

        this.holdCroppedImg = croppedImageCanvas.toDataURL();
        this.holdImageUrl = this.holdCroppedImg;

        this.holdCropModalIsVisible = false;
        // this.$emit('on-captured', this.holdCroppedImg) // original code

        // convert canvas into a blob
        var fileName = "attachment" + this.count + ".jpg";

        croppedImageCanvas.toBlob((blob) => {
            let file = new File([blob], fileName, { type: "image/jpeg" });
                this.holdAttachmentImage = this.holdCroppedImg;
                this.holdAttachmentType = "image";
                this.holdAttachmentFile = file;
                this.isHoldEmpty = false;
            // add new card
        }, "image/jpeg");

        console.log("file cropped");
    },

    cropAttachmentImage: function () {
        var croppedImageCanvas = this.$refs.attachmentCropper.getCroppedCanvas();
        var croppedImageFile = null;

        //console.log(croppedImageCanvas);

        this.attachmentCroppedImg = croppedImageCanvas.toDataURL();
        this.attachmentImageUrl = this.attachmentCroppedImg;

        this.attachmentCropModalIsVisible = false;
        // this.$emit('on-captured', this.attachmentCroppedImg) // original code

        // convert canvas into a blob
        var fileName = "attachment" + this.count + ".jpg";

        croppedImageCanvas.toBlob((blob) => {
            let file = new File([blob], fileName, { type: "image/jpeg" });
                this.applicationAttachmentImage = this.attachmentCroppedImg;
                this.applicationAttachmentType = "image";

                const reader = new FileReader();
                reader.readAsDataURL(file);
                console.log("file",file["type"].split('/')[1])
                reader.onload = () => {
                  console.log("inside reader")
                  const dataUrl = reader.result;
                  const base64String = dataUrl.split(',')[1];
                  console.log("row form of base64image",base64String);
                  this.applicationAttachmentFile = base64String;
                  this.isEmpty = false;
                }  
                //console.log("row form of base64image",this.applicationAttachmentFile);
            // add new card
        }, "image/jpeg");
        console.log("applicationAttachmentType",this.applicationAttachmentType);
        console.log("applicationAttachmentFile",this.applicationAttachmentFile);
        console.log("file cropped");
    },

    getAttachmentInfo:function(){
      const comp = this;
      const data = { kyc_form_id: comp.applicationId};

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
      "api/KycApplication/getAgentUploadAttachmentById",
      data,
      function (e) {
        if (e.statusCode === 200) {
          console.log("getAgentUploadAttachmentById data ===============", e.data);
          comp.detailReviewerAttachments = e.data.hold_application_attachments.detailReviewerAttachments;
          comp.callAgentAttachments = e.data.hold_application_attachments.callAgentHoldAttachments;
          comp.applicationAttachments = e.data.additional_attachments.attachment_list;
          comp.maxHoldUploadCount = e.data.hold_application_attachments.maximum_attachment_count
          comp.currentDRHoldUploadCount = e.data.hold_application_attachments.detail_reviewer_current_attachment_count
          comp.currentCAHoldUploadCount = e.data.hold_application_attachments.call_agent_current_attachment_count
          comp.maxAttachmentUploadCount = e.data.additional_attachments.maximum_attachment_count
          comp.currentAttachmentUploadCount = e.data.additional_attachments.current_attachment_count
          comp.hideFullScreenLoader();
        } else{
          comp.hideFullScreenLoader();
        }
      }
      );
    },
    clearHoldSelect: function () {
        this.holdCropModalIsVisible = false;
        this.holdFile = null;
        this.holdCroppedImg = null;
        this.holdImageUrl = null;
    },
    clearAttachmentSelect: function () {
        this.attachmentCropModalIsVisible = false;
        this.attachmentFile = null;
        this.attachmentCroppedImg = null;
        this.attachmentImageUrl = null;
    },
    initiateHoldImageCrop: function () {
        this.holdCropModalIsVisible = true;
    },
    initiateAttachmentImageCrop: function () {
        this.attachmentCropModalIsVisible = true;
    },
  },
  mounted() {
    console.log("application id", this.applicationId);
    console.log("AuthID :",this.authId)
    console.log("CurrentState :",this.currentStatus)
    console.log("Current Logged in User",this.authId.role)
    this.getAttachmentInfo();
  }
}
</script>

<style lang="scss" scoped>
.disable-button {
    display: none; /* Initially hide the download button */
    position: absolute;
    top: 0;
    left: 0;
    width: 42%;
    height:98%;
    margin-left :1.3vw;
    margin-top: 1.5vh;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
    linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    background-blend-mode: overlay;
    color: white;
    font-size: 18px;
    border: none;
    cursor: not-allowed;
}
.image-box-wrapper:hover .disable-button {
    display: block; /* Show the download button on hover */
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}

.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 90%;
  height: auto;
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}

.web-camera-container .camera-shoot {
  margin: 1rem 0;
}

.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}

.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}


.crop-content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;

  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.modal-card {
  border-radius: 0;
}
</style>