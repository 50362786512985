<template>
  <div class="wrapper">

    <div v-if="type !== 'string-list'">

      <!-- main value   -->
      <b-field
          label-position="inside"
          custom-class="custom-float-label" >

        <template #label>{{ label }}</template>

        <b-input
            v-model="value"
            rounded
            type="text"
            :disabled="true"
            :readonly="true"/>
      </b-field>

      <!-- value changes if any-->
      <div v-if="fieldData.changes.length > 0" class="kyc-change-wrapper">
        <label>Previous {{label}} Data</label>
        <span class="kyc-field-change" v-for="(change, index) in fieldData.changes" :key="index">
        {{ getKycFieldChangesTemplate(change) }}
      </span>

      </div>
    </div>

    <div v-else>

      <label>{{label}}</label>
      <div class="list-wrapper">
        <ul>
          <li v-for="(item, index) in fieldData.value" :key="index">
            {{item}}
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>

import {defineComponent} from "vue";
import {BuefyHelper} from "@/helpers/bufeyHelper";
import {DateTimeManger} from "@/helpers/dateTimeManger";

export default defineComponent({

  name : "central-kyc-field",

  props : {
    label : {
      required : true,
      type : String
    },
    type : {
      required : false,
      type : String,
      default : "text"
    },
    fieldData: {
      required : true,
      type : Object
    }
  },

  data(){
    return {
      value: null
    }
  },


  methods : {
    copyToClipboard(text) {

      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      BuefyHelper.showToastMessage("Copied to Clipboard", "is-danger");
    },

    bindValue(){
      const comp = this

      if(comp.type === "text"){
        comp.value = comp.fieldData.value
      }
      else if(comp.type === "date"){
        comp.value = comp.getFormattedDateTime(comp.fieldData.value)
      }
      else if(comp.type === "boolean"){
        if(comp.fieldData.value === true){
          comp.value = 'Yes'
        }
        else {
          comp.value = 'No'
        }
      }
    },


    getFormattedDateTime(date){
      return DateTimeManger.formatCustomerInputDates(date)
    },


    getKycFieldChangesTemplate(change){
      let value = this.type === "date" ? this.getKycFieldChangesTemplate(change.value) : change.value;
      return change.source + " - " + value
    }
  },


  mounted(){
    this.bindValue();
  }

})
</script>

<style scoped>
  .wrapper{
    margin-bottom: 20px;
  }
  .kyc-change-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    color: red;
  }
  .kyc-change-wrapper span{
    margin-left: 10px;
    margin-top: 5px;
  }

  .list-wrapper{
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  .list-wrapper ul{
    margin-left: 20px;
    list-style-type: disc;
    color: #7a7a7a;
  }
</style>