import {ExceptionalApproverBaseTableDataItem} from "../exceptionalApproverBaseTableDataItem";

export class PendingExApprovalDetailReviewerTableDataItem extends  ExceptionalApproverBaseTableDataItem{
    received_date

    constructor(id,refNo,nic,name,gender,email,branch,assignedUserName,status,receivedDate) {
        super(id,refNo,nic,name,gender,email,branch,assignedUserName,status);
        this.received_date = receivedDate
    }

}