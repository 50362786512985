<!--LR-->
<template>
  <div class="wrapper">
    <b-button class="custom-btn" type="is-warning" expanded @click="emitShowInputIndustryModal">{{GetButtonLabel}}</b-button>
  </div>
</template>

<script>
import {GlobalEventManager} from "../../helpers/globalEventManager";
import {GlobalEvents} from "../../helpers/globalEvents";

export default {
  name: "AddIndustryButtonComponent",

  computed :{
    GetButtonLabel(){
      const comp = this
      return comp.addOccupation ? "Add Industry and Occupation" : "Add Industry";
    }
  },

  data(){
    return {

    }
  },

  props : {
    addOccupation : {
      type : Boolean,
      required : true,
      default : false
    }
  },

  methods :{

    emitShowInputIndustryModal(){
      console.log("emit ShowInputIndustryModal")
      GlobalEventManager.$emit(GlobalEvents.admin.kycApplications.showInputIndustryModal)
    }
  },
}
</script>

<style scoped>
.wrapper{
  display: block;
  padding-bottom: 40px;
}
.custom-btn{
  min-height: 55px;
  font-weight: bolder;
  font-size: 14pt;
}
</style>