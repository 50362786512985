<template>
  <div class="mt-6 ml-3 wrapper">
    <div class="filter-wrapper" v-if="!showLoader">

      <div class="filter-item">
        <label>Date Range</label>
        <b-field class="is-small">
          <b-datepicker
              rounded
              v-model="startDate"
              :max-date="GetMaxStartDate"
              :show-week-number="false"
              :append-to-body="true"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field>
      </div>

      <div class="filter-item" style="margin-bottom: 6px">
        <span class="mt-4"><br>To</span>
      </div>

      <div class="filter-item">
        <b-field>
          <b-datepicker
              rounded
              v-model="endDate"
              :min-date="startDate"
              :max-date="maxStartDate"
              :show-week-number="false"
              :append-to-body="true"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus>
          </b-datepicker>
        </b-field>
      </div>

      <!-- branch filter -->
      <div class="filter-item" style="align-self: baseline">
        <label>Branch</label>
        <b-field>
          <b-select  v-model="filterBranchId" placeholder="All Branches" rounded size="is-small" >
            <option value=0 >All Branches</option>
            <option v-for="(branch,index) in branches" :value="branch.id" :key="index">{{ branch.branch_name }}</option>
          </b-select>
        </b-field>
      </div>

      <!-- user filter -->
      <div class="filter-item" style="align-self: baseline">
        <label>User</label>
        <b-field>
          <b-select  v-model="filterUsername" placeholder="All Users" rounded size="is-small" >
            <option :value=null>All Users</option>
            <option v-for="user in users" :value="user.username" :key="user.id">{{user.username}}</option>
          </b-select>
        </b-field>
      </div>


      <!-- filter button -->
      <div class="filter-item">
        <b-button class="is-primary filter-btn" rounded @click="emitFilterData" style="margin-top: 21px;">Filter</b-button>
      </div>

      <!-- clear filters button  -->
      <div class="filter-item">
        <b-button class="is-primary clear-filter-btn" rounded outlined @click="clearFilters" style="margin-top: 21px;">Clear</b-button>
      </div>

    </div>

    <b-loading :is-full-page="true" v-model="showLoader" :can-cancel="false"></b-loading>


  </div>

</template>

<script>

import {useAuthStore} from "../../store/authStore";
import NetworkManager from "../../network";
import {GlobalEventManager} from "../../helpers/globalEventManager";
import {GlobalEvents} from "../../helpers/globalEvents";
import {GlobalAttributes} from "../../helpers/globalAttributes";
import {BranchFilterOption} from "../../helpers/classes/admin/dataFilterTable/branchFilterOption";
import ActivityLogFilterUserDTO from "../../helpers/classes/activityLog/activityLogFilterUserDTO";


export default {
  computed: {
    GetMaxStartDate(){
      const comp = this
      return comp.endDate === null ? comp.maxStartDate : comp.endDate
    },
  },


  components: {

  },

  props:['date','fields','tableData','roleFilteringFor','userSelect'],

  name: "ActivityLogFilter",



  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      maxStartDate : new Date(), // get yesterday as max date
      minEndDate: new Date(),
      filterChanged: false,
      users:[],
      branches :[],
      filterUsername:null,
      filterBranchId:null,
      showLoader : false,

    };

  },

  mounted(){
    this.getUsers();
    this.assignBranchList()
    // console.log("filter fields")
    // console.log(this.fields)
  },

  methods: {



    clearFilters(){
      const comp = this
      comp.showLoader = true

      comp.startDate = null
      comp.endDate = null
      comp.filterUsername = null
      comp.filterBranchId = null


      // small delay was added otherwise placeholders will not appeared in the dropdown fields
      setTimeout(()=>{comp.showLoader = false},50)
      this.emitFilterData()
    },


    getUsers:function (){
      const comp = this;

      const requestBody = {
        role_name: comp.roleFilteringFor
      }

      NetworkManager.apiRequest("api/User/list",requestBody,(response)=>{
        console.log(response)
        if(response.statusCode  === 200){
          console.log(response)
          comp.users = []
          response.data.users.forEach(user => comp.users.push(new ActivityLogFilterUserDTO(user.id.value,user.username.value)) )
        }
      })
    },


    emitFilterData:function (){
      const comp = this


      let filterParameters = {}

      // add filter selections
      filterParameters["startDate"] =comp.startDate
      filterParameters["endDate"] =comp.endDate
      filterParameters["username"] = comp.filterUsername
      filterParameters["branchId"] = comp.filterBranchId


      console.log("filter params (emitters end) : ")
      console.log(filterParameters)

      this.$emit('filterData',filterParameters)
    },


    async assignBranchList(){

      const comp = this

      comp.showFullScreenLoader()
      await comp.waitUntilBranchListLoaded()
      console.log("after waiting for branch list...")
      comp.branches = comp.$root.formDataSupport.branchesArray
      comp.hideFullScreenLoader()
    },


    /**
     * This function is used to wait until the branch list was loaded completely
     * @returns {Promise<unknown>}
     */
    waitUntilBranchListLoaded() {
      const comp = this;

      return new Promise((resolve) => {
        const checkBranchListLoaded = () => {
          if (comp.$root.formDataSupport.branchesArray.length > 0) {
            console.log("branch list loaded ...");
            resolve();
          } else {
            console.log("waiting for branch list...");
            setTimeout(checkBranchListLoaded, 100);
          }
        };

        checkBranchListLoaded();
      });
    },
  }
}

</script>
<style scoped>
.wrapper{
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display:grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}


.filter-wrapper{
  display: flex;
  justify-content: left;
  align-items: flex-end;
  flex-wrap: wrap;
}

.filter-item{
  min-width: min-content;
  max-width: 200px;
  margin-right : 10px;
}

.filter-item-loop{
  align-self: baseline;
}

.filter-btn{
  min-width: 120px;
}

.clear-filter-btn{
  width: 100px;
}

</style>
