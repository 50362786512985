/**
 * =====================================================================================================================
 * This class can be used to represent each kyc field
 *
 */
export class KycAttribute {
  customer_input = "";
  is_changed_by_call_agent = false;
  call_agent_input = "";
}
