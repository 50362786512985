<template>
  <div class="columns">
    <div class="column is-half has-text-left">
      <p class="comparison-header" style="margin-left:40px">{{ header }} :</p>
    </div>
    <div class="column is-half has-text-left" style="color:grey">
      <div>{{ newValue.value }}</div>
      <div v-if="((oldValue !== undefined && oldValue !== null) && oldValue.value !== newValue.value)" class="old-value">{{ oldValue.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeRequestStringComparisonItem",
  props: ['header', 'newValue', 'oldValue'],
  data(){
    return {

    }
  },
  methods: {
    
  },

  mounted() {
    console.log(this.header)
  }
}
</script>

<style scoped>
  .comparison-header {
   
    color:#04c3fc !important;
    font-size: medium;
  }

  .old-value {
    color: #ff4d4d;
    text-decoration: line-through;
  }

 
</style>
