export class FilterDetail {
    id = 0;
    name = ""
    email = ""
    contact = ""
    selectedValue = null
    placeholder = ""
    options = []

    constructor(id,name,email,mobileNo,selectedValue,placeholder,options) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.contact = mobileNo;
        this.selectedValue = selectedValue;
        this.placeholder = placeholder;
        this.options = options
    }


    // original constructor
    // constructor(id,name,selectedValue,placeholder,options) {
    //     this.id = id;
    //     this.name = name;
    //     this.selectedValue = selectedValue;
    //     this.placeholder = placeholder;
    //     this.options = options
    // }

    /**
     * CAUTION
     * This static method is used to get admin filter component filter option.
     * Never change this method. This directly associated with the AdminDataTableFilter.vue
     * If this changed, AdminDataTableFilter.vue dropdown values will not display
     * @param id
     * @param name
     * @param selectedValue
     * @param placeholder
     * @param options
     * @returns {FilterDetail}
     */
    static getFilterDetailObjectForAdminFilter(id,name,selectedValue,placeholder,options){
        let filterDetail = new FilterDetail()
        filterDetail.id = id;
        filterDetail.name = name
        filterDetail.selectedValue = selectedValue;
        filterDetail.placeholder =placeholder
        filterDetail.options = options

        // return new FilterDetail(id,name,null,null,selectedValue,placeholder,options)

        return filterDetail
    }
}