<template >
  <div style="overflow: auto !important">
   
    <div class="container-header p-3" >
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag="router-link" to="" active
              ><label style="font-family:Gotham-Medium; color: gray; font-weight: 400;">Users</label></b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <b-input
              placeholder="Search..."
              rounded
              type="search"
              icon="magnify"
              icon-clickable
              v-model="searchKeyword"
              style="width:450px"

            >
            </b-input>
          </div>
          <div  class="column">
            <b-button
              v-if="hasPermission('user-create')"
              type="is-primary is-small"
              class="is-pulled-right"
              icon-left="account-plus-outline"
              icon="account"
              @click="navigateTo('CreateUser')"
              rounded
              > Create User</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <FilterBranch></FilterBranch>
    </div> -->
    <section>
    <div>
    
      <section style="margin-top:10px; margin-left: 20px; height: 100px;overflow: visible; position: relative!important;"> 
      
 
        <div class="columns">
          <div class="column">
            <b-field >Date Range
              <b-datepicker
                rounded
                v-model="startDate"
                :max-date="maxStartDate"
                :show-week-number="false"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
                :append-to-body="true"
               
                
                >
              </b-datepicker>
            </b-field>
          </div>
          <br><br> To
          <div class="column">
            <b-field> 
              <b-datepicker
                rounded
                v-model="endDate"
                :max-date="maxStartDate"
                :show-week-number="false"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
                style="margin-top: 21px;"
                :append-to-body="true"
                
                >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column" >
            <label>Branch</label>
            <b-field>

              <b-select placeholder="Select a Branch" rounded v-model="selectedBranch" style="placeholder-color: rgb(95, 74, 74): importa !important;;" >
                <option value=0 disabled>Select a Branch</option>
                <option v-for="branch in branchList" :value="branch.id.value" :key="branch.id.value">{{branch.branch_name.value}}</option>
            </b-select>
    

            </b-field>
          </div>
          <div class="column">
            <b-field> 
            <b-button type="is-primary"
                icon-left="magnify"
                @click="filter"
                style="margin-top: 21px; width: 200px;">
                Filter
            </b-button>
            </b-field>
          </div>
          <div class="column">
            <b-field> 
            <b-button  class="clear-filter-btn"
                type="is-primary" outlined
                icon-left="magnify"
                @click="clear"
                style="margin-top: 21px; width: 200px;">
                Clear
            </b-button>
            </b-field>
          </div>
        </div>  
      </section>
    </div>
    <div class="card-content" style="overflow: auto !important; height: 55vh;">

      
      <b-table
        :data="tableData.searchData(userList, searchableFields, searchKeyword)"
        :paginated="tableOptions.isPaginated"
        :per-page="tableOptions.perPage"
        :current-page.sync="tableOptions.currentPage"
        :pagination-simple="tableOptions.isPaginationSimple"
        :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection"
        :pagination-rounded="tableOptions.isPaginationRounded"
        :sort-icon="tableOptions.sortIcon"
        :sort-icon-size="tableOptions.sortIconSize"
        default-sort="id"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :page-input="tableOptions.hasInput"
        :pagination-order="tableOptions.paginationOrder"
        :page-input-position="tableOptions.inputPosition"
        :debounce-page-input="tableOptions.inputDebounce"
        :sticky-header="true"
        :class="{ 'is-loading': isTableLoading }"
         style="position: relative; z-index: 1;"

      >
        
        <b-table-column
          field="id"
          label="ID"
          width="40"
          sortable
          numeric
          v-slot="props"
        >
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="name" label="Name" sortable v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column
          field="contact_number"
          label="Contact No"
          sortable
          v-slot="props"
        >
          {{ props.row.contact_number }}
        </b-table-column>

        <b-table-column
          field="username"
          label="Username"
          sortable
          v-slot="props"
        >
          {{ props.row.username}}
        </b-table-column>

      
        <!-- <b-table-column field="branch" label="Branch" sortable v-slot="props">
          {{ props.row.branch }}
        </b-table-column> -->

        <b-table-column field="Role" label="Role Name" sortable v-slot="props">
          {{ props.row.role }}
        </b-table-column> 

        <b-table-column
          field="created_at_upm"
          label="Created At"
          sortable
          centered
          v-slot="props"
        >
          <span>
            {{ DateTimeManger.getDisplayDateForUPM(props.row.created_at_upm) }}
          </span>
        </b-table-column>

        <b-table-column
          field="updated_at_upm"
          label="Updated At"
          sortable
          centered
          v-slot="props"
        >
          <span>
            {{ DateTimeManger.getDisplayDateForUPM(props.row.updated_at_upm) }}
          </span>
        </b-table-column>

        <b-table-column field="status" label="Status" sortable v-slot="props">
          <span>
            <b-tooltip :label="props.row.status === 'Active' ? 'Active': 'Not Active'" :type="props.row.status === 'Active' ? 'is-success' : 'is-danger'">
            <b-icon
              icon="circle"
              size="is-small"
              :type="
                props.row.status === 'Active' ? 'is-success' : 'is-danger'
              "
              rounded
            />
            </b-tooltip>
          </span>
        </b-table-column>

        <b-table-column field="action" label="Action" v-slot="props">
          <div class="buttons" v-if="props.row.locked === 0">
            <b-tooltip v-if="hasPermission('user-edit')" label="Update">
              <b-button rounded
                style="width:auto; height: auto;"
                type="is-primary"
                size="is-small"
                @click="navigateTo('EditUser', { id: props.row.id })"
                icon-left="lead-pencil"
              ></b-button>

            </b-tooltip>
            <b-tooltip label="Change Password" position="is-left" type="is-dark">
              <b-button
                v-if="hasPermission('user-edit')"
                type="is-info"
                size="is-small"
                @click="onChangePasswordClick(props.row.username)"
                icon-left="form-textbox-password"
                rounded></b-button>
            </b-tooltip>
          </div>
          <b-tooltip v-else label="Locked">
            <!-- <b-icon icon="lock" size="is-medium"  style="background-color: black; border-radius: 15px; color:white;padding:10px" > </b-icon> -->
            <b-button rounded
                      style="width:auto; height: auto;"
                      type="is-dark"
                      size="is-small"
                      icon-left="lock"></b-button>
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
    </section>
    <br />

    <!-- Password Change Modal -->
    <b-modal
      v-model="isPwChangeModalActive"
      has-modal-card
      :can-cancel="false"
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
    >
      <template>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Change Password</p>
          </header>
          <section class="modal-card-body">
            <b-field
              
              :type="newPassword.class"
              :message="newPassword.message"
            >
              <b-field :type="newPassword.class" grouped>
                <b-input disabled rounded @blur="newPassword.blur()" v-model="newPassword.value">
                </b-input>
                <p class="control">
                  <b-button @click="generatePassword" class="button is-primary" rounded icon-left="autorenew">
                  </b-button>
                </p>

                <p class="control">
                  <b-button @click="copyToClipboard" class="button is-primary" rounded icon-left="content-copy">
                  </b-button>
                </p>
              </b-field>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button label="Close" @click="isPwChangeModalActive = false" />
            <b-button
              :loading="isPwChangeModalLoading"
              label="Change"
              @click="changePassword()"
              type="is-primary"
            />
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import { FieldValidator } from "@/helpers/validators/fieldValidator";
import SearchBarVue from "../../components/SearchBar.vue";
import FilterBranch from "../../components/FilterBranch.vue";
import { filterTableData } from "../../helpers/filterTableData";
import {DateTimeManger} from "../../helpers/dateTimeManger";

export default {
  name: "Table",
  components: {
    searchBar: SearchBarVue,
    FilterBranch: FilterBranch,
  },
  data() {
    return {

      users: [],
      crData: [],
      crIsLoading: true,
      filterOutFromCR: ["id", "created_at_upm", "updated_at_upm", "locked"],
      data:[],
      isPwChangeModalActive: false,
      isPwChangeModalLoading: false,
      selectedUsername: "",
      newPassword: new FieldValidator(),
      searchable:'',
      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: true,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: "is-centered",
        inputPosition: "",
        inputDebounce: "",
        stickyHeaders: true,
      },
      searchableFields: ['name', 'email', 'branch','contact_number','username','updated_at_upm','created_at_upm'],
      searchKeyword: "",
      tableData:"",
      userList:[],

      startDate: null,
      endDate: null,
      selectedBranch:0,
      maxStartDate : new Date(), // get yesterday as max date
      minEndDate: new Date(),
      branchList : [],
      isTableLoading : false,
    };
  },

  computed: {
    DateTimeManger() {
      return DateTimeManger
    }

  },

  created() {
    this.tableData = filterTableData
  },

  methods: {
    // this function can be used to hide side bar
    // buttonClick() {
    //   this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    // },
    /**
     * This function can be used to fetch the relevant product list to customer
     */

    onChangePasswordClick: function (username) {
      this.selectedUsername = username;
      console.log("Selected Username : ",this.selectedUsername)
      this.isPwChangeModalActive = true;
      this.generatePassword();
    },

    generatePassword: function () {
      const self = this;

      const length = Math.floor(Math.random() * 8) + 8;

      let result = "";
      const uc = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const lc = "abcdefghijklmnopqrstuvwxyz";
      const num = "0123456789";
      const sp = "@$!%*?&";

      const characters = uc + lc + num + sp;
      const charactersLength = characters.length;

      const x = Math.floor(Math.random() * (uc.length - 1)) + 1;
      const y = Math.floor(Math.random() * (length - 1)) + 1;
      const c1 = uc[x];

      const a = Math.floor(Math.random() * (lc.length - 1)) + 1;
      const b = Math.floor(Math.random() * (length - 1)) + 1;
      const c2 = lc[a];

      const p = Math.floor(Math.random() * (num.length - 1)) + 1;
      const q = Math.floor(Math.random() * (length - 1)) + 1;
      const c3 = num[p];

      const m = Math.floor(Math.random() * (sp.length - 1)) + 1;
      const n = Math.floor(Math.random() * (length - 1)) + 1;
      const c4 = sp[m];
      for (let i = 0; i < length; i++) {
        if (i === y) result += c1;
        if (i === b) result += c2;
        if (i === q) result += c3;
        if (i === n) result += c4;

        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      self.newPassword.value = result;
    },

    copyToClipboard: function () {
      const self = this;

      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = self.newPassword.value;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      self.$buefy.toast.open({
        type:"is-warning",
        message: "Copied to Clipboard",
        duration: 5000,
      });
    },

    loadData: function (startDate, endDate,branch) {
      let self = this;

      const data={
        start_date:startDate,
        end_date:endDate,
        branch_id : branch
      }

      self.showFullScreenLoader();
      self.isTableLoading = true;

      NetworkManager.apiRequest("api/user/list", data, function (e) {
        if (e.statusCode === 200) {
          console.log("user list resp")
          console.log(e.data)
          self.users = []
          self.userList = []
          self.users = e.data.users;
          self.users.forEach(user => {
            console.log('user list------------------')
            console.log(user)
            self.userList.push(
                { 'branch':user.branch.value,
                  'contact_number':user.contact_number.value,
                  'created_at_upm':user.created_at.value,
                  'email':user.email.value,
                  'id':user.id.value,
                  'name':user.name.value,
                  'role':user.role.value,
                  'username':user.username.value,
                  'updated_at_upm':user.updated_at.value,
                  'status':user.status.value,
                  'locked':user.locked.value
                })
          })
          // console.log("Save user details");
          // console.log(e.data)
          // self.crIsLoading=false
          self.isTableLoading = false;
          self.hideFullScreenLoader()
        }
        else {
          self.isTableLoading = false
          self.hideFullScreenLoader()
        }

      });
    },

    getBranchesList: function (){
      var self = this;

      self.showFullScreenLoader();
      NetworkManager.apiRequest("api/branch/list", {}, function (e) {
        if (e.statusCode === 200) {
          self.branchList = [];
          e.data.branches.forEach(element => {
            if (element.status.value === 'Active') {
              self.branchList.push(element);
            }
          });

          // Sort the branchList array in alphabetical order based on branch_name.value
          self.branchList.sort((a, b) => {
            const nameA = a.branch_name.value.toUpperCase();
            const nameB = b.branch_name.value.toUpperCase();

            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          });

          self.hideFullScreenLoader();
        }


      });
    },
    clear : function(){
        var self = this;
        self.startDate = null;
        self.endDate = null;
        self.selectedBranch = 0;
        self.loadData(null,null,0)
    },
    changePassword: function () {
      const self = this;

      if (self.newPassword.validate()) {
        self.isPwChangeModalLoading = true;

        const data = {
          username: self.selectedUsername,
          password: self.newPassword.value,
        };

        self.showFullScreenLoader();

        NetworkManager.apiRequest(
          "api/User/change-password",
          data,
          function (e) {
            if (e.statusCode === 200) {
              self.isPwChangeModalActive = false;
              self.isPwChangeModalLoading = false;

              const params = {
                type: "is-success",
                message:
                  'Password changed for user "' +
                  self.selectedUsername +
                  '" successfully',
                  duration: 5000,
              };
              self.$buefy.toast.open(params);
            } else {
              self.isPwChangeModalActive = false;
              self.isPwChangeModalLoading = false;

              const params = {
                type: "is-warning",
                message: "Password change failed!",
                duration: 5000,
              };
              self.$buefy.toast.open(params);
            }

            self.hideFullScreenLoader();
          }
        );
      }
    },

    approve_or_reject: function (data) {
      const self = this;
      this.crIsLoading = true;

      NetworkManager.apiRequest("api/user/approve", data, function (e) {
        if (e.statusCode === 200) {
          const params = {
            type: "is-success",
            message: "User " + data.actionTaken + " successfully",
            duration: 5000,
          };
          self.$buefy.toast.open(params);
          location.reload();
        } else {
          const params = {
            type: "is-warning",
            message: "User " + data.actionTaken + " failed!",
            duration: 5000,
          };
          self.$buefy.toast.open(params);
        }

        self.crIsLoading = false;
      });
    },

    // getFormattedDateTime(date){
    //   return DateTimeManger.formatDateTime(date)
    // },

    filter: function(){
      this.loadData(this.startDate, this.endDate , this.selectedBranch);
    }
    //fuction to filter the table
    /*filterTableData(users, searchableFields, searchKeyword) {
  if (!searchKeyword) {
    return users;
  }
  
  const keyword = searchKeyword.toLowerCase();
  
  return users.filter(user => {
    return searchableFields.some(field => {
      return user[field].value.toLowerCase().includes(keyword);
    });
  });
}*/
   

  },

  mounted() {
    this.newPassword.regex = this.regex.passwordValidation;
    this.newPassword.errorMessage =
      "Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)";
    this.loadData(null, null,0);
    this.getBranchesList();
    this.generatePassword();
  },
};
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}
.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
.level {
  justify-content: center !important;
}


</style>