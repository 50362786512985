<template>
  <div style="height: 80.5vh; overflow: auto !important;">
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label style="font-family:Gotham-Medium; color: gray; font-weight: 400; font-size: 23px;">Incomplete Applications</label>
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                  placeholder="Search..."
                  rounded
                  type="search"
                  icon="magnify"
                  icon-clickable
                  v-model="searchKeyword"
                  style="width:450px" 

              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!---------table--------------------------------------->
    <section class="mt-5 p-2">
      <AdminTableComp
          :tableData="tableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToBeAssigned"
          :searchableFields="searchableFields"
          :searchKeyword="searchKeyword"
      />
    </section>
  </div>
</template>

<script>

import NetworkManager from "@/network";
import AdminTableComp from "@/components/AdminTableComp";
import AdminDataTableFilter from "@/components/AdminDataTableFilter";

export default {
  components: {
    // TableComp,
    // SearchBar,
    AdminTableComp,
    AdminDataTableFilter
  },
  data() {
    return {
      activeTab: 0,

      currentPage: "NEW",
      statusToBeAssigned: "ASSIGNED-DETAIL-REVIEWER",

      searchKeyword:"",
      searchableFields: ['reference_number', 'nic_eic_number', 'full_name','email','received_date','mobile_number'],

      filterDetails:[
        {
          id:1,
          name:'Branch',
          selectedValue:null,
          placeholder:'Select a Branch',
          options:[
            {id:'1',value:'Kandy'},
            {id:'2',value:'Dambulla'}
          ]
        },

        {
          id:2,
          name:'User',
          selectedValue:null,
          placeholder:'All Users',
          options:[
            {id:'1',value:'Dtm98'},
            {id:'2',value:'User23'}
          ]
        }
      ],

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.",date:false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "email", label: "Email" ,date:false},
        { id: 5, field: "received_date",label: "Received Date",date:true},
        { id: 6, field: "mobile_number", label: "Contact" ,date:false},
      ],
      tableDetails: [ ],
    };
  },

  methods: {

    GetApplicationData: function () {
      const comp = this;
     

      comp.showFullScreenLoader();
      NetworkManager.apiRequest(
          "api/Admin/getIncompleteApplications",
          {},
          function (e) {
            if (e.statusCode === 200) {
              comp.tableDetails = e.data.applications;
            }
      
            comp.hideFullScreenLoader();
          }
      );
      comp.hideFullScreenLoader();
    },
  },
  mounted() {
    this.GetApplicationData();
    if(this.$root.isSideBarHidden ){
      this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
    }
  },
};
</script>
