<template>
  <ValidationObserver v-slot="{ invalid }">
    <div>
      <div class="pdf-content">
        <!-- <b-message
          v-if="!isReworkedApplication"
          type="is-danger">Re-Worked
        </b-message> -->

        <b-message
          v-if="
            !formData.pep_declaration.customer_input.not_politically_exposed
          "
          type="is-danger"
        >
          PEP Customer
        </b-message>

        <b-message
          v-if="videoCallDetail != null && videoCallDetail.call_duration > 0"
          type="is-success"
          has-icon
        >
          Duration to complete video call :
          {{
            GlobalFunctions.getDisplayTimeDuration(
              videoCallDetail.call_duration
            )
          }}
        </b-message>

        <b-message
          v-if="productErrorDetail.product_error"
          type="is-danger"
          has-icon
        >
          <div>
            Error
            <ul style="list-style: disc; margin-left: 20px">
              <li
                v-for="(error, index) in productErrorDetail.errors"
                :key="index"
              >
                {{ error }}
              </li>
            </ul>
          </div>
        </b-message>

        <div v-if="showLoadingModel" class="fullscreen">
          <ProgressLoader :value="loaderValue" label="this is text"/>
        </div>
        <div v-if="getCommitKycDetails !== null">
          <div v-for="comments in getCommitKycDetails">
            <div v-if="!(comments.comment === '' || comments.comment === null)">
              <div>
                <ul
                  type="disc"
                  style="color: #000000"
                  :key="comments.comment_label"
                >
                  <li>*{{ comments.comment_label }}</li>
                  <br />
                </ul>
                <!-- <b-field :label="comments.comment_label"  style="font-family: Gotham-Light; font-weight: bold">
                </b-field> -->
              </div>
            </div>
          </div>

          <b-modal v-model="isCardModalActive" :width="1100" scroll="keep">
            <div
              class="card"
              rounded
              style="
                border: 0.3vh solid #000000;
                border-radius: 25px;
                padding: 20px;
              "
            >
              <div v-for="comments in getCommitKycDetails">
                <div>
                  <b-field
                    :label="comments.comment_label"
                    style="font-family: Gotham-Light; font-weight: bold"
                  >
                  </b-field>
                </div>
                <div>
                  <InputLabel
                    v-if="comments.internal_comment"
                    label="Internal Comment"
                    readonly="true"
                    type="textarea"
                    placeholder="-- No Data --"
                    :value="comments.internal_comment"
                  />
                </div>
                <div>
                  <InputLabel
                    v-if="comments.customer_comment"
                    label="Customer Comment"
                    readonly="true"
                    type="textarea"
                    placeholder="-- No Data --"
                    :value="comments.customer_comment"
                  />
                </div>
                <div>
                  <ul type="disc" style="color: #313030">
                    <li v-if="comments.reasonsList">Reasons</li>
                  </ul>
                  <div v-for="items in comments.reasonsList">
                    <ul
                      type="disc"
                      style="color: #000000"
                      :key="items.comment_key"
                    >
                      <li v-if="items.is_comment_selected">
                        *{{ items.comment_content }}
                      </li>
                    </ul>
                    <div
                      v-if="
                        (items.comment_content == 'Other' ||
                          items.comment_content == 'Others') &&
                        items.is_comment_selected
                      "
                    >
                      <InputLabel
                        label="Please specify"
                        readonly="true"
                        type="textarea"
                        placeholder="-- No Data --"
                        :value="items.additional_comment"
                      />
                    </div>
                  </div>
                </div>
                <hr
                  style="width: 100%"
                  :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                  }"
                />
              </div>
            </div>
          </b-modal>
        </div>

        <b-button type="is-primary" outlined
          @click="isCardModalActive = true"
          v-if="getCommitKycDetails !== null && getCommitKycDetails.length > 0"
          >Show more</b-button
        >

        <div class="is-7 mt-3" v-for="reports in drpArray" style="margin-top: 20;">
          <label >DRP Checked date : {{ reports.updated_at  }}</label>
            <InputLabel class="mt-3"
              v-if="reports.comment !=''"
              label="DRP Internal Comment"
              readonly="true"
              type="textarea"
              placeholder="-- No Data --"
              :value="reports.comment[0]"
            />
        </div>

        <div id="input-separator"></div>

        <p>Customer Details</p>
        <div class="columns mt-1 is-7 is-variable">
          <div class="column is-three-fifths">
            <!-- CIF number -->
            <div
              class="columns is-7 is-variable"
              v-if="customerCifNumber.trim() !== ''"
            >
              <div class="column is-half">
                <InputLabel
                  copyable="true"
                  label="CIF Number"
                  readonly="true"
                  :value="customerCifNumber"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <!-- Title -->
            <div class="columns is-7 is-variable">
              <div class="column is-one-quarter">
                <InputLabel
                  copyable="true"
                  label="Title"
                  readonly="true"
                  :value="formData.title.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Title"
                  copyable="true"
                  :value="formData.title.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.title.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <FullNameView
                  :copyable=true
                  class="mt-1"
                  label="Full Name (As per the NIC / EIC / PP)"
                  :value="formData.full_name.customer_input"
                  :is-agent="false"
                  />
                <FullNameView v-if="formData.full_name.is_changed_by_call_agent"
                  class="mt-1"
                  :copyable=true
                  label="Full Name (As per the NIC / EIC / PP)"
                  :value="formData.full_name.call_agent_input"
                  :is-agent="true"
                  />
                <!-- <InputLabel
                  label="Full Name (As per the NIC / EIC / PP)"
                  readonly="true"
                  type="textarea"
                  rounded
                  :maxlength="146"
                  rows="2"
                  cols="48"
                  :value="formData.full_name.customer_input"
                  class="non-interactable-input"
                /> -->
                <!-- <AgentInputField
                  label="Full Name (As per the NIC / EIC / PP)"
                  type="textarea"
                  rounded
                  :maxlength="146"
                  rows="2"
                  cols="48"
                  :value="formData.full_name.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.full_name.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                /> -->
              </div>
            </div>
            <!-- citizenship -->
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  label="Citizenship"
                  copyable="true"
                  readonly="true"
                  :value="formData.citizenship.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Citizenship"
                  copyable="true"
                  :value="formData.citizenship.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.citizenship.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel
                  label="Residency"
                  copyable="true"
                  readonly="true"
                  :value="formData.residentship.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Residency"
                  copyable="true"
                  :value="formData.residentship.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.residentship.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- date of birth -->
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  label="Nationality"
                  copyable="true"
                  readonly="true"
                  :value="formData.nationality.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Nationality"
                  copyable="true"
                  :value="formData.nationality.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.nationality.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-two-fifths">
                <InputLabel
                  label="Date of Birth"
                  copyable="true"
                  readonly="true"
                  :value="
                    getFormattedDateTime(formData.date_of_birth.customer_input)
                  "
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Date of Birth"
                  copyable="true"
                  :value="
                    getFormattedDateTime(
                      formData.date_of_birth.call_agent_input
                    )
                  "
                  styles="mt-5"
                  :hide="!formData.date_of_birth.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- Nic -->
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  label="NIC / EIC Number"
                  copyable="true"
                  readonly="true"
                  :value="formData.nic_eic_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="NIC / EIC Number"
                  copyable="true"
                  :value="formData.nic_eic_number.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.nic_eic_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-two-fifths">
                <InputLabel
                  label="NIC Issue Date"
                  copyable="true"
                  readonly="true"
                  :value="
                    getFormattedDateTime(
                      formData.nic_eic_issue_date.customer_input
                    )
                  "
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="NIC Issue Date"
                  copyable="true"
                  :value="
                    getFormattedDateTime(
                      formData.nic_eic_issue_date.call_agent_input
                    )
                  "
                  styles="mt-5"
                  :hide="!formData.nic_eic_issue_date.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <!-- Passport -->
            <div v-if="formData.passport_number.customer_input != ''">
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    label="Passport Number"
                    copyable="true"
                    readonly="true"
                    :value="formData.passport_number.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField
                    label="Passport Number"
                    copyable="true"
                    :value="formData.passport_number.call_agent_input"
                    styles="mt-5"
                    :hide="!formData.passport_number.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column is-two-fifths">
                  <InputLabel
                    label="Passport Issue Date "
                    copyable="true"
                    reaDonly="true"
                    :value="
                      getFormattedDateTime(
                        formData.passport_issue_date.customer_input
                      )
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField
                    label="Passport Issue Date"
                    copyable="true"
                    :value="
                      getFormattedDateTime(
                        formData.passport_issue_date.call_agent_input
                      )
                    "
                    styles="mt-5"
                    :hide="
                      !formData.passport_issue_date.is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    label="Passport Expire Date"
                    copyable="true"
                    readonly="true"
                    :value="
                      getFormattedDateTime(
                        formData.passport_expire_date.customer_input
                      )
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField
                    label="Passport Expire Date"
                    copyable="true"
                    :value="
                      getFormattedDateTime(
                        formData.passport_expire_date.call_agent_input
                      )
                    "
                    styles="mt-5"
                    :hide="
                      !formData.passport_expire_date.is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column is-two-fifths"></div>
              </div>
            </div>

            <!-- gender -->
            <div class="columns is-7 is-variable">
              <div class="column is-two-fifths">
                <InputLabel
                  label="Gender"
                  copyable="true"
                  readonly="true"
                  :value="formData.gender.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Gender"
                  copyable="true"
                  :value="formData.gender.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.gender.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-two-fifths">
                <InputLabel
                  label="Marital Status"
                  copyable="true"
                  readonly="true"
                  :value="formData.maritial_status.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Marital Status"
                  copyable="true"
                  :value="formData.maritial_status.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.maritial_status.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <b-message type="is-success" has-icon v-if="formData.nic_selfie_face_verification_details.isIdentical">
              Face Validation Success : Selfie image {{ GlobalFunctions.getRoundedValue(formData.nic_selfie_face_verification_details.confidence * 100) }}% matching with NIC
            </b-message>
            <b-message type="is-danger" has-icon v-else>
              Face Validation Failed : Selfie image not matched with NIC
            </b-message>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <!-- Permanent Address -->
            <p>Permanent Address</p>
            <!-- <div v-if="!formData.is_correspondence_address_differs_with_the_permanent_address">
              <label style="size: 18px; color: #ababab">Permanent and correspondence addresses are one and the same.</label>
            </div> -->
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-two-fifths">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Building Number"
                  readonly="true"
                  :value="formData.building_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Building Number"
                  copyable="true"
                  :value="formData.building_number.call_agent_input"
                  :hide="!formData.building_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Building Name"
                  readonly="true"
                  :value="formData.building_name.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Building Name"
                  copyable="true"
                  :value="formData.building_name.call_agent_input"
                  :hide="!formData.building_name.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Street"
                  readonly="true"
                  :value="formData.street.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Street"
                  copyable="true"
                  :value="formData.street.call_agent_input"
                  :hide="!formData.street.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Street 2"
                  readonly="true"
                  :value="formData.street_2.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Street 2"
                  copyable="true"
                  :value="formData.street_2.call_agent_input"
                  :hide="!formData.street_2.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Town"
                  readonly="true"
                  :value="formData.town.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Town"
                  copyable="true"
                  :value="formData.town.call_agent_input"
                  :hide="!formData.town.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Postal Code"
                  readonly="true"
                  :value="formData.postal_code.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Postal Code"
                  copyable="true"
                  :value="formData.postal_code.call_agent_input"
                  :hide="!formData.postal_code.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="District"
                  readonly="true"
                  :value="formData.district.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="District"
                  copyable="true"
                  :value="formData.district.call_agent_input"
                  :hide="!formData.district.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Country"
                  readonly="true"
                  :value="formData.country.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField
                  label="Country"
                  copyable="true"
                  :value="formData.country.call_agent_input"
                  :hide="!formData.country.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Status of Permanant Residence"
                  readonly="true"
                  :value="
                    GlobalFunctions.getStatusOfResidenceDisplayValue(
                      formData.state_of_residence.customer_input
                    )
                  "
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Status of Permanant Residence"
                  :value="
                    GlobalFunctions.getStatusOfResidenceDisplayValue(
                      formData.state_of_residence.call_agent_input
                    )
                  "
                  styles="mt-5"
                  :hide="!formData.state_of_residence.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <!-- correspondence  Address -->
            <div>
              <p>Correspondence Address</p>
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-two-fifths">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Building Number"
                    readonly="true"
                    :value="
                      formData.correspondence_address_building_number
                        .customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Building Number"
                    :value="
                      formData.correspondence_address_building_number
                        .call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_building_number
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Building Name"
                    readonly="true"
                    :value="
                      formData.correspondence_address_building_name
                        .customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Building Name"
                    :value="
                      formData.correspondence_address_building_name
                        .call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_building_name
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Street"
                    readonly="true"
                    :value="
                      formData.correspondence_address_street.customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Street"
                    :value="
                      formData.correspondence_address_street.call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_street
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Street 2"
                    readonly="true"
                    :value="
                      formData.correspondence_address_street_2.customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Street 2"
                    :value="
                      formData.correspondence_address_street_2.call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_street_2
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Town"
                    readonly="true"
                    :value="formData.correspondence_address_town.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Town"
                    :value="
                      formData.correspondence_address_town.call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_town
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Postal Code"
                    readonly="true"
                    :value="
                      formData.correspondence_address_postal_code.customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Postal Code"
                    :value="
                      formData.correspondence_address_postal_code
                        .call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_postal_code
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="District"
                    readonly="true"
                    :value="
                      formData.correspondence_address_district.customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="District"
                    :value="
                      formData.correspondence_address_district.call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_district
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Country"
                    readonly="true"
                    :value="
                      formData.correspondence_address_country.customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Country"
                    :value="
                      formData.correspondence_address_country.call_agent_input
                    "
                    :hide="
                      !formData.correspondence_address_country
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <hr
                :style="{
                  'background-color': '#1b1b1b',
                  height: '1.8px',
                  opacity: '0.5',
                  'margin-inline': '4px',
                }"
              />
            </div>
            <!-- contact -->
            <P>Contact Details</P>
            <div class="columns is-7 is-variable mt-1">
              <div class="column">
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Primary Mobile Number"
                  readonly="true"
                  :value="formData.mobile_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Primary Mobile Number"
                  :value="formData.mobile_number.call_agent_input"
                  :hide="!formData.mobile_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>

              <div
                class="column"
                v-if="
                  formData.telephone_number.customer_input ||
                  formData.telephone_number.call_agent_input
                "
              >
                <InputLabel
                  placeholder=" -- No data -- "
                  copyable="true"
                  label="Mobile/Telephone Number"
                  readonly="true"
                  :value="formData.telephone_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Mobile/Telephone Number"
                  :value="formData.telephone_number.call_agent_input"
                  :hide="!formData.telephone_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- Email Address -->
            <InputLabel
              placeholder=" -- No data -- "
              copyable="true"
              label="Primary Email ID"
              readonly="true"
              :value="formData.email.customer_input"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Primary Email ID"
              :value="formData.email.call_agent_input"
              :hide="!formData.email.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <!-- additional email address -->
            <InputLabel
              v-if="formData.secondary_email.customer_input"
              copyable="true"
              placeholder=" -- No data -- "
              label="Additional Email"
              readonly="true"
              :value="formData.secondary_email.customer_input"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Additional Email"
              :value="formData.secondary_email.call_agent_input"
              :hide="!formData.secondary_email.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <!-- Mother’s Maiden Name -->
            <InputLabel
              placeholder=" -- No data -- "
              copyable="true"
              label="Mother’s Maiden Name"
              readonly="true"
              :value="formData.mothers_maiden_name.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Mother’s Maiden Name"
              :value="formData.mothers_maiden_name.call_agent_input"
              styles="mt-5"
              :hide="!formData.mothers_maiden_name.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <!-- Employment Details -->
            <p>Employment Details</p>
            <!-- Industry -->

            <InputLabel
              v-if="formData.customer_industry !== null"
              copyable="true"
              label="Industry"
              readonly="true"
              :value="formData.customer_industry.description"
              class="non-interactable-input"
            />

            <!-- occupation -->
            <InputLabel
              v-if="
                GlobalFunctions.checkCustomerOccupationRequired(formData) &&
                formData.customer_occupation !== null
              "
              label="Occupation"
              copyable="true"
              readonly="true"
              :value="formData.customer_occupation"
              class="non-interactable-input"
            />
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel
                  v-if="formData.employment_status.customer_input"
                  label="Employment Status"
                  placeholder=" -- No data -- "
                  copyable="true"
                  readonly="true"
                  :value="formData.employment_status.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Employment Status"
                  :value="formData.employment_status.call_agent_input"
                  styles="mt-5"
                  :hide="!formData.employment_status.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div
              v-if="
                formData.employment_status.is_changed_by_call_agent
                  ? formData.employment_status.call_agent_input ===
                      'Self-Employed' ||
                    formData.employment_status.call_agent_input === 'Freelance'
                  : formData.employment_status.customer_input === 'Freelance' ||
                    formData.employment_status.customer_input ===
                      'Self-Employed'
              "
            >
              <InputLabel
                label="Profession / Nature of Business"
                copyable="true"
                readonly="true"
                placeholder=" -- No data -- "
                :value="formData.profession_nature_of_business.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Profession / Nature of Business"
                :value="formData.profession_nature_of_business.call_agent_input"
                styles="mt-5"
                :hide="
                  !formData.profession_nature_of_business
                    .is_changed_by_call_agent
                "
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div
              v-if="
                formData.employment_status.is_changed_by_call_agent
                  ? formData.employment_status.call_agent_input ===
                      'Full-Time' ||
                    formData.employment_status.call_agent_input ===
                      'Part Time' ||
                    formData.employment_status.call_agent_input ===
                      'Self-Employed' ||
                    formData.employment_status.call_agent_input === 'Probation'
                  : formData.employment_status.customer_input === 'Full-Time' ||
                    formData.employment_status.customer_input === 'Part Time' ||
                    formData.employment_status.customer_input ===
                      'Self-Employed' ||
                    formData.employment_status.customer_input === 'Probation'
              "
            >
              <InputLabel
                label="Employer /  Business Name"
                copyable="true"
                readonly="true"
                placeholder=" -- No data -- "
                :value="formData.employer.customer_input"
                styles="mt-5"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Employer /  Business Name"
                :value="formData.employer.call_agent_input"
                styles="mt-5"
                :hide="!formData.employer.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>

            <div
              v-if="
                formData.employment_status.is_changed_by_call_agent
                  ? formData.employment_status.call_agent_input ===
                      'Full-Time' ||
                    formData.employment_status.call_agent_input ===
                      'Part Time' ||
                    formData.employment_status.call_agent_input ===
                      'Self-Employed' ||
                    formData.employment_status.call_agent_input === 'Probation'
                  : formData.employment_status.customer_input === 'Full-Time' ||
                    formData.employment_status.customer_input === 'Part Time' ||
                    formData.employment_status.customer_input ===
                      'Self-Employed' ||
                    formData.employment_status.customer_input === 'Probation'
              "
            >
              <!-- Employer / Business Address -->
              <p class="mt-4">Employer / Business Address</p>
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-two-fifths">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Building Number"
                    readonly="true"
                    :value="formData.emp_building_number.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Building Number"
                    :value="formData.emp_building_number.call_agent_input"
                    :hide="
                      !formData.emp_building_number.is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Building Name"
                    readonly="true"
                    :value="formData.emp_building_name.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Building Name"
                    :value="formData.emp_building_name.call_agent_input"
                    :hide="!formData.emp_building_name.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Street"
                    readonly="true"
                    :value="formData.emp_street.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Street"
                    :value="formData.emp_street.call_agent_input"
                    :hide="!formData.emp_street.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel copyable="true"
                    placeholder=" -- No data -- "
                    label="Town"
                    readonly="true"
                    :value="formData.emp_town.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Town"
                    :value="formData.emp_town.call_agent_input"
                    :hide="!formData.emp_town.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Postal Code"
                    readonly="true"
                    :value="formData.emp_postal_code.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Postal Code"
                    :value="formData.emp_postal_code.call_agent_input"
                    :hide="!formData.emp_postal_code.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="District"
                    readonly="true"
                    :value="formData.emp_district.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="District"
                    :value="formData.emp_district.call_agent_input"
                    :hide="!formData.emp_district.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Country"
                    readonly="true"
                    :value="formData.emp_country.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Country"
                    :value="formData.emp_country.call_agent_input"
                    :hide="!formData.emp_country.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>

            <!-- <div
              v-if="
                formData.employment_status.is_changed_by_call_agent
                  ? formData.employment_status.call_agent_input === 'Freelance'
                  : formData.employment_status.customer_input === 'Freelance'
              "
            > -->
            <div
              v-if="
                formData.employment_status.call_agent_input === 'Freelance' ||
                formData.employment_status.customer_input === 'Freelance'
              "
            >
              <InputLabel
                label="Are you working for any other platform's?"
                copyable="true"
                readonly="true"
                styles="mt-5"
                :value="
                  formData.are_you_work_on_another_platform.customer_input
                    ? 'Yes'
                    : 'No'
                "
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Are you working for any other platform's?"
                :value="
                  formData.are_you_work_on_another_platform.call_agent_input
                    ? 'Yes'
                    : 'No'
                "
                styles="mt-5"
                :hide="
                  !formData.are_you_work_on_another_platform
                    .is_changed_by_call_agent
                "
                readonly="true"
                class="non-interactable-input"
              />
              <div
                v-if="
                  formData.are_you_work_on_another_platform.customer_input ||
                  formData.are_you_work_on_another_platform.call_agent_input
                "
              >
                <!-- <InputLabel
                  label="What other platforms are you working in?"
                  readonly="true"
                  :value="formData.other_platforms_working_on.customer_input"
                  styles="mt-4"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  v-if="formData.other_platforms_working_on.is_changed_by_call_agent"
                  label="What other platforms are you working in?"
                  :value="formData.other_platforms_working_on.call_agent_input"
                  styles="mt-5"
                  class="non-interactable-input"
                  readonly="true"
                /> -->
                <!-- other_platforms_working - customer inputs -->

                <DisplayBulletList
                  :copyable=true
                  label="What other platforms are you working in?"
                  :items="formData.other_platforms_working_on.customer_input"
                  :is-agent="false"
                />

                <!-- other_platforms_working - agent inputs -->
                <DisplayBulletList
                  :copyable=true
                  v-if="
                    formData.other_platforms_working_on.call_agent_input != null
                  "
                  label="What other platforms are you working in?"
                  :items="formData.other_platforms_working_on.call_agent_input"
                  :is-agent="true"
                />

                <br />
                <InputLabel
                  label="Please Specify"
                  copyable="true"
                  v-if="
                    formData.other_platforms_working_on_other_selected
                      .customer_input
                  "
                  readonly="true"
                  :value="
                    formData.other_platforms_working_on_other_description
                      .customer_input
                  "
                  styles="mt-4"
                  class="non-interactable-input"
                />
                <AgentInputField copyable="true"
                  label="Please Specify"
                  v-if="
                    formData.other_platforms_working_on_other_selected
                      .is_changed_by_call_agent
                  "
                  :value="
                    formData.other_platforms_working_on_other_description
                      .call_agent_input
                  "
                  styles="mt-5"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <!-- Tax Details -->
            <div>
              <hr
                :style="{
                  'background-color': '#1b1b1b',
                  height: '1.8px',
                  opacity: '0.5',
                  'margin-inline': '4px',
                }"
              />
              <p>Tax Details</p>
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <InputLabel
                    label="Are You a Taxpayer?"
                    copyable="true"
                    readonly="true"
                    :value="
                      formData.are_you_a_tax_payer.customer_input ? 'Yes' : 'No'
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Are You a Taxpayer?"
                    :value="
                      formData.are_you_a_tax_payer.call_agent_input ? 'Yes' : 'No'
                    "
                    styles="mt-5"
                    :hide="!formData.are_you_a_tax_payer.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="
                formData.are_you_a_tax_payer.customer_input ||
                formData.are_you_a_tax_payer.call_agent_input
              "
            >
              <InputLabel
                label="Tax File Number"
                placeholder=" -- No data -- "
                copyable="true"
                readonly="true"
                :value="formData.tax_file_numer.customer_input"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                v-if="formData.tax_file_numer.call_agent_input != null"
                label="Tax File Number"
                :value="formData.tax_file_numer.call_agent_input"
                styles="mt-5"
                :hide="!formData.tax_file_numer.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <p>PEP Declaration</p>
            <div>
              <!--          New way of displaying pep details - start -->
              <InputLabel
                label="Are You or Your Close Relative a Politically Exposed Person?"
                copyable="true"
                readonly="true"
                :value="
                  GlobalFunctions.getPepSelectionDisplayValue(
                    formData.pep_declaration
                  )
                "
                styles="mt-4"
                class="non-interactable-input"
              />

              <AgentInputField copyable="true"
                v-if="
                  GlobalFunctions.showAgentChangedPepSelection(
                    formData.pep_declaration
                  )
                "
                label="Are You or Your Close Relative a Politically Exposed Person?"
                :value="
                  GlobalFunctions.getPepSelectionDisplayValue(
                    formData.pep_declaration,
                    true
                  )
                "
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <br />
            <div
              v-if="
                !formData.pep_declaration.customer_input.not_politically_exposed
              "
            >
              <div
                class="columns is-7 is-variable"
                v-for="(pep_detail, index) in formData.pep_declaration
                  .customer_input.pep_person_details"
                :key="index"
              >
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Name"
                    readonly="true"
                    :value="pep_detail.name"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Designation"
                    readonly="true"
                    :value="pep_detail.designation"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>
            <div v-if="formData.pep_declaration.is_changed_by_call_agent">
              <div
                class="columns is-7 is-variable"
                v-for="(pep_detail, index) in formData.pep_declaration
                  .call_agent_input.pep_person_details"
                :key="index"
              >
                <div class="column">
                  <AgentInputField copyable="true"
                    v-if="
                      GlobalFunctions.showAgentChangedPepDetail(
                        formData.pep_declaration,
                        index,
                        'name'
                      )
                    "
                    label="Name"
                    :value="pep_detail.name"
                    styles="mt-5"
                    :hide="!formData.pep_declaration.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <AgentInputField copyable="true"
                    v-if="
                      GlobalFunctions.showAgentChangedPepDetail(
                        formData.pep_declaration,
                        index,
                        'designation'
                      )
                    "
                    label="Designation"
                    :value="pep_detail.designation"
                    styles="mt-5"
                    :hide="!formData.pep_declaration.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <p>Financial Details</p>

            <!-- source of funds - customer inputs -->

            <DisplayBulletList
              :copyable=true
              label="Source of Funds"
              :items="formData.source_of_funds.customer_input"
              :is-agent="false"
            />

            <!-- source of funds - agent inputs -->
            <DisplayBulletList
              :copyable=true
              v-if="formData.source_of_funds.call_agent_input != null"
              label="Source of Funds"
              :items="formData.source_of_funds.call_agent_input"
              :is-agent="true"
            />

            <br />
            <InputLabel
              v-if="formData.source_of_funds_other.customer_input"
              copyable="true"
              label="Please Specify"
              readonly="true"
              :value="formData.source_of_funds_other_description.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              label="Please Specify"
              :value="
                formData.source_of_funds_other_description.call_agent_input
              "
              styles="mt-5"
              :hide="
                !formData.source_of_funds_other_description
                  .is_changed_by_call_agent
              "
              readonly="true"
              class="non-interactable-input"
            />
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <p>Branch Details</p>
            <InputLabel
              v-if="customer_branch.branch_name"
              copyable="true"
              label="Preferred Branch"
              placeholder=" -- No data -- "
              readonly="true"
              :value="customer_branch.branch_name"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputField copyable="true"
              v-if="agent_branch != null"
              label="Preferred Branch"
              :value="agent_branch.branch_name"
              styles="mt-5"
              :hide="!formData.branch_id.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <br />
            <InputLabel
              v-if="formData.branch_comment.customer_input != ''"
              copyable="true"
              label=" Reason for opening an account in a branch that is located far from the permanent address"
              readonly="true"
              :value="formData.branch_comment.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />

            <AgentInputField copyable="true"
              v-if="formData.branch_comment.is_changed_by_call_agent"
              label="Reason for opening an account in a branch that is located far from the permanent address"
              :value="formData.branch_comment.call_agent_input"
              styles="mt-5"
              readonly="true"
              class="non-interactable-input"
            />

            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <!-- Product Selection -->

            <div v-for="product in products" :key="product.id">
              <p>
                Product Selection -
                <span>{{
                  product.account_type.is_changed_by_call_agent
                    ? product.account_type.call_agent_input
                    : product.account_type.customer_input
                }}</span>
              </p>
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <InputLabel
                    v-if="product.currency_type.customer_input"
                    copyable="true"
                    label="Currency"
                    placeholder=" -- No data -- "
                    readonly="true"
                    :value="product.currency_type.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Currency"
                    :value="product.currency_type.call_agent_input"
                    styles="mt-5"
                    :hide="!product.currency_type.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <!-- saving_account_type -->
                <div class="column">
                  <InputLabel
                    v-if="product.account_type.customer_input"
                    copyable="true"
                    label="Account"
                    placeholder=" -- No data -- "
                    readonly="true"
                    :value="product.account_type.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Account"
                    :value="product.account_type.call_agent_input"
                    styles="mt-5"
                    :hide="!product.account_type.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <!-- Purpose of Account Opening -->
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <!-- purpose of opening account - customer inputs -->

                  <DisplayBulletList
                    :copyable=true
                    label="Purpose of Account Opening"
                    :items="product.purpose_of_opening_customer_inputs"
                    :is-agent="false"
                  />

                  <!-- purpose of opening account - agent inputs -->
                  <DisplayBulletList
                    :copyable=true
                    v-if="
                      product.purpose_of_opening_account
                        .is_changed_by_call_agent
                    "
                    label="Purpose of Account Opening"
                    :items="product.purpose_of_opening_agent_inputs"
                    :is-agent="true"
                  />

                  <!-- OTHER SPECIFIED  -->
                  <InputLabel
                    v-if="
                      product.purpose_of_opening_other_specify_customer_inputs !==
                      ''
                    "
                    label="Please Specify the Purpose"
                    copyable="true"
                    readonly="true"
                    :value="
                      product.purpose_of_opening_other_specify_customer_inputs
                    "
                    styles="mt-4"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Please Specify the Purpose"
                    :value="
                      product.purpose_of_opening_other_specify_agent_inputs
                    "
                    styles="mt-5"
                    :hide="
                      product.purpose_of_opening_other_specify_agent_inputs ==
                      ''
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <!-- Anticipated Volumes of Deposits from above Sources -->

              <InputLabel
                v-if="product.anticipated_volume.customer_input"
                label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                placeholder=" -- No data -- "
                copyable="true"
                readonly="true"
                :value="product.anticipated_volume.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                :value="product.anticipated_volume.call_agent_input"
                styles="mt-5"
                :hide="!product.anticipated_volume.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
              <!-- Initial Deposit -->
              <InputLabel
                label="Initial Deposit"
                copyable="true"
                placeholder=" -- No data -- "
                readonly="true"
                :value="product.initial_deposit_amount.customer_input"
                styles="mt-5"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Initial Deposit"
                :value="product.initial_deposit_amount.call_agent_input"
                styles="mt-5"
                :hide="!product.initial_deposit_amount.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>

            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <!-- Value Added Services -->
            <p>Value Added Services</p>
            <div class="columns is-7 is-variable mt-1">
              <div class="column">
                <InputLabel
                  label="SMS Alerts"
                  copyable="true"
                  readonly="true"
                  :value="formData.vas_sms_alert.customer_input ? 'Yes' : 'No'"
                  class="non-interactable-input"
                />
              </div>

              <div class="column">
                <InputLabel
                  label="Online Banking"
                  copyable="true"
                  readonly="true"
                  :value="
                    formData.vas_online_banking.customer_input ? 'Yes' : 'No'
                  "
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable">
              <div class="column is-half" v-if="formData.residentship.customer_input == 'Sri Lanka'">
                <InputLabel
                  label="Virtual Wallet"
                  copyable="true"
                  readonly="true"
                  :value="formData.vas_virtual_wallet.customer_input ? 'Yes' : 'No'"
                  class="non-interactable-input"
                />
              </div>

              <div class="column is-half">
                <InputLabel
                  label="E-Statement"
                  copyable="true"
                  readonly="true"
                  :value="formData.vas_estatement.customer_input ? 'Yes' : 'No'"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <!--  debit card -->
            <div
              v-if="
                GlobalFunctions.checkDebitCardSectionVisible(
                  formData.residentship,
                  products
                )
              "
            >
              <div class="columns is-7 is-variable">
                <div class="column">
                  <InputLabel
                    label="Debit Card"
                    copyable="true"
                    readonly="true"
                    :value="
                      formData.vas_debit_card.customer_input ? 'Yes' : 'No'
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Debit Card"
                    :value="
                      formData.vas_debit_card.call_agent_input ? 'Yes' : 'No'
                    "
                    styles="mt-5"
                    :hide="!formData.vas_debit_card.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    label="POS Required"
                    readonly="true"
                    copyable="true"
                    :value="
                      formData.vas_debit_card_pos_required.customer_input
                        ? 'Yes'
                        : 'No'
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="POS Required"
                    :value="
                      formData.vas_debit_card_pos_required.call_agent_input
                        ? 'Yes'
                        : 'No'
                    "
                    styles="mt-5"
                    :hide="
                      !formData.vas_debit_card_pos_required
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <!-- If costomer POS REQUIRED TRUE THIS WILL SHOW-->

                <div class="column">
                  <InputLabel
                    v-if="formData.vas_debit_card_pos_account.customer_input"
                    label="Account to be Linked for POS Transaction"
                    copyable="true"
                    readonly="true"
                    placeholder=" -- No data -- "
                    :value="formData.vas_debit_card_pos_account.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    label="Account to be Linked for POS Transaction"
                    :value="
                      formData.vas_debit_card_pos_account.call_agent_input
                    "
                    styles="mt-5"
                    :hide="
                      !formData.vas_debit_card_pos_account
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>

              <InputLabel
                v-if="formData.vas_debit_card_name.customer_input"
                placeholder=" -- No data -- "
                label="Name to be Printed on Card"
                copyable="true"
                readonly="true"
                :value="formData.vas_debit_card_name.customer_input"
                styles="mt-5"
                class="non-interactable-input"
              />
              <AgentInputField copyable="true"
                label="Name to be Printed on Card"
                :value="formData.vas_debit_card_name.call_agent_input"
                styles="mt-5"
                :hide="!formData.vas_debit_card_name.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
              <!-- {{ formData.vas_debit_card_collection_details.is_changed_by_call_agent + "4" }}
              <br />
              {{ formData.vas_debit_card_collection_details.call_agent_input }} -->
              <div class="columns is-7 is-variable mt-3">
                <div class="column">
                  <InputLabel
                    v-if="
                      formData.vas_debit_card_collection_details.customer_input
                    "
                    placeholder=" -- No data -- "
                    copyable="true"
                    label="Card Delivery Details"
                    readonly="true"
                    :value="
                      formData.vas_debit_card_collection_details.customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    v-if="
                      formData.vas_debit_card_collection_details
                        .is_changed_by_call_agent
                    "
                    label="Card Delivery Details"
                    :value="
                      formData.vas_debit_card_collection_details
                        .call_agent_input
                    "
                    styles="mt-5"
                    :hide="
                      !formData.vas_debit_card_collection_details
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <InputLabel
                    v-if="
                      formData.vas_debit_card_pin_collection_details
                        .customer_input
                    "
                    label="PIN Delivery Details"
                    copyable="true"
                    readonly="true"
                    :value="
                      formData.vas_debit_card_pin_collection_details
                        .customer_input
                    "
                    class="non-interactable-input"
                  />
                  <AgentInputField copyable="true"
                    v-if="
                      formData.vas_debit_card_pin_collection_details
                        .is_changed_by_call_agent
                    "
                    label="PIN Delivery Details"
                    :value="
                      formData.vas_debit_card_pin_collection_details
                        .call_agent_input
                    "
                    styles="mt-5"
                    :hide="
                      !formData.vas_debit_card_pin_collection_details
                        .is_changed_by_call_agent
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />
            <!-- Video Call Details -->
            <p>Video Call Details</p>
            <div class="columns is-7 is-variable mt-1">
              <div class="column">
                <InputLabel
                  label="Preferred Date"
                  readonly="true"
                  copyable="true"
                  :value="getFormattedDateTime(formData.video_call_date)"
                  class="non-interactable-input"
                />
              </div>
              <div class="column">
                <InputLabel
                  label="Preferred Time"
                  copyable="true"
                  readonly="true"
                  :value="formData.video_call_time"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <div
              class="columns is-7 is-variable mt-1"
              v-if="
                videoCallDetail !== null &&
                videoCallDetail.selfie_image !== null
              "
            >
              <div class="column">
                <InputLabel
                  label="Date of Account Funding"
                  copyable="true"
                  readonly="true"
                  :value="
                    getFormattedDateTime(formData.date_of_account_funding)
                  "
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            /> -->
          </div>
          <!-- image part -->
          <div class="column">
            <!-- ================================================== PROOF IMAGES ID(F/B) / SELFI ================================================== -->
            <div>
              <div ref="checkDRPFirst"
                class="container"
                rounded
                style="
                  width: 32vw;
                  height: 68vh;
                  border: 0.3vh solid #000000;
                  border-radius: 25px;
                  padding: 20px;
                "
              >
                <div class="wrapper">
                  <div style="position: relative; z-index: 1">
                    <b-tabs
                      size="is-small"
                      type="is-toggle-rounded"
                      v-model="nicActiveTab"
                      position="is-centered"
                    >
                      <b-tab-item label="NIC Front Image">
                        <figure
                          style="
                            min-width: 28.2vw;
                            max-width: 28.2vw;
                            min-height: 56vh;
                            max-height: 56vh;
                          "
                        >
                          <div class="wrapper">
                            <img
                              :src="button"  v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                              @click="idfRotateClockwise"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                                cursor: pointer;
                              "
                            />
                            <b-button v-else rounded
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.nic_front_attachment.attachment_url, 'NIC Front')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>
                            <div
                              style="
                                height: 56vh;
                                width: 28.2vw;
                                text-align: center;
                              "
                              :style="{
                                transform: 'rotate(' + idfRotateAngle + 'deg)',
                              }"
                            >
                              <span class="helper"></span>
                                <img v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                                  :src="formData.nic_front_attachment.attachment_url"
                                  @error="imageplaceholder"
                                  alt="NIC Front"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                                <img v-else
                                  :src="pdfPlaceholder"
                                  @error="imageplaceholder"
                                  alt="NIC Front"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                            </div>
                          </div>
                        </figure>
                      </b-tab-item>

                      <b-tab-item label="NIC Rear Image">
                        <figure
                          style="
                            min-width: 28.2vw;
                            max-width: 28.2vw;
                            min-height: 56vh;
                            max-height: 56vh;
                          "
                        >
                          <div class="wrapper">
                            <img v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                              :src="button"
                              @click="idbRotateClockwise"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                                cursor: pointer;
                              "
                            />
                            <b-button v-else rounded
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.nic_back_attachment.attachment_url, 'NIC Rear')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>

                            <div
                              style="
                                height: 56vh;
                                width: 28.2vw;
                                text-align: center;
                              "
                              :style="{
                                transform: 'rotate(' + idbRotateAngle + 'deg)',
                              }"
                            >
                              <span class="helper"></span>
                              <img v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                                :src="formData.nic_back_attachment.attachment_url"
                                @error="imageplaceholder"
                                alt="NIC Rear"
                                style="vertical-align: middle; max-height: 55vh"
                              />
                              <img v-else
                                  :src="pdfPlaceholder"
                                  @error="imageplaceholder"
                                  alt="NIC Rear"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                            </div>
                          </div>
                        </figure>
                      </b-tab-item>

                      <b-tab-item label="Selfie Image">
                        <figure
                          style="
                            min-width: 28.2vw;
                            max-width: 28.2vw;
                            min-height: 56vh;
                            max-height: 56vh;
                          "
                        >
                          <div class="wrapper">
                            <div
                              style="
                                height: 56vh;
                                width: 28.2vw;
                                text-align: center;
                              "
                            >
                              <span class="helper"></span>
                              <img v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
                                  :src="formData.selfie_attachment.attachment_url"
                                  @error="imageplaceholder"
                                  alt="Selfie"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                              <img v-else
                                  :src="pdfPlaceholder"
                                  @error="imageplaceholder"
                                  alt="Selfie"
                                  style="vertical-align: middle; max-height: 55vh"
                                />



                            </div>
                            <!-- <div> -->

                            <!-- </div> -->
                          </div>


                        </figure>
                      </b-tab-item>
                    </b-tabs>
                  </div>
                </div>
              </div>

              <div style="margin-top: 20px">
                <!-- NIC OCR RESULT-->
                <b-button type="is-primary"
                          class="attachment-btn"
                          size="is-medium"
                          label="View NIC OCR Result"
                          icon-left="ocr"
                          @click="()=>{showNicOcrResult = true}"
                />

                <!-- NIC OCR result component -->
                <nic-ocr-result
                    v-if="showNicOcrResult"
                    :nic-rear="nicRearOcrResult"
                    :nic-front="nicFrontOcrResult"
                    :kyc-form="formData"
                    @close="()=>{showNicOcrResult = false}"/>
              </div>

              <div
                style="margin-top: 1%;"
                v-if="hasPermission('view-new-application-pool') && !reviewOnly"
              >
                <div class=columns>
                  <div class=column v-if="drpAttempt == null || drpAttempt.is_manually_verified === false">
                    <CheckDRPNewVue
                      :formData = formData
                      @update_DRP_internal_comment="update_DRP_internal_comment"
                      @DRP_success="DRP_success"
                      @DRP_Check_Failed_fail="DRP_Check_Failed_fail"
                      @DRP_fail="DRP_fail">
                    </CheckDRPNewVue>
                  </div>
                  <div class=column v-if="drpAttempt == null || drpAttempt.is_manually_verified === false">
                    <b-button @click=showModel() type="is-primary">Add DRP Data Manually</b-button>
                  </div>
                  <div class="column"  v-if="drpAttempt !== null && drpAttempt.is_manually_verified === true">
                    <b-button size="is-medium" class="attachment-btn" @click=showManualDRPModel() type="is-primary">
                      Show Manual DRP Data
                    </b-button>
                  </div>
                </div>
                <!-- <label>Add DRP details manually</label>
                <button type="button" class="delete" @click="manualDRP = false"/> -->
                <b-modal
                  v-model="manualDRP"
                  has-modal-card
                  trap-focus
                  :destroy-on-hide="false"
                  aria-role="dialog"
                  aria-label="Example Modal"
                  :can-cancel=false
                  aria-modal>
                  <div >
                      <form action="">
                        <div class="card manual-drp-model" style="max-height: 90vh;">
                            <div style="width:100%">
                              <div class="flex-container">
                                <div style="text-align: center; flex-grow: 1;">
                                  <label style="color: #04c3fc; font-size: x-large;">Add DRP Data Manually</label>
                                </div>
                                <div style="text-align: right;">
                                  <button type="button" class="delete" @click="closeManualDRPModel()"/>
                                </div>
                              </div>
                            </div>
                            <hr style="background-color: gray; height: 1.8px; opacity: 0.5" />
                            <div class="body manual-drp-model-body">
                              <div style="text-align: center; font-weight: lighter; margin-bottom: 20px ">
                                <span>Attach the Image with DRP data</span>
                              </div>
                              <div style="margin-left: auto; margin-right: auto; max-width:20vw;">
                                <ImageCaptureComponent
                                  @on-captured="onDRPCaptured" :crop_ratio="null"
                                  file_name="nic_front_image"
                                  :selectedImageURL="DRPImage"
                                  uploadButtonLabel=Upload
                                  />
                              </div>

                              <ValidationProvider :rules="{required:false}" v-slot="{ errors }">
                                <div style="text-align:center">
                                  <label>Internal Comment</label>
                                    <b-input
                                      style="max-width:25vw"
                                      rounded
                                      :rows=3
                                      type="textarea"
                                      placeholder="Internal Comment"
                                      v-model="manualDRPComment"
                                    >
                                    </b-input>
                                </div>

                                <!-- <p class="help is-danger" v-if="errors.length">{{ errors[0] }}</p> -->
                                <p class="help is-danger" v-if="errors">{{ errors[0] }}</p>
                              </ValidationProvider>

                            </div>
                            <br>
                            <b-field grouped position="is-centered">
                              <div style="margin-left: auto; margin-right: auto; text-align: center;">
                                <div class="columns">
                                  <div class="column">
                                    <b-button style="width:10vw" type="is-danger" @click="Send_DRP_Status('fail')">Fail</b-button>
                                  </div>
                                  <div class="column">
                                    <b-button style="width:10vw" type="is-success" @click="Send_DRP_Status('pass')">Success</b-button>
                                  </div>
                                </div>
                              </div>
                            </b-field>
                        </div>
                      </form>
                  </div>
                </b-modal>


                <b-modal
                  v-model="showmanualDRPdata"
                  has-modal-card
                  trap-focus
                  :destroy-on-hide="false"
                  aria-role="dialog"
                  aria-label="Example Modal"
                  :can-cancel=false
                  aria-modal>
                  <div>
                      <form action="">
                        <div class="card manual-drp-model" style="max-height: 90vh;">
                            <div>
                              <div class="flex-container">
                                <div style="text-align: center; flex-grow: 1;">
                                  <label style="color: #04c3fc; font-size: x-large;">Manually added DRP data</label>
                                </div>
                                <div style="text-align: right;">
                                  <button type="button" class="delete" @click="showmanualDRPdata = false"/>
                                </div>
                              </div>
                            </div>
                            <hr style="background-color: gray; height: 1.8px; opacity: 0.5" />
                            <br>
                            <b-field grouped position="is-centered" v-if="drpAttempt != null">
                              <div style="margin-left: auto; margin-right: auto; text-align: center;">
                                <div class="columns" v-if="drpAttempt.attachment">
                                  <ImageBox
                                    style="width:60%; margin:auto"
                                    label="DRP Data Document"
                                    :imageName="drpAttempt.attachment"
                                  />
                                </div>
                                <b-message :type="formData.drp_status === true ? 'is-success' : 'is-danger'">
                                  DRP Status : {{ GlobalFunctions.getCheckedDrpStatusResultDisplayValue(formData.drp_status) }}
                                </b-message>
                                <div >
                                  <div class="is-7" v-for="reports in drpArray" style="margin-top: 20; margin-left: auto; margin-right: auto ;">
                                    <InputLabel class="mt-3"
                                      v-if="reports.comment !=''"
                                      copyable="true"
                                      label="DRP Internal Comment"
                                      readonly="true"
                                      type="textarea"
                                      placeholder="-- No Data --"
                                      :value="reports.comment"
                                    />
                                  </div>
                                </div>
                              </div>
                            </b-field>
                        </div>
                      </form>
                  </div>
                </b-modal>

                <!-- <div v-if="DRP_showMessageBox">
                  <div id="loading" class="fullscreen">
                    <CheckDRP
                      :customer_data="DRPCustomerData"
                      :drp_data="DRPdata"
                      @update_DRP_internal_comment="update_DRP_internal_comment"
                      @DRP_success="DRP_success"
                      @DRP_fail="DRP_fail"
                      @Closs_DRP="Closs_DRP"
                    >
                    </CheckDRP>
                  </div>
                </div> -->
              </div>
              <div style="margin-top: 1%; margin-left: 2%">

                <div v-if="drpAttempt !== null">
                  <b-message :type="formData.drp_status === true ? 'is-success' : 'is-danger'">
                    DRP Status : {{ GlobalFunctions.getCheckedDrpStatusResultDisplayValue(formData.drp_status) }}
                  </b-message>
                </div>
              </div>
            </div>
            <br />

            <!-- PEP Sanction List Results-->
             <div style="margin-top: 20px">
                <b-button type="is-primary"
                          class="attachment-btn"
                          size="is-medium"
                          label="View PEP and Sanction Result"
                          icon-left="pep"
                          @click=" getPepSanctionData"

                />

                  <!-- PEP Sanction List Component-->
                <PepSanctionListResults
                    v-if="showPepSanctionResult"
                    :pepData="pepData"
                    :sanctionData="sanctionData"
                    :customerRiskRatingData="customerRiskRatingData"
                    :fullName="formData.full_name.customer_input"
                    :currentDate="currentDate"
                    @close="()=>{showPepSanctionResult = false}"/>
              </div>

              <!-- AMS List Results-->
             <div style="margin-top: 20px">
                <b-button type="is-primary"
                          class="attachment-btn"
                          size="is-medium"
                          label="View AMS Results"
                          icon-left="pep"
                          @click=" getAMSData"

                />

                  <!-- AMS List Component-->
                <AmsResults
                    v-if="showAmsResult"
                    :amsData="amsData"
                    :fullName="formData.full_name.customer_input"
                    :currentDate="currentDate"
                    @close="()=>{showAmsResult = false}"/>
              </div>




            <!-- ================================================== PROOF PASSPORT BIO / ALTERATION / STAMP ================================================== -->
            <div v-if="formData.passport_bio_page_attachment.attachment_type != ''">
              <div style="text-align: center">
                <label>Passport Images</label>
              </div>

              <div
                class="container"
                rounded
                style="
                  min-width: 32vw;
                  max-width: 32vw;
                  min-height: 68vh;
                  max-height: 68vh;
                  border: 0.3vh solid #000000;
                  border-radius: 25px;
                  padding: 20px;
                "
              >
                <div class="wrapper">
                  <div style="position: relative; z-index: 1">
                    <b-tabs
                      size="is-small"
                      type="is-toggle-rounded"
                      v-model="passportActiveTab"
                      position="is-centered"
                    >
                      <b-tab-item label="Bio Page">
                        <figure
                          style="
                            min-width: 28.2vw;
                            max-width: 28.2vw;
                            min-height: 56vh;
                            max-height: 56vh;
                          "
                        >
                          <div class="wrapper">
                            <img v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                              :src="button"
                              @click="ppbioRotateClockwise"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                                cursor: pointer;
                              "
                            />
                            <b-button v-else rounded
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.passport_bio_page_attachment.attachment_url, 'passport_bio_page')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>
                            <div
                              style="
                                height: 56vh;
                                width: 28.2vw;
                                text-align: center;
                              "
                              :style="{
                                transform:
                                  'rotate(' + ppbioRotateAngle + 'deg)',
                              }"
                            >
                              <span class="helper"></span>
                              <img v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                                :src="formData.passport_bio_page_attachment.attachment_url"
                                @error="imageplaceholder"
                                alt="Passport Bio Page"
                                style="vertical-align: middle; max-height: 55vh"
                              />
                              <img v-else
                                  :src="pdfPlaceholder"
                                  @error="imageplaceholder"
                                  alt="Passport Bio Page"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                            </div>
                          </div>
                        </figure>
                      </b-tab-item>

                      <b-tab-item label="Alteration Page">
                        <figure
                          style="
                            min-width: 28.2vw;
                            max-width: 28.2vw;
                            min-height: 56vh;
                            max-height: 56vh;
                          "
                        >
                          <div class="wrapper">
                            <img v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                              :src="button"
                              @click="ppaltRotateClockwise"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                                cursor: pointer;
                              "
                            />
                            <b-button v-else rounded
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.passport_alteration_attachment.attachment_url, 'passport_alteration')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>
                            <div
                              style="
                                height: 54vh;
                                width: 28.2vw;
                                text-align: center;
                              "
                              :style="{
                                transform:
                                  'rotate(' + ppaltRotateAngle + 'deg)',
                              }"
                            >
                              <span class="helper"></span>
                              <img v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                                :src="formData.passport_alteration_attachment.attachment_url"
                                @error="imageplaceholder"
                                alt="Passport Alteration Page"
                                style="vertical-align: middle; max-height: 55vh"
                              />
                              <img v-else
                                  :src="pdfPlaceholder"
                                  @error="imageplaceholder"
                                  alt="Passport Alteration Page"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                            </div>
                          </div>
                        </figure>
                      </b-tab-item>

                      <b-tab-item label="Stamp Page">
                        <figure
                          style="
                            min-width: 28.2vw;
                            max-width: 28.2vw;
                            min-height: 56vh;
                            max-height: 56vh;
                          "
                        >
                          <div class="wrapper">
                            <img v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                              :src="button"
                              @click="ppsRotateClockwise"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                                cursor: pointer;
                              "
                            />
                            <b-button v-else rounded
                              icon-right=download-circle-outline type=is-primary
                              size=is-large
                              @click="downloadPdf(formData.passport_entry_stamp_attachment.attachment_url, 'passport_entry_stamp')"
                              style="
                                width: 50px;
                                position: absolute;
                                z-index: 2;
                                margin-left: 25vw;
                                margin-top: 45vh;
                                display: flex;
                              "></b-button>

                            <div
                              style="
                                height: 54vh;
                                width: 28.2vw;
                                text-align: center;
                              "
                              :style="{
                                transform: 'rotate(' + ppsRotateAngle + 'deg)',
                              }"
                            >
                              <span class="helper"></span>
                              <img v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                                :src="formData.passport_entry_stamp_attachment.attachment_url"
                                @error="imageplaceholder"
                                alt="Passport Stamp Page Image"
                                style="vertical-align: middle; max-height: 55vh"
                              />
                              <img v-else
                                  :src="pdfPlaceholder"
                                  @error="imageplaceholder"
                                  alt="Passport Stamp Page Image"
                                  style="vertical-align: middle; max-height: 55vh"
                                />
                            </div>
                          </div>
                        </figure>
                      </b-tab-item>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>

            <!-- visa image  -->
            <br v-if="formData.visa_attachment.attachment_url != ''" />
            <div
              v-if="formData.visa_attachment.attachment_url != ''"
              style="text-align: center"
            >
              <label>Visa image</label>
            </div>

            <div
              v-if="formData.visa_attachment.attachment_url != ''"
              class="container"
              rounded
              style="
                min-width: 32vw;
                max-width: 32vw;
                min-height: 60vh;
                max-height: 60vh;
                border: 0.3vh solid #000000;
                border-radius: 25px;
                padding: 20px;
              "
            >
              <div class="wrapper">
                <figure
                  style="
                    min-width: 28.2vw;
                    max-width: 28.2vw;
                    min-height: 56vh;
                    max-height: 56vh;
                  "
                >
                  <img v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                    :src="button1"
                    @click="visarotateClockwise"
                    style="
                      width: 50px;
                      position: absolute;
                      z-index: 2;
                      margin-left: 26vw;
                      margin-top: 45vh;
                      display: flex;
                      cursor: pointer;
                    "
                  />
                  <b-button v-else rounded
                    icon-right=download-circle-outline type=is-primary
                    size=is-large
                    @click="downloadPdf(formData.visa_attachment.attachment_url, 'Visa')"
                    style="
                      width: 50px;
                      position: absolute;
                      z-index: 2;
                      margin-left: 25vw;
                      margin-top: 45vh;
                      display: flex;
                    "></b-button>
                  <div
                    style="height: 56vh; width: 28.2vw; text-align: center"
                    :style="{ transform: 'rotate(' + visarotateAngle + 'deg)' }"
                  >
                    <span class="helper"></span>
                    <img v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                      :src="formData.visa_attachment.attachment_url"
                      @error="imageplaceholder"
                      alt="Visa"
                      style="vertical-align: middle; max-height: 55vh"
                    />
                    <img v-else
                      :src="pdfPlaceholder"
                      @error="imageplaceholder"
                      alt="Visa"
                      style="vertical-align: middle; max-height: 55vh"
                    />
                  </div>
                </figure>
              </div>
            </div>

            <br />

            <div style="text-align: center">
              <label>Proof of address image</label>
            </div>

            <div
              class="container"
              rounded
              style="
                min-width: 32vw;
                max-width: 32vw;
                min-height: 60vh;
                max-height: 60vh;
                border: 0.3vh solid #000000;
                border-radius: 25px;
                padding: 20px;
              "
            >
              <div class="wrapper">
                <figure
                  style="
                    min-width: 28.2vw;
                    max-width: 28.2vw;
                    min-height: 56vh;
                    max-height: 56vh;
                  "
                >
                  <img v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                    :src="button1"
                    @click="addressrotateClockwise"
                    style="
                      width: 50px;
                      position: absolute;
                      z-index: 2;
                      margin-left: 26vw;
                      margin-top: 45vh;
                      display: flex;
                      cursor: pointer;
                    "
                  />
                  <b-button v-else rounded
                    icon-right=download-circle-outline type=is-primary
                    size=is-large
                    @click="downloadPdf(formData.address_proof_attachment.attachment_url, 'Proof of address')"
                    style="
                      width: 50px;
                      position: absolute;
                      z-index: 2;
                      margin-left: 25vw;
                      margin-top: 45vh;
                      display: flex;
                    "></b-button>
                  <div
                    style="height: 56vh; width: 28.2vw; text-align: center"
                    :style="{
                      transform: 'rotate(' + addressrotateAngle + 'deg)',
                    }"
                  >
                    <span class="helper"></span>
                    <img v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                      style="vertical-align: middle; max-height: 55vh"
                      :src="formData.address_proof_attachment.attachment_url"
                      @error="imageplaceholder"
                      resposive
                      alt="Proof of address Image"
                    />
                    <img v-else
                      :src="pdfPlaceholder"
                      @error="imageplaceholder"
                      alt="Proof of address Image"
                      style="vertical-align: middle; max-height: 55vh"
                    />
                  </div>
                </figure>
              </div>
            </div>

            <text-display-component
                label="Type"
                :value="GlobalFunctions.getOcrBillType(formData.ocr_address_proof_result.bill_type)"
                v-if="formData.address_proof_attachment.attachment_type === 'image'"/>
            <text-display-component
                label="OCR Address Data"
                :value="formData.ocr_address_proof_result.address"
                v-if="formData.address_proof_attachment.attachment_type === 'image'"/>

            <br />

            <!--================================= ADD ATTACHMENTS UPTO 5 =================================-->
            <!-- <b-button @click ="showAttachmentModel = true">Add Attachments</b-button> -->
            <div
              class="container"
              style="
                width: 30vw;
                height: 8vh;
                border-radius: 15px;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
              "
            >

              <b-button type="is-primary"
                  class="attachment-btn"
                  size="is-medium"
                  label="Attachments"
                  icon-left="paperclip"
                  @click="showAttachmentModel =true"
              />
            </div>
            <div v-if=showAttachmentModel id="loading" class="fullscreen">
              <Attachments
              :applicationId = authStore.kyc_form_id
              :authId = authStore.loggedInUser
              :currentStatus = formData.current_status
              :reviewOnly = reviewOnly
              @Closs_Att="Closs_Att"/>
            </div>
            <!--                v-if="videoCallDetail.face_and_nic_front_side_image != null"-->
            <div  v-if="videoCallDetail != null"
                class="container"
                style="
                width: 30vw;
                height: 8vh;
                border-radius: 15px;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
              "
            >

              <b-button v-if="videoCallDetail.video_call_recording != null" type="is-primary"
                        class="attachment-btn"
                        size="is-medium"
                        label="Random Questionnaire"
                        @click="showRandomQuestionnaireModel =true"
              />
            </div>

            <div>
              <div v-if=showRandomQuestionnaireModel  id="loading" class="fullscreen">
                <VideoCallRandomQuestionnaireResult
                    :applicationId = authStore.kyc_form_id
                    @Close_Random_Question_Model="Close_Random_Question_Model"/>
              </div>
            </div>
            <br>

            <!-- show selfie liveness video start -->
            <br />

            <div class="container"  >
              <div style="text-align: center">
              <label>Selfie Liveness</label>
            </div>
            <div v-if="formData.selfie_liveness !== null">
                <b-message :type="formData.selfie_liveness.result == 'real' ? 'is-success' : 'is-danger'">
                                  Selfie Liveness : {{ GlobalFunctions.getRoundedValue(formData.selfie_liveness.score * 100) }} %
                                </b-message>
                </div>
                <br>
              <div
                class="card"
                v-if="formData.face_liveness_video_recording != null"
              >
                <video
                  ref="videoPlayer"
                  controls
                  preload="auto"
                  disablePictureInPicture
                  :src="formData.face_liveness_video_recording"
                  style="
                    width: 100%;
                    border-radius: 25px;
                    border: 2px solid #ff6666;
                  "
                  v-once
                  onloadmetadata="this.parentNode.querySelector('.videoDuration').textContent = this.duration.toFixed(2)"
                ></video>
                <br />
                <!-- <b-button
                  v-if="!reviewOnly"
                  class="is-primary"
                  style="width: 100%"
                  @click="downloadVideo"
                >
                  Download
                </b-button> -->
              </div>
            </div>
            <!-- show selfie liveness video end  -->


            <!-- START OF VIDEO VIEW & DOWNLOAD-->
            <br />
            <div class="container" v-if="(videoCallDetail != null) && (authStore.loggedInUser.role != 'Branch User')">
              <div
                class="card"
                v-if="videoCallDetail.video_call_recording != null"
              >
                <video
                  ref="videoPlayer"
                  controls
                  preload="auto"
                  disablePictureInPicture
                  :src="videoCallDetail.video_call_recording"
                  style="
                    width: 100%;
                    border-radius: 25px;
                    border: 2px solid #ff6666;
                  "
                  v-once
                  onloadmetadata="this.parentNode.querySelector('.videoDuration').textContent = this.duration.toFixed(2)"
                ></video>
                <br />
                <b-button
                  v-if="!reviewOnly"
                  class="is-primary"
                  style="width: 100%"
                  @click="downloadVideo"
                >
                  Download
                </b-button>
              </div>
            </div>
            <!-- END OF VIDEO VIEW & DOWNLOAD-->
          </div>
        </div>

        <hr
          :style="{
            'background-color': '#1b1b1b',
            height: '1.8px',
            opacity: '0.5',
            'margin-inline': '4px',
          }"
        />

        <div class="columns is-7 is-variable">
          <div class="column">
            <!-- Signature image -->
            <ImageBox
              label="Signature image"
              :imageName="formData.signature_image"
            />
            <!-- <b-message type="is-info" has-icon class="mt-5">
              Duration to complete form :
            </b-message> -->
          </div>
          <div class="column" style="margin-top: 25px">
            <ShowMapLocation
              v-if="showCustomerLocation"
              :position="kycFormCustomerLocation"
              styles="height: 400px"
            />
            <b-message
                style="margin-top: 10px"
                type="is-info"
                has-icon
                v-if="formData.reverse_geocoded_customer_location !== ''">
              <b>Geo-Location Address: </b>
              {{formData.reverse_geocoded_customer_location}}
            </b-message>
          </div>
        </div>
        <hr
          :style="{
            'background-color': '#1b1b1b',
            height: '1.8px',
            opacity: '0.5',
            'margin-inline': '4px',
          }"
        />

        <!--  =========================== CHECK IF APPLICATION IS "NEW" , "SUBMITTED-CALL-AGENT" , "else (AFTER CONDUCTING VIDEO CALL)"=========================== -->

        <div v-if="videoCallDetail">
          <div v-if="videoCallDetail.call_duration != 0">
            <!-- Face Comparisons -->
            <p class="has-text-danger">Face Comparisons</p>
            <div class="columns is-7 is-variable">
              <div class="column">
                <ImageBox v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
                  label="User uploaded selfie image"
                  :imageName="formData.selfie_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded selfie image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column" v-if="videoCallDetail.selfie_image != null">
                <ImageBox
                  label="Selfie image from video call"
                  :imageName="videoCallDetail.selfie_image.path"
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="videoCallDetail.selfie_image.rating"
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>

            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <div v-if="videoCallDetail.face_and_nic_front_side_image != null">
              <!-- User Uploads and Video Call Data Comparison -->
              <p class="has-text-danger">
                User Uploads and Video Call Data Comparison
              </p>
              <p class="is-uppercase">NIC / Passport</p>
              <!-- NIC -->
              <div class="columns is-7 is-variable">
                <div class="column">
                  <ImageBox v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                    label="User uploaded NIC front image"
                    :imageName="formData.nic_front_attachment.attachment_url"
                    styles="mt-3"
                  />
                  <ImageBox v-else
                    label="User uploaded NIC front image"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                </div>
                <div class="column">
                  <ImageBox
                    label="NIC front image from video call"
                    :imageName="
                      videoCallDetail.face_and_nic_front_side_image.path
                    "
                    styles="mt-3"
                  />
                  <div class="rating-wrapper">
                    <label class="label">Satisfactory Level</label>
                    <b-rate
                      disabled
                      @click.native="warning()"
                      v-model="
                        videoCallDetail.face_and_nic_front_side_image.rating
                      "
                      style="margin-left: 150px"
                    >
                    </b-rate>
                  </div>
                </div>
              </div>

              <!-- NIC rear image from video call -->
              <div
                class="columns is-7 is-variable"
                v-if="videoCallDetail.face_and_nic_back_side_image != null"
              >
                <div class="column">
                  <ImageBox v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                    label="User uploaded NIC rear image"
                    :imageName="formData.nic_back_attachment.attachment_url"
                    styles="mt-3"
                  />
                  <ImageBox v-else
                    label="User uploaded NIC rear image"
                    :imageName="pdfPlaceholder"
                    styles="mt-3"
                  />
                </div>
                <div class="column">
                  <ImageBox
                    label="NIC rear image from video call"
                    :imageName="
                      videoCallDetail.face_and_nic_back_side_image.path
                    "
                    styles="mt-3"
                  />
                  <div class="rating-wrapper">
                    <label class="label">Satisfactory Level</label>
                    <b-rate
                      disabled
                      @click.native="warning()"
                      v-model="
                        videoCallDetail.face_and_nic_back_side_image.rating
                      "
                      style="margin-left: 150px"
                    >
                    </b-rate>
                  </div>
                </div>
              </div>
              <!-- ///////////////////////////////////nic comments ////////////////////////////////////////////////////////////////////////-->

              <div
                style="width: fit-content; max-width: 850px"
                v-if="videoCallDetail.nic_comments != null"
              >
                <div class="columns is-variable is-7">
                  <div
                    class="column is-three-fifths"
                    style="pointer-events: none"
                  >
                    <div
                      v-for="comment in videoCallDetail.nic_comments.comments"
                      :key="comment.comment_content"
                    >
                      <b-field style="width: fit-content">
                        <b-checkbox
                          type="is-primary"
                          v-model="comment.is_comment_selected"
                          @click.native="warning()"
                        >
                          {{ comment.comment_content }}
                        </b-checkbox>
                      </b-field>

                      <div v-if="comment.additional_comment != ''">
                        <b-field style="text-align: center">
                          <b-input
                            rounded
                            @click.native="warning()"
                            maxlength="200"
                            placeholder="Specify"
                            v-model="comment.additional_comment"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ////////////////nic comment end//////////////////////// -->
            </div>

            <!-- Passport -->
            <div
              v-if="videoCallDetail.face_and_passport_bio_page_image != null"
              class="columns is-7 is-variable"
            >
              <div
                class="column is-half"
                v-if="formData.passport_bio_page_attachment.attachment_type !== ''"
              >
                <ImageBox v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Passport bio page image"
                  :imageName="formData.passport_bio_page_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded Passport bio page image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column">
                <ImageBox
                  label="Passport bio page  image from video call"
                  :imageName="
                    videoCallDetail.face_and_passport_bio_page_image.path
                  "
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="
                      videoCallDetail.face_and_passport_bio_page_image.rating
                    "
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>

            <div
              class="columns is-7 is-variable"
              v-if="
                videoCallDetail.face_and_passport_alteration_page_image != null
              "
            >
              <div
                class="column"
                v-if="
                  formData.passport_alteration_attachment.attachment_type !== ''
                "
              >
                <ImageBox v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Passport alteration page image"
                  :imageName="formData.passport_alteration_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded Passport alteration page image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column">
                <ImageBox
                  label="Passport alteration page  image from video call"
                  :imageName="
                    videoCallDetail.face_and_passport_alteration_page_image.path
                  "
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="
                      videoCallDetail.face_and_passport_alteration_page_image
                        .rating
                    "
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>

            <div
              class="columns is-7 is-variable"
              v-if="
                videoCallDetail.face_and_passport_entry_stamp_page_image != null
              "
            >
              <div
                class="column"
                v-if="
                  formData.passport_entry_stamp_attachment.attachment_type !== ''
                "
              >
                <ImageBox v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Passport entry stamp page image"
                  :imageName="formData.passport_entry_stamp_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded Passport entry stamp page image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column">
                <ImageBox
                  label="Passport entry stamp page  image from video call"
                  :imageName="
                    videoCallDetail.face_and_passport_entry_stamp_page_image
                      .path
                  "
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="
                      videoCallDetail.face_and_passport_entry_stamp_page_image
                        .rating
                    "
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>
            <!-- ////////////////////passport comments start//////////////// -->
            <!-- <div v-if="videoCallDetail.face_and_passport_alteration_page_image || videoCallDetail.face_and_passport_entry_stamp_page_image"> -->
            <div v-if="videoCallDetail.passport_comments != null">
              <div relative style="width: fit-content; max-width: 1100px">
                <div class="columns is-variable is-7">
                  <div
                    class="column is-three-fifths"
                    style="pointer-events: none"
                  >
                    <div
                      v-for="comment in videoCallDetail.passport_comments
                        .comments"
                      :key="comment.comment_content"
                    >
                      <b-field style="width: fit-content">
                        <b-checkbox
                          type="is-primary"
                          v-model="comment.is_comment_selected"
                          @click.native="warning()"
                        >
                          {{ comment.comment_content }}
                        </b-checkbox>
                      </b-field>

                      <div v-if="comment.additional_comment != ''">
                        <b-field style="text-align: center">
                          <b-input
                            @click.native="warning()"
                            maxlength="200"
                            rounded
                            placeholder="Specify"
                            v-model="comment.additional_comment"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <!-- ////////////////////passport comments end//////////////// -->
            <p class="is-uppercase">Signature image</p>
            <div class="columns is-7 is-variable">
              <!-- Signature image -->

              <div class="column">
                <ImageBox
                  label="User uploaded Signature image"
                  :imageName="formData.signature_image"
                  styles="mt-3"
                />
              </div>
              <div
                class="column"
                v-if="videoCallDetail.signature_image != null"
              >
                <ImageBox
                  label="Signature image from video call"
                  :imageName="videoCallDetail.signature_image.path"
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="videoCallDetail.signature_image.rating"
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>

            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <p class="is-uppercase">address proof document</p>
            <div class="columns is-7 is-variable">
              <!-- address proof document -->

              <div class="column">
                <ImageBox v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                  label="User uploaded address proof document"
                  :imageName="formData.address_proof_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded address proof document"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div
                class="column"
                v-if="videoCallDetail.address_proof_document_image != null"
              >
                <ImageBox
                  label="Address proof document from video call"
                  :imageName="videoCallDetail.address_proof_document_image.path"
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="
                      videoCallDetail.address_proof_document_image.rating
                    "
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
            </div>

            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <!-- checkbox selection for vedio call review page -->
            <div class="check">
              <div class="columns is-7 is-variable">
                <div
                  class="column"
                  v-if="videoCallDetail.video_comments != null"
                >
                  <p class="mb-2">Video</p>
                  <div style="pointer-events: none">
                    <div
                      v-for="comment in videoCallDetail.video_comments.comments"
                      :key="comment.comment_content"
                    >
                      <b-field style="width: fit-content">
                        <b-checkbox
                          type="is-primary"
                          v-model="comment.is_comment_selected"
                          @click.native="warning()"
                        >
                          {{ comment.comment_content }}
                        </b-checkbox>
                      </b-field>

                      <div v-if="comment.additional_comment != ''">
                        <b-field style="text-align: center">
                          <b-input
                            @click.native="warning()"
                            maxlength="200"
                            rounded
                            placeholder="Specify"
                            v-model="comment.additional_comment"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Audio call verification -->

                <div
                  class="column"
                  v-if="videoCallDetail.audio_comments != null"
                >
                  <p class="mb-2">Audio call verification</p>
                  <div style="pointer-events: none">
                    <div
                      v-for="comment in videoCallDetail.audio_comments.comments"
                      :key="comment.comment_content"
                    >
                      <b-field style="width: fit-content">
                        <b-checkbox
                          type="is-primary"
                          v-model="comment.is_comment_selected"
                          @click.native="warning()"
                        >
                          {{ comment.comment_content }}
                        </b-checkbox>
                      </b-field>

                      <div v-if="comment.additional_comment != ''">
                        <b-field style="text-align: center">
                          <b-input
                            @click.native="warning()"
                            maxlength="200"
                            rounded
                            placeholder="Specify"
                            v-model="comment.additional_comment"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- if DRP Verification failed -->

            <div class="columns is-7 is-variable">
              <div class="column" v-if="videoCallDetail.drp_comments != null">
                <p class="mb-2">if DRP Verification failed</p>

                <div style="pointer-events: none">
                  <div
                    v-for="comment in videoCallDetail.drp_comments.comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="column"
                v-if="videoCallDetail.customer_behavior_comments != null"
              >
                <p class="mb-2">Customer Behaviour</p>

                <div style="pointer-events: none">
                  <div
                    v-for="comment in videoCallDetail.customer_behavior_comments
                      .comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            />

            <!-- ////////////////////passport comments end//////////////// -->

            <!-- visa image -->
            <div
              class="columns is-7 is-variable"
              v-if="videoCallDetail.face_and_visa_image != null"
            >
              <div class="column" v-if="formData.visa_attachment.attachment_type !== ''">
                <ImageBox v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Visa image"
                  :imageName="formData.visa_attachment.attachment_url"
                  styles="mt-3"
                />
                <ImageBox v-else
                  label="User uploaded Visa image"
                  :imageName="pdfPlaceholder"
                  styles="mt-3"
                />
              </div>
              <div class="column">
                <ImageBox
                  label="Visa image from video call"
                  :imageName="videoCallDetail.face_and_visa_image.path"
                  styles="mt-3"
                />
                <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                    disabled
                    @click.native="warning()"
                    v-model="videoCallDetail.face_and_visa_image.rating"
                    style="margin-left: 150px"
                  >
                  </b-rate>
                </div>
              </div>
              <hr
                :style="{
                  'background-color': '#1b1b1b',
                  height: '1.8px',
                  opacity: '0.5',
                  'margin-inline': '4px',
                }"
              />
            </div>

            <!-- <p class="is-uppercase">Signature image</p>
          <div class="columns is-7 is-variable"> -->
            <!-- Signature image -->

            <!-- <div class="column">
              <ImageBox
                label="User uploaded Signature image"
                :imageName="formData.signature_image"
                styles="mt-3"
              />
            </div>
            <div class="column" v-if="videoCallDetail.signature_image != null">
              <ImageBox
                label="Signature image from video call"
                :imageName="videoCallDetail.signature_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.signature_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div> -->

            <!-- <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            /> -->

            <!-- <p class="is-uppercase">address proof document</p>
          <div class="columns is-7 is-variable"> -->
            <!-- address proof document -->

            <!-- <div class="column">
              <ImageBox
                label="User uploaded address proof document"
                :imageName="formData.address_proof_image"
                styles="mt-3"
              />
            </div>
            <div class="column" v-if="videoCallDetail.address_proof_document_image != null">
              <ImageBox
                label="Address proof document from video call"
                :imageName="videoCallDetail.address_proof_document_image.path"
                styles="mt-3"
              />
              <div class="rating-wrapper">
                <label class="label">Satisfactory Level</label>
                <b-rate
                  disabled
                  @click.native="warning()"
                  v-model="videoCallDetail.address_proof_document_image.rating"
                  style="margin-left: 150px"
                >
                </b-rate>
              </div>
            </div>
          </div> -->

            <!-- <hr
              :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
              }"
            /> -->

            <!-- Location verification -->
            <div class="columns is-7 is-variable">
              <div
                class="column"
                v-if="videoCallDetail.location_verification_comments != null"
              >
                <p class="mb-2">Location verification</p>
                <div style="pointer-events: none">
                  <div
                    v-for="comment in videoCallDetail
                      .location_verification_comments.comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
              <!-- location verifier -->
              <div
                class="column"
                v-if="videoCallDetail.location_verifier_comments != null"
              >
                <p class="mb-2">Location verifier</p>
                <div style="pointer-events: none">
                  <div
                    v-for="comment in videoCallDetail.location_verifier_comments
                      .comments"
                    :key="comment.comment_content"
                  >
                    <b-field style="width: fit-content">
                      <b-checkbox
                        type="is-primary"
                        v-model="comment.is_comment_selected"
                        @click.native="warning()"
                      >
                        {{ comment.comment_content }}
                      </b-checkbox>
                    </b-field>

                    <div v-if="comment.additional_comment != ''">
                      <b-field style="text-align: center">
                        <b-input
                          @click.native="warning()"
                          maxlength="200"
                          rounded
                          placeholder="Specify"
                          v-model="comment.additional_comment"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-7 is-variable">
              <div class="column">
                <ShowMapLocation
                  v-if="showVideoCallCustomerLocation"
                  :position="videoKycCustomerLocation"
                  styles="height: 400px"
                />

                <b-message
                    style="margin-top: 10px"
                    type="is-info"
                    has-icon
                    v-if="videoCallDetail.reverse_geocoded_video_call_location !== ''">
                  <b>Geo-Location Address: </b>
                  {{videoCallDetail.reverse_geocoded_video_call_location}}
                </b-message>

              </div>
              <div class="column">
                <p class="mb-2">Customer Spoken Language</p>
                <div v-if="formData.spoken_language">
                  <AgentInputField copyable="true"
                    label="Customer Spoken Language"
                    :value="formData.spoken_language"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Industry adding area - start -->
      <div
        v-if="
          authStore.loggedInUser.role === 'Detail Reviewer' &&
          formData.current_status === 'ASSIGNED-DETAIL-REVIEWER' &&
          formData.customer_industry === null &&
          !reviewOnly
        "
      >
        <div id="loading" class="fullscreen" v-if="showAddIndustryModal">
          <AddIndustryModal
            :kyc-form-id="authStore.kyc_form_id"
            :occupation-required="
              GlobalFunctions.checkCustomerOccupationRequired(formData)
            "
          />
        </div>
        <AddIndustryButtonComponent
          :add-occupation="
            GlobalFunctions.checkCustomerOccupationRequired(formData)
          "
        />
      </div>

      <!-- Industry adding area - ends -->

      <div style="display: block; padding-bottom: 40px;"
        v-if="
          authStore.loggedInUser.role === 'Detail Reviewer' &&
          formData.current_status === 'ASSIGNED-DETAIL-REVIEWER' &&
          (drpAttempt == null) && (formData.customer_industry != null ) &&
          !reviewOnly
        "
      >
          <div class="card" style="min-height: 55px; font-weight: bolder; font-size: 14pt; text-align: center;">
            <b-button style="min-height: 55px; font-weight: bolder; font-size: 14pt;" type="is-warning"
              expanded @click="handleClick()">Check DRP to Continue</b-button>
          </div>
      </div>
      <div
        class="container"
        v-if="formData.customer_industry !== null && !reviewOnly && (drpAttempt !== null)"
      >
        <div
          class="container"
          rounded
          style="
            height: 80px;
            width: 1800px;
            padding: 20px;
            border-radius: 25px;
          "
        >
          <!-- ======================================== SCHEDULE A VIDEO CALL AND SUBMIT ======================================== -->
          <div
            v-if="
              hasPermission('assign-scheduled-application') &&
              (formData.current_status == 'SUBMITTED-CALL-AGENT' ||
                formData.current_status == 'ASSIGNED-CALL-AGENT' ||
                !applicationstatus)
            "
          >
            <b-button
              type="is-warning"
              style="width: 100%"
              :disabled="Sh_isButtonDisabled"
              @click="Sh_showMessageBox = true"
              >{{ schedule_button_name }}</b-button
            >
          </div>
          <div v-if="Sh_showMessageBox">
            <div id="loading" class="fullscreen">
              <ScheduleRescheduleMeeting
                :Schedule_or_Reschedule="Schedule_or_Reschedule"
                :Sh_Disabled="Sh_Disabled"
                :selected-date="selected"
                :video-call-time="videoCallTime"
                @update_Sh_customer_comment="update_Sh_customer_comment"
                @update_Sh_internal_comment="update_Sh_internal_comment"
                @update_Sh_email="update_Sh_email"
                @update_Sh_sms="update_Sh_sms"
                @Update_Sh_New_Date="Update_Sh_New_Date"
                @Update_Sh_New_Time="Update_Sh_New_Time"
                @sendScheduleMeeting="sendReScheduleMeeting"
                @Closs_Sh="Closs_Sh"
              >
              </ScheduleRescheduleMeeting>
            </div>
          </div>
          <div>
            <b-button
              type="is-primary"
              v-if="
                hasPermission('review-application-from-branch-pool') &&
                formData.current_status == 'REFER-TO-BRANCH-PENDING'
              "
              @click="goToDownload(authStore.kyc_form_id)"
              style="width: 100%"
              >DOWNLOAD AS PDF</b-button
            >
          </div>
          <div class="columns">
            <!-- ======================================== SCHEDULE A VIDEO CALL AND SUBMIT ======================================== -->
            <div
              class="column"
              v-if="
                hasPermission('view-new-application-pool') &&
                (formData.current_status == 'NEW' ||
                  formData.current_status == 'ASSIGNED-DETAIL-REVIEWER' ||
                  formData.current_status == 'EXCEPTIONAL-APPROVER-REJECTED' ||
                  formData.current_status == 'EXCEPTIONAL-APPROVER-RETURNED' ||
                  formData.current_status == 'EXCEPTIONAL-APPROVER-APPROVED' ||
                  formData.current_status == 'HOLD' ||
                  formData.current_status == 'RETURNED-DETAIL-REVIEWER')
              "
            >
              <b-button
                v-if="!applicationstatus"
                type="is-primary"
                :disabled="Sh_isButtonDisabled"
                @click="Sh_showMessageBox = true"
                >Schedule a Video Call & Submit</b-button
              >
              <div v-if="Sh_showMessageBox">
                <div id="loading" class="fullscreen">
                  <ScheduleRescheduleMeeting
                    :Sh_Disabled="Sh_Disabled"
                    :Schedule_or_Reschedule="Schedule_or_Reschedule"
                    :selected-date="selected"
                    :video-call-time="videoCallTime"
                    @update_Sh_customer_comment="update_Sh_customer_comment"
                    @update_Sh_internal_comment="update_Sh_internal_comment"
                    @update_Sh_email="update_Sh_email"
                    @update_Sh_sms="update_Sh_sms"
                    @Update_Sh_New_Date="Update_Sh_New_Date"
                    @Update_Sh_New_Time="Update_Sh_New_Time"
                    @sendScheduleMeeting="sendScheduleMeeting"
                    @Closs_Sh="Closs_Sh"
                  ></ScheduleRescheduleMeeting>
                </div>
              </div>
            </div>

            <div
              class="column"
              v-if="
                (hasPermission('view-new-application-pool') &&
                  (formData.current_status == 'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status == 'NEW' ||
                    formData.current_status == 'ASSIGNED-DETAIL-REVIEWER' ||
                    formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED')) ||
                (hasPermission('assign-scheduled-application') &&
                  (formData.current_status == 'VIDEO-CALL-COMPLETED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                    formData.current_status == 'RETURNED-CALL-AGENT' ||
                    (formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED') |
                      (formData.current_status ==
                        'EXCEPTIONAL-APPROVER-APPROVED')))
              "
            >
              <b-button type="is-primary" outlined
                :disabled="HA_isButtonDisabled"
                @click="HA_showMessageBox = true"
                >Hold Application</b-button
              >
              <div v-if="HA_showMessageBox">
                <div id="loading" class="fullscreen">
                  <HoldApplication
                    @update_HA_customer_comment="update_HA_customer_comment"
                    @update_HA_internal_comment="update_HA_internal_comment"
                    @update_HA_reasons="update_HA_reasons"
                    @Closs_HA="Closs_HA"
                    @hold_application="hold_application"
                  ></HoldApplication>
                </div>
              </div>
            </div>

            <!-- ======================================== EXCEPTIONAL APPROVER ======================================== -->
            <div class="column">
              <div
                v-if="
                  (hasPermission('view-new-application-pool') &&
                    (formData.current_status == 'NEW' ||
                      formData.current_status == 'ASSIGNED-DETAIL-REVIEWER' ||
                      formData.current_status == 'HOLD' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-REJECTED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-RETURNED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-APPROVED')) ||
                  (hasPermission('assign-scheduled-application') &&
                    (formData.current_status == 'VIDEO-CALL-COMPLETED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-REJECTED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-RETURNED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-APPROVED' ||
                      formData.current_status == 'HOLD')) ||
                  ((authStore.loggedInUser.role === 'Detail Reviewer' ||
                    authStore.loggedInUser.role === 'Call Agent') &&
                    (formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                      formData.current_status == 'RETURNED-CALL-AGENT')) ||
                  (hasPermission('approve-application') &&
                    (formData.current_status == 'SUBMITTED-APPROVER' ||
                      formData.current_status == 'ASSIGNED-APPROVER' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-RETURNED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-REJECTED' ||
                      formData.current_status ==
                        'EXCEPTIONAL-APPROVER-APPROVED'))
                "
              >
                <b-button type="is-primary" outlined
                  :disabled="EA_isButtonDisabled"
                  @click="EA_showMessageBox = true"
                >
                  Exceptional Approver
                </b-button>
                <div v-if="EA_showMessageBox">
                  <div id="loading" class="fullscreen">
                    <ExceptionalApprover
                      @update_EA_customer_comment="update_EA_customer_comment"
                      @update_EA_internal_comment="update_EA_internal_comment"
                      @Closs_EA="Closs_EA"
                      @exceptional_Approver="exceptional_Approver"
                    ></ExceptionalApprover>
                  </div>
                </div>
              </div>
            </div>
            <!-- ========================================          CREATE CIF AND OPEN ACCOUNTS RELATED BUTTONS  - START       ======================================== -->

            <!-- ======================================== REFFER TO BRANCH ======================================== -->
            <div
              class="column"
              v-if="
                (hasPermission('view-new-application-pool') &&
                  (formData.current_status == 'NEW' ||
                    formData.current_status == 'ASSIGNED-DETAIL-REVIEWER' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED' ||
                    formData.current_status == 'HOLD' ||
                    formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                    formData.current_status == 'RETURNED-CALL-AGENT')) ||
                (hasPermission('assign-scheduled-application') &&
                  (formData.current_status == 'VIDEO-CALL-COMPLETED' ||
                    formData.current_status == 'HOLD' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED')) ||
                ((authStore.loggedInUser.role === 'Detail Reviewer' ||
                  authStore.loggedInUser.role === 'Call Agent') &&
                  (formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                    formData.current_status == 'RETURNED-CALL-AGENT')) ||
                (hasPermission('approve-application') &&
                  (formData.current_status == 'SUBMITTED-APPROVER' ||
                    formData.current_status == 'ASSIGNED-APPROVER' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED' ||
                    formData.current_status == 'T24-CIF-ERROR' ||
                    formData.current_status == 'T24-ACC-CREATION-ERROR'))
              "
            >
              <div>
                <b-button  type="is-primary" outlined
                  :disabled="RB_isButtonDisabled"
                  @click="RB_showMessageBox = true"
                  >Refer to Branch</b-button
                >
                <div v-if="RB_showMessageBox">
                  <div id="loading" class="fullscreen">
                    <ReffertoBranch
                      @update_RB_customer_comment="update_RB_customer_comment"
                      @update_RB_internal_comment="update_RB_internal_comment"
                      @Closs_RB="Closs_RB"
                      @refer_to_Branch="refer_to_Branch"
                    ></ReffertoBranch>
                  </div>
                </div>
              </div>
            </div>

            <!-- ======================================== APPROVE APPLICATION AUTHORIZER - CREATE CIF AND OPEN ACCOUNTS ======================================== -->
            <div class="column">
              <div v-if="ShowCreateCIFAndOpenAccountsBtn">
                <b-button
                  :disabled="DisableCreateCIFAndOpenAccountsBtn"
                  @click="showCreateCifAndOpenAccountApproveMsgBox = true"
                >
                  {{ GetCreateCIFAndOpenAccountsBtnLabel }}
                </b-button>

                <div v-if="showCreateCifAndOpenAccountApproveMsgBox">
                  <div id="loading" class="fullscreen">
                    <ApproveApplication
                      @update_App_customer_comment="
                        getCreateCIFAndOpenAccountsCustomerComment
                      "
                      @update_App_internal_comment="
                        getCreateCIFAndOpenAccountsInternalComment
                      "
                      @Closs_App="closeCreateCifAndOpenAccountsApproveMsgBox"
                      @Approve_Application="createCIFOpenAccountsAndApproveKyc"
                    ></ApproveApplication>
                  </div>
                </div>
              </div>
            </div>

            <!-- ======================================== ADD INTERNAL COMMENT FOR FCM REJECTED APPLICATIONS ======================================== -->
            <div class="column">
              <div v-if="ShowAddCommentForFCMRejectApplicationBtn">
                <b-button
                  icon-left="plus"
                  :disabled="DisableAddCommentForFCMRejectedApplicationBtn"
                  @click="
                    cifPopUpModals.showAddInternalCommentForFCMRejectApplicationModal = true
                  "
                >
                  Add Comment
                </b-button>
              </div>
            </div>

            <!-- ======================================== MANUALLY CHECKED BTN FOR SIGN ERROR APPLICATIONS ======================================== -->
            <div class="column">
              <div v-if="ShowManuallyCheckForSignErrorApplicationBtn">
                <b-button @click="manuallyAcceptT24SignError">
                  Manually Checked
                </b-button>
              </div>
            </div>

            <!-- ======================================== MANUALLY REJECT FROM T24 FLOW BTN ======================================== -->
            <div class="column">
              <div v-if="ShowManuallyRejectFromCIFAccFlowBtn">
                <b-button @click="manuallyRejectKycFromCifAccFlow">
                  Reject
                </b-button>
              </div>
            </div>

            <!-- ========================================          CREATE CIF AND OPEN ACCOUNTS RELATED BUTTONS  - END       ======================================== -->

            <!-- ======================================== APPROVE APPLICATION ======================================== -->
            <div class="column">
              <div
                v-if="
                  hasPermission('assign-exceptional-approval-application') &&
                  formData.current_status === 'ASSIGNED-EXCEPTIONAL-APPROVER'
                "
              >
                <b-button type="is-primary" outlined
                  :disabled="App_isButtonDisabled"
                  @click="App_showMessageBox = true"
                  >Approve</b-button
                >
                <div v-if="App_showMessageBox">
                  <div id="loading" class="fullscreen">
                    <ApproveApplication
                      @update_App_customer_comment="update_App_customer_comment"
                      @update_App_internal_comment="update_App_internal_comment"
                      @Closs_App="Closs_App"
                      @Approve_Application="Approve_Application"
                    ></ApproveApplication>
                  </div>
                </div>
              </div>
            </div>

            <!-- ======================================== SUBMIT TO APPROVER APPLICATION ======================================== -->
            <div
              class="column"
              v-if="
                (formData.current_status == 'VIDEO-CALL-COMPLETED' ||
                  formData.current_status == 'HOLD' ||
                  formData.current_status == 'EXCEPTIONAL-APPROVER-REJECTED' ||
                  formData.current_status == 'EXCEPTIONAL-APPROVER-RETURNED' ||
                  formData.current_status == 'EXCEPTIONAL-APPROVER-APPROVED') &&
                hasPermission('assign-scheduled-application') &&
                !isReworkedApplication
              "
            >
              <div>
                <b-button
                  :disabled="Su_to_App_isButtonDisabled"
                  @click="Su_to_App_showMessageBox = true"
                  class="is-primary"
                  >Submit to Authorizer</b-button
                >
                <div v-if="Su_to_App_showMessageBox">
                  <div id="loading" class="fullscreen">
                    <SubmitToApprover
                      @update_Su_to_App_internal_comment="
                        update_Su_to_App_internal_comment
                      "
                      @Closs_Su_to_App="Closs_Su_to_App"
                      @Su_to_Approver="Su_to_Approver"
                    ></SubmitToApprover>
                  </div>
                </div>
              </div>
            </div>

            <!-- ======================================== SUBMIT TO APPROVER APPLICATION ======================================== -->
            <div
              class="column"
              v-if="
                (hasPermission('view-new-application-pool') &&
                  applicationstatus &&
                  isReworkedApplication) ||
                (hasPermission('assign-scheduled-application') &&
                  !isReworkedApplication &&
                  formData.current_status === 'RETURNED-CALL-AGENT') ||
                (hasPermission('assign-scheduled-application') &&
                  isReworkedApplication)
              "
            >
              <div>
                <b-button
                  :disabled="Su_to_App_isButtonDisabled"
                  @click="Su_to_App_showMessageBox = true"
                  class="is-danger"
                  >Re-Submit to Authorizer</b-button
                >
                <div v-if="Su_to_App_showMessageBox">
                  <div id="loading" class="fullscreen">
                    <SubmitToApprover
                      @update_Su_to_App_internal_comment="
                        update_Su_to_App_internal_comment
                      "
                      @Closs_Su_to_App="Closs_Su_to_App"
                      @Su_to_Approver="Re_Su_to_Approver"
                    ></SubmitToApprover>
                  </div>
                </div>
              </div>
            </div>

            <!-- ======================================== REJECT APPLICATION ======================================== -->

            <div
              class="column"
              v-if="
                (hasPermission('view-new-application-pool') &&
                  (formData.current_status == 'NEW' ||
                    formData.current_status == 'ASSIGNED-DETAIL-REVIEWER' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status == 'HOLD' ||
                    formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                    formData.current_status == 'RETURNED-CALL-AGENT' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED')) ||
                (hasPermission('assign-scheduled-application') &&
                  (formData.current_status == 'VIDEO-CALL-COMPLETED' ||
                    formData.current_status == 'HOLD' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED')) ||
                ((authStore.loggedInUser.role === 'Detail Reviewer' ||
                  authStore.loggedInUser.role === 'Call Agent') &&
                  (formData.current_status == 'RETURNED-DETAIL-REVIEWER' ||
                    formData.current_status == 'RETURNED-CALL-AGENT')) ||
                (hasPermission('approve-application') &&
                  (formData.current_status == 'SUBMITTED-APPROVER' ||
                    formData.current_status == 'ASSIGNED-APPROVER' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-RETURNED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-REJECTED' ||
                    formData.current_status ==
                      'EXCEPTIONAL-APPROVER-APPROVED')) ||
                (hasPermission('assign-exceptional-approval-application') &&
                  formData.current_status == 'ASSIGNED-EXCEPTIONAL-APPROVER')
              "
            >
              <b-button type="is-primary" outlined
                :disabled="R_isButtonDisabled"
                @click="R_showMessageBox = true"
                >Reject</b-button
              >
              <div v-if="R_showMessageBox">
                <div id="loading" class="fullscreen">
                  <RejectApplication
                    @update_R_drp_fail="update_R_drp_fail"
                    @update_R_specify="update_R_specify"
                    @update_R_reasons="update_R_reasons"
                    @Closs_R="Closs_R"
                    @update_R_customer_comment="update_R_customer_comment"
                    @update_R_internal_comment="update_R_internal_comment"
                    @reject_application="reject_application"
                  ></RejectApplication>
                </div>
              </div>
            </div>

            <!-- ======================================== Return APPLICATION ======================================== -->

            <div
              class="column"
              v-if="
                (authStore.loggedInUser.role === 'Exceptional Approver' &&
                  formData.current_status === 'ASSIGNED-APPROVER') ||
                (hasPermission('view-returned-application-list') &&
                  formData.current_status == 'ASSIGNED-APPROVER') ||
                (hasPermission('assign-exceptional-approval-application') &&
                  formData.current_status == 'ASSIGNED-EXCEPTIONAL-APPROVER') ||
                formData.current_status == 'ASSIGNED-APPROVER' ||
                (authStore.loggedInUser.role ===
                  GlobalAttributes.roles.AUTHORIZER &&
                  formData.current_status ===
                    GlobalAttributes.kycFormStatus
                      .EXCEPTIONAL_APPROVER_RETURNED)
              "
            >
              <b-button type="is-primary" outlined
                :disabled="RA_isButtonDisabled"
                @click="RA_showMessageBox = true"
                >Return Application</b-button
              >
              <div v-if="RA_showMessageBox">
                <div id="loading" class="fullscreen">
                  <ReturnApplication
                    @update_RA_return_to="update_RA_return_to"
                    @update_RA_reasons="update_RA_reasons"
                    @Closs_RA="Closs_RA"
                    @update_RA_customer_comment="update_RA_customer_comment"
                    @update_RA_internal_comment="update_RA_internal_comment"
                    @return_application="return_application"
                  ></ReturnApplication>
                </div>
              </div>
            </div>
            <!-- <div class="column">
                <b-button @click="goToDownload()">DOWNLOAD</b-button>
              </div> -->
            <!--          </div>-->
          </div>
        </div>
      </div>

      <!-- CIF creation and Account opening flow related pop up modals - START  -->

      <!-- waiting for cif creation pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showWaitingForCifCreationProcessModal"
        close-modal-redirect-route="/assignedApproval"
        :hide-close-btn="true"
        :modalImage="copygif"
        modalImageAltText="Copy Gif"
        modalImageClass="img_blend"
        modalImageWidth="220px"
        imgline="Waiting for Account Creation Process"
        modalthirdline=""
        :modalClose="acccreationprocessModalRoute"
        line="line"
      />

      <!-- cif error go to retry queue pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showCifErrorRetryQueueModal"
        close-modal-redirect-route="/assignedApproval"
        :modalImage="charmcircle"
        modalImageAltText="charmcircle"
        modalImageWidth="80px"
        modalfirstline="An error occurred"
        errorLine="Retry Queue"
        :modalClose="retryqueueModalRoute"
      />

      <!-- cif error overrides pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showCifErrorOverridesModal"
        close-modal-redirect-route="/assignedApproval"
        :modalImage="charmcircle"
        modalImageAltText="charmcircle"
        modalImageWidth="80px"
        modalfirstline="An error occurred"
        modalsecondline="Overrides"
        errorLineSecond="Override Queue"
        :modalClose="overridequeueModalRoute"
      />

      <!-- FCM reject pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showFCMRejectModal"
        close-modal-redirect-route="/assignedApproval"
        :modalImage="charmcircle"
        modalImageAltText="charmcircle"
        modalImageWidth="80px"
        modalfirstline="An error occurred"
        modalsecondline="FCM Rejected"
        errorLineSecond="FCM Rejected Queue"
        :modalClose="fcmRejectedModalRoute"
      />

      <!-- signature error pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showSignatureErrorModal"
        close-modal-redirect-route="/assignedApproval"
        :modalImage="charmcircle"
        modalImageAltText="charmcircle"
        modalImageWidth="80px"
        modalfirstline="An error occurred"
        modalsecondline="Signature Error"
        errorLineSecond="Signature Error"
        :modalClose="signatureModalRoute"
      />

      <!-- product error pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showProductErrorModal"
        close-modal-redirect-route="/assignedApproval"
        :modalImage="charmcircle"
        modalImageAltText="charmcircle"
        modalImageWidth="80px"
        modalfirstline="An error occurred"
        :modalsecondline="approvedKycDFCCData.productErrorTextForPopUp"
        :errorLineSecond="approvedKycDFCCData.productErrorTextForPopUp"
        :modalClose="productcurrencyModalRoute"
      />

      <!-- successful CIF creation and open accounts pop up -->
      <CifModalScreen
        v-if="cifPopUpModals.showSuccessfulCifCreationAndAccOpeningModal"
        close-modal-redirect-route="/assignedApproval"
        :isShowSecondModalContent="true"
        :accountNumbers="approvedKycDFCCData.productDetails"
        :cifNumber="approvedKycDFCCData.cifNumber"
        :modalClose="accCreationModal"
      />

      <!-- Add internal comment modal -->
      <AddInternalComment
        v-if="cifPopUpModals.showAddInternalCommentForFCMRejectApplicationModal"
        @closeInternalCommentModal="closeCifAddInternalCommentModal"
        @saveInternalComment="saveInternalCommentForFCMRejectApplication"
      />
      <!-- CIF creation and Account opening flow related pop up modals - END    -->
    </div>
  </ValidationObserver>
</template>

<script>
import NicOcrResult from "./NicOcrResult/NicOcrResult.vue";
import PepSanctionListResults from "./PepSanctionListResults/PepSanctionListResults.vue";
import AmsResults from "./AmsResults/AmsResults.vue";
import ProgressLoader from "./ProgressLoader.vue";
import FullNameView from "./FullNameView.vue"
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlus } from "@mdi/js";
import ImageCaptureComponent from "./ImageCaptureComponent.vue"
import InputLabel from "./InputLabel.vue";
import ImageBox from "./ImageBox.vue";
import NetworkManager from "../network";
import ReffertoBranch from "./ReffertoBranch.vue";
import { onMounted } from "vue";
import ExceptionalApprover from "./ExceptionalApprover.vue";
import RejectApplication from "./RejectApplication.vue";
import ReturnApplication from "./ReturnApplication.vue";
import HoldApplication from "./HoldApplication.vue";
import ScheduleRescheduleMeeting from "./ScheduleRescheduleMeeting.vue";
import ApproveApplication from "./ApproveApplication.vue";
import SubmitToApprover from "./SubmitToApprover.vue";
import CheckDRP from "./CheckDRP.vue";
import { useAuthStore } from "../store/authStore";
import { DateTimeManger } from "../helpers/dateTimeManger";
import AgentInputField from "./AgentInputField.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import AddIndustryButtonComponent from "./T24Industry/AddIndustryButtonComponent.vue";
import AddIndustryModal from "./T24Industry/AddIndustryModal.vue";
import { GlobalEventManager } from "../helpers/globalEventManager";
import { GlobalEvents } from "../helpers/globalEvents";
import { GlobalFunctions } from "../helpers/globalFunctions";
import { GeoPositionCoordinates } from "../helpers/classes/geoPositionCoordinates";
import ShowMapLocation from "./ShowMapLocation.vue";
import LocationgetMapVue from "./LocationgetMap.vue";
import { BuefyHelper } from "../helpers/bufeyHelper";
import DisplayBulletList from "./DisplayBulletList.vue";
import DisplayReturnReasons from "./DisplayReturnReasons.vue";
import { CreatedProductDetailDTO } from "../helpers/classes/dtos/createdProductDetailDTO";
import { ApiRequestManager } from "../network/apiRequestManager";
import CifModalScreen from "./CifModalScreen.vue";
import doneimage from "../assets/Images/Icons/done.png";
import charmcircle from "../assets/Images/Icons/charm_circle-tick.png";
import copygif from "../assets/Images/Gif/copy.gif";
import AddInternalComment from "./AddInternalComment.vue";
import network from "../network";
import { GlobalAttributes } from "../helpers/globalAttributes";
import CheckDRPNewVue from "./CheckDRPNew.vue";
import Attachments from "./Attachments.vue";
import AttachmentViewer from "./AttachmentViewer.vue";
import TextDisplayComponent from "./TextDisplayComponent/TextDisplayComponent.vue";
import VideoCallRandomQuestionnaireResult from "./VideoCallRandomQuestionnaireResult.vue";
import {NicOcrResultDTO} from "../helpers/classes/dtos/nicOcrResultDTO";

export default {
  name: "ApplicationReview",
  props: {
    reviewOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    NicOcrResult,
    PepSanctionListResults,
    AmsResults,
    VideoCallRandomQuestionnaireResult,
    ProgressLoader,
    SvgIcon,
    AddIndustryButtonComponent,
    AddIndustryModal,
    InputLabel,
    ImageBox,
    ShowMapLocation,
    ReffertoBranch,
    ExceptionalApprover,
    RejectApplication,
    ReturnApplication,
    ApproveApplication,
    HoldApplication,
    ScheduleRescheduleMeeting,
    CheckDRP,
    AgentInputField,
    LocationgetMapVue,
    VueCropper,
    FullNameView,
    SubmitToApprover,
    DisplayBulletList,
    DisplayReturnReasons,
    CifModalScreen,
    AddInternalComment,
    ImageCaptureComponent,
    CheckDRPNewVue,
    Attachments,
    AttachmentViewer,
    TextDisplayComponent
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      showNicOcrResult : false,
      showPepSanctionResult : false,
      showAmsResult : false,
      nicFrontOcrResult : null,
      nicRearOcrResult : null,
      showAttachmentModel:false,
      showRandomQuestionnaireModel : false,
      showAttachmenviewer:false,
      applicationId : null,
      authId :"",
      currentStatus:null,
      loaderValue:50,
      showLoadingModel:false,
      showmanualDRPdata:false,
      isDRPManuallyVerify:false,
      checkDRPFailed:false,
      manualDRPComment:'',
      DRPImage : null,
      base64Image:null,
      manualDRP:false,
      drpAttempt : null,
      isReworkedApplication: false,
      isCardModalActive: false,
      customerCifNumber: "",

      applicationstatus: false,
      videoDuration: "",
      getAllInternalComments: null,
      getCommitKycDetails: null,
      returnReasonDetail: null,
      path: mdiPlus,

      showAddIndustryModal: false,
      showAddIndustryButton: false,

      nicActiveTab: 0,
      passportActiveTab: 0,
      DRP_showMessageBox: false,

      HA_isButtonDisabled: false,
      HA_showMessageBox: false,

      RB_isButtonDisabled: false,
      RB_showMessageBox: false,

      App_isButtonDisabled: false,
      App_showMessageBox: false,

      showCreateCifAndOpenAccountApproveMsgBox: false,

      Su_to_App_isButtonDisabled: false,
      Su_to_App_showMessageBox: false,

      EA_isButtonDisabled: false,
      EA_showMessageBox: false,

      R_isButtonDisabled: false,
      R_showMessageBox: false,

      RA_isButtonDisabled: false,
      RA_showMessageBox: false,

      Sh_isButtonDisabled: false,
      Sh_showMessageBox: false,

      idfRotateAngle: 0,
      idbRotateAngle: 0,
      addressrotateAngle: 0,
      visarotateAngle: 0,
      ppbioRotateAngle: 0,
      ppaltRotateAngle: 0,
      ppsRotateAngle: 0,

      Image: "",
      IDF: true,
      IDB: false,
      SLF: false,
      varcheker: "",

      kycFormCustomerLocation: new GeoPositionCoordinates(),
      videoKycCustomerLocation: new GeoPositionCoordinates(),
      showCustomerLocation: false,
      showVideoCallCustomerLocation: false,
      formData: [],
      pepSanctionData: [],
      customerRiskRatingData: {},
      pepData:[],
      amsDetails:[],
      sanctionData:[],
      videoCallDetail: [],
      customer_branch: null,
      agent_branch: [],
      products: [],

      attachment: ["", "", "", "", ""],

      button: "https://cdn-icons-png.flaticon.com/512/1040/1040252.png",
      button1: "https://cdn-icons-png.flaticon.com/512/1040/1040252.png",
      pdfPlaceholder : require("../assets/Images/pdf.png"),

      //defaultimg: require("../assets/Images/add.png"),
      defaultimg: require("../assets/Images/add.png"),
      //pdftest:"../assets/Mandate.pdf",

      cards: [{ id: 1, image: "", description: "" , type:""}],

      drpArray : '',

      count: 1,
      maxCards: 5,

      R_drp_fail: false,
      R_specify: "",
      R_reasons: [],
      R_customer_comment: "",
      R_internal_comment: "",

      RA_return_to: "",
      RA_reasons: [],
      RA_customer_comment: "",
      RA_internal_comment: "",

      RB_customer_comment: "",
      RB_internal_comment: "",

      App_customer_comment: "",
      App_internal_comment: "",

      Su_to_App_internal_comment: "",

      EA_customer_comment: "",
      EA_internal_comment: "",

      HA_customer_comment: "",
      HA_internal_comment: "",
      HA_reasons: [],

      DRP_internal_comment: "",
      //DRP_data :[],

      //values forSchedule Reschedule meeting

      Sh_customer_comment: "",
      Sh_internal_comment: "",
      Sh_email: true,
      Sh_sms: false,
      videoCallTime: "",
      selected: "",
      Schedule_or_Reschedule: "Schedule a Video Call",
      // detailReviewerAttachments : null,
      // callAgentAttachments : null,
      Sh_Disabled: true,

      schedule_button_name: "Schedule a Video Call & Submit",

      DRPCustomerData: {
        nic: "",
        dob: "",
        address: "",
        full_name: "",
        issue_date: "",
      },
      DRPdata: null,
      isActive: false,
      imageUrl: "",
      captureModalIsVisible: false,
      cropModalIsVisible: false,
      file: null,
      imageToCrop: null,
      croppedImg: null,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      crop_ratio: 1 / 1,
      selectedCardID: "",
      latestUpdateUserRoleId: "",

      // CIF creation and open accounts and approve kyc related properties - START
      copygif: copygif,
      charmcircle: charmcircle,
      doneimage: doneimage,

      cifPopUpModals: {
        showWaitingForCifCreationProcessModal: false,
        showCifErrorRetryQueueModal: false,
        showCifErrorOverridesModal: false,
        showFCMRejectModal: false,
        showSignatureErrorModal: false,
        showProductErrorModal: false,
        showSuccessfulCifCreationAndAccOpeningModal: false,
        showAddInternalCommentForFCMRejectApplicationModal: false,
      },

      createCifAndOpenAccountsApproveModal: {
        internalComment: "",
        customerComment: "",
      },

      createCifOpenAccountsBtnLabel: "",

      approvedKycDFCCData: {
        cifNumber: "",
        productDetails: [],
        productErrorTextForPopUp: "",
      },

      latestT24Overrides: null, // store latest t24 overrides
      productErrorDetail: null, // store t24 product errors
      isInternalCommentAddedForFcmRejectApplication: false,
      currentDate: '',

      pepIds: [],
      searchId: '',
      amsData : [],
      applicantName: "",
      // CIF creation and open accounts and approve kyc related properties - END
    };
  },

  methods: {
    downloadPdf(pdfUrl, pdfFileName) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = pdfFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleClick() {
      this.$refs.checkDRPFirst.scrollIntoView({ behavior: 'smooth' });
    },
    closeManualDRPModel()
    {
      this.manualDRP= false
      this.DRPImage = null
      this.base64Image = null
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      this.showFullScreenLoader();
      reader.onload = () => {
        const dataUrl = reader.result;
        this.hideFullScreenLoader();
        // Remove the "data:image/png;base64," prefix to get the raw Base64 string
        const base64String = dataUrl.split(',')[1];

        this.base64Image = base64String;
        console.log("row form of base64image",this.base64Image);
      };

      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    },



    onDRPCaptured: function (e) {
      // alert(e)
      this.DRPImage = e;
      console.log(
        "DRP Manual Image : " + this.DRPImage
      );
    },

    showModel(){
        this.manualDRP = true
        console.log("Show this.manualDRP",this.manualDRP)
    },
    showManualDRPModel()
    {
      this.showmanualDRPdata = true
    },
    // CIF creation and open accounts flow Pop-ups related methods - START

    accCreationModal() {
      this.cifPopUpModals.showSuccessfulCifCreationAndAccOpeningModal = false;
      this.$router.push("/approvedApplications");
    },

    acccreationprocessModalRoute() {
      this.cifPopUpModals.showWaitingForCifCreationProcessModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 0 },
      });
    },

    retryqueueModalRoute() {
      this.cifPopUpModals.showCifErrorRetryQueueModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 0 },
      });
    },

    overridequeueModalRoute() {
      this.cifPopUpModals.showCifErrorOverridesModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 1 },
      });
    },

    fcmRejectedModalRoute() {
      this.cifPopUpModals.showFCMRejectModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 3 },
      });
    },

    signatureModalRoute() {
      this.cifPopUpModals.showSignatureErrorModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 5 },
      });
    },

    productcurrencyModalRoute() {
      this.cifPopUpModals.showProductErrorModal = false;
      this.$router.push({
        path: "/accountCreationQueue",
        query: { tabId: 6 },
      });
    },

    closeCifAddInternalCommentModal() {
      const comp = this;
      console.log("Close internal comment modal captured ...");
      comp.cifPopUpModals.showAddInternalCommentForFCMRejectApplicationModal = false;
    },

    // CIF creation and open accounts flow Pop-ups related methods - END

    // CIF creation and open account related other methods - START

    /**
     * This function can be used to save internal comment for FCM reject applications
     * @param comments
     */
    saveInternalCommentForFCMRejectApplication(comments) {
      const comp = this;

      console.log("Internal comment for FCM reject application");
      console.log(comments);

      comp.cifPopUpModals.showAddInternalCommentForFCMRejectApplicationModal = false;
      comp.showFullScreenLoader();

      const requestBody = {
        kyc_form_id: comp.authStore.kyc_form_id,
        kyc_status: "FCM-REJECTED",
        internal_comment: comments,
      };

      console.log("request body ", requestBody);

      network.apiRequest(
        "api/KycApplication/addInternalCommentByStatus",
        requestBody,
        (response) => {
          console.log("Internal comment for FCM reject application");
          console.log("response", response);

          comp.hideFullScreenLoader();

          if (response.statusCode === 200) {
            // successfully saved the internal comment for the requested status
            // reload here again to get latest comment
            comp.$router.go();
          } else {
            // any error
            BuefyHelper.showToastMessage("Something went wrong!", "is-danger");
          }
        }
      );
    },

    manuallyAcceptT24SignError() {
      const comp = this;
      comp.showFullScreenLoader();

      const requestBody = {
        kyc_form_id: comp.authStore.kyc_form_id,
        comment_for_customer: "",
        comment_for_internal_use: "",
      };

      console.log("manually accepting t24 Sign error");
      console.log("request body");
      console.log(requestBody);

      NetworkManager.apiRequest(
        "api/KycApplication/manuallyAcceptSignatureForT24",
        requestBody,
        (response) => {
          console.log("manually accepting t24 sign error response");
          console.log(response);

          comp.hideFullScreenLoader();

          if (response.statusCode === 200) {
            // successfully done

            // TODO :: show some kind of toast msg before executing the cif creation flow
            let comments = {
              commentForCustomer: "",
              commentForInternal: "",
            };

            // retry CIF creation
            comp.createCIFOpenAccountsAndApproveKyc(comments, true);
          } else {
            // any other failure
            BuefyHelper.showToastMessage(
              "Failed to update the manually checked status",
              "is-danger"
            );
          }
        }
      );
    },

    manuallyRejectKycFromCifAccFlow() {
      const comp = this;
      comp.showFullScreenLoader();

      const requestBody = {
        id: comp.authStore.kyc_form_id,
        status: "MANUALLY-REJECTED-FROM-CIF-ACC-FLOW",
        comment_for_customer: "",
        comment_for_internal_use: "",
        reasons: [],
      };

      console.log("Manually rejecting kyc application from CIF ACC flow");
      console.log("request body", requestBody);

      network.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        requestBody,
        (response) => {
          console.log("Manually rejecting kyc application from CIF ACC flow");
          console.log("response", response);

          comp.hideFullScreenLoader();

          if (response.statusCode === 200) {
            BuefyHelper.showToastMessage(
              "Application Manually Rejected",
              "is-danger"
            );
            // move to manually rejected tab in acc creation queue
            comp.$router.push({
              path: "/accountCreationQueue",
              query: { tabId: 4 },
            });
          } else {
            // error from api
            BuefyHelper.showToastMessage("Something went wrong!", "is-danger");
          }
        }
      );
    },

    // CIF creation and open account related other methods - END

    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },

    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "i-was-html.pdf",
      });
    },

    getVideoDuration() {
      const videoPlayer = this.$refs.videoPlayer;
      this.videoDuration = this.formatDuration(videoPlayer.duration);
    },
    formatDuration(duration) {
      const minutes = Math.floor(duration / 60);
      const seconds = Math.floor(duration % 60);
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },

    goToDownload(id) {
      const comp = this;
      comp.authStore.kyc_form_id = id;
      window.open("/Download", "_blank", "width=1920,height=1080");
      newWindow.focus();
    },

    getFormattedDateTime(date) {
      return DateTimeManger.formatCustomerInputDates(date);
    },
    //Exceptional Approver
    update_EA_customer_comment(value) {
      this.EA_customer_comment = value;
    },
    update_EA_internal_comment(value) {
      this.EA_internal_comment = value;
    },
    Closs_EA(value) {
      this.EA_showMessageBox = value;
    },

    //Refer to Branch
    update_RB_customer_comment(value) {
      this.RB_customer_comment = value;
    },
    update_RB_internal_comment(value) {
      this.RB_internal_comment = value;
    },
    Closs_RB(value) {
      this.RB_showMessageBox = value;
    },

    //Approve Application
    update_App_customer_comment(value) {
      this.App_customer_comment = value;
    },
    update_App_internal_comment(value) {
      this.App_internal_comment = value;
    },

    // create cif open account and approve kyc
    getCreateCIFAndOpenAccountsCustomerComment(comment) {
      this.createCifAndOpenAccountsApproveModal.customerComment = comment;
    },
    getCreateCIFAndOpenAccountsInternalComment(comment) {
      this.createCifAndOpenAccountsApproveModal.internalComment = comment;
    },

    Closs_App(value) {
      this.App_showMessageBox = value;
    },

    closeCreateCifAndOpenAccountsApproveMsgBox(value) {
      this.showCreateCifAndOpenAccountApproveMsgBox = value;
    },

    //SUBMIT TO APPROVER
    update_Su_to_App_internal_comment(value) {
      this.Su_to_App_internal_comment = value;
    },
    Closs_Su_to_App(value) {
      this.Su_to_App_showMessageBox = value;
    },

    //Reject Application
    update_R_customer_comment(value) {
      this.R_customer_comment = value;
    },
    update_R_internal_comment(value) {
      this.R_internal_comment = value;
    },
    update_R_specify(value) {
      this.R_specify = value;
    },
    update_R_reasons(value) {
      this.R_reasons = value;
    },
    update_R_drp_fail(value) {
      this.R_drp_fail = value;
    },
    Closs_R(value) {
      this.R_showMessageBox = value;
    },

    //Return Application
    update_RA_customer_comment(value) {
      this.RA_customer_comment = value;
    },
    update_RA_internal_comment(value) {
      this.RA_internal_comment = value;
    },
    update_RA_reasons(value) {
      this.RA_reasons = value;
    },
    update_RA_return_to(value) {
      this.RA_return_to = value;
    },
    Closs_RA(value) {
      this.RA_showMessageBox = value;
    },

    //Hold Application
    update_HA_customer_comment(value) {
      this.HA_customer_comment = value;
    },
    update_HA_internal_comment(value) {
      this.HA_internal_comment = value;
    },
    update_HA_reasons(value) {
      this.HA_reasons = value;
    },
    Closs_HA(value) {
      this.HA_showMessageBox = value;
    },
    DRP_Check_Failed_fail(value)
    {
      console.log("value",value)
      this.checkDRPFailed =true
    },
    //Check DRP
    update_DRP_internal_comment(value) {
      console.log("DRP INTERNAL COMMENT RECIEVED: ",value)
      this.DRP_internal_comment = value;
    },
    DRP_data(value) {
      this.DRP_specify = value;
    },
    Closs_DRP(value) {
      this.DRP_showMessageBox = value;
    },

    //Shedule meeting
    update_Sh_customer_comment(value) {
      this.Sh_customer_comment = value;
    },
    update_Sh_internal_comment(value) {
      this.Sh_internal_comment = value;
    },
    update_Sh_email(value) {
      this.Sh_email = value;
    },
    update_Sh_sms(value) {
      this.Sh_sms = value;
    },
    Update_Sh_New_Date(value) {
      this.selected = value;
    },
    Update_Sh_New_Time(value) {
      this.videoCallTime = value;
    },
    Closs_Sh(value) {
      this.Sh_showMessageBox = value;
    },

    Closs_Att(value) {
      this.showAttachmentModel = value;
    },

    Close_Random_Question_Model(value) {
      this.showRandomQuestionnaireModel = value;
    },

    Closs_Viewer(value) {
      this.showAttachmenviewer = value;
    },


    DrpCheck() {
      const comp = this;
      comp.showFullScreenLoader();
      const data = {
        nic: comp.formData.nic_eic_number.customer_input,
      };
      console.log("check drp console 1", data);
      comp.DRPCustomerData.nic = comp.formData.nic_eic_number.customer_input;
      comp.DRPCustomerData.dob = comp.getFormattedDateTime( comp.formData.date_of_birth.customer_input);

      // get permanent address
      comp.DRPCustomerData.address = GlobalFunctions.getCustomerPermanentAddress(comp.formData)
      comp.DRPCustomerData.full_name = comp.formData.full_name.is_changed_by_call_agent ? comp.formData.full_name.call_agent_input : comp.formData.full_name.customer_input;
      comp.DRPCustomerData.issue_date = comp.getFormattedDateTime(comp.formData.nic_eic_issue_date.is_changed_by_call_agent ? comp.formData.nic_eic_issue_date.call_agent_input : comp.formData.nic_eic_issue_date.customer_input );

      NetworkManager.apiRequest(
        "api/KycApplication/checkDrp",
        data,
        function (e) {
          console.log("Response here ========", e)
          if (e.statusCode === 200) {
            if (e.data != null) {
              comp.DRPdata = e.data.drp_result;

              console.log("DRP customer data : ",comp.DRPCustomerData)
              console.log("check drp endpoint  : ",comp.DRPdata)

              comp.hideFullScreenLoader();
              comp.DRP_showMessageBox = true;
            }
            else {
              comp.hideFullScreenLoader();
              const params = {
                type: "is-danger",
                message: "Unable to check DRP. Try again.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
            }
          }
          else if (e.statusCode === 404) {
            // no records found
            comp.hideFullScreenLoader();
            BuefyHelper.showToastMessage("No records found", "is-danger");
            comp.checkDRPFailed =true;
          }
          else {
            comp.hideFullScreenLoader();
            comp.checkDRPFailed =true;
            const params = {
              type: "is-danger",
              message: "Unable to check DRP. Try again.",
              duration: 5000,
            };
            console.warn("Drp failed value===============",comp.checkDRPFailed)
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    reject_application: function () {
      const comp = this;
      //this.isButtonDisabled = true
      comp.showFullScreenLoader();
      let data = null;
      this.R_showMessageBox = false;
      if (comp.authStore.loggedInUser.role == "Exceptional Approver") {
        data = {
          id: comp.authStore.kyc_form_id,
          status: "EXCEPTIONAL-APPROVER-REJECTED",
          comment_for_internal_use: comp.R_internal_comment,
          reasons: comp.R_reasons,
        };
      } else {
        data = {
          id: comp.authStore.kyc_form_id,
          status: "REJECTED",
          comment_for_customer: comp.R_customer_comment,
          comment_for_internal_use: comp.R_internal_comment,
          reasons: comp.R_reasons,
        };
      }

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.R_isButtonDisabled = true;
            comp.R_showMessageBox = false;
            comp.hideFullScreenLoader();
            const params = {
              type: "is-success",
              message: "Application Rejected",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            if (comp.authStore.loggedInUser.role == "Exceptional Approver") {
              comp.$router.push("/RejectedapplicationsExceptional");
            } else {
              comp.$router.push("/rejectedApplications");
            }
          } else {
            comp.hideFullScreenLoader();
            comp.R_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    return_application: function () {
      const comp = this;
      comp.showFullScreenLoader();

      let data = null;

      this.RA_showMessageBox = false;

      if (comp.authStore.loggedInUser.role === "Exceptional Approver") {
        data = {
          id: comp.authStore.kyc_form_id,
          status: "EXCEPTIONAL-APPROVER-RETURNED",
          comment_for_customer: "",
          comment_for_internal_use: comp.RA_internal_comment,
          reasons: comp.RA_reasons,
        };
      } else {
        data = {
          id: comp.authStore.kyc_form_id,
          status: comp.RA_return_to,
          comment_for_internal_use: comp.RA_internal_comment,
          comment_for_customer: comp.RA_customer_comment,
          reasons: comp.RA_reasons,
        };
      }

      console.log(data, "data");

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.RA_isButtonDisabled = true;
            comp.RA_showMessageBox = false;
            comp.hideFullScreenLoader();
            const params = {
              type: "is-success",
              message: "Application Returned",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            if (comp.authStore.loggedInUser.role == "Exceptional Approver") {
              let tabValue;
              const actionUserId = comp.latestUpdateUserRoleId;
              if (actionUserId === 3) {
                //tab 0
                tabValue = 0;
              } else if (actionUserId === 4) {
                //tab 1
                tabValue = 1;
              } else if (actionUserId === 5) {
                //tab 2
                tabValue = 2;
              }
              console.log("track tab value", tabValue);

              setTimeout(() => {
                comp.$router.push({
                  path: "/ReturnedapplicationsExceptional",
                  query: { tabId: tabValue },
                });
              }, 200);

              //comp.$router.push("/ReturnedapplicationsExceptional");
            } else {
              comp.$router.push("/returnedapplications");
            }
          } else {
            comp.hideFullScreenLoader();
            comp.RA_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    exceptional_Approver: function (comments) {
      const comp = this;
      comp.showFullScreenLoader();

      this.EA_showMessageBox = false;

      const data = {
        id: comp.authStore.kyc_form_id,
        status: "SUBMITTED-EXCEPTIONAL-APPROVER",
        comment_for_internal_use: comments.commentForInternal,
        comment_for_customer: comments.commentForCustomer,
      };

      console.log(data);

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.EA_isButtonDisabled = true;
            comp.EA_showMessageBox = false;
            comp.hideFullScreenLoader();
            const params = {
              type: "is-success",
              message: "Application Sent for Exceptional Approval",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            comp.$router.push("/exceptionalapproval");
          } else {
            comp.hideFullScreenLoader();
            comp.EA_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    refer_to_Branch: function (comments) {
      const comp = this;
      comp.showFullScreenLoader();

      this.RB_showMessageBox = false;

      const data = {
        id: comp.authStore.kyc_form_id,
        comment_to_customer: comments.commentForCustomer,
        comment_to_internal_use: comments.commentForInternal,
      };

      console.log("data", data);

      NetworkManager.apiRequest(
        "api/KycApplication/referToBranch",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.RB_isButtonDisabled = true;
            comp.RB_showMessageBox = false;
            comp.hideFullScreenLoader();
            const params = {
              type: "is-success",
              message: "Application Sent for Refer to Branch.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            comp.$router.push("/refertobranch");
          } else {
            comp.hideFullScreenLoader();
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    Approve_Application: function (comments) {
      const comp = this;
      comp.showFullScreenLoader();

      let data = null;
      this.App_showMessageBox = false;

      if (comp.authStore.loggedInUser.role === "Exceptional Approver") {
        data = {
          id: comp.authStore.kyc_form_id,
          comment_for_customer: comments.commentForCustomer,
          comment_for_internal_use: comments.commentForInternal,
          status: "EXCEPTIONAL-APPROVER-APPROVED",
        };
      }

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.App_isButtonDisabled = true;
            comp.App_showMessageBox = false;
            comp.hideFullScreenLoader();
            const params = {
              type: "is-success",
              message: "Application Approved.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;

            if (comp.authStore.loggedInUser.role === "Exceptional Approver") {
              comp.$router.push("/ApprovedApplicationsExceptional");
            }
          } else {
            comp.hideFullScreenLoader();
            comp.App_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    createCIFOpenAccountsAndApproveKyc(comments, useOldComments = false) {
      const comp = this;

      comp.showCreateCifAndOpenAccountApproveMsgBox = false;

      console.log("Create CIF and Open accounts ==========");
      console.log("Kyc Form Id : " + comp.authStore.kyc_form_id);
      console.log("Comments : ", comments);

      // show processing pop up
      comp.cifPopUpModals.showWaitingForCifCreationProcessModal = true;

      ApiRequestManager.createCIFAndOpenAccounts(
        comp.authStore.kyc_form_id,
        comments.commentForInternal,
        comments.commentForCustomer,
        useOldComments,
        (response) => {
          console.log("response");
          console.log(response);

          // hide pop up
          comp.cifPopUpModals.showWaitingForCifCreationProcessModal = false;

          if (response.statusCode === 200) {
            // success api responses
            console.log(response.data.message);

            if (response.data.cif_creation_and_open_accounts_success) {
              // successfully created the CIF and opened the accounts

              // get cif number and account details
              comp.approvedKycDFCCData.cifNumber = response.data.cif_number;
              comp.approvedKycDFCCData.productDetails = [];

              response.data.created_product_details.forEach(
                (createdProduct) => {
                  console.log("current created product", createdProduct);
                  comp.approvedKycDFCCData.productDetails.push(
                    new CreatedProductDetailDTO(
                      createdProduct.account_type,
                      createdProduct.account_number
                    )
                  );
                }
              );

              // show success popup
              comp.cifPopUpModals.showSuccessfulCifCreationAndAccOpeningModal = true;
            } else if (
              response.data.t24_cif_error ||
              response.data.inao_record_from_t24
            ) {
              // cif error or record status is INAO from dfcc side

              // show cif error modal -> move to retry queue
              comp.cifPopUpModals.showCifErrorRetryQueueModal = true;
            } else if (response.data.t24_cif_overrides) {
              // CIF Overrides situation
              // show cif overrides modal -> move to overrides queue
              comp.cifPopUpModals.showCifErrorOverridesModal = true;
            } else if (response.data.fcm_rejected) {
              // FCM rejected the customer situation
              // move to fcm rejected queue
              comp.cifPopUpModals.showFCMRejectModal = true;
            } else if (response.data.t24_signature_error) {
              // T24 signature error situation
              // show signature error pop up and move to signature error queue queue
              comp.cifPopUpModals.showSignatureErrorModal = true;
            } else if (response.data.t24_account_creation_error) {
              // T24 account creation error situation

              // get error type
              console.log(
                "Product error type : " +
                  response.data.t24_account_creation_error_type
              );

              if (
                response.data.t24_account_creation_error_type === "CURRENCY"
              ) {
                comp.approvedKycDFCCData.productErrorTextForPopUp =
                  "Currency Change";
              } else if (
                response.data.t24_account_creation_error_type === "PRODUCT"
              ) {
                comp.approvedKycDFCCData.productErrorTextForPopUp =
                  "Product Change";
              } else {
                comp.approvedKycDFCCData.productErrorTextForPopUp =
                  "Other Error";
              }

              // show product error pop up and move to signature error queue queue
              comp.cifPopUpModals.showProductErrorModal = true;
            }
          } else if (response.statusCode === 503) {
            // apis are not enabled

            console.warn("T24 APIs are not enabled");
            BuefyHelper.showToastMessage(
              "Unable to approve. Try again",
              "is-danger"
            );

            // hide popup
            // comp.cifPopUpModals.showWaitingForCifCreationProcessModal = false
          } else if (response.statusCode === 500) {
            // internal server errors

            console.warn("Internal error. Refer to internal logs");
            BuefyHelper.showToastMessage(
              "Unable to approve. Try again",
              "is-danger"
            );
          } else {
            // 400 bad requests
          }
        }
      );
    },

    Su_to_Approver: function (comment) {
      const comp = this;

      //this.isButtonDisabled = true
      comp.showFullScreenLoader();

      this.Su_to_App_showMessageBox = false;

      const data = {
        id: comp.authStore.kyc_form_id,
        status: "SUBMITTED-APPROVER",
        comment_for_internal_use: comment,
      };

      console.log(data, "data");

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.Su_to_App_isButtonDisabled = true;
            comp.Su_to_App_showMessageBox = false;
            comp.hideFullScreenLoader();

            const params = {
              type: "is-success",
              message: "Application Sent for approval",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            comp.$router.go(-1);
          } else {
            comp.hideFullScreenLoader();
            comp.Su_to_App_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    Re_Su_to_Approver: function (comment) {
      const comp = this;

      //this.isButtonDisabled = true
      comp.showFullScreenLoader();

      this.Su_to_App_showMessageBox = false;

      const data = {
        id: comp.authStore.kyc_form_id,
        status: "ASSIGNED-APPROVER",
        comment_for_internal_use: comment,
      };

      console.log(data, "data");

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.Su_to_App_isButtonDisabled = true;
            comp.Su_to_App_showMessageBox = false;
            comp.hideFullScreenLoader();

            const params = {
              type: "is-success",
              message: "Application Sent for approval",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            comp.$router.go(-1);
          } else {
            comp.hideFullScreenLoader();
            comp.Su_to_App_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    hold_application: function () {
      const comp = this;
      comp.showFullScreenLoader();

      this.HA_showMessageBox = false;

      const data = {
        id: comp.authStore.kyc_form_id,
        status: "HOLD",
        comment_for_customer: comp.HA_customer_comment,
        comment_for_internal_use: comp.HA_internal_comment,
        reasons: comp.HA_reasons,
      };

      console.log(data);

      NetworkManager.apiRequest(
        "api/KycApplication/ApplicationStatusChange",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.HA_isButtonDisabled = true;
            comp.HA_showMessageBox = false;
            comp.hideFullScreenLoader();
            const params = {
              type: "is-success",
              message: "Application Hold",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
            comp.$root.isSideBarHidden = !comp.$root.isSideBarHidden;
            comp.$router.push("/holdApplications");
          } else {
            comp.hideFullScreenLoader();
            comp.HA_isButtonDisabled = false;
            const params = {
              type: "is-warning",
              message: "Something went wrong!.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    Send_DRP_Status (state) {
      const comp = this;

      const data = {
        is_manually_verified: true,
        kyc_form_id: comp.authStore.kyc_form_id,
        comment: comp.manualDRPComment,
        attachment: comp.base64Image,
        status: state === "pass",
      }

      console.log("BASE64IMAGE ATTACHED:", comp.base64Image)
      console.log("req body ", data)

      comp.DRP_showMessageBox = false;
      comp.manualDRP = false;

      comp.showFullScreenLoader()

      NetworkManager.apiRequest("api/KycApplication/SaveDRPStatus", data,function (e) {
        if (e.statusCode === 200) {
          BuefyHelper.showToastMessage("DRP status saved successfully","is-success")
          comp.hideFullScreenLoader()
          setTimeout(() => { comp.refreshPage(); }, 200);
        }
        else{
          comp.hideFullScreenLoader()
          BuefyHelper.showToastMessage("DRP status saving failed","is-danger")
          }
      });
    },

    DRP_success: function () {

      console.log("drp success event called. calling endpoint ....")
      const comp = this;
      //this.isButtonDisabled = true

      this.DRP_showMessageBox = false;
      const data = {
        kyc_form_id: comp.authStore.kyc_form_id,
        comment: comp.DRP_internal_comment,
        status: true,
      };
      console.log("DRP PASS DATA----->",data)

      NetworkManager.apiRequest(
        "api/KycApplication/SaveDRPStatus",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.DRP_showMessageBox = false;
            const params = {
              type: "is-success",
              message: "DRP status saved successfully.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);

            comp.GetApplicationData();
            comp.refreshPage();
          }
        }
      );
    },

    DRP_fail: function () {
      const comp = this;
      //this.isButtonDisabled = true

      this.DRP_showMessageBox = false;
      const data = {
        kyc_form_id: comp.authStore.kyc_form_id,
        comment: comp.DRP_internal_comment,
        status: false,
      };
      console.log("DRP FAIL DATA----->",data)
      NetworkManager.apiRequest(
        "api/KycApplication/SaveDRPStatus",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.DRP_showMessageBox = false;
            const params = {
              type: "is-success",
              message: "DRP status saved successfully.",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);

            comp.GetApplicationData();
            comp.refreshPage();
          }
        }
      );
    },

    refreshPage() {
      this.$router.go(0);
    },

    sendReScheduleMeeting: function () {
      const comp = this;
      comp.showFullScreenLoader();
      this.Sh_showMessageBox = false;
      const data = {
        id: comp.authStore.kyc_form_id,
        date: comp.selected.toISOString(),
        time: comp.videoCallTime,
        comment_for_customer: comp.Sh_customer_comment,
        comment_for_internal_use: comp.Sh_internal_comment,
        send_reminder: true,
        is_reschedule: false,
        is_email: comp.Sh_email,
        is_sms: comp.Sh_sms,
      };
      NetworkManager.apiRequest(
        "api/KycApplication/scheduleVideoCall",
        data,
        function (e) {
          //console.log("send Schedule Meeting",e.data)
          if (e.statusCode === 200) {
            comp.Sh_isButtonDisabled = true;
            comp.SH_showMessageBox = false;
            comp.hideFullScreenLoader();
            if (
              comp.Schedule_or_Reschedule ===
              "Schedule a Video Call & Submit to Caller"
            ) {
              const params = {
                type: "is-success",
                message: "Video Call has been Scheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.$router.push("/schedulevideocall");
              comp.refreshPage();
            } else if (
              comp.Schedule_or_Reschedule === "Reminder for video call"
            ) {
              const params = {
                type: "is-success",
                message: "Video Call Reminder has been Sent successfully.",
                duration: 5000,
              };

              comp.$buefy.toast.open(params);
              comp.refreshPage();
            } else {
              comp.Sh_isButtonDisabled = false;
              comp.hideFullScreenLoader();
              const params = {
                type: "is-success",
                message: "Video Call has been Rescheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.refreshPage();
            }
          } else {
            comp.hideFullScreenLoader();
            const params = {
              type: "is-warning",
              message: "Failed to Reschedule the videocall",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    sendScheduleMeeting: function () {
      const comp = this;
      comp.showFullScreenLoader();
      this.Sh_showMessageBox = false;
      const data = {
        id: comp.authStore.kyc_form_id,
        date: comp.selected.toISOString(),
        time: comp.videoCallTime,
        comment_for_customer: comp.Sh_customer_comment,
        comment_for_internal_use: comp.Sh_internal_comment,
        is_reschedule: comp.Schedule_or_Reschedule !== "Schedule a Video Call",
        send_reminder: comp.schedule_button_name === "Reminder for video call",
        is_email: comp.Sh_email,
        is_sms: comp.Sh_sms,
      };

      NetworkManager.apiRequest(
        "api/KycApplication/scheduleVideoCall",
        data,
        function (e) {
          //console.log("send Schedule Meeting",e.data)
          if (e.statusCode === 200) {
            comp.Sh_isButtonDisabled = true;
            comp.Sh_showMessageBox = false;
            comp.hideFullScreenLoader();
            console.warn(
              "schedule or reschedule " + comp.Schedule_or_Reschedule
            );

            if (comp.Schedule_or_Reschedule === "Schedule a Video Call") {
              const params = {
                type: "is-success",
                message: "Video Call has been Scheduled successfully.",
                duration: 5000,
              };
              comp.$buefy.toast.open(params);
              comp.$router.push("/schedulevideocall");
              comp.refreshPage();
            } else {
              comp.Sh_isButtonDisabled = false;
              comp.hideFullScreenLoader();

              if (comp.schedule_button_name === "Reminder for video call") {
                const params = {
                  type: "is-success",
                  message: "Video Call Reminder has been Sent successfully.",
                  duration: 5000,
                };
                comp.$buefy.toast.open(params);
                comp.refreshPage();
              } else {
                const params = {
                  type: "is-success",
                  message: "Video Call has been Rescheduled successfully.",
                  duration: 5000,
                };
                comp.$buefy.toast.open(params);
                comp.refreshPage();
              }
            }
          } else {
            comp.hideFullScreenLoader();
            const params = {
              type: "is-warning",
              message: "Failed to Reschedule the videocall",
              duration: 5000,
            };
            comp.$buefy.toast.open(params);
          }
        }
      );
    },

    showIDF() {
      this.IDF = true;
      this.IDB = false;
      this.SLF = false;
    },
    showIDB() {
      this.IDF = false;
      this.IDB = true;
      this.SLF = false;
    },
    showSelfi() {
      this.IDF = false;
      this.IDB = false;
      this.SLF = true;
    },
    idfRotateClockwise() {
      this.idfRotateAngle += 90;
    },
    idbRotateClockwise() {
      this.idbRotateAngle += 90;
    },

    ppbioRotateClockwise() {
      this.ppbioRotateAngle += 90;
    },
    ppaltRotateClockwise() {
      this.ppaltRotateAngle += 90;
    },
    ppsRotateClockwise() {
      this.ppsRotateAngle += 90;
    },

    addressrotateClockwise() {
      this.addressrotateAngle += 90;
    },
    visarotateClockwise() {
      this.visarotateAngle += 90;
    },
    imageplaceholder(event) {
      event.target.src = this.defaultimg;
    },

    downloadVideo() {
      var video_name = this.formData.reference_number + ".mp4";

      var xhr = new XMLHttpRequest();
      xhr.open("GET", this.videoCallDetail.video_call_recording, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.target = "_blank";

        tag.download = video_name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = (err) => {
        alert("Failed to download video");
      };
      xhr.send();
    },

    incrementCount() {
      if (this.count <= 5) {
        this.count++;

        // Create file upload input element
        // const input = document.createElement("input");
        // input.type = "file";
        // input.accept = "image/*";
        //
        // // Listen for file selection
        // input.addEventListener("change", (event) => {
        //   const file = event.target.files[0];
        //
        //   // Create file reader
        //   // const reader = new FileReader();
        //
        //   // Listen for file loading
        //   reader.addEventListener("load", () => {
        //     // Add new card with image data URL
        //     this.cards.push({
        //       count: this.count,
        //       image: reader.result,
        //       description: "",
        //     });
        //   });
        //
        //   if (reader.result != null) {
        //     handleImageClick(card);
        //   }
        //   // Read file as data URL
        //   reader.readAsDataURL(file);
        // });
        //
        // // Click file upload input element
        // input.click();
        if (card.count <= 5) {
          this.addCard();
        }
      }
    },

    removeImage(cardID) {
      this.cards.forEach((card) => {
        if (card.id == cardID) {
          card.image = "";
          card.type = "";
          card.file = "";
        }
      });
    },

    handleImageClick(cardid) {
      this.selectedCardID = cardid;
      this.$refs.upload.click();

      // this.isActive = true

      // const input = document.createElement("input");
      // input.type = "file";
      // input.accept = "image/*";
      //
      // input.addEventListener("change", (event) => {
      //   const file = event.target.files[0];
      //   card.file = event.target.files[0];
      //   const reader = new FileReader();
      //
      //   reader.addEventListener("load", () => {
      //     card.image = reader.result;
      //
      //     if (card.id === this.count && this.count < this.maxCards) {
      //       this.addCard();
      //     }
      //   });
      //   reader.readAsDataURL(file);
      // });
      // input.click();
    },

    addCard(image) {
      this.count++;

      this.cards.push({
        id: this.count,
        image: image,
        type: "",
        file: "",
        description: "",
      });
    },

    removeCard(card) {
      const index = this.cards.indexOf(card);
      if (index > -1) {
        this.cards.splice(index, 1);
        this.count--;

        // if (this.count < this.maxCards && this.cards.length === this.maxCards) {
        //   this.cards.push({
        //     id: this.maxCards,
        //     image: "",
        //     description: "",
        //   });
        // }
      }
      if (index == 0) {
        this.addCard();
      }
    },

    GetApplicationData: function () {
      const comp = this;
      const data = { id: comp.authStore.kyc_form_id };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
        "api/KycApplication/getApplicationById",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.formData = e.data.kycApplication;

            console.log("get application by id data ===============", e.data);

            // get nic ocr data
            comp.nicFrontOcrResult =
                new NicOcrResultDTO(comp.formData.ocr_nic_front_type, comp.formData.nic_front_ocr_data);
            comp.nicRearOcrResult =
                new NicOcrResultDTO(comp.formData.ocr_nic_rear_type, comp.formData.nic_rear_ocr_data);

            comp.drpAttempt = e.data.drpAttempt
            comp.applicationstatus = e.data.isKycApplicationVideoCallComplete;
            comp.products = e.data.productSelection;
            comp.getAllInternalComments = e.data.getAllInternalComments;
            comp.getCommitKycDetails = e.data.getCommitKycDetails;
            comp.drpArray = e.data.drpStatusList;
            //console.log("DRP ARRAY",comp.drpArray)
            comp.returnReasonDetail = e.data.returnReasonDetail;
            comp.isReworkedApplication = e.data.isReworkedApplication;
            comp.customerCifNumber = e.data.customerCIFNumber;
            // comp.detailReviewerAttachments = comp.formData.agent_upload_hold_application_attachments
            // comp.callAgentAttachments = comp.formData.agent_upload_hold_application_attachments_call_agent
            comp.productErrorDetail = e.data.productErrorDetail;
            comp.latestT24Overrides = e.data.latestT24Overrides;
            comp.isInternalCommentAddedForFcmRejectApplication =
              e.data.isInternalCommentAddedForFcmRejectApplication;

            comp.videoCallDetail = e.data.videoCallDetail;
            // console.log("vid call",comp.videoCallDetail )

            comp.videoCallTime = e.data.kycApplication.video_call_time;
            let dateStr = e.data.kycApplication.video_call_date;
            comp.selected = new Date(dateStr);
            console.log("customer branch", e.data.customerBranch);
            comp.customer_branch = e.data.customerBranch;
            comp.agent_branch = e.data.agentBranch;
            comp.latestUpdateUserRoleId = e.data.latestUpdatedUserModel.role_id;
            // get customer location
            let customerLocation = JSON.parse(
              e.data.kycApplication.customer_location
            );
            //console.log("customer location")
            //console.log(customerLocation)
            comp.kycFormCustomerLocation = new GeoPositionCoordinates(
              customerLocation.latitude,
              customerLocation.longitude
            );
            comp.showCustomerLocation = true;

            // get video call location if available
            if (comp.videoCallDetail !== null) {
              let videoCallCustomerLocation = JSON.parse(
                comp.videoCallDetail.video_call_location
              );
              //console.log("video call location")
              //console.log(videoCallCustomerLocation)
              comp.videoKycCustomerLocation = new GeoPositionCoordinates(
                videoCallCustomerLocation.latitude,
                videoCallCustomerLocation.longitude
              );
              comp.showVideoCallCustomerLocation = true;
            }
            console.log("---------------------------->>>>>>>>>>>>>>>>>>>>>>>>>",this.reviewOnly)
            comp.hideFullScreenLoader();
          } else {
            // BuefyHelper.showToastMessage("Unable to get the application data", "is-danger")
            comp.hideFullScreenLoader();
          }
        }
      );
    },

     getCurrentDate: function () {

      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      this.currentDate = `${year}-${month}-${day}`;
    },

    //get PEP and Sanction Data

     getPepSanctionData: function () {
      console.log('sanction innnn');
      const comp = this;
      const data = { name: comp.formData.full_name.customer_input,
                     applicant_id: "uhi4hi-3safdsa3-asdfj", // currently have used the application id from SAMS side. this doesn't affect response data
                     screen_type: "initial",
                     search_type: "individual",
                     target_date: this.currentDate,
                     application_id : "uhi4hi-3safdsa3-asdfj", // currently have used the application id from SAMS side. this doesn't affect response data
                     sanction_field : true,
                     legal_field : false,
                     corporate_field : true,
                     pep_field : true,
                     ams_field : true,
                     risk_rating_field : true };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
        "api/PepSanctionResult/getSearchResults",
        data,
        function (e) {
          console.log('e.statusCode', e.statusCode);
          if (e.statusCode === 200) {
            comp.pepSanctionData = e.data;
            comp.searchId = comp.pepSanctionData.search_ID
            comp.pepData = comp.pepSanctionData.peP_Result;
            console.log('peppppp', comp.pepData);
            comp.sanctionData = comp.pepSanctionData.sanctions_Result;
            comp.amsDetails = comp.pepSanctionData.amS_Result
            comp.getCustomerRiskRatingData(); 
            comp.hideFullScreenLoader();
            comp.showPepSanctionResult = true;

            console.log("get pep and sanction data ===============", e.data);
            console.log("get pep  data ===============", comp.pepSanctionData.peP_Result);
            console.log("get sanction  data ===============", comp.pepSanctionData.sanctions_Result);


          }
          else {
            comp.hideFullScreenLoader();
            BuefyHelper.showToastMessage("Unable to get the pep sanction data", "is-danger")
          }
        }
      );
    },

    //get AMS results

    getAMSData: function () {

      const comp = this;
      const data = { name: comp.formData.full_name.customer_input,
                     applicant_id: "uhi4hi-3safdsa3-asdfj", // currently have used the application id from SAMS side. this doesn't affect response data
                     screen_type: "initial",
                     search_type: "individual",
                     target_date: this.currentDate,
                     application_id : "uhi4hi-3safdsa3-asdfj", // currently have used the application id from SAMS side. this doesn't affect response data
                     sanction_field : true,
                     legal_field : false,
                     corporate_field : true,
                     pep_field : true,
                     ams_field : true,
                     risk_rating_field : true,
                     };

      comp.showFullScreenLoader();

      NetworkManager.apiRequest(
        "api/PepSanctionResult/getSearchResults",
        data,
        function (e) {
          console.log('e.statusCode', e.statusCode);
          if (e.statusCode === 200) {
            console.log("AMSSSS data ===============", e.data.amS_Result);
            comp.amsData = e.data.amS_Result;
             console.log("AMSSSS data ===============", comp.amsData);
            comp.hideFullScreenLoader();
            comp.showAmsResult = true;
          }
          else {
            comp.hideFullScreenLoader();
            BuefyHelper.showToastMessage("Unable to get AMS data", "is-danger")
          }
        }
      );
    },


    //get customer risk rating data

     getCustomerRiskRatingData() {

      const comp = this;

    if (comp.pepSanctionData) {
        const pepIds = comp.pepSanctionData.peP_Result.map(item => item.id);
        const sanctionIds = comp.pepSanctionData.sanctions_Result.map(item => item.id);

        const requestBody = {
          search_id : comp.searchId,
          applicant_name : comp.formData.full_name.customer_input,
          pep_data : comp.pepSanctionData,
          ams_data: comp.amsDetails,
          sanction_data : comp.sanctionData,
          remarks: "These are the comments added by analyst!",
          status: "accept",
          results: {
              pep_result: pepIds.map(id => ({ id, status: true, Remark: "okk" })),
              sanction_result: sanctionIds.map(id => ({ id, status: true, Remark: "okk" })),
          }
      };

        const requestBodyJSON = JSON.stringify(requestBody);

        NetworkManager.apiRequest('api/PepSanctionResult/getCustomerRiskRatingResults', requestBodyJSON, function (response) {
          if (response.statusCode === 200) {
              comp.customerRiskRatingData = response.data.results;
              console.log('====customerRiskRatingData===', comp.customerRiskRatingData);
              //BuefyHelper.showToastMessage("Customer Risk Rating Results retreived Successfully", "is-success");

          } else {
              console.log('statusCode', response.statusCode);
              BuefyHelper.showToastMessage("Customer Risk Rating Results retreival Failed", "is-danger");
              //location.reload();
          }
  });
}

  },


    checkforReview: function () {
      const checkif = this;
      const comp = this;
      const data = { id: comp.authStore.kyc_form_id };
      NetworkManager.apiRequest(
        "api/KycApplication/getApplicationById",
        data,
        function (e) {
          if (e.statusCode === 200) {
            checkif.varcheker = e.data.kycApplication.current_status;
            if (checkif.varcheker == "NEW" || "SUBMITTED-CALL-AGENT") {
              return false;
            } else {
              return true;
            }
          }
        }
      );
    },
    onUpload: function () {
      console.log("on upload -------------------");
      const comp = this;
      if (comp.file !== null && comp.file["type"].split("/")[0] === "image") {
        console.log("uploaded image size (bytes): " + comp.file.size);
        // validate image size
        if (comp.file.size <= 5245329) {
          comp.imageToCrop = URL.createObjectURL(comp.file);
          comp.isActive = false;
          comp.initiateImageCrop();
        }
        else {
          comp.file = [];
          BuefyHelper.showToastMessage(
            "Please select an image of the maximum size of 5MB",
            "is-danger"
          );
        }
      }
      else if (comp.file != null && comp.file["type"] === "application/pdf") {
        console.log("uploaded pdf size (bytes): " + comp.file.size);
        this.cards.forEach((card) => {
          if (card.id == this.selectedCardID) {
            card.image = this.croppedImg;
            card.type = "pdf";
            card.file = comp.file;
          }

          if (this.count < this.maxCards) {
            this.addCard(this.defaultimg);
          }
        })
      }
      else {
        console.log("file type", comp.file["type"]);
        BuefyHelper.showToastMessage("Select an image or pdf file", "is-danger");
      }
    },

    cropImage: function () {
      var croppedImageCanvas = this.$refs.cropper.getCroppedCanvas();
      var croppedImageFile = null;

      //console.log(croppedImageCanvas);

      this.croppedImg = croppedImageCanvas.toDataURL();
      this.imageUrl = this.croppedImg;

      this.cropModalIsVisible = false;
      // this.$emit('on-captured', this.croppedImg) // original code

      // convert canvas into a blob
      var fileName = "attachment" + this.count + ".jpg";

      croppedImageCanvas.toBlob((blob) => {
        let file = new File([blob], fileName, { type: "image/jpeg" });
        this.cards.forEach((card) => {
          if (card.id == this.selectedCardID) {
            card.image = this.croppedImg;
            card.type = "image";
            card.file = file;
          }
        });

        // add new card
        if (this.count < this.maxCards) {
          this.addCard(this.defaultimg);
        }
      }, "image/jpeg");

      console.log("file cropped");
    },

    clearselect: function () {
      this.cropModalIsVisible = false;
      this.file = null;
      this.croppedImg = null;
      this.imageUrl = null;
    },

    initiateImageCrop: function () {
      this.cropModalIsVisible = true;
    },

    submitimages: function () {
      const data = new FormData();
      data.append(`kyc_form_id`, this.authStore.kyc_form_id);
      data.append(
        "is_call_agent",
        this.hasPermission("assign-scheduled-application")
      );
      for (let i = 0; i < this.cards.length; i++) {
        console.log(this.cards[i]["type"]);
        console.log(this.cards[i]["file"]);
        console.log(this.cards[i]["description"]);
        if (this.cards[i]["image"]) {
          data.append(`attachments_file${i + 1}`, this.cards[i]["file"]);
          data.append(`attachments_comment${i + 1}`,this.cards[i]["description"]);
          data.append(`attachments_type${i + 1}`,this.cards[i]["type"]);
        }
      }
      this.showFullScreenLoader();
      NetworkManager.apiRequest(
        "api/KycApplication/UploadAttchmentsInHold",
        data,
        (response) => {
          if (response.statusCode == 200) {
            const params = {
              type: "is-success",
              message: "Attachments upload successfully",
              duration: 5000,
            };
            this.$buefy.toast.open(params);
            this.hideFullScreenLoader();
            this.GetApplicationData();
          } else {
            this.hideFullScreenLoader();
            const params = {
              type: "is-warning",
              message: "Attachments upload failed",
              duration: 5000,
            };
            this.$buefy.toast.open(params);
          }
        },
        false,
        "multipart/form-data"
      );
    },
  },
  computed: {
    GlobalAttributes() {
      return GlobalAttributes;
    },
    GlobalFunctions() {
      return GlobalFunctions;
    },
    showButton() {
      return this.count <= this.maxCards;
    },

    ShowAddCommentForFCMRejectApplicationBtn() {
      const comp = this;
      return (
        comp.hasPermission("approve-application") &&
        comp.formData.current_status === "FCM-REJECTED"
      );
    },

    DisableAddCommentForFCMRejectedApplicationBtn() {
      const comp = this;
      return comp.isInternalCommentAddedForFcmRejectApplication;
    },

    ShowManuallyCheckForSignErrorApplicationBtn() {
      const comp = this;
      return (
        comp.hasPermission("approve-application") &&
        (comp.formData.current_status === "T24-SIGN-CREATE-ERROR" ||
          comp.formData.current_status === "T24-SIGN-UPLOAD-ERROR")
      );
    },

    ShowManuallyRejectFromCIFAccFlowBtn() {
      const comp = this;
      return (
        comp.hasPermission("approve-application") &&
        (comp.formData.current_status === "T24-CIF-OVERRIDES" ||
          comp.formData.current_status === "T24-ACC-CREATION-ERROR")
      );
    },

    DisableCreateCIFAndOpenAccountsBtn() {
      const comp = this;
      console.warn("product errors ", comp.productErrorDetail);
      return comp.productErrorDetail.product_error === true;
    },

    ShowCreateCIFAndOpenAccountsBtn() {
      const comp = this;
      return (
        comp.hasPermission("approve-application") &&
        (comp.formData.current_status === "ASSIGNED-APPROVER" ||
          comp.formData.current_status === "T24-CIF-ERROR" ||
          comp.formData.current_status === "EXCEPTIONAL-APPROVER-RETURNED" ||
          comp.formData.current_status === "T24-ACC-CREATION-ERROR" ||
          comp.formData.current_status === "T24-SIGN-CREATE-ERROR" ||
          comp.formData.current_status === "T24-SIGN-UPLOAD-ERROR" ||
          comp.formData.current_status === "EXCEPTIONAL-APPROVER-APPROVED" ||
          comp.formData.current_status === "EXCEPTIONAL-APPROVER-REJECTED")
      );
    },

    GetCreateCIFAndOpenAccountsBtnLabel() {
      // TODO :: remove cif part
      const comp = this;

      if (
        comp.formData.current_status === "ASSIGNED-APPROVER" ||
        comp.formData.current_status === "EXCEPTIONAL-APPROVER-APPROVED" ||
        comp.formData.current_status === "EXCEPTIONAL-APPROVER-RETURNED" ||
        comp.formData.current_status === "EXCEPTIONAL-APPROVER-REJECTED"
      ) {
        return "Approve";
      } else {
        return "Retry";
      }
      // return comp.formData.current_status === 'ASSIGNED-APPROVER' ? "Approve" : "Retry";
    },
  },

  // lifecycle hooks ===================================================================================================
  created() {
    const comp = this;
    // listening to global events

    GlobalEventManager.$on(
      GlobalEvents.admin.kycApplications.showInputIndustryModal,
      () => {
        comp.showAddIndustryModal = true;
      }
    );

    GlobalEventManager.$on(
      GlobalEvents.admin.kycApplications.hideInputIndustryModal,
      () => {
        comp.showAddIndustryModal = false;
      }
    );

    GlobalEventManager.$on(
      GlobalEvents.admin.kycApplications.hideInputIndustryModalAndLoadData,
      () => {
        comp.showAddIndustryModal = false;
        comp.GetApplicationData();
      }
    );
  },
  watch:{
    "DRPImage": function () {
      let comp = this;
      if (this.DRPImage) {
        // this.convertToBase64()
        this.getBase64(this.DRPImage)
        console.log("BTOA,", this.base64Image )
      }
    },
  },
  mounted() {

     this.getCurrentDate();


    console.warn("Is review only : " + this.reviewOnly);
    if (!this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
    if (this.hasPermission("assign-scheduled-application")) {
      this.Schedule_or_Reschedule = "Reminder for video call";
      this.schedule_button_name = "Reminder for video call";
    }
    this.GetApplicationData();
    console.log("APPATSIRI AUTH STORE", this.authStore);
    // setTimeout(() => {
    //     this.generatePDF(authStore.kyc_form_id);
    // }, 300);
  },
};
</script>

<style lang="scss" scoped>
.attachment-btn{
  min-width: -webkit-fill-available;
  color: white;
  background: red;
  min-height: 55px;
}

#input-separator {
  margin-bottom: 20px;
}
p {
  color: #64676a;
}
p span {
  color: #231f20;
  font-family: "Gotham-Light";
}
.check p {
  color: #231f20;
  text-transform: uppercase;
  font-family: "Gotham-Bold";
}
.wrapper {
  position: relative;
}
.image {
  width: 960px;
  height: 540px;
  border: 2px solid #000000;
  border-radius: 25px;
  padding: 20px;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.rating-wrapper label {
  font-size: medium;
}

crop-content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.modal-card {
  border-radius: 0;
}
.non-interactable-input {
  pointer-events: none;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.coer img {
  margin: 0 auto;
  display: block;
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}

.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 90%;
  height: auto;
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}

.web-camera-container .camera-shoot {
  margin: 1rem 0;
}

.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}

.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}
.manual-drp-model {
  max-width: 28vw;
  background-color: white;
  border: 2px solid #000000;
  border-radius: 25px;
  padding: 30px;
  z-index: 2;
  overflow:auto !important;
}
</style>
