import VideoCallAttachment from "./videoCallAttachment";

export default class  ImageUploadDetail {
    face_and_nic_front_side_image = new VideoCallAttachment()
    face_and_nic_back_side_image = new VideoCallAttachment()
    face_and_passport_bio_page_image = new VideoCallAttachment()
    face_and_passport_alteration_page_image = new VideoCallAttachment()
    face_and_passport_entry_stamp_page_image = new VideoCallAttachment()
    face_and_visa_image = new VideoCallAttachment()
    signature_image = new VideoCallAttachment()
    selfie_image = new VideoCallAttachment()
    address_proof_document_image = new VideoCallAttachment()

    constructor() {

    }

}