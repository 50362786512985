<template>
    <div :class="GetWrapperClass">
        <span style="font-family: Gotham-Light; font-size: 14px; font-weight: bold; pointer-events: none;" :class="GetItemClass">{{label}}</span>

        <div style="margin-top: 10px">
          <div style="display: grid; margin-left: 10px; ">
            <label style="pointer-events: none;" :class="GetItemClass" >{{value}}
            </label>
          </div>
          <!-- <b-icon 
            :icon="copyable? 'content-copy' : ''"
            @icpn-click="copyToClipboard(value)"
            icon-clickable
            size="is-medium"
            type="is-primary">
          </b-icon> -->
          <div style="margin-left:92%" v-if="copyable">
            <b-button :icon-right="copyable? 'content-copy' : ''" @click="copyToClipboard(value)" size=is-medium type="is-primary" inverted></b-button>
          </div>
        </div>
  </div>
</template>

<script>
import { BuefyHelper } from '../helpers/bufeyHelper';
export default {
  name: "FullNameView",

  props: {
    copyable:{
      type : Boolean,
      required : false,
      default : false
    },
    isAgent : {
      type : Boolean,
      required : true,
      default : false
    },

    value:{
      type: String,
      required : true
    },

    label :{
      type : String,
      required : true
    }
  },

  computed : {
    GetWrapperClass(){
      const comp = this
      return comp.isAgent ? 'wrapper-agent' : 'wrapper-customer';
    },

    GetItemClass(){
      const comp = this
      return comp.isAgent ? 'item-agent' : 'item-customer';
    }
  },
  methods:{
    copyToClipboard(text) {
      console.log("copy to clipboard called");
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      BuefyHelper.showToastMessage("Copied to Clipboard", "is-danger");
    },
  }

}
</script>

<style scoped>

.wrapper-customer {
    margin-top : 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 25px;
  }

  .wrapper-agent {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid red;
    border-radius: 20px;
  }
  .item-customer {
    color: black;
  }

  .item-agent {
    color: red;
  }
</style>