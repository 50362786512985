<template>
  <div style="height: 80.5vh; overflow: auto !important">
    <!-- <div class="mt-6">
        <SearchBar page="Completed Video Calls" />
      </div> -->
    <div class="columns" style="margin-top: 25px; margin-left: 20px">
      <div class="column">
        <label
          style="
            font-family: Gotham-Medium;
            color: gray;
            font-weight: 400;
            font-size: 23px;
          "
          >Completed Video Calls</label
        >
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          type="search"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width: 450px"
        >
        </b-input>
      </div>
    </div>
    <TableComp
      :tableData="
        tableinfo.searchData(tableDetails, searchableFields, searchKeyword)
      "
      :columns="columnDetails"
      :previousPage="currentPage"
      :statusToAssign="statusToAssign"
      :searchableFields="searchableFields"
      styles="mt-4 mx-6"
    />
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network";
import FilterBranch from "../../components/FilterBranch.vue";
import { filterTableData } from "../../helpers/filterTableData";
import { DateTimeManger } from "../../helpers/dateTimeManger";

export default {
  components: { TableComp, SearchBar },
  name: "CompletedVideoCalls",
  data() {
    return {
      ref_no: "",
      nic: "",
      name: "",
      email: "",
      gender: "",

      scheduled_time: "",
      received_date: "",
      completed_date: "",
      action: "",

      searchableFields: [
        "reference_number",
        "nic_eic_number",
        "full_name",
        "email",
        "status_updated_at",
        "scheduled_date",
      ],
      searchKeyword: "",
      tableDetails: "",
      tableinfo: "",
      applicationList: [],

      currentPage: "VIDEO-CALL-COMPLETED",
      statusToAssign: "",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "gender", label: "Gender", date: false },
        { id: 5, field: "email", label: "Email", date: false },
        { id: 6, field: "resident_status", label: "Residence", date: false },
        {
          id: 7,
          field: "status_updated_at",
          label: "Completed Date",
          date: true,
        },
        {
          id: 8,
          field: "scheduled_date",
          label: "Scheduled Time",
          date: false,
        },
        { id: 9, field: "action", label: "Action", date: false },
      ],
    };
  },

  created() {
    this.tableinfo = filterTableData;
  },

  methods: {
    getFormattedDateTime(date) {
      return DateTimeManger.formatDate(date);
    },
    GetCompletedVideoCall: function () {
      const comp = this;
      const data = {
        status: "VIDEO-CALL-COMPLETED",
      };
      console.log("in CompletedVideoCalls");

      // comp.showFullScreenLoader()
      NetworkManager.apiRequest(
        "api/KycApplication/listAssignedApplicationByStatus",
        data,
        function (e) {
          console.log("in CompletedVideoCalls", e.data);
          if (e.statusCode === 200) {
            comp.tableDetails = e.data.applications;
            comp.tableDetails.forEach((tableDetail) => {
              tableDetail.scheduled_date =
                comp.getFormattedDateTime(tableDetail.video_call_date) +
                " " +
                tableDetail.video_call_time;
            });
          }

          // comp.hideFullScreenLoader()
        }
      );
    },
  },
  mounted() {
    console.log("in mounted");

    this.GetCompletedVideoCall();
    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
  },
};
</script>
<style scoped></style>
