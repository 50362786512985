import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.min.css";
import "../src/myBuefyStyles/myBuefyStyles.scss";
import "@mdi/font/css/materialdesignicons.min.css";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Global from "@/mixins/global";
import * as VueGoogleMaps from "vue2-google-maps";
import { KycAttribute } from "./helpers/classes/kycAttribute";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { PiniaStoreHelper } from "./helpers/piniaHelper";

import axios from "axios";
Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.use(Buefy);

Vue.mixin(Global);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD_Dh3FB4ToBgiJ5OkNAjFbMGFupwUMlHY",
    libraries: "places",
  },
  installComponents: true,
});

// import and use pinia with vue 2 -------------------------------------------------------------------------------------
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// persisting pinia states ---------------------------------------------------------------------------------------------

pinia.use((context) => {
  const storeID = context.store.$id;
  console.log("Pinia store id : " + storeID);

  switch (storeID) {
    case "authStore":
      let fromLocalAuthStorage =
        PiniaStoreHelper.getDataFromLocalStore(storeID);
      if (fromLocalAuthStorage) {
        context.store.$patch(fromLocalAuthStorage);
      }
      // listen to changes and update local storage
      PiniaStoreHelper.syncDataToLocalStore(context, storeID);
      break;

    case "videoKycStore":
    // NOTE : Do not sync video kyc store with local store due to storage limitations. means data stored on the store will reset after a refresh

    // let fromVideoKycStore = PiniaStoreHelper.getDataFromLocalStore(storeID);
    // if (fromVideoKycStore) {
    //   context.store.$patch(fromVideoKycStore);
    // }
    // // listen to changes and update local storage
    // PiniaStoreHelper.syncDataToLocalStore(context, storeID);
    // break;
  }
});
// persisting pinia states - end ---------------------------------------------------------------------------------------

new Vue({
  pinia,
  data() {
    return {
      videoCallManager: null,

      isSideBarHidden: false,
      kyc_form_id: null,
      formData: {
        full_name: new KycAttribute(),
        email: new KycAttribute(),
        nic_eic_number: new KycAttribute(),
        employment_position: "",
        title: new KycAttribute(),
        citizenship: new KycAttribute(),
        residentship: new KycAttribute(),
        nationality: new KycAttribute(),
        nic_eic_issue_date: new KycAttribute(),
        passport_number: new KycAttribute(),
        passport_expiry_date: new KycAttribute(),
        passport_issue_date: new KycAttribute(),
        date_of_birth: new KycAttribute(),
        gender: new KycAttribute(),
        maritial_status: new KycAttribute(),

        building_number: new KycAttribute(),
        building_name: new KycAttribute(),
        street: new KycAttribute(),
        street_2: new KycAttribute(),
        town: new KycAttribute(),
        postal_code: new KycAttribute(),
        district: new KycAttribute(),
        country: new KycAttribute(),
        is_correspondence_address_differs_with_the_permanent_address:
          new KycAttribute(),
        correspondence_address_building_number: new KycAttribute(),
        correspondence_address_building_name: new KycAttribute(),
        correspondence_address_street: new KycAttribute(),
        correspondence_address_street_2: new KycAttribute(),
        correspondence_address_town: new KycAttribute(),
        correspondence_address_postal_code: new KycAttribute(),
        correspondence_address_district: new KycAttribute(),
        correspondence_address_country: new KycAttribute(),
        state_of_residence: new KycAttribute(),
        contact_no_resident: new KycAttribute(),
        maiden_name: new KycAttribute(),

        are_you_a_taxpayer: new KycAttribute(),
        tax_file_num: new KycAttribute(),

        politically_exposed: new KycAttribute(),
        politically_exposed_name: new KycAttribute(),
        politically_exposed_position: new KycAttribute(),
        second_politically_exposed_name: new KycAttribute(),
        second_politically_exposed_position: new KycAttribute(),
        politically_exposed_relative: new KycAttribute(),
        politically_exposed_relative_position: new KycAttribute(),
        second_politically_exposed_relative: new KycAttribute(),
        second_politically_exposed_relative_position: new KycAttribute(),

        source_of_income: new KycAttribute(),
        specified_source_of_income: new KycAttribute(),

        employment_status: new KycAttribute(),
        nature_of_business: new KycAttribute(),
        employer_name: new KycAttribute(),
        employer_address_line1: new KycAttribute(),
        employer_address_line2: new KycAttribute(),
        employer_address_line3: new KycAttribute(),
        employer_address_town: new KycAttribute(),
        employer_address_postal_code: new KycAttribute(),
        employer_address_district: new KycAttribute(),
        employer_address_country: new KycAttribute(),

        saving_account_type: "Personal - Winner Savings Account",

        onboarding_account_number: new KycAttribute(),
        specified_purpose_of_opening: new KycAttribute(),
        purpose_of_opening: new KycAttribute(),
        anticipated_volumes: new KycAttribute(),
        initial_deposit: new KycAttribute(),
        branch: new KycAttribute(),
        reason_for_opening_an_account: new KycAttribute(),
        currency_type: new KycAttribute(),
        second_currency_type: new KycAttribute(),
        second_saving_account_type: new KycAttribute(),
        second_purpose_of_opening: new KycAttribute(),
        second_specified_purpose_of_opening: new KycAttribute(),
        second_anticipated_vol: new KycAttribute(),
        second_initial_deposit: new KycAttribute(),
        third_currency_type: new KycAttribute(),
        third_saving_account_type: new KycAttribute(),
        third_purpose_of_opening: new KycAttribute(),
        third_specified_purpose_of_opening: new KycAttribute(),
        third_anticipated_vol: new KycAttribute(),
        third_initial_deposit: new KycAttribute(),

        sms_service: new KycAttribute(),
        e_banking_service: new KycAttribute(),
        virtual_wallet_service: new KycAttribute(),
        estatement_service: new KycAttribute(),
        debit_card_service: new KycAttribute(),
        pos_required: new KycAttribute(),
        collection_details: new KycAttribute(),
        pin_collection_details: new KycAttribute(),
        facility_debit_card_customer_name: new KycAttribute(),
        account_to_be_linked_for_pos_transaction: new KycAttribute(),

        preferred_date_for_video_call: new KycAttribute(),
        preferred_time_for_video_call: new KycAttribute(),
        mobile_number_country_code: new KycAttribute(),
        mobile_number: new KycAttribute(),
        full_mobile_number: new KycAttribute(),
        overall_score: "58%",
        staff_name: "",

        call_agent_comment: "",

        kycForm: {
          nic_front_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fnic_front_628198f3-2e3d-4fb6-95f7-0438a7bb8717.jpg?generation=1680870869078417&alt=media",
          nic_back_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fnic_back_48b347a4-de62-48c1-aa9a-b56afc1ce758.jpg?generation=1680870870717946&alt=media",
          selfie_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fselfie_5cc5e66d-f5c3-44f0-b64c-b890614cbb66.jpg?generation=1680870876898686&alt=media",
          passport_bio_page_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fpassport_bio_page_30558230-c822-4fb3-9f86-b884f284c656.jpg?generation=1680870872328649&alt=media",
          passport_alteration_page_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fpassport_alteration_page_bd8d03b3-8820-4de2-995e-7ad0a2ecff1e.jpg?generation=1680870873675120&alt=media",
          passport_entry_stamp_page_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fpassport_entry_stamp_page_ae51f84f-5ebc-4b40-aa66-c25e5680229e.jpg?generation=1680870875504889&alt=media",
          signature_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Fsignature_3dce658f-6f1a-4334-92e8-73d062f9ca82.jpg?generation=1680870886841333&alt=media",
          address_proof_image:
            "https://storage.googleapis.com/download/storage/v1/b/dfcc_qa/o/kyc_form_uploads%2F870743448v%2Faddress_proof_899e4afa-e6a6-44fb-8eb0-df84acd4e096.jpg?generation=1680870888062827&alt=media",

          title: {
            customer_input: "Miss",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          calling_name: {
            customer_input: "Sharmi",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          citizenship: {
            customer_input: "Sri Lankan",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          residentship: {
            customer_input: "Sri Lanka",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          mobile_number: {
            customer_input: "+94771111111",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          mobile_number_country_code: {
            customer_input: "+94",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          mobile_number_without_country_code: {
            customer_input: "771111111",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          telephone_number_country_code: {
            customer_input: "+94",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          telephone_number: {
            customer_input: "+94212263275",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          telephone_number_without_country_code: {
            customer_input: "212263275",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          email: {
            customer_input: "sarmiiiiiiiiiiiiiii@gmail.com",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          nic_eic_number: {
            customer_input: "968111159v",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          nic_eic_issue_date: {
            customer_input: "2013-03-24T05:29:38.942Z",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          passport_number: {
            customer_input: "A87072",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          passport_issue_date: {
            customer_input: "2010-03-24T05:29:38.942Z",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          passport_expire_date: {
            customer_input: "2023-03-24T05:29:38.942Z",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          full_name: {
            customer_input: "Sarmisha",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          gender: {
            customer_input: "Female",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          date_of_birth: {
            customer_input: "1996-11-06T00:00:00Z",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          maritial_status: {
            customer_input: "Unmarried",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          building_number: {
            customer_input: "415",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          building_name: {
            customer_input: "Alvai",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          street: {
            customer_input: "Nakeeran street",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          street_2: {
            customer_input: "Nakeeran street 2",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          town: {
            customer_input: "Point Pedro",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          postal_code: {
            customer_input: "105",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          district: {
            customer_input: "Jaffna",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          country: {
            customer_input: "Canada",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          is_correspondence_address_differs_with_the_permanent_address: true,
          correspondence_address_building_number: {
            customer_input: "105",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_building_name: {
            customer_input: "Alvai",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_street: {
            customer_input: "Nakeeran street",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_street_2: {
            customer_input: "Nakeeran street 2",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_town: {
            customer_input: "Point Pedro",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_postal_code: {
            customer_input: "105",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_district: {
            customer_input: "Jaffna",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          correspondence_address_country: {
            customer_input: "Canada",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          state_of_residence: {
            customer_input: "Renter",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          mothers_maiden_name: {
            customer_input: "Jasmin",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          employment_status: {
            customer_input: "Full-Time",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          profession_nature_of_business: {
            customer_input: "nature of business",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          employer: {
            customer_input: "Business Name",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_building_number: {
            customer_input: "205",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_building_name: {
            customer_input: "Alvai",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_street: {
            customer_input: "Nakeeran street",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_town: {
            customer_input: "Point Pedro",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_postal_code: {
            customer_input: "105",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_country: {
            customer_input: "Canada",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          emp_district: {
            customer_input: "Jaffna",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          tax_file_numer: {
            customer_input: "111112",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          pep_declaration: {
            customer_input: {
              iam_politically_exposed: false,
              close_relative_politically_exposed: true,
              not_politically_exposed: false,
              pep_person_details: [
                {
                  name: "Mahinda Rajapaksha",
                  designation: "Minister",
                },
                {
                  name: "Gotabaya Rajapaksha",
                  designation: "Minister",
                },
              ],
            },
            is_changed_by_call_agent: false,
            call_agent_input: null,
          },

          source_of_funds_other_description: {
            customer_input: "specified_source_of_income",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          branch_comment: {
            customer_input: "reason_for_opening_an_account",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },

          vas_debit_card_name: {
            customer_input: "main",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          are_you_a_tax_payer: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },

          source_of_funds: {
            customer_input: ["Family Remittance", "Sale of Property / Asset"],
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_debit_card_pos_account: {
            customer_input: "Special SDA Savings",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_debit_card_pos_required: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          branch_id: {
            customer_input: 13,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_debit_card_collection_details: {
            customer_input: "Branch",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_debit_card_pin_collection_details: {
            customer_input: "Residence Address(for PIN collection)",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          nationality: {
            customer_input: "Foreign National",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_sms_alert: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_online_banking: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_virtual_wallet: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_estatement: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          vas_debit_card: {
            customer_input: true,
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          video_call_date: {
            customer_input: "2020-03-24T05:29:38.942Z",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
          video_call_time: {
            customer_input: "01:15 PM to 01:30 PM",
            is_changed_by_call_agent: false,
            call_agent_input: "",
          },
        },
        kyc_form_product_selection: [
          {
            id: 1,
            currency_type: {
              customer_input: "LKR",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            account_type: {
              customer_input: "Special SDA Savings",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            product_id: {
              customer_input: "1",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            purpose_of_opening_account: {
              customer_input: [
                {
                  key: "purpose_one",
                  purpose: "Salary Remittance",
                },
                {
                  key: "purpose_three",
                  purpose: "Loan Repayment",
                },
                {
                  key: "purpose_other",
                  purpose: "Other",
                },
                {
                  key: "purpose_other_specified",
                  purpose: "Test other specified one",
                },
              ],
              is_changed_by_call_agent: false,
              call_agent_input: null,
            },
            anticipated_volumes: {
              customer_input: "Less than 100,000",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            initial_deposit_amount: {
              customer_input: "",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
          },
          {
            id: 2,
            currency_type: {
              customer_input: "GBP",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            account_type: {
              customer_input:
                "Personal Foreign Currency Account - Normal Account",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            product_id: {
              customer_input: "7",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            purpose_of_opening_account: {
              customer_input: [
                {
                  key: "purpose_one",
                  purpose: "Salary Remittance",
                },
                {
                  key: "purpose_seven",
                  purpose: "Interest Credits",
                },
                {
                  key: "purpose_other",
                  purpose: "Other",
                },
                {
                  key: "purpose_other_specified",
                  purpose: "test other two",
                },
              ],
              is_changed_by_call_agent: false,
              call_agent_input: null,
            },
            anticipated_volumes: {
              customer_input: "500,001 - 1,000,000",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            initial_deposit_amount: {
              customer_input: "150",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
          },
          {
            id: 3,
            currency_type: {
              customer_input: "EUR",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            account_type: {
              customer_input: "Special SDA Savings",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            product_id: {
              customer_input: "6",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            purpose_of_opening_account: {
              customer_input: [
                {
                  key: "purpose_two",
                  purpose: "Business",
                },
                {
                  key: "purpose_four",
                  purpose: "Savings",
                },
              ],
              is_changed_by_call_agent: false,
              call_agent_input: null,
            },
            anticipated_volumes: {
              customer_input: "Above 5,000,001",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
            initial_deposit_amount: {
              customer_input: "500",
              is_changed_by_call_agent: false,
              call_agent_input: "",
            },
          },
        ],
      },
      formDataSupport: {
        countriesArray: [],
        districtsArray: [],
        branchesArray: [],
        productList: [],
        detailReviewersArray: [],
        callAgentsArray: [],
        approversArray: [],
        exceptionalApproversArray: [],
        branchUsersArray: [],
        isDebitCardNoClicked: false,
      },
    };
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
