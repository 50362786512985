<template>

  <!--  TODO :: add styles, make proper structure -->
  <div>
    <b-loading v-model="isLoading" :is-full-page="true" :can-cancel="false"/>
    <div v-if="!isLoading">
      <h1 style="font-weight: bolder; text-align-last: center; font-size: xx-large;">Central KYC</h1>
      <div class="row" style="display: flex;">
        <div class="column is-three-fifths">

          <!--      Example usage of text/date kyc field -->
          <h2 style="color: #1e7f63; padding-left: 10px;">Personal Information</h2><br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.title" label="Title"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.calling_name" label="Calling Name"/>
            </div>
          </div>

          <div class="row" style="display: flex;">
            <div class="column" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.full_name" label="Full Name"/>
            </div>
          </div>

          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.gender" label="Gender"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.maritial_status" label="Marital Status"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.date_of_birth" label="Date of Birth" type="date"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.nationality" label="Nationality"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.citizenship" label="Citizenship"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.residentship" label="Residentship"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.mobile_number" label="Mobile Number"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.mobile_number_country_code" label="Mobile Number Country Code"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.telephone_number" label="Telephone Number"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.telephone_number_country_code" label="Telephone Number Country Code"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.email" label="Email"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.secondary_email" label="Secondary Email"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.nic_eic_number" label="NIC Number"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.nic_eic_issue_date" label="NIC Issue Date" type="date"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column iis-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.passport_number" label="Passport Number"/>
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.passport_issue_date" label="Passport Issue Date" type="date"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.passport_expire_date" label="Passport Expiry Date" type="date"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.state_of_residence" label="State of Residence" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.mothers_maiden_name" label="Mother's Maiden Name" />
            </div>
          </div>

          <h2 style="color: #1e7f63; padding-left: 10px;">Personal Address Information</h2>
          <br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.building_number" label="Building Number" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.building_name" label="Building Name" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.street" label="Street" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.street_2" label="Street 2"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.town" label="Town" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.postal_code" label="Postal Code" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.district" label="District" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.country" label="Country" />
            </div>
          </div>

          <h2 style="color: #1e7f63; padding-left: 10px;">Correspondence Address Information</h2>
          <br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_building_number" label="Correspondence Address Building Number" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_building_name" label="Correspondence Address Building Name" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_street" label="Correspondence Address Street" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_street_2" label="Correspondence Address Street 2" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_town" label="Correspondence Address Town" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_postal_code" label="Correspondence Address Postal Code" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_district" label="Correspondence Address District" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.correspondence_address_country" label="Correspondence Address Country" />
            </div>
          </div>

          <h2 style="color: #1e7f63; padding-left: 10px;">Employment Information</h2>
          <br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.employment_status" label="Employment Status" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.profession_nature_of_business" label="Profession/ Nature of Business" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.other_platforms_working_on" label="Other Platforms Working On" type="string-list" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.other_platforms_working_on_other_description" label="Description of Other Platforms Working On" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.employer" label="Employer" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_building_number" label="Employer Building Number" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_building_name" label="Employer Building Name" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_street" label="Employer Street" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_town" label="Employer Town" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_postal_code" label="Employer Postal Code" />
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_district" label="Employer District" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.emp_country" label="Employer Country" />
            </div>
          </div>

          <h2 style="color: #1e7f63; padding-left: 10px;">Tax Information</h2>
          <br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.are_you_a_tax_payer" label="Are You a Tax Payer?" type="boolean" />
            </div>
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.tax_file_numer" label="Tax File Number" />
            </div>
          </div>



          <h2 style="color: #1e7f63; padding-left: 10px;">Politically Exposed Person</h2>
          <br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-pep-declaration-field :field-data="centralKyc.pep_declaration" />
            </div>
          </div>


          <h2 style="color: #1e7f63; padding-left: 10px;">Source of Funds</h2>
          <br>
          <div class="row" style="display: flex;">
            <div class="column is-half" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.source_of_funds" label="Source of Funds" type="string-list"/>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="column" style="padding-bottom: 0">
              <central-kyc-field :field-data="centralKyc.source_of_funds_other_description" label="Other Sources of Funds Description" />
            </div>
          </div>


<!--          <h2 style="color: #1e7f63; padding-left: 10px;">Video Call Information</h2>-->
<!--          <br>-->
<!--          <div class="row" style="display: flex;">-->
<!--            <div class="column is-half" style="padding-bottom: 0">-->
<!--              <central-kyc-field :field-data="centralKyc.video_call_date" label="Video Call date" type="date" />-->
<!--            </div>-->
<!--            <div class="column is-half" style="padding-bottom: 0">-->
<!--              <central-kyc-field :field-data="centralKyc.video_call_time" label="Video Call Time" />-->
<!--            </div>-->
<!--          </div>-->

          <!-- pep declaration -->
          <!--      <central-kyc-pep-declaration-field :field-data="centralKyc.pep_declaration"/>-->

          <!-- source of funds -->
          <!--      <central-kyc-field :field-data="centralKyc.source_of_funds" label="Source of Funds" type="string-list"/>-->

          <!-- display attachments -->
<!--          <central-kyc-attachment label="Proof of Address Image" :kyc-attachment="centralKyc.address_proof_attachment"/>-->

        </div>

        <div class="column">
          <central-kyc-attachment label="NIC Front Image" :kyc-attachment="centralKyc.nic_front_attachment"/>
          <br>
          <br>
          <central-kyc-attachment label="NIC Back Image" :kyc-attachment="centralKyc.nic_back_attachment"/>
          <br>
          <br>
          <central-kyc-attachment label="Selfie Image" :kyc-attachment="centralKyc.selfie_attachment"/>
          <br>
          <br>
          <central-kyc-attachment label="Proof of Address Image" :kyc-attachment="centralKyc.address_proof_attachment"/>

          <!-- passport related images -->
          <div v-if="showPassportImages">
            <br>
            <br>
            <central-kyc-attachment label="Passport Bio Page Image" :kyc-attachment="centralKyc.passport_bio_page_attachment"/>
            <br>
            <br>
            <central-kyc-attachment label="Passport Alteration Page Image" :kyc-attachment="centralKyc.passport_alteration_attachment"/>
            <br>
            <br>
            <central-kyc-attachment label="Passport Entry Stamp Page Image" :kyc-attachment="centralKyc.passport_entry_stamp_attachment"/>
            <br>
            <br>
            <central-kyc-attachment label="Visa Image" :kyc-attachment="centralKyc.visa_attachment"/>
          </div>

        </div>

      </div>
      </div>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import NetworkManager from "@/network";
import {BuefyHelper} from "@/helpers/bufeyHelper";
import CentralKycField from "@/components/CentralKyc/CentralKycField.vue";
import CentralKycAttachment from "@/components/CentralKyc/CentralKycAttachment.vue";
import CentralKycPepDeclarationField from "@/components/CentralKyc/CentralKycPepDeclarationField.vue";
import {useAuthStore} from "@/store/authStore";
export default defineComponent({
  name: "review-central-kyc",

  components : {
    CentralKycField,
    CentralKycAttachment,
    CentralKycPepDeclarationField
  },

  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },

  data(){
    return {
      centralKyc: null,
      isLoading : true,
      showPassportImages: false,
    }
  },
  methods : {
    getCentralKycById(){

      const comp = this

      let requestBody = {
        id : comp.authStore.common_kyc_form_id
      }

      NetworkManager.apiRequest("api/central-kyc/kyc", requestBody, (response) => {

        console.log("get central kyc data...")

        if(response.statusCode === 200){
          comp.centralKyc = response.data.kyc_form
          comp.showPassportImages = response.data.show_passport_images
        }
        else {
          console.log("failed to get central kyc")
          console.log(response)
          BuefyHelper.showToastMessage("Failed to get the data", "is-danger")
        }

        comp.isLoading = false
      },false)
    }
  },

  mounted(){
    this.getCentralKycById()
  }
});

</script>

<style scoped>
</style>