import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { ToastProgrammatic as Toast } from 'buefy'
/*
========================================================================================================================
This helper can be use to define reusable buefy functions
* */
const BuefyHelper = {

    /**
     * =================================================================================================================
     * This function can be used to show buefy snackbar
     * @param message message to show
     * @param actionText text on action button
     * @param callback callback function
     * @param type snackbar type, default is set to is-warning
     * */
    showSnackBar(message,actionText,type,callback){
       Snackbar.open({
           message: message,
           type: type,
           position: 'is-top',
           actionText: actionText,
           indefinite: true,
           onAction:  callback
       })
    },

    /**
     * =================================================================================================================
     * This function can be used to show a buefy toast message
     * @param message
     * @param type
     * @param duration
     */
    showToastMessage(message,type,duration=5000){
        Toast.open({
            duration: duration,
            message: message,
            type: type,
        })
    }
}

export {BuefyHelper}