import {VideoStreamMerger} from "video-stream-merger"
import fixWebmDuration from "webm-duration-fix";
export default function EKycVideoRecorder(element, localVideo, remoteVideo, fps = 30) {
    let merger = null;
    let mediaRecorder = null;
    let chunks = []
    let url = ""
    let blob = null
    let stopCallBack = null

    let isRecordingStarted = false
    let isStoppedRecording = false

    let isFirefox = false
    const sUsrAg = navigator.userAgent;
    if (sUsrAg.indexOf('Firefox') > -1) isFirefox = true

    return {
        startRecord: function () {
            const localVideoRatio = localVideo.videoHeight / localVideo.videoWidth
            const remoteVideoRatio = remoteVideo.videoHeight / remoteVideo.videoWidth

            const lvh = 150
            const lvw = lvh / localVideoRatio

            const rvh = 400
            const rvw = rvh / remoteVideoRatio

            console.log(`lvh : ${lvh} | lvw : ${lvw}`)
            console.log(`rvh : ${rvh} | rvw : ${rvw}`)

            const VIDEO_WIDTH = lvw + rvw + 50
            const VIDEO_HEIGHT = rvh + 50

            merger = new VideoStreamMerger({
                width: VIDEO_WIDTH,
                height: VIDEO_HEIGHT
            })

            let localStream, remoteStream

            /*
            NOTE :
                following lines were commented due to it cause media stream controlling issues after a browser update.
                Instead of captureStream() method, use each video element srcObject property to access the media streams of each
            * */

            // if (isFirefox) {
            //     localStream = localVideo.mozCaptureStream()
            //     remoteStream = remoteVideo.mozCaptureStream()
            // } else {
            //     localStream = localVideo.captureStream()
            //     remoteStream = remoteVideo.captureStream()
            // }


            // New code fixes for media stream controlling issue occurred after the browser update
            localStream = localVideo.srcObject
            remoteStream = remoteVideo.srcObject

            merger.addStream(localStream, {
                x: 25, // position of the topleft corner
                y: 25,
                width: lvw,
                height: lvh,
                mute: false // we don't want sound from the screen (if there is any)
            })

            merger.addStream(remoteStream, {
                x: lvw + 25, // position of the topleft corner
                y: 25,
                width: rvw,
                height: rvh,
                mute: false // we don't want sound from the screen (if there is any)
            })

            merger.start()
            mediaRecorder = new MediaRecorder(merger.result);
            mediaRecorder.ondataavailable = this.mediaRecorder_onDataAvailable
            mediaRecorder.onstop = this.mediaRecorder_onStop

            mediaRecorder.start();
            console.log(mediaRecorder.state);
            console.log("recorder started");

            isRecordingStarted = true
            isStoppedRecording = false
        },

        stopRecord: function (callBack) {
            stopCallBack = callBack
            mediaRecorder.stop();
            console.log(mediaRecorder.state);
            console.log("recorder stopped");
        },

        getUrl: function () {
            return url
        },

        getBlob: function () {
            return blob
        },

        mediaRecorder_onStop: async function (e) {
            console.log("data available after MediaRecorder.stop() called.");

            // repairing media recorder output blob to get complete duration of the video recording
            const mimeType = 'video/webm\;codecs=vp9';
            const fixBlob = await fixWebmDuration(new Blob([...chunks], { type: mimeType }));

            //blob = new Blob(chunks, {'type': 'audio/ogg; codecs=opus'});
            chunks = [];
            url = window.URL.createObjectURL(fixBlob);
            blob = fixBlob
            console.log("recorder stopped");

            isRecordingStarted = false
            isStoppedRecording = true

            stopCallBack()
        },

        mediaRecorder_onDataAvailable: function (e) {
            console.log('data available')
            chunks.push(e.data);
        }
    }
}
