<template>
  <div :class="[isValueAvailable() ? 'wrapper-content' : 'wrapper-no-content', 'wrapper']">
    <span id="label">{{label}} : </span>
    <span>{{Value}}</span>
  </div>
</template>

<script >
import {defineComponent} from "vue";

export default defineComponent({
  name: "TextDisplayComponent",

  props : {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },

  computed : {
    Value(){
      const comp = this
      if (!comp.isValueAvailable()){
        return "Not Available"
      }
      else {
        return comp.value
      }
    }
  },


  methods : {
    isValueAvailable(){
      const comp = this
      return comp.value.trim().length !== 0
    }
  }

})

</script>

<style scoped>
.wrapper{
  display: flex;
  margin-top: 15px;
  justify-content: center;
  min-height: 50px;
  border-radius: 4px;
  align-items: center;
}
.wrapper-content {
  background-color: #affcb3;
  color: #257953;
}

.wrapper-no-content {
  background-color: #dbdbdb;
  color: black;
}
.wrapper span {
  font-size: 15px !important;
  font-weight:normal;
}

#label{
  font-weight: bold;
  margin-right: 5px;
}
</style>