<template>
  <div id="app" style="width: 100%" @click="onClickApp">
    <router-view />

    <!-- Agent video call component   -->
    <VideoCallAgentModal v-if="showVideoCallModel"
                         :video-call-modal-visible="showVideoCallModel"
                         :start-recording="enableVideoCallRecording" >

    </VideoCallAgentModal>

   <div class="call-alerts" v-if="showPepMessage">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header custom-header">
            <h5 class="modal-title">{{ pepSanctionUpdateMessage.message }}</h5>
          </div>
          <div class="modal-body custom-body">
            {{ pepSanctionUpdateMessage.data }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Customer video call notifications -->
    <div id="loading" class="call-notifications" v-if="videoKycStore.videoCallRequestData.array.length > 0">
      <IncomingCustomerCall v-for="(videoCallNotification,index) in videoKycStore.videoCallRequestData.array"
                            :key="index" :customer-name="GlobalFunctions.getFormattedNameForNotification(videoCallNotification.kycForm)"
                            :scheduled-time="DateTimeManger.getVideoCallNotificationDateTime(videoCallNotification.videoCallDate,videoCallNotification.videoCallTime)"
                            :customer-session-token="videoCallNotification.customer"
                            :kyc-form-id="videoCallNotification.kycForm.id"
                            style="z-index:2">

      </IncomingCustomerCall>
    </div>

  </div>
</template>

<script>
import "./validate.js";
import {useAuthStore} from "./store/authStore";
import NetworkManager from "./network";
import WebRtcBackendInterface from "./helpers/webRTC/backendInterface";
import JwtUtils from "./helpers/jwtUtils";
import {GlobalEventManager} from "./helpers/globalEventManager";

import VideoCallAgentModal from "./components/VideoCallAgentModal.vue";
import IncomingCustomerCall from "./components/IncomingCustomerCall.vue";
import VideoCallNotification from "./helpers/classes/videoCall/videoCallNotification";
import {useVideoKycStore} from "./store/videoKycStore";
import {DateTimeManger} from "./helpers/dateTimeManger";
import {PiniaStoreHelper} from "./helpers/piniaHelper";
import {GlobalFunctions} from "./helpers/globalFunctions";
import {GlobalEvents} from "./helpers/globalEvents";
import AnsweredVideoCall from "./helpers/classes/videoCall/answeredVideoCall";
import ImageUploadDetail from "./helpers/classes/videoCall/imageUploadDetail";
import Location from "./helpers/classes/location";
import {ApiRequestManager} from "./network/apiRequestManager";
import jwtUtils from "./helpers/jwtUtils";
import 'bulma/css/bulma.css';
import {NextAssignedVideoCall} from "./helpers/classes/videoCall/nextAssignedVideoCall";
import {UserInteractionDetails} from "@/helpers/classes/userInteractionDetails";
import SignalR from "./helpers/signalR/signalR";


export default {
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    },
    DateTimeManger() {
      return DateTimeManger
    }
  },
  components :{
    VideoCallAgentModal,
    IncomingCustomerCall
  },
  setup(){
    const authStore = useAuthStore()
    const videoKycStore = useVideoKycStore()
    return {authStore,videoKycStore}
  },
  name: "App",

  data(){
    return{
      showVideoCallModel : false,
      enableVideoCallRecording: false,
      currentRoutePath:"",
      count:0,
      pepSanctionUpdateMessage:{},
      showPepMessage : false,
    }
  },

  watch:{
    "authStore.token"(){
      const comp = this
      console.log("store updated")

      if(comp.authStore.token !== ""){
        comp.loadStaticData()
      }

      if(comp.authStore.token !== "" && !comp.authStore.isSocketInitialized){

        console.log("agent logged in : initializing web socket ...")
        comp.authStore.loggedInUser =  JwtUtils.loadToken().jwtObj;

        comp.getWebRTCInternalLink(()=>{comp.InitializeWebSocket()})
      }


    },

  },


  methods:{

    /**
     * A event handler to listen global click events
     * Used to get user interactions with the system
     */
    onClickApp() {
      const comp = this

      if(comp.authStore.loggedInUser !== null){
        comp.authStore.userInteractionDetails.setLastInteractionTime()
      }
    },


    /**
     * A function which recursively called that can be used to check session is valid
     * Execute in every second
     */
    checkUserIsInactive(){
      // console.log("session handler - recursive initialized ...")
      const comp = this
      let recursive = ()=>{
        // console.log("session handler - recursive called ...")
        if(comp.authStore.loggedInUser !== null){
          GlobalFunctions.checkUserIsInactive(comp.authStore)
        }
        else {
          console.log("session handler - No user logged in yet ....")
        }

        setTimeout(()=>{ recursive() },1000) // call same function recursively every second
      }

      recursive()
    },


    /**
     * A method to show the agent video call model
     */
    showVideoCallAgentModel(){
      const comp = this
      comp.showVideoCallModel = true
    },

    /**
     * A method to hide the agent video call model
     */
    hideVideoCallAgentModel(){
      const comp = this
      comp.showVideoCallModel = false
      comp.enableVideoCallRecording = false
      PiniaStoreHelper.clearLocalStorage(comp.videoKycStore)

      // update video call started state
      GlobalFunctions.setVideoCallStartedState(false)
    },

    // video call related functions - start ----------------------------------------------------------------------------

    refreshSocketConnection(){
      const comp = this
      // check socket was initialized but video call manger was rest

      // if((comp.authStore.isSocketInitialized && comp.$root.videoCallManager == null) || (comp.authStore.token !== "" && comp.authStore.isSocketInitialized)){
      //   console.warn("refreshing web socket ...")
      //   comp.InitializeWebSocket()
      // }
      console.log("current path : "+comp.$route.path)
      if(comp.$route.path.trim() !=="/login" && (comp.authStore.isSocketInitialized && comp.$root.videoCallManager == null) || (comp.authStore.token !== "" && comp.authStore.isSocketInitialized)){
        console.warn("refreshing web socket ...")
        // Debugging logs ==================================
        // console.warn("auth store")
        // console.log(comp.authStore)
        // console.warn("video call manager")
        // console.log(comp.$root.videoCallManager)
        comp.InitializeWebSocket()
      }
      else{
        // here previously user closed the browser instead of logout from the system so, auth store is not cleared properly.
        // clear auth store at this point
        console.log("Resetting auth store to prevent other errors...")
        PiniaStoreHelper.clearLocalStorage(comp.authStore)
      }
    },

    getWebRTCInternalLink(callback){
      const comp = this

      NetworkManager.apiRequest("api/WebRtc/getWebRtcInternalLink",{},(response)=>{
        if(response.statusCode === 200){
          console.log("webrtc internal link and ice servers were loaded")
          console.log(response)
          comp.authStore.webRTCInternalLink = response.data.web_rtc_internal_link
          comp.authStore.iceServers = response.data.ice_servers
          callback()
        }
        else {
          console.log("Failed to get webrtc internal link...")
        }
      },)
    },


    InitializeWebSocket () {
      const comp = this;

      console.log("Socket URL : "+comp.authStore.webRTCInternalLink)

      comp.$root.videoCallManager = WebRtcBackendInterface(comp.authStore.webRTCInternalLink, comp.authStore.iceServers, comp.authStore.loggedInUser.username)
      comp.$root.videoCallManager.subscribe(comp.socketClosed, comp.videoStreamReceived, comp.videoCallReceived, comp.videoCallEnded)
      comp.$root.videoCallManager.initiateSocketConnection(()=>{
        comp.authStore.isSocketInitialized = true
        console.info("socket initialized successfully")
      })
    },


    socketClosed (e) {
      const comp = this

      console.log("socket closed : "+ e)

      if(comp.authStore.token !== ""){
        console.log("Agent still logged-in, but socket connection was closed from the service")
        console.log("Re-initiating the web socket")

        comp.InitializeWebSocket();
      }
    },

    videoStreamReceived (e) {
      const comp = this
      console.log("video stream received ... ")
      setTimeout(()=>{comp.enableVideoCallRecording = true},comp.videoKycStore.startRecordingProtectionTimeMs) // start recording after protection time. otherwise remote video width will not get properly. will affect the recorded video

      comp.videoKycStore.buttonStates.disabled.audioBtn = false
      comp.videoKycStore.buttonStates.disabled.videoBtn = false
      comp.videoKycStore.buttonStates.disabled.endCallBtn = false

    },

    videoCallReceived(e){
      const comp = this
      console.log('Video call received...')

      comp.recievedVideoCall = e.response.customer
      comp.videoCallAgentID = e.response.agent
      comp.location = e.response.location

      let videoCallNotification = new VideoCallNotification()
      videoCallNotification.agent = e.response.agent
      videoCallNotification.customer = e.response.customer
      videoCallNotification.location = e.response.location

      comp.getInformationToStartVideoCall(videoCallNotification)
    },

    videoCallEnded (e) {


      const comp = this

      console.log('video call ended')
      console.log(e)

      comp.videoKycStore.videoCallRequestData.removeByKey('customer',e.response.customer)

      console.log(comp.videoKycStore.videoCallRequestData)

      // updated the logic by adding isStartedUploadingCallData to handle client disconnections when call is uploading
      if(comp.showVideoCallModel && !comp.videoKycStore.isStartedUploadingCallData && e.response.customer === comp.videoKycStore.openVideoRequestUserID){
        comp.$buefy.toast.open({
          message: 'Client Disconnected!',
          duration: 5000,
          type: 'is-warning'
        })


        setTimeout(() => {
          comp.$root.videoCallManager.stopVideo()
          comp.hideVideoCallAgentModel();
        }, 1000);
      }

    },

    connectAgentToCall () {
      const comp = this;
      // TODO :: make required changes in this method

    },


    getInformationToStartVideoCall(videoNotificationObj) {

      const comp = this

      let decodedSessToken = videoNotificationObj.customer

      const requestBody = {
        session_token : decodedSessToken
      }
      NetworkManager.apiRequest("api/KycApplication/getInformationToStartVideocall",requestBody,(response)=>{
          console.log("get information to start video call response")
          console.log(response)

        if(response.statusCode === 200){
          comp.connectAgentToCall()

          //temporary add customer session token here for testing, it should be added during the video call received event
          // videoNotificationObj.customer = videoNotificationObj.customer

          videoNotificationObj.kycForm = response.data.kyc_form
          videoNotificationObj.kycFormProductSelection = response.data.kyc_form_product_selection
          videoNotificationObj.videoCallDate = response.data.video_call_date
          videoNotificationObj.videoCallTime = response.data.video_call_time
          videoNotificationObj.questionDetails = response.data.question_details
          videoNotificationObj.callMaxDurationMinutes = response.data.call_max_duration_minutes
          videoNotificationObj.videoCallVerificationQuestionList = response.data.video_call_verification_question_list

          //set next assigned video call details
          if(response.data.next_assigned_video_call !== null){
            const nextAssignedCallDetails = response.data.next_assigned_video_call
            videoNotificationObj.nextAssignedVideoCall = new NextAssignedVideoCall(
                nextAssignedCallDetails.kyc_form_id,
                nextAssignedCallDetails.full_name,
                nextAssignedCallDetails.nic_eic_number,
                nextAssignedCallDetails.video_call_date,
                nextAssignedCallDetails.video_call_time
            )
          }


          comp.videoKycStore.videoCallRequestData.add(videoNotificationObj)
        }
        else if(response.statusCode === 406){
          // already completed video call request
          console.warn("Already completed video call request received")
          console.log("Rejecting the call")
          comp.rejectVideoCallRequest(videoNotificationObj.customer)
        }
        else {
          console.warn("failed to fetch data")
          // TODO :: reset variables here as required
        }
      })

    },

    rejectVideoCallRequest(customerSessionToken) {
      let comp = this

      comp.videoKycStore.openVideoRequestUserID = customerSessionToken
      comp.$root.videoCallManager.rejectVideoCall(customerSessionToken)
      comp.videoKycStore.videoCallRequestData.removeByKey('customer',customerSessionToken)

      if (comp.videoKycStore.videoCallRequestData.array.length === 0) {
        // if not video call requests are available clear video call store
        PiniaStoreHelper.clearLocalStorage(comp.videoKycStore)
      }
    },


    acceptVideoCallRequest (customerSessionToken) {
      const comp = this

      comp.videoKycStore.openVideoRequestUserID = customerSessionToken
      console.log('openVideoRequestUserID : '+comp.videoKycStore.openVideoRequestUserID)

      comp.showVideoCallAgentModel()


      let allVideoCallRequests = comp.videoKycStore.videoCallRequestData.array.slice()

      allVideoCallRequests.forEach(
          function (item){
            if(item.customer === customerSessionToken){
              // get all data from answered call
              // comp.videoKycStore.answeredVideoKycData = item -- old code

              // create new answered video call object and add values to the properties
              comp.videoKycStore.answeredVideoKycData = new AnsweredVideoCall()

              comp.videoKycStore.answeredVideoKycData.customer = item.customer
              comp.videoKycStore.answeredVideoKycData.agent = item.agent
              comp.videoKycStore.answeredVideoKycData.kycForm = item.kycForm
              comp.videoKycStore.answeredVideoKycData.kycFormProductSelection = item.kycFormProductSelection
              comp.videoKycStore.answeredVideoKycData.questionDetails = item.questionDetails
              comp.videoKycStore.answeredVideoKycData.videoCallDate = item.videoCallDate
              comp.videoKycStore.answeredVideoKycData.videoCallTime = item.videoCallTime
              comp.videoKycStore.answeredVideoKycData.location = new Location(item.location.lat,item.location.long)
              comp.videoKycStore.answeredVideoKycData.callMaxDurationMinutes = item.callMaxDurationMinutes
              comp.videoKycStore.answeredVideoKycData.nextAssignedVideoCall = item.nextAssignedVideoCall
              comp.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList = item.videoCallVerificationQuestionList


              //set image upload details
              comp.videoKycStore.answeredVideoKycData.setImageUploadDetails(item.questionDetails.images)

              console.log("Answered customer (session token) : "+item.customer)
              console.log("Answered customer KYC data id  : "+item.kycForm.id)
              console.log("Answered customer KYC data full name  : "+item.kycForm.full_name.customer_input)

            }
          }
      );

      comp.videoKycStore.videoCallRequestData.removeByKey('customer',customerSessionToken)

      console.log("-------------------------------------")
      console.log("pending video call notifications in the array")
      console.log(comp.videoKycStore.videoCallRequestData.array)

      let array = comp.videoKycStore.videoCallRequestData.array.slice()

      array.forEach(function (item) {
        const customerID = item.customer;

        console.log('Rejected customer (session token) : '+customerID)
        comp.$root.videoCallManager.agentBusy(customerID)
        comp.videoKycStore.videoCallRequestData.removeByKey('customer',customerID)
      })

      console.log("-------------------------------------")

      if (comp.videoKycStore.videoCallRequestData.array.length === 0) {
        // TODO :: when notifications empty
      }
      // TODO :: refresh JWT before start the video call
    },

    // video call related functions - end ------------------------------------------------------------------------------


    /**
     * This function can be used to reset the video call started state
     */
    resetVideoCallStartedState(){
      const comp = this
      if(GlobalFunctions.getVideoCallStartedState()){
        console.log("App mounted, video call started state is True, resetting state ....")
        GlobalFunctions.setVideoCallStartedState(false)
      }
      else{
        console.log("No need to reset the video call started state ....")
      }
    },

      //related to webhook notifications
      handleSignalRMessage(message){

      const comp = this
      comp.pepSanctionUpdateMessage = message
      console.warn("SignalR : Message received ....")
      comp.showPepMessage = true;
      comp.$root.notifications.push(comp.pepSanctionUpdateMessage);

    },

    closeModal() {
      this.showPepMessage = false;
    },

    generateClientId(){
      const comp = this
      comp.user = JwtUtils.loadToken().jwtObj;
      console.log("USER DETAILS IN APP VUE",comp.user)
      // use logged-in user id/username as the client id
      comp.$root.clientId =  comp.user.id;

      //comp.$root.clientId = "user_"+Math.ceil(Math.random()*100000).toString();
    },

    loadStaticData(){
      console.log("loading static data.....")

      if(this.$root.formDataSupport.countriesArray.length <= 0){
        ApiRequestManager.getCountryList(
            (countries) => (this.$root.formDataSupport.countriesArray = countries)
        );
      }

      if(this.$root.formDataSupport.districtsArray.length <= 0){
        ApiRequestManager.getDistrictList(
            (districts) => (this.$root.formDataSupport.districtsArray = districts)
        );
      }

      if(this.$root.formDataSupport.branchesArray.length <= 0){
        ApiRequestManager.getBranchList(
            (branches) => (this.$root.formDataSupport.branchesArray = branches)
        );
      }

    }
  },

  // life cycle hooks --------------------------------------------------------------------------------------------------

  created() {

    const comp = this

    // listening to global events --------------------------------------------------------------------------------------
    GlobalEventManager.$on(GlobalEvents.agentSide.showVideoCallAgentModel,()=>{comp.showVideoCallAgentModel()})

    GlobalEventManager.$on(GlobalEvents.agentSide.hideVideoCallAgentModel,()=>{comp.hideVideoCallAgentModel()})

    GlobalEventManager.$on(GlobalEvents.agentSide.answerVideoCall,(customerSessionToken)=>{
      console.log("accepting video call request ...")
      console.log("customer (session token) : "+customerSessionToken)

      // updating video call started state and refreshing token
      GlobalFunctions.setVideoCallStartedState(true)
      jwtUtils.refreshJwt(true) // enforced refresh

      comp.acceptVideoCallRequest(customerSessionToken)
    })

    GlobalEventManager.$on(GlobalEvents.agentSide.rejectVideoCall,(customerSessionToken)=>{
      console.log("rejecting video call request ...")
      console.log("customer (session token) : "+customerSessionToken)

      comp.rejectVideoCallRequest(customerSessionToken)
    })

    comp.checkUserIsInactive()
  },

  mounted() {
    const comp = this
    console.log("app mounted --------")

   //implementations related to webhook
     comp.generateClientId()

    console.log("Client ID : "+comp.$root.clientId)

    // build connection and connect to hub
    SignalR.buildClientConnection(comp.$root.clientId,()=>{SignalR.connect()})

    // register event handler for count updated
    SignalR.testNotification((message)=>{comp.handleSignalRMessage(message)})

    console.log("reset video kyc store ")
    PiniaStoreHelper.clearLocalStorage(comp.videoKycStore)


    setTimeout(()=>{
      comp.refreshSocketConnection()
    },200)
    comp.resetVideoCallStartedState()
  },

  beforeDestroy() {
    // disconnect from the SignalR server
    SignalR.disconnect()
  },

};
</script>

<style>
:root {
  --navbar-height: 10vh;
}

html,
body,
#app {
  /* overflow: hidden !important; */
  font-family: "Gotham-Medium";
  height: 100vh;
  /* background: #ecedf0; */
  /* border-radius: 30px; */
}

#main-nav {
  height: var(--navbar-height);
}
.logo {
  mix-blend-mode: darken;
}
@font-face {
  font-family: "Gotham-Black";
  src: url(../src/assets/fonts/Gotham-Black.otf);
}
@font-face {
  font-family: "Gotham-Bold";
  src: url(../src/assets/fonts/Gotham-Bold.otf);
}
@font-face {
  font-family: "Gotham-Medium";
  src: url(../src/assets/fonts/Gotham-Medium.otf);
}
@font-face {
  font-family: "Gotham-Light";
  src: url(../src/assets/fonts/Gotham-Light.otf);
}
@font-face {
  font-family: "Gotham-Ultra";
  src: url(../src/assets/fonts/Gotham-Ultra.otf);
}
@font-face {
  font-family: "Gotham-Thin";
  src: url(../src/assets/fonts/Gotham-Thin.otf);
}
@font-face {
  font-family: "Gotham-Book";
  src: url(../src/assets/fonts/Gotham-Book.otf);
}
@font-face {
  font-family: "Gotham-MediumItalic";
  src: url(../src/assets/fonts/Gotham-MediumItalic.otf);
}

.message-body .media {
  display: flex;
  align-items: center;
}

.link-style {
  text-decoration: underline;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color : #04c3fc;
}

.link:hover {
  text-decoration: underline;
}

.call-notifications{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  /* hide scroll bar */
-ms-overflow-style: none !important; /* for IE and Edge */
  scrollbar-width: none !important; /* for Firefox */
}

/* hide scroll bar - Chrome, Safari, Opera */
.call-notifications::-webkit-scrollbar{
  display: none;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
tr.is-success {
  background: #48c78e !important;
  color: #fff;
}

tr.is-danger {
  background: #f03a5f !important;
  color: #fff;
}


.call-alerts {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.custom-header {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
  background-color: #04c3fc;
  color: #fff;
}

.custom-body {
  padding: 20px;
}

.modal-footer {
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  border-radius: 0 0 10px 10px;
  background-color: #f8f9fa;
}
</style>
