import {ExceptionalApproverBaseTableDataItem} from "../exceptionalApproverBaseTableDataItem";

export class PendingExApprovalCallAgentTableDataItem extends ExceptionalApproverBaseTableDataItem{
    scheduled_time
    completed_date
    received_date
    constructor(id,refNo,nic,name,gender,email,branch,assignedUserName,status,scheduledTime,completedDate,receivedDate) {
        super(id,refNo,nic,name,gender,email,branch,assignedUserName,status);
        this.scheduled_time = scheduledTime;
        this.completed_date = completedDate;
        this.received_date =receivedDate;
    }
}