<template>
  <div style="height: 80.5vh; overflow: auto !important">
    <b-tabs >
      <b-tab-item>
        <div>
          <!-- <SearchBar page="" /> -->
          <div class="columns" style="margin-left: 1.1vw">
            <div class="column">
              <label
                style="
                  font-family: Gotham-Medium;
                  color: gray;
                  font-weight: 400;
                  font-size: 23px;
                "
                >Account Creation Queue</label>
            </div>
            <div class="column">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>

    <b-tabs v-model="activeTab">

      <b-tab-item label="Retry" >
        <TableComp
          :tableData="FilterTableData.searchData(tableData.retryTableData,searchableFields,searchKeyword)"
          :columns="commonColumnDetails"
          :previousPage="GlobalAttributes.kycFormStatus.T24_CIF_ERROR"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Overrides" >
        <TableComp
          :tableData="FilterTableData.searchData(tableData.overridesTableData,searchableFields,searchKeyword)"
          :columns="commonColumnDetails"
          :previousPage="GlobalAttributes.kycFormStatus.T24_CIF_OVERRIDES"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Corrected Overrides" >
        <TableComp
            :tableData="FilterTableData.searchData(tableData.correctedOverridesTableData,searchableFields,searchKeyword)"
            :columns="correctedOverridesColumnDetails"
            :previousPage="GlobalAttributes.kycFormStatus.T24_CORRECTED_OVERRIDES"
            :statusToAssign="statusToAssign"
            :searchableFields="searchableFields"
            styles="mt-4 mx-6"
        />
      </b-tab-item>


      <b-tab-item label="FCM Rejected" >
        <TableComp
          :tableData="FilterTableData.searchData(tableData.fcmRejectedTableData,searchableFields,searchKeyword)"
          :columns="rejectedCommonColumnDetails"
          :previousPage="GlobalAttributes.kycFormStatus.FCM_REJECTED"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Manually Rejected" >
        <TableComp
          :tableData="FilterTableData.searchData(tableData.manuallyRejectedTableData,searchableFields,searchKeyword)"
          :columns="rejectedCommonColumnDetails"
          :previousPage="GlobalAttributes.kycFormStatus.MANUALLY_REJECTED_FROM_CIF_ACC_FLOW"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Sign.Error" >
        <TableComp
          :tableData="FilterTableData.searchData(tableData.signatureErrorTableData,searchableFields,searchKeyword)"
          :columns="commonColumnDetails"
          :previousPage="GlobalAttributes.kycFormStatus.T24_SIGN_CREATE_ERROR"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Acc.Creation Error" >
        <TableComp
          :tableData="FilterTableData.searchData(tableData.accCreationErrorTableData,searchableFields,searchKeyword)"
          :columns="commonColumnDetails"
          :previousPage="GlobalAttributes.kycFormStatus.T24_ACC_CREATION_ERROR"
          :statusToAssign="statusToAssign"
          :searchableFields="searchableFields"
          styles="mt-4 mx-6"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import network from "../../network";
import {BuefyHelper} from "../../helpers/bufeyHelper";
import {GlobalAttributes} from "../../helpers/globalAttributes";
import {filterTableData} from "../../helpers/filterTableData";
// import NetworkManager from "@/network";

export default {
  components: { TableComp, SearchBar },

  name: "AccountCreationQueue",

  computed:{
    GlobalAttributes(){
      return GlobalAttributes
    },

    FilterTableData(){
      return filterTableData
    }
  },

  data() {
    return {
      activeTab: 0,


      tableData : {
        retryTableData: [],
        overridesTableData: [],
        correctedOverridesTableData: [],
        fcmRejectedTableData: [],
        manuallyRejectedTableData: [],
        signatureErrorTableData: [],
        accCreationErrorTableData: [],
      },

      statusToAssign: "",

      rejectedCommonColumnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "email", label: "Email", date: false },
        { id: 5, field: "mobile_number", label: "Contact", date: false },
        { id: 6, field: "status_updated_at", label: "Rejected Date", date: true, },
        { id: 7, field: "action", label: "Action", date: false },
      ],

      correctedOverridesColumnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "email", label: "Email", date: false },
        { id: 5, field: "mobile_number", label: "Contact", date: false },
        { id: 6, field: "status_updated_at", label: "Corrected Date", date: true, },
        { id: 7, field: "action", label: "Action", date: false },
      ],

      commonColumnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "email", label: "Email", date: false },
        { id: 5, field: "mobile_number", label: "Contact", date: false },
        { id: 6, field: "status_updated_at",label: "Received Date", date: true, },
        { id: 7, field: "action", label: "Action", date: false },
      ],

      searchableFields: [
        "reference_number",
        "nic_eic_number",
        "full_name",
        "email",
        "mobile_number",
        "created_at",
        "status_updated_at",
      ],
      searchKeyword: "",
    };
  },


  methods: {

    getApplicationsByStatus(status,callback){
      const comp = this

      const requestBody = {
        status : status
      }

      console.log("Get assigned applications by status")
      console.log("requestBody",requestBody)

      network.apiRequest("api/KycApplication/listAssignedApplicationByStatus",requestBody,(response)=>{

        console.log("Get assigned applications by status response ==============")
        console.log(response)

        if(response.statusCode === 200){
          // add applications to the correct array

          switch (status){
            case GlobalAttributes.kycFormStatus.T24_CIF_ERROR:
              comp.tableData.retryTableData = response.data.applications
              break;
            case  GlobalAttributes.kycFormStatus.FCM_REJECTED:
              comp.tableData.fcmRejectedTableData = response.data.applications
              break;
            case GlobalAttributes.kycFormStatus.T24_CIF_OVERRIDES:
              comp.tableData.overridesTableData = response.data.applications
              break;
            case GlobalAttributes.kycFormStatus.T24_CORRECTED_OVERRIDES:
              comp.tableData.correctedOverridesTableData = response.data.applications
              break;
            case GlobalAttributes.kycFormStatus.T24_SIGN_CREATE_ERROR:
              comp.tableData.signatureErrorTableData = response.data.applications
              break;
            case GlobalAttributes.kycFormStatus.T24_ACC_CREATION_ERROR:
              comp.tableData.accCreationErrorTableData = response.data.applications
              break
            case GlobalAttributes.kycFormStatus.MANUALLY_REJECTED_FROM_CIF_ACC_FLOW:
              comp.tableData.manuallyRejectedTableData = response.data.applications
              break
          }

          // call back here
          if(typeof callback === "function"){
            callback()
          }
        }
        else {
          console.warn("failed to get the assigned applications")
          BuefyHelper.showToastMessage("Something went wrong!","is-danger")
        }
      })

    },


    loadInitialData(){
      const comp = this

      comp.showFullScreenLoader()
      comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.T24_CIF_ERROR,()=>{
        comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.T24_CIF_OVERRIDES,()=>{
          comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.T24_CORRECTED_OVERRIDES,()=>{
            comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.FCM_REJECTED,()=>{
              comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.MANUALLY_REJECTED_FROM_CIF_ACC_FLOW,()=>{
                comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.T24_SIGN_CREATE_ERROR,()=>{
                  comp.getApplicationsByStatus(GlobalAttributes.kycFormStatus.T24_ACC_CREATION_ERROR,()=>{
                    comp.hideFullScreenLoader()
                  })
                })
              })
            })
          })
        })
      })
    }

  },


  mounted() {
    const comp = this;

    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }


    // load applications
    comp.loadInitialData()


    // navigating to correct tab
    if( typeof this.$route.query.tabId !== "undefined"){

      console.log("The id is: " + comp.$route.query.tabId);
      this.activeTab = parseInt(comp.$route.query.tabId);
      console.log("Active tab value redefine==================================:", this.activeTab);
    }

  },
};
</script>
<style scoped></style>
