<template>
    <div style="padding: 50px;" class="page-container" >
 
     <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
 
     <!-- <div class="columns" style="margin-left: 10px; margin-top: 10px;">
         <div>
           <b-button rounded @click="$router.go(-1)"> <i class="mdi mdi-arrow-left mdi-36px"></i>  </b-button>
         </div>
         <div >
           <p style="color: #aaaaaa; font-size: 25px">Download Application Preview</p>
         </div>
         <div style="margin-left: auto;">
             <b-button @click="generatePDF">DOWNLOAD</b-button>
         </div>    
     </div> -->
 
     <!--PDF DOWNLOAD CONTROLLER -->
     <!--IF DOWNLOAD IS NOT WORKING. MAKE SURE :enable-download= true and in hasDownloaded() this.goback is not commented-->
     <vue-html2pdf 
         :show-layout="true"
         :float-layout="false"
         :enable-download="false"
         :preview-modal="false"
         :htmlToPdfOptions="{
             filename: formData.title.customer_input +' '+formData.full_name.customer_input,
             margin: 0.3,
             image: {
                 type: 'jpeg', 
                 quality: 1
             },
         
             enableLinks: false,
         
             html2canvas: {
                 scale: 1,
                 useCORS: true
             },
         
             jsPDF: {
                 unit: 'in',
                 format: 'a3',
                 orientation: 'portrait',
                 putOnlyUsedFonts :true
             }
         }"
         :paginate-elements-by-height="1800"
         :pdf-quality="2"
         
         pdf-orientation="portrait"
         pdf-content-width="90%"
         :manual-pagination="false" 
         @progress="onProgress($event)"
         @startPagination="startPagination()"
         @hasPaginated="hasPaginated()"
         @beforeDownload="beforeDownload($event)"
         @hasDownloaded="hasDownloaded($event)"
         ref="html2Pdf"
     > 
     <section slot="pdf-content">  
         <img src="../../assets/logo.png" style="mix-blend-mode: darken; width: 10vw; margin-left: 3vw;" />
         <div style="align-items: center; text-align: center;">
            <img v-if="formData.selfie_attachment.attachment_type !== `pdf`"
                class="imgAttachment" :src="formData.selfie_attachment.attachment_url" alt="Selfie Image" style="width:15vw;  height: 26vh;">
            <img v-else
                class="imgAttachment" :src="pdfPlaceholder" alt="ID Back Image" style="width:15vw;  height: 26vh;">
            <br>
             <label style="font-size: 32px;">KYC Application Profile</label>
             <br>
             <label class="text">{{ formData.title.customer_input + " " + formData.full_name.customer_input }}</label>
         </div>
 
         <div>
                <p>Personal Details</p>
                <!-- Title -->
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel 
                            placeholder=" -- No data -- "
                            label="Title"
                            readonly="true"
                            :value="formData.title.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Title"
                            :value="formData.title.call_agent_input"
                            :hide="!formData.title.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <!-- <InputLabel placeholder=" -- No data -- " 
                            label="Full Name (As per the NIC / EIC / PP)"
                            readonly="true"
                            :value="formData.full_name.customer_input"
                            class="non-interactable-input"
                        /> -->
                        <FullNameView
                            class="mt-1 non-interactable-input"
                            label="Full Name (As per the NIC / EIC / PP)"
                            :value="formData.full_name.customer_input"
                            :is-agent="false"
                        />
                    </div>
                    <div class="column">
                        <FullNameView v-if="formData.full_name.is_changed_by_call_agent"
                            class="mt-1 non-interactable-input"
                            label="Full Name (As per the NIC / EIC / PP)"
                            :value="formData.full_name.call_agent_input"
                            :is-agent="true"
                        />
                        <!-- <AgentInputField
                            label="Full Name (As per the NIC / EIC / PP)"
                            :value="formData.full_name.call_agent_input"
                            :hide="!formData.full_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        /> -->
                    </div>
                </div>
                <!-- citizenship -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel 
                            placeholder=" -- No data -- "
                            label="Citizenship"
                            readonly="true"
                            :value="formData.citizenship.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Citizenship"
                        :value="formData.citizenship.call_agent_input"
                        :hide="!formData.citizenship.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                            label="Residency"
                            readonly="true"
                            :value="formData.residentship.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Residency"
                            :value="formData.residentship.call_agent_input"
                            :hide="!formData.residentship.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- date of birth -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                            label="Nationality"
                            readonly="true"
                            :value="formData.nationality.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Nationality"
                            :value="formData.nationality.call_agent_input"
                            :hide="!formData.nationality.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column ">
                        <InputLabel
                            label="Date of Birth"
                            readonly="true"
                            :value="getFormattedDateTime(formData.date_of_birth.customer_input)"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column ">
                        <AgentInputField
                            label="Date of Birth"
                            :value="getFormattedDateTime(formData.date_of_birth.call_agent_input)"
                            :hide="!formData.date_of_birth.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Nic -->
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                            label="NIC / EIC Number"
                            readonly="true"
                            :value="formData.nic_eic_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column ">
                        <AgentInputField
                            label="NIC / EIC Number"
                            :value="formData.nic_eic_number.call_agent_input"
                            :hide="!formData.nic_eic_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="NIC Issue Date"
                            readonly="true"
                            :value="getFormattedDateTime(formData.nic_eic_issue_date.customer_input)"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="NIC Issue Date"
                            :value="getFormattedDateTime(formData.nic_eic_issue_date.call_agent_input)"
    
                            :hide="!formData.nic_eic_issue_date.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>

                <!-- Passport -->
                <div v-if="formData.passport_number.customer_input != ''">
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column">
                            <InputLabel placeholder=" -- No data -- "
                            label="Passport Number"
                            readonly="true"
                            :value="formData.passport_number.customer_input"
                            class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Passport Number"
                            :value="formData.passport_number.call_agent_input"
                            :hide="!formData.passport_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>
                    </div>
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column">
                            <InputLabel
                            label="Passport Issue Date "
                            reaDonly="true"
                            :value="
                                getFormattedDateTime(
                                formData.passport_issue_date.customer_input
                                )
                            "
                            class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Passport Issue Date"
                            :value="
                                getFormattedDateTime(
                                formData.passport_issue_date.call_agent_input
                                )
                            "
                            :hide="!formData.passport_issue_date.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>
                    </div>
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column">
                            <InputLabel
                                label="Passport Expire Date"
                                readonly="true"
                                :value="
                                    getFormattedDateTime(
                                    formData.passport_expire_date.customer_input
                                    )
                                "
                                class="non-interactable-input"
                            />
                        </div>
                        <div class="column">
                            <AgentInputField
                            label="Passport Expire Date"
                            :value="
                                getFormattedDateTime(
                                formData.passport_expire_date.call_agent_input
                                )
                            "
                            :hide="!formData.passport_expire_date.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                            />
                        </div>

                    </div>
                </div>

                <!-- gender -->
                <div class="columns is-7 is-variable" style="margin-top:-3vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                            label="Gender"
                            readonly="true"
                            :value="formData.gender.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Marital Status"
                        :value="formData.maritial_status.call_agent_input"
                        :hide="!formData.maritial_status.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                        label="Marital Status"
                        readonly="true"
                        :value="formData.maritial_status.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Gender"
                            :value="formData.gender.call_agent_input"
                            :hide="!formData.gender.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
            </div>     
 
             <hr style="width:90vw"
             :style="{
                 'background-color': '#1b1b1b',
                 height: '1.8px',
                 opacity: '0.5',
                 'margin-inline': '4px',
             }"
             />
 
            <div>
                <!-- Permanent Address -->
                <p>Permanent Address</p>
                <div class="columns is-7 is-variable">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                            label="Building Number"
                            readonly="true"
                            :value="formData.building_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">  
                        <AgentInputField
                            label="Building Number"
                            :value="formData.building_number.call_agent_input"
                            :hide="!formData.building_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />                          
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Building Name"
                            readonly="true"
                            :value="formData.building_name.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">    
                        <AgentInputField
                            label="Building Name"
                            :value="formData.building_name.call_agent_input"
                            :hide="!formData.building_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Street"
                            readonly="true"
                            :value="formData.street.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">        
                        <AgentInputField
                        label="Street"
                        :value="formData.street.call_agent_input"
                        :hide="!formData.street.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Street 2"
                            readonly="true"
                            :value="formData.street_2.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">        
                        <AgentInputField
                        label="Street 2"
                        :value="formData.street_2.call_agent_input"
                        :hide="!formData.street_2.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Town"
                            readonly="true"
                            :value="formData.town.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">        
                        <AgentInputField
                            label="Town"
                            :value="formData.town.call_agent_input"
                            :hide="!formData.town.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Postal Code"
                            readonly="true"
                            :value="formData.postal_code.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Postal Code"
                            :value="formData.postal_code.call_agent_input"
                            :hide="!formData.postal_code.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>  
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="District"
                            readonly="true"
                            :value="formData.district.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="District"
                            :value="formData.district.call_agent_input"
                            :hide="!formData.district.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                            label="Country"
                            readonly="true"
                            :value="formData.country.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Country"
                            :value="formData.country.call_agent_input"
                            :hide="!formData.country.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel
                            label="Status of Permanant Residence"
                            readonly="true"
                            :value="GlobalFunctions.getStatusOfResidenceDisplayValue(formData.state_of_residence.customer_input)"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Status of Permanant Residence"
                            :value="GlobalFunctions.getStatusOfResidenceDisplayValue(formData.state_of_residence.call_agent_input)"
                            :hide="!formData.state_of_residence.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>

                <hr style="width:90vw"
                    :style="{
                        'background-color': '#1b1b1b',
                        height: '1.8px',
                        opacity: '0.5',
                        'margin-inline': '4px',
                    }"
                    />
            </div>

             <!-- correspondence  Address -->
            <!-- <div v-if="formData.is_correspondence_address_differs_with_the_permanent_address"> -->
            <div>
                <p>Correspondence  Address</p>
                <div class="columns is-7 is-variable mt-1">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                        label="Building Number"
                        readonly="true"
                        :value="
                            formData.correspondence_address_building_number.customer_input
                        "
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Building Number"
                        :value="
                            formData.correspondence_address_building_number
                            .call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_building_number
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable"  style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                        label="Building Name"
                        readonly="true"
                        :value="
                            formData.correspondence_address_building_name.customer_input
                        "
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Building Name"
                        :value="
                            formData.correspondence_address_building_name.call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_building_name
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                        label="Street"
                        readonly="true"
                        :value="formData.correspondence_address_street.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Street"
                        :value="
                            formData.correspondence_address_street.call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_street
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                        label="Street 2"
                        readonly="true"
                        :value="formData.correspondence_address_street_2.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Street 2"
                        :value="
                            formData.correspondence_address_street_2.call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_street_2
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                        label="Town"
                        readonly="true"
                        :value="formData.correspondence_address_town.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Town"
                        :value="formData.correspondence_address_town.call_agent_input"
                        :hide="
                            !formData.correspondence_address_town.is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                        label="Postal Code"
                        readonly="true"
                        :value="
                            formData.correspondence_address_postal_code.customer_input
                        "
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Postal Code"
                        :value="
                            formData.correspondence_address_postal_code.call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_postal_code
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                        label="District"
                        readonly="true"
                        :value="
                            formData.correspondence_address_district.customer_input
                        "
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="District"
                        :value="
                            formData.correspondence_address_district.call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_district
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                        label="Country"
                        readonly="true"
                        :value="formData.correspondence_address_country.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        label="Country"
                        :value="
                            formData.correspondence_address_country.call_agent_input
                        "
                        :hide="
                            !formData.correspondence_address_country
                            .is_changed_by_call_agent
                        "
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>

                <hr style="width:90vw"
                    :style="{
                        'background-color': '#1b1b1b',
                        height: '1.8px',
                        opacity: '0.5',
                        'margin-inline': '4px',
                    }"
                    />
            </div>
            <div class="html2pdf__page-break"/>
            <div>
                <!-- contact -->
                <P>Contact</P>
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Primary Mobile Number"
                            readonly="true"
                            :value="formData.mobile_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Primary Mobile Number"
                            :value="formData.mobile_number.call_agent_input"
                            :hide="!formData.mobile_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" >
                    <div class="column" >
                        <InputLabel placeholder=" -- No data -- " 
                            label="Mobile/Telephone Number"
                            readonly="true"
                            :value="formData.telephone_number.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Mobile/Telephone Number"
                            :value="formData.telephone_number.call_agent_input"
                            :hide="!formData.telephone_number.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Email Address -->
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Primary Email ID"
                            readonly="true"
                            :value="formData.email.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Primary Email ID"
                            :value="formData.email.call_agent_input"
                            :hide="!formData.email.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>

                <!--Secondary  Email Address -->
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " v-if="formData.secondary_email.customer_input || formData.secondary_email.is_changed_by_call_agent"
                            label="Additional Email Address"
                            readonly="true"
                            :value="formData.secondary_email.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Additional Email Address"
                            :value="formData.secondary_email.call_agent_input"
                            :hide="!formData.secondary_email.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <!-- Mother’s Maiden Name -->
                <div class="columns is-7 is-variable" >
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " 
                            label="Mother’s Maiden Name"
                            readonly="true"
                            :value="formData.mothers_maiden_name.customer_input"
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Mother’s Maiden Name"
                            :value="formData.mothers_maiden_name.call_agent_input"
                            :hide="!formData.mothers_maiden_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                
                
                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />
            </div>   
 
             <div>
                 <!-- Employment Details -->
                 <p>Employment Details</p>

                <!-- industry -->
                 <InputLabel placeholder=" -- No data -- " v-if="formData.customer_industry  !==null"
                   label="Industry"
                   readonly="true"
                   :value="formData.customer_industry.description"
                   class="non-interactable-input"
                 />

               <!-- occupation -->
               <InputLabel placeholder=" -- No data -- " v-if="GlobalFunctions.checkCustomerOccupationRequired(formData) && formData.customer_occupation  !==null"
                           label="Occupation"
                           readonly="true"
                           :value="formData.customer_occupation"
                           class="non-interactable-input"
               />
             <div class="columns is-7 is-variable">
                 <div class="column">
                    <InputLabel placeholder=" -- No data -- " 
                     label="Employment Status"
                     readonly="true"
                     :value="formData.employment_status.customer_input"
                     class="non-interactable-input"
                 />
                 </div>
                 <div class="column">
                 <AgentInputField
                     label="Employment Status"
                     :value="formData.employment_status.call_agent_input"
                     :hide="!formData.employment_status.is_changed_by_call_agent"
                     readonly="true"
                     class="non-interactable-input"
                 />
                 </div>
             </div>
             <div v-if="
                formData.employment_status.is_changed_by_call_agent ? 
                formData.employment_status.call_agent_input ===  'Self-Employed' ||
                formData.employment_status.call_agent_input === 'Freelance' : 
                formData.employment_status.customer_input === 'Freelance' ||
                formData.employment_status.customer_input === 'Self-Employed'"
          >
          <InputLabel placeholder=" -- No data -- " 
                label="Profession / Nature of Business"
                readonly="true"
                :value="formData.profession_nature_of_business.customer_input"
                class="non-interactable-input"
                />
                <AgentInputField 
                label="Profession / Nature of Business"
                :value="formData.profession_nature_of_business.call_agent_input"
                :hide="
                    !formData.profession_nature_of_business.is_changed_by_call_agent
                "
                readonly="true"
                class="non-interactable-input"
                />
            </div>
            <div
                v-if="formData.employment_status.is_changed_by_call_agent ?
                formData.employment_status.call_agent_input === 'Full-Time' ||
                formData.employment_status.call_agent_input === 'Part Time' ||
                formData.employment_status.call_agent_input === 'Self-Employed' ||
                formData.employment_status.call_agent_input === 'Probation' : formData.employment_status.customer_input === 'Full-Time' ||
                formData.employment_status.customer_input === 'Part Time' ||
                formData.employment_status.customer_input === 'Self-Employed' ||
                formData.employment_status.customer_input === 'Probation'
                "
            >
            <InputLabel placeholder=" -- No data -- "
                label="Employer /  Business Name"
                readonly="true"
                :value="formData.employer.customer_input"
                styles="mt-5"
                class="non-interactable-input"
                />
                <AgentInputField
                label="Employer /  Business Name"
                :value="formData.employer.call_agent_input"
                styles="mt-5"
                :hide="!formData.employer.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
                />
            </div>
            <div
            v-if="
              formData.employment_status.is_changed_by_call_agent ? (formData.employment_status.call_agent_input === 'Full-Time' ||
              formData.employment_status.call_agent_input === 'Part Time' ||
              formData.employment_status.call_agent_input === 'Self-Employed' ||
              formData.employment_status.call_agent_input === 'Probation' ) : (formData.employment_status.customer_input === 'Full-Time' ||
              formData.employment_status.customer_input === 'Part Time' ||
              formData.employment_status.customer_input === 'Self-Employed' ||
              formData.employment_status.customer_input === 'Probation' )
            "
          >
            <!-- Employer / Business Address -->
            <p class="mt-4">Employer / Business Address</p>
            <div class="columns is-7 is-variable">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- " 
                    label="Building Number"
                    readonly="true"
                    :value="formData.emp_building_number.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">         
                    <AgentInputField
                    label="Building Number"
                    :value="formData.emp_building_number.call_agent_input"
                    :hide="!formData.emp_building_number.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- " 
                    label="Building Name"
                    readonly="true"
                    :value="formData.emp_building_name.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Building Name"
                    :value="formData.emp_building_name.call_agent_input"
                    :hide="!formData.emp_building_name.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- "
                    label="Street"
                    readonly="true"
                    :value="formData.emp_street.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Street"
                    :value="formData.emp_street.call_agent_input"
                    :hide="!formData.emp_street.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- " 
                    label="Town"
                    readonly="true"
                    :value="formData.emp_town.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Town"
                    :value="formData.emp_town.call_agent_input"
                    :hide="!formData.emp_town.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- " 
                    label="Postal Code"
                    readonly="true"
                    :value="formData.emp_postal_code.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Postal Code"
                    :value="formData.emp_postal_code.call_agent_input"
                    :hide="!formData.emp_postal_code.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- " 
                    label="District"
                    readonly="true"
                    :value="formData.emp_district.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="District"
                    :value="formData.emp_district.call_agent_input"
                    :hide="!formData.emp_district.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable" style="margin-top:-2vh">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- "
                    label="Country"
                    readonly="true"
                    :value="formData.emp_country.customer_input"
                    class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                    label="Country"
                    :value="formData.emp_country.call_agent_input"
                    :hide="!formData.emp_country.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
                </div>
            </div>
          </div>
          <div v-if="formData.employment_status.is_changed_by_call_agent ? formData.employment_status.call_agent_input === 'Freelance' : formData.employment_status.customer_input === 'Freelance'">
            

            <InputLabel
              label="Are you working for any other platform's?"
              readonly="true"
              styles="mt-5"
              :value="
                formData.are_you_work_on_another_platform.customer_input ? 'Yes' : 'No'
              "
              class="non-interactable-input"
            />
            <AgentInputField
              label="Are you working for any other platform's?"
              :value="
                formData.are_you_work_on_another_platform.call_agent_input ? 'Yes' : 'No'
              "
              styles="mt-5"
              :hide="!formData.are_you_work_on_another_platform.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <div v-if="formData.are_you_work_on_another_platform.customer_input || formData.are_you_work_on_another_platform.call_agent_input ">
              <!-- <InputLabel
                label="What other platforms are you working in?"
                readonly="true"
                :value="formData.other_platforms_working_on.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField
                v-if="formData.other_platforms_working_on.is_changed_by_call_agent"
                label="What other platforms are you working in?"
                :value="formData.other_platforms_working_on.call_agent_input"
                styles="mt-5"
                class="non-interactable-input"
                readonly="true"
              /> -->
              <!-- other_platforms_working - customer inputs -->
              <DisplayBulletList class="non-interactable-input" label="What other platforms are you working in?" :items="formData.other_platforms_working_on.customer_input" :is-agent="false"/>

              <!-- other_platforms_working - agent inputs -->
             <DisplayBulletList class="non-interactable-input" v-if="formData.other_platforms_working_on.call_agent_input != null" label="What other platforms are you working in?" :items="formData.other_platforms_working_on.call_agent_input" :is-agent="true"/>
              
              <br />
              <InputLabel
                label="Please Specify"
                v-if="formData.other_platforms_working_on_other_selected.customer_input"
                readonly="true"
                :value="formData.other_platforms_working_on_other_description.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputField
                label="Please Specify"
                v-if="formData.other_platforms_working_on_other_selected.is_changed_by_call_agent"
                :value="formData.other_platforms_working_on_other_description.call_agent_input"
                styles="mt-5"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>

                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />
            </div>
            <div class="html2pdf__page-break"/> 
            <div>
                <!-- Tax Details -->
                <p>Tax Details</p>
                <div class="columns is-7 is-variable">
                    <div class="column" >
                        <InputLabel
                            label="Are You a Taxpayer?"
                            readonly="true"
                            :value="
                            formData.are_you_a_tax_payer.customer_input ? 'Yes' : 'No'
                            "
                            class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                            label="Are You a Taxpayer?"
                            :value="
                            formData.are_you_a_tax_payer.call_agent_input ? 'Yes' : 'No'
                            "
                            :hide="!formData.are_you_a_tax_payer.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                    </div>
                </div>
                <div class="columns is-7 is-variable" style="margin-top:-4vh" v-if="formData.are_you_a_tax_payer.customer_input || formData.are_you_a_tax_payer.call_agent_input">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- "
                        label="Tax File Number"
                        readonly="true"
                        :value="formData.tax_file_numer.customer_input"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        v-if="formData.tax_file_numer.call_agent_input != null"
                        label="Tax File Number"
                        :value="formData.tax_file_numer.call_agent_input"
                        :hide="!formData.tax_file_numer.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                </div>
                <hr style="width:90vw"
                :style="{
                    'background-color': '#1b1b1b',
                    height: '1.8px',
                    opacity: '0.5',
                    'margin-inline': '4px',
                }"
                />

            </div>
            
            <div>
                <p>PEP Declaration</p> 
                <div class="columns is-7 is-variable">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " v-if="formData.pep_declaration.customer_input.not_politically_exposed"
                        label="Are You or Your Close Relative a Politically Exposed Person?"
                        readonly="true"
                        :value="
                            formData.pep_declaration.customer_input.not_politically_exposed
                            ? 'No'
                            : 'Yes'
                        "
                        class="non-interactable-input"
                        />
                        <InputLabel placeholder=" -- No data -- " v-if="formData.pep_declaration.customer_input.close_relative_politically_exposed"
                        label="Are You or Your Close Relative a Politically Exposed Person?"
                        readonly="true"
                        :value="
                            formData.pep_declaration.customer_input.close_relative_politically_exposed
                            ? 'Yes : Close Relative Politically Exposed'
                            : 'No'
                        "
                        class="non-interactable-input"
                        />
                        <InputLabel placeholder=" -- No data -- " v-if="formData.pep_declaration.customer_input.iam_politically_exposed"
                        label="Are You or Your Close Relative a Politically Exposed Person?"
                        readonly="true"
                        :value="
                            formData.pep_declaration.customer_input.iam_politically_exposed
                            ? 'Yes : I am Politically Exposed'
                            : 'No'
                        "
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <div v-if="formData.pep_declaration.is_changed_by_call_agent">
                            <AgentInputField  v-if="formData.pep_declaration.call_agent_input.not_politically_exposed"
                                label="Are You or Your Close Relative a Politically Exposed Person?"
                                :value="
                                formData.pep_declaration.call_agent_input.not_politically_exposed
                                    ? 'No'
                                    : 'Yes'
                                "
                                :hide="!formData.pep_declaration.is_changed_by_call_agent"
                                readonly="true"
                                class="non-interactable-input"
                            />
                        </div>
                        <div v-if="formData.pep_declaration.is_changed_by_call_agent">
                        <AgentInputField  v-if="formData.pep_declaration.call_agent_input.close_relative_politically_exposed"
                            label="Are You or Your Close Relative a Politically Exposed Person?"
                            :value="
                            formData.pep_declaration.call_agent_input.close_relative_politically_exposed
                                ? 'Yes : Close Relative Politically Exposed'
                                : 'No'
                            "
                            :hide="!formData.pep_declaration.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        </div>
                        <div v-if="formData.pep_declaration.is_changed_by_call_agent">
                        <AgentInputField  v-if="formData.pep_declaration.call_agent_input.iam_politically_exposed"
                            label="Are You or Your Close Relative a Politically Exposed Person?"
                            :value="
                            formData.pep_declaration.call_agent_input.iam_politically_exposed
                                ? 'Yes : I am Politically Exposed'
                                : 'No'
                            "
                            :hide="!formData.pep_declaration.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        </div>
                    </div>             
                </div>

                <div v-if="!formData.pep_declaration.customer_input.not_politically_exposed">
                    <div class="columns is-7 is-variable" v-for="pep_detail in formData.pep_declaration.customer_input.pep_person_details" :key="pep_detail.name">
                    <div class="column">
                        <InputLabel
                        v-if="pep_detail.name != ''"
                        label="Name"
                        readonly="true"
                        :value="pep_detail.name"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <InputLabel
                        v-if="pep_detail.designation != ''"
                        label="Designation"
                        readonly="true"
                        :value="pep_detail.designation"
                        class="non-interactable-input"
                        />
                    </div>
                    </div>
                </div>

                <div v-if="formData.pep_declaration.is_changed_by_call_agent">
                    <div class="columns is-7 is-variable" v-for="pep_detail in formData.pep_declaration.call_agent_input.pep_person_details" :key="pep_detail.name">
                    <div class="column">
                        <AgentInputField
                        v-if="pep_detail.name != ''"
                        label="Name"
                        :value="pep_detail.name"
                        :hide="!formData.pep_declaration.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                    <div class="column">
                        <AgentInputField
                        v-if="pep_detail.designation != ''"
                        label="Designation"
                        :value="pep_detail.designation"
                        :hide="!formData.pep_declaration.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                        />
                    </div>
                    </div>
                </div>
                
            </div>

            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />  
 
             <div>
                 <p>Financial Details</p>
                 <div class="columns is-7 is-variable">
                         <div class="column">
                             <DisplayBulletList label="Source of Funds" class="non-interactable-input"  :items="formData.source_of_funds.customer_input" :is-agent="false"/>
                         </div>
                         <div class="column">
                             <!-- source of funds - agent inputs -->
                             <DisplayBulletList label="Source of Funds" style="color:red" class="non-interactable-input" v-if="formData.source_of_funds.call_agent_input != null"  :items="formData.source_of_funds.call_agent_input" :is-agent="true"/>
                         </div>
                 </div>
 
                 <br />
                 <InputLabel
                     v-if="formData.source_of_funds_other.customer_input"
                     label="Please Specify"
                     readonly="true"
                     :value="formData.source_of_funds_other_description.customer_input"
                     class="non-interactable-input"
                 />
                 <AgentInputField
                     label="Please Specify"
                     :value="formData.source_of_funds_other_description.call_agent_input"
                     :hide="!formData.source_of_funds_other_description.is_changed_by_call_agent"
                     readonly="true"
                     class="non-interactable-input"
                 />
                 
                 
                 <hr style="width:90vw"
                 :style="{
                     'background-color': '#1b1b1b',
                     height: '1.8px',
                     opacity: '0.5',
                     'margin-inline': '4px',
                 }"
                 />
             </div>
 
             <p>Branch Details</p>
            <div class="columns is-7 is-variable">
                <div class="column">
                    <InputLabel placeholder=" -- No data -- " v-if="customer_branch.branch_name"
                        label="Preferred Branch"
                        readonly="true"
                        :value="customer_branch.branch_name"
                        class="non-interactable-input"
                    />
                </div>
                <div class="column">
                    <AgentInputField
                        v-if="agent_branch != null"
                        label="Preferred Branch"
                        :value="agent_branch.branch_name"
                        :hide="!formData.branch_id.is_changed_by_call_agent"
                        readonly="true"
                        class="non-interactable-input"
                    />
                </div>
            </div>
            <div class="columns is-7 is-variable">
                <div class="column">
                    <InputLabel
                        v-if="formData.branch_comment.customer_input != '' "
                        label=" Reason for opening an account in a branch that is located far from the permanent address"
                        readonly="true"
                        :value="formData.branch_comment.customer_input"
                        class="non-interactable-input"
                    />
                </div>
                <div class="column">   
                    <AgentInputField
                        v-if="formData.branch_comment.is_changed_by_call_agent"
                        label="Reason for opening an account in a branch that is located far from the permanent address"
                        :value="formData.branch_comment.call_agent_input"
                        readonly="true"
                        class="non-interactable-input"
                    />
                </div>
            </div>
            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />

             <!-- Product Selection -->
             <div class="html2pdf__page-break"/>
 
             <div v-for="product in products" :key="product.id">
                 <p>Product Selection - 
                     <span v-if="product.account_type.is_changed_by_call_agent">
                         {{ product.account_type.customer_input }}
                     </span>
                     <span v-if="product.account_type.is_changed_by_call_agent == false">
                         {{ product.account_type.customer_input }}
                     </span>
                 </p>
                 <div class="columns is-7 is-variable">
                    <div class="column">
                        <InputLabel placeholder=" -- No data -- " v-if="formData.current_status != 'REJECTED'"
                           label="Onboarding Account Number"
                           :value="product.account_number"
                           class="non-interactable-input"
                           styles="mt-2"
                           readonly="true"
                       /> 
                    </div>
                    <div class="column">
                        
                    </div>
                 </div>
                 <div class="columns is-7 is-variable">
                     <div class="column ">
                        <InputLabel placeholder=" -- No data -- " 
                         label="Currency"
                         readonly="true"
                         :value="product.currency_type.customer_input"
                         class="non-interactable-input"
                         />
                     </div>
                     <div class="column">
                         <AgentInputField
                         label="Currency"
                         :value="product.currency_type.call_agent_input"
                         :hide="!product.currency_type.is_changed_by_call_agent"
                         readonly="true"
                         class="non-interactable-input"
                         />
                     </div>
                 </div>
                 <div>
                     <!-- saving_account_type -->
                     <div class="columns is-7 is-variable">
                         <!-- saving_account_type -->
                         <div class="column">
                            <InputLabel placeholder=" -- No data -- " 
                             label="Account"
                             readonly="true"
                             :value="product.account_type.customer_input"
                             class="non-interactable-input"
                             />
                         </div>
                         <div class="column">
                             <AgentInputField
                             label="Account"
                             :value="product.account_type.call_agent_input"
                             :hide="!product.account_type.is_changed_by_call_agent"
                             readonly="true"
                             class="non-interactable-input"
                             />
                         </div>
                     </div>
 
                     <!-- Purpose of Account Opening -->
                     <!-- purpose of opening account - customer inputs -->
                     <div class="columns is-7 is-variable">
                         <div class="column">
                             <DisplayBulletList class="non-interactable-input" label="Purpose of Account Opening" :items="product.purpose_of_opening_customer_inputs" :is-agent="false"/>
                         </div>
                         <div class="column">
                             <!-- purpose of opening account - agent inputs -->
                             <DisplayBulletList style="color:red" class="non-interactable-input" v-if="product.purpose_of_opening_account.is_changed_by_call_agent" label="Purpose of Account Opening" :items="product.purpose_of_opening_agent_inputs" :is-agent="true"/>
                         </div>
                     </div>
                     
                     
                     <div class="columns is-7 is-variable">
                         
                         <!-- OTHER SPECIFIED  -->
                         <div class="column">
                             <InputLabel
                                 v-if="product.purpose_of_opening_other_specify_customer_inputs !== ''"
                                 label="Please Specify the Purpose"
                                 readonly="true"
                                 :value="product.purpose_of_opening_other_specify_customer_inputs"
                                 class="non-interactable-input"
                             />
                         </div>
                         <div class="column">
                             <AgentInputField
                                 label="Please Specify the Purpose"
                                 :value="product.purpose_of_opening_other_specify_agent_inputs"
                                 :hide="product.purpose_of_opening_other_specify_agent_inputs == ''"
                                 readonly="true"
                                 class="non-interactable-input"
                             />
                         </div>
                     </div>          
                 </div>
                 <!-- Anticipated Volumes of Deposits from above Sources -->
                 
                 <div class="columns is-7 is-variable">
                     <div class="column">  
                        <InputLabel placeholder=" -- No data -- "
                             label="AAnticipated Volumes of Deposits from declared Sources (Per Month)"
                             readonly="true"
                             :value="product.anticipated_volume.customer_input"
                             class="non-interactable-input"
                         />
                     </div>
                     <div class="column">  
                         <AgentInputField
                             label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                             :value="product.anticipated_volume.call_agent_input"
                             :hide="!product.anticipated_volume.is_changed_by_call_agent"
                             readonly="true"
                             class="non-interactable-input"
                         />
                     </div>
                 </div>
                 <!-- Initial Deposit -->
                 <div class="columns is-7 is-variable" style="margin-top: -6vh;">
                     <div class="column">  
                        <InputLabel placeholder=" -- No data -- " 
                             label="Initial Deposit"
                             readonly="true"
                             :value="product.initial_deposit_amount.customer_input"
                             styles="mt-5"
                             class="non-interactable-input"
                         />
                     </div>
                     <div class="column">  
                         <AgentInputField
                             label="Initial Deposit"
                             :value="product.initial_deposit_amount.call_agent_input"
                             styles="mt-5"
                             :hide="!product.initial_deposit_amount.is_changed_by_call_agent"
                             readonly="true"
                             class="non-interactable-input"
                         />
                     </div>
                 </div>
                 <br>
                 <div v-if="products.length > 2">
                    <div class="html2pdf__page-break"/>
                 </div>
             </div>
 
             <hr style="width:90vw"
             :style="{
                 'background-color': '#1b1b1b',
                 height: '1.8px',
                 opacity: '0.5',
                 'margin-inline': '4px',
             }"
             />
 
             <div>
                <!-- Value Added Services -->
                <p>Value Added Services</p>
                <div class="columns is-7 is-variable">
                    <div class="column">
                    <InputLabel
                        label="SMS Alerts"
                        readonly="true"
                        :value="formData.vas_sms_alert.customer_input ? 'Yes' : 'No'"
                        class="non-interactable-input"
                    />
                    </div>

                    <div class="column">
                    <InputLabel
                        label="Online Banking"
                        readonly="true"
                        :value="formData.vas_online_banking.customer_input ? 'Yes' : 'No'"
                        class="non-interactable-input"
                    />
                    </div>
                </div>               
                <div>
                    <div class="columns is-7 is-variable" style="margin-top:-4vh">
                        <div class="column is-half">
                        <InputLabel v-if="formData.residentship.customer_input == 'Sri Lanka'"
                            label="Virtual Wallet"
                            readonly="true"
                            :value="formData.vas_virtual_wallet.customer_input ? 'Yes' : 'No'"
                            class="non-interactable-input"
                        />
                        </div>

                        <div class="column is-half">
                        <InputLabel
                            label="E-Statement"
                            readonly="true"
                            :value="formData.vas_estatement.customer_input ? 'Yes' : 'No'"
                            class="non-interactable-input"
                        />
                        </div>
                    </div>
                    <!--  debit card -->
                    <div v-if="GlobalFunctions.checkDebitCardSectionVisible(formData.residentship,products)">
                        <div class="columns is-7 is-variable" style="margin-top:-4vh">
                            <div class="column">
                                <InputLabel
                                    label="Debit Card"
                                    readonly="true"
                                    :value="formData.vas_debit_card.customer_input ? 'Yes' : 'No'"
                                    class="non-interactable-input"
                                />
                                <AgentInputField
                                    label="Debit Card"
                                    :value="formData.vas_debit_card.call_agent_input ? 'Yes' : 'No'"
                                    styles="mt-5"
                                    :hide="!formData.vas_debit_card.is_changed_by_call_agent"
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                            <div class="column">
                                <InputLabel
                                    v-if="formData.vas_debit_card.customer_input"
                                    label="POS Required"
                                    readonly="true"
                                    :value="
                                    formData.vas_debit_card_pos_required.customer_input
                                    ? 'Yes'
                                    : 'No'
                                "
                                class="non-interactable-input"
                                />
                                <AgentInputField
                                    label="POS Required"
                                    :value="
                                    formData.vas_debit_card_pos_required.call_agent_input
                                    ? 'Yes'
                                    : 'No'
                                "
                                    :hide="!formData.vas_debit_card_pos_required.is_changed_by_call_agent"
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>
                        <div class="columns is-7 is-variable">
                            <!-- If costomer POS REQUIRED TRUE THIS WILL SHOW-->
                            <div class="column">
                                <InputLabel placeholder=" -- No data -- " v-if="formData.vas_debit_card_pos_required.customer_input"
                                    label="Account to be Linked for POS Transaction"
                                    readonly="true"
                                    :value="formData.vas_debit_card_pos_account.customer_input"
                                    class="non-interactable-input"
                                />
                                <AgentInputField
                                    label="Account to be Linked for POS Transaction"
                                    :value="formData.vas_debit_card_pos_account.call_agent_input"
                                    :hide="
                                    !formData.vas_debit_card_pos_account.is_changed_by_call_agent
                                "
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>

                        <InputLabel
                            v-if="formData.vas_debit_card.customer_input"
                            label="Name to be Printed on Card"
                            readonly="true"
                            :value="formData.vas_debit_card_name.customer_input"
                            class="non-interactable-input"
                        />
                        <AgentInputField
                            label="Name to be Printed on Card"
                            :value="formData.vas_debit_card_name.call_agent_input"
                            :hide="!formData.vas_debit_card_name.is_changed_by_call_agent"
                            readonly="true"
                            class="non-interactable-input"
                        />
                        <!-- {{ formData.vas_debit_card_collection_details.is_changed_by_call_agent + "4" }}
                        <br />
                        {{ formData.vas_debit_card_collection_details.call_agent_input }} -->
                        <div class="columns is-7 is-variable mt-3">
                            <div class="column">
                                <InputLabel
                                    v-if="formData.vas_debit_card.customer_input"
                                    label="Card Collection Details"
                                    readonly="true"
                                    :value="
                                    formData.vas_debit_card_collection_details.customer_input
                                "
                                class="non-interactable-input"
                                />
                                <AgentInputField
                                    v-if="formData.vas_debit_card_collection_details.is_changed_by_call_agent"
                                    label="Card Collection Details"
                                    :value="
                                    formData.vas_debit_card_collection_details.call_agent_input
                                "
                                    :hide="
                                    !formData.vas_debit_card_collection_details
                                    .is_changed_by_call_agent
                                "
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                            <div class="column">
                                <InputLabel
                                    v-if="formData.vas_debit_card.customer_input"
                                    label="PIN Collection Details"
                                    readonly="true"
                                    :value="
                                    formData.vas_debit_card_pin_collection_details.customer_input
                                "
                                class="non-interactable-input"
                                />
                                <AgentInputField
                                    v-if="formData.vas_debit_card_pin_collection_details.is_changed_by_call_agent"
                                    label="PIN Collection Details"
                                    :value="
                                    formData.vas_debit_card_pin_collection_details.call_agent_input
                                "
                                    :hide="
                                    !formData.vas_debit_card_pin_collection_details
                                    .is_changed_by_call_agent
                                "
                                    readonly="true"
                                    class="non-interactable-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr style="width:90vw"
            :style="{
                'background-color': '#1b1b1b',
                height: '1.8px',
                opacity: '0.5',
                'margin-inline': '4px',
            }"
            />
 
             <div>
                 <!-- Video Call Details -->
                 <p>Video Call Details</p>
                 <div>
                     <div class="columns is-7 is-variable mt-1">
                         <div class="column">
                             <InputLabel
                                 label="Preferred Date"
                                 readonly="true"
                                 :value="getFormattedDateTime(formData.video_call_date)"
                                 class="non-interactable-input"
                             />
                             </div>
                             <div class="column">
                             <InputLabel
                                 label="Preferred Time"
                                 readonly="true"
                                 :value="formData.video_call_time"
                                 class="non-interactable-input"
                             />
                         </div>
                     </div>
                     <div class="columns is-7 is-variable mt-1" v-if="isvideocalldone">
                         <div class="column">
                         <InputLabel
                             label="Date of Account Funding"
                             readonly="true"
                             :value="getFormattedDateTime(formData.date_of_account_funding)"
                             class="non-interactable-input"
                         />
                         </div>
                     </div>
                 </div> 
 
                 <hr style="width:90vw"
                 :style="{
                     'background-color': '#1b1b1b',
                     height: '1.8px',
                     opacity: '0.5',
                     'margin-inline': '4px',
                 }"
                 />
             </div>
             <!-- image part -->
 
             <!-- ================================================== PROOF IMAGES ID(F/B) / SELFI ================================================== -->
            <div>
                <div class="columns is-7 is-variable" style="width: 60vw;">
                    <div class="column is-two-third" v-if="formData.nic_front_attachment.attachment_url !== ''">
                        <label><b>NIC Front Image</b></label>
                        <br>
                    <img v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                        class="imgAttachment"
                        :src="formData.nic_front_attachment.attachment_url"
                        resposive
                        alt="NIC Front Image"/>
                    <img v-else
                    class="imgAttachment"
                    :src="pdfPlaceholder"
                    resposive
                    alt="NIC Front Image"/>
                    </div>
                    <div class="column is-two-third" v-if="formData.nic_back_attachment.attachment_url !==''">
                        <label><b>NIC Rear Image</b></label>
                        <br>
                        <img v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                            class="imgAttachment"
                            :src="formData.nic_back_attachment.attachment_url"
                            resposive
                            alt="NIC Back Image"/>
                        <img v-else
                        class="imgAttachment"
                        :src="pdfPlaceholder"
                        resposive
                        alt="NIC Back Image"/>
                    </div>
                </div>
                
                <div class="columns is-7 is-variable" style="width: 60vw;">
                    <div class="column is-two-third">
                        <label><b>Selfie image</b></label>
                        <br>
                    <img v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
                        class="imgAttachment"
                        :src="formData.selfie_attachment.attachment_url"
                        resposive
                        alt="Selfie image"/>
                    <img v-else
                    class="imgAttachment"
                    :src="pdfPlaceholder"
                    resposive
                    alt="Selfie image"/>
                    </div>
                    <div class="column is-two-third">
                        <label><b>Signature image</b></label>
                        <br>
                        <img class="imgAttachment"
                            :src="formData.signature_image"
       
                            resposive
                            alt="Signature image"/>
                    </div>
                </div>
    
    
            </div>
            <div class="html2pdf__page-break"/>
            <div>
                <div class="columns is-7 is-variable" style="width: 60vw;">
                    <div class="column is-two-third" v-if="formData.passport_bio_page_attachment.attachment_url !== ''">
                        <label><b>Passport bio page Image</b></label>
                        <br>
                    <img v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                        class="imgAttachment"
                        :src="formData.passport_bio_page_attachment.attachment_url"
                        resposive
                        alt="Passport bio page Image"/>
                    <img v-else
                    class="imgAttachment"
                    :src="pdfPlaceholder"
                    resposive
                    alt="Passport bio page Image"/>
                    </div>
                    <div class="column is-two-third" v-if="formData.passport_alteration_attachment.attachment_url !==''">
                        <label><b>Passport Alteration page Image</b></label>
                        <br>
                        <img v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                            class="imgAttachment"
                            :src="formData.passport_alteration_attachment.attachment_url"
                            resposive
                            alt="Passport Alteration page Image"/>
                        <img v-else
                        class="imgAttachment"
                        :src="pdfPlaceholder"
                        resposive
                        alt="Passport Alteration page Image"/>
                    </div>
                </div>
                
                    
                <!-- <div class="html2pdf__page-break"/> -->
    
                <div class="columns is-7 is-variable" style="width: 60vw;">
                    <div class="column is-two-third" v-if="formData.passport_entry_stamp_attachment.attachment_url !==''">
                        <label><b>Passport Stamp page Image</b></label>
                        <br>
                        <img v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                            class="imgAttachment"
                            :src="formData.passport_entry_stamp_attachment.attachment_url"
                            resposive
                            alt="Passport Stamp page Image"/>
                        <img v-else
                            class="imgAttachment"
                            :src="pdfPlaceholder"
                            resposive
                            alt="Passport Stamp page Image"/>
                    </div>
                    <div class="column is-two-third" v-if="formData.address_proof_attachment.attachment_url !==''">
                        <label><b>Proof of address image</b></label>
                        <br>
                        <img v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                            class="imgAttachment"
                            :src="formData.address_proof_attachment.attachment_url"
                            resposive
                            alt="Proof of address image"/>
                        <img v-else
                            class="imgAttachment"
                            :src="pdfPlaceholder"
                            resposive
                            alt="Proof of address image"/>
                    </div>
                </div>
            </div>
            <div>
                <div class="columns is-7 is-variable" style="width: 60vw;">
                    <div class="column is-two-third" v-if="formData.visa_attachment.attachment_url !==''">
                        <label><b>Visa image</b></label>
                        <br>
                        <img v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                        class="imgAttachment"
                            :src="formData.visa_attachment.attachment_url"
                            resposive
                            alt="User uploaded Visa image"/>
                        <img v-else
                            class="imgAttachment"
                            :src="pdfPlaceholder"
                            resposive
                            alt="User uploaded Visa image"/>
                    </div>
                    
                </div>
            </div>  
    
                <br>              
                <div style="display: flex; width: 60vw;">
                    <div v-if="detailReviewerAttachments.length !== 0" style="width: 30vw;">
                        <label style="margin-left:2vw">Image Attachments of Detail Reviewer</label>
                        <div v-for="(item, index) in detailReviewerAttachments" :key="index">
                            <div style="margin-top: 20px">
                                <div class="container"  style="width: 25vw; border: 2px solid #000000; border-radius: 2px; padding: 2px;">
                                    <label>Image Attachment {{ index + 1 }}</label>        
                                    <img
                                        :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                                        style="height: 45vh"
                                        resposive
                                        alt="Attachment"
                                    />
                                    <br>
                                    <label>Comment : </label>
                                    <label>{{item.comment}}</label>
                                    <br/>
                                </div>
                            </div> 
                            <div v-if="index === 2">
                                <div class="html2pdf__page-break"/>
                            </div> 
                        </div>   
                        <hr style="width:90vw"
                        :style="{
                            'background-color': '#1b1b1b',
                            height: '1.8px',
                            opacity: '0.5',
                            'margin-inline': '4px',
                        }"
                        />             
                    </div>
                    <div v-if="callAgentAttachments.length !== 0" style="width: 30vw;">
                        <label  style="margin-left:2vw">Image Attachments of Call Agent</label>
                        <div v-for="(item, index) in callAgentAttachments" :key="item.path">
                            <div style="margin-top: 20px">
                                <div class="container" style="width: 25vw; border: 2px solid #000000; border-radius: 2px; padding: 2px;">
                                    <label>Image Attachment {{ index + 1 }}</label>
                                    <img
                                        :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                                        style="height:45vh;"
                                        resposive
                                        alt="Attachment"
                                    />
                                    <br />
                                    <label>Comment : </label>
                                    <label>{{ item.comment }}</label>
                                    <br />
                                </div>
                            </div>
                            <div v-if="index === 2">
                                <div class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div>  
                </div>
                <div>
                    <div v-if="applicationAttachments.length !== 0" style="width: 30vw;">
                        <label  style="margin-left:2vw">Application Attachments</label>
                        <div v-for="(item, index) in applicationAttachments" :key="item.path">
                            <div style="margin-top: 20px">
                                <div class="container" style="width: 25vw; border: 2px solid #000000; border-radius: 2px; padding: 2px;">
                                    <label>Image Attachment {{ index + 1 }}</label>
                                    <img
                                        :src="((item.type === 'pdf')? pdfPlaceholder : item.url)"
                                        style="height:45vh;"
                                        resposive
                                        alt="Attachment"
                                    />
                                    <br />
                                    <label>Comment : </label>
                                    <label>{{ item.comment }}</label>
                                    <br />
                                </div>
                            </div>
                            <div v-if="index === 2">
                                <div class="html2pdf__page-break"/>
                            </div>
                        </div>
                    </div> 
                </div>        
                 <br>    
                <div v-if="isvideocalldone" style="width:70vw">
 
                 <!-- Face Comparisons -->
                    
                        <p class="has-text-danger">Face Comparisons</p>
                        <div class="columns is-7 is-variable" style="width: 60vw;">
                            <div class="column">
                                <label><b>Form Selfie image</b></label>
                                <br>
                            <img class="imgAttachment" v-if="formData.selfie_attachment.attachment_type !== 'pdf'"
                                :src="formData.selfie_attachment.attachment_url"
                                resposive
                                alt="Selfie Image"/>
                            <img v-else
                                class="imgAttachment"
                                :src="pdfPlaceholder"
                                resposive
                                alt="Form Selfie image"/>
                            </div>
                            <div class="column" >
                                <label><b>Video call Selfie image</b></label>
                                <br>
                                <img class="imgAttachment"
                                    :src="videoCallDetail.selfie_image.path"
                                    resposive
                                    alt="Vid call Selfie Image"/>
                                <div class="rating-wrapper">
                                    <label class="label">Satisfactory Level</label>
                                    <b-rate disabled
                                            @click.native="warning()"
                                            v-model="videoCallDetail.selfie_image.rating"
                                            style="margin-left:9vw; margin-top: -4vh ;">
                                    </b-rate>
                                </div>
                            </div>
                        </div>
                     
                     <!-- <hr
                         :style="{
                         'background-color': '#1b1b1b',
                         height: '1.8px',
                         opacity: '0.5',
                         'margin-inline': '4px',
                         }"
                     /> -->
 
                     <!-- <div class="html2pdf__page-break"/> -->
 
                </div>
                    <div v-if="isvideocalldone">
                    <!-- User Uploads and Video Call Data Comparison -->
                        <p class="has-text-danger">User Uploads and Video Call Data Comparison</p>
                        <!-- NIC -->
                        <div class="columns is-7 is-variable" style="width: 60vw;">
                            <div class="column">
                                <label><b>User uploaded NIC front image</b></label>
                                    <br>
                                <img v-if="formData.nic_front_attachment.attachment_type !== 'pdf'"
                                    class="imgAttachment"
                                    :src="formData.nic_front_attachment.attachment_url"
                                    resposive
                                    alt="Form NIC front image"/>
                                <img v-else
                                    class="imgAttachment"
                                    :src="pdfPlaceholder"
                                    resposive
                                    alt="Form NIC front image"/>
                            </div>
                            <div class="column" v-if="isvideocalldone">
                                <label><b>NIC front image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoCallDetail.face_and_nic_front_side_image.path"
                                    resposive
                                    alt="Vid call Selfie Image"/>
                                <div class="rating-wrapper">
                                    <label class="label">Satisfactory Level</label>
                                    <b-rate disabled
                                            @click.native="warning()"
                                            v-model="videoCallDetail.face_and_nic_front_side_image.rating"
                                            style="margin-left:9vw; margin-top: -4vh ;">
                                    </b-rate>
                                </div>

                            </div>
                        </div>
                        <div class="html2pdf__page-break"/>

                        <!-- NIC rear image from video call -->
                        <div class="columns is-7 is-variable" style="width: 60vw;">
                            <div class="column">
                                <label><b>User uploaded NIC rear image</b></label>
                                    <br>
                                <img v-if="formData.nic_back_attachment.attachment_type !== 'pdf'"
                                    class="imgAttachment"
                                    :src="formData.nic_back_attachment.attachment_url"
                                    resposive
                                    alt="User uploaded NIC rear image"/>
                                <img v-else
                                    class="imgAttachment"
                                    :src="pdfPlaceholder"
                                    resposive
                                    alt="User uploaded NIC rear image"/>
                            </div>
                            <div class="column" v-if="isvideocalldone">
                                <label><b>NIC rear image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoCallDetail.face_and_nic_back_side_image.path"
                                    resposive
                                    alt="NIC rear image from video call"/>
                                <div class="rating-wrapper">
                                    <label class="label">Satisfactory Level</label>
                                    <b-rate disabled
                                            @click.native="warning()"
                                            v-model="videoCallDetail.face_and_nic_back_side_image.rating"
                                            style="margin-left:9vw; margin-top: -4vh ;">
                                    </b-rate>
                                </div>
                            </div>
                        </div>

                        <br>
                        <!-- ///////////////////////////////////nic comments ////////////////////////////////////////////////////////////////////////-->
                        <div style="width:fit-content; max-width: 850px;" v-if="isvideocalldone">
                            <div class="columns is-variable is-7" >
                                <div class="column is-three-fifths" style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.nic_comments.comments" :key="comment.comment_content">
                                        
                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>
                                    
                                    <div v-if="comment.additional_comment != ''">
                                        <b-field>
                                        <label>Other : {{ comment.additional_comment }}</label>
                                        </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <hr style="width:90vw"
                                :style="{
                                    'background-color': '#1b1b1b',
                                    height: '1.8px',
                                    opacity: '0.5',
                                    'margin-inline': '4px',
                                }"
                                />
                        </div>

                    <!-- ////////////////nic comment end//////////////////////// -->
                    
                <!-- Passport -->
                    <div v-if="formData.passport_bio_page_attachment.attachment_url !== ''"
                        class="columns is-7 is-variable"
                        style="width: 60vw;"
                    >
                        <div class="column" >
                            <label><b>User uploaded Passport bio page image</b></label>
                                <br>
                            <img v-if="formData.passport_bio_page_attachment.attachment_type !== 'pdf'"
                                class="imgAttachment"
                                :src="formData.passport_bio_page_attachment.attachment_url"
                                resposive
                                alt="User uploaded Passport bio page image"/>
                            <img v-else
                                class="imgAttachment"
                                :src="pdfPlaceholder"
                                resposive
                                alt="User uploaded Passport bio page image"/>
                        </div>
                        
                        <div class="column" v-if="isvideocalldone">
                            <label><b>Passport bio page  image from video call</b></label>
                                <br>
                            <img class="imgAttachment"
                                :src="videoCallDetail.face_and_passport_bio_page_image.path"
                                resposive
                                alt="Passport bio page  image from video call"/>
                            <div class="rating-wrapper">
                                <label class="label">Satisfactory Level</label>
                                <b-rate disabled
                                        @click.native="warning()"
                                        v-model="videoCallDetail.face_and_passport_bio_page_image.rating"
                                        style="margin-left:9vw; margin-top: -4vh ;">
                                </b-rate>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-7 is-variable" style="width: 60vw;" v-if="formData.passport_alteration_attachment.attachment_url !== ''">
                        <div class="column" >
                            <label><b>User uploaded Passport alteration page image</b></label>
                                <br>
                            <img v-if="formData.passport_alteration_attachment.attachment_type !== 'pdf'"
                                class="imgAttachment"
                                :src="formData.passport_alteration_attachment.attachment_url"
                                resposive
                                alt="User uploaded Passport alteration page image"/>
                            <img v-else
                                class="imgAttachment"
                                :src="pdfPlaceholder"
                                resposive
                                alt="User uploaded Passport alteration page image"/>
                        </div>
                        <div class="column" v-if="isvideocalldone">
                            <label><b>Passport alteration page  image from video call</b></label>
                                <br>
                            <img class="imgAttachment"
                                :src="videoCallDetail.face_and_passport_alteration_page_image.path"
                                resposive
                                alt="Passport alteration page  image from video call"/>
                            <div class="rating-wrapper">
                                <label class="label">Satisfactory Level</label>
                                <b-rate disabled
                                        @click.native="warning()"
                                        v-model="videoCallDetail.face_and_passport_alteration_page_image.rating"
                                        style="margin-left:9vw; margin-top: -4vh ;">
                                </b-rate>
                            </div>
                        </div>
                    </div>

                    <div class="columns is-7 is-variable" style="width: 60vw;" v-if="formData.passport_entry_stamp_attachment.attachment_url !== ''">
                        <div class="column" >
                            <label><b>User uploaded Passport entry stamp page image</b></label>
                                <br>
                            <img v-if="formData.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                                class="imgAttachment"
                                :src="formData.passport_entry_stamp_attachment.attachment_url"
                                resposive
                                alt="User uploaded Passport entry stamp page image"/>
                            <img v-else
                                class="imgAttachment"
                                :src="pdfPlaceholder"
                                resposive
                                alt="User uploaded Passport entry stamp page image"/>
                        </div>
                        <div class="column" v-if="isvideocalldone">
                            <label><b>Passport entry stamp page  image from video call</b></label>
                                <br>
                            <img class="imgAttachment"
                                :src="videoCallDetail.face_and_passport_entry_stamp_page_image.path"
                                resposive
                                alt="Passport entry stamp page  image from video call"/>
                            <div class="rating-wrapper">
                                <label class="label">Satisfactory Level</label>
                                <b-rate disabled
                                        @click.native="warning()"
                                        v-model="videoCallDetail.face_and_passport_entry_stamp_page_image.rating"
                                        style="margin-left:9vw; margin-top: -4vh ;">
                                </b-rate>
                            </div>
                        </div>
                    </div>

                    <!-- ////////////////////passport comments start//////////////// -->

                    <!-- <div v-if="videoCallDetail.face_and_passport_alteration_page_image || videoCallDetail.face_and_passport_entry_stamp_page_image"> -->
                    <div v-if="isvideocalldone">
                        <div relative style="width:fit-content; max-width:1100px;" >
                            <div class="columns is-variable is-7" v-if="videoCallDetail.passport_comments">
                                <div class="column is-three-fifths" style="pointer-events: none;">
                                    <div v-for="comment in videoCallDetail.passport_comments.comments" :key="comment.comment_content">
                                        <b-field style="width: fit-content;" >
                                            <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                            {{ comment.comment_content }}
                                            </b-checkbox>
                                        </b-field>
                                        <div v-if="comment.additional_comment != ''">
                                            <b-field>
                                                <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify" v-model="comment.additional_comment"></b-input> -->
                                                <label>Other : {{ comment.additional_comment }}</label>
                                            </b-field>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <hr style="width:90vw"
                        :style="{
                            'background-color': '#1b1b1b',
                            height: '1.8px',
                            opacity: '0.5',
                            'margin-inline': '4px',
                        }"
                        />
                    </div>
                        <!-- ////////////////////passport comments end//////////////// -->
                    <div class="html2pdf__page-break"/>
                    <div >
                        <!-- Visa image -->
                        <div v-if="isvideocalldone">
                            <div  v-if="formData.visa_attachment.attachment_url != '' ">
                                <p class="is-uppercase">Visa image</p>
                                <div class="columns is-7 is-variable" style="width: 60vw;">
                                    <div class="column">
                                        <label><b>User uploaded Visa image</b></label>
                                            <br>
                                        <img v-if="formData.visa_attachment.attachment_type !== 'pdf'"
                                            class="imgAttachment"
                                            :src="formData.visa_attachment.attachment_url"
                                            resposive
                                            alt="User uploaded Visa image"/>
                                        <img v-else
                                            class="imgAttachment"
                                            :src="pdfPlaceholder"
                                            resposive
                                            alt="User uploaded Visa image"/>
                                    </div>
                                    <div class="column" v-if="isvideocalldone">
                                        <label><b>Visa image from video call</b></label>
                                            <br>
                                        <img class="imgAttachment"
                                            :src="videoCallDetail.face_and_visa_image.path"
                                            resposive
                                            alt="Signature image from video call"/>
                                        <div class="rating-wrapper">
                                            <label class="label">Satisfactory Level</label>
                                            <b-rate disabled
                                                    @click.native="warning()"
                                                    v-model="videoCallDetail.face_and_visa_image.rating"
                                                    style="margin-left:9vw; margin-top: -4vh ;">
                                            </b-rate>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width:90vw"
                            :style="{
                                'background-color': '#1b1b1b',
                                height: '1.8px',
                                opacity: '0.5',
                                'margin-inline': '4px',
                            }"
                            />               
                        <div>
                            <!-- address proof document -->
                            <p class="is-uppercase">address proof document</p>
                            <div class="columns is-7 is-variable" style="width: 60vw;">
                                <div class="column">
                                    <label><b>User uploaded address proof document</b></label>
                                        <br>
                                    <img v-if="formData.address_proof_attachment.attachment_type !== 'pdf'"
                                        class="imgAttachment"
                                        :src="formData.address_proof_attachment.attachment_url"
                                        resposive
                                        alt="User uploaded address proof document"/>
                                    <img v-else
                                        class="imgAttachment"
                                        :src="pdfPlaceholder"
                                        resposive
                                        alt="User uploaded address proof document"/>
                                </div>
                                <div class="column" v-if="isvideocalldone">
                                    <label><b>Address proof document from video call</b></label>
                                        <br>
                                    <img class="imgAttachment"
                                        :src="videoCallDetail.address_proof_document_image.path"
                                        resposive
                                        alt="Address proof document from video call"/>

                                    <div class="rating-wrapper">
                                        <label class="label">Satisfactory Level</label>
                                        <b-rate disabled
                                                @click.native="warning()"
                                                v-model="videoCallDetail.address_proof_document_image.rating"
                                                style="margin-left:9vw; margin-top: -4vh ;">
                                        </b-rate>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width:90vw"
                            :style="{
                                'background-color': '#1b1b1b',
                                height: '1.8px',
                                opacity: '0.5',
                                'margin-inline': '4px',
                            }"
                            />
                    </div>

                    <div>
                        <!-- Signature image -->
                        <p class="is-uppercase">Signature image</p>
                        <div class="columns is-7 is-variable" style="width: 60vw;">
                            <div class="column">
                                <label><b>User uploaded Signature image</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="formData.signature_image"
                                    resposive
                                    alt="User uploaded Signature image"/>
                            </div>
                            <div class="column" v-if="isvideocalldone">
                                <label><b>Signature image from video call</b></label>
                                    <br>
                                <img class="imgAttachment"
                                    :src="videoCallDetail.signature_image.path"
                                    resposive
                                    alt="Signature image from video call"/>
                                <div class="rating-wrapper">
                                    <label class="label">Satisfactory Level</label>
                                    <b-rate disabled
                                            @click.native="warning()"
                                            v-model="videoCallDetail.signature_image.rating"
                                            style="margin-left:9vw; margin-top: -4vh ;">
                                    </b-rate>
                                </div>
                            </div>
                        </div>
                    <!-- <hr style="width:90vw"
                        :style="{
                            'background-color': '#1b1b1b',
                            height: '1.8px',
                            opacity: '0.5',
                            'margin-inline': '4px',
                        }"
                        /> -->
                    </div>
                    <div>
                        <!-- checkbox selection for vedio call review page -->
                        <div class="columns is-7 is-variable" v-if="isvideocalldone">
                            <div class="column is-three-fifths" v-if="videoCallDetail.video_comments != null">
                                <p class="mb-2">Video</p>
                                <div style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.video_comments.comments" :key="comment.comment_content">

                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>

                                    <div v-if="comment.additional_comment != ''">
                                        <b-field>
                                        <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify"  v-model="comment.additional_comment"></b-input> -->
                                        <label>Other : {{ comment.additional_comment }}</label>
                                    </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <!-- Audio call verification -->
                    
                        <div class="columns is-7 is-variable" v-if="isvideocalldone">
                            <div class="column is-three-fifths" v-if="videoCallDetail.audio_comments != null">
                                <p class="mb-2">Audio call verification</p>
                                <div style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.audio_comments.comments" :key="comment.comment_content">
                                    
                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>

                                    
                                    <div v-if="comment.additional_comment != ''">
                                        <b-field >
                                        <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify"  v-model="comment.additional_comment"></b-input> -->
                                        <label>Other : {{ comment.additional_comment }}</label>
                                        </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <br/>

                        <!-- if DRP Verification failed -->

                        <div class="columns is-7 is-variable" v-if="isvideocalldone">
                            <div class="column is-three-fifths" v-if="videoCallDetail.drp_comments != null">
                                <p class="mb-2">if DRP Verification failed</p>


                                <div style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.drp_comments.comments" :key="comment.comment_content">
                                
                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>

                                    <div v-if="comment.additional_comment != ''">
                                        <b-field >
                                        <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify" v-model="comment.additional_comment"></b-input> -->
                                        <label>Other : {{ comment.additional_comment }}</label>
                                        </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="columns is-7 is-variable" v-if="isvideocalldone">
                            <div class="column is-three-fifths" v-if="videoCallDetail.customer_behavior_comments != null">
                                <p class="mb-2">Customer Behaviour</p>

                                <div style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.customer_behavior_comments.comments" :key="comment.comment_content">
                                    
                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>

                                    
                                    <div v-if="comment.additional_comment != ''">
                                        <b-field >
                                        <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify"  v-model="comment.additional_comment"></b-input> -->
                                        <label>Other : {{ comment.additional_comment }}</label>
                                        </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <!-- Location verification -->
                        <div class="columns is-7 is-variable" v-if="isvideocalldone">
                            <div class="column is-three-fifths" v-if="videoCallDetail.location_verification_comments != null">
                                <p class="mb-2">Location verification</p>
                                <div style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.location_verification_comments.comments" :key="comment.comment_content">
                                    
                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>

                                    
                                    <div v-if="comment.additional_comment != ''">
                                        <b-field >
                                        <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify"  v-model="comment.additional_comment"></b-input> -->
                                        <label>Other : {{ comment.additional_comment }}</label>
                                        </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <!-- location verifier -->
                        
                        <div class="columns is-7 is-variable" v-if="isvideocalldone">
                            <div class="column is-three-fifths" v-if="videoCallDetail.location_verifier_comments != null">
                                <p class="mb-2">Location verifier</p>
                                <div style="pointer-events: none;">
                                <div v-for="comment in videoCallDetail.location_verifier_comments.comments" :key="comment.comment_content">
                                    
                                    <b-field style="width: fit-content;" >
                                        <b-checkbox type="is-primary" v-model="comment.is_comment_selected" @click.native="warning()">
                                        {{ comment.comment_content }}
                                        </b-checkbox>
                                    </b-field>

                                
                                    <div v-if="comment.additional_comment != ''">
                                        <b-field >
                                        <!-- <b-input @click.native="warning()" maxlength="200" rounded placeholder="Specify"  v-model="comment.additional_comment"></b-input> -->
                                        <label>Other : {{ comment.additional_comment }}</label>
                                        </b-field>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    <div class="columns is-7 is-variable">
                        <div class="column ">
                            <p class="mb-2">Customer Spoken Language</p>
                            <div v-if="formData.spoken_language">
                            <AgentInputField
                                label="Customer Spoken Language"
                                :value="formData.spoken_language"
                                readonly="true"
                                class="non-interactable-input"
                            />
                            </div>
                        </div>
                    </div>
                </div>
     </section>
     </vue-html2pdf> 
     </div>
 </template>
 <script>
 import {useAuthStore} from "../../store/authStore"; 
 import VueHtml2pdf from 'vue-html2pdf' 
 import ImageBox from "../../components/ImageBox.vue";
 import NetworkManager from "../../network";
 import {GlobalFunctions} from "../../helpers/globalFunctions";
 import {GeoPositionCoordinates} from "../../helpers/classes/geoPositionCoordinates";
 import ShowMapLocation from "../../components//ShowMapLocation.vue";
 import {DateTimeManger} from "../../helpers/dateTimeManger";
 import InputLabel from "../../components/InputLabel.vue";
 import AgentInputField from "../../components/AgentInputField.vue";
 import DisplayBulletList from "../../components/DisplayBulletList.vue";
 import FullNameView from "../../components/FullNameView.vue";
 
   export default {
     name: "ApplicationDetailReview",
     components: {
     ImageBox,
     VueHtml2pdf,
     ShowMapLocation,
     InputLabel,
     AgentInputField,
     DisplayBulletList,
     FullNameView,
   },
     computed: {
       GlobalFunctions() {
         return GlobalFunctions
       },
     },
    data() {
        return{
            detailReviewerAttachments: "",
            callAgentAttachments :"",
            applicationAttachments : "",
            isLoading:true,
            isvideocalldone :false,
            formData:[],
            products:[],
            videoCallDetail:[],
            branch:[],
            customer_branch: null,
            agent_branch: [],
            contentRendered: false,
            progress: 0,
            generatingPdf: false,
            pdfDownloaded: false,
            pdfPlaceholder : require("@/assets/Images/pdf.png"),
            showCustomerLocation : false,
            showVideoCallCustomerLocation : false,

            kycFormCustomerLocation: new GeoPositionCoordinates(),
            videoKycCustomerLocation: new GeoPositionCoordinates(),
        }
    },
     setup(){
     const authStore = useAuthStore()
     return {authStore}
   },
     methods :{
         goback(){
             this.$router.go(-1);
         },
 
         disableResize() {
             window.addEventListener('resize', this.handleResize);
             this.handleResize();
         },
         handleResize() {
             const desiredWidth = 1920; // Desired width in pixels
             const desiredHeight = 1080; // Desired height in pixels
             window.resizeTo(desiredWidth, desiredHeight);
         },
 
         generatePDF() {
             // Get the HTML content of the component
             console.log("Generating PDF")
             this.$refs.html2Pdf.generatePdf() 
         },
         onProgress(progress) {
             this.progress = progress;
             console.log(`PDF generation progress: ${progress}%`)
         },
         startPagination() {
             console.log(`PDF has started pagination`)
         },
         hasPaginated () {
             console.log(`PDF has been paginated`)
         },
         async beforeDownload ({ html2pdf, options, pdfContent }) {
         console.log(`On Before PDF Generation`)
         // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                 // 	const totalPages = pdf.internal.getNumberOfPages()
                 // 	for (let i = 1; i <= totalPages; i++) {
                 // 		pdf.setPage(i)
                 // 		pdf.setFontSize(10)
                 // 		pdf.setTextColor(150)
                 // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                 // 	} 
                 // }).save()
         },
        //  hasDownloaded (blobPdf) {
        //      console.log(`PDF has downloaded`)
        //      this.pdfDownloaded = true
        //      console.log(blobPdf)
        //      this.goback();
        //      window.close();
 
        //  },
 
 
         getFormattedDateTime(date){
             return DateTimeManger.formatCustomerInputDates(date)
         },
         getAttachmentInfo:function(){
            const comp = this;
            const data = { kyc_form_id: comp.authStore.kyc_form_id};


            NetworkManager.apiRequest(
            "api/KycApplication/getAgentUploadAttachmentById",
            data,
            function (e) {
                if (e.statusCode === 200) {
                    console.log("getAgentUploadAttachmentById data ===============", e.data);
                    comp.detailReviewerAttachments = e.data.hold_application_attachments.detailReviewerAttachments;
                    comp.callAgentAttachments = e.data.hold_application_attachments.callAgentHoldAttachments;
                    comp.applicationAttachments = e.data.additional_attachments.attachment_list;
                } else{
                    console.error("FAILED GETTING ATTACHMENTS")
                }
            }
            );  
        },
         GetApplicationData: function (){
             const comp= this
             const data = { 
                 id: comp.authStore.kyc_form_id
             }
             //console.log("ID---------------------",comp.authStore.kyc_form_id)
             //console.log("in getapplication")
 
             NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
                 console.log("in getapplicationlll",e.data)
                 if(e.statusCode === 200){
                 comp.formData = e.data.kycApplication
                 comp.isvideocalldone = e.data.isKycApplicationVideoCallComplete
                 console.log("formData RECEIVED", comp.formData)
                 comp.products = e.data.productSelection
                 console.log("products ", comp.products)
                 comp.videoCallDetail=e.data.videoCallDetail
                 comp.branch=e.data.customerBranch
                 comp.customer_branch = e.data.customerBranch;
                 comp.agent_branch = e.data.agentBranch;
 
                 comp.markerPosition = e.data.kycApplication.customer_location;
                 const splitted = comp.markerPosition.split(',')
                 const latitude = splitted[1]
                 const again1 = latitude.split(':')
                 const longitude = splitted[2]
                 const again2 = longitude.split(':')
                 const newvar = {latitude, longitude}
                 const lattt = parseFloat(again1[1])
                 const lngggg = parseFloat(again2[1])
                 comp.markerPosition = {"lat": lattt, "lng": lngggg};       
                 //console.log("kkkkk",comp.formData.are_you_a_tax_payer.customer_input)
 
                 let customerLocation = JSON.parse(e.data.kycApplication.customer_location)
                 //console.log("customer location")
                 //console.log(customerLocation)
                 comp.kycFormCustomerLocation = new GeoPositionCoordinates(customerLocation.latitude,customerLocation.longitude)
                 comp.showCustomerLocation = true;
 
                 // get video call location if available
                 if(comp.videoCallDetail !== null){
                     let videoCallCustomerLocation = JSON.parse(comp.videoCallDetail.video_call_location)
                     //console.log("video call location")
                     //console.log(videoCallCustomerLocation)
                     comp.videoKycCustomerLocation = new GeoPositionCoordinates(videoCallCustomerLocation.latitude,videoCallCustomerLocation.longitude)
                     comp.showVideoCallCustomerLocation = true;
                 }
                 
 
                 // comp.products.forEach(product=>{
 
                 //     product.perpose_of_account_opening_customer=''
                 //     product.perpose_of_account_opening_agent=''
 
                 //     product.purpose_of_opening_account.customer_input.forEach(purpose=>{
                 //       if(product.perpose_of_account_opening_customer === ''){
                 //           product.perpose_of_account_opening_customer = purpose.purpose
                 //       }else{
                 //           product.perpose_of_account_opening_customer += ", "+purpose.purpose
                 //       }
                 //     })
 
                 //     if(product.purpose_of_opening_account.call_agent_input !== null){
                 //       product.purpose_of_opening_account.call_agent_input.forEach(purpose=>{
                 //         if(product.perpose_of_account_opening_agent === ''){
                 //           product.perpose_of_account_opening_agent = purpose.purpose
                 //         }else{
                 //           product.perpose_of_account_opening_agent += ", "+purpose.purpose
                 //         }
                 //       })
                 //     }
                 // }) 
                 
                 }       
             })
         },
         
     },
     mounted(){
         this.disableResize();
         this.GetApplicationData()
         this.getAttachmentInfo()
         setTimeout(() => {
             this.generatePDF();
         }, 3000);   
         // setTimeout(() => {
         //     this.goback();
         // }, 5000);    
     }
 };
 </script>
 <style scoped>
 .text{
     font-family: 'Courier New', Courier, monospace
 }
 .Cutsomertext{
     font-family: 'Courier New', Courier, monospace;
 }
 .Agenttext{
     font-family: 'Courier New', Courier, monospace;
     color:red;
 }
 .rating-wrapper label {
   font-size: medium;
 }
 label{
     font-weight: 700;
     font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
     
 }
 .imgAttachment{
     border: 2px solid #1b1b1b;
     padding: 1px;
 }
 .page-container {
    width: 70vw; /* Desired width */

 }
 html {
   /* Disable resizing of the page */
   resize: none;
 }
 body {
   overflow: hidden;
 }
 </style>
   