<template>
  <b-modal
    v-model="displayModal"
    :can-cancel="false"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal>

    <div class="modal-card" style="width: auto">
      <section class="modal-card-body custom-section" >
        <div class="header-section">
          <p class="modal-card-title" id="header-text" style="color:#04c3fc">End Video Call</p>
          <button
              type="button"
              class="delete"
              id="close-icon-btn"
              @click="emitModalClose()"/>
        </div>

        <p v-if="!confirmExit" id="body-text">Are you sure, do you want to end this call?</p>
        <p v-if="confirmExit" id="body-text">Are you sure, do you want to end this call without saving data?</p>

        <div class="button-section" v-if="!endCallSelected && !confirmExit">
          <b-button class="custom-button" type="is-primary" rounded outlined @click="emitModalClose()" >Cancel</b-button>
          <b-button class="custom-button" type="is-primary" rounded @click="handleEndCall" >End</b-button>
        </div>

        <div class="button-section" v-if="endCallSelected && !confirmExit">
          <b-button class="custom-additional-button" type="is-primary" rounded outlined @click="emitModalClose()">Cancel</b-button>
          <b-button class="custom-additional-button" type="is-primary" rounded outlined @click="emitEndAndSaveData()">Save Data and End</b-button>
          <b-button class="custom-additional-button" type="is-primary" rounded outlined @click="openExitConfirm()" >End</b-button>
        </div>

        <div class="button-section" v-if="confirmExit">
          <b-button class="custom-button" type="is-primary" rounded outlined @click="emitModalClose()" >Cancel</b-button>
          <b-button class="custom-button" type="is-primary" rounded @click="emitEndWithoutSavingData()" >End</b-button>
        </div>

      </section>
    </div>
</b-modal>
</template>

<script>
import {GlobalEventManager} from "../helpers/globalEventManager";
import {GlobalEvents} from "../helpers/globalEvents";
import {useVideoKycStore} from "../store/videoKycStore";
import {BuefyHelper} from "../helpers/bufeyHelper";

export default {
  name: "EndAgentVideoCallModal",
  props:{
    displayModal: Boolean,
  },

  setup (){
    const videoKycStore = useVideoKycStore()
    return {videoKycStore}
  },

  data(){
    return {
      endCallSelected:false,
      confirmExit :false,

      buttonStatus:{
        disabled:{
          cancelBtn : false,
          endCallBtn : false
        },
        loading : {
          cancelBtn : false,
          endCallBtn : false,
        },

      }
    }
  },

  methods:{
    openExitConfirm(){
      const comp=this
      comp.confirmExit = true
    },
    handleEndCall(){
      const comp = this
      comp.endCallSelected = true
    },

    resetEndCallSelection(){
      const comp = this
      comp.endCallSelected = false
      comp.confirmExitModel = true
    },

    emitModalClose(){
      const comp = this
      comp.resetEndCallSelection()
      GlobalEventManager.$emit(GlobalEvents.agentSide.endCallModal.closeModal)
    },

    emitEndAndSaveData(){
      const comp = this
      const validationResult = comp.validateRequiredData()
      comp.resetEndCallSelection()

      console.log("end video call and submit data : validation result")
      console.log(validationResult)

      if(validationResult.isValid){
        GlobalEventManager.$emit(GlobalEvents.agentSide.endCallModal.endAndSaveData)
      }
      else{
        BuefyHelper.showToastMessage(validationResult.message,"is-danger")
        comp.emitModalClose()
      }

    },

    emitEndWithoutSavingData(){
      const comp = this
      comp.resetEndCallSelection()
      GlobalEventManager.$emit(GlobalEvents.agentSide.endCallModal.endWithOutSavingData)
    },


    /***
     * =================================================================================================================
     * This function can be used to validate the required data before uploading the data
     * @returns {{isValid: boolean, message: (string)}}
     */
    validateRequiredData(){
      const comp = this
      let isValid = true
      let message = ""

      /*
      * submit function calling procedure
      * ----------------------------------------------------------------------------------------------------------------
      * 01. If kyc form fields changed by agent, -> submit kyc form
      * 02. Upload video call video
      * 03. Upload video call data (questions, images)
      * */

      // check changed kyc form is submitted already.
      if(comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallEkycFormUpdatedByAgent && !comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallKycFormSubmitted) {
        message = "Submit the EKYC form before uploading the data"
        isValid = false
      }
      else if(comp.videoKycStore.answeredVideoKycData.dateOfAccountFunding === null){
        message = "Select date of account funding"
        isValid = false
      }

      else{

          const imageUploadDetails = comp.videoKycStore.answeredVideoKycData.imageUploadDetails
          if(imageUploadDetails.face_and_nic_front_side_image.required){
            if(imageUploadDetails.face_and_nic_front_side_image.file === null) {
              isValid = false
            }
          }

          if(imageUploadDetails.face_and_nic_back_side_image.required){
            if(imageUploadDetails.face_and_nic_back_side_image.file === null) {
              isValid = false
            }
          }

          if(imageUploadDetails.face_and_passport_alteration_page_image.required){
            if(imageUploadDetails.face_and_passport_alteration_page_image.file === null) {
              isValid = false
            }
          }

          if(imageUploadDetails.face_and_passport_bio_page_image.required){
            if(imageUploadDetails.face_and_passport_bio_page_image.file === null) {
              isValid = false
            }
          }

          if(imageUploadDetails.face_and_passport_entry_stamp_page_image.required){
            if(imageUploadDetails.face_and_passport_entry_stamp_page_image.file === null) {
              isValid = false
            }
          }

          if(imageUploadDetails.face_and_visa_image.required){
            if(imageUploadDetails.face_and_visa_image.file === null) {
              isValid = false
            }
          }

          if(imageUploadDetails.selfie_image.required){
            if(imageUploadDetails.selfie_image.file === null) {
              isValid = false
            }
          }

                  if(imageUploadDetails.signature_image.required){
                    if(imageUploadDetails.signature_image.file === null) {
                      isValid = false
                    }
                  }

        if(imageUploadDetails.address_proof_document_image.required){
          if(imageUploadDetails.address_proof_document_image.file === null) {
            isValid = false
          }
        }

        message =  !isValid ? "Capture all required images before uploading the data" : ""
        }

      // else{

      //   const imageUploadDetails = comp.videoKycStore.answeredVideoKycData.imageUploadDetails
      //   if(imageUploadDetails.face_and_nic_front_side_image.required){
      //     if(imageUploadDetails.face_and_nic_front_side_image.file === null || imageUploadDetails.face_and_nic_front_side_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.face_and_nic_back_side_image.required){
      //     if(imageUploadDetails.face_and_nic_back_side_image.file === null || imageUploadDetails.face_and_nic_back_side_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.face_and_passport_alteration_page_image.required){
      //     if(imageUploadDetails.face_and_passport_alteration_page_image.file === null || imageUploadDetails.face_and_passport_alteration_page_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.face_and_passport_bio_page_image.required){
      //     if(imageUploadDetails.face_and_passport_bio_page_image.file === null || imageUploadDetails.face_and_passport_bio_page_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.face_and_passport_entry_stamp_page_image.required){
      //     if(imageUploadDetails.face_and_passport_entry_stamp_page_image.file === null || imageUploadDetails.face_and_passport_entry_stamp_page_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.face_and_visa_image.required){
      //     if(imageUploadDetails.face_and_visa_image.file === null || imageUploadDetails.face_and_visa_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.selfie_image.required){
      //     if(imageUploadDetails.selfie_image.file === null || imageUploadDetails.selfie_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.signature_image.required){
      //     if(imageUploadDetails.signature_image.file === null || imageUploadDetails.signature_image.rating === 0) {
      //       isValid = false
      //     }
      //   }

      //   if(imageUploadDetails.address_proof_document_image.required){
      //     if(imageUploadDetails.address_proof_document_image.file === null || imageUploadDetails.address_proof_document_image.rating === 0) {
      //       isValid = false
      //     }
      //   }
        
      //   message =  !isValid ? "Capture all required images and rate them before uploading the data" : ""
      // }
      
      if(!(this.videoKycStore.answeredVideoKycData.questionDetails.comments.video_comments.some(comment => comment.is_comment_selected) &&
        this.videoKycStore.answeredVideoKycData.questionDetails.comments.audio_comments.some(comment => comment.is_comment_selected) &&
        this.videoKycStore.answeredVideoKycData.questionDetails.comments.location_verifier_comments.some(comment => comment.is_comment_selected) &&
        this.videoKycStore.answeredVideoKycData.questionDetails.comments.customer_behavior_comments.some(comment => comment.is_comment_selected) &&
        this.videoKycStore.answeredVideoKycData.questionDetails.comments.location_verification_comments.some(comment => comment.is_comment_selected) &&
        this.videoKycStore.answeredVideoKycData.questionDetails.comments.nic_comments.some(comment => comment.is_comment_selected) &&
        (this.videoKycStore.answeredVideoKycData.questionDetails.comments.passport_comments === null ||
          this.videoKycStore.answeredVideoKycData.questionDetails.comments.passport_comments.some(comment => comment.is_comment_selected)
          )))
      {
        isValid = false;
        message ="You must Select atleast one from each checkbox section"
      }
      else if(this.videoKycStore.answeredVideoKycData.kycForm.spoken_language == "")
      {
         message =  !isValid ? "Select the Client Spoken Language before uploading the data" : ""
      }


      if(this.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList != null){
        this.videoKycStore.answeredVideoKycData.videoCallVerificationQuestionList.forEach(
                    function (item){
                      if(item.status == null){
                        isValid = false;
                        message ="You must Check All the Question Verifications";
                      }else{
                         if(item.status == "is-danger" && item.correct_answer == null){

                          isValid = false;
                          message ="You must Fill all the answers in Verification Tab";
                           
                         }
                      }

                    });
      }

      return {
        isValid,
        message
      }

    },
  },

}
</script>

<style scoped>
.header-section{
  display: flex;
  justify-content: center;
  padding:10px;
  border-bottom: 1px solid lightgray;
  text-transform: uppercase;
  font-size: small;
  font-family: Gotham-Medium;
}

.header-section p {
  color: #ED1C24;
}

.button-section{
  display: flex;
  justify-content: space-around;
}

#body-text{
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: Gotham-Medium;
  font-size: smaller;
}

#close-icon-btn{
  margin-top: 15px;
  margin-right: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

#header-text{
  max-width: max-content;
}

.custom-section{
  border-radius: 25px;
  min-width: 420px !important;
  text-align: center;
}

.custom-button{
  min-width: 120px;
}

.custom-additional-button{
  min-width: 80px;
}


.end-call-additional-options{
  margin-top: 25px;
}
</style>