<template>
  <div>
    <ValidationObserver>
      <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css"> -->
      <br>
      <div class="card"  style="margin-left: auto;  margin-right: auto; width:fit-content;  border-radius: 25px;  padding: 20px;">
        <b-tabs type="is-toggle-rounded" position="is-centered">
          <b-tab-item label="NIC">

            <!--=======================Face & NIC front side=============================-->
            <div style="text-align:center;" v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_nic_front_side_image">
              <ValidationProvider
                name="NIC Front image"
                :rules="{required:true}"
                v-slot="{errors}">

                <label class="red-title" for="Face&NICFront" style="margin-left: auto; margin-right: auto;">Face & NIC front side</label>
                <br>

                <div class="image-wrapper">
                  <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.srcObject"  alt="Face & NIC front side"  @error="imageplaceholder"/>
                </div>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <div  style="width: fit-content; margin-left: auto; margin-right: auto !important;">
                <!--=======================Satisfactory Level=============================-->

                <ValidationProvider
                    name="NIC Front Ratings"
                    :rules="{required:true}"
                    v-slot="{errors}">

                  <div class="rating-wrapper">
                    <label class="label">Satisfactory Level</label>
                    <b-rate
                        :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.file === null"
                        @click.native="warning()"
                        v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.rating"
                        style="margin-left:150px;">
                    </b-rate>
                  </div>

                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

              </div>
              <div>
                <b-button type="is-primary" @click="requestImageCapture('face_and_nic_front_side_image')" rounded style="max-width: 650px; width: 100%;">
                  <i class="mdi mdi-camera"></i> Tap to Capture
                </b-button>
              </div>
              <!--================== line break for next segment ==================-->
              <hr width="50%" style="margin-left: auto; margin-right: auto;">
            </div>

            <!--=======================Face & NIC rear side=============================-->
            <div style="text-align:center ;" v-if= "videoKycStore.answeredVideoKycData.questionDetails.images.face_and_nic_back_side_image">
              <ValidationProvider
                name="NIC Back image"
                :rules="{required:true}"
                v-slot="{errors}">
                <label class="red-title" for="Face&NICRear" style="margin-left: auto; margin-right: auto;">Face & NIC Rear side</label>

                <div class="image-wrapper">
                  <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.srcObject"  alt="Face & NIC rear side" @error="imageplaceholder" />
                </div>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div  style="width: fit-content; margin-left: auto; margin-right: auto !important;">
                <!--=======================Satisfactory Level=============================-->

                <ValidationProvider
                    name="NIC Back Ratings"
                    :rules="{required:true}"
                    v-slot="{errors}">

                  <div class="rating-wrapper" >
                      <label class="label">Satisfactory Level</label>
                      <b-rate
                          :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.file === null"
                          @click.native="warning()"
                          v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.rating"
                          style="margin-left:150px;"
                      ></b-rate>
                    </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

              </div>
              <div>
                <b-button type="is-primary" @click="requestImageCapture('face_and_nic_back_side_image')" rounded style="max-width: 650px; width: 100%;">
                  <i class="mdi mdi-camera"></i> Tap to Capture
                </b-button>
              </div>
              <!--================== line break for next segment ==================-->
              <hr width="50%" style="margin-left: auto; margin-right: auto;">
            </div>

            <!--=========================== checkbox area ============================-->
            <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_nic_front_side_image || videoKycStore.answeredVideoKycData.questionDetails.images.face_and_nic_back_side_image">
              <div style="width:fit-content; max-width: 850px; margin-right:auto; margin-left:auto;">
                <div class="columns " >
                  <div class="column ">

                    <ValidationProvider
                        name="NIC image checkbox"
                        :rules="{required:false}"
                        v-slot="{errors}">

                      <div class="container" v-for="(nicComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.nic_comments" :key="index">

                          <b-field style="margin-bottom: 20px;">
                            <b-checkbox type="is-primary" v-model="nicComment.is_comment_selected" @click.native="warning()">
                              {{ nicComment.comment_content }}
                            </b-checkbox>
                          </b-field>

                          <!-- additional comments   -->
                          <b-field style="text-align: left;" v-if="nicComment.comment_content ===  'Other' && nicComment.is_comment_selected ">
                            <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify" v-model="nicComment.additional_comment"></b-input>
                          </b-field>

                          <span class="has-text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="PASSPORT" v-if="showPassportTab">

            <!--======================= IF PASSPORT SELECTED =============================-->
            <div style="text-align:center;">
              <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_bio_page_image">
                <ValidationProvider
                  name="Passport Bio Page image"
                  :rules="{required:true}"
                  v-slot="{errors}">
                  <label class="red-title" for="Face&Passportbio"  style="margin-left: auto; margin-right: auto;"> Face & Passport bio page </label>
                  <div class="image-wrapper" >
                    <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.srcObject"  alt="Face & Passport bio page" @error="imageplaceholder" />
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div style="width: fit-content; margin-left: auto; margin-right: auto !important;">

                  <ValidationProvider
                      name="Passport Bio Page Ratings"
                      :rules="{required:true}"
                      v-slot="{errors}">

                    <div class="rating-wrapper">
                      <label class="label">Satisfactory Level</label>
                      <b-rate
                          :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.file === null"
                          @click.native="warning()"
                          v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.rating"
                          style="margin-left:150px;"
                      ></b-rate>
                    </div>

                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div>
                  <b-button type="is-primary" @click="requestImageCapture('face_and_passport_bio_page_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i> Tap to Capture</b-button>
                </div>
                <hr width="50%" style="margin-left: auto; margin-right: auto;">
              </div>
                <!--line break for Face and NIC Rear photo-->


              <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_alteration_page_image">
                <ValidationProvider
                  name="Passport Alteration Page image"
                  :rules="{required:true}"
                  v-slot="{errors}">
                  <label class="red-title" for="Face&Passportalterationpage">Face & Passport alteration page</label>
                  <div class="image-wrapper" >
                    <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.srcObject"  alt="Face & Passport alteration page" @error="imageplaceholder" />
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="raw" style="width: fit-content; margin-left: auto; margin-right: auto !important;">

                  <ValidationProvider
                    name="Passport Alteration Page Ratings"
                    :rules="{required:true}"
                    v-slot="{errors}">

                    <div class="rating-wrapper">
                      <label class="label">Satisfactory Level</label>
                      <b-rate
                          :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.file === null"
                          @click.native="warning()"
                          v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.rating"
                          style="margin-left:150px;"
                      ></b-rate>
                    </div>

                    <span class="has-text-danger">{{ errors[0] }}</span>

                  </ValidationProvider>
                </div>
                <div>
                  <b-button type="is-primary" @click="requestImageCapture('face_and_passport_alteration_page_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i>Tap to Capture</b-button>
                </div>
                <hr width="50%" style="margin-left: auto; margin-right: auto;">
              </div>

              <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_entry_stamp_page_image">
                <ValidationProvider
                  name="Passport Entry Stamp Page image"
                  :rules="{required:true}"
                  v-slot="{errors}">
                  <label class="red-title" for="Face&Passportstamppage">Face & Passport entry stamp page</label>
                  <div class="image-wrapper">
                    <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.srcObject" alt="Face & Passport entry stamp page" @error="imageplaceholder" />
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>

                <div style="width: fit-content; margin-left: auto; margin-right: auto !important;">

                  <ValidationProvider
                    name="Passport Entry Stamp Page Ratings"
                    :rules="{required:true}"
                    v-slot="{errors}">
                    <div class="rating-wrapper">
                      <label class="label">Satisfactory Level</label>
                      <b-rate
                          :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.file === null"
                          v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.rating"
                          style="margin-left:150px;"
                          @click.native="warning()"
                      ></b-rate>
                    </div>

                    <span class="has-text-danger">{{ errors[0] }}</span>

                  </ValidationProvider>
                  <br>
                </div>
                <div>
                  <b-button type="is-primary" @click="requestImageCapture('face_and_passport_entry_stamp_page_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i> Tap to Capture</b-button>
                </div>
              </div>

             

            </div>
            <!--=========================== checkbox area ============================-->
            <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_alteration_page_image || videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_bio_page_image || videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_entry_stamp_page_image">
              <div v-if="videoKycStore.answeredVideoKycData.questionDetails.comments.passport_comments != null">
                <hr width="50%" style="margin-left: auto; margin-right: auto;">
                <div style="width:fit-content; max-width: 850px; margin-right:auto; margin-left:auto;">
                  <div class="columns">
                    <div class="column">
                      <ValidationProvider
                          name="Passport image checkbox"
                          :rules="{required:false}"
                          v-slot="{errors}">

                        <div class="container" v-for="(passportComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.passport_comments" :key="index">
                          <b-field>
                            <b-checkbox type="is-primary" v-model="passportComment.is_comment_selected" @click.native="warning()">
                              {{ passportComment.comment_content }}
                            </b-checkbox>
                          </b-field>

                          <!-- Additional Comment -->
                          <b-field  v-if="passportComment.comment_content === 'Other' && passportComment.is_comment_selected ">
                            <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify" v-model="passportComment.additional_comment"></b-input>
                          </b-field>

                          <span class="has-text-danger">{{ errors[0] }}</span>

                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
        <br>
        <!-- visa image -->
        <div style="text-align:center ;" v-if= "videoKycStore.answeredVideoKycData.questionDetails.images.face_and_visa_image">
          <ValidationProvider
            name="Visa image"
            :rules="{required:true}"
            v-slot="{errors}">
            <label class="red-title" for="Face&Visa" style="margin-left: auto; margin-right: auto;">Visa image</label>

            <div class="image-wrapper">
              <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.srcObject"  alt="Face & Visa image" @error="imageplaceholder" />
            </div>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <div  style="width: fit-content; margin-left: auto; margin-right: auto !important;">
            <!--=======================Satisfactory Level=============================-->

            <ValidationProvider
                name="Visa image ratings"
                :rules="{required:true}"
                v-slot="{errors}">

              <div class="rating-wrapper" >
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                      :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.file === null"
                      @click.native="warning()"
                      v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.rating"
                      style="margin-left:150px;"
                  ></b-rate>
                </div>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>

          </div>
          <div>
            <b-button type="is-primary" @click="requestImageCapture('face_and_visa_image')" rounded style="max-width: 650px; width: 100%;">
              <i class="mdi mdi-camera"></i> Tap to Capture
            </b-button>
          </div>
          <!--================== line break for next segment ==================-->
          <hr width="50%" style="margin-left: auto; margin-right: auto;">
        </div>
        <!-- <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.face_and_visa_image">
          <div class="card custom-card">
            <br>
            <label class="red-title" for="Face&VisaImage">Visa image</label>

            <div  class="image-wrapper">
              <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.srcObject" alt="Face & Visa image" @error="imageplaceholder">
            </div>
            <div style="width: fit-content; margin-left: auto; margin-right: auto !important;">
              <div class="rating-wrapper">

                <label class="label">Satisfactory Level</label>
                <b-rate
                    :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.file === null"
                    v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.rating"
                    style="margin-left:150px;"
                    @click.native="warning()"
                ></b-rate>

              </div>

            </div>
            <div>
              <b-button type="is-primary" @click="requestImageCapture('face_and_visa_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i> Tap to Capture</b-button>
            </div>
          </div>
        </div> -->
        <!-- SELFI IMAGE-->
        <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.selfie_image">
          <div class="card custom-card">
            <br>
            <label class="red-title" for="Selfieimage">Selfie image</label>

            <div  class="image-wrapper">
              <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.srcObject" alt="Selfie image" @error="imageplaceholder">
            </div>
            <div style="width: fit-content; margin-left: auto; margin-right: auto !important;">
              <div class="rating-wrapper">

                <label class="label">Satisfactory Level</label>
                <b-rate
                    :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.file === null"
                    v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.rating"
                    style="margin-left:150px;"
                    @click.native="warning()"
                ></b-rate>

              </div>

            </div>
            <div>
              <b-button type="is-primary" @click="requestImageCapture('selfie_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i> Tap to Capture</b-button>
            </div>
          </div>
        </div>
        <br>
    
        <!-- Customer Signature IMAGE-->
        <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.signature_image">
          <div class="card custom-card">
            <br>
            <ValidationProvider
              name="Customer Signature Image"
              :rules="{required:true}"
              v-slot="{errors}">
              <label class="red-title" for="CustomerSignature">Customer Signature</label>

              <div  class="image-wrapper">
                <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.srcObject"  alt="Customer Signature" @error="imageplaceholder" >
              </div>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <div style="width: fit-content; margin-left: auto; margin-right: auto !important;">

            <ValidationProvider
              name="Customer Signature Ratings"
              :rules="{required:true}"
              v-slot="{errors}">
              <div class="rating-wrapper">
                <label class="label" >Satisfactory Level</label>
                <b-rate
                    :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.file === null"
                    v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.rating"
                    style="margin-left:150px;"
                    @click.native="warning()"
                ></b-rate>
              </div>

              <span class="has-text-danger">{{ errors[0] }}</span>

            </ValidationProvider>

            </div>
            <div>
              <b-button type="is-primary" @click="requestImageCapture('signature_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i> Tap to Capture</b-button>
            </div>
          </div>
        </div>
        <br>

        <!-- Address Proof document -->
        <div v-if="videoKycStore.answeredVideoKycData.questionDetails.images.address_proof_document_image">
          <div class="card custom-card">
            <br>
            <ValidationProvider
              name="Address Proof Image"
              :rules="{required:true}"
              v-slot="{errors}">
              <label class="red-title" for="AddressProofdocument">Address Proof document</label>

              <div class="image-wrapper">
                <img class="image" :src="videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.srcObject"  alt="Address Proof document"  @error="imageplaceholder">
              </div>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <div style="width: fit-content; margin-left: auto; margin-right: auto !important;">

            <ValidationProvider
                name="Address Proof Ratings"
                :rules="{required:true}"
                v-slot="{errors}">

              <div class="rating-wrapper">
                  <label class="label">Satisfactory Level</label>
                  <b-rate
                      :disabled="videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.file === null"
                      v-model="videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.rating"
                      style="margin-left:150px;"
                      @click.native="warning()"
                  ></b-rate>
                </div>

                <span class="has-text-danger">{{ errors[0] }}</span>

              </ValidationProvider>

            </div>
            <b-button type="is-primary" @click="requestImageCapture('address_proof_document_image')" rounded style="max-width: 650px; width: 100%;"> <i class="mdi mdi-camera"></i> Tap to Capture</b-button>
          </div>
        </div>
        <br>
    
        <!--VIDEO-->
        <div class="card custom-card">
          <br>
          <label class="red-title" for="Video">VIDEO</label>

          <div style="width:fit-content; max-width: 850px; text-align: left;">
            <div class="columns" style="text-align: left;">
              <div class="column">
                <br>
                <ValidationProvider
                  name="Video checkbox"
                  :rules="{required:false}"
                  v-slot="{errors}">

                  <div v-for="(videoComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.video_comments" :key="index">
                    <b-field style="margin-bottom: 10px;">
                      <b-checkbox @click.native="warning()" type="is-primary" v-model="videoComment.is_comment_selected">
                        <div>
                          {{videoComment.comment_content}}
                        </div>
                      </b-checkbox>
                    </b-field>

                    <!-- additional comment -->
                    <b-field v-if="videoComment.comment_content === 'Other' &&  videoComment.is_comment_selected ">
                      <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify" v-model="videoComment.additional_comment"></b-input>
                    </b-field>

                  </div>

                  <span class="has-text-danger">{{ errors[0] }}</span>

                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!--Audio call verification-->
        <div class="card custom-card">
          <br>
          <label class="red-title" for="Audio">Audio call verification </label>

          <div style="width:auto; max-width: 850px; ">
            <div class="columns" style="text-align: left;">
              <div class="column" >
              <br>
              <ValidationProvider
                  name="Audio Call checkbox"
                  :rules="{required:false}"
                  v-slot="{errors}">

                <div v-for="(audioComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.audio_comments" :key="index">
                  <b-field style="margin-bottom: 10px;">
                    <b-checkbox @click.native="warning()" type="is-primary" v-model="audioComment.is_comment_selected">
                      <div>
                        {{audioComment.comment_content}}
                      </div>
                    </b-checkbox>
                  </b-field>

                    <b-field v-if="audioComment.comment_content === 'Other' && audioComment.is_comment_selected ">
                      <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify" v-model="audioComment.additional_comment"></b-input>
                    </b-field>
                  </div>

                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!--if DRP Verification failed-->
        <div class="card custom-card">
          <br>
          <label class="red-title">If DRP Verification failed </label>

          <div style="width:fit-content; max-width: 650px;">
            <div class="columns " style="text-align: left;">
              <div class="column" >
              <br>

              <ValidationProvider
                  name="DRP Verification checkbox"
                  :rules="{required:false}"
                  v-slot="{errors}">

                <div v-for="(drpComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.drp_comments" :key="index" style="text-align: left;">

                  <b-field style="margin-bottom: 20px;">
                    <b-checkbox @click.native="warning()" type="is-primary" v-model="drpComment.is_comment_selected">
                      <div>
                        {{ drpComment.comment_content }}
                      </div>
                    </b-checkbox>
                  </b-field>

                </div>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              </div>
            </div>
          </div>
        </div>
        <br>

        <!--Location verification-->
        <div class="card custom-card">
          <br>
          <label class="red-title"> Location verification </label>

          <div style="width:auto; max-width: 850px;">
            <div class="columns " style="text-align: left;">
              <div class="column" >
              <br>

              <ValidationProvider
                  name="Location Verification checkbox"
                  :rules="{required:false}"
                  v-slot="{errors}">

                <div v-for="(locationVerificationComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.location_verification_comments" :key="index">
                  <b-field style="margin-bottom: 20px;">
                    <b-checkbox @click.native="warning()" type="is-primary" v-model="locationVerificationComment.is_comment_selected">
                      <div>
                        {{ locationVerificationComment.comment_content }}
                      </div>
                    </b-checkbox>
                  </b-field>

                  <b-field v-if="locationVerificationComment.comment_content ==='Other' && locationVerificationComment.is_comment_selected ">
                    <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify" v-model="locationVerificationComment.additional_comment"></b-input>
                  </b-field>

                    <span class="has-text-danger">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!--Location verifier-->
        <div class="card custom-card">
          <br>
          <label class="red-title"> Location verifier  </label>

          <div style="width: auto; max-width: 850px;">
            <div class="columns " style="text-align: justify;">
              <div class="column ">
                <br>

                <ValidationProvider
                    name="Location Verifier checkbox"
                    :rules="{required:false}"
                    v-slot="{errors}">

                  <div  v-for="(locationVerifierComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.location_verifier_comments" :key="index">
                      <b-field style="margin-bottom: 20px;">
                        <b-checkbox @click.native="warning()" type="is-primary" v-model="locationVerifierComment.is_comment_selected">
                          <div>
                            {{ locationVerifierComment.comment_content }}
                          </div>
                        </b-checkbox>
                      </b-field>

                      <b-field v-if="locationVerifierComment.comment_content === 'Other' && locationVerifierComment.is_comment_selected " >
                        <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify" v-model="locationVerifierComment.additional_comment"></b-input>
                      </b-field>

                      <span class="has-text-danger">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div style="width: auto; margin-left: auto; margin-right: auto !important;">
              <div>
                <gmap-map
                    :center="{lat: videoKycStore.answeredVideoKycData.location.latitude, lng: videoKycStore.answeredVideoKycData.location.longitude}"
                    :zoom="15"
                    map-type-id="terrain"
                    style="width: 100%; height: 200px"
                >
                  <gmap-marker
                      :position="{lat: videoKycStore.answeredVideoKycData.location.latitude, lng: videoKycStore.answeredVideoKycData.location.longitude}"
                      :clickable="true"
                      :draggable="false"
                  />
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!--Customer Behaviour-->
        <div class="card custom-card">
          <br>
          <label class="red-title">Customer Behaviour</label>

          <div style="width:fit-content;  max-width: 850px;">
            <div class="columns" style="text-align: left;">
              <div class="column" >
              <br>

              <ValidationProvider
                  name="Customer Behavior checkbox"
                  :rules="{required:false}"
                  v-slot="{errors}">

                <div v-for="(customerBehaviorComment,index) in videoKycStore.answeredVideoKycData.questionDetails.comments.customer_behavior_comments" :key="index">
                  <b-field style="margin-bottom: 20px;">
                    <b-checkbox @click.native="warning()" type="is-primary" v-model="customerBehaviorComment.is_comment_selected">
                      <div>
                        {{ customerBehaviorComment.comment_content }}
                      </div>
                    </b-checkbox>
                  </b-field>

                  <b-field v-if="customerBehaviorComment.comment_content === 'Other' && customerBehaviorComment.is_comment_selected ">
                    <b-input @click.native="warning()" maxlength="200" type="textarea" placeholder="Specify"  v-model="customerBehaviorComment.additional_comment"></b-input>
                  </b-field>

                  <span class="has-text-danger">{{ errors[0] }}</span>

                </div>
              </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!--Customer Language-->
        <div class="card custom-card">
          <br>
          <label class="red-title">Customer Spoken Language</label>

          <div style="width:fit-content;  max-width: 850px;">
            <div class="columns" style="text-align: left;">
              <div class="column" >
              <br>

              <ValidationProvider
                  name="Customer Lanuage"
                  :rules="{required:true}"
                  v-slot="{errors}">

                  <SelectLabelVue style="width:20vw"
                    label="Client Spoken Language"
                    required="true"
                    v-model="videoKycStore.answeredVideoKycData.kycForm.spoken_language"
                    :validationRules="{ required: true }"
                    :selectOptions="languageList"
                  />
                  <!-- <b-button v-if="languageChanged" @click="">Save Language</b-button> -->
                  <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <br>

        <!--Date of account funding-->
        <div class="card custom-card" style="margin-bottom: 330px">
          <br>
          <label class="red-title"> Date of account funding  </label>

          <ValidationProvider
            name="Date of account funding"
            :rules="{required:true}"
            v-slot="{errors}"
          >
            <b-field label-position="inside" :custom-class="'custom_float_label_date'" >
              <template #label >Preferred Date for account funding<span class="has-text-danger">*</span></template>
              <p class="control has-icons-right">
                <b-datepicker
                    class="date-picking"
                    rounded
                    :min-date="minAccountFundingDate"
                    v-model="videoKycStore.answeredVideoKycData.dateOfAccountFunding"
                    placeholder="Type or select a date..."
                >
                </b-datepicker>
                <span class="icon is-small is-right">
                    <img
                        src="@/assets/Images/Icons/Calendar.png"
                        alt="Calendar-img"
                        width="25"
                        height="25"
                    />
                </span>
              </p>
            </b-field>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <br>
       </div>
    </ValidationObserver>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Vue, { onMounted } from 'vue';
//import LocationaMap from '@/components/LocationaMap.vue';
import LocationSetMap from './LocationSetMap.vue';
import AgentVideoCall from './AgentVideoCall.vue'; 
import {useVideoKycStore} from "../store/videoKycStore";
import {BuefyHelper} from "../helpers/bufeyHelper";
import {GlobalEventManager} from "../helpers/globalEventManager";
import {GlobalEvents} from "../helpers/globalEvents";
import SelectLabelVue from './SelectLabel.vue';

export default {
  name: 'ApplicationQuestionsCheck',

  components: {
    LocationSetMap,
    SelectLabelVue,
  },

  props:{
    isRecordingOn : {
      type : Boolean,
      default : false,
    },
  },

  setup(){
    const videoKycStore = useVideoKycStore()
    console.log("this",videoKycStore.answeredVideoKycData)
    return {videoKycStore}
  },
 watch:{
  "videoKycStore.answeredVideoKycData.kycForm.spoken_language":function(){
    this.consolelog();
    this.languageChanged = true;
  }
 },
 computed : {
    showPassportTab(){
      const comp = this
      return comp.videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_bio_page_image || comp.videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_alteration_page_image || comp.videoKycStore.answeredVideoKycData.questionDetails.images.face_and_passport_entry_stamp_page_image
    },
 },

  data(){
    return{
      languageChanged:false,
      //calender codes
      minAccountFundingDate : new Date(), // set current date as the minimum account funding date
      defaultimg: require("../assets/Images/image-placeholder.png"),
      spokenLanguage : ["English","Sinhala","Tamil"],
      languageList: [
        {
          value: "Sinhala",
          label: "Sinhala",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "Tamil",
          label: "Tamil",
        },
      ],
    };
  },
  // watch:{
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.rating' : function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.rating ' : function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.rating': function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.rating': function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.rating': function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.rating': function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.rating': function()
  //   {this.Ratingwarning()},
  //   'videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.rating': function()
  //   {this.Ratingwarning()},
  // },
  methods :{
    submitAgentChangedKycForm() {
      const comp = this;

      comp.isSubmitBtnLoading = true;
      comp.videoKycStore.buttonStates.disabled.endCallBtn = true; // disable end call btn

      comp.videoKycStore.answeredVideoKycData.ekycFormData = comp.ekycFormData;
      comp.videoKycStore.answeredVideoKycData.product_details =
        comp.product_selection;
      // comp.videoKycStore.answeredVideoKycData.branchArray = comp.$root.formDataSupport.branchesArray;
      console.log("BRANCH ARRAY", comp.$root.formDataSupport.branchesArray);

      console.log(
        "ekycFormData",
        this.videoKycStore.answeredVideoKycData.ekycFormData
      );
      console.log(
        "product_selection",
        this.videoKycStore.answeredVideoKycData.product_details
      );
      let requestBody = {
        ...comp.ekycFormData,
        product_details: comp.product_selection,
      };

      // debug logs ----------------------------------------
      // console.warn("updated customer Kyc form data")
      // console.log(requestBody)
      // comp.isSubmitBtnLoading = false;

      NetworkManager.apiRequest(
        "api/KycApplication/submitAgnetChangedKycFormData",
        requestBody,
        (response) => {
          if (response.statusCode === 200) {
            console.log("successfully uploaded the updated kyc form");

            comp.isSubmitBtnLoading = false;
            comp.videoKycStore.answeredVideoKycData.pendingActions.isVideoCallKycFormSubmitted = true;
            comp.videoKycStore.buttonStates.disabled.endCallBtn = false;

            BuefyHelper.showToastMessage(
              "Successfully submitted the data",
              "is-success"
            );
          } else {
            comp.isSubmitBtnLoading = false;
            comp.videoKycStore.buttonStates.disabled.endCallBtn = false;
            BuefyHelper.showToastMessage(
              "Sorry! unable to submit the data this time. Try again",
              "is-danger"
            );
          }
        }
      );
    },
    consolelog(){
      console.log("videoKycStore.answeredVideoKycData.kycForm.spoken_language",this.videoKycStore)
    },
    warning() {
      if(!this.isRecordingOn) {
        BuefyHelper.showToastMessage("You Have to start Recording First","is-danger")
      }        
    },

    // Ratingwarning() {
    //   if(this.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_front_side_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_nic_back_side_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_bio_page_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_alteration_page_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_passport_entry_stamp_page_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.face_and_visa_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.selfie_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.signature_image.rating === 0 ||
    //      this.videoKycStore.answeredVideoKycData.imageUploadDetails.address_proof_document_image.rating === 0) {
    //       BuefyHelper.showToastMessage("Rate the captured image","is-danger")
    //   }        
    // },

    imageplaceholder(event) {
      event.target.src = this.defaultimg;
    },

    //calender clear date
    clearDate() {
      this.selected = null
    },


    /**
     * This function can be used to make a request to capture a image for the required image container
     * @param imageKey requested image key
     */
    requestImageCapture(imageKey){
      console.log(imageKey,"imageKey")
      const comp = this
      // comp.Ratingwarning()
      GlobalEventManager.$emit(GlobalEvents.agentSide.captureAttachmentImage,imageKey)
    },

  },

  mounted(){
    const comp = this
    comp.consolelog()
  }
}
</script>

<style scoped>
 .red-title{
    font-family:Gotham-Medium;
    font-weight: 40;
    font-size: 18px;
    color:#04c3fc;
    text-transform: uppercase;
 }
 .black-title{
  font-family:Gotham-Medium;
  font-weight: 500;
  font-size: 18px;
  color:#000000;
 }
 .image{
   width:40vw;
   max-width: 30vw;
   margin-left: auto;
   margin-right: auto;
   border: 2px solid #04c3fc;
   border-radius: 25px;
   /* flex-basis: 40%; */
   padding: 20px;
 }
 .video-kyc-question-container{
  flex-basis: 40%;
  overflow-y: scroll;
  border-radius: 25px;  -webkit-box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
  box-shadow: 1px 1px 14px 1px rgba(0,0,0,0.40);
  margin-top: 10px;
  margin-right: 10px;
}

 .image-wrapper{
   position: relative;
   display: inline-block;
   overflow: hidden;
   margin: 0;
   width: -webkit-fill-available;
 }

 .rating-wrapper{
   display: flex;
   align-items: baseline;
   margin-bottom: 5px;
 }

 .rating-wrapper label{
   font-size: medium;
 }

 .custom-card{
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   width:auto;
   height: fit-content;
   border-radius: 25px;
   padding: 20px;
 }

 .custom_float_label_date {
   top: 0.22em !important;
   font-family: "Gotham-Medium";
   letter-spacing: 0.4px;
   font-size: 0.9rem !important;
   transition: ease 0.3s;
 }

</style>
