<template>

  <div>
    <section style="margin-top: 20px; margin-left: 20px;">
      <label style="font-family:Gotham-Medium; color: gray; font-weight: 400;">Dual Authentication</label>
    </section>

    <section>
      <div class="container-header p-3">
        <div class="columns" style="width: 100%">
          <div class="column">
            <section>
            <b-tabs>
            <b-tab-item label="Users" tag='router-link'  active v-if="hasPermission('user-approve')"><change-request  v-if="hasPermission('user-approve') && !isUpdating" :data="roles" :filter-out="filterOutFromCR" :is-loading="crIsLoading" @approve-or-reject="approve_or_reject_user" labelname='User Name' type="user"></change-request></b-tab-item>
            <b-tab-item label="Roles" tag='router-link' v-if="hasPermission('role-approve')"><change-request  v-if="hasPermission('role-approve') && !isUpdating" :data="role" :filter-out="filterOutFromCR" :is-loading="crIsLoading" @approve-or-reject="approve_or_reject_role" labelname='Role Name' type="role" ></change-request></b-tab-item>
            <b-tab-item label="Branches" tag='router-link' v-if="hasPermission('branch-approve')"><change-request  v-if="hasPermission('branch-approve') && !isUpdating" :data="branches" :filter-out="filterOutFromCR" :is-loading="crIsLoading" @approve-or-reject="approve_or_reject_branch" labelname='Branch Code' type="branch" ></change-request></b-tab-item>
            </b-tabs>
            </section>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>

import ChangeRequest from '../../components/ChangeRequest.vue';
import NetworkManager from "@/network";
import {BuefyHelper} from "../../helpers/bufeyHelper";

export default {
  name: 'List',
  components: {
    'change-request':ChangeRequest
  },

  data() {
    return {
      users: [],
      data: [],
      crIsLoading: false,
      filterOutFromCR: ['id', 'created_at', 'updated_at', 'locked','edited','deleted'],
      roles: [],
      role: [],
      branches:[],
      isUpdating : false,
    };
  },

  methods:{

    resetChangeRequestData(){
      const comp = this

      console.log("resetting change request data....")
      comp.roles = []
      comp.role = []
      comp.branches= []

    },
    loadData: function (){
      const  self = this

      self.showFullScreenLoader()
      self.resetChangeRequestData()

      console.log("Loading initial change request data ...")

      NetworkManager.apiRequest('api/DualAuth/getPendingChangeRequests', {}, function (e){
        if(e.statusCode === 200){
          console.log("============== hello ",e.data)

          self.roles = e.data.user_change_requests
          self.role = e.data.role_change_requests
          self.branches= e.data.branch_change_requests
          self.hideFullScreenLoader()
        }
        else {
          self.hideFullScreenLoader()
        }

      })
      
    },

    approve_or_reject_branch:function(data){
      console.log("Approval or rejected called to the branch list")
      console.log(data)

      const comp = this
      comp.showFullScreenLoader()
      comp.isUpdating = true;

      NetworkManager.apiRequest('api/Branch/approve', data, function (e){
        if(e.statusCode === 200){

          comp.hideFullScreenLoader()

          let toastType = data.actionTaken === "approve" ? "is-success" : "is-danger";
          BuefyHelper.showToastMessage(e.data.message,toastType)

          comp.loadData()
          comp.isUpdating = false;
        }
        else{
          comp.hideFullScreenLoader()
          BuefyHelper.showToastMessage(e.data.message,"is-danger")

          comp.loadData()
          comp.isUpdating = true;
        }

       // location.reload()


      })
    },


    approve_or_reject_user:function(data){
      console.log("Approval or rejected called to the user list")
      console.log(data)

      const comp = this
      comp.showFullScreenLoader()
      comp.isUpdating = true;

      NetworkManager.apiRequest('api/user/approve', data, function (e){
        if(e.statusCode === 200){
          comp.hideFullScreenLoader()

          let toastType = data.actionTaken === "approve" ? "is-success" : "is-danger";
          BuefyHelper.showToastMessage(e.data.message,toastType)

          comp.loadData()
          comp.isUpdating = false;

        }
        else{
          comp.hideFullScreenLoader()
          BuefyHelper.showToastMessage(e.data.message,"is-danger")

          comp.loadData()
          comp.isUpdating = false;
        }

       // location.reload()
      })
      
    },


    approve_or_reject_role:function(data){
      console.log("Approval or rejected called to the role list")
      console.log(data)

      const comp = this
      comp.showFullScreenLoader()
      comp.isUpdating = true;

      NetworkManager.apiRequest('api/Role/approve', data, function (e){
        if(e.statusCode === 200){
          comp.hideFullScreenLoader()

          let toastType = data.actionTaken === "approve" ? "is-success" : "is-danger";
          BuefyHelper.showToastMessage(e.data.message,toastType)

          comp.loadData()
          comp.isUpdating = false;
        }
        else{
          comp.hideFullScreenLoader()
          BuefyHelper.showToastMessage(e.data.message,"is-danger")

          comp.loadData()
          comp.isUpdating = false;
        }

        // location.reload()
      })
    },

  },

  mounted(){
    this.loadData()
        
  }

}

</script>

<style>
.tabs .is-active a{
 color: #04c3fc !important; 
 border-bottom-color:#04c3fc !important;
 margin-left: 15px;
}

</style>