<template>
  <div style="height: 80.5vh; overflow: hidden !important;">
    <!--    un comment following lines -->
    <!-- <div class="mt-6">
      <SearchBar page="New Applications" />
    </div> -->
    <div class="columns" style="margin-top: 2.8vh; margin-left: 1.1vw;">
      <div class="column">
        <label
          style="
            font-family: Gotham-Medium;
            color: gray;
            font-weight: 400;
            font-size: 23px;
          "
          >New Applications</label
        >
      </div>
      <div class="column">
        <b-input
          placeholder="Search..."
          rounded
          type="search is-primary"
          icon="magnify"
          icon-clickable
          v-model="searchKeyword"
          style="width: 23.4vw"
        >
        </b-input>
      </div>
    </div>

    <TableComp
      :tableData="tableDetails"
      :columns="columnDetails"
      :previousPage="currentPage"
      :statusToAssign="statusToBeAssigned"
      :searchableFields="searchableFields"
      :searchKeyword = "searchKeyword"
      styles="mt-4 mx-6"
    />

    <b-button
      v-if="hasPermission('assigned-applications-view')"
      type="is-primary is-small"
      rounded
      class="is-pulled-right"
      @click="
        () => {
          buttonClick();
          navigateTo('AssignedApplicationsReview');
        }
      "
      >View</b-button
    >
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import FilterBranch from "../../components/FilterBranch.vue";
import { filterTableData } from "../../helpers/filterTableData";
import NetworkManager from "@/network";
// import { ApiRequestManager } from "../../network/apiRequestManager";
import { GlobalEventManager } from "../../helpers/globalEventManager";

export default {
  components: {
    TableComp,
    SearchBar,
    FilterBranch,
  },
  data() {
    return {
      showVideoCallModel: false,

      ref_no: "",
      nic: "",
      name: "",
      email: "",
      contact: "",
      received_date: "",
      action: "",

      searchableFields: [
        "reference_number",
        "nic_eic_number",
        "full_name",
        "email",
        "mobile_number",
        "created_at",
        "gender",
        "resident_status",
      ],
      searchKeyword: "",
      tableDetails: "",
      tableinfo: "",
      applicationList: [],

      currentPage: "NEW",
      statusToBeAssigned: "ASSIGNED-DETAIL-REVIEWER",
      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No.", date: false },
        { id: 2, field: "nic_eic_number", label: "NIC/EIC", date: false },
        { id: 3, field: "full_name", label: "Name", date: false },
        { id: 4, field: "gender", label: "Gender" ,date:false},
        { id: 5, field: "email", label: "Email", date: false },
        { id: 6, field: "mobile_number", label: "Contact", date: false },
        { id: 7, field: "resident_status", label: "Residence" ,date:false},
        { id: 8, field: "created_at", label: "Received Date", date: true },
        { id: 9, field: "action", label: "Action", date: false },
      ],
    };
  },

  created() {
    this.tableinfo = filterTableData;
  },

  methods: {
    GetNewApplicationData: function () {
      const comp = this;
      const data = {
        status: "NEW",
      };

      comp.showFullScreenLoader();
      NetworkManager.apiRequest(
        "api/KycApplication/listByStatus",
        data,
        function (e) {
          if (e.statusCode === 200) {
            comp.tableDetails = e.data.applications;
          }

          comp.hideFullScreenLoader();
        }
      );
    },
  },
  mounted() {
    this.GetNewApplicationData();
    if (this.$root.isSideBarHidden) {
      this.$root.isSideBarHidden = !this.$root.isSideBarHidden;
    }
  },
};
</script>
<style scoped>
.search-input  .magnify { color: rgb(65, 217, 207); }
</style>
