// import {useAuthStore} from "../store/authStore";
// const authStore = useAuthStore()


import {GlobalAttributes} from "./globalAttributes";
import ProductDetailDTO from "./classes/dtos/productDetailDTO";
import jwtUtils from "@/helpers/jwtUtils";

const GlobalFunctions = {

    videoCallStartedLocalStoreKey : 'videoCallStarted',

    /**
     * A function that can be used to get formatted name of the customer with the title (before video call)
     * @param kycForm
     * @returns {string}
     */
    getFormattedNameForNotification(kycForm){
        return kycForm.title.customer_input+". "+kycForm.full_name.customer_input;
    },

    checkUserHasPermission(permission){
        return "abc"
    },


    /***
     * This function is used to get status of the residence display value
     * @param statusOfResidence
     * @returns {string}
     */
    getStatusOfResidenceDisplayValue(statusOfResidence){
        let outputValue = "";

        switch (statusOfResidence){
            case "Renter":
                outputValue = "On Rent";
                break;
            case "Living With Parents":
                outputValue = statusOfResidence;
                break;
            case "House Holder":
                outputValue = statusOfResidence;
                break;
        }

        return outputValue;
    },


    /**
     * This function can be used to get display time in minutes and seconds
     * @param timeSeconds time duration in seconds
     * @returns {string} display time :  15 minutes and 38 seconds kind of output
     */
    getDisplayTimeDuration(timeSeconds){
        let minutes = Math.floor(timeSeconds/60);
        let seconds = timeSeconds % 60;

        console.log(`Mins ${minutes} seconds ${seconds}`)

        let outMins = minutes === 1 ? `${minutes} minute` : `${minutes} minutes` ;
        let outSecs = seconds === 1 ? `${seconds} second` : `${seconds} seconds`;

        let output = outMins+" and "+outSecs;
        console.log("output time :"+output)

        return output;
    },


    /**
     * This function can be used to get the verified kyc field value from a kyc field
     * @param kycField
     * @returns {*}
     */
    getKycFieldVerifiedValue(kycField){
      return kycField.is_changed_by_call_agent === true ?  kycField.call_agent_input :  kycField.customer_input
    },


    /**
     * This function can be used to show or hide, debit card related fields in the UI
     * @param residentship
     * @param productSelection
     * @returns {false|boolean}
     */
    checkDebitCardSectionVisible(residentship,productSelection){

        console.warn("check debit card section is visible")
        console.log(residentship)
        console.log(productSelection)
        console.log("verified residentship value : "+ this.getKycFieldVerifiedValue(residentship))
        console.log("==============================================")
        let isSriLankan = this.getKycFieldVerifiedValue(residentship) !== 'Out of Sri Lanka'
        let isLKRAvailable = false

        productSelection.forEach(productSelection => {
            if(this.getKycFieldVerifiedValue(productSelection.currency_type) === "LKR"){
                isLKRAvailable = true
            }
        })

        let debitCardSectionVisible = isSriLankan && isLKRAvailable
        console.log("Show debit card section : "+debitCardSectionVisible)
        return debitCardSectionVisible
    },


    /**
     * This function can be used to check is text area is needed or not
     * @param textToDisplay
     * @returns {boolean}
     */
    showAsTextArea(textToDisplay){
        return textToDisplay.length > 50;

    },


    // Video call status related functions - start ---------------------------------------------------------------------

    setVideoCallStartedState(isStarted=true){
        localStorage.setItem(this.videoCallStartedLocalStoreKey,JSON.stringify(isStarted))
    },

    getVideoCallStartedState(){
        if(localStorage.getItem(this.videoCallStartedLocalStoreKey) !== null){
            return JSON.parse(localStorage.getItem(this.videoCallStartedLocalStoreKey))
        }
        else{
            return false
        }
    },

    removeVideoCallStartedState(){
        if(localStorage.getItem(this.videoCallStartedLocalStoreKey) !== null){
            localStorage.removeItem(this.videoCallStartedLocalStoreKey)
        }
    },

    // Video call status related functions - end -----------------------------------------------------------------------

    /**
     * This can be used to prevent default click
     * @param e event
     */
    preventDefault(e){
        e.preventDefault();
    },


    /**
     * This function is used to wait until district list is loaded
     * @param root
     * @returns {Promise<unknown>}
     */
    waitUntilDistrictListLoaded(root) {
        const comp = this;

        return new Promise((resolve) => {
            const checkDistrictListLoaded = () => {
                if (root.formDataSupport.districtsArray.length > 0) {
                    console.log("district list loaded ...");
                    resolve();
                } else {
                    console.log("waiting for district list...");
                    setTimeout(checkDistrictListLoaded, 100);
                }
            };

            checkDistrictListLoaded();
        });
    },


    /**
     * This function can be used to check is the video call is completed
     * @param videoCallDetails
     * @returns {boolean}
     */
    isVideoCallCompleted(videoCallDetails){

        if(videoCallDetails === null){
            return false;
        }
        else {
            return videoCallDetails.face_and_nic_front_side_image !== null
        }
    },


    /**
     * This function can be used to get the given number in two digits
     * @param inputNumber
     * @returns {*}  two digits number
     */
    getNumberInTwoDigits(inputNumber){
        if(inputNumber<10){
            inputNumber = "0"+inputNumber
        }
        return inputNumber
    },


    /**
     * This function can be used to check given kyc form is approved or rejected
     * @param kycStatus
     * @returns {boolean}
     */
    checkKycApprovedOrRejected(kycStatus){
        if(kycStatus.trim() === GlobalAttributes.kycFormStatus.APPROVED || kycStatus.trim() === GlobalAttributes.kycFormStatus.REJECTED){
            return true;
        }
        else{
            return false;
        }
    },

    /**
     * This function can be used to check given kyc form is approved
     * @param kycStatus
     * @returns {boolean}
     */
    checkKycApproved(kycStatus){
        return kycStatus.trim() === GlobalAttributes.kycFormStatus.APPROVED;
    },


    /**
     * This function can be used to check given index is exist in the given array
     * @param array array
     * @param index array index
     * @returns {boolean}
     */
    checkIndexExistInArray(array,index){
        return typeof array[index] !== 'undefined';
    },


    /**
     * This function can be used to check given agent changed pep detail should show or hide
     * @param pepDeclaration pep declaration kyc attribute
     * @param index array index
     * @param property pep detail property
     * @returns {boolean}
     */
    showAgentChangedPepDetail(pepDeclaration,index,property){
        let customerPepDetails = pepDeclaration.customer_input.pep_person_details // details array
        let agentPepDetails = pepDeclaration.call_agent_input.pep_person_details // details array

        // debug logs
        // console.log("PEP TEST ===============")
        // console.log(pepDeclaration)
        // console.log(customerPepDetails[index][property])
        // console.log(customerPepDetails[index][property])

        if(pepDeclaration.is_changed_by_call_agent){
            // if(customerPepDetails[index][property])

            // check index exist in customer array
            if(this.checkIndexExistInArray(customerPepDetails,index)){

                // check agent input is empty
                if(agentPepDetails[index][property].trim() !== ''){

                    //compare inputs
                    if(customerPepDetails[index][property].trim() === agentPepDetails[index][property].trim()){
                        // inputs identical no need to display
                        return false;

                    }
                    else{
                        // show agent input
                        return true;
                    }
                }
                else{
                    // no agent input
                    return false;
                }
            }
            else{
                // check agent input is empty
                if(agentPepDetails[index][property].trim() !== ''){
                    // return true since requested index only in agent array and its not empty
                    return true;
                }
                else{
                    // empty agent input
                    return false;
                }
            }
        }
        else{
            return false;
        }

    },

    /**
     * This function can be used to get the display value of the user selected pep option
     * @param pepDeclaration pep declaration
     * @param isAgentInput is using for agent input or not default to customer input
     * @returns {string}
     */
    getPepSelectionDisplayValue(pepDeclaration,isAgentInput=false){
        let pepSelection;

        if(isAgentInput){
            pepSelection = pepDeclaration.call_agent_input
        }
        else{
            pepSelection = pepDeclaration.customer_input
        }

        console.log("PEP selection")
        console.log(pepSelection)

        // currently pep selection has only 3 following options
        if(pepSelection.not_politically_exposed){
            return "No";
        }
        else if(pepSelection.close_relative_politically_exposed){
            return "Yes : Close Relative Politically Exposed"
        }
        else if(pepSelection.iam_politically_exposed){
            return "Yes : I am Politically Exposed"
        }
    },


    /**
     * This function can be used to show or hide agent pep selection
     * @param pepDeclaration pep declaration
     * @returns {boolean}
     */
    showAgentChangedPepSelection(pepDeclaration){
        let customerSelection = pepDeclaration.customer_input
        let agentSelection = pepDeclaration.call_agent_input

        // check agent changed selection
        if(pepDeclaration.is_changed_by_call_agent){

            if(customerSelection.not_politically_exposed){
               return !agentSelection.not_politically_exposed; // true only agent change other option
            }
            else if(customerSelection.close_relative_politically_exposed){
                return !agentSelection.close_relative_politically_exposed; // true only agent change other option
            }
            else if(customerSelection.iam_politically_exposed){
                return !agentSelection.iam_politically_exposed; // true only agent change other option
            }
        }
        else{
            // not changed
            return false
        }

    },


    /**
     * This function can be used to get formatted product selection as required for the frontend vue components
     * @param rawProductSelection
     * @returns {[]}
     */
    getFormattedProductDetails(rawProductSelection){

        console.log("Get formatted product details - start")
        console.log(rawProductSelection)

        let formattedProducts =[];

        rawProductSelection.forEach(rawProduct => {
            let purposeOfOpeningCustomerInputs =[]
            let purposeOfOpeningCAgentInputs =[]
            let purposeOfOpeningOtherSpecifyCustomerInput = ""
            let purposeOfOpeningOtherSpecifyAgentInput = ""

            // get purpose of opening account - customer inputs
            rawProduct.purpose_of_opening_account.customer_input.forEach(purpose => {
                if (purpose.key !== "purpose_other_specified")
                {
                    purposeOfOpeningCustomerInputs.push(purpose.purpose)

                }
                else
                {
                    purposeOfOpeningOtherSpecifyCustomerInput = purpose.purpose;
                }
            })

            // get purpose of opening account - agent inputs
            if(rawProduct.purpose_of_opening_account.call_agent_input != null){

                rawProduct.purpose_of_opening_account.call_agent_input.forEach(purpose => {
                    if (purpose.key !== "purpose_other_specified")
                    {
                        purposeOfOpeningCAgentInputs.push(purpose.purpose)
                    }
                    else
                    {
                        purposeOfOpeningOtherSpecifyAgentInput = purpose.purpose;
                    }
                })
            }

            let productDetailDTO = new ProductDetailDTO()
            productDetailDTO.id = rawProduct.id
            productDetailDTO.account_type = rawProduct.account_type
            productDetailDTO.product_id = rawProduct.product_id
            productDetailDTO.anticipated_volume = rawProduct.anticipated_volume
            productDetailDTO.currency_type = rawProduct.currency_type
            productDetailDTO.initial_deposit_amount = rawProduct.initial_deposit_amount
            productDetailDTO.purpose_of_opening_account = rawProduct.purpose_of_opening_account
            productDetailDTO.purpose_of_opening_customer_inputs = purposeOfOpeningCustomerInputs
            productDetailDTO.purpose_of_opening_agent_inputs = purposeOfOpeningCAgentInputs
            productDetailDTO.purpose_of_opening_other_specify_customer_inputs = purposeOfOpeningOtherSpecifyCustomerInput
            productDetailDTO.purpose_of_opening_other_specify_agent_inputs = purposeOfOpeningOtherSpecifyAgentInput

            console.log("result product DTO")
            console.log(productDetailDTO)

            // add to output products
            formattedProducts.push(productDetailDTO)
        })


        return formattedProducts;

    },


    /**
     * This function can be used to check customer occupation is required
     * @param kycForm customer kyc form
     * @returns {boolean}
     */
    checkCustomerOccupationRequired(kycForm){
        let  verifiedEmploymentStatus = kycForm.employment_status.is_changed_by_call_agent ? kycForm.employment_status.call_agent_input : kycForm.employment_status.customer_input

        console.log("verified employment status of customer : "+verifiedEmploymentStatus)

        if(verifiedEmploymentStatus === "Full-Time" || verifiedEmploymentStatus === "Part Time" ||verifiedEmploymentStatus === "Probation"){
            return true;
        }
        else {
            return false;
        }
    },

    getVerifiedKycAttributeValue(kycAttribute){
      return kycAttribute.is_changed_by_call_agent ? kycAttribute.call_agent_input : kycAttribute.customer_input
    },

    getCustomerPermanentAddress(kycForm){
        let address  = ""

        address += this.getVerifiedKycAttributeValue(kycForm.building_number)+", "
        address += this.getVerifiedKycAttributeValue(kycForm.street)+", "
        address += this.getVerifiedKycAttributeValue(kycForm.town)+", "
        address += this.getVerifiedKycAttributeValue(kycForm.district)

        console.log("customer permanent address : ",address)

        address = address.trim()

        if(address.charAt(0) === ','){
            address = address.substring(1)
        }
        console.log("customer permanent address (formatted) : ",address)
        return address.trim()
    },

    getCheckedDrpStatusResultDisplayValue(drpStatus){
        return drpStatus === true ? 'Success' : 'Fail'
    },


    /**
     * This function can be used to get time difference between two days in minutes
     * @param date1
     * @param date2
     * @returns {number} time difference in minutes
     */
    getTimeDifferenceInMinutes(date1,date2){
        // console.log("date 1 : ",date1)
        // console.log("date 2 : ",date2)

        // console.log("dif millis : ",(date2.getTime() - date1.getTime()))
        let differenceValue =(date2.getTime() - date1.getTime()) / 1000;

        // console.log("dif secs : ",differenceValue)
        differenceValue /= 60;

        // console.log("dif min raw : ",differenceValue)
        return Math.floor(Math.abs(differenceValue)) ;
    },


    /**
     * This is a automatic session handler function.
     * This can be used to automatically logout the logged user if the user inactive more than specified idle session value
     * @param authStore
     */
    checkUserIsInactive(authStore){
        let currentTime = new Date() // store current timestamp
        let lastInteractionAt = authStore.userInteractionDetails.lastInteractionAt // get last user interaction time

        // console.warn("check user is inactive ......")
        // console.log("current time : " ,currentTime)
        // console.log("last interaction at : ",lastInteractionAt)

        let timeDifferenceMinutes = this.getTimeDifferenceInMinutes(new Date(lastInteractionAt),currentTime)
        // console.log("Time diff : ",timeDifferenceMinutes)
        // console.log("Max session idle time : ",authStore.maxSessionIdleTimeMins)

        if(timeDifferenceMinutes >= authStore.maxSessionIdleTimeMins ){
            console.warn("Session expired! .................")
            if(this.getVideoCallStartedState()){
                console.warn("Force automatic logout - video call started...")
            }
            else {
                console.warn("Logout the user .................")
                jwtUtils.logoutTheUser()
            }
        }
        else{
            // console.log("Session is valid ....................")
        }
    },

    /**
     * Used to get the rounded number
     * @param number
     * @returns {number}
     */
    getRoundedValue(number){
        return Math.round((number + Number.EPSILON) * 100) / 100
    },


    getOcrBillType(type){
      if (type.trim().length === ""){
          return type;
      }
      else{
          let displayType = ""

          if(type === "ceb_new_bill"){
              displayType = "Ceylon Electricity Board Bill"
          }
          else if(type === "leco_bill_v1"){
              displayType = "Lanka Electricity Company Bill"
          }

          return displayType;
      }
    },

}

export {GlobalFunctions}
