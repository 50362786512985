<template>
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 600px;
      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;">
      <ValidationObserver v-slot="{ invalid }">
        <div>
          <div class="columns" style="width: 600px;">
            <div class="column" style="text-align: center;">
              <label style="color: #04c3fc; font-size: large;">Submit to Approver</label>
            </div> 
            <div class="column" style="margin-left: 87%; position: absolute;">
              <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
            </div> 
          </div> 
        </div>
          

        
        <div class="message-box-body">
          Add Comment/s for internal use
          <ValidationProvider
                name="Internal Comment"
                :rules="validateComment()"
                v-slot="{errors}">
            <b-input maxlength="200" 
              v-model="Su_to_App_internalcomment"
              @input="emitSu_to_Appinternalcomment"
              type="textarea" 
              placeholder="Specify" 
              rows = 4
              style="width: 100%;
              margin-top: 10px;" 
            ></b-input> 
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div style="margin-left: auto; margin-right: auto; text-align: center;">
          <b-button style=" width: 400px;" type="is-primary" @click="emitSu_to_Approver" :disabled="invalid">Submit</b-button>
        </div>
      </ValidationObserver>
    </div>
  <!-- </b-modal>  -->
</template>

<script>

export default {
  name: 'SubmitToApprover',
  props: {
    title: String,
    message: String,
    show: Boolean,
  },

  emits:['close'],

  data(){
    return{

      Su_to_App_internalcomment:'',
      isvisible : true,
      Su_to_App_isButtonDisabled: false,
      Su_to_App_showMessageBox: false,
    }
  },
  methods: {
    emitSu_to_Appinternalcomment() {
      this.$emit('update_Su_to_App_internal_comment', this.Su_to_App_internalcomment);
    },
    emitSu_to_Approver() {
      this.$emit('Su_to_Approver',this.Su_to_App_internalcomment);
    },
    emitCloss(){
      this.$emit('Closs_Su_to_App',this.Su_to_App_showMessageBox)
    },
    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
  },
};
</script>

<style>

</style>