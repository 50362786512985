export default class ActivityLogTableDataDTO {
    level = ""
    description = ""
    branch = ""
    user = ""
    timestamp = ""

    constructor(level,description,branch,user,timestamp) {
        this.level = level
        this.description = description
        this.branch = branch
        this.user = user
        this.timestamp = timestamp
    }

}