import {ExceptionalApproverBaseTableDataItem} from "../exceptionalApproverBaseTableDataItem";

export class DeniedExApprovalCallAgentTableDataItem extends  ExceptionalApproverBaseTableDataItem{
    scheduled_time
    denied_date

    received_date

    constructor(id,refNo,nic,name,gender,email,branch,assignedUserName,status,scheduledTime,deniedDate,receivedDate) {
        super(id,refNo,nic,name,gender,email,branch,assignedUserName,status);
        this.scheduled_time = scheduledTime;
        this.denied_date = deniedDate;
        this.received_date = receivedDate
    }

}