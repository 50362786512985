<template>
  <div style="height: 80.5vh; overflow: auto !important" > 
    <div class="container-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <label
            style="
                font-family: Gotham-Medium;
                color: gray;
                font-weight: 400;
                font-size: 23px;
              "
              >Approved Applications</label
            >
          </b-breadcrumb>
        </div>
        <div class="columns is-vcentered">
          <div class="coloumn is-half">
            <div class="column mr-2">
              <b-input
                placeholder="Search..."
                rounded
                type="search"
                icon="magnify"
                icon-clickable
                v-model="searchKeyword"
                style="width: 450px"
              >
              </b-input>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <b-tabs v-model="activeTab">
      <b-tab-item label="Detail Reviewer" v-bind="deatailReviwer">
        <TableComp
          :tableData="deatailReviwertableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchable-fields="searchableFields"
          :searchKeyword="searchKeyword"
        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Call Agent" v-bind="callAgent">
        <TableComp
          :tableData="callAgenttableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchable-fields="searchableFields"
          :searchKeyword="searchKeyword"
        styles="mt-4 mx-6"
        />
      </b-tab-item>

      <b-tab-item label="Authorizer" v-bind="authorizer">
        <TableComp
          :tableData="authorizertableDetails"
          :columns="columnDetails"
          :previousPage="currentPage"
          :statusToAssign="statusToAssign"
          :searchable-fields="searchableFields"
          :searchKeyword="searchKeyword"
        styles="mt-4 mx-6"
        />
      </b-tab-item>
    </b-tabs> 
  </div>
</template>

<script>
import TableComp from "../../components/TableComp.vue";
import SearchBar from "../../components/SearchBar.vue";
import NetworkManager from "@/network"

export default {
  components: { TableComp, SearchBar },
  name: "ApprovalRequired",
  data() {
    return {
      activeTab: 0,
      callAgent : false,
      authorizer : false,
      deatailReviwer : false,
     
      callAgenttableDetails: [],
      authorizertableDetails: [],
      deatailReviwertableDetails: [],
      currentPage:'EXCEPTIONAL-APPROVER-APPROVED',
      statusToAssign:"",

      columnDetails: [
        { id: 1, field: "reference_number", label: "Ref No." ,date:false},
        { id: 2, field: "nic_eic_number", label: "NIC/EIC" ,date:false},
        { id: 3, field: "full_name", label: "Name" ,date:false},
        { id: 4, field: "gender", label: "Gender" ,date:false},
        { id: 5, field: "email", label: "Email" ,date:false},
        { id: 6, field: "mobile_number", label: "Contact" ,date:false},
        { id: 7, field: "resident_status", label: "Residence" ,date:false},
        { id: 8, field: "created_at", label: "Received Date",date:true},
        { id: 9, field: "status_updated_at", label: "Approved Date",date:true},
        { id: 10, field: "action", label: "Action" ,date:false},   
      ],

      searchableFields :['reference_number', 'nic_eic_number','full_name','email','mobile_number','created_at','status_updated_at',],
      searchKeyword : "",
    };
  },
  methods: {
    Get__Approved_DetailReviwer_Details(){
      const comp= this
        const  data = {
          status:"EXCEPTIONAL-APPROVER-APPROVED",
          type:3
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/GetExceptionalApplications',data, function (e){
          if(e.statusCode === 200){
            comp.deatailReviwertableDetails = e.data
          }
          // comp.hideFullScreenLoader()
        })
    },
    Get__Approved_CallAgent_Details(){
      const comp= this
        const  data = {
          status:"EXCEPTIONAL-APPROVER-APPROVED",
          type:4
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/GetExceptionalApplications',data, function (e){
          if(e.statusCode === 200){
            comp.callAgenttableDetails = e.data
          }
          // comp.hideFullScreenLoader()
        })
    },
    Get__Approved_Authorizer_Details(){
      const comp= this
        const  data = {
          status:"EXCEPTIONAL-APPROVER-APPROVED",
          type:5
        }
        // comp.showFullScreenLoader()
        NetworkManager.apiRequest('api/KycApplication/GetExceptionalApplications',data, function (e){
          if(e.statusCode === 200){
            comp.authorizertableDetails = e.data
          }
          // comp.hideFullScreenLoader()
        })
    }
 
  },
  mounted() {     
      this.Get__Approved_DetailReviwer_Details();
      this.Get__Approved_CallAgent_Details();
      this.Get__Approved_Authorizer_Details();
      if(this.$root.isSideBarHidden ){
        this.$root.isSideBarHidden=!this.$root.isSideBarHidden;
      }
    },
};
</script>
<style scoped></style>
