<template>
    <section style="font-size: 1.05rem">
       
      <b-table
        :data="data ? data : []"
        :class="tableStyle"
        :mobile-cards="hasMobileCards"
        :row-class="(row, index) => (index % 2 === 0 ? rowClassOne : rowClassTwo)"
      >
        <b-table-column
          v-for="column in columns"
          :key="column.field"
          :field="column.field"
          :label="column.label"
          :th-attrs="column.tableHeaderAttributes"
          :td-attrs="column.tableDataAttributes"
          v-slot="props"
        >
        {{props.row[column.field]}}
        </b-table-column>
      </b-table>
    </section>
  </template>
  
  <script>
  export default {
    name: "DRPTable",
    data() {
      return {};
    },
    props: [
      "data",
      "hasMobileCards",
      "columns",
      "rowClassOne",
      "rowClassTwo",
      "tableStyle",
    ],
    methods: {},
  };
  </script>
  
  <style>
  .striped-table-color {
    background-color: #f0fee4;
  }
  .striped-table-color-2 {
    background-color: #eff3fc;
  }
  .color-heading .table thead {
    background-color: #eff3fc !important;
  }
  </style>
  