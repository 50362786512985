<template>
  <ValidationObserver v-slot="{ invalid }">
  <!-- <b-modal v-model="modelvisible" :width="450" scroll="keep" animation="zoom-out" :can-cancel="false"> -->
    <div class="container" 
      rounded style="width: 41vw;
      background-color: white;
      border: 2px solid #000000;
      border-radius: 25px;
      padding: 20px;">
      <div>
        <div class="columns is-7 is-variable" style="width: 41vw;">
          <div class="column is-three-fifth" style="text-align: center;">
            <label style="color: #04c3fc; font-size: large;">Approve Application</label>
          </div> 
          <div>
            <b-button @click="emitCloss" type="is-primary" outlined>x</b-button>
          </div> 
        </div> 
      </div>
        

      
      <!-- <div class="message-box-body" v-if="!is_exceptional_approver">
        Add Comment/s for Customer
        <b-input class="rounded-input"
          maxlength="400" 
          v-model="App_customercomment"
          @input="emitAppcustomercomment"
          type="textarea" 
          placeholder="Specify" 
          rows = 4
          style="width: 100%;
          margin-top: 10px;" 
        ></b-input> 
      </div> -->
      <div class="message-box-body" v-if="is_exceptional_approver">
        Add Comment/s internal use
        <ValidationProvider
          name="ex Internal Comment"
          :rules="validateComment()"
          v-slot="{errors}">
          <b-input class="rounded-input"
            maxlength="400" 
            rounded
            v-model="App_internalcomment" 
            @input="emitAppinternalcomment"
            type="textarea" 
            placeholder="" 
            rows = 3
            style="width: 100%;"
          ></b-input>
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div v-if="!is_exceptional_approver">
        <!-- <b-collapse :open="false" aria-id="contentIdForA11y1"> -->
        <b-collapse  aria-id="contentIdForA11y1">
          <label aria-controls="contentIdForA11y1">Add Comment/s for internal use</label>
        </b-collapse>
        <b-collapse  aria-id="contentIdForA11y1">
              <!-- <template #trigger="props">
                <label aria-controls="contentIdForA11y1"
                      :aria-expanded="props.open"
                      style="color: #ED1C24;"> 
                      <i class="mdi mdi-plus-circle-outline"></i> 
                      Add Comment/s for internal use
                </label>
                 
              </template> -->
              <!-- <div class="notification"> -->
                  <div class="content">
                    <ValidationProvider
                      name="not ex Internal Comment"
                      :rules="validateComment()"
                      v-slot="{errors}">
                      <b-input class="rounded-input"
                        maxlength="400" 
                        rounded
                        type="textarea" 
                        placeholder="" 
                        rows = 3
                        v-model="App_internalcomment" 
                        @input="emitAppinternalcomment"
                        style="width: 100%;"
                      ></b-input>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
              <!-- </div> -->
          </b-collapse>

      </div>
      <b-field grouped position="is-centered">
        <b-button style=" width: 25vw;" type="is-primary" @click="emitApproveApplication">Approve</b-button>
      </b-field>
    </div>
  <!-- </b-modal>  -->
  </ValidationObserver>
</template>

<script>
import {useAuthStore} from "../store/authStore"; 

export default {
  name: 'ApproveApplication',
  props: {
    title: String,
    message: String,
    show: Boolean,
  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  emits:['close'],

  data(){
    return{
      App_customercomment :'',
      App_internalcomment:'',
      isvisible : true,
      App_isButtonDisabled: false,
      App_showMessageBox: false,
      is_exceptional_approver:false,
    }
  },
  methods: {
    emitAppcustomercomment() {
      this.$emit('update_App_customer_comment', this.App_customercomment);
    },
    emitAppinternalcomment() {
      this.$emit('update_App_internal_comment', this.App_internalcomment);
    },
    emitApproveApplication() {
      let comments = {
        commentForCustomer : this.App_customercomment,
        commentForInternal :  this.App_internalcomment
      }
      this.$emit('Approve_Application',comments);
    },
    emitCloss(){
      this.$emit('Closs_App',this.App_showMessageBox)
    },
    validateComment: function () {
      const obj = {
        required: false,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
  },
  mounted()
  {
    console.log("in mounted", this.authStore.loggedInUser)
    if(this.authStore.loggedInUser.role == 'Exceptional Approver'){
      this.is_exceptional_approver =true
    }
  }
};
</script>

<style>
.rounded-input .input,
.rounded-input textarea {
  border-radius: 25px;
}
</style>