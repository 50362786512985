<template>
    <div>
        <b-collapse class="card" animation="slide" :open="isOpen" :aria-id="'contentIdForA11y5-'">
            <template #trigger="props">
                <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-'"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        AMS Results
                    </p>
                    <span v-if="amsData && amsData.length > 0">NO. of Sources : {{ amsData.length }}</span>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <div class="body">
                        <div size="is-small" type="is-toggle-rounded" position="is-centered" class="custom-tabs">
                            <div label="AMS Results" icon="card-account-details">

                                <div class="tab-item-wrapper">
                                    <div class="not-available" v-if="!amsData || amsData.length === 0">
                                        No Data Available
                                    </div>
                                    <div v-else>
                                        <!-- <div>NO. of Sources : {{ amsData.length }}</div>
                                        <br> -->
                                        <!-- Iterate over each object in pepData array -->
                                        <div v-for="(amsData, index) in amsData" :key="index">
                                            <div class="table-container" style="margin-bottom: 20px;">
                                                <div>Source : {{ index + 1 }}</div>
                                                <table class="data-table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Name</td>
                                                            <td>{{ amsData.name ? amsData.name : 'N/A' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tags</td>
                                                            <td>{{ amsData.tags ? amsData.tags.join(', ') : 'N/A' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Heading</td>
                                                            <td>{{ amsData.heading ? amsData.heading : 'N/A' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Summary</td>
                                                            <td>{{ amsData.summary ? amsData.summary : 'N/A' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Content</td>
                                                            <td>{{ amsData.content ? amsData.content : 'N/A' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Source</td>
                                                            <td>{{ amsData.source ? amsData.source : 'N/A' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Url</td>
                                                            <td>{{ amsData.url ? amsData.url : 'N/A' }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Publish Date</td>
                                                            <td>{{ amsData.publish_date ? amsData.publish_date : 'N/A'
                                                                }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>

        <b-collapse class="card" animation="slide" :open="isOpen" :aria-id="'contentIdForA11y5-'">
            <template #trigger="props">
                <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-'"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        PEP Results
                    </p>
                    <span v-if="pepData && pepData.length > 0">NO. of Sources : {{ pepData.length }}</span>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">

                    <div class="tab-item-wrapper">
                        <div class="not-available" v-if="!pepData || !pepData.length">
                            No Data Available
                        </div>
                        <div v-else>
                            <!-- <div>NO. of Sources : {{ pepData.length }}</div>
                            <br> -->
                            <!-- Iterate over each object in pepData array -->
                            <div v-for="(pepData, index) in pepData" :key="index">
                                <div>Source : {{ index + 1 }}</div>
                                <div class="table-container" style="margin-bottom: 20px;">
                                    <table class="data-table">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{{ pepData.name ? pepData.name : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>{{ pepData.address ? pepData.address.join(', ') : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Gender</td>
                                                <td>{{ pepData.gender ? pepData.gender : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Place of Birth</td>
                                                <td>{{ pepData.place_of_birth ? pepData.place_of_birth.join(', ') :
                                                    'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date of Birth</td>
                                                <td>{{ pepData.date_of_birth ? pepData.date_of_birth : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Country</td>
                                                <td>{{ pepData.country ? pepData.country.join(', ') : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Alias Names</td>
                                                <td>{{ pepData.alias_names ? pepData.alias_names.join(', ') : 'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Family Tree</td>
                                                <td>
                                                    <ul>
                                                        <li v-for="(members, relationship) in pepData.familytree"
                                                            :key="relationship">
                                                            <strong>{{ relationship }}</strong>:
                                                            <ul>
                                                                <li v-for="member in members" :key="member">{{ member }}
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Source Name</td>
                                                <td>{{ pepData.source_name ? pepData.source_name : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Source Link</td>
                                                <td><a :href="pepData.source_link" target="_blank">{{
                                                    pepData.source_link ?
                                                        pepData.source_link : 'N/A' }}</a></td>
                                            </tr>
                                            <tr>
                                                <td>Source Description</td>
                                                <td>{{ pepData.source_description ? pepData.source_description : 'N/A'
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td>Nationality</td>
                                                <td>{{ pepData.nationality ? pepData.nationality.join(', ') : 'N/A' }}
                                                </td>
                                            </tr>
                                            <tr v-if="index >= 0">
                                                <td :rowspan="pepData.designation.length">Designation</td>
                                                <td>{{ pepData.designation.designation }}</td>
                                            </tr>
                                            <!-- Display rest of the designations -->
                                            <tr v-for="(designation, dIndex) in pepData.designation.slice(1)"
                                                :key="'designation_' + dIndex">
                                                <td>{{ designation.designation }}</td>
                                            </tr>
                                            <tr>
                                                <td>Designation PEP Type</td>
                                                <td>{{ pepData.pep_type ? pepData.pep_type.join(', ') : 'N/A' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>

        <b-collapse class="card" animation="slide" :open="isOpen" :aria-id="'contentIdForA11y5-'">
            <template #trigger="props">
                <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-'"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        Sanction Results
                    </p>
                    <span v-if="sanctionData && sanctionData.length > 0">NO. of Sources : {{ sanctionData.length }}</span>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <div class="header">
                        Sanction Results
                    </div>
                    <div class="tab-item-wrapper">
                        <div class="not-available" v-if="!sanctionData || !sanctionData.length">
                            No Data Available
                        </div>
                        <div v-else>
                            <!-- <div>NO. of Sources : {{ sanctionData.length }}</div>
                            <br> -->
                            <div v-for="(sanctionData, index) in sanctionData" :key="index">
                                <div class="table-container">
                                    <div>Source : {{ index + 1 }}</div>
                                    <table class="data-table">
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{{ sanctionData.name ? sanctionData.name : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Gender</td>
                                                <td>{{ sanctionData.gender ? sanctionData.gender : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Date of Birth</td>
                                                <td>{{ sanctionData.date_of_birth ? sanctionData.date_of_birth : 'N/A'
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td>Alias Names</td>
                                                <td>{{ sanctionData.alias_names ? sanctionData.alias_names.join(', ') :
                                                    'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Associated Country</td>
                                                <td>{{ sanctionData.associated_country ?
                                                    sanctionData.associated_country.join(', ')
                                                    : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Sanctioned Country</td>
                                                <td>{{ sanctionData.sanctionedCountry ?
                                                    sanctionData.sanctionedCountry.join(',') :
                                                    'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>President</td>
                                                <td>{{ sanctionData.president ? sanctionData.president : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Aircraft Details</td>
                                                <td>{{ sanctionData.aircraft_details ? sanctionData.aircraft_details :
                                                    'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Source Name</td>
                                                <td>{{ sanctionData.source_name ? sanctionData.source_name : 'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Source Type</td>
                                                <td>{{ sanctionData.source_type ? sanctionData.source_type : 'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Source Link</td>
                                                <td><a :href="sanctionData.source_link" target="_blank">{{
                                                    sanctionData.source_link
                                                        ? sanctionData.source_link : 'N/A' }}</a></td>
                                            </tr>
                                            <tr>
                                                <td>Source Description</td>
                                                <td>{{ sanctionData.source_description ? sanctionData.source_description
                                                    : 'N/A'
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Zipcode</td>
                                                <td>{{ sanctionData.zipcode ? sanctionData.zipcode : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Country of Jurisdiction</td>
                                                <td>{{ sanctionData.country_of_jurisdiction ?
                                                    sanctionData.country_of_jurisdiction :
                                                    'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Headquarters</td>
                                                <td>{{ sanctionData.headquarters ? sanctionData.headquarters : 'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Parent Company</td>
                                                <td>{{ sanctionData.parent_company ? sanctionData.parent_company : 'N/A'
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <td>Flag State</td>
                                                <td>{{ sanctionData.flag_state ? sanctionData.flag_state : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Call Sign</td>
                                                <td>{{ sanctionData.call_sign ? sanctionData.call_sign : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Tonnage</td>
                                                <td>{{ sanctionData.tonnage ? sanctionData.tonnage : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Model</td>
                                                <td>{{ sanctionData.model ? sanctionData.model : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Name Match Score</td>
                                                <td>{{ sanctionData.name_match_score ? sanctionData.name_match_score :
                                                    'N/A' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Overall Data Match score</td>
                                                <td>{{ sanctionData.sams_score.overall_score ?
                                                    sanctionData.sams_score.overall_score
                                                    : 'N/A' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Matched Address</td>
                                                <td>{{ sanctionData.matched_address ? sanctionData.matched_address :
                                                    'N/A' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>

        <b-collapse class="card" animation="slide" :open="isOpen" :aria-id="'contentIdForA11y5-'">
            <template #trigger="props">
                <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-'"
                    :aria-expanded="props.open">
                    <p class="card-header-title">
                        Risk Results
                    </p>
                    <span v-if="customerRiskRatingData && customerRiskRatingData.length > 0">NO. of Sources : {{ customerRiskRatingData.length }}</span>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <div class="header">
                        Risk Results
                    </div>
                    <div class="tab-item-wrapper">
                        <div class="not-available"
                            v-if="!customerRiskRatingData || typeof customerRiskRatingData.risk_rating === 'undefined'">
                            No Data Available
                        </div>
                        <div v-else>

                            <div class="table-container">
                                <table class="data-table">
                                    <tbody>
                                        <tr>
                                            <td>Risk Type</td>
                                            <td>{{ customerRiskRatingData.risk_type ? customerRiskRatingData.risk_type :
                                                'N/A'
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Risk Rating</td>
                                            <td>{{ customerRiskRatingData.risk_rating ?
                                                customerRiskRatingData.risk_rating + "%"
                                                :
                                                'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <td>Risk Rating Result</td>
                                            <td>
                                                <ul
                                                    v-if="customerRiskRatingData.risk_rating_result && customerRiskRatingData.risk_rating_result.length">
                                                    <li v-for="result in customerRiskRatingData.risk_rating_result"
                                                        :key="result">
                                                        <!-- Exclude 'ams' -->
                                                        <template v-if="result.name !== 'ams'">
                                                            {{ result.name }}: {{ result.classification }}
                                                        </template>
                                                    </li>
                                                </ul>
                                                <span v-else>N/A</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>

    </div>
</template>

<script>
import NetworkManager from "@/network";
import { BuefyHelper } from "@/helpers/bufeyHelper";

export default {
    name: "PepSactionDetails",
    data() {
        return {
            isOpen: false,
            currentDate: '',
            pepData: null,
            amsData: null,
            sanctionData: null,
            customerRiskRatingData: null,
        }
    },
    props: ['fullName'],
    methods: {
        getCurrentDate: function () {
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            this.currentDate = `${year}-${month}-${day}`;
        },
        getAllSearchResults: function () {
            console.log('getAllSearchResults in', this.fullName);
            const comp = this;
            const data = { applicant_name: this.fullName };

            comp.showFullScreenLoader();

            NetworkManager.apiRequest(
                "api/PepSanctionResult/getAllSearchResults",
                data,
                function (e) {
                    console.log('e.statusCode', e.statusCode);
                    if (e.statusCode === 200) {
                        if(e.data != null){
                            comp.amsData = e.data.amsResults;
                            comp.pepData = e.data.pepResults.peP_Result
                            comp.sanctionData = e.data.sanctionResults
                            comp.customerRiskRatingData = e.data.riskRatingResults.results
                            comp.hideFullScreenLoader();
                        }
                        else{
                            comp.hideFullScreenLoader();
                        }
                    }
                    else {
                        comp.hideFullScreenLoader();
                        BuefyHelper.showToastMessage("Unable to get the data", "is-danger")
                    }
                }
            );
        },
    },
    mounted() {
        console.log("full name :", this.fullName);
        this.getAllSearchResults();
    }
}
</script>

<style scoped></style>