<template>
  <div v-if="!isLoading">
    <div class="has-text-right" v-if="videCallData != null">
      <b-button class="is-primary is-rounded" v-if="videCallData.video_call_recording != null && !reviewOnly" @click="downloadVideo">Download Video Call</b-button>
    </div>
    <p>Video Call Details</p>
    <div class="columns is-7 is-variable">
      <div class="column">
        <InputLabel placeholder=" -- No data -- " 
          label="Date"
          :value="getFormattedDateTime(formData.video_call_date)"
          styles="mt-2"
          readonly="true"
          class="non-interactable-input"
        />
      </div>
      <div class="column">
        <InputLabel placeholder=" -- No data -- " 
          label="Time"
          readonly="true"
          class="non-interactable-input"
          v-model="formData.video_call_time"
          styles="mt-2"
        />
      </div>
    </div>

    <div class="columns is-7 is-variable" v-if="GlobalFunctions.isVideoCallCompleted(videCallData)">
      <div class="column">
        <InputLabel placeholder=" -- No data -- " 
            label="Date of Account Funding"
            :value="getFormattedDateTime(formData.date_of_account_funding)"
            styles="mt-2"
            readonly="true"
            class="non-interactable-input"
        />
      </div>
      <div class="column">
      </div>
    </div>

    <div v-if="videCallData != null">

      <div class="columns is-7 is-variable">
      <div class="column" v-if="videCallData.face_and_nic_front_side_image != null">
        <ImageBox label="Face and NIC front side image" styles="mt-2" :imageName=videCallData.face_and_nic_front_side_image.path />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.face_and_nic_front_side_image.rating"
              style="margin-left:100px;"
              readonly="true"
              class="non-interactable-input"
              custom-text="Satisfactory Level">
              </b-rate>
          
          </section>
        </div>
      </div>
      <div class="column" v-if="videCallData.face_and_nic_back_side_image != null">
        <ImageBox label="Face and NIC rear side image" styles="mt-2" :imageName=videCallData.face_and_nic_back_side_image.path />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.face_and_nic_back_side_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
    </div>
<!-- nic comments -->
<div
          style="width: fit-content; max-width: 850px"
          v-if="videCallData.nic_comments != null"
        >
          <div class="columns is-variable is-7">
            <div class="column is-three-fifths" style="pointer-events: none">
              <div
                v-for="comment in videCallData.nic_comments.comments"
                :key="comment.comment_content"
              >
                <b-field style="width: fit-content">
                  <b-checkbox         
                    readonly="true"
                    class="non-interactable-input"
                    type="is-primary"
                    v-model="comment.is_comment_selected"
                    @click.native="warning()"
                  >
                    {{ comment.comment_content }}
                  </b-checkbox>
                </b-field>

                <div v-if="comment.additional_comment != ''">
                  <b-field style="text-align: center">
                    <b-input
                      rounded
                      readonly="true"
                      class="non-interactable-input"
                      @click.native="warning()"
                      maxlength="200"
                      placeholder="Specify"
                      v-model="comment.additional_comment"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end of nic comments -->
    <div class="columns is-7 is-variable" v-if="videCallData.face_and_passport_bio_page_image != null">
      <div class="column">
        <ImageBox label="Face and Passport bio page image" styles="mt-2" :imageName=videCallData.face_and_passport_bio_page_image.path	 />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.face_and_passport_bio_page_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
      <div class="column">
        <ImageBox label="Face and Passport alteration page image" styles="mt-2" :imageName=videCallData.face_and_passport_alteration_page_image.path />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.face_and_passport_alteration_page_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
    </div>

    <div class="columns is-7 is-variable" v-if="videCallData.face_and_passport_bio_page_image != null">
      <div class="column is-half">
        <ImageBox label="Face and Passport entry stamp page image" styles="mt-5" :imageName=videCallData.face_and_passport_entry_stamp_page_image.path	 />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.face_and_passport_entry_stamp_page_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
    </div>

         <!-- ////////////////////passport comments start//////////////// -->
      <!-- <div v-if="videoCallDetail.face_and_passport_alteration_page_image || videoCallDetail.face_and_passport_entry_stamp_page_image"> -->
        <div v-if="videCallData.passport_comments != null">
        <div relative style="width: fit-content; max-width: 1100px">
          <div class="columns is-variable is-7">
            <div class="column is-three-fifths" style="pointer-events: none">
              <div
                v-for="comment in videCallData.passport_comments.comments"
                :key="comment.comment_content"
              >
                <b-field style="width: fit-content">
                  <b-checkbox
                    type="is-primary"
                    v-model="comment.is_comment_selected"
                    @click.native="warning()"
                    readonly="true"
                    class="non-interactable-input"
                  >
                    {{ comment.comment_content }}
                  </b-checkbox>
                </b-field>

                <div v-if="comment.additional_comment != ''">
                  <b-field style="text-align: center">
                    <b-input
                      @click.native="warning()"
                      maxlength="200"
                      rounded
                      placeholder="Specify"
                      v-model="comment.additional_comment"
                      readonly="true"
                      class="non-interactable-input"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <hr
            :style="{
              'background-color': '#1b1b1b',
              height: '1.8px',
              opacity: '0.5',
              'margin-inline': '4px',
            }"
          />
        </div>
      </div>

      <!-- ////////////////////passport comments end//////////////// -->

      <div class="columns is-7 is-variable" v-if="videCallData.face_and_visa_image != null">
      <div class="column is-half">
        <ImageBox label="Visa image" styles="mt-5" :imageName=videCallData.face_and_visa_image.path	 />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.face_and_visa_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level">
              </b-rate>
          
          </section>
        </div>
      </div>
      </div>

    <div class="columns is-7 is-variable">
      <div class="column" v-if="videCallData.selfie_image != null">
        <ImageBox label="Selfie image" styles="mt-5" :imageName=videCallData.selfie_image.path />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.selfie_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
      <div class="column" v-if="videCallData.signature_image != null">
        <ImageBox label="Signature image" styles="mt-5" :imageName=videCallData.signature_image.path />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.signature_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
    </div>

    <div class="columns is-7 is-variable" v-if="videCallData.address_proof_document_image != null">
      <div class="column is-half">
        <ImageBox label="Proof of address" styles="mt-5" :imageName=videCallData.address_proof_document_image.path />
        <div class="rating-wrapper">
          <section>
            <b-rate disabled
              @click.native="warning()"
              v-model="videCallData.address_proof_document_image.rating"
              style="margin-left:100px;"
              custom-text="Satisfactory Level"
              readonly="true"
              class="non-interactable-input">
              </b-rate>
          
          </section>
        </div>
      </div>
    </div>
    
     <!-- checkbox selection for vedio call review page -->
     <div class="check">
        <div class="columns is-7 is-variable">
          <div class="column" v-if="videCallData.video_comments != null">
            <p class="mb-2">Video</p>
            <div style="pointer-events: none">
              <div
                v-for="comment in videCallData.video_comments.comments"
                :key="comment.comment_content"
              >
                <b-field style="width: fit-content">
                  <b-checkbox
                    type="is-primary"
                    v-model="comment.is_comment_selected"
                    @click.native="warning()"
                    readonly="true"
                    class="non-interactable-input"
                  >
                    {{ comment.comment_content }}
                  </b-checkbox>
                </b-field>

                <div v-if="comment.additional_comment != ''">
                  <b-field style="text-align: center">
                    <b-input
                      @click.native="warning()"
                      maxlength="200"
                      rounded
                      placeholder="Specify"
                      v-model="comment.additional_comment"
                      readonly="true"
                      class="non-interactable-input"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
 <!-- Audio call verification -->

 <div class="column" v-if="videCallData.audio_comments != null">
        <p class="mb-2">Audio call verification</p>
        <div style="pointer-events: none">
          <div
            v-for="comment in videCallData.audio_comments.comments"
            :key="comment.comment_content"
          >
            <b-field style="width: fit-content">
              <b-checkbox
                type="is-primary"
                v-model="comment.is_comment_selected"
                @click.native="warning()"
                readonly="true"
                class="non-interactable-input"
              >
                {{ comment.comment_content }}
              </b-checkbox>
            </b-field>

            <div v-if="comment.additional_comment != ''">
              <b-field style="text-align: center">
                <b-input
                  @click.native="warning()"
                  maxlength="200"
                  rounded
                  placeholder="Specify"
                  v-model="comment.additional_comment"
                  readonly="true"
                  class="non-interactable-input"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
   

       <!-- if DRP Verification failed -->

       <div class="columns is-7 is-variable">
        <div class="column" v-if="videCallData.drp_comments != null">
          <p class="mb-2">if DRP Verification failed</p>

          <div style="pointer-events: none">
            <div
              v-for="comment in videCallData.drp_comments.comments"
              :key="comment.comment_content"
            >
              <b-field style="width: fit-content">
                <b-checkbox
                  type="is-primary"
                  v-model="comment.is_comment_selected"
                  @click.native="warning()"
                  readonly="true"
                  class="non-interactable-input"
                >
                  {{ comment.comment_content }}
                </b-checkbox>
              </b-field>

              <div v-if="comment.additional_comment != ''">
                <b-field style="text-align: center">
                  <b-input
                    @click.native="warning()"
                    maxlength="200"
                    rounded
                    placeholder="Specify"
                    v-model="comment.additional_comment"
                    readonly="true"
                    class="non-interactable-input"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>

        <div
        class="column"
        v-if="videCallData.customer_behavior_comments != null"
      >
        <p class="mb-2">Customer Behaviour</p>

        <div style="pointer-events: none">
          <div
            v-for="comment in videCallData.customer_behavior_comments
              .comments"
            :key="comment.comment_content"
          >
            <b-field style="width: fit-content">
              <b-checkbox
                type="is-primary"
                v-model="comment.is_comment_selected"
                @click.native="warning()"
                readonly="true"
                class="non-interactable-input"
              >
                {{ comment.comment_content }}
              </b-checkbox>
            </b-field>

            <div v-if="comment.additional_comment != ''">
              <b-field style="text-align: center">
                <b-input
                  @click.native="warning()"
                  maxlength="200"
                  rounded
                  placeholder="Specify"
                  v-model="comment.additional_comment"
                  readonly="true"
                  class="non-interactable-input"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      </div>

       <!-- Location verification -->
       <div class="columns is-7 is-variable">
        <div
          class="column"
          v-if="videCallData.location_verification_comments != null"
        >
          <p class="mb-2">Location verification</p>
          <div style="pointer-events: none">
            <div
              v-for="comment in videCallData.location_verification_comments
                .comments"
              :key="comment.comment_content"
            >
              <b-field style="width: fit-content">
                <b-checkbox
                  type="is-primary"
                  v-model="comment.is_comment_selected"
                  @click.native="warning()"
                  readonly="true"
                  class="non-interactable-input"
                >
                  {{ comment.comment_content }}
                </b-checkbox>
              </b-field>

              <div v-if="comment.additional_comment != ''">
                <b-field style="text-align: center">
                  <b-input
                    @click.native="warning()"
                    maxlength="200"
                    rounded
                    placeholder="Specify"
                    v-model="comment.additional_comment"
                    readonly="true"
                    class="non-interactable-input"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
 <!-- location verifier -->
 <div
        class="column"
        v-if="videCallData.location_verifier_comments != null"
      >
        <p class="mb-2">Location verifier</p>
        <div style="pointer-events: none">
          <div
            v-for="comment in videCallData.location_verifier_comments
              .comments"
            :key="comment.comment_content"
          >
            <b-field style="width: fit-content">
              <b-checkbox
                type="is-primary"
                v-model="comment.is_comment_selected"
                @click.native="warning()"
                readonly="true"
                class="non-interactable-input"
              >
                {{ comment.comment_content }}
              </b-checkbox>
            </b-field>

            <div v-if="comment.additional_comment != ''">
              <b-field style="text-align: center">
                <b-input
                  @click.native="warning()"
                  maxlength="200"
                  rounded
                  placeholder="Specify"
                  v-model="comment.additional_comment"
                  readonly="true"
                  class="non-interactable-input"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>

      </div>
      <div class=columns>
        <div class="column">
          <p class="mb-2">Customer Spoken Language</p>
            <div v-if="formData.spoken_language">
              <AgentInputField
                label="Customer Spoken Language"
                :value="formData.spoken_language"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
        </div>
      </div>
      <div v-if="videCallData.video_call_recording != null">
        <video 
          disablePictureInPicture 
          controls 
          :src="videCallData.video_call_recording" 
          style="width: 50%; border-radius: 25px; border: 2px solid #ff6666;"
          v-once
          onloadmetadata="this.parentNode.querySelector('.videoDuration').textContent = this.duration.toFixed(2)"
        ></video>  
        <br>
        <!-- <b-button class="is-danger" style="width: 50%;" @click="downloadVideo">Download</b-button> -->
      </div>
    </div>
    <div class="columns ml-1">
        <div
              class="container"
              style="
                width: 50vw;
                height: 8vh;
                border-radius: 15px;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
              "
        >

          <b-button type="is-primary"
                    class="attachment-btn"
                    style="width: 350px"
                    size="is-medium"
                    label="Random Questionnaire"
                    @click="showRandomQuestionnaireModel =true"
          />
        </div>

        <div v-if=showRandomQuestionnaireModel  id="loading" class="fullscreen">
          <VideoCallRandomQuestionnaireResult
              :applicationId = authStore.kyc_form_id
              @Close_Random_Question_Model="Close_Random_Question_Model"/>
        </div>
      </div>
  </div>
  <b-loading v-else :is-full-page="true" :can-cancel="false" v-model="isLoading"/>

</template>

<script>
import ImageBox from "../../../components/ImageBox.vue";
import InputLabel from "../../../components/InputLabel.vue";
import {useAuthStore} from "../../../store/authStore";
import {DateTimeManger} from "../../../helpers/dateTimeManger";
import NetworkManager from "@/network";
import {GlobalFunctions} from "../../../helpers/globalFunctions";
import AgentInputField from "../../../components/AgentInputField.vue";
import VideoCallRandomQuestionnaireResult from "@/components/VideoCallRandomQuestionnaireResult.vue";

export default {
  name: "VedioCallDetail",
  computed: {
    GlobalFunctions() {
      return GlobalFunctions
    }
  },

  props:{
    reviewOnly : {
      required:false,
      type:Boolean,
      default:false
    }
  },

  components: {
    VideoCallRandomQuestionnaireResult,
    InputLabel,
    AgentInputField,
    ImageBox

  },
  setup(){
    const authStore = useAuthStore()
    return {authStore}
  },
  data() {
    return {
      videCallData:[],
      formData :[],
      isLoading : false,
      showRandomQuestionnaireModel : false
    };
  },
  methods: {
    getFormattedDateTime(date){
      return DateTimeManger.formatCustomerInputDates(date)
    },

    GetApprovedApplicationData: function (){
      const comp= this

      comp.isLoading = true

      const data = {
        id: comp.authStore.kyc_form_id
      }
      console.log("in GetApprovedApplicationData")

      NetworkManager.apiRequest('api/KycApplication/getApplicationById',data, function (e){
        console.log("in GetApprovedApplicationData",e.data)
        if(e.statusCode === 200){
          comp.formData = e.data.kycApplication  
          comp.videCallData = e.data.videoCallDetail

          comp.isLoading = false;
        }
        else{
          comp.isLoading = false;
          console.error("Failed to get application data")
        }
      })
    },

    downloadVideo() {
      var video_name=this.formData.reference_number	+".mp4"

      var xhr = new XMLHttpRequest();
      xhr.open('GET', this.videCallData.video_call_recording, true);
      xhr.responseType = 'blob';
      xhr.onload = function() {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.target = '_blank';
        
        tag.download = video_name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = err => {
        alert('Failed to download video');
      };
      xhr.send();
    },
    Close_Random_Question_Model(value) {
      this.showRandomQuestionnaireModel = value;
    },
  },
  mounted() {
    this.GetApprovedApplicationData();
   }
};
</script>

<style lang="scss" scoped>
.non-interactable-input {
  pointer-events: none;
}
</style>
