import {defineStore} from "pinia";
import UserInteractionDetails from "@/helpers/classes/userInteractionDetails";


export const useAuthStore = defineStore('authStore',{

    state : () =>({
        token:'',
        isTokenRefreshing : false,
        webRTCInternalLink : "",
        iceServers : [],
        loggedInUser : null,
        isSocketInitialized : false,
        kyc_form_id:null,
        customer_nic_number:null,

        // for common kyc application id
        common_kyc_form_id:null,

        // to track review only option in application view UIs
        reviewOnly:{
            approvedApplicationView : false,
            basicApplicationView : false,
        },

        userInteractionDetails :  new UserInteractionDetails(),
        maxSessionIdleTimeMins : 0,
        tempTime : 2
    }),
})